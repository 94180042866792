import React, { useState, useContext, useEffect, useRef } from "react";
import { IconButton, Modal } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import HomeLayout from "./HomeLayout";
import SearchOnlinePage from "./SearchOnlinePage";
import SelectedCitation from "./SelectedCitation";
import DocumentReference from "../DocumentReference/DocumentReference";
import ShareArticle from "./ShareArticle";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";
import User from "../../api/User";
import Recommendation from "./Recommendation";
import Topics from "./Topics";

const Home = ({ logout, setLoggedInFalse, checkLoginStatusWithContentControl }) => {
  const [searchRoute, setSearchRoute] = useState(true);
  const [documentRoute, setDocumentRoute] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [searchOnline, setSearchOnline] = useState(false);
  const [recommendedArticles, setRecommendedArticles] = useState(false);
  const [userTopics, setUserTopics] = useState(false);

  const homeRef = useRef();

  const context = useContext(HomeContext);

  useEffect(() => {
    let userData;
    User.auth()
      .then(response => {
        userData = response.data;
        setUserInfo(response.data);
        return context.setUserInformation(userData);
      })
      .then(() => {
        return checkLoginStatusWithContentControl();
      })
      .then(() => {
        return context.setDocumentFileName();
      })
      .then(() => {
        return context.updateReferenceArticlesForNewFile();
      })
      .then(() => {
        context.updateCitationDoc();
      })
      .catch(err => {
        console.log(err);
        if (typeof err.response.status !== "undefined") {
          let status = err.response.status;
          if (status === 401) {
            setLoggedInFalse();
          }
          if (status === 403) {
            toast.error(
              "You can't sign in to nXr.iCite in this document as the citations in the document were not made from your library. To view cited materials, single users can use the ‘Share Cited Materials’ option in nXr.iCite.",
              { autoClose: 3000 }
            );
            setLoggedInFalse();
          }
        }
      });
  }, []);

  const updateAddIns = () => {
    if (searchRoute) {
      homeRef.current.syncAddIns();
    } else {
      toast.success("Synced successfully", { autoClose: 2000 });
    }
  };

  const changeRoute = routeType => {
    if (routeType === "search") {
      setSearchRoute(true);
      setDocumentRoute(false);
    } else {
      setSearchRoute(false);
      setDocumentRoute(true);
    }
  };

  const changeSearchOnlineStatus = () => {
    let activeStatus = !searchOnline;
    changeRoute("search");
    setSearchOnline(activeStatus);
    setRecommendedArticles(false);
    setUserTopics(false);
  };

  const changeRecommendedArticlesStatus = () => {
    let activeStatus = !recommendedArticles;
    changeRoute("search");
    setRecommendedArticles(activeStatus);
    setSearchOnline(false);
    setUserTopics(false);
  };

  const changeTopicsStatus = () => {
    let activeStatus = !userTopics;
    changeRoute("search");
    setUserTopics(activeStatus);
    setRecommendedArticles(false);
    setSearchOnline(false);
  };

  return (
    <main className="ms-welcome__main">
      <div>
        <div className="top-sync-dot-menu">
          <div className="top-sd-button"></div>
          {userInfo.nxr_user === 1 ? (
            <div className="top-sync">
              <button type="button" onClick={() => changeTopicsStatus()} title="Topics" style={{ marginRight: "8px" }}>
                {userTopics ? (
                  <img
                    src={http.BASE_URL + "assets/topic-active.svg"}
                    alt=""
                    style={{ height: "22px", width: "32px" }}
                  />
                ) : (
                  <img
                    src={http.BASE_URL + "assets/topic-inactive.svg"}
                    alt=""
                    style={{ height: "22px", width: "32px" }}
                  />
                )}
              </button>
              <button
                type="button"
                style={{ marginRight: "8px" }}
                className="online-search-btn"
                onClick={() => changeSearchOnlineStatus()}
                title="Search online"
              >
                {searchOnline ? (
                  <img src={http.BASE_URL + "assets/online-search-active.svg"} alt="" />
                ) : (
                  <img src={http.BASE_URL + "assets/online-search-inactive.svg"} alt="" />
                )}
              </button>
              {/* <button
                type="button"
                style={{ marginRight: "8px" }}
                className="online-search-btn"
                onClick={() => changeRecommendedArticlesStatus()}
                title="Recommended Articles"
              >
                <img src={http.BASE_URL + "assets/recommended-articles.png"} alt="" />
              </button> */}
              <button
                type="button"
                style={{ marginRight: "8px" }}
                onClick={() => context.shareReference()}
                title="Share cited materials"
              >
                <img src={http.BASE_URL + "assets/citeshare.svg"} alt="" />
              </button>
              <button
                type="button"
                onClick={() => updateAddIns()}
                title="Sync between nXr.iCite and nXr.iLibrary"
                style={{ marginRight: "8px" }}
              >
                <img src={http.BASE_URL + "assets/update.svg"} alt="" />
              </button>
            </div>
          ) : null}
          <div className="top-dot-menu">
            <button className="button">
              <img src={http.BASE_URL + "assets/dot-menu.png"} alt="" />
            </button>
            <ul className="menu-list">
              <li>
                <button
                  onClick={() =>
                    context.goToUrl("https://www.youtube.com/watch?v=04mRQyl1jFE&ab_channel=nextXenerationreferencing")
                  }
                >
                  See It In Action
                </button>
              </li>
              <li>
                <button onClick={() => context.goToUrl("https://www.nxref.com/help/")}>Help</button>
              </li>
              <li>
                <button type="button" onClick={() => logout()}>
                  Sign Out
                </button>
              </li>
            </ul>
          </div>
        </div>
        {Object.keys(userInfo).length > 0 ? (
          userInfo.nxr_user === 1 ? null : (
            <div>
              <div className="share-user-title">
                <h3>Reference validation</h3>
              </div>
              {context.referenceArticles.length === 0 ? (
                <div className="share-user-info">
                  <hr />
                  If the document is shared with you and you haven’t changed the original document name, then you can
                  see the “Citation details” and “References”
                  <hr />
                </div>
              ) : null}
              <div className="share-user-info">
                <h4>Citation details</h4>
                <hr />
                <span>To view, click on a citation (e.g., on [1] or (Doe et al. 2020)) in the document</span>
              </div>
            </div>
          )
        ) : null}
        <div>
          <SelectedCitation
            searchOnline={searchOnline}
            recommendedArticles={recommendedArticles}
            userTopics={userTopics}
          />
        </div>
        {userInfo.nxr_user === 1 ? (
          searchOnline || recommendedArticles || userTopics ? null : (
            <ul className="top-tab tab-list">
              <li className={searchRoute ? "active" : ""}>
                <a href="#0" onClick={() => changeRoute("search")}>
                  Search
                </a>
              </li>
              <li className={documentRoute ? "active" : ""}>
                <a href="#0" onClick={() => changeRoute("document")}>
                  Document references
                </a>
              </li>
            </ul>
          )
        ) : null}
        {userInfo.nxr_user === 1 ? (
          searchRoute ? (
            searchOnline ? (
              <SearchOnlinePage userInfo={userInfo} />
            ) : recommendedArticles ? (
              <Recommendation userInfo={userInfo} />
            ) : userTopics ? (
              <Topics userInfo={userInfo} />
            ) : (
              <HomeLayout ref={homeRef} userInfo={userInfo} />
            )
          ) : (
            <DocumentReference isNxrUser={userInfo.nxr_user === 1} />
          )
        ) : (
          <DocumentReference isNxrUser={userInfo.nxr_user === 1} />
        )}
      </div>
      <ShareArticle />
    </main>
  );
};

export default Home;
