import React, { createContext, Component } from "react";
import CSL from "citeproc";
import { toast } from "react-toastify";
import axios from "axios";
import { IconButton, Modal, mergeStyleSets, getTheme, FontSizes, FontWeights } from "office-ui-fabric-react";
import ReferenceArticles from "../api/ReferenceArticles";
import ArticleApi from "../api/Article";
import User from "../api/User";
import { http } from "../utils/http";
import { allCslStyles } from "../components/DocumentReference/cslstyle";
import Note from "../api/Note";

export const HomeContext = createContext();

const _ = require("lodash");

toast.configure();

class HomeContextProvider extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      citedTexts: [],
      contentStyles: null,
      iconButtonStyles: null,
      showModal: false,
      userInfo: {},
      selectedArticles: [],
      selectedNotes: [],
      filterBy: {},
      documentUrl: "",
      actualFileName: "",
      referenceStyle: "apa",
      referenceArticles: [],
      inlineReferenceArticles: [],
      citations: {},
      deletedCitedList: [],
      sharedEmail: "",
      sharedPassword: "",
      userFullInformation: {},
      showShareModal: false,
      showUpdateInfoModal: false,
      articleInfo: {},
      orderByArticles: [],
      citedNotes: [],
      deletedCitatedNotesList: [],
      showCitationInsertBtn: false,
      forSharebleName: "",
      citeproc: null,
      contentControls: [],
      pasteOptionEnable: true,
      citationIsNumberFormat: false,
      onContentControl: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // Set style for modal
    this.setModalStyle();

    Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, eventArgs => {
      if (this._isMounted) {
        this.setState({ deletedCitedList: [], deletedCitatedNotesList: [] });

        this.getCitationTextsByControlId();
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Get citation text from content control id
  getCitationTextsByControlId = () => {
    let myContentControl = null;
    Word.run(context => {
      myContentControl = context.document.getSelection().parentContentControl;
      context.load(myContentControl);
      this.setState({ pasteOptionEnable: false, onContentControl: true });
      return context.sync();
    })
      .then(() => {
        let contentControlId = myContentControl.tag;
        return this.getCitationTextFromDbByContentControlId(contentControlId);
      })
      .then(citedTexts => {
        let citeproc = this.getCiteProc(this.state.orderByArticles);

        let resultedArr = citeproc.registry.reflist;

        let sortedRefList = this.getSortedRefList(resultedArr);

        citedTexts.forEach(citedItem => {
          let authorName = "";
          if (citedItem.article.authors !== null) {
            let authors = citedItem.article.authors.split(";");
            if (authors[0].includes(",")) {
              let firstAuthor = authors[0].split(", ");
              authorName = firstAuthor[firstAuthor.length - 1].trim();
            } else {
              let firstAuthor = authors[0].split(" ");
              authorName = firstAuthor[firstAuthor.length - 1].trim();
            }
          }
          let findIndex = resultedArr.findIndex(val => parseInt(val.id) === citedItem.article_id);
          let charSeq = typeof sortedRefList[findIndex].charSeq !== "undefined" ? sortedRefList[findIndex].charSeq : "";
          let articleCitationText = authorName + " et al. " + citedItem.article.publication_year + charSeq;
          if (sortedRefList[findIndex].ambig !== "" && sortedRefList[findIndex].ambig !== null) {
            citedItem.citeproc_text = sortedRefList[findIndex].ambig;
            // citedItem.show_citation_text = articleCitationText;
            citedItem.show_citation_text =
              citedItem.custom_citation_text !== null && citedItem.custom_citation_text !== ""
                ? citedItem.custom_citation_text
                : articleCitationText;
            citedItem.modal_citation_text = citedItem.show_citation_text;
            citedItem.disable_custom_text = false;
          } else {
            citedItem.citeproc_text = null;
            citedItem.show_citation_text = "[" + parseInt(findIndex + 1) + "] " + articleCitationText;
            citedItem.modal_citation_text = "[" + parseInt(findIndex + 1) + "]";
            citedItem.disable_custom_text = true;
          }
          citedItem.sortedKey = parseInt(findIndex + 1);
        });

        citedTexts.sort((a, b) => {
          return parseInt(a.sortedKey) - parseInt(b.sortedKey);
        });

        this.setState({ citedTexts: citedTexts });
      })
      .catch(err => {
        this.setState({ citedTexts: [], pasteOptionEnable: true, onContentControl: false });
      });
  };

  // get text from db by content control id
  getCitationTextFromDbByContentControlId = contentControlId => {
    let inlineArticlesByContentControlId = [];
    const inlineReferenceArticles = this.state.inlineReferenceArticles;
    const citedNotes = this.state.citedNotes;
    inlineReferenceArticles.forEach(article => {
      if (article.content_control_id === contentControlId) {
        article.notes = [];
        inlineArticlesByContentControlId.push(article);
      }
    });
    citedNotes.forEach(note => {
      if (note.content_control_id === contentControlId) {
        let findIndex = inlineArticlesByContentControlId.findIndex(val => val.article_id === note.article_id);
        if (findIndex !== -1) {
          inlineArticlesByContentControlId[findIndex].notes.push(note);
        }
      }
    });
    return inlineArticlesByContentControlId;
  };

  // Set document file name
  setDocumentFileName = () => {
    return new Promise((resolve, reject) => {
      Office.context.document.getFilePropertiesAsync(asyncResult => {
        var fileUrl = asyncResult.value.url;
        let sharableFileName, actualFileName;
        let userId = this.state.userInfo.id;
        if (fileUrl == "") {
          console.log("The file hasn't been saved yet. Save the file and try again");
          let min = 222222;
          let max = 999999;
          const diff = max - min;
          const random = Math.random();
          const generatedNumber = Math.floor(random * diff + min);
          let fileName = "NXRTEMP-" + generatedNumber + "D.docx";
          sharableFileName = fileName;
          actualFileName = fileName;
          this.setState(
            { documentUrl: fileName, forSharebleName: sharableFileName, actualFileName: actualFileName },
            () => {
              resolve(fileName);
            }
          );
        } else {
          let fileName;
          let isOneDrive = true;
          if (fileUrl.includes("/")) {
            fileUrl = fileUrl.split("/");
          } else {
            fileUrl = fileUrl.split("\\");
            isOneDrive = false;
          }
          if (fileUrl.length >= 2) {
            fileName = userId + fileUrl[fileUrl.length - 2] + fileUrl[fileUrl.length - 1];
            if (isOneDrive) {
              if (fileUrl.length === 5) {
                actualFileName = "OneDrive" + "\\" + fileUrl[fileUrl.length - 1];
              } else {
                actualFileName = fileUrl[fileUrl.length - 2] + "\\" + fileUrl[fileUrl.length - 1];
              }
            } else {
              actualFileName = fileUrl[fileUrl.length - 2] + "\\" + fileUrl[fileUrl.length - 1];
            }
            console.log(actualFileName);
          } else {
            fileName = userId + fileUrl[fileUrl.length - 1];
            actualFileName = "OneDrive" + "\\" + fileUrl[fileUrl.length - 1];
          }
          sharableFileName = fileUrl[fileUrl.length - 1];
          fileName = decodeURI(fileName);
          sharableFileName = decodeURI(sharableFileName);
          actualFileName = decodeURI(actualFileName);
          this.setState(
            { documentUrl: fileName, forSharebleName: sharableFileName, actualFileName: actualFileName },
            () => {
              resolve(fileName);
            }
          );
        }
      });
    });
  };

  // Set User Information
  setUserInformation = userInfo => {
    this.setState({ userInfo: userInfo });
  };

  // Add Or Update citation Style
  addOrUpdateUserCitationStyle = referenceStyle => {
    return new Promise((resolve, reject) => {
      ReferenceArticles.updateFileCitationStyle({ file_name: this.state.documentUrl, citation_style: referenceStyle })
        .then(response => {
          this.setState({ referenceStyle: referenceStyle });
          return true;
        })
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };

  // Set reference articles
  setReferenceArticles = articles => {
    this.setState({ referenceArticles: articles });
  };

  toggleArticleNotes = article => {
    let articles = this.state.referenceArticles;
    articles.find(articleResult => articleResult.id === article.id).isShowNotes = !articles.find(
      articleResult => articleResult.id === article.id
    ).isShowNotes;
    this.setState({ referenceArticles: articles });
  };

  // Sort Articles
  sortArticles = articles => {
    let citeproc = this.getCiteProc(articles);
    let resultedArr = citeproc.registry.reflist;
    let finalArticles = [];
    resultedArr.forEach(article => {
      let findIndex = articles.findIndex(val => val.id === parseInt(article.id));
      if (findIndex !== -1) {
        finalArticles.push(articles[findIndex]);
      }
    });
    return { citeproc: citeproc, finalArticles: finalArticles };
  };

  // Get citeproc result
  getCiteProc = articles => {
    let citedArticles = this.getCitedArticles(articles);
    let findIndex = allCslStyles.findIndex(val => val.id === this.state.referenceStyle);
    let styleId = this.state.referenceStyle;
    if (findIndex !== -1) {
      if (typeof allCslStyles[findIndex] !== "undefined") {
        styleId = allCslStyles[findIndex].parent === "NA" ? allCslStyles[findIndex].id : allCslStyles[findIndex].parent;
      }
    }
    let citeproc = this.getProcessor(styleId, citedArticles.citations);
    citeproc.updateItems(citedArticles.itemIDs);
    return citeproc;
  };

  // Update reference Articles For new file
  updateReferenceArticlesForNewFile = () => {
    return new Promise((resolve, reject) => {
      let documentName = this.state.userInfo.nxr_user === 1 ? this.state.documentUrl : this.state.forSharebleName;
      let actualFileName = this.state.actualFileName;
      let contentControlsWithTag;
      Word.run(context => {
        // Create a proxy object for the content controls collection that contains a specific tag.
        contentControlsWithTag = context.document.contentControls;
        // Queue a command to load the tag property for all of content controls.
        context.load(contentControlsWithTag);

        return context.sync();
      })
        .then(() => {
          let contentControls = [];
          if (contentControlsWithTag.items.length > 0) {
            for (let i = 0; i < contentControlsWithTag.items.length; i++) {
              if (contentControlsWithTag.items[i].tag !== "reference-citations") {
                let contentControlId = isNaN(Number(contentControlsWithTag.items[i].tag))
                  ? contentControlsWithTag.items[i].id
                  : Number(contentControlsWithTag.items[i].tag);
                contentControls.push(contentControlId);
              }
            }
          }
          return ReferenceArticles.updateReferenceArticles({
            contentControls: contentControls,
            documentName: documentName,
            actualFileName: actualFileName
          });
        })
        .then(response => {
          this.setState({ referenceStyle: response.data });
          return response.data;
        })
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          console.log("Error: " + JSON.stringify(error));
          if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
          }
          reject(error);
        });
    });
  };

  // Get reference articles
  getReferenseArticles = () => {
    return new Promise((resolve, reject) => {
      let contentControlsWithTag;
      Word.run(context => {
        // Create a proxy object for the content controls collection that contains a specific tag.
        contentControlsWithTag = context.document.contentControls;
        // Queue a command to load the tag property for all of content controls.
        context.load(contentControlsWithTag);

        return context.sync();
      })
        .then(() => {
          if (contentControlsWithTag.items.length === 0) {
            return {
              articles: [],
              inlineArticles: []
            };
          } else {
            return this.getCitedItems(contentControlsWithTag.items);
          }
        })
        .then(allArticles => {
          resolve(allArticles);
        })
        .catch(error => {
          console.log("Error: " + JSON.stringify(error));
          if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
          }
          reject(error);
        });
    });
  };

  // Get matched cited items from db
  getCitedItems = contentControlItems => {
    return new Promise((resolve, reject) => {
      let documentName = this.state.documentUrl;
      let sharableDocName = this.state.forSharebleName;
      let contentControls = [];
      let articles = [];
      let inlineArticles = [];
      let citedNotes = [];
      let duplicateCitedItems = [];
      let citedIndexes = [];
      for (let i = 0; i < contentControlItems.length; i++) {
        if (contentControlItems[i].tag !== "reference-citations") {
          if (contentControlItems[i].tag.includes("110120")) {
            let contentControlId = isNaN(Number(contentControlItems[i].tag))
              ? contentControlItems[i].id
              : Number(contentControlItems[i].tag);

            let findContentControlIndex = contentControls.findIndex(val => val === contentControlId);

            if (findContentControlIndex !== -1) {
              duplicateCitedItems.push(contentControlId);
            }

            citedIndexes.push(i);

            contentControls.push(contentControlId);
          }
        }
      }
      this.updateCopyCitation(citedIndexes, contentControls, duplicateCitedItems, documentName)
        .then(foundedContentControls => {
          this.setState({ contentControls: foundedContentControls }, () => {
            ReferenceArticles.getDocumentReferenceArticles(foundedContentControls, documentName, sharableDocName)
              .then(response => {
                response.data.forEach(result => {
                  result.notes = [];
                  inlineArticles.push(result);
                  let findIndex = articles.findIndex(val => val.id === result.article_id);
                  if (findIndex === -1) {
                    result.article.notes = [];
                    articles.push(result.article);
                  }
                });
                return ReferenceArticles.getDocumentReferenceNotes(
                  foundedContentControls,
                  documentName,
                  sharableDocName
                );
              })
              .then(response => {
                response.data.forEach(result => {
                  let findArticleIndex = articles.findIndex(val => val.id === result.article_id);
                  if (typeof articles[findArticleIndex] !== "undefined") {
                    articles[findArticleIndex].notes.push(result);
                    citedNotes.push(result);
                  }
                });
                citedNotes.forEach(note => {
                  let findArticleIndex = inlineArticles.findIndex(
                    val => val.content_control_id === note.content_control_id && val.article_id === note.article_id
                  );
                  if (findArticleIndex !== -1) {
                    inlineArticles[findArticleIndex].notes.push(note);
                  }
                });
                resolve({
                  articles: articles,
                  inlineArticles: inlineArticles,
                  citedNotes: citedNotes
                });
              })
              .catch(err => {
                console.log(err);
                reject(err);
              });
          });
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };

  updateContentControlTag = (citedIndexes, contentControls) => {
    return new Promise((resolve, reject) => {
      let contentControlsWithTag;
      Word.run(context => {
        // Create a proxy object for the content controls collection that contains a specific tag.
        contentControlsWithTag = context.document.contentControls;
        // Queue a command to load the tag property for all of content controls.
        context.load(contentControlsWithTag);

        return context.sync().then(() => {
          if (contentControlsWithTag.items.length > 0) {
            for (let i = 0; i < citedIndexes.length; i++) {
              contentControlsWithTag.items[citedIndexes[i]].tag = `${Number(contentControls[i])}`;
            }
          }
          return context.sync().then(() => {
            resolve(true);
          });
        });
      }).catch(error => {
        console.log("Error: " + JSON.stringify(error));
        if (error instanceof OfficeExtension.Error) {
          console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
        reject(error);
      });
    });
  };

  updateCopyCitation = (citedIndexes, contentControls, duplicateContentControls, documentName) => {
    return new Promise((resolve, reject) => {
      if (duplicateContentControls.length > 0) {
        let updatedContentControls;
        ReferenceArticles.updateCopyCitation({
          contentControls: contentControls,
          documentName: documentName,
          actualFileName: this.state.actualFileName
        })
          .then(response => {
            updatedContentControls = response.data;
            return this.updateContentControlTag(citedIndexes, updatedContentControls);
          })
          .then(() => {
            let resultantContentControls = [];
            updatedContentControls.forEach((updateContent, index) => {
              resultantContentControls.push(Number(updateContent));
            });
            resolve(resultantContentControls);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve(contentControls);
      }
    });
  };

  getCitedArticles = articles => {
    let citationData = { items: [] };
    articles.forEach(article => {
      let authors = [];
      if (article.authors !== null) {
        article.authors.split(";").forEach(authorResult => {
          let result = [],
            family;
          if (authorResult.includes(",")) {
            result = authorResult.split(",");
            family = result[result.length - 1].trim();
          } else {
            result = authorResult.split(" ");
            family = result[result.length - 1].trim();
          }
          result.pop();
          authors.push({
            family: family,
            given: result.join("").trim()
          });
        });
      }
      let finalAuthors = [];
      if (authors.length > 0) {
        finalAuthors = authors;
      } else {
        authors.push({ family: "et al.", given: "" });
        finalAuthors = authors;
      }
      let citationInputData = {
        id: article.id,
        type: article.doi_link ? "article-journal" : article.publication_type,
        title: article.title,
        "container-title": article.journal_name ? article.journal_name : "",
        page: article.pages,
        volume: article.volume,
        issue: article.issue,
        URL: article.article_url,
        DOI: article.doi_link,
        author: finalAuthors,
        issued: {
          "date-parts": [[article.publication_year]]
        }
      };
      if (article.doi_link === "" || article.doi_link === null) {
        delete citationInputData["DOI"];
      } else {
        delete citationInputData["URL"];
      }
      citationData.items.push(citationInputData);
    });
    let citations = {};
    let itemIDs = [];
    for (let i = 0, ilen = citationData.items.length; i < ilen; i++) {
      let item = citationData.items[i];
      if (!item.issued) continue;
      // if (item.URL) delete item.URL;
      let id = item.id;
      citations[id] = item;
      itemIDs.push(id);
    }
    return {
      citations: citations,
      itemIDs: itemIDs
    };
  };

  // Get with processor
  getProcessor = (styleID, citations) => {
    let citeprocSys = {
      retrieveLocale: function(lang) {
        // return "/assets/locales/locales-en-US.xml";
        let xhr = new XMLHttpRequest();
        xhr.open(
          "GET",
          // "https://raw.githubusercontent.com/Juris-M/citeproc-js-docs/master/locales-" + lang + ".xml",
          // http.BASE_URL + "assets/locales/locales-" + lang + ".xml",
          http.STYLE_URL + "locales/locales-" + lang + ".xml",
          false
        );
        xhr.send(null);
        return xhr.responseText;
      },
      retrieveItem: function(id) {
        // return citations[id];
        return citations[id];
        // return thisState.state.citations[id];
      }
    };
    // let styleAsText = "/assets/styles-distribution/" + styleID + ".csl";
    let xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      // "https://raw.githubusercontent.com/citation-style-language/styles/master/" + styleID + ".csl",
      // http.BASE_URL + "assets/styles-distribution/" + styleID + ".csl",
      http.STYLE_URL + "styles-distribution/" + styleID + ".csl",
      false
    );
    xhr.send(null);
    let styleAsText = xhr.responseText;
    let citeProcResult = new CSL.Engine(citeprocSys, styleAsText);
    return citeProcResult;
  };

  // Close modal
  closeModal = () => {
    this.setState({ showModal: false, showShareModal: false, showUpdateInfoModal: false, articleInfo: {} });
  };

  // Show modal
  showModalStatus = () => {
    this.setState({ showModal: true });
  };

  // Set styles for modals
  setModalStyle = () => {
    let theme = getTheme();
    let contentStyles = mergeStyleSets({
      container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch"
      },
      header: [
        // tslint:disable-next-line:deprecation
        theme.fonts.xLargePlus,
        {
          flex: "1 1 auto",
          borderTop: `4px solid ${theme.palette.themePrimary}`,
          color: theme.palette.neutralPrimary,
          display: "flex",
          fontSize: FontSizes.xLarge,
          alignItems: "center",
          fontWeight: FontWeights.semibold,
          padding: "12px 12px 14px 24px"
        }
      ],
      body: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        selectors: {
          p: {
            margin: "14px 0"
          },
          "p:first-child": {
            marginTop: 0
          },
          "p:last-child": {
            marginBottom: 0
          }
        }
      }
    });
    let iconButtonStyles = mergeStyleSets({
      root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px"
      },
      rootHovered: {
        color: theme.palette.neutralDark
      }
    });
    this.setState({ contentStyles: contentStyles, iconButtonStyles: iconButtonStyles });
  };

  // Share document references
  shareReference = () => {
    let generatedId =
      this.state.userInfo.group_info !== null ? this.state.userInfo.group_info.group_id : this.state.userInfo.id;
    let sharingEmail = "nxr-" + generatedId + "@nxref.com";
    let password = "nxr-" + generatedId;
    this.setState({ sharedEmail: sharingEmail, sharedPassword: password, showShareModal: true });
  };

  // Generate authors format
  generateAuthorsFormat = article => {
    if (Object.keys(article).length > 0 && article.authors !== null) {
      let authors = article.authors.split(";");
      if (authors.length > 0) {
        if (authors.length > 3) {
          return authors[0] + " ... " + authors[authors.length - 1];
        } else {
          return authors.join("; ");
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  // Set articles
  setArticlesValues = (event, article) => {
    event.persist();
    let selectedArticles = this.state.selectedArticles;
    let selectedNotes = this.state.selectedNotes;
    if (event.target.checked) {
      // console.log(this.state.citedTexts);
      selectedArticles.push(article);
    } else {
      let index = selectedArticles.findIndex(val => val.id === article.id);
      if (typeof selectedArticles[index].selectedNotes !== "undefined") {
        selectedNotes.forEach(note => {
          let noteIndex = selectedNotes.findIndex(val => val.id === note.id);
          selectedNotes.splice(noteIndex, 1);
        });
      }
      selectedArticles.splice(index, 1);
    }
    this.setState({ selectedArticles: selectedArticles, selectedNotes: this.state.selectedNotes });
  };

  // Set topic notes
  setTopicNotes = (notes, status) => {
    for (var i = 0; i < notes.length; i++)
      (t => {
        setTimeout(() => {
          let selectedNotes = this.state.selectedNotes;
          if (status) {
            let findIndex = this.state.selectedArticles.findIndex(val => val.id === notes[t].article_id);
            let findNoteIndex = this.state.selectedNotes.findIndex(val => val.id === notes[t].id);
            if (findNoteIndex === -1) {
              if (findIndex !== -1) {
                if (typeof this.state.selectedArticles[findIndex].selectedNotes !== "undefined") {
                  this.state.selectedArticles[findIndex].selectedNotes.push(notes[t]);
                } else {
                  let article = this.state.selectedArticles[findIndex];
                  article.selectedNotes = [];
                  article.selectedNotes.push(notes[t]);
                  this.setState({ selectedArticles: [...this.state.selectedArticles, article] });
                }
                this.setState({
                  selectedArticles: this.state.selectedArticles,
                  selectedNotes: [...this.state.selectedNotes, notes[t]]
                });
              } else {
                let article = Object.assign({}, notes[t].article);
                article.selectedNotes = [];
                article.selectedNotes.push(notes[t]);
                this.setState({
                  selectedArticles: [...this.state.selectedArticles, article],
                  selectedNotes: [...this.state.selectedNotes, notes[t]]
                });
              }
            }
          } else {
            let index = selectedNotes.findIndex(val => val.id === notes[t].id);
            selectedNotes.splice(index, 1);
            let articleIndex = this.state.selectedArticles.findIndex(val => val.id === notes[t].article_id);
            let findNoteIndex = this.state.selectedArticles[articleIndex].selectedNotes.findIndex(
              val => val.id === notes[t].id
            );
            this.state.selectedArticles[articleIndex].selectedNotes.splice(findNoteIndex, 1);
            if (this.state.selectedArticles[articleIndex].selectedNotes.length === 0) {
              this.state.selectedArticles.splice(articleIndex, 1);
            }
            this.setState({ selectedNotes: this.state.selectedNotes, selectedArticles: this.state.selectedArticles });
          }
        }, t * 500);
      })(i);
  };

  // Set articles notes
  setArticlesNotes = (event, note, fromTopic = false) => {
    event.persist();
    let selectedNotes = this.state.selectedNotes;
    if (event.target.checked) {
      let findIndex = this.state.selectedArticles.findIndex(val => val.id === note.article_id);
      if (findIndex !== -1) {
        if (typeof this.state.selectedArticles[findIndex].selectedNotes !== "undefined") {
          this.state.selectedArticles[findIndex].selectedNotes.push(note);
        } else {
          let article = this.state.selectedArticles[findIndex];
          article.selectedNotes = [];
          article.selectedNotes.push(note);
          this.setState({ selectedArticles: [...this.state.selectedArticles, article] });
        }
        this.setState({
          selectedArticles: this.state.selectedArticles,
          selectedNotes: [...this.state.selectedNotes, note]
        });
      } else {
        ArticleApi.getArticleById({
          article_id: note.article_id
        })
          .then(response => {
            let article = response.data;
            article.selectedNotes = [];
            article.selectedNotes.push(note);
            this.setState({
              selectedArticles: [...this.state.selectedArticles, article],
              selectedNotes: [...this.state.selectedNotes, note]
            });
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else {
      let index = selectedNotes.findIndex(val => val.id === note.id);
      selectedNotes.splice(index, 1);
      let articleIndex = this.state.selectedArticles.findIndex(val => val.id === note.article_id);
      let findNoteIndex = this.state.selectedArticles[articleIndex].selectedNotes.findIndex(val => val.id === note.id);
      this.state.selectedArticles[articleIndex].selectedNotes.splice(findNoteIndex, 1);
      if (fromTopic && this.state.selectedArticles[articleIndex].selectedNotes.length === 0) {
        this.state.selectedArticles.splice(articleIndex, 1);
      }
      this.setState({ selectedNotes: this.state.selectedNotes, selectedArticles: this.state.selectedArticles });
    }
  };

  // Update citation texts values
  updateCitations = () => {
    this.setState({ showCitationInsertBtn: true });
    this.awaitDeleteAndInsertCitedRecords()
      .then(() => {
        this.setState({ deletedCitedList: [], deletedCitatedNotesList: [] });
        return this.updateCitationDoc();
      })
      .then(() => {
        this.setState({ showCitationInsertBtn: false });
        toast.success("Reference updated successfully", { autoClose: 1000 });
      })
      .catch(err => {
        this.setState({ showCitationInsertBtn: false });
        console.log(err);
        toast.error("Something went wrong", { autoClose: 1000 });
      });
  };

  // Get reference articles
  getSelectedArticles = contentControlId => {
    let referenceArticles = [];
    let citedText = [];
    this.state.selectedArticles.map((article, index) => {
      // let docNameArr = this.state.documentUrl;
      let docName = this.state.documentUrl;
      let actualFileName = this.state.actualFileName;
      let articleCitationText = "";
      if (article.authors !== null) {
        let authors = article.authors.split(";");
        if (authors[0].includes(",")) {
          let firstAuthor = authors[0].split(",");
          articleCitationText = firstAuthor[firstAuthor.length - 1].trim() + " et al. " + article.publication_year;
        } else {
          let firstAuthor = authors[0].split(" ");
          articleCitationText = firstAuthor[firstAuthor.length - 1].trim() + " et al. " + article.publication_year;
        }
      } else {
        articleCitationText = "et al. " + article.publication_year;
      }
      referenceArticles.push({
        // document_url: docNameArr[docNameArr.length - 1],
        document_url: docName,
        actual_file_name: actualFileName,
        article_id: typeof article.id !== "undefined" ? article.id : 0,
        citation_text: articleCitationText,
        original_citation_text: articleCitationText,
        content_control_id: contentControlId,
        article: article
      });
      citedText.push(articleCitationText);
    });
    return { referenceArticles: referenceArticles, citedText: citedText.join(", ") };
  };

  // Get reference articles notes
  getSelectedArticlesNotes = contentControlId => {
    let referenceNotes = [];
    this.state.selectedNotes.map((note, index) => {
      // let docNameArr = this.state.documentUrl;
      let docName = this.state.documentUrl;
      let actualFileName = this.state.actualFileName;
      referenceNotes.push({
        // document_url: docNameArr[docNameArr.length - 1],
        document_url: docName,
        actual_file_name: actualFileName,
        content_control_id: contentControlId,
        article_id: note.article_id,
        note_id: note.id,
        selected_note: note.selected_note,
        downloadable_url: note.downloadable_url,
        will_paste: note.will_paste
      });
    });
    return referenceNotes;
  };

  // Insert or update to db
  insertCitationText = tag => {
    return new Promise((resolve, reject) => {
      ReferenceArticles.storeReferenceArticles({
        referenceArticles: this.getSelectedArticles(Number(tag)).referenceArticles
      })
        .then(response => {
          return ReferenceArticles.storeReferenceArticleNotes({
            referenceNotes: this.getSelectedArticlesNotes(Number(tag))
          });
        })
        .then(results => {
          this.setState({ selectedArticles: [], selectedNotes: [] });
          resolve(results);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  };

  // Insert new Content Control
  insertNewContentControl = (contentControlId, citedText, selectedNotes) => {
    return new Promise((resolve, reject) => {
      Word.run(context => {
        let insertText = "";
        selectedNotes.map((note, index) => {
          if (note.will_paste) {
            if (index === selectedNotes.length - 1) {
              insertText += note.selected_note;
            } else {
              insertText += note.selected_note + "<br>";
            }
          }
        });

        let doc = context.document;
        let originalRange = doc.getSelection();
        // Insert notes to document
        if (insertText !== "") {
          originalRange.insertHtml(` &ldquo;${insertText}&rdquo;`, "Before");
        }

        let startRange = doc.getSelection().getRange("End");
        let endRange = doc
          .getSelection()
          .paragraphs.getLast()
          .getRange("End");

        let deltaRange = startRange.expandTo(endRange);

        deltaRange.font.load();

        context.load(deltaRange);

        return context.sync().then(() => {
          let fontName = deltaRange.font.name;
          let fontSize = deltaRange.font.size;
          let contentControl = originalRange.insertContentControl();
          contentControl.appearance = Word.ContentControlAppearance.boundingBox;
          contentControl.tag = `${Number(contentControlId)}`;
          contentControl.cannotDelete = false;
          // contentControl.insertHtml(".", "End");
          contentControl.insertHtml(citedText, "End");
          contentControl.cannotEdit = false;
          context.load(contentControl);
          return context.sync().then(results => {
            this.setState({ selectedArticles: [], selectedNotes: [] });
            if (fontName) {
              deltaRange.font.name = fontName;
              deltaRange.font.size = fontSize;
            }
            resolve(results);
          });
        });
      }).catch(err => {
        if (err instanceof OfficeExtension.Error) {
          console.log("Error code and message 1: " + err.toString());
        }
        reject(err);
      });
    });
  };

  // Insert new citation with content control
  insertContentToDoc = async () => {
    return new Promise((resolve, reject) => {
      let contentControlIdResponse;
      let citedText;
      let selectedNotes;
      ReferenceArticles.getContentControlId()
        .then(response => {
          contentControlIdResponse = response;
          citedText = this.getSelectedArticles(Number(response.data)).citedText;
          return ReferenceArticles.storeReferenceArticles({
            referenceArticles: this.getSelectedArticles(Number(response.data)).referenceArticles
          });
        })
        .then(results => {
          selectedNotes = this.getSelectedArticlesNotes(Number(contentControlIdResponse.data));
          return ReferenceArticles.storeReferenceArticleNotes({
            referenceNotes: selectedNotes
          });
        })
        .then(results => {
          resolve(this.insertNewContentControl(contentControlIdResponse.data, citedText, selectedNotes));
        })
        .catch(err => {
          if (err instanceof OfficeExtension.Error) {
            console.log("Error code and message 2: " + err.toString());
          }
          reject(err);
        });
    });
  };

  // Update inline citation to doc
  updateCitationDoc = () => {
    this.getReferenseArticles()
      .then(allArticles => {
        let sortedArticles = this.sortArticles(allArticles.articles);
        let fileName = this.state.documentUrl;
        this.setState(
          {
            referenceArticles: sortedArticles.finalArticles,
            orderByArticles: allArticles.articles,
            inlineReferenceArticles: allArticles.inlineArticles,
            citedNotes: allArticles.citedNotes
          },
          () => {
            let inlineContentControlIds = [];
            let referencesStorage = JSON.parse(localStorage.getItem("_References"));
            allArticles.inlineArticles.map(inlineArticle => {
              let findIndex = inlineContentControlIds.findIndex(val => val === inlineArticle.content_control_id);
              if (findIndex === -1) {
                inlineContentControlIds.push(inlineArticle.content_control_id);
              }
            });
            if (referencesStorage) {
              let fileIndex = referencesStorage.findIndex(val => val.filename === fileName);
              if (fileIndex !== -1) {
                referencesStorage[fileIndex].citations = inlineContentControlIds;
              } else {
                referencesStorage.push({ filename: fileName, citations: inlineContentControlIds });
              }
              localStorage.setItem("_References", JSON.stringify(referencesStorage));
            } else {
              let newlyAddedFiles = [{ filename: fileName, citations: inlineContentControlIds }];
              localStorage.setItem("_References", JSON.stringify(newlyAddedFiles));
            }
            this.changeCitationDoc(sortedArticles.citeproc);
          }
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  getSortedRefList = citeRefList => {
    for (let i = 0; i < citeRefList.length; i++) {
      if (citeRefList[i].ambig !== "" && citeRefList[i].ambig !== null) {
        for (let j = i + 1; j < citeRefList.length; j++) {
          if (citeRefList[j].ambig.trim() === citeRefList[i].ambig.trim()) {
            if (typeof citeRefList[i].charSeq === "undefined") {
              citeRefList[i].charSeq = String.fromCharCode(97);
              citeRefList[j].charSeq = String.fromCharCode(98);
            } else {
              let getPreviousCharCode = citeRefList[i].charSeq.charCodeAt(0);
              citeRefList[j].charSeq = String.fromCharCode(getPreviousCharCode + 1);
            }
          }
        }
      }
    }
    return citeRefList;
  };

  // Change Citation Doc
  changeCitationDoc = citeproc => {
    let sortedArticles = this.sortArticles(this.state.orderByArticles);
    this.setReferenceArticles(sortedArticles.finalArticles);
    this.changeInlineCitationsAndUpdateDoc(citeproc);
  };

  // Get Formatted data
  getFormattedData = (citeproc, contentControlId) => {
    let sortedRefList = this.getSortedRefList(citeproc.registry.reflist);
    let foundedArticles = [];
    this.state.inlineReferenceArticles.map((article, index) => {
      if (article.content_control_id === contentControlId) {
        let findedArticleIndex = sortedRefList.findIndex(value => parseInt(value.id) === article.article_id);
        if (findedArticleIndex !== -1) {
          foundedArticles.push({
            ...sortedRefList[findedArticleIndex],
            content_control_id: article.content_control_id,
            custom_citation_text: article.custom_citation_text
          });
        }
      }
    });
    foundedArticles.sort((a, b) => {
      return a.seq - b.seq;
    });
    let isCiteNumber = false;
    let finallyFoundedArticlesResult = [];
    foundedArticles.forEach(foundResult => {
      let checkSeq = typeof foundResult.charSeq !== "undefined" ? foundResult.charSeq : "";
      if (foundResult.ambig !== "") {
        this.setState({ citationIsNumberFormat: false });
        if (foundResult.custom_citation_text !== null && foundResult.custom_citation_text !== "") {
          finallyFoundedArticlesResult.push(foundResult.custom_citation_text);
        } else {
          finallyFoundedArticlesResult.push(foundResult.ambig + checkSeq);
        }
      } else {
        this.setState({ citationIsNumberFormat: true });
        isCiteNumber = true;
        finallyFoundedArticlesResult.push(foundResult.seq);
      }
    });
    let citationOpt = citeproc.citation.opt;
    let citedResult = null;
    if (citationOpt.layout_decorations.length < 1) {
      if (isCiteNumber) {
        citedResult =
          citationOpt.layout_prefix +
          this.checkIfNumbersAreSequential(
            finallyFoundedArticlesResult.join(citationOpt.layout_delimiter),
            citationOpt.layout_delimiter
          ) +
          citationOpt.layout_suffix;
      } else {
        citedResult =
          citationOpt.layout_prefix +
          finallyFoundedArticlesResult.join(citationOpt.layout_delimiter).trim() +
          citationOpt.layout_suffix;
      }
    } else {
      if (citationOpt.layout_prefix !== "" && citationOpt.layout_suffix !== "") {
        citedResult =
          "<" +
          citationOpt.layout_decorations[0][citationOpt.layout_decorations[0].length - 1] +
          ">" +
          citationOpt.layout_prefix +
          this.checkIfNumbersAreSequential(
            finallyFoundedArticlesResult.join(citationOpt.layout_delimiter),
            citationOpt.layout_delimiter
          ) +
          citationOpt.layout_suffix +
          "</" +
          citationOpt.layout_decorations[0][citationOpt.layout_decorations[0].length - 1] +
          ">";
      } else {
        citedResult =
          "<sup>" +
          this.checkIfNumbersAreSequential(
            finallyFoundedArticlesResult.join(citationOpt.layout_delimiter),
            citationOpt.layout_delimiter
          ) +
          "</sup>";
      }
    }
    return {
      finallyFoundedArticlesResult: finallyFoundedArticlesResult,
      citedResult: citedResult
    };
  };

  // Get formatted reference list
  getFormattedReferenceList = citeproc => {
    let result = citeproc.makeBibliography();
    let referenceArr = [];
    if (result) {
      for (let index = 0; index < result[1].length; index++) {
        let xmlString = result[1][index];
        let domString = new DOMParser().parseFromString(xmlString, "text/html");
        // let elementsArr = domString.getElementsByTagName("div");
        let elementsArr = domString.getElementsByClassName("csl-entry");
        if (elementsArr[0].firstElementChild) {
          if (elementsArr[0].firstElementChild.classList.contains("csl-left-margin")) {
            referenceArr.push(
              elementsArr[0].firstElementChild.textContent +
                " " +
                elementsArr[0].childNodes[elementsArr[0].childNodes.length - 2].innerHTML
            );
          } else {
            referenceArr.push(elementsArr[0].innerHTML);
          }
        } else {
          referenceArr.push(elementsArr[0].innerHTML);
        }
      }
    }
    return referenceArr;
  };

  // Change inline citations
  changeInlineCitationsAndUpdateDoc = citeproc => {
    Word.run(context => {
      // Create a proxy object for the content controls collection that contains a specific tag.
      let contentControlsWithTag = context.document.contentControls;
      // Queue a command to load the tag property for all of content controls.
      context.load(contentControlsWithTag);
      // Synchronize the document state by executing the queued commands,
      // and return a promise to indicate task completion.
      return context.sync().then(() => {
        if (contentControlsWithTag.items.length === 0) {
          console.log("No reference articles found.");
        } else {
          let referenceArr = this.getFormattedReferenceList(citeproc);
          for (let i = 0; i < contentControlsWithTag.items.length; i++) {
            // Replace inline citation doc
            if (contentControlsWithTag.items[i].tag !== "reference-citations") {
              if (contentControlsWithTag.items[i].tag.includes("110120")) {
                contentControlsWithTag.items[i].title = "nXr.iCite citation";
                let formattedResult = this.getFormattedData(citeproc, contentControlsWithTag.items[i].tag);
                if (formattedResult.finallyFoundedArticlesResult.length > 0) {
                  contentControlsWithTag.items[i].cannotEdit = false;
                  contentControlsWithTag.items[i].insertHtml(formattedResult.citedResult, "Replace");
                  // contentControlsWithTag.items[i].cannotEdit = true;
                  contentControlsWithTag.items[i].cannotDelete = false;
                } else {
                  contentControlsWithTag.items[i].cannotDelete = false;
                  contentControlsWithTag.items[i].delete(false);
                }
              }
            } else if (contentControlsWithTag.items[i].tag === "reference-citations") {
              if (referenceArr.length > 0) {
                contentControlsWithTag.items[i].insertHtml(referenceArr.join("<br>"), "Replace");
              } else {
                contentControlsWithTag.items[i].delete(false);
              }
            }
          }

          return context.sync().then(() => {
            console.log("Updated");
          });
        }
      });
    }).catch(err => {
      console.log(err);
    });
  };

  // Check sequential numbers
  checkIfNumbersAreSequential = (numbers, delimiter) => {
    let result = "";
    let numbersArr = numbers.split(delimiter);
    let arr = [];
    for (let index = 0; index < numbersArr.length; index++) {
      arr[index] = parseInt(numbersArr[index]);
    }
    let start, end;
    end = start = arr[0];
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] == arr[i - 1] + 1) {
        end = arr[i];
      } else {
        if (start == end) {
          result += start + delimiter;
        } else {
          result += start + "-" + end + delimiter;
        }
        start = end = arr[i];
      }
    }
    if (start == end) {
      result += start;
    } else {
      result += start + "-" + end;
    }

    // second part
    let resultArr = result.split(delimiter);
    let finalArr = [];
    for (let j = 0; j < resultArr.length; j++) {
      let anotherArr = resultArr[j].split("-");
      if (anotherArr.length > 1) {
        let diff = parseInt(anotherArr[1]) - parseInt(anotherArr[0]);
        if (diff > 1) {
          finalArr.push(anotherArr[0] + "-" + anotherArr[1]);
        } else {
          finalArr.push(anotherArr[0] + delimiter + anotherArr[1]);
        }
      } else {
        finalArr.push(resultArr[j]);
      }
    }
    // return result;
    return finalArr.join(delimiter);
  };

  // Delete citated note
  deleteCitatedNote = (note, index, noteIndex, isImage) => {
    this.setState({ deletedCitatedNotesList: [...this.state.deletedCitatedNotesList, note] }, () => {
      this.state.citedTexts[index].notes.splice(noteIndex, 1);
      this.setState({ citedTexts: this.state.citedTexts });
    });
  };

  getContentControlById = contentControlId => {
    return new Promise((resolve, reject) => {
      Word.run(context => {
        // Create a proxy object for the content control that contains a specific id.
        let contentControl = context.document.contentControls.getByIdOrNullObject(contentControlId);

        // Queue a command to load the text property for a content control.
        context.load(contentControl);

        // Synchronize the document state by executing the queued commands,
        // and return a promise to indicate task completion.
        return context.sync().then(() => {
          if (contentControl.isNullObject) {
            resolve(null);
          } else {
            console.log("TAgg ", contentControl.tag);
            resolve(contentControl.tag);
          }
        });
      }).catch(function(error) {
        console.log("Error: " + JSON.stringify(error));
        if (error instanceof OfficeExtension.Error) {
          console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
        reject(error);
      });
    });
  };

  // Update contentcontrols before insert record
  updateContentControls = contentControlId => {
    return new Promise((resolve, reject) => {
      Word.run(context => {
        // Create a proxy object for the content controls collection that contains a specific tag.
        let contentControlItems = context.document.contentControls;
        // Queue a command to load the tag property for all of content controls.
        context.load(contentControlItems);

        return context
          .sync()
          .then(() => {
            let documentName = this.state.documentUrl;
            let citedIndexes = [];
            let contentControls = [];
            let duplicateCitedItems = [];
            for (let i = 0; i < contentControlItems.items.length; i++) {
              if (contentControlItems.items[i].tag !== "reference-citations") {
                if (contentControlItems.items[i].tag.includes("110120")) {
                  let contentControlId = isNaN(Number(contentControlItems.items[i].tag))
                    ? contentControlItems.items[i].id
                    : Number(contentControlItems.items[i].tag);

                  let findContentControlIndex = contentControls.findIndex(val => val === contentControlId);

                  if (findContentControlIndex !== -1) {
                    duplicateCitedItems.push(contentControlId);
                  }

                  citedIndexes.push(i);

                  contentControls.push(contentControlId);
                }
              }
            }

            return this.updateCopyCitation(citedIndexes, contentControls, duplicateCitedItems, documentName);
          })
          .then(foundedContentControls => {
            resolve(this.getContentControlById(contentControlId));
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      }).catch(error => {
        console.log("Error: " + JSON.stringify(error));
        if (error instanceof OfficeExtension.Error) {
          console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
        reject(error);
      });
    });
  };

  // Check all length by content control tag
  getContentControlByTag = (tag, contentControlId) => {
    return new Promise((resolve, reject) => {
      Word.run(context => {
        let contentControls = context.document.contentControls.getByTag(tag);
        contentControls.load("text");
        return context.sync().then(() => {
          if (contentControls.items.length > 1) {
            // Update doc file
            resolve(this.updateContentControls(contentControlId));
          } else {
            // Do nothing
            resolve(tag);
          }
        });
      }).catch(err => {
        console.log(err);
        reject(err);
      });
    });
  };

  // Delete all cited records
  awaitDeleteAndInsertCitedRecords = () => {
    return new Promise((resolve, reject) => {
      let citatedNotes = [];
      let documentName = this.state.documentUrl;
      let contentControlUpdatedTag;
      Word.run(context => {
        let myContentControl = context.document.getSelection().parentContentControl;
        context.load(myContentControl);
        return context.sync().then(() => {
          this.getContentControlByTag(myContentControl.tag, myContentControl.id)
            .then(result => {
              contentControlUpdatedTag = result;
              let deletedCitedList = [];
              this.state.deletedCitedList.forEach(citedList => {
                citedList.content_control_id = result;
                deletedCitedList.push(citedList);
                citedList.notes.forEach(note => {
                  note.content_control_id = result;
                  citatedNotes.push(note);
                });
              });
              this.state.deletedCitatedNotesList.map((citationNote, index) => {
                citationNote.content_control_id = result;
                citatedNotes.push(citationNote);
              });
              return ReferenceArticles.deleteReferenceArticles({
                citations: deletedCitedList,
                documentName: documentName,
                sharableFileName: this.state.forSharebleName
              });
            })
            .then(response => {
              return ReferenceArticles.deleteReferenceArticlesNotes({
                citedNotes: citatedNotes,
                documentName: documentName,
                sharableFileName: this.state.forSharebleName
              });
            })
            .then(response => {
              if (this.state.selectedArticles.length > 0) {
                return this.insertCitationText(contentControlUpdatedTag);
              } else {
                return null;
              }
            })
            .then(response => {
              resolve(response);
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        });
      }).catch(err => {
        resolve(this.insertContentToDoc());
      });
    });
  };

  // Delete new added citation
  deleteNewAddedCitation = article => {
    let findIndex;
    if (typeof article.id !== "undefined") {
      findIndex = this.state.selectedArticles.findIndex(val => val.id === article.id);
    } else {
      findIndex = this.state.selectedArticles.findIndex(val => val.article_url === article.article_url);
    }
    if (findIndex !== -1) {
      if (typeof this.state.selectedArticles[findIndex].selectedNotes !== "undefined") {
        this.state.selectedArticles[findIndex].selectedNotes.forEach(note => {
          let noteIndex = this.state.selectedNotes.findIndex(val => val.id === note.id);
          if (noteIndex !== -1) {
            this.state.selectedNotes.splice(noteIndex, 1);
          }
        });
      }
      this.state.selectedArticles.splice(findIndex, 1);
    }
    this.setState({ selectedArticles: this.state.selectedArticles, selectedNotes: this.state.selectedNotes });
  };

  // Delete citation data
  deleteCitationData = citationData => {
    this.setState({ deletedCitedList: [...this.state.deletedCitedList, citationData] }, () => {
      let findIndex = this.state.citedTexts.findIndex(val => val.id === citationData.id);
      if (findIndex !== -1) {
        this.state.citedTexts.splice(findIndex, 1);
      }
      this.setState({ citedTexts: this.state.citedTexts });
    });
  };

  // Go to article url
  goToArticleUrlFromNote = note => {
    window.open(note.article_url, "_blank");
  };

  // Update Article Information
  updateArticleInfo = article => {
    this.setState({ showUpdateInfoModal: true, articleInfo: article });
  };

  // Redirect to the url
  goToUrl = url => {
    window.open(url, "_blank");
  };

  updateCustomNoteText = _.debounce((event, note, customNote) => {
    note.user_custom_note = customNote;
    Note.updateNoteRecord(note)
      .then(response => {
        console.log(response);
      })
      .catch(err => {
        console.log(err);
      });
  }, 1000);

  // Update user custom note
  updateUserCustomNote = (event, note) => {
    event.persist();

    this.updateCustomNoteText(event, note, event.target.value);
  };

  render() {
    return (
      <HomeContext.Provider
        value={{
          ...this.state,
          showModalStatus: this.showModalStatus,
          closeModal: this.closeModal,
          generateAuthorsFormat: this.generateAuthorsFormat,
          setArticlesValues: this.setArticlesValues,
          updateCitations: this.updateCitations,
          deleteNewAddedCitation: this.deleteNewAddedCitation,
          deleteCitationData: this.deleteCitationData,
          setArticlesNotes: this.setArticlesNotes,
          setTopicNotes: this.setTopicNotes,
          deleteCitatedNote: this.deleteCitatedNote,
          goToArticleUrlFromNote: this.goToArticleUrlFromNote,
          shareReference: this.shareReference,
          setUserInformation: this.setUserInformation,
          setDocumentFileName: this.setDocumentFileName,
          updateReferenceArticlesForNewFile: this.updateReferenceArticlesForNewFile,
          updateArticleInfo: this.updateArticleInfo,
          getCiteProc: this.getCiteProc,
          addOrUpdateUserCitationStyle: this.addOrUpdateUserCitationStyle,
          changeCitationDoc: this.changeCitationDoc,
          goToUrl: this.goToUrl,
          updateCitationDoc: this.updateCitationDoc,
          getCitationTextFromDbByContentControlId: this.getCitationTextFromDbByContentControlId,
          toggleArticleNotes: this.toggleArticleNotes,
          updateUserCustomNote: this.updateUserCustomNote
        }}
      >
        {this.props.children}
      </HomeContext.Provider>
    );
  }
}

export default HomeContextProvider;
