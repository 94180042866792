import Api from "./Api";

export default {
  getUserTopics(pageNumber, pageLimit, searchFor, searchText) {
    return Api().get("/getUserTopics", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        searchFor: searchFor,
        searchText: searchText
      }
    });
  }
};
