import * as React from "react";
import { Button, ButtonType, Stack, IStackProps, TextField, PrimaryButton } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import Header from "../Header";
import HeroList from "../HeroList";
import { http } from "../../utils/http";

toast.configure();

export default class Login extends React.Component {
  // Go to url
  goToUrl = url => {
    window.open(url, "blank");
  };

  render() {
    return (
      <div>
        <Header
          title={this.props.title}
          message="Welcome to"
          logo={http.BASE_URL + "assets/logo-filled.png"}
          title2={this.props.title2}
          title3={this.props.title3}
        />
        <HeroList>
          <Stack horizontal tokens={{ childrenGap: 0 }} className="app-info-div">
            <p className="" style={{ marginTop: "0px" }}>
              <span className="app-info">Write quotation/citation error-free research papers faster with</span>
              <span className="app-info">quote/image searchable citation tool, citation based on quotes/images,</span>
              <span className="app-info">search and cite, and more.</span>
              <span className="app-info">
                Learn more at{" "}
                <button className="app-info-link" onClick={() => this.goToUrl("https://www.nxref.com")}>
                  www.nxref.com
                </button>
              </span>
            </p>
            <hr />
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 0 }} style={{ marginTop: "20px" }}>
            <form onSubmit={event => this.props.login(event)} className="welcome-login-form">
              <div className="welcome-login-full">
                <div className="welcome-input-box">
                  <input type="email" placeholder="Email" name="email" />
                </div>
                <div className="welcome-input-box">
                  <input type="password" placeholder="Password" name="password" />
                </div>
                <div className="forgot">
                  <button type="button" onClick={() => this.goToUrl("https://www.nxref.com/lib/resetpassword")}>
                    Forgot Password?
                  </button>
                </div>
                <div className="submit">
                  <button type="submit" style={{ width: "100%" }}>
                    Login
                  </button>
                </div>
                <div className="no-account">
                  Dont have an account?{" "}
                  <button
                    type="button"
                    className="signup"
                    onClick={() => this.goToUrl("https://www.nxref.com/lib/login")}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </form>
          </Stack>
        </HeroList>
      </div>
    );
  }
}
