import React, { useState } from "react";

import { http } from "../../utils/http";
import UpdateCitation from "./UpdateCitation";

const CitationList = ({
  index,
  citationText,
  citationNotes,
  citatedArticle,
  isNxr,
  fileName,
  deleteCitationData,
  deleteCitatedNote,
  toggleDiv,
  title,
  pdfDownloaded,
  showIcon,
  articleUrl
}) => {
  const [showCitationModal, setShowCitationModal] = useState(false);
  const [error, setError] = useState({ isError: false });
  const [citeArticle, setCiteArticle] = useState({});

  const goToPdfUrl = citatedArticle => {
    const pdfUrl = http.LIBRARY_URL + "viewer/" + citatedArticle.article_id + "?from=icite&filename=" + fileName;
    window.open(pdfUrl, "_blank");
  };

  const setCitationData = citatedArticle => {
    setError({ isError: false });
    setCiteArticle(citatedArticle);
    setShowCitationModal(true);
  };

  const closeCitationModal = () => {
    setShowCitationModal(false);
  };

  const setCitationPageType = (event, value) => {
    event.persist();
    setCiteArticle({ ...citeArticle, pages_type: value });
  };

  const setCitationLocation = (event, value) => {
    event.persist();
    setCiteArticle({ ...citeArticle, citation_location: value });
  };

  const setCitationRole = (event, value) => {
    event.persist();
    setCiteArticle({ ...citeArticle, citation_role: value });
  };

  const clearCitationLocation = () => {
    setCiteArticle({ ...citeArticle, citation_location: null, citation_role: null });
  };

  const isErrorOccured = status => {
    setError({ isError: status });
  };

  return (
    <div className="single-accordion">
      <button
        className={citationNotes.length > 0 ? "accordion" : "accordion acc-no-image"}
        onClick={e => toggleDiv(e)}
        title={title}
      >
        <a rel="noopener noreferrer" href={articleUrl} target="_blank">
          {citationText}
        </a>
        <span className="close-it">
          {showIcon ? (
            <span>
              {pdfDownloaded === 1 ? (
                <img
                  src={http.BASE_URL + "assets/pdf.png"}
                  onClick={() => goToPdfUrl(citatedArticle)}
                  alt=""
                  style={{ marginRight: "10px" }}
                  title="View PDF"
                />
              ) : (
                <img
                  src={http.BASE_URL + "assets/pdf-download-failed.png"}
                  alt=""
                  style={{ marginRight: "10px" }}
                  title="View PDF"
                />
              )}
              <img
                src={http.BASE_URL + "assets/request.png"}
                onClick={() => setCitationData(citatedArticle)}
                alt=""
                style={{ marginRight: "10px" }}
                title="Citation Settings"
              />
            </span>
          ) : null}
          {isNxr === 1 ? (
            <img
              src={http.BASE_URL + "assets/close.png"}
              alt=""
              onClick={() => deleteCitationData(citatedArticle)}
              title="Delete citation"
            />
          ) : null}
        </span>
      </button>
      <div className="panel">
        {citationNotes.length > 0 ? (
          <ul>
            {citationNotes.map((note, noteIndex) => {
              return note.selected_note !== "" && note.selected_note !== null ? (
                <li key={noteIndex}>
                  {isNxr === 1 ? (
                    <button
                      className="accordion-close"
                      type="button"
                      onClick={() => deleteCitatedNote(note, index, noteIndex, 0)}
                    >
                      X
                    </button>
                  ) : null}
                  <p className="aa-p-text">{note.selected_note}</p>
                </li>
              ) : (
                <li key={noteIndex}>
                  {isNxr === 1 ? (
                    <button
                      className="accordion-close"
                      type="button"
                      onClick={() => deleteCitatedNote(note, index, noteIndex, 1)}
                    >
                      X
                    </button>
                  ) : null}
                  <img src={note.downloadable_url} alt="Image" />
                </li>
              );
            })}
          </ul>
        ) : null}
        <UpdateCitation
          citeArticle={citeArticle}
          showCitationModal={showCitationModal}
          closeCitationModal={closeCitationModal}
          setCitationLocation={setCitationLocation}
          setCitationRole={setCitationRole}
          setCitationPageType={setCitationPageType}
          clearCitationLocation={clearCitationLocation}
          isError={error.isError}
          isErrorOccured={isErrorOccured}
        />
      </div>
    </div>
  );
};

export default CitationList;
