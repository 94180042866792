import axios from "axios";
import { http } from "../utils/http";

let BaseApi = axios.create({
  baseURL: http.API_URL
});

let Api = () => {
  let token = localStorage.getItem("token");

  if (token) {
    BaseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  return BaseApi;
};

export default Api;
