import Api from "./Api";

export default {
  getArticlesBySearch(pageNumber, pageLimit, readItLater, searchType, searchText, searchFor, labels) {
    return Api().get("/getArticlesBySearch", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        readItLater: readItLater,
        searchType: searchType,
        searchText: searchText,
        searchFor: searchFor,
        labels: labels
      }
    });
  },

  getGroupArticlesBySearch(pageNumber, pageLimit, readItLater, searchType, searchText, searchFor, labels) {
    return Api().get("/getGroupArticlesBySearch", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        readItLater: readItLater,
        searchType: searchType,
        searchText: searchText,
        searchFor: searchFor,
        labels: labels
      }
    });
  },

  getUserNotesBySearch(pageNumber, pageLimit, searchType, searchText, type, searchFor, labels) {
    return Api().get("/getUserNotesBySearch", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        searchType: searchType,
        searchText: searchText,
        type: type,
        searchFor: searchFor,
        labels: labels
      }
    });
  },

  getUserGroupNotesBySearch(pageNumber, pageLimit, searchType, searchText, type, searchFor, labels) {
    return Api().get("/getUserGroupNotesBySearch", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        searchType: searchType,
        searchText: searchText,
        type: type,
        searchFor: searchFor,
        labels: labels
      }
    });
  }
};
