import React, { useState, useContext } from "react";
import { IconButton, Modal } from "office-ui-fabric-react";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";

const Topic = ({
  topic,
  pageIndex,
  topicIndex,
  activeOrInactiveTopicNotesPasteState,
  activeInactiveWholeTopicStatus
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalNote, setModalNote] = useState({});

  const context = useContext(HomeContext);

  // Check note is selected or not
  const checkNoteIsSelected = (citedTexts, note) => {
    let findIndex = citedTexts.findIndex(val => val.article_id === note.article_id);
    if (findIndex !== -1) {
      let noteIndex = citedTexts[findIndex].notes.findIndex(val => val.note_id === note.id);
      if (noteIndex !== -1) {
        return true;
      }
    }
    return false;
  };

  const goToPdfUrl = article => {
    let fileName = context.userInfo.nxr_user === 1 ? context.documentUrl : context.forSharebleName;
    const pdfUrl = http.LIBRARY_URL + "viewer/" + article.id + "?from=icite&filename=" + fileName;
    window.open(pdfUrl, "_blank");
  };

  const activeInactiveTopicStatus = (pageIndex, topicIndex, status) => {
    context.setTopicNotes(topic.notes, status);
    activeInactiveWholeTopicStatus(pageIndex, topicIndex, status);
  };

  // Set modal status
  const setModalStatus = note => {
    setModalNote(note);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getUrlExtension = url => {
    try {
      return url.match(/^https?:\/\/.*[\\\/][^\?#]*\.([a-zA-Z0-9]+)\??#?/)[1];
    } catch (ignored) {
      return false;
    }
  };

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{topic.topic}</div>
        <div style={{ display: "flex" }}>
          {topic.will_topic_paste ? (
            <img
              src={http.BASE_URL + "assets/paste-active.svg"}
              className="paste-icon"
              style={{ marginRight: "12px" }}
              onClick={() => activeInactiveTopicStatus(pageIndex, topicIndex, false)}
            />
          ) : (
            <img
              src={http.BASE_URL + "assets/paste-inactive.svg"}
              className="paste-icon"
              style={{ marginRight: "12px" }}
              onClick={() => activeInactiveTopicStatus(pageIndex, topicIndex, true)}
            />
          )}
          {isActive ? "-" : "+"}
        </div>
      </div>
      {isActive && (
        <div className="accordion-content">
          {topic.notes.map((note, noteIndex) => {
            const ext = getUrlExtension(note.downloadable_url);
            return note.selected_note ? (
              <div key={noteIndex} className="single-notes">
                <div className="notes-div">
                  <input
                    type="checkbox"
                    value={note.id}
                    checked={context.selectedNotes.findIndex(val => val.id === note.id) !== -1}
                    onChange={event => context.setArticlesNotes(event, note, true)}
                    disabled={checkNoteIsSelected(context.citedTexts, note)}
                  />
                  {context.pasteOptionEnable ? (
                    note.will_paste ? (
                      <img
                        src={http.BASE_URL + "assets/paste-active.svg"}
                        className="paste-icon"
                        onClick={() => activeOrInactiveTopicNotesPasteState(pageIndex, topicIndex, noteIndex, false)}
                      />
                    ) : (
                      <img
                        src={http.BASE_URL + "assets/paste-inactive.svg"}
                        className="paste-icon"
                        onClick={() => activeOrInactiveTopicNotesPasteState(pageIndex, topicIndex, noteIndex, true)}
                      />
                    )
                  ) : (
                    <img src={http.BASE_URL + "assets/paste-inactive.svg"} className="paste-icon-dpl" />
                  )}
                </div>
                <a href="#0" className="text" onClick={() => setModalStatus(note)}>
                  {note.selected_note}
                </a>
                {note.article.article_downloaded === 1 ? (
                  <a href="#0" onClick={() => goToPdfUrl(note.article)} className="external" style={{ right: "35px" }}>
                    <img src={http.BASE_URL + "assets/pdf.png"} alt="" />
                  </a>
                ) : null}
                <a href="#0" onClick={() => context.goToArticleUrlFromNote(note.article)} className="external">
                  <img src={http.BASE_URL + "assets/link.png"} alt="" />
                </a>
              </div>
            ) : (
              <div key={noteIndex} className="single-imgages">
                <input
                  type="checkbox"
                  value={note.id}
                  checked={context.selectedNotes.findIndex(val => val.id === note.id) !== -1}
                  onChange={event => context.setArticlesNotes(event, note, true)}
                  disabled={checkNoteIsSelected(context.citedTexts, note)}
                />
                {ext && ext === "svgz" ? (
                  <img src={note.selected_figure} alt="" onClick={() => setModalStatus(note)} />
                ) : (
                  <img src={note.downloadable_url} alt="" onClick={() => setModalStatus(note)} />
                )}
                {note.article.article_downloaded === 1 ? (
                  <a href="#0" onClick={() => goToPdfUrl(note.article)} className="external" style={{ right: "35px" }}>
                    <img src={http.BASE_URL + "assets/pdf.png"} alt="" />
                  </a>
                ) : null}
                <a href="#0" onClick={() => context.goToArticleUrlFromNote(note.article)} className="external">
                  <img src={http.BASE_URL + "assets/link.png"} alt="" />
                </a>
              </div>
            );
          })}
        </div>
      )}
      {context.contentStyles != null && context.iconButtonStyles != null && Object.keys(modalNote).length > 0 ? (
        <Modal
          className="notes-modal"
          titleAriaId="title"
          subtitleAriaId="subtext"
          isOpen={showModal}
          onDismiss={closeModal}
          isBlocking={false}
          containerClassName={context.contentStyles.container}
        >
          <div className="">
            <h4 className="title">
              <span className="inner" id="title">
                Quote
              </span>
            </h4>
            <IconButton
              className="close-btn"
              styles={context.iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={() => closeModal()}
            />
          </div>
          <div className="img-info">
            {modalNote.selected_note ? (
              <p className="text">{modalNote.selected_note}</p>
            ) : (
              <img src={modalNote.selected_figure} alt="" />
            )}
          </div>
          <div className="input-text-info">
            <textarea
              type="text"
              disabled={modalNote.user_id !== context.userInfo.id}
              defaultValue={modalNote.user_custom_note}
              placeholder="Write tags/notes"
              onKeyUp={e => context.updateUserCustomNote(e, modalNote)}
            ></textarea>
          </div>
          <div id="subtext" className="">
            <h4 className="title">
              <span className="inner">Article Information</span>
            </h4>
            <p>{modalNote.article.title}</p>
            <p style={{ fontSize: "12px" }}>
              {context.generateAuthorsFormat(modalNote.article) +
                "-" +
                modalNote.article.journal_name +
                " - " +
                modalNote.article.publication_year}
            </p>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Topic;
