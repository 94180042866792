import React, { useContext, useState } from "react";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";

const Articles = ({ pages, articleNotes, annotateBibliography }) => {
  const context = useContext(HomeContext);

  const goToPdfUrl = article => {
    let fileName = context.userInfo.nxr_user === 1 ? context.documentUrl : context.forSharebleName;
    const pdfUrl = http.LIBRARY_URL + "viewer/" + article.id + "?from=icite&filename=" + fileName;
    window.open(pdfUrl, "_blank");
  };

  const articleList = pages.map((page, index) => {
    return (
      <div key={index}>
        {page.pageNumber > 1 ? <div className="separator">Page {page.pageNumber}</div> : null}
        {page.articles.map((article, articleIndex) => {
          return (
            <div key={articleIndex} className="single-article">
              <p className="info">
                <input
                  type="checkbox"
                  checked={context.selectedArticles.findIndex(val => val.id === article.id) !== -1}
                  value={article.id}
                  onChange={event => context.setArticlesValues(event, article)}
                  disabled={context.citedTexts.findIndex(val => val.article_id === article.id) !== -1}
                />
                {article.article_downloaded === 1 ? (
                  <a href="#0" onClick={() => goToPdfUrl(article)} className="external-link" style={{ right: "68px" }}>
                    <img src={http.BASE_URL + "assets/pdf.png"} alt="" style={{ height: "auto" }} />
                  </a>
                ) : null}
                {article.annotation ? (
                  <a
                    className="external-link"
                    href="#0"
                    onClick={() => annotateBibliography(article)}
                    title="Notes for annotated bibliography"
                    style={{ right: "45px" }}
                  >
                    <img src={http.BASE_URL + "assets/annotate-bib-active.svg"} style={{ height: "18px" }} alt="" />
                  </a>
                ) : null}
                <a
                  className="external-link"
                  href="#0"
                  onClick={() => articleNotes(article)}
                  title="Article notes/images"
                >
                  <img src={http.BASE_URL + "assets/articale-edit.png"} style={{ height: "18px" }} alt="" />
                  <span className="count">{article.notes.length}</span>
                </a>
              </p>
              <a href="#0" className="text" onClick={() => context.goToUrl(article.article_url)}>
                {article.title}
              </a>
              <p className="info">
                {context.generateAuthorsFormat(article) +
                  " - " +
                  article.journal_name +
                  " - " +
                  article.publication_year}
                {/* <a className="external-link" href="#0" onClick={() => context.goToUrl(articleUrl)}>
                  <img src={http.BASE_URL + "assets/link.png"} alt="" />
                </a> */}
              </p>
            </div>
          );
        })}
      </div>
    );
  });
  return articleList;
};

export default Articles;
