import * as React from "react";
import { toast } from "react-toastify";
import Progress from "./Progress";
import Login from "./Login/Login";
import Home from "./Home/Home";
import HomeContextProvider from "../contexts/HomeContext";

import { http } from "../utils/http";
import User from "../api/User";
import ReferenceArticles from "../api/ReferenceArticles";

toast.configure();

export default class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      user: null,
      loggedIn: false,
      isNxrUser: false,
      filterBy: {},
      userInfo: {},
      userFullInformation: {},
      referenceStyle: "apa"
    };
  }

  componentDidMount() {
    let token = localStorage.getItem("token");

    if (token) {
      this.setState({ loggedIn: true });
    }
  }

  // Check login status
  checkLoginStatusWithContentControl = () => {
    return new Promise((resolve, reject) => {
      let contentControlsWithTag;
      let contentControls = [];
      let duplicateCitedItems = [];
      let citedIndexes = [];
      Word.run(context => {
        // Create a proxy object for the content controls collection that contains a specific tag.
        contentControlsWithTag = context.document.contentControls;
        // Queue a command to load the tag property for all of content controls.
        context.load(contentControlsWithTag);

        return context.sync();
      })
        .then(() => {
          if (contentControlsWithTag.items.length === 0) {
            return 1;
          } else {
            for (let i = 0; i < contentControlsWithTag.items.length; i++) {
              if (contentControlsWithTag.items[i].tag !== "reference-citations") {
                if (contentControlsWithTag.items[i].tag.includes("110120")) {
                  let contentControlId = isNaN(Number(contentControlsWithTag.items[i].tag))
                    ? contentControlsWithTag.items[i].id
                    : Number(contentControlsWithTag.items[i].tag);

                  let findContentControlIndex = contentControls.findIndex(val => val === contentControlId);

                  if (findContentControlIndex !== -1) {
                    duplicateCitedItems.push(contentControlId);
                  }

                  citedIndexes.push(i);

                  contentControls.push(contentControlId);
                }
              }
            }
            if (contentControls.length === 0) {
              return 1;
            } else {
              // Check login status with content control id
              return ReferenceArticles.checkLoginStatus(contentControls);
            }
          }
        })
        .then(response => {
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  login = event => {
    event.preventDefault();
    event.persist();

    let email = event.target.elements.email.value;
    let password = event.target.elements.password.value;

    User.login({ email: email, password: password, device: "icite" })
      .then(response => {
        localStorage.setItem("token", response.data.access_token);
        return this.checkLoginStatusWithContentControl();
      })
      .then(result => {
        toast.success("Login successfull", { autoClose: 2000 });
        this.setState({ loggedIn: true });
      })
      .catch(err => {
        if (typeof err.response.status !== "undefined") {
          let status = err.response.status;
          if (status === 403) {
            toast.error(
              "You can't sign in to nXr.iCite in this document as the citations in the document were not made from your library. To view cited materials, single users can use the ‘Share Cited Materials’ option in nXr.iCite.",
              { autoClose: 3000 }
            );
            this.setLoggedInFalse();
          } else {
            toast.error("Email or Password is not correct", { autoClose: 2000 });
          }
        } else {
          toast.error("Something went wrong", { autoClose: 2000 });
        }
      });
  };

  logout = () => {
    User.logout({ device: "icite" })
      .then(response => {
        localStorage.removeItem("token");
        localStorage.removeItem("_References");
        this.setState({ loggedIn: false });
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Set logged IN to false
  setLoggedInFalse = () => {
    localStorage.removeItem("token");
    this.setState({ loggedIn: false });
  };

  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={http.BASE_URL + "assets/logo-filled.png"}
          message="Please sideload your addin to see app body."
        />
      );
    }
    return (
      <div className="ms-welcome">
        {this.state.loggedIn ? (
          <HomeContextProvider>
            <Home
              logout={this.logout}
              setLoggedInFalse={this.setLoggedInFalse}
              checkLoginStatusWithContentControl={this.checkLoginStatusWithContentControl}
            />
          </HomeContextProvider>
        ) : (
          <Login login={this.login} />
        )}
      </div>
    );
  }
}
