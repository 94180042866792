export const allCslStyles = [
  {
    id: "2d-materials",
    text: "2D Materials",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "3-biotech",
    text: "3 Biotech",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "3d-printed-materials-and-systems",
    text: "3D-Printed Materials and Systems",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "3d-printing-in-medicine",
    text: "3D Printing in Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "3d-research",
    text: "3D Research",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "4or",
    text: "4OR",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aapg-bulletin",
    text: "AAPG Bulletin",
    parent: "american-association-of-petroleum-geologists",
    urlone: "http://www.aapg.org/bulletin/reference.cfm",
    urltwo: "NA"
  },
  {
    id: "aaps-open",
    text: "AAPS Open",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aaps-pharmscitech",
    text: "AAPS PharmSciTech",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "abhandlungen-aus-dem-mathematischen-seminar-der-universitat-hamburg",
    text: "Abhandlungen aus dem Mathematischen Seminar der UniversitÃ¤t Hamburg",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "abi-technik",
    text: "ABI Technik (German)",
    parent: "chicago-fullnote-bibliography",
    urlone: "http://www.degruyter.com/view/supplement/s21914664_Autorenhinweise.pdf",
    urltwo: "NA"
  },
  {
    id: "academic-medicine",
    text: "Academic Medicine",
    parent: "american-medical-association",
    urlone: "http://journals.lww.com/academicmedicine/Pages/InstructionsforAuthors.aspx#references",
    urltwo: "NA"
  },
  {
    id: "academic-pediatrics",
    text: "Academic Pediatrics",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "academic-psychiatry",
    text: "Academic Psychiatry",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "academic-questions",
    text: "Academic Questions",
    parent: "springer-humanities-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "academy-of-management-discoveries",
    text: "Academy of Management Discoveries",
    parent: "academy-of-management-review",
    urlone: "http://aom.org/uploadedFiles/Publications/AMD/AMD%20STYLE%20GUIDE.pdf",
    urltwo: "NA"
  },
  {
    id: "academy-of-management-journal",
    text: "Academy of Management Journal",
    parent: "academy-of-management-review",
    urlone: "http://aom.org/uploadedFiles/Publications/AMJ/AMJ%20Style%20Guide(1).pdf",
    urltwo: "NA"
  },
  {
    id: "academy-of-management-learning-and-education",
    text: "Academy of Management Learning and Education",
    parent: "academy-of-management-review",
    urlone: "http://aom.org/Publications/AMLE/AMLE-Style---Formatting.aspx",
    urltwo: "NA"
  },
  {
    id: "academy-of-management-perspectives",
    text: "Academy of Management Perspectives",
    parent: "apa-5th-edition",
    urlone: "http://aom.org/Publications/AMP/Information-for-Contributors.aspx",
    urltwo: "NA"
  },
  {
    id: "academy-of-management-proceedings",
    text: "Academy of Management Proceedings",
    parent: "academy-of-management-review",
    urlone: "http://aom.org/annualmeeting/submission/guidelines/",
    urltwo: "NA"
  },
  {
    id: "academy-of-management-review",
    text: "Academy of Management Review",
    parent: "NA",
    urlone: "http://aom.org/uploadedFiles/Publications/AMR/AMR%20Style%20Guide%202014.pdf",
    urltwo: "NA"
  },
  {
    id: "accident-analysis-and-prevention",
    text: "Accident Analysis and Prevention",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/accident-analysis-and-prevention/0001-4575/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "accounting-forum",
    text: "Accounting Forum",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "accounting-history-review",
    text: "Accounting History Review",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RABF20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "accounting-organizations-and-society",
    text: "Accounting, Organizations and Society",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "accounts-of-chemical-research",
    text: "Accounts of Chemical Research",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/achre4/achre4_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "accreditation-and-quality-assurance",
    text: "Accreditation and Quality Assurance",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "achievements-in-the-life-sciences",
    text: "Achievements in the Life Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aci-materials-journal",
    text: "ACI Materials Journal",
    parent: "NA",
    urlone: "https://www.concrete.org/publications/acimaterialsjournal/submittingtoacijournals.aspx",
    urltwo: "NA"
  },
  {
    id: "aci-structural-journal",
    text: "ACI Structural Journal",
    parent: "aci-materials-journal",
    urlone: "https://www.concrete.org/publications/acimaterialsjournal/submittingtoacijournals.aspx",
    urltwo: "NA"
  },
  {
    id: "acm-sig-proceedings-long-author-list",
    text: 'ACM SIG Proceedings ("et al." for 15+ authors)',
    parent: "NA",
    urlone: "http://www.acm.org/sigs/publications/proceedings-templates",
    urltwo: "NA"
  },
  {
    id: "acm-sig-proceedings",
    text: 'ACM SIG Proceedings ("et al." for 3+ authors)',
    parent: "NA",
    urlone: "http://www.acm.org/sigs/publications/proceedings-templates",
    urltwo: "NA"
  },
  {
    id: "acm-sigchi-proceedings-extended-abstract-format",
    text: "ACM SIGCHI Proceedings - Extended Abstract Format",
    parent: "NA",
    urlone: "http://www.sigchi.org/publications/chipubform/sigchi-extended-abstracts-format-2016/view",
    urltwo: "NA"
  },
  {
    id: "acm-sigchi-proceedings",
    text: "ACM SIGCHI Proceedings (2016)",
    parent: "NA",
    urlone: "http://cscw.acm.org/2016/submit/sigchi-word-proceedings.zip",
    urltwo: "NA"
  },
  {
    id: "acm-siggraph",
    text: "ACM SIGGRAPH",
    parent: "NA",
    urlone: "http://www.siggraph.org/publications/instructions.pdf",
    urltwo: "NA"
  },
  {
    id: "acme-an-international-journal-for-critical-geographies",
    text: "ACME: An International Journal for Critical Geographies",
    parent: "NA",
    urlone: "https://www.acme-journal.org/index.php/acme/about/submissions",
    urltwo: "NA"
  },
  {
    id: "acoustics-australia",
    text: "Acoustics Australia",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acs-applied-energy-materials",
    text: "ACS Applied Energy Materials",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/aaemcq/aaemcq_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-applied-materials-and-interfaces",
    text: "ACS Applied Materials &amp; Interfaces",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/aamick/aamick_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-applied-nano-materials",
    text: "ACS Applied Nano Materials",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/aanmf6/aanmf6_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-biomaterials-science-and-engineering",
    text: "ACS Biomaterials Science &amp; Engineering",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/journal/abseba",
    urltwo: "NA"
  },
  {
    id: "acs-catalysis",
    text: "ACS Catalysis",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/accacs/accacs_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-chemical-biology",
    text: "ACS Chemical Biology",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/acbcct/acbcct_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-chemical-neuroscience",
    text: "ACS Chemical Neuroscience",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/acncdm/acncdm_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-combinatorial-science",
    text: "ACS Combinatorial Science",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/acsccc/acsccc_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-earth-and-space-chemistry",
    text: "ACS Earth and Space Chemistry",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/aesccq/aesccq_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-energy-letters",
    text: "ACS Energy Letters",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/aelccp/aelccp_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-infectious-diseases",
    text: "ACS Infectious Diseases",
    parent: "american-chemical-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acs-macro-letters",
    text: "ACS Macro Letters",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/amlccd/amlccd_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-medicinal-chemistry-letters",
    text: "ACS Medicinal Chemistry Letters",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/amclct/amclct_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-nano",
    text: "ACS Nano",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/ancac3/ancac3_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-photonics",
    text: "ACS Photonics",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/apchd5/apchd5_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-sustainable-chemistry-and-engineering",
    text: "ACS Sustainable Chemistry &amp; Engineering",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/ascecg/ascecg_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acs-synthetic-biology",
    text: "ACS Synthetic Biology",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/asbcd6/asbcd6_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "acta-agriculturae-scandinavica-section-b-soil-and-plant-science",
    text: "Acta Agriculturae Scandinavica, Section B - Soil &amp; Plant Science",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=SAGB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "acta-amazonica",
    text: "Acta Amazonica",
    parent: "NA",
    urlone: "https://acta.inpa.gov.br/guia_ingles.php",
    urltwo: "NA"
  },
  {
    id: "acta-anaesthesiologica-scandinavica",
    text: "Acta Anaesthesiologica Scandinavica",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1399-6576/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "acta-anaesthesiologica-taiwanica",
    text: "Acta Anaesthesiologica Taiwanica",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/acta-anaesthesiologica-taiwanica/1875-4597/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "acta-analytica",
    text: "Acta Analytica",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-applicandae-mathematicae",
    text: "Acta Applicandae Mathematicae",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-astronautica",
    text: "Acta Astronautica",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-biologica-sibirica",
    text: "Acta Biologica Sibirica",
    parent: "pensoft-journals",
    urlone: "https://abs.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "acta-biomaterialia",
    text: "Acta Biomaterialia",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-biotheoretica",
    text: "Acta Biotheoretica",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-botanica-croatica",
    text: "Acta Botanica Croatica",
    parent: "NA",
    urlone: "http://www.abc.botanic.hr/index.php/abc/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "acta-botanica-gallica",
    text: "Acta Botanica Gallica",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TABG20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "acta-chiropterologica",
    text: "Acta Chiropterologica",
    parent: "NA",
    urlone: "http://system.miiz.waw.pl/ac/journal/for_authors/",
    urltwo: "NA"
  },
  {
    id: "acta-chirurgiae-orthopaedicae-et-traumatologiae-cechoslovaca",
    text: "Acta chirurgiae orthopaedicae et traumatologiae ÄŒechoslovaca",
    parent: "NA",
    urlone: "http://www.achot.cz/instruction.php",
    urltwo: "NA"
  },
  {
    id: "acta-crystallographica-section-a-foundations-and-advances",
    text: "Acta Crystallographica Section A: Foundations and Advances",
    parent: "international-union-of-crystallography",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-crystallographica-section-b-structural-science-crystal-engineering-and-materials",
    text: "Acta Crystallographica Section B: Structural Science, Crystal Engineering and Materials",
    parent: "international-union-of-crystallography",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-crystallographica-section-c-structural-chemistry",
    text: "Acta Crystallographica Section C: Structural Chemistry",
    parent: "international-union-of-crystallography",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-crystallographica-section-d-biological-crystallography",
    text: "Acta Crystallographica Section D: Biological Crystallography",
    parent: "international-union-of-crystallography",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-crystallographica-section-e-structure-reports-online",
    text: "Acta Crystallographica Section E: Structure Reports Online",
    parent: "international-union-of-crystallography",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-crystallographica-section-f-structural-biology-communications",
    text: "Acta Crystallographica Section F: Structural Biology Communications",
    parent: "international-union-of-crystallography",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-cytologica",
    text: "Acta Cytologica",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=0001-5547",
    urltwo: "NA"
  },
  {
    id: "acta-de-investigacion-psicologica",
    text: "Acta de InvestigaciÃ³n PsicolÃ³gica",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-diabetologica",
    text: "Acta Diabetologica",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-ecologica-sinica",
    text: "Acta Ecologica Sinica",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-ethologica",
    text: "acta ethologica",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-geochimica",
    text: "Acta Geochimica",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-geodaetica-et-geophysica",
    text: "Acta Geodaetica et Geophysica",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-geotechnica",
    text: "Acta Geotechnica",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-haematologica-polonica",
    text: "Acta Haematologica Polonica",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-haematologica",
    text: "Acta Haematologica",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9662",
    urltwo: "NA"
  },
  {
    id: "acta-histochemica",
    text: "Acta Histochemica",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-ichthyologica-et-piscatoria",
    text: "Acta Ichthyologica et Piscatoria",
    parent: "NA",
    urlone: "https://aiep.pl/main/instr.php",
    urltwo: "NA"
  },
  {
    id: "acta-materialia",
    text: "Acta Materialia",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-mathematica-vietnamica",
    text: "Acta Mathematica Vietnamica",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-mechanica-sinica",
    text: "Acta Mechanica Sinica",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-mechanica-solida-sinica",
    text: "Acta Mechanica Solida Sinica",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-mechanica",
    text: "Acta Mechanica",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-medica-colombiana",
    text: "Acta MÃ©dica Colombiana",
    parent: "vancouver",
    urlone: "http://www.actamedicacolombiana.com/info_autores.php?d=Indicaciones%20para%20autores#req",
    urltwo: "NA"
  },
  {
    id: "acta-medica-peruana",
    text: "Acta MÃ©dica Peruana",
    parent: "NA",
    urlone: "https://amp.cmp.org.pe/index.php/AMP/instructionsforauthors",
    urltwo: "NA"
  },
  {
    id: "acta-medica",
    text: "Acta Medica",
    parent: "vancouver",
    urlone: "http://www.lfhk.cuni.cz/article.asp?nArticleID=391&amp;nDepartmentID=1290&amp;nLanguageID=2",
    urltwo: "NA"
  },
  {
    id: "acta-metallurgica-sinica",
    text: "Acta Metallurgica Sinica",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-naturae",
    text: "Acta Naturae",
    parent: "NA",
    urlone: "http://actanaturae.ru/",
    urltwo: "NA"
  },
  {
    id: "acta-neurobiologiae-experimentalis",
    text: "Acta Neurobiologiae Experimentalis",
    parent: "NA",
    urlone:
      "https://www.exeley.com/exeley/journals/acta_neurobiologiae_experimentalis/supp/Acta%20Neurobiologiae%20Experimentalis%20-%20Instructions%20for%20Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "acta-neurochirurgica",
    text: "Acta Neurochirurgica",
    parent: "NA",
    urlone: "http://www.springer.com/medicine/surgery/journal/701",
    urltwo: "NA"
  },
  {
    id: "acta-neurologica-belgica",
    text: "Acta Neurologica Belgica",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-neuropathologica-communications",
    text: "Acta Neuropathologica Communications",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-neuropathologica",
    text: "Acta Neuropathologica",
    parent: "springer-basic-brackets-no-et-al-alphabetical",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-oecologica",
    text: "Acta Oecologica",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-ophthalmologica",
    text: "Acta Ophthalmologica",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291755-3768/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "acta-ornithologica",
    text: "Acta Ornithologica",
    parent: "NA",
    urlone: "https://miiz.waw.pl/periodicals/acta-ornithologica/acta_ornithologica.pdf",
    urltwo: "NA"
  },
  {
    id: "acta-orthopaedica-belgica",
    text: "Acta OrthopÃ¦dica Belgica",
    parent: "NA",
    urlone: "http://journals.sfu.ca/actaob/index.php/actaob/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "acta-orthopaedica-et-traumatologica-turcica",
    text: "Acta Orthopaedica et Traumatologica Turcica",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-orthopaedica",
    text: "Acta Orthopaedica",
    parent: "NA",
    urlone: "http://www.actaorthop.org/index.php?p=include/aut/man_",
    urltwo: "NA"
  },
  {
    id: "acta-otorrinolaringologica-espanola",
    text: "Acta OtorrinolaringolÃ³gica EspaÃ±ola (Spanish)",
    parent: "vancouver",
    urlone: "http://ees.elsevier.com/otorrino/",
    urltwo: "NA"
  },
  {
    id: "acta-paediatrica",
    text: "Acta Paediatrica",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/16512227/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "acta-palaeontologica-polonica",
    text: "Acta Palaeontologica Polonica",
    parent: "NA",
    urlone: "http://www.app.pan.pl/instruction.html",
    urltwo: "NA"
  },
  {
    id: "acta-pharmaceutica-sinica-b",
    text: "Acta Pharmaceutica Sinica B",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/acta-pharmaceutica-sinica-b/2211-3835/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "acta-pharmaceutica",
    text: "Acta Pharmaceutica",
    parent: "NA",
    urlone: "http://public.carnet.hr/acphee/Inst.html",
    urltwo: "NA"
  },
  {
    id: "acta-pharmacologica-sinica",
    text: "Acta Pharmacologica Sinica",
    parent: "nature",
    urlone: "http://www.chinaphar.com/Author-APS.htm",
    urltwo: "NA"
  },
  {
    id: "acta-philosophica",
    text: "Acta Philosophica",
    parent: "NA",
    urlone: "http://www.actaphilosophica.it/it/norme-i-collaboratori",
    urltwo: "NA"
  },
  {
    id: "acta-physiologiae-plantarum",
    text: "Acta Physiologiae Plantarum",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-physiologica",
    text: "Acta Physiologica",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/17481716/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "acta-polytechnica",
    text: "Acta Polytechnica",
    parent: "NA",
    urlone: "http://www.cvut.cz/en/facilities/ph/ap/resolveuid/a1a4420631dd2b61eff7a823f4b4f949",
    urltwo: "NA"
  },
  {
    id: "acta-psychiatrica-scandinavica",
    text: "Acta Psychiatrica Scandinavica",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291600-0447/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "acta-psychologica",
    text: "Acta Psychologica",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-radiologica",
    text: "Acta Radiologica",
    parent: "NA",
    urlone: "https://uk.sagepub.com/en-gb/eur/journal/acta-radiologica#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "acta-scientiae-veterinariae",
    text: "Acta Scientiae Veterinariae",
    parent: "NA",
    urlone: "http://www.ufrgs.br/actavet/instrucoes.htm",
    urltwo: "NA"
  },
  {
    id: "acta-societatis-botanicorum-poloniae",
    text: "Acta Societatis Botanicorum Poloniae",
    parent: "NA",
    urlone: "https://pbsociety.org.pl/journals/index.php/asbp/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "acta-sociologica",
    text: "Acta SociolÃ³gica",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-tropica",
    text: "Acta Tropica",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acta-universitatis-agriculturae-et-silviculturae-mendelianae-brunensis",
    text: "Acta Universitatis Agriculturae et Silviculturae Mendelianae Brunensis",
    parent: "NA",
    urlone: "https://acta.mendelu.cz/for-authors.html",
    urltwo: "NA"
  },
  {
    id: "acta-universitatis-agriculturae-sueciae",
    text: "Acta Universitatis Agriculturae Sueciae (Swedish University of Agricultural Sciences)",
    parent: "NA",
    urlone:
      "https://www.slu.se/en/subweb/library/write-and-cite2/writing-references/referencelist-according-to-the-harvard-system/",
    urltwo: "https://www.slu.se/site/bibliotek/skriva-och-referera/skriva-referenser/referenslista-enligt-harvard/"
  },
  {
    id: "acta-veterinaria-scandinavica",
    text: "Acta Veterinaria Scandinavica",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "acta-zoologica-academiae-scientiarum-hungaricae",
    text: "Acta Zoologica Academiae Scientiarum Hungaricae",
    parent: "NA",
    urlone: "http://actazool.nhmus.hu/",
    urltwo: "NA"
  },
  {
    id: "action-learning-research-and-practice",
    text: "Action Learning: Research and Practice",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/toc/calr20/current#.U3qymy_Wr7A",
    urltwo: "NA"
  },
  {
    id: "actualites-pharmaceutiques",
    text: "ActualitÃ©s pharmaceutiques",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "actuators",
    text: "Actuators",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "acupuncture-and-related-therapies",
    text: "Acupuncture and Related Therapies",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "acupuncture-in-medicine",
    text: "Acupuncture in Medicine",
    parent: "bmj",
    urlone: "http://aim.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "ad-hoc-networks",
    text: "Ad Hoc Networks",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "adansonia",
    text: "Adansonia",
    parent: "museum-national-dhistoire-naturelle",
    urlone: "http://sciencepress.mnhn.fr/sites/default/files/periodiques/pdf/ia-adansonia-2018-flux-continu-en.pdf",
    urltwo: "NA"
  },
  {
    id: "adaptive-human-behavior-and-physiology",
    text: "Adaptive Human Behavior and Physiology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "addiction-biology",
    text: "Addiction Biology",
    parent: "american-medical-association",
    urlone: "https://onlinelibrary.wiley.com/page/journal/13691600/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "addiction-science-and-clinical-practice",
    text: "Addiction Science &amp; Clinical Practice",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "addictive-behaviors-reports",
    text: "Addictive Behaviors Reports",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "addictive-behaviors",
    text: "Addictive Behaviors",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "additive-manufacturing",
    text: "Additive Manufacturing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "adhd-attention-deficit-and-hyperactivity-disorders",
    text: "ADHD Attention Deficit and Hyperactivity Disorders",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "adipocyte",
    text: "Adipocyte",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "administration-and-policy-in-mental-health-and-mental-health-services-research",
    text: "Administration and Policy in Mental Health and Mental Health Services Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "administrative-science-quarterly",
    text: "Administrative Science Quarterly",
    parent: "NA",
    urlone: "https://us.sagepub.com/en-us/nam/administrative-science-quarterly/journal202065#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "administrative-sciences",
    text: "Administrative Sciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "adolescent-research-review",
    text: "Adolescent Research Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "adsorption",
    text: "Adsorption",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advanced-composite-materials",
    text: "Advanced Composite Materials",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TACM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "advanced-drug-delivery-reviews",
    text: "Advanced Drug Delivery Reviews",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advanced-engineering-informatics",
    text: "Advanced Engineering Informatics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advanced-engineering-materials",
    text: "Advanced Engineering Materials",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291527-2648/homepage/2266_forauthors.html",
    urltwo: "NA"
  },
  {
    id: "advanced-functional-materials",
    text: "Advanced Functional Materials",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291616-3028/homepage/2126_forauthors.html",
    urltwo: "NA"
  },
  {
    id: "advanced-healthcare-materials",
    text: "Advanced Healthcare Materials",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)2192-2659/homepage/2087_authorresources.html",
    urltwo: "NA"
  },
  {
    id: "advanced-industrial-and-engineering-polymer-research",
    text: "Advanced Industrial and Engineering Polymer Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advanced-materials",
    text: "Advanced Materials",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291521-4095/homepage/2089_authorresources.html",
    urltwo: "NA"
  },
  {
    id: "advanced-medicinal-chemistry-letters",
    text: "Advanced Medicinal Chemistry Letters",
    parent: "integrated-science-publishing-journals",
    urlone: "http://www.pubs.iscience.in/journal/index.php/amcl/about/submissions#onlineSubmissions",
    urltwo: "NA"
  },
  {
    id: "advanced-modeling-and-simulation-in-engineering-sciences",
    text: "Advanced Modeling and Simulation in Engineering Sciences",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advanced-optical-materials",
    text: "Advanced Optical Materials",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)2195-1071/homepage/2298_forauthors.html",
    urltwo: "NA"
  },
  {
    id: "advanced-organic-chemistry-letters",
    text: "Advanced Organic Chemistry Letters",
    parent: "integrated-science-publishing-journals",
    urlone: "http://www.pubs.iscience.in/journal/index.php/ocl/about/submissions#onlineSubmissions",
    urltwo: "NA"
  },
  {
    id: "advanced-powder-technology",
    text: "Advanced Powder Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advanced-robotics",
    text: "Advanced Robotics",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TADR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "advanced-structural-and-chemical-imaging",
    text: "Advanced Structural and Chemical Imaging",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advances-in-accounting",
    text: "Advances in Accounting",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-alzheimers-disease",
    text: "Advances in Alzheimer's Disease",
    parent: "NA",
    urlone: "http://www.scirp.org/journal/ForAuthors.aspx?JournalID=1408",
    urltwo: "NA"
  },
  {
    id: "advances-in-applied-clifford-algebras",
    text: "Advances in Applied Clifford Algebras",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advances-in-biological-regulation",
    text: "Advances in Biological Regulation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-biomarker-sciences-and-technology",
    text: "Advances in Biomarker Sciences and Technology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-building-energy-research",
    text: "Advances in Building Energy Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TAER20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "advances-in-climate-change-research",
    text: "Advances in Climate Change Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-colloid-and-interface-science",
    text: "Advances in Colloid and Interface Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-complex-systems",
    text: "Advances in Complex Systems",
    parent: "NA",
    urlone: "http://www.worldscinet.com/acs/mkt/guidelines.shtml",
    urltwo: "NA"
  },
  {
    id: "advances-in-computational-mathematics",
    text: "Advances in Computational Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advances-in-cosmetic-surgery",
    text: "Advances in Cosmetic Surgery",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-data-analysis-and-classification",
    text: "Advances in Data Analysis and Classification",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advances-in-difference-equations",
    text: "Advances in Difference Equations",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advances-in-digestive-medicine",
    text: "Advances in Digestive Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-eating-disorders-theory-research-and-practice",
    text: "Advances in Eating Disorders: Theory, Research and Practice",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REAT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "advances-in-engineering-software",
    text: "Advances in Engineering Software",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-family-practice-nursing",
    text: "Advances in Family Practice Nursing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-geosciences",
    text: "Advances in Geosciences",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-health-sciences-education",
    text: "Advances in Health Sciences Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advances-in-integrative-medicine",
    text: "Advances in Integrative Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-life-course-research",
    text: "Advances in Life Course Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-manufacturing",
    text: "Advances in Manufacturing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advances-in-medical-sciences",
    text: "Advances in Medical Sciences",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-molecular-pathology",
    text: "Advances in Molecular Pathology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-natural-sciences-nanoscience-and-nanotechnology",
    text: "Advances in Natural Sciences: Nanoscience and Nanotechnology",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/2043-6262",
    urltwo: "NA"
  },
  {
    id: "advances-in-nutrition",
    text: "Advances in Nutrition",
    parent: "journal-of-nutrition",
    urlone: "http://advances.nutrition.org/site/misc/ifora08_elements.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "advances-in-oceanography-and-limnology",
    text: "Advances in Oceanography and Limnology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TAOL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "advances-in-ophthalmology-and-optometry",
    text: "Advances in Ophthalmology and Optometry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-optics-and-photonics",
    text: "Advances in Optics and Photonics",
    parent: "the-optical-society",
    urlone: "https://www.osapublishing.org/submit/templates/default.cfm",
    urltwo: "https://www.osapublishing.org/submit/style/oestyleguide.cfm"
  },
  {
    id: "advances-in-physiology-education",
    text: "Advances in Physiology Education",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-radiation-oncology",
    text: "Advances in Radiation Oncology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-radio-science",
    text: "Advances in Radio Science",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-school-mental-health-promotion",
    text: "Advances in School Mental Health Promotion",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RASM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "advances-in-science-and-research",
    text: "Advances in Science and Research",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-simulation",
    text: "Advances in Simulation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advances-in-space-research",
    text: "Advances in Space Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-statistical-climatology-meteorology-and-oceanography",
    text: "Advances in Statistical Climatology, Meteorology and Oceanography",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "advances-in-therapy",
    text: "Advances in Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "advances-in-water-resources",
    text: "Advances in Water Resources",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aeolian-research",
    text: "Aeolian Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aequationes-mathematicae",
    text: "Aequationes mathematicae",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aerobiologia",
    text: "Aerobiologia",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aerosol-and-air-quality-research",
    text: "Aerosol and Air Quality Research",
    parent: "NA",
    urlone: "https://aaqr.org/page/instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "aerosol-science-and-technology",
    text: "Aerosol Science and Technology",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=uast20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "aerospace-science-and-technology",
    text: "Aerospace Science and Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aerospace",
    text: "Aerospace",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "aesthetic-plastic-surgery",
    text: "Aesthetic Plastic Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aethiopica",
    text: "Aethiopica",
    parent: "hiob-ludolf-centre-for-ethiopian-studies",
    urlone: "http://journals.sub.uni-hamburg.de/aethiopica/about/submissions",
    urltwo: "NA"
  },
  {
    id: "aethiopistische-forschungen",
    text: "Aethiopistische Forschungen",
    parent: "hiob-ludolf-centre-for-ethiopian-studies",
    urlone: "http://www1.uni-hamburg.de/ethiostudies/aef.html",
    urltwo: "NA"
  },
  {
    id: "aeue-international-journal-of-electronics-and-communications",
    text: "AEUE - International Journal of Electronics and Communications",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "africa-review",
    text: "Africa Review",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAFR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "african-and-black-diaspora-an-international-journal",
    text: "African and Black Diaspora: An International Journal",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RABD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "african-archaeological-review",
    text: "African Archaeological Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "african-geographical-review",
    text: "African Geographical Review",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAFG20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "african-identities",
    text: "African Identities",
    parent: "apa",
    urlone: "http://www.tandfonline.com/toc/cafi20/current#.U3qy0C_Wr7A",
    urltwo: "NA"
  },
  {
    id: "african-invertebrates",
    text: "African Invertebrates",
    parent: "pensoft-journals",
    urlone: "https://africaninvertebrates.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "african-journal-of-emergency-medicine",
    text: "African Journal of Emergency Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "african-journal-of-marine-science",
    text: "African Journal of Marine Science",
    parent: "NA",
    urlone: "https://www.nisc.co.za/products/3/journals/african-journal-of-marine-science#downloads",
    urltwo: "NA"
  },
  {
    id: "african-journal-of-urology",
    text: "African Journal of Urology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "african-online-scientific-information-systems-harvard",
    text: "African Online Scientific Information Systems - Harvard",
    parent: "NA",
    urlone: "https://books.aosis.co.za/index.php/ob/submission-guideline",
    urltwo: "NA"
  },
  {
    id: "african-zoology",
    text: "African Zoology",
    parent: "NA",
    urlone: "https://journals.co.za/upload/Guidelines_for_Authors/afzoo_auth.pdf",
    urltwo: "https://journals.co.za/content/journal/afzoo"
  },
  {
    id: "afrika-matematika",
    text: "Afrika Matematika",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "afro-asia",
    text: "Afro-Ãsia (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://portalseer.ufba.br/index.php/afroasia/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "afte-journal",
    text: "AFTE Journal",
    parent: "NA",
    urlone: "https://afte.org/afte-journal/information-for-authors",
    urltwo: "NA"
  },
  {
    id: "age-and-ageing",
    text: "Age and Ageing",
    parent: "NA",
    urlone: "https://academic.oup.com/ageing/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "age",
    text: "AGE",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ageing-and-society",
    text: "Ageing &amp; Society",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/journals/ageing-and-society/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "ageing-international",
    text: "Ageing International",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ageing-research-reviews",
    text: "Ageing Research Reviews",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aggression-and-violent-behavior",
    text: "Aggression and Violent Behavior",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aging-and-disease",
    text: "Aging and Disease",
    parent: "NA",
    urlone: "http://www.aginganddisease.org/EN/column/column217.shtml",
    urltwo: "NA"
  },
  {
    id: "aging-and-mental-health",
    text: "Aging &amp; Mental Health",
    parent: "apa",
    urlone: "http://www.tandfonline.com/toc/camh20/current#.U3qzCS_Wr7A",
    urltwo: "NA"
  },
  {
    id: "aging-cell",
    text: "Aging Cell",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291474-9726/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "aging-clinical-and-experimental-research",
    text: "Aging Clinical and Experimental Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aging-health",
    text: "Aging Health",
    parent: "future-science-group",
    urlone: "http://www.futuremedicine.com/loi/ahe",
    urltwo: "NA"
  },
  {
    id: "aging-neuropsychology-and-cognition",
    text: "Aging, Neuropsychology, and Cognition",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NANC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "aging",
    text: "Aging",
    parent: "NA",
    urlone: "http://www.aging-us.com/for-authors",
    urltwo: "NA"
  },
  {
    id: "agora",
    text: "Agora",
    parent: "NA",
    urlone: "https://www.idunn.no/agora?languageId=2#/authors",
    urltwo: "NA"
  },
  {
    id: "agri-gene",
    text: "Agri Gene",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "agriculturae-conspectus-scientificus",
    text: "Agriculturae Conspectus Scientificus",
    parent: "NA",
    urlone: "https://acs.agr.hr/acs/index.php/acs/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "agricultural-and-food-economics",
    text: "Agricultural and Food Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "agricultural-and-forest-meteorology",
    text: "Agricultural and Forest Meteorology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "agricultural-research",
    text: "Agricultural Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "agricultural-systems",
    text: "Agricultural Systems",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "agricultural-water-management",
    text: "Agricultural Water Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "agriculture-and-food-security",
    text: "Agriculture &amp; Food Security",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "agriculture-and-human-values",
    text: "Agriculture and Human Values",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "agriculture-and-natural-resources",
    text: "Agriculture and Natural Resources",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "agriculture-ecosystems-and-environment",
    text: "Agriculture, Ecosystems and Environment",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "agriculture",
    text: "Agriculture",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "agroforestry-systems",
    text: "Agroforestry Systems",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "agronomy-for-sustainable-development",
    text: "Agronomy for Sustainable Development",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "agronomy-journal",
    text: "Agronomy Journal",
    parent: "asa-cssa-sssa",
    urlone: "https://www.agronomy.org/publications/aj/instructions-to-authors",
    urltwo: "NA"
  },
  {
    id: "agronomy",
    text: "Agronomy",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "ai-and-society",
    text: "AI &amp; SOCIETY",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aiaa-journal",
    text: "AIAA Journal",
    parent: "american-institute-of-aeronautics-and-astronautics",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aib-studi",
    text: "AIB studi (Italian)",
    parent: "NA",
    urlone: "http://aibstudi.aib.it/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "aids-and-behavior",
    text: "AIDS and Behavior",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aids-care",
    text: "AIDS Care",
    parent: "apa",
    urlone: "http://www.tandfonline.com/toc/caic20/current#.U3qzRy_Wr7A",
    urltwo: "NA"
  },
  {
    id: "aids-research-and-therapy",
    text: "AIDS Research and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aids",
    text: "AIDS",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/aids/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "aims-agriculture-and-food",
    text: "AIMS Agriculture and Food",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/72.html",
    urltwo: "NA"
  },
  {
    id: "aims-allergy-and-immunology",
    text: "AIMS Allergy and Immunology",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/217.html",
    urltwo: "NA"
  },
  {
    id: "aims-bioengineering",
    text: "AIMS Bioengineering",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/81.html",
    urltwo: "NA"
  },
  {
    id: "aims-biophysics",
    text: "AIMS Biophysics",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/89.html",
    urltwo: "NA"
  },
  {
    id: "aims-electronics-and-electrical-engineering",
    text: "AIMS Electronics and Electrical Engineering",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/243.html",
    urltwo: "NA"
  },
  {
    id: "aims-energy",
    text: "AIMS Energy",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/96.html",
    urltwo: "NA"
  },
  {
    id: "aims-environmental-science",
    text: "AIMS Environmental Science",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/105.html",
    urltwo: "NA"
  },
  {
    id: "aims-genetics",
    text: "AIMS Genetics",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/14.html",
    urltwo: "NA"
  },
  {
    id: "aims-geosciences",
    text: "AIMS Geosciences",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/115.html",
    urltwo: "NA"
  },
  {
    id: "aims-materials-science",
    text: "AIMS Materials Science",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/123.html",
    urltwo: "NA"
  },
  {
    id: "aims-mathematics",
    text: "AIMS Mathematics",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/204.html",
    urltwo: "NA"
  },
  {
    id: "aims-medical-science",
    text: "AIMS Medical Science",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/38.html",
    urltwo: "NA"
  },
  {
    id: "aims-microbiology",
    text: "AIMS Microbiology",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/132.html",
    urltwo: "NA"
  },
  {
    id: "aims-molecular-science",
    text: "AIMS Molecular Science",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/24.html",
    urltwo: "NA"
  },
  {
    id: "aims-neuroscience",
    text: "AIMS Neuroscience",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/51.html",
    urltwo: "NA"
  },
  {
    id: "aims-press",
    text: "AIMS Press",
    parent: "NA",
    urlone: "https://www.aimspress.com/news/140.html",
    urltwo: "NA"
  },
  {
    id: "aims-public-health",
    text: "AIMS Public Health",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/140.html",
    urltwo: "NA"
  },
  {
    id: "ain-shams-engineering-journal",
    text: "Ain Shams Engineering Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ains",
    text: "AINS",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "aip-advances",
    text: "AIP Advances",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "air-quality-atmosphere-and-health",
    text: "Air Quality, Atmosphere &amp; Health",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aix-marseille-universite-departement-d-etudes-asiatiques",
    text: "Aix-Marseille UniversitÃ© - DÃ©partement d'Ã©tudes asiatiques (French)",
    parent: "NA",
    urlone: "http://allsh.univ-amu.fr/llc/d%C3%A9partement/DEA",
    urltwo: "NA"
  },
  {
    id: "ajp-cell-physiology",
    text: "American Journal of Physiology - Cell Physiology",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ajp-endocrinology-and-metabolism",
    text: "American Journal of Physiology - Endocrinology and Metabolism",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ajp-gastrointestinal-and-liver-physiology",
    text: "American Journal of Physiology - Gastrointestinal and Liver Physiology",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ajp-heart-and-circulatory-physiology",
    text: "American Journal of Physiology - Heart and Circulatory Physiology",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ajp-lung-cellular-and-molecular-physiology",
    text: "American Journal of Physiology - Lung Cellular and Molecular Physiology",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ajp-regulatory-integrative-and-comparative-physiology",
    text: "American Journal of Physiology - Regulatory, Integrative, and Comparative Physiology",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ajp-renal-physiology",
    text: "American Journal of Physiology - Renal Physiology",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "akce-international-journal-of-graphs-and-combinatorics",
    text: "AKCE International Journal of Graphs and Combinatorics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aktuelle-dermatologie",
    text: "Aktuelle Dermatologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "aktuelle-ernahrungsmedizin",
    text: "Aktuelle ErnÃ¤hrungsmedizin",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "aktuelle-kardiologie",
    text: "Aktuelle Kardiologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "aktuelle-neurologie",
    text: "Aktuelle Neurologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "aktuelle-rheumatologie",
    text: "Aktuelle Rheumatologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "aktuelle-urologie",
    text: "Aktuelle Urologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "al-jamiah-journal-of-islamic-studies",
    text: "Al-Jami'ah - Journal of Islamic Studies",
    parent: "NA",
    urlone: "http://aljamiah.or.id/index.php/AJIS/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "alcohol-and-alcoholism",
    text: "Alcohol and Alcoholism",
    parent: "NA",
    urlone: "https://academic.oup.com/alcalc/pages/General_Instructions#Instructions%20to%20authors",
    urltwo: "NA"
  },
  {
    id: "alcohol",
    text: "Alcohol",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "alcoholism-and-drug-addiction",
    text: "Alcoholism and Drug Addiction",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "alcoholism-clinical-and-experimental-research",
    text: "Alcoholism: Clinical and Experimental Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1530-0277/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "alergologia-polska-polish-journal-of-allergology",
    text: "Alergologia Polska- Polish Journal of Allergology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "alexandria-engineering-journal",
    text: "Alexandria Engineering Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "alexandria-journal-of-medicine",
    text: "Alexandria Journal of Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "algal-research",
    text: "Algal Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "algebra-universalis",
    text: "Algebra universalis",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "algebras-and-representation-theory",
    text: "Algebras and Representation Theory",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "algorithmica",
    text: "Algorithmica",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "algorithms-for-molecular-biology",
    text: "Algorithms for Molecular Biology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "algorithms",
    text: "Algorithms",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "alkoholizmus-a-drogove-zavislosti",
    text: "Alkoholizmus a drogovÃ© zÃ¡vislosti",
    parent: "NA",
    urlone: "https://www.adzpo.sk/sk/adzpo-pokyny-pre-autorov",
    urltwo: "NA"
  },
  {
    id: "allergology-international",
    text: "Allergology International",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/allergology-international/1323-8930/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "allergy-asthma-and-clinical-immunology",
    text: "Allergy, Asthma &amp; Clinical Immunology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "allergy",
    text: "Allergy",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291398-9995/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "allgemein-und-viszeralchirurgie-up2date",
    text: "Allgemein- und Viszeralchirurgie up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "alpine-botany",
    text: "Alpine Botany",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "alpine-entomology",
    text: "Alpine Entomology",
    parent: "pensoft-journals",
    urlone: "https://alpineentomology.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "alter-european-journal-of-disability-research-revue-europeenne-de-recherche-sur-le-handicap",
    text: "Alter - European Journal of Disability research, Revue europÃ©enne de recherche sur le handicap",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "alternatives-to-animal-experimentation",
    text: "Alternatives to Animal Experimentation",
    parent: "NA",
    urlone: "http://www.altex.ch/en/index.html?id=34",
    urltwo: "NA"
  },
  {
    id: "alzheimers-and-dementia-diagnosis-assessment-and-disease-monitoring",
    text: "Alzheimer's &amp; Dementia: Diagnosis, Assessment &amp; Disease Monitoring",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "alzheimers-and-dementia-the-journal-of-the-alzheimers-association",
    text: "Alzheimer's &amp; Dementia: The Journal of the Alzheimer's Association",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "alzheimers-and-dementia-translational-research-and-clinical-interventions",
    text: "Alzheimer's &amp; Dementia: Translational Research &amp; Clinical Interventions",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "alzheimers-research-and-therapy",
    text: "Alzheimer's Research &amp; Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "amar-analytic-methods-in-accident-research",
    text: "AMAR Analytic Methods in Accident Research.",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "amb-express",
    text: "AMB Express",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ambio",
    text: "AMBIO",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Instructions_for_authors_AMBIO_2013.pdf",
    urltwo: "NA"
  },
  {
    id: "ameghiniana",
    text: "Ameghiniana",
    parent: "NA",
    urlone: "http://www.ameghiniana.org.ar/guidelines/EDITORIAL-GUIDELINES.pdf",
    urltwo: "NA"
  },
  {
    id: "american-anthropological-association",
    text: "American Anthropological Association",
    parent: "NA",
    urlone: "http://www.aaanet.org/publications/style_guide.pdf",
    urltwo: "NA"
  },
  {
    id: "american-antiquity",
    text: "American Antiquity",
    parent: "society-for-american-archaeology",
    urlone: "http://www.saa.org/AbouttheSociety/Publications/StyleGuide/tabid/984/Default.aspx",
    urltwo: "NA"
  },
  {
    id: "american-association-for-cancer-research",
    text: "American Association for Cancer Research",
    parent: "NA",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "american-association-of-petroleum-geologists",
    text: "American Association of Petroleum Geologists",
    parent: "NA",
    urlone: "http://deg.aapg.org/Portals/0/documents/DEG_ALL.pdf",
    urltwo: "NA"
  },
  {
    id: "american-chemical-society",
    text: "American Chemical Society",
    parent: "NA",
    urlone: "https://pubs.acs.org/doi/pdf/10.1021/bk-2006-STYG.ch014",
    urltwo: "NA"
  },
  {
    id: "american-educational-research-journal",
    text: "American Educational Research Journal",
    parent: "apa",
    urlone: "http://aer.sagepub.com/",
    urltwo: "NA"
  },
  {
    id: "american-entomologist",
    text: "American Entomologist",
    parent: "entomological-society-of-america",
    urlone: "http://www.entsoc.org/pubs/publish/style",
    urltwo: "NA"
  },
  {
    id: "american-family-physician",
    text: "American Family Physician",
    parent: "vancouver",
    urlone: "http://www.aafp.org/online/en/home/publications/journals/afp/afpauthors.html#Parsys53772",
    urltwo: "NA"
  },
  {
    id: "american-fisheries-society",
    text: "American Fisheries Society",
    parent: "NA",
    urlone: "http://fisheries.org/docs/pub_stylefl.pdf",
    urltwo: "NA"
  },
  {
    id: "american-geophysical-union",
    text: "American Geophysical Union",
    parent: "NA",
    urlone: "http://publications.agu.org/brief-guide-agu-style-grammar/",
    urltwo: "NA"
  },
  {
    id: "american-heart-association",
    text: "American Heart Association",
    parent: "NA",
    urlone:
      "http://my.americanheart.org/professional/Research/FundingOpportunities/SupportingInformation/Literature-Cited_UCM_320683_Article.jsp#.TxfjyVGJGHU",
    urltwo: "NA"
  },
  {
    id: "american-heart-journal",
    text: "American Heart Journal",
    parent: "vancouver",
    urlone: "http://www.elsevier.com/journals/american-heart-journal/0002-8703/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "american-institute-of-aeronautics-and-astronautics",
    text: "American Institute of Aeronautics and Astronautics",
    parent: "NA",
    urlone: "https://www.aiaa.org/publications/journals/reference-style-and-format",
    urltwo: "NA"
  },
  {
    id: "american-institute-of-physics",
    text: "American Institute of Physics",
    parent: "NA",
    urlone: "http://www.aip.org/pubservs/style/4thed/toc.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-agricultural-economics",
    text: "American Journal of Agricultural Economics",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/ajae/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-alzheimers-disease-and-other-dementias",
    text: "American Journal of Alzheimer's Disease &amp; Other Dementias",
    parent: "american-medical-association",
    urlone: "http://www.sagepub.com/journalsProdDesc.nav?prodId=Journal201798#tabview=manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-archaeology",
    text: "American Journal of Archaeology",
    parent: "NA",
    urlone: "https://www.ajaonline.org/submissions/references",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-botany",
    text: "American Journal of Botany",
    parent: "NA",
    urlone: "http://www.botany.org/ajb/ajb_Lit_Cited_Instructions.pdf",
    urltwo: "https://onlinelibrary.wiley.com/page/journal/15372197/homepage/ForAuthors.html"
  },
  {
    id: "american-journal-of-cardiovascular-drugs",
    text: "American Journal of Cardiovascular Drugs",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "american-journal-of-climate-change",
    text: "American Journal of Climate Change",
    parent: "NA",
    urlone: "http://www.scirp.org/journal/ajcc/",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-clinical-dermatology",
    text: "American Journal of Clinical Dermatology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "american-journal-of-clinical-pathology",
    text: "American Journal of Clinical Pathology",
    parent: "NA",
    urlone: "http://ajcp.ascpjournals.org/site/misc/ifora.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-community-psychology",
    text: "American Journal of Community Psychology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "american-journal-of-criminal-justice",
    text: "American Journal of Criminal Justice",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "american-journal-of-dance-therapy",
    text: "American Journal of Dance Therapy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "american-journal-of-enology-and-viticulture",
    text: "American Journal of Enology and Viticulture",
    parent: "NA",
    urlone: "http://www.ajevonline.org/content/authors",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-epidemiology",
    text: "American Journal of Epidemiology",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/aje/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-gastroenterology-supplements",
    text: "American Journal of Gastroenterology Supplements",
    parent: "the-american-journal-of-gastroenterology",
    urlone: "http://www.nature.com/ajgsup/gta.pdf",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-health-behavior",
    text: "American Journal of Health Behavior",
    parent: "NA",
    urlone: "http://www.ajhb.org/submission/guidelines.htm",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-health-system-pharmacy",
    text: "American Journal of Health-System Pharmacy",
    parent: "american-medical-association",
    urlone: "http://www.ashp.org/DocLibrary/AJHP/References.aspx",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-hypertension",
    text: "American Journal of Hypertension",
    parent: "NA",
    urlone: "https://academic.oup.com/ajh/pages/general_instructions#FormatofManuscripts",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-industrial-medicine",
    text: "American Journal of Industrial Medicine",
    parent: "american-medical-association",
    urlone: "https://onlinelibrary.wiley.com/page/journal/10970274/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-infection-control",
    text: "American Journal of Infection Control",
    parent: "vancouver",
    urlone: "http://www.ajicjournal.org/authorinfo",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-kidney-diseases",
    text: "American Journal of Kidney Diseases",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-medical-genetics",
    text: "American Journal of Medical Genetics",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291552-4833/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-nephrology",
    text: "American Journal of Nephrology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9670",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-neuroradiology",
    text: "American Journal of Neuroradiology",
    parent: "NA",
    urlone: "http://www.ajnr.org/site/misc/ifora.xhtml#ref",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-obstetrics-and-gynecology",
    text: "American Journal of Obstetrics &amp; Gynecology",
    parent: "american-medical-association",
    urlone:
      "http://www.elsevier.com/journals/american-journal-of-obstetrics-and-gynecology/0002-9378/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-ophthalmology-case-reports",
    text: "American Journal of Ophthalmology Case Reports",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-ophthalmology",
    text: "American Journal of Ophthalmology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-orthodontics-and-dentofacial-orthopedics",
    text: "American Journal of Orthodontics &amp; Dentofacial Orthopedics",
    parent: "NA",
    urlone: "http://journals.elsevierhealth.com/periodicals/ymod/authorinfo",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-orthopsychiatry",
    text: "American Journal of Orthopsychiatry",
    parent: "apa",
    urlone: "http://content.apa.org/journals/ort",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-otolaryngology-head-and-neck-medicine-and-surgery",
    text: "American Journal of Otolaryngology--Head and Neck Medicine and Surgery",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-physical-anthropology",
    text: "American Journal of Physical Anthropology",
    parent: "apa-6th-edition",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291096-8644/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-plant-sciences",
    text: "American Journal of Plant Sciences",
    parent: "NA",
    urlone: "http://www.scirp.org/journal/ajps/",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-political-science",
    text: "American Journal of Political Science",
    parent: "NA",
    urlone: "http://www.ajps.org/AJPS%20Style%20Guide.pdf",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-potato-research",
    text: "American Journal of Potato Research",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "american-journal-of-primatology",
    text: "American Journal of Primatology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1098-2345/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-public-health",
    text: "American Journal of Public Health",
    parent: "american-medical-association",
    urlone: "http://ajph.aphapublications.org/page/authors.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-reproductive-immunology",
    text: "American Journal of Reproductive Immunology",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1600-0897/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-respiratory-and-critical-care-medicine",
    text: "American Journal of Respiratory and Critical Care Medicine",
    parent: "NA",
    urlone: "http://www.atsjournals.org/page/AJRCCM/instructions_for_contributors",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-roentgenology",
    text: "American Journal of Roentgenology",
    parent: "vancouver",
    urlone: "http://www.ajronline.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-science",
    text: "American Journal of Science",
    parent: "NA",
    urlone: "http://www.ajsonline.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-sonography",
    text: "American Journal of Sonography",
    parent: "NA",
    urlone: "https://americanjs.com/instructions-for-authors.php",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-surgical-pathology",
    text: "American Journal of Surgical Pathology",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/ajsp/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-translational-research",
    text: "American Journal of Translational Research",
    parent: "NA",
    urlone: "http://www.ajtr.org/guidelines.html",
    urltwo: "NA"
  },
  {
    id: "american-journal-of-veterinary-research",
    text: "American Journal of Veterinary Research",
    parent: "american-veterinary-medical-association",
    urlone: "https://www.avma.org/News/Journals/Pages/ajvr-manuscript-style.aspx",
    urltwo: "NA"
  },
  {
    id: "american-marketing-association",
    text: "American Marketing Association",
    parent: "NA",
    urlone: "https://www.ama.org/submission-guidelines-journal-of-marketing/",
    urltwo: "https://www.ama.org/american-marketing-association-journals-reference-style-examples/"
  },
  {
    id: "american-medical-association-10th-edition",
    text: "American Medical Association 10th edition",
    parent: "NA",
    urlone: "https://westlibrary.txwes.edu/sites/default/files/pdf/AMACitationStyle.pdf",
    urltwo: "NA"
  },
  {
    id: "american-medical-association-alphabetical",
    text: "American Medical Association 11th edition (sorted alphabetically)",
    parent: "NA",
    urlone: "https://westlibrary.txwes.edu/sites/default/files/pdf/AMACitationStyle.pdf",
    urltwo: "https://www.amamanualofstyle.com/fileasset/AMAMOS/aaaAMWA%20presentation%20Nov%202019%20FULL.pdf"
  },
  {
    id: "american-medical-association-no-et-al",
    text: 'American Medical Association 11th edition (no "et al.")',
    parent: "NA",
    urlone: "https://westlibrary.txwes.edu/sites/default/files/pdf/AMACitationStyle.pdf",
    urltwo: "https://www.amamanualofstyle.com/fileasset/AMAMOS/aaaAMWA%20presentation%20Nov%202019%20FULL.pdf"
  },
  {
    id: "american-medical-association-no-url",
    text: "American Medical Association 11th edition (no URL)",
    parent: "NA",
    urlone: "https://westlibrary.txwes.edu/sites/default/files/pdf/AMACitationStyle.pdf",
    urltwo: "https://www.amamanualofstyle.com/fileasset/AMAMOS/aaaAMWA%20presentation%20Nov%202019%20FULL.pdf"
  },
  {
    id: "american-medical-association",
    text: "American Medical Association 11th edition",
    parent: "NA",
    urlone: "https://westlibrary.txwes.edu/sites/default/files/pdf/AMACitationStyle.pdf",
    urltwo: "https://www.amamanualofstyle.com/fileasset/AMAMOS/aaaAMWA%20presentation%20Nov%202019%20FULL.pdf"
  },
  {
    id: "american-medical-writers-association-journal",
    text: "American Medical Writers Association Journal",
    parent: "american-medical-association",
    urlone: "http://www.amwa.org/default/publications/journal/journalinstructions.pdf",
    urltwo: "NA"
  },
  {
    id: "american-meteorological-society",
    text: "American Meteorological Society",
    parent: "NA",
    urlone:
      "https://www.ametsoc.org/ams/index.cfm/publications/authors/journal-and-bams-authors/formatting-and-manuscript-components/references/",
    urltwo: "NA"
  },
  {
    id: "american-mineralogist",
    text: "American Mineralogist",
    parent: "NA",
    urlone: "http://www.minsocam.org/msa/AmMin/All_About_References.html",
    urltwo: "NA"
  },
  {
    id: "american-nuclear-society",
    text: "American Nuclear Society",
    parent: "NA",
    urlone: "http://cdn.ans.org/pubs/journals/fst/docs/fst-ref-style.pdf",
    urltwo: "NA"
  },
  {
    id: "american-physical-society-et-al",
    text: "American Physical Society - et al. (if more than 3 authors)",
    parent: "NA",
    urlone: "https://cdn.journals.aps.org/files/styleguide-pr.pdf",
    urltwo: "NA"
  },
  {
    id: "american-physics-society-without-titles",
    text: "American Physical Society (without titles)",
    parent: "NA",
    urlone: "https://journals.aps.org/prl/authors#techformat",
    urltwo: "NA"
  },
  {
    id: "american-physics-society",
    text: "American Physical Society",
    parent: "NA",
    urlone: "https://journals.aps.org/prl/authors#techformat",
    urltwo: "NA"
  },
  {
    id: "american-physiological-society",
    text: "American Physiological Society",
    parent: "NA",
    urlone: "https://journals.physiology.org/author-info.manuscript-composition#EXAMPLE%20REFERENCES",
    urltwo: "NA"
  },
  {
    id: "american-phytopathological-society",
    text: "American Phytopathological Society",
    parent: "NA",
    urlone: "http://apsjournals.apsnet.org/userimages/ContentEditor/1173286505152/phyto_author_instructions.pdf",
    urltwo: "NA"
  },
  {
    id: "american-political-science-association",
    text: "American Political Science Association",
    parent: "NA",
    urlone: "http://www.apsanet.org/media/PDFs/Publications/APSAStyleManual2006.pdf",
    urltwo: "NA"
  },
  {
    id: "american-political-science-review",
    text: "American Political Science Review",
    parent: "american-political-science-association",
    urlone: "https://www.apsanet.org/content_43805.cfm",
    urltwo: "NA"
  },
  {
    id: "american-psychologist",
    text: "American Psychologist",
    parent: "apa",
    urlone: "http://content.apa.org/journals/amp",
    urltwo: "NA"
  },
  {
    id: "american-review-of-canadian-studies",
    text: "American Review of Canadian Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RARC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "american-school-of-classical-studies-at-athens",
    text: "American School of Classical Studies at Athens",
    parent: "NA",
    urlone: "https://www.ascsa.edu.gr/uploads/media/Guidelines10-29-18.pdf",
    urltwo: "NA"
  },
  {
    id: "american-society-for-microbiology",
    text: "American Society for Microbiology",
    parent: "NA",
    urlone: "http://aem.asm.org/misc/journal-ita_org.dtl#03",
    urltwo: "NA"
  },
  {
    id: "american-society-for-pharmacology-and-experimental-therapeutics",
    text: "American Society for Pharmacology and Experimental Therapeutics",
    parent: "NA",
    urlone: "http://molpharm.aspetjournals.org/site/misc/ifora.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "american-society-of-agricultural-and-biological-engineers",
    text: "American Society of Agricultural and Biological Engineers",
    parent: "NA",
    urlone: "http://www.asabe.org/media/59251/asabe_guide_for_authors.pdf",
    urltwo: "NA"
  },
  {
    id: "american-society-of-civil-engineers",
    text: "American Society of Civil Engineers",
    parent: "NA",
    urlone: "https://doi.org/10.1061/9780784478998.ch17",
    urltwo: "https://doi.org/10.1061/9780784479018.ch05"
  },
  {
    id: "american-society-of-mechanical-engineers",
    text: "American Society of Mechanical Engineers",
    parent: "NA",
    urlone: "https://www.asme.org/shop/proceedings/conference-publications/references",
    urltwo: "NA"
  },
  {
    id: "american-sociological-association",
    text: "American Sociological Association 6th edition",
    parent: "NA",
    urlone: "http://owl.english.purdue.edu/owl/resource/583/03/",
    urltwo: "NA"
  },
  {
    id: "american-sociological-review",
    text: "American Sociological Review",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/asr/american_sociological_review.cfm",
    urltwo: "NA"
  },
  {
    id: "american-statistical-association",
    text: "American Statistical Association",
    parent: "NA",
    urlone: "http://amstat.tfjournals.com/asa-style-guide/",
    urltwo: "NA"
  },
  {
    id: "american-veterinary-medical-association",
    text: "American Veterinary Medical Association",
    parent: "NA",
    urlone: "https://www.avma.org/News/Journals/Pages/javma-manuscript-style.aspx",
    urltwo: "https://www.avma.org/News/Journals/Pages/ajvr-manuscript-style.aspx"
  },
  {
    id: "amerindia",
    text: "Amerindia",
    parent: "NA",
    urlone: "https://www.vjf.cnrs.fr/sedyl/revues.php?voirintro=O&amp;langue=en&amp;revue=Amerindia&amp;aire=CELIA",
    urltwo: "https://www.vjf.cnrs.fr/sedyl/images/Divers_Doc/Instructions_authors.pdf"
  },
  {
    id: "amino-acids",
    text: "Amino Acids",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ampersand",
    text: "Ampersand",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "amphibia-reptilia",
    text: "Amphibia-Reptilia",
    parent: "NA",
    urlone: "http://www.brill.com/amphibia-reptilia",
    urltwo: "NA"
  },
  {
    id: "ams-review",
    text: "AMS Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "amsterdam-university-press-academic",
    text: "Amsterdam University Press - Academic",
    parent: "NA",
    urlone: " https://www.aup.nl/en/publish/author-guidance",
    urltwo:
      "https://assets.ctfassets.net/w9b4jh0bui0y/2Zq8jT4ld6CgKo6Iig2IW/7687b7dc5ecb4febe678569476d29871/author_instructions_april_2018.pdf"
  },
  {
    id: "anabases",
    text: "Anabases (French)",
    parent: "NA",
    urlone: "https://anabases.revues.org/1358",
    urltwo: "NA"
  },
  {
    id: "anaerobe",
    text: "Anaerobe",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "anaesthesia-and-intensive-care",
    text: "Anaesthesia and Intensive Care",
    parent: "vancouver",
    urlone: "http://www.aaic.net.au/pages/10318/Editorial-Policies-and-Instructions-for-Authors#References",
    urltwo: "NA"
  },
  {
    id: "anaesthesia-critical-care-and-pain-medicine",
    text: "Anaesthesia Critical Care &amp; Pain Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "anaesthesia",
    text: "Anaesthesia",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2044/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "anales-de-antropologia",
    text: "Anales de AntropologÃ­a",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "anales-de-pediatria",
    text: "Anales de PediatrÃ­a (Spanish)",
    parent: "vancouver-superscript-only-year",
    urlone: "http://www.elsevier.es/sites/default/files/elsevier/NormOrga/037normas.pdf",
    urltwo: "NA"
  },
  {
    id: "analog-integrated-circuits-and-signal-processing",
    text: "Analog Integrated Circuits and Signal Processing",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "analysis-and-mathematical-physics",
    text: "Analysis and Mathematical Physics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "analyst",
    text: "Analyst",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "analytic-methods-in-accident-research",
    text: "Analytic Methods in Accident Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "analytica-chimica-acta-x",
    text: "Analytica Chimica Acta: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "analytica-chimica-acta",
    text: "Analytica Chimica Acta",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "analytical-and-bioanalytical-chemistry",
    text: "Analytical and Bioanalytical Chemistry",
    parent: "springer-basic-brackets-no-et-al",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "analytical-biochemistry",
    text: "Analytical Biochemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "analytical-chemistry-research",
    text: "Analytical Chemistry Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "analytical-chemistry",
    text: "Analytical Chemistry",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/ancham/ancham_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "analytical-methods",
    text: "Analytical Methods",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "analytical-sciences",
    text: "Analytical Sciences",
    parent: "NA",
    urlone: "http://www.jsac.or.jp/analsci/data/instr/InstAuthor-13-1.pdf",
    urltwo: "NA"
  },
  {
    id: "anatolia",
    text: "Anatolia",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RANA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "anatomia-histologia-embryologia",
    text: "Anatomia, Histologia, Embryologia",
    parent: "apa",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14390264/homepage/forauthors.html#manuscript",
    urltwo: "NA"
  },
  {
    id: "anatomical-science-international",
    text: "Anatomical Science International",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "anatomical-sciences-education",
    text: "Anatomical Sciences Education",
    parent: "NA",
    urlone: "https://anatomypubs.onlinelibrary.wiley.com/hub/journal/19359780/homepage/forauthors.html#5",
    urltwo: "NA"
  },
  {
    id: "ancilla-iuris",
    text: "Ancilla Iuris",
    parent: "NA",
    urlone: "https://www.anci.ch/about",
    urltwo: "NA"
  },
  {
    id: "andean-geology",
    text: "Andean Geology",
    parent: "NA",
    urlone: "http://www.andeangeology.cl/index.php/revista1/about/submissions",
    urltwo: "NA"
  },
  {
    id: "anesthesia-and-analgesia",
    text: "Anesthesia and Analgesia",
    parent: "NA",
    urlone: "http://www.aaeditor.org/GuideforAuthors.pdf",
    urltwo: "NA"
  },
  {
    id: "anesthesie-and-reanimation",
    text: "AnesthÃ©sie &amp; RÃ©animation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "anesthesiology",
    text: "Anesthesiology",
    parent: "NA",
    urlone: "http://journals.lww.com/anesthesiology/Pages/completeinstructionsforauthors.aspx#references",
    urltwo: "NA"
  },
  {
    id: "angelaki",
    text: "Angelaki",
    parent: "modern-language-association-6th-edition-note",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=cang&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "angewandte-chemie",
    text: "Angewandte Chemie International Edition",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/15213773/homepage/notice-to-authors",
    urltwo: "NA"
  },
  {
    id: "angiogenesis",
    text: "Angiogenesis",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "angiologia",
    text: "Angiologia (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/angiologia/0003-3170/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "anglia",
    text: "Anglia",
    parent: "NA",
    urlone: "https://www.degruyter.com/view/supplement/s18658938_Style_sheet.pdf",
    urltwo: "NA"
  },
  {
    id: "animal-behaviour",
    text: "Animal Behaviour",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "animal-biotelemetry",
    text: "Animal Biotelemetry",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "animal-cells-and-systems",
    text: "Animal Cells and Systems",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TACS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "animal-cognition",
    text: "Animal Cognition",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "animal-conservation",
    text: "Animal Conservation",
    parent: "NA",
    urlone:
      "http://zslpublications.onlinelibrary.wiley.com/hub/journal/10.1111/(ISSN)1469-1795/about/author-guidelines.html",
    urltwo: "NA"
  },
  {
    id: "animal-feed-science-and-technology",
    text: "Animal Feed Science and Technology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "animal-gene",
    text: "Animal Gene",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "animal-migration",
    text: "Animal Migration",
    parent: "NA",
    urlone: "https://www.degruyter.com/view/supplement/s20848838_Instruction_for_Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "animal-nutrition",
    text: "Animal Nutrition",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "animal-production-science",
    text: "Animal Production Science",
    parent: "international-journal-of-wildland-fire",
    urlone: "http://www.publish.csiro.au/nid/75/aid/376.htm#14",
    urltwo: "NA"
  },
  {
    id: "animal-reproduction-science",
    text: "Animal Reproduction Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "animal-welfare",
    text: "Animal Welfare",
    parent: "NA",
    urlone: "https://www.ufaw.org.uk/the-ufaw-journal/instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "animal",
    text: "animal",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/journals/animal/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "animals",
    text: "Animals",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "annalen-des-naturhistorischen-museums-in-wien",
    text: "Annalen des Naturhistorischen Museums in Wien",
    parent: "NA",
    urlone: "http://www.nhm-wien.ac.at/jart/prj3/nhm/data/uploads/archiv_annalen/Instructions_2011.pdf",
    urltwo: "NA"
  },
  {
    id: "annales-de-cardiologie-et-dangeiologie",
    text: "Annales de cardiologie et d'angÃ©iologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-de-chirurgie-plastique-esthetique",
    text: "Annales de chirurgie plastique esthÃ©tique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-de-chirurgie-vasculaire",
    text: "Annales de Chirurgie Vasculaire",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-de-demographie-historique",
    text: "Annales de dÃ©mographie historique",
    parent: "NA",
    urlone: "http://www.societededemographiehistorique.fr/les-annales-de-deacutemographie-historique.html",
    urltwo: "NA"
  },
  {
    id: "annales-de-dermatologie-et-de-venereologie",
    text: "Annales de Dermatologie et de VÃ©nÃ©rÃ©ologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-de-linstitut-henri-poincare-analyse-non-lineaire",
    text: "Annales de l'Institut Henri PoincarÃ© / Analyse non linÃ©aire",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-de-paleontologie",
    text: "Annales de PalÃ©ontologie",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-de-pathologie",
    text: "Annales de Pathologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-dendocrinologie",
    text: "Annales d'Endocrinologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-francaises-danesthesie-et-de-reanimation",
    text: "Annales FranÃ§aises d'AnesthÃ©sie et de RÃ©animation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-francaises-doto-rhino-laryngologie-et-de-pathologie-cervico-faciale",
    text: "Annales franÃ§aises d'oto-rhino-laryngologie et de pathologie cervico-faciale",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-geophysicae",
    text: "Annales Geophysicae",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-henri-poincare",
    text: "Annales Henri PoincarÃ©",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annales-mathematiques-du-quebec",
    text: "Annales mathÃ©matiques du QuÃ©bec",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annales-medico-psychologiques",
    text: "Annales mÃ©dico-psychologiques",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales-pharmaceutiques-francaises",
    text: "Annales Pharmaceutiques FranÃ§aises",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annales",
    text: "Annales. Histoire, Sciences sociales (French)",
    parent: "NA",
    urlone: "http://annales.ehess.fr/?lang=en/",
    urltwo: "NA"
  },
  {
    id: "annali-delluniversita-di-ferrara",
    text: "ANNALI DELL'UNIVERSITA' DI FERRARA",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annali-di-matematica-pura-ed-applicata",
    text: "Annali di Matematica Pura ed Applicata",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-academy-of-medicine-singapore",
    text: "Annals, Academy of Medicine, Singapore",
    parent: "vancouver",
    urlone: "http://www.annals.edu.sg/instructions.html",
    urltwo: "NA"
  },
  {
    id: "annals-of-agrarian-science",
    text: "Annals of Agrarian Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-agricultural-sciences",
    text: "Annals of Agricultural Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-allergy-asthma-and-immunology",
    text: "Annals of Allergy, Asthma &amp; Immunology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-anatomy",
    text: "Annals of Anatomy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-applied-biology",
    text: "Annals of Applied Biology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291744-7348/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "annals-of-behavioral-medicine",
    text: "Annals of Behavioral Medicine",
    parent: "NA",
    urlone: "http://www.springer.com/medicine/journal/12160#",
    urltwo: "NA"
  },
  {
    id: "annals-of-behavioral-science-and-medical-education",
    text: "Annals of Behavioral Science and Medical Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-biomedical-engineering",
    text: "Annals of Biomedical Engineering",
    parent: "NA",
    urlone: "http://www.springer.com/biomed/journal/10439",
    urltwo: "NA"
  },
  {
    id: "annals-of-botany",
    text: "Annals of Botany",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/annbot/for_authors/index.html",
    urltwo: "NA"
  },
  {
    id: "annals-of-clinical-and-laboratory-science",
    text: "Annals of Clinical &amp; Laboratory Science",
    parent: "vancouver",
    urlone: "http://www.annclinlabsci.org/site/misc/authinstruct.pdf",
    urltwo: "NA"
  },
  {
    id: "annals-of-clinical-biochemistry",
    text: "Annals of Clinical Biochemistry",
    parent: "vancouver",
    urlone: "http://acb.rsmjournals.com/site/misc/authors.xhtml",
    urltwo: "NA"
  },
  {
    id: "annals-of-clinical-microbiology-and-antimicrobials",
    text: "Annals of Clinical Microbiology and Antimicrobials",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-data-science",
    text: "Annals of Data Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-diagnostic-pathology",
    text: "Annals of Diagnostic Pathology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-dyslexia",
    text: "Annals of Dyslexia",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-emergency-medicine",
    text: "Annals of Emergency Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-epidemiology",
    text: "Annals of Epidemiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-finance",
    text: "Annals of Finance",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-forest-science",
    text: "Annals of Forest Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-general-psychiatry",
    text: "Annals of General Psychiatry",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-global-analysis-and-geometry",
    text: "Annals of Global Analysis and Geometry",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-global-health",
    text: "Annals of Global Health",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-hematology",
    text: "Annals of Hematology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-intensive-care",
    text: "Annals of Intensive Care",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-internal-medicine",
    text: "Annals of Internal Medicine",
    parent: "vancouver",
    urlone: "http://annals.org/public/authorsinfo.aspx#documents-cited",
    urltwo: "NA"
  },
  {
    id: "annals-of-joint",
    text: "Annals of Joint",
    parent: "NA",
    urlone: "http://aoj.amegroups.com/public/addition/aoj/aoj-instruction-for-authors.pdf",
    urltwo: "NA"
  },
  {
    id: "annals-of-leisure-research",
    text: "Annals of Leisure Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RANZ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "annals-of-mathematics-and-artificial-intelligence",
    text: "Annals of Mathematics and Artificial Intelligence",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-medicine-and-surgery",
    text: "Annals of Medicine and Surgery",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-microbiology",
    text: "Annals of Microbiology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-neurology",
    text: "Annals of Neurology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291531-8249/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "annals-of-nuclear-energy",
    text: "Annals of Nuclear Energy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-nuclear-medicine",
    text: "Annals of Nuclear Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-nutrition-and-metabolism",
    text: "Annals of Nutrition &amp; Metabolism",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9697",
    urltwo: "NA"
  },
  {
    id: "annals-of-occupational-and-environmental-medicine",
    text: "Annals of Occupational and Environmental Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-oncology",
    text: "Annals of Oncology",
    parent: "NA",
    urlone: "www.oxfordjournals.org/our_journals/annonc/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "annals-of-operations-research",
    text: "Annals of Operations Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-otology-rhinology-and-laryngology",
    text: "Annals of Otology, Rhinology and Laryngology",
    parent: "vancouver",
    urlone: "http://www.annals.com/",
    urltwo: "NA"
  },
  {
    id: "annals-of-physical-and-rehabilitation-medicine",
    text: "Annals of Physical and Rehabilitation Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-physics",
    text: "Annals of Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-saudi-medicine",
    text: "Annals of Saudi Medicine",
    parent: "vancouver",
    urlone: "http://www.annsaudimed.net/",
    urltwo: "NA"
  },
  {
    id: "annals-of-solid-and-structural-mechanics",
    text: "Annals of Solid and Structural Mechanics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-surgery",
    text: "Annals of Surgery",
    parent: "NA",
    urlone: "http://journals.lww.com/annalsofsurgery/_layouts/15/1033/oaks.journals/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "annals-of-surgical-innovation-and-research",
    text: "Annals of Surgical Innovation and Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-telecommunications-annales-des-telecommunications",
    text: "annals of telecommunications - annales des tÃ©lÃ©communications",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-the-association-of-american-geographers",
    text: "Annals of the Association of American Geographers",
    parent: "NA",
    urlone: "http://www.aag.org/libraries/cm_journals/ANNALS_STYLE_SHEET_61912.pdf",
    urltwo: "NA"
  },
  {
    id: "annals-of-the-entomological-society-of-america",
    text: "Annals of the Entomological Society of America",
    parent: "entomological-society-of-america",
    urlone: "http://www.entsoc.org/pubs/publish/style",
    urltwo: "NA"
  },
  {
    id: "annals-of-the-icrp",
    text: "Annals of the ICRP",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-the-institute-of-statistical-mathematics",
    text: "Annals of the Institute of Statistical Mathematics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "annals-of-the-new-york-academy-of-sciences",
    text: "Annals of the New York Academy of Sciences",
    parent: "NA",
    urlone: "https://www.nyas.org/Publications/Annals/AuthorGuidelines.aspx",
    urltwo: "NA"
  },
  {
    id: "annals-of-the-rheumatic-diseases",
    text: "Annals of the Rheumatic Diseases",
    parent: "bmj",
    urlone: "http://ard.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "annals-of-the-royal-college-of-surgeons-of-england",
    text: "Annals of the Royal College of Surgeons of England",
    parent: "vancouver",
    urlone: "http://www.rcseng.ac.uk/publications/submissions/authorinstructions.html",
    urltwo: "NA"
  },
  {
    id: "annals-of-tourism-research",
    text: "Annals of Tourism Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annals-of-tropical-paediatrics",
    text: "Annals of Tropical Paediatrics",
    parent: "vancouver",
    urlone: "http://maneypublishing.com/index.php/journals/pch/",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-analytical-chemistry",
    text: "Annual Review of Analytical Chemistry",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-animal-biosciences",
    text: "Annual Review of Animal Biosciences",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-anthropology",
    text: "Annual Review of Anthropology",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-astronomy-and-astrophysics",
    text: "Annual Review of Astronomy and Astrophysics",
    parent: "NA",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-biochemistry",
    text: "Annual Review of Biochemistry",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-biomedical-engineering",
    text: "Annual Review of Biomedical Engineering",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-biophysics",
    text: "Annual Review of Biophysics",
    parent: "annual-reviews-alphabetical",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-cell-and-developmental-biology",
    text: "Annual Review of Cell and Developmental Biology",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-chemical-and-biomolecular-engineering",
    text: "Annual Review of Chemical and Biomolecular Engineering",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-clinical-psychology",
    text: "Annual Review of Clinical Psychology",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-condensed-matter-physics",
    text: "Annual Review of Condensed Matter Physics",
    parent: "annual-reviews-without-titles",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-earth-and-planetary-sciences",
    text: "Annual Review of Earth and Planetary Sciences",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-ecology-evolution-and-systematics",
    text: "Annual Review of Ecology, Evolution, and Systematics",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-economics",
    text: "Annual Review of Economics",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-entomology",
    text: "Annual Review of Entomology",
    parent: "annual-reviews-alphabetical",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-environment-and-resources",
    text: "Annual Review of Environment and Resources",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-financial-economics",
    text: "Annual Review of Financial Economics",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-fluid-mechanics",
    text: "Annual Review of Fluid Mechanics",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-food-science-and-technology",
    text: "Annual Review of Food Science and Technology",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-genetics",
    text: "Annual Review of Genetics",
    parent: "annual-reviews-alphabetical",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-genomics-and-human-genetics",
    text: "Annual Review of Genomics and Human Genetics",
    parent: "annual-reviews-alphabetical",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-immunology",
    text: "Annual Review of Immunology",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-law-and-social-science",
    text: "Annual Review of Law and Social Science",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-marine-science",
    text: "Annual Review of Marine Science",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-materials-research",
    text: "Annual Review of Materials Research",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-medicine",
    text: "Annual Review of Medicine",
    parent: "NA",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-microbiology",
    text: "Annual Review of Microbiology",
    parent: "annual-reviews-alphabetical",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-neuroscience",
    text: "Annual Review of Neuroscience",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-nuclear-and-particle-science",
    text: "Annual Review of Nuclear and Particle Science",
    parent: "NA",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-nutrition",
    text: "Annual Review of Nutrition",
    parent: "annual-reviews-alphabetical",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-organizational-psychology-and-organizational-behavior",
    text: "Annual Review of Organizational Psychology and Organizational Behavior",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-pathology-mechanisms-of-disease",
    text: "Annual Review of Pathology: Mechanisms of Disease",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-pharmacology-and-toxicology",
    text: "Annual Review of Pharmacology and Toxicology",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-physical-chemistry",
    text: "Annual Review of Physical Chemistry",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-physiology",
    text: "Annual Review of Physiology",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-phytopathology",
    text: "Annual Review of Phytopathology",
    parent: "annual-reviews-alphabetical",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-plant-biology",
    text: "Annual Review of Plant Biology",
    parent: "annual-reviews-alphabetical",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-political-science",
    text: "Annual Review of Political Science",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-psychology",
    text: "Annual Review of Psychology",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-public-health",
    text: "Annual Review of Public Health",
    parent: "annual-reviews-alphabetical",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-resource-economics",
    text: "Annual Review of Resource Economics",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-sociology",
    text: "Annual Review of Sociology",
    parent: "annual-reviews-author-date",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-statistics-and-its-application",
    text: "Annual Review of Statistics and Its Application",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-review-of-virology",
    text: "Annual Review of Virology",
    parent: "annual-reviews",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-reviews-alphabetical",
    text: "Annual Reviews (sorted alphabetically)",
    parent: "NA",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-reviews-author-date",
    text: "Annual Reviews (author-date)",
    parent: "NA",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-reviews-in-control",
    text: "Annual Reviews in Control",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "annual-reviews-without-titles",
    text: "Annual Reviews (sorted by order of appearance, without titles)",
    parent: "NA",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "annual-reviews",
    text: "Annual Reviews (sorted by order of appearance)",
    parent: "NA",
    urlone: "http://www.annualreviews.org/page/authors/author-instructions/preparing/handbooks",
    urltwo: "NA"
  },
  {
    id: "ansiedad-y-estres",
    text: "Ansiedad y EstrÃ©s",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "antarctic-science",
    text: "Antarctic Science",
    parent: "NA",
    urlone: "http://journals.cambridge.org/images/fileUpload/documents/ans_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "anthropocene",
    text: "Anthropocene",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "anthropologie-et-societes",
    text: "Anthropologie et SociÃ©tÃ©s (French)",
    parent: "NA",
    urlone: "http://www.anthropologie-societes.ant.ulaval.ca/regles-de-publication/normes-editoriales",
    urltwo: "NA"
  },
  {
    id: "anthropology-and-medicine",
    text: "Anthropology &amp; Medicine",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CANM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "anthropozoologica",
    text: "Anthropozoologica",
    parent: "museum-national-dhistoire-naturelle",
    urlone:
      "http://sciencepress.mnhn.fr/sites/default/files/periodiques/pdf/ia-anthropozoologica-2018-flux-continu_en.pdf",
    urltwo: "NA"
  },
  {
    id: "antibiotics",
    text: "Antibiotics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "antibodies",
    text: "Antibodies",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "anticancer-research",
    text: "Anticancer Research",
    parent: "NA",
    urlone: "http://ar.iiarjournals.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "antimicrobial-agents-and-chemotherapy",
    text: "Antimicrobial Agents and Chemotherapy",
    parent: "american-society-for-microbiology",
    urlone: "http://aac.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "antimicrobial-resistance-and-infection-control",
    text: "Antimicrobial Resistance and Infection Control",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "antioxidants-and-redox-signaling",
    text: "Antioxidants &amp; Redox Signaling",
    parent: "NA",
    urlone: "https://home.liebertpub.com/publications/antioxidants-and-redox-signaling/4/for-authors",
    urltwo: "NA"
  },
  {
    id: "antioxidants",
    text: "Antioxidants",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "antipode",
    text: "Antipode",
    parent: "NA",
    urlone: "https://mc.manuscriptcentral.com/societyimages/anti/Final%20format%20(papers).doc",
    urltwo: "NA"
  },
  {
    id: "antiquites-africaines",
    text: "AntiquitÃ©s africaines",
    parent: "NA",
    urlone: "https://journals.openedition.org/antafr/340",
    urltwo: "NA"
  },
  {
    id: "antiquity",
    text: "Antiquity",
    parent: "NA",
    urlone: "http://journal.antiquity.ac.uk/contribute",
    urltwo: "NA"
  },
  {
    id: "antiviral-research",
    text: "Antiviral Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "antonie-van-leeuwenhoek",
    text: "Antonie van Leeuwenhoek",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "anuario-de-psicologia-juridica",
    text: "Anuario de PsicologÃ­a JurÃ­dica",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "anuario-de-psicologia",
    text: "Anuario de PsicologÃ­a",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "anxiety-stress-and-coping",
    text: "Anxiety, Stress &amp; Coping",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GASC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "aorn-journal",
    text: "AORN Journal",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "apa-5th-edition",
    text: "American Psychological Association 5th edition",
    parent: "NA",
    urlone: "http://rdc.libguides.com/content.php?pid=63487",
    urltwo: "NA"
  },
  {
    id: "apa-6th-edition-no-ampersand",
    text: "American Psychological Association 6th edition (no ampersand)",
    parent: "NA",
    urlone: "http://owl.english.purdue.edu/owl/resource/560/01/",
    urltwo: "NA"
  },
  {
    id: "apa-6th-edition",
    text: "American Psychological Association 6th edition",
    parent: "NA",
    urlone: "http://owl.english.purdue.edu/owl/resource/560/01/",
    urltwo: "NA"
  },
  {
    id: "apa-annotated-bibliography",
    text: "American Psychological Association 7th edition (annotated bibliography)",
    parent: "NA",
    urlone: "https://apastyle.apa.org/style-grammar-guidelines/references/examples",
    urltwo: "NA"
  },
  {
    id: "apa-cv",
    text: "American Psychological Association 7th edition (curriculum vitae, sorted by descending date)",
    parent: "NA",
    urlone: "https://apastyle.apa.org/style-grammar-guidelines/references/examples",
    urltwo: "NA"
  },
  {
    id: "apa-fr-provost",
    text: "American Psychological Association 6th edition (Provost) (French - Canada)",
    parent: "NA",
    urlone: "http://www.worldcat.org/oclc/693823953",
    urltwo: "NA"
  },
  {
    id: "apa-no-ampersand",
    text: "American Psychological Association 7th edition (no ampersand)",
    parent: "NA",
    urlone: "https://apastyle.apa.org/style-grammar-guidelines/references/examples",
    urltwo: "NA"
  },
  {
    id: "apa-no-doi-no-issue",
    text: "American Psychological Association 6th edition (no DOIs, no issue numbers)",
    parent: "NA",
    urlone: "http://owl.english.purdue.edu/owl/resource/560/01/",
    urltwo: "NA"
  },
  {
    id: "apa-numeric-superscript-brackets",
    text: "American Psychological Association 7th edition (numeric, brackets)",
    parent: "NA",
    urlone: "https://apastyle.apa.org/style-grammar-guidelines/references/examples",
    urltwo: "NA"
  },
  {
    id: "apa-numeric-superscript",
    text: "American Psychological Association 7th edition (numeric, superscript)",
    parent: "NA",
    urlone: "https://apastyle.apa.org/style-grammar-guidelines/references/examples",
    urltwo: "NA"
  },
  {
    id: "apa-old-doi-prefix",
    text: 'American Psychological Association 6th edition ("doi:" DOI prefix)',
    parent: "NA",
    urlone: "http://owl.english.purdue.edu/owl/resource/560/01/",
    urltwo: "NA"
  },
  {
    id: "apa-single-spaced",
    text: "American Psychological Association 7th edition (single-spaced bibliography)",
    parent: "NA",
    urlone: "https://apastyle.apa.org/style-grammar-guidelines/references/examples",
    urltwo: "NA"
  },
  {
    id: "apa-tr",
    text: "American Psychological Association 6th edition (Turkish)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/issues/240",
    urltwo: "NA"
  },
  {
    id: "apa-with-abstract",
    text: "American Psychological Association 7th edition (with abstract)",
    parent: "NA",
    urlone: "https://apastyle.apa.org/style-grammar-guidelines/references/examples",
    urltwo: "NA"
  },
  {
    id: "apa",
    text: "American Psychological Association 7th edition",
    parent: "NA",
    urlone: "https://apastyle.apa.org/style-grammar-guidelines/references/examples",
    urltwo: "NA"
  },
  {
    id: "apcbee-procedia",
    text: "APCBEE Procedia",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aphasiology",
    text: "Aphasiology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PAPH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "apl-materials",
    text: "APL Materials",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "apollo-medicine",
    text: "Apollo Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "apoptosis",
    text: "Apoptosis",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aporia-the-nursing-journal",
    text: "Aporia: The Nursing Journal",
    parent: "NA",
    urlone: "https://uottawa.scholarsportal.info/ottawa/index.php/aporia/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "appetite",
    text: "Appetite",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applicable-algebra-in-engineering-communication-and-computing",
    text: "Applicable Algebra in Engineering, Communication and Computing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applicable-analysis",
    text: "Applicable Analysis",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GAPA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "applied-acoustics",
    text: "Applied Acoustics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-adhesion-science",
    text: "Applied Adhesion Science",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-and-computational-harmonic-analysis",
    text: "Applied and Computational Harmonic Analysis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-and-environmental-microbiology",
    text: "Applied and Environmental Microbiology",
    parent: "american-society-for-microbiology",
    urlone: "http://aem.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "applied-and-translational-genomics",
    text: "Applied &amp; Translational Genomics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-animal-behaviour-science",
    text: "Applied Animal Behaviour Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-biochemistry-and-biotechnology",
    text: "Applied Biochemistry and Biotechnology",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-catalysis-a-general",
    text: "Applied Catalysis A, General",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-catalysis-b-environmental",
    text: "Applied Catalysis B: Environmental",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-categorical-structures",
    text: "Applied Categorical Structures",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-clay-science",
    text: "Applied Clay Science",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/applied-clay-science/0169-1317/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "applied-composite-materials",
    text: "Applied Composite Materials",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-computing-and-geosciences",
    text: "Applied Computing and Geosciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-computing-and-informatics",
    text: "Applied Computing and Informatics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-energy",
    text: "Applied Energy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-engineering-in-agriculture",
    text: "Applied Engineering in Agriculture",
    parent: "american-society-of-agricultural-and-biological-engineers",
    urlone: "https://doi.org/10.13031/issn.0883-8542",
    urltwo: "NA"
  },
  {
    id: "applied-entomology-and-zoology",
    text: "Applied Entomology and Zoology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-ergonomics",
    text: "Applied Ergonomics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-geochemistry",
    text: "Applied Geochemistry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-geography",
    text: "Applied Geography",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-geomatics",
    text: "Applied Geomatics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-health-economics-and-health-policy",
    text: "Applied Health Economics and Health Policy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-in-vitro-toxicology",
    text: "Applied In Vitro Toxicology",
    parent: "NA",
    urlone: "http://www.liebertpub.com/forauthors/applied-in-vitro-toxicology/626/",
    urltwo: "NA"
  },
  {
    id: "applied-informatics",
    text: "Applied Informatics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-intelligence",
    text: "Applied Intelligence",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-magnetic-resonance",
    text: "Applied Magnetic Resonance",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-materials-today",
    text: "Applied Materials Today",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-mathematical-finance",
    text: "Applied Mathematical Finance",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAMF20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "applied-mathematical-modelling",
    text: "Applied Mathematical Modelling",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-mathematics-and-computation",
    text: "Applied Mathematics and Computation",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-mathematics-and-optimization",
    text: "Applied Mathematics &amp; Optimization",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-mathematics-letters",
    text: "Applied Mathematics Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-mechanics-reviews",
    text: "Applied Mechanics Reviews",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://appliedmechanicsreviews.asmedigitalcollection.asme.org/journal.aspx?journalid=113",
    urltwo: "NA"
  },
  {
    id: "applied-microbiology-and-biotechnology",
    text: "Applied Microbiology and Biotechnology",
    parent: "springer-basic-author-date-no-et-al",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-nanomedicine",
    text: "Applied Nanomedicine",
    parent: "integrated-science-publishing-journals",
    urlone: "http://www.pubs.iscience.in/journal/index.php/appnanomed/about/submissions#onlineSubmissions",
    urltwo: "NA"
  },
  {
    id: "applied-nanoscience",
    text: "Applied Nanoscience",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-network-science",
    text: "Applied Network Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-nursing-research",
    text: "Applied Nursing Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-ocean-research",
    text: "Applied Ocean Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-optics",
    text: "Applied Optics",
    parent: "the-optical-society",
    urlone: "https://www.osapublishing.org/submit/templates/default.cfm",
    urltwo: "https://www.osapublishing.org/submit/style/oestyleguide.cfm"
  },
  {
    id: "applied-petrochemical-research",
    text: "Applied Petrochemical Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-physics-a",
    text: "Applied Physics A",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-physics-b",
    text: "Applied Physics B",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-physics-letters",
    text: "Applied Physics Letters",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "applied-physiology-nutrition-and-metabolism",
    text: "Applied Physiology, Nutrition, and Metabolism",
    parent: "canadian-journal-of-fisheries-and-aquatic-sciences",
    urlone: "http://www.nrcresearchpress.com/page/apnm/authors",
    urltwo: "NA"
  },
  {
    id: "applied-psychophysiology-and-biofeedback",
    text: "Applied Psychophysiology and Biofeedback",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-radiation-and-isotopes",
    text: "Applied Radiation and Isotopes",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-research-in-quality-of-life",
    text: "Applied Research in Quality of Life",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-sciences",
    text: "Applied Sciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "applied-soft-computing-journal",
    text: "Applied Soft Computing Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-soil-ecology",
    text: "Applied Soil Ecology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-spatial-analysis-and-policy",
    text: "Applied Spatial Analysis and Policy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "applied-spectroscopy-reviews",
    text: "Applied Spectroscopy Reviews",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=laps20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "applied-spectroscopy",
    text: "Applied Spectroscopy",
    parent: "NA",
    urlone: "http://www.s-a-s.org/journal/authguide/",
    urltwo: "NA"
  },
  {
    id: "applied-surface-science",
    text: "Applied Surface Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-thermal-engineering",
    text: "Applied Thermal Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "applied-water-science",
    text: "Applied Water Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aquacultural-engineering",
    text: "Aquacultural Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aquaculture-and-fisheries",
    text: "Aquaculture and Fisheries",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aquaculture-environment-interactions",
    text: "Aquaculture Environment Interactions",
    parent: "inter-research-science-center",
    urlone: "http://www.int-res.com/journals/aei/aei-home/",
    urltwo: "NA"
  },
  {
    id: "aquaculture-international",
    text: "Aquaculture International",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aquaculture-reports",
    text: "Aquaculture Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aquaculture",
    text: "Aquaculture",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aquatic-biology",
    text: "Aquatic Biology",
    parent: "inter-research-science-center",
    urlone: "http://www.int-res.com/journals/ab/ab-home/",
    urltwo: "NA"
  },
  {
    id: "aquatic-botany",
    text: "Aquatic Botany",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aquatic-conservation",
    text: "Aquatic Conservation: Marine and Freshwater Ecosystems",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291099-0755/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "aquatic-data",
    text: "Aquatic Data",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aquatic-ecology",
    text: "Aquatic Ecology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aquatic-geochemistry",
    text: "Aquatic Geochemistry",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aquatic-invasions",
    text: "Aquatic Invasions",
    parent: "NA",
    urlone: "http://www.aquaticinvasions.net/submissions.html",
    urltwo: "NA"
  },
  {
    id: "aquatic-living-resources",
    text: "Aquatic Living Resources",
    parent: "NA",
    urlone: "http://journals.cambridge.org/images/fileUpload/images/ALR-InstructionsApr13.pdf",
    urltwo: "NA"
  },
  {
    id: "aquatic-microbial-ecology",
    text: "Aquatic Microbial Ecology",
    parent: "inter-research-science-center",
    urlone: "http://www.int-res.com/journals/ame/ame-home/",
    urltwo: "NA"
  },
  {
    id: "aquatic-procedia",
    text: "Aquatic Procedia",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aquatic-sciences",
    text: "Aquatic Sciences",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aquatic-toxicology",
    text: "Aquatic Toxicology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "aquitania",
    text: "Aquitania (French)",
    parent: "NA",
    urlone: "http://aquitania.u-bordeaux-montaigne.fr/images/PDF/NORMES-GENERALES.pdf",
    urltwo: "NA"
  },
  {
    id: "arab-economic-and-business-journal",
    text: "Arab Economic and Business Journal",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "arab-journal-of-gastroenterology",
    text: "Arab Journal of Gastroenterology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "arab-journal-of-urology",
    text: "Arab Journal of Urology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "arabian-journal-for-science-and-engineering",
    text: "Arabian Journal for Science and Engineering",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "arabian-journal-of-chemistry",
    text: "Arabian Journal of Chemistry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "arabian-journal-of-geosciences",
    text: "Arabian Journal of Geosciences",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "arabian-journal-of-mathematics",
    text: "Arabian Journal of Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "arachne",
    text: "Arachne",
    parent: "NA",
    urlone: "http://www.dearge.de/arachne/",
    urltwo: "https://github.com/citation-style-language/styles/pull/869"
  },
  {
    id: "arachnology",
    text: "Arachnology",
    parent: "NA",
    urlone: "http://wiki.britishspiders.org.uk/index.php5?title=Publications/Guidelines_for_the_submission_of_articles",
    urltwo: "NA"
  },
  {
    id: "arboricultural-journal-the-international-journal-of-urban-forestry",
    text: "Arboricultural Journal: The International Journal of Urban Forestry",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TARB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "archaeologia-austriaca",
    text: "Archaeologia Austriaca",
    parent: "NA",
    urlone: "https://www.orea.oeaw.ac.at/en/publications/journals/archaeologia-austriaca/",
    urltwo: "NA"
  },
  {
    id: "archaeological-and-anthropological-sciences",
    text: "Archaeological and Anthropological Sciences",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archaeological-research-in-asia",
    text: "Archaeological Research in Asia",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archaeometry",
    text: "Archaeometry",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291475-4754/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "archaeonautica",
    text: "Archaeonautica",
    parent: "NA",
    urlone: "https://journals.openedition.org/archaeonautica/279",
    urltwo: "NA"
  },
  {
    id: "archeologia-classica",
    text: "Archeologia Classica (Italian)",
    parent: "NA",
    urlone: "http://www.antichita.uniroma1.it/node/8243",
    urltwo: "NA"
  },
  {
    id: "archeologicke-rozhledy",
    text: "ArcheologickÃ© rozhledy",
    parent: "NA",
    urlone: "http://www.arup.cas.cz/?cat=227",
    urltwo: "NA"
  },
  {
    id: "archeologie-medievale",
    text: "ArchÃ©ologie mÃ©diÃ©vale (French)",
    parent: "NA",
    urlone: "http://www.unicaen.fr/crahm/archeomed/spip.php?article80",
    urltwo: "NA"
  },
  {
    id: "archeologies-et-sciences-de-lantiquite",
    text: "ArchÃ©ologies et Sciences de l'AntiquitÃ© (French)",
    parent: "NA",
    urlone: "https://archeonum.hypotheses.org/717",
    urltwo: "NA"
  },
  {
    id: "archeosciences",
    text: "ArchÃ©oSciences (French)",
    parent: "NA",
    urlone: "https://journals.openedition.org/archeosciences/",
    urltwo: "NA"
  },
  {
    id: "architectural-engineering-and-design-management",
    text: "Architectural Engineering and Design Management",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TAEM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "architectural-science-review",
    text: "Architectural Science Review",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TASR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "archiv-fur-die-civilistische-praxis",
    text: "Archiv fÃ¼r die civilistische Praxis (German)",
    parent: "NA",
    urlone:
      "https://www.mohrsiebeck.com/fileadmin/user_upload/Zeitschriften/Richtlinien_Autoren/2015/Richtlinien_AcP.pdf",
    urltwo: "https://www.mohrsiebeck.com/zeitschrift/archiv-fuer-die-civilistische-praxis-acp"
  },
  {
    id: "archiv-fur-geschichte-der-philosophie",
    text: "Archiv fÃ¼r Geschichte der Philosophie",
    parent: "NA",
    urlone: "http://www.degruyter.com/view/supplement/s16130650_Autorenrichtlinien_de.pdf",
    urltwo: "www.degruyter.com/view/supplement/s16130650_Instructions_for_Authors_en.pdf"
  },
  {
    id: "archival-science",
    text: "Archival Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archive-for-history-of-exact-sciences",
    text: "Archive for History of Exact Sciences",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archive-for-mathematical-logic",
    text: "Archive for Mathematical Logic",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archive-for-rational-mechanics-and-analysis",
    text: "Archive for Rational Mechanics and Analysis",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archive-of-applied-mechanics",
    text: "Archive of Applied Mechanics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-and-manuscripts",
    text: "Archives and Manuscripts",
    parent: "style-manual-australian-government-note",
    urlone: "https://www.tandfonline.com/action/authorSubmission?journalCode=raam20&amp;page=instructions#style",
    urltwo: "NA"
  },
  {
    id: "archives-and-records",
    text: "Archives and Records",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJSA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "archives-animal-breeding",
    text: "Archives Animal Breeding",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-de-pediatrie",
    text: "Archives de pÃ©diatrie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-des-maladies-du-coeur-et-des-vaisseaux-pratique",
    text: "Archives des Maladies du Coeur et des Vaisseaux - Pratique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-des-maladies-professionnelles-et-de-lenvironnement",
    text: "Archives des Maladies Professionnelles et de l'Environnement",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-of-agronomy-and-soil-science",
    text: "Archives of Agronomy and Soil Science",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GAGS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "archives-of-animal-nutrition",
    text: "Archives of Animal Nutrition",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GAAN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "archives-of-biochemistry-and-biophysics",
    text: "Archives of Biochemistry and Biophysics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-of-cardiovascular-diseases-supplements",
    text: "Archives of Cardiovascular Diseases Supplements",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-of-cardiovascular-diseases",
    text: "Archives of Cardiovascular Diseases",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-of-civil-and-mechanical-engineering",
    text: "Archives of Civil and Mechanical Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-of-computational-methods-in-engineering",
    text: "Archives of Computational Methods in Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-dermatological-research",
    text: "Archives of Dermatological Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-disease-in-childhood",
    text: "Archives of Disease in Childhood",
    parent: "bmj",
    urlone: "http://adc.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "archives-of-environmental-contamination-and-toxicology",
    text: "Archives of Environmental Contamination and Toxicology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-facial-plastic-surgery",
    text: "Archives of Facial Plastic Surgery",
    parent: "american-medical-association",
    urlone: "http://archfaci.jamanetwork.com/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "archives-of-general-psychiatry",
    text: "Archives of General Psychiatry",
    parent: "american-medical-association",
    urlone: "http://archpsyc.jamanetwork.com/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "archives-of-gerontology-and-geriatrics",
    text: "Archives of Gerontology and Geriatrics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-of-gynecology-and-obstetrics",
    text: "Archives of Gynecology and Obstetrics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-internal-medicine",
    text: "Archives of Internal Medicine",
    parent: "american-medical-association",
    urlone: "http://archinte.jamanetwork.com/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "archives-of-medical-research",
    text: "Archives of Medical Research",
    parent: "vancouver",
    urlone: "http://www.elsevier.com/journals/archives-of-medical-research/0188-4409/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "archives-of-microbiology",
    text: "Archives of Microbiology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-neurology",
    text: "Archives of Neurology",
    parent: "american-medical-association",
    urlone: "http://archneur.jamanetwork.com/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "archives-of-ophthalmology",
    text: "Archives of Ophthalmology",
    parent: "american-medical-association",
    urlone: "http://archopht.jamanetwork.com/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "archives-of-oral-biology",
    text: "Archives of Oral Biology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-of-orthopaedic-and-trauma-surgery",
    text: "Archives of Orthopaedic and Trauma Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-osteoporosis",
    text: "Archives of Osteoporosis",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-otolaryngology-head-and-neck-surgery",
    text: "Archives of Otolaryngology - Head &amp; Neck Surgery",
    parent: "american-medical-association",
    urlone: "http://archotol.jamanetwork.com/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "archives-of-pathology-and-laboratory-medicine",
    text: "Archives of Pathology &amp; Laboratory Medicine",
    parent: "american-medical-association",
    urlone: "http://www.archivesofpathology.org/",
    urltwo: "NA"
  },
  {
    id: "archives-of-pediatrics-and-adolescent-medicine",
    text: "Archives of Pediatrics &amp; Adolescent Medicine",
    parent: "american-medical-association",
    urlone:
      "http://archpedi.jamanetwork.com/public/instructionsForAuthors.aspx#ManuscriptPreparationandSubmissionRequirements",
    urltwo: "NA"
  },
  {
    id: "archives-of-pharmacal-research",
    text: "Archives of Pharmacal Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-physical-medicine-and-rehabilitation",
    text: "Archives of Physical Medicine and Rehabilitation",
    parent: "NA",
    urlone: "http://www.archives-pmr.org/authorinfo#preparation",
    urltwo: "NA"
  },
  {
    id: "archives-of-physiotherapy",
    text: "Archives of Physiotherapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-phytopathology-and-plant-protection",
    text: "Archives Of Phytopathology And Plant Protection",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GAPP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "archives-of-psychiatric-nursing",
    text: "Archives of Psychiatric Nursing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "archives-of-public-health",
    text: "Archives of Public Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-scientific-psychology",
    text: "Archives of Scientific Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/arc",
    urltwo: "NA"
  },
  {
    id: "archives-of-sexual-behavior",
    text: "Archives of Sexual Behavior",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-surgery",
    text: "Archives of Surgery",
    parent: "american-medical-association",
    urlone: "http://archsurg.jamanetwork.com/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "archives-of-toxicology",
    text: "Archives of Toxicology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-virology",
    text: "Archives of Virology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archives-of-womens-mental-health",
    text: "Archives of Women's Mental Health",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archivos-de-bronconeumologia",
    text: "Archivos de BronconeumologÃ­a",
    parent: "NA",
    urlone: "http://www.archbronconeumol.org/es/guia-autores/",
    urltwo: "NA"
  },
  {
    id: "archivos-de-cardiologia-de-mexico",
    text: "Archivos de CardiologÃ­a de MÃ©xico (Spanish)",
    parent: "vancouver",
    urlone: "http://www.archivos-cardiologia.org.mx/",
    urltwo: "NA"
  },
  {
    id: "archivos-de-la-sociedad-espanola-de-oftalmologia",
    text: "Archivos de la Sociedad EspaÃ±ola de OftalmologÃ­a (Spanish)",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/archivos-de-la-sociedad-espanola-de-oftalmologia/0365-6691/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "archivos-de-medicina-interna",
    text: "Archivos de Medicina Interna (Spanish)",
    parent: "vancouver",
    urlone: "http://www.prensamedica.com.uy/pagina.php?id=MedicinaInterna",
    urltwo: "NA"
  },
  {
    id: "archivos-de-psiquiatria",
    text: "Archivos de PsiquiatrÃ­a (Spanish)",
    parent: "vancouver",
    urlone: "http://archivosdepsiquiatria.es/",
    urltwo: "NA"
  },
  {
    id: "archivum-immunologiae-et-therapiae-experimentalis",
    text: "Archivum Immunologiae et Therapiae Experimentalis",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "archivum-latinitatis-medii-aevi",
    text: "Archivum Latinitatis Medii Aevi (French)",
    parent: "NA",
    urlone: "http://www.aibl.fr/IMG/pdf/normes_de_redaction-.pdf",
    urltwo: "NA"
  },
  {
    id: "arctic-antarctic-and-alpine-research",
    text: "Arctic, Antarctic, and Alpine Research",
    parent: "NA",
    urlone: "http://aaarjournal.org/page/format?code=iaar-site",
    urltwo: "NA"
  },
  {
    id: "arctic-environmental-research",
    text: "Arctic Environmental Research",
    parent: "pensoft-journals",
    urlone: "https://aer.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "arctic-science",
    text: "Arctic Science",
    parent: "canadian-journal-of-soil-science",
    urlone: "http://www.nrcresearchpress.com/page/as/authors",
    urltwo: "NA"
  },
  {
    id: "arctic",
    text: "Arctic",
    parent: "NA",
    urlone: "https://arctic.ucalgary.ca/guide-authors",
    urltwo: "NA"
  },
  {
    id: "argumentation",
    text: "Argumentation",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "arhiv-za-higijenu-rada-i-toksikologiju",
    text: "Arhiv za higijenu rada i toksikologiju (Archives of Industrial Hygiene and Toxicology)",
    parent: "NA",
    urlone: "https://hrcak.srce.hr/aiht?lang=en",
    urltwo: "NA"
  },
  {
    id: "arktos",
    text: "arktos",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "arnold-mathematical-journal",
    text: "Arnold Mathematical Journal",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "arquivos-brasileiros-de-cardiologia",
    text: "Arquivos Brasileiros de Cardiologia (Portuguese - Brazil)",
    parent: "vancouver",
    urlone: "http://www.arquivosonline.com.br/",
    urltwo: "NA"
  },
  {
    id: "arquivos-brasileiros-de-endocrinologia-e-metabologia",
    text: "Arquivos Brasileiros de Endocrinologia &amp; Metabologia",
    parent: "vancouver",
    urlone: "http://www.abem-sbem.org.br/",
    urltwo: "NA"
  },
  {
    id: "array",
    text: "Array",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "art-history",
    text: "Art History",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291467-8365/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "arteriosclerosis-thrombosis-and-vascular-biology",
    text: "Arteriosclerosis, Thrombosis, and Vascular Biology",
    parent: "american-medical-association-no-et-al",
    urlone: "http://atvb.ahajournals.org/site/misc/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "artery-research",
    text: "Artery Research",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/artery-research/1872-9312/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "arthritis-and-rheumatism",
    text: "Arthritis &amp; Rheumatism",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1529-0131/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "arthritis-care-and-research",
    text: "Arthritis Care &amp; Research",
    parent: "arthritis-and-rheumatism",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)2151-4658/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "arthritis-research-and-therapy",
    text: "Arthritis Research &amp; Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "arthroplasty-today",
    text: "Arthroplasty Today",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "arthropod-plant-interactions",
    text: "Arthropod-Plant Interactions",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "arthroscopy-techniques",
    text: "Arthroscopy Techniques",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "arthroscopy",
    text: "Arthroscopy: The Journal of Arthroscopic and Related Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "arthroskopie",
    text: "Arthroskopie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/orthopedics/journal/142?detailsPage=pltci_1300626",
    urltwo: "NA"
  },
  {
    id: "artificial-dna-pna-and-xna",
    text: "Artificial DNA: PNA &amp; XNA",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "artificial-intelligence-and-law",
    text: "Artificial Intelligence and Law",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "artificial-intelligence-in-agriculture",
    text: "Artificial Intelligence in Agriculture",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "artificial-intelligence-in-medicine",
    text: "Artificial Intelligence In Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "artificial-intelligence-review",
    text: "Artificial Intelligence Review",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "artificial-intelligence",
    text: "Artificial Intelligence",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "artificial-life-and-robotics",
    text: "Artificial Life and Robotics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "arts-and-health",
    text: "Arts &amp; Health",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAHE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "arts-university-bournemouth",
    text: "Arts University Bournemouth",
    parent: "NA",
    urlone: "http://www.aublibrary.info/index.php/referencing",
    urltwo: "NA"
  },
  {
    id: "arts",
    text: "Arts",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "arzneimitteltherapie",
    text: "Arzneimitteltherapie",
    parent: "NA",
    urlone: "http://www.arzneimitteltherapie.de/services/autorenhinweise.html",
    urltwo: "NA"
  },
  {
    id: "asa-cssa-sssa",
    text: "American Society of Agronomy, Crop Science Society of America, Soil Science Society of America",
    parent: "NA",
    urlone: "https://dl.sciencesocieties.org/publications/style/",
    urltwo: "NA"
  },
  {
    id: "asaio-journal",
    text: "ASAIO Journal (American Society for Artificial Internal Organs)",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/asaio/accounts/MAT_IFA_online.pdf",
    urltwo: "NA"
  },
  {
    id: "asce-asme-journal-of-risk-and-uncertainty-in-engineering-systems-part-a-civil-engineering",
    text: "ASCE-ASME Journal of Risk and Uncertainty in Engineering Systems, Part A: Civil Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/ajrua6",
    urltwo: "NA"
  },
  {
    id: "asia-and-the-pacific-policy-studies",
    text: "Asia &amp; the Pacific Policy Studies",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%292050-2680/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "asia-europe-journal",
    text: "Asia Europe Journal",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asia-pacific-business-review",
    text: "Asia Pacific Business Review",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FAPB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-education-review",
    text: "Asia Pacific Education Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asia-pacific-family-medicine",
    text: "Asia Pacific Family Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asia-pacific-financial-markets",
    text: "Asia-Pacific Financial Markets",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asia-pacific-journal-of-accounting-and-economics",
    text: "Asia-Pacific Journal of Accounting &amp; Economics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAAE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-journal-of-counselling-and-psychotherapy",
    text: "Asia Pacific Journal of Counselling and Psychotherapy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAPC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-journal-of-education",
    text: "Asia Pacific Journal of Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CAPE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-journal-of-health-sport-and-physical-education",
    text: "Asia-Pacific Journal of Health, Sport and Physical Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RASP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-journal-of-human-resources",
    text: "Asia Pacific Journal of Human Resources",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/17447941/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-journal-of-social-work-and-development",
    text: "Asia Pacific Journal of Social Work and Development",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSWD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-journal-of-sports-medicine-arthroscopy-rehabilitation-and-technology",
    text: "Asia-Pacific Journal of Sports Medicine, Arthroscopy, Rehabilitation and Technology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-journal-of-tourism-research",
    text: "Asia Pacific Journal of Tourism Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAPT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-journal-on-computational-engineering",
    text: "Asia Pacific Journal on Computational Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asia-pacific-management-review",
    text: "Asia Pacific Management Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "asia-pacific-science-education",
    text: "Asia-Pacific Science Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asian-american-journal-of-psychology",
    text: "Asian American Journal of Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/aap",
    urltwo: "NA"
  },
  {
    id: "asian-anthropology",
    text: "Asian Anthropology",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAAN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asian-ethnicity",
    text: "Asian Ethnicity",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/toc/caet20/current#.U3qzcS_Wr7A",
    urltwo: "NA"
  },
  {
    id: "asian-geographer",
    text: "Asian Geographer",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAGE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asian-journal-of-andrology",
    text: "Asian Journal of Andrology",
    parent: "vancouver",
    urlone: "http://www.ajandrology.com/contributors.asp#sm",
    urltwo: "NA"
  },
  {
    id: "asian-journal-of-anesthesiology",
    text: "Asian Journal of Anesthesiology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "asian-journal-of-business-ethics",
    text: "Asian Journal of Business Ethics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asian-journal-of-criminology",
    text: "Asian Journal of Criminology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asian-journal-of-gambling-issues-and-public-health",
    text: "Asian Journal of Gambling Issues and Public Health",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asian-journal-of-german-and-european-studies",
    text: "Asian Journal of German and European Studies",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asian-journal-of-health-sciences",
    text: "Asian Journal of Health Sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "asian-journal-of-neurosurgery",
    text: "Asian Journal of Neurosurgery",
    parent: "NA",
    urlone: "http://www.asianjns.org/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "asian-journal-of-pharmaceutical-sciences",
    text: "Asian Journal of Pharmaceutical Sciences",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "asian-journal-of-psychiatry",
    text: "Asian Journal of Psychiatry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "asian-journal-of-social-psychology",
    text: "Asian Journal of Social Psychology",
    parent: "apa",
    urlone: "https://onlinelibrary.wiley.com/page/journal/1467839x/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "asian-journal-of-surgery",
    text: "Asian Journal of Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "asian-journal-of-urology",
    text: "Asian Journal of Urology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "asian-pacific-journal-of-reproduction",
    text: "Asian Pacific Journal of Reproduction",
    parent: "hainan-medical-university-journal-publisher",
    urlone: "http://www.hnmujp.org/guide.html",
    urltwo: "NA"
  },
  {
    id: "asian-pacific-journal-of-second-and-foreign-language-education",
    text: "Asian-Pacific Journal of Second and Foreign Language Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asian-pacific-journal-of-tropical-biomedicine",
    text: "Asian Pacific Journal of Tropical Biomedicine",
    parent: "hainan-medical-university-journal-publisher",
    urlone: "http://apjtb.com/zy/f3.pdf",
    urltwo: "NA"
  },
  {
    id: "asian-pacific-journal-of-tropical-disease",
    text: "Asian Pacific Journal of Tropical Disease",
    parent: "hainan-medical-university-journal-publisher",
    urlone: "http://www.apjtcm.com/authors.html",
    urltwo: "NA"
  },
  {
    id: "asian-pacific-journal-of-tropical-medicine",
    text: "Asian Pacific Journal of Tropical Medicine",
    parent: "hainan-medical-university-journal-publisher",
    urlone: "http://www.apjtm.net/news/anthors.htm",
    urltwo: "NA"
  },
  {
    id: "asian-philosophy",
    text: "Asian Philosophy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CASP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asian-population-studies",
    text: "Asian Population Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAPS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "asian-studies-review",
    text: "Asian Studies Review",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=casr20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "assay-and-drug-development-technologies",
    text: "ASSAY and Drug Development Technologies",
    parent: "NA",
    urlone: "https://home.liebertpub.com/publications/assay-and-drug-development-technologies/118/for-authors",
    urltwo: "NA"
  },
  {
    id: "assessing-writing",
    text: "Assessing Writing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "assessment-and-evaluation-in-higher-education",
    text: "Assessment &amp; Evaluation in Higher Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/toc/caeh20/.U3qzwC_Wr7A#.U3qz5i_Wr7A",
    urltwo: "NA"
  },
  {
    id: "assessment-in-education-principles-policy-and-practice",
    text: "Assessment in Education: Principles, Policy &amp; Practice",
    parent: "apa",
    urlone: "http://www.tandfonline.com/toc/caie20/current#.U3qz8S_Wr7A",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-eceme",
    text: "Escola de Comando e Estado-Maior do ExÃ©rcito - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/1838#issuecomment-194640677",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-instituto-meira-mattos",
    text: "Escola de Comando e Estado-Maior do ExÃ©rcito - Instituto Meira Mattos - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://www.eceme.eb.mil.br/instituto-meira-mattos-imm",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-ipea",
    text: "Instituto de Pesquisa EconÃ´mica Aplicada - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-note",
    text: "AssociaÃ§Ã£o Brasileira de Normas TÃ©cnicas (note, Portuguese - Brazil)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-ufmg-face-full",
    text:
      "Universidade Federal de Minas Gerais - Faculdade de CiÃªncias EconÃ´micas - ABNT (autoria completa) (Portuguese - Brazil)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-ufmg-face-initials",
    text:
      "Universidade Federal de Minas Gerais - Faculdade de CiÃªncias EconÃ´micas - ABNT (autoria abreviada) (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://www.face.ufmg.br/portal/files/biblioteca/Manual_normalizacao_FACE.pdf",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-ufpr",
    text: "Universidade Federal do ParanÃ¡ - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-ufrgs-initials",
    text: "Universidade Federal do Rio Grande do Sul - ABNT (autoria abreviada) (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://www.ufrgs.br/bibliotecas/campos-basicos-abnt-mendeley",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-ufrgs-note-initials-with-ibid",
    text: "Universidade Federal do Rio Grande do Sul - ABNT (autoria abreviada, nota, com Ibid.) (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://www.ufrgs.br/bibliotecas/campos-basicos-abnt-mendeley",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-ufrgs",
    text: "Universidade Federal do Rio Grande do Sul - ABNT (autoria completa) (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://www.ufrgs.br/bibliotecas/campos-basicos-abnt-mendeley",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-ufs",
    text: "Universidade Federal de Sergipe - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-unirio-eipp",
    text:
      "Universidade Federal do Estado do Rio de Janeiro - EducaÃ§Ã£o Infantil e PolÃ­ticas PÃºblicas - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://www.tovar.com.br/citationstyles/",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas-usp-fmvz",
    text: "Universidade de SÃ£o Paulo - Faculdade de Medicina VeterinÃ¡ria e Zootecnia - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://biblioteca.fmvz.usp.br/wp-content/uploads/2018/10/DIRETRIZES_22outubro2018.pdf",
    urltwo: "NA"
  },
  {
    id: "associacao-brasileira-de-normas-tecnicas",
    text: "AssociaÃ§Ã£o Brasileira de Normas TÃ©cnicas (Portuguese - Brazil)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "associacao-nacional-de-pesquisa-e-ensino-em-transportes",
    text: "ANPET - Congresso de Pesquisa e Ensino em Transportes (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://www.anpet.ufsc.br/ssat/interface/content/autor/arquivos/formato_trabalhos.pdf",
    urltwo: "NA"
  },
  {
    id: "association-de-science-regionale-de-langue-francaise",
    text: "Association de Science RÃ©gionale de Langue FranÃ§aise (French)",
    parent: "NA",
    urlone: "http://asrdlf2016.com/sites/default/files/users/user167/feuille_de_style_asrdlf2016c.docx",
    urltwo: "NA"
  },
  {
    id: "association-for-computational-linguistics",
    text: "Association for Computational Linguistics - Conference Proceedings",
    parent: "NA",
    urlone: "http://www.aclweb.org/",
    urltwo: "NA"
  },
  {
    id: "association-for-computing-machinery",
    text: "Association for Computing Machinery",
    parent: "NA",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "asta-advances-in-statistical-analysis",
    text: "AStA Advances in Statistical Analysis",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asta-wirtschafts-und-sozialstatistisches-archiv",
    text: "AStA Wirtschafts- und Sozialstatistisches Archiv",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "asthma-research-and-practice",
    text: "Asthma Research and Practice",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "astra-proceedings",
    text: "ASTRA Proceedings",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "astronomy-and-computing",
    text: "Astronomy and Computing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "astroparticle-physics",
    text: "Astroparticle Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "astrophysics-and-space-science",
    text: "Astrophysics and Space Science",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "atencion-familiar",
    text: "AtenciÃ³n Familiar",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atencion-primaria",
    text: "AtenciÃ³n Primaria (Spanish)",
    parent: "medicina-clinica",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atherosclerosis-supplements",
    text: "Atherosclerosis (Supplements)",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atherosclerosis-x",
    text: "Atherosclerosis: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atherosclerosis",
    text: "Atherosclerosis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "athletic-training-and-sports-health-care",
    text: "Athletic Training &amp; Sports Health Care",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/~/media/Files/Journals/General%20PDFs/ATSHC/ATSHC_InfoforAuthors.ashx",
    urltwo: "NA"
  },
  {
    id: "atlande",
    text: "Atlande (author-date, French)",
    parent: "NA",
    urlone: "http://atlande.eu/atlande-author-date-v2_documentation.pdf",
    urltwo: "NA"
  },
  {
    id: "atlantic-economic-journal",
    text: "Atlantic Economic Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "atlantic-studies",
    text: "Atlantic Studies",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJAS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "atlas-of-genetics-and-cytogenetics-in-oncology-and-haematology",
    text: "Atlas of Genetics and Cytogenetics in Oncology and Haematology",
    parent: "NA",
    urlone: "http://atlasgeneticsoncology.org/BackpageAuthors.html#CONTRIBUTION",
    urltwo: "NA"
  },
  {
    id: "atmosphere-ocean",
    text: "Atmosphere-Ocean",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TATO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "atmosphere",
    text: "Atmosphere",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "atmospheric-chemistry-and-physics",
    text: "Atmospheric Chemistry and Physics",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atmospheric-environment-x",
    text: "Atmospheric Environment: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atmospheric-environment",
    text: "Atmospheric Environment",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atmospheric-measurement-techniques",
    text: "Atmospheric Measurement Techniques",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atmospheric-pollution-research",
    text: "Atmospheric Pollution Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atmospheric-research",
    text: "Atmospheric Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atomic-data-and-nuclear-data-tables",
    text: "Atomic Data and Nuclear Data Tables",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "atomization-and-sprays",
    text: "Atomization and Sprays",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/6a7c7e10642258cc.html",
    urltwo: "NA"
  },
  {
    id: "atoms",
    text: "Atoms",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "attachment-and-human-development",
    text: "Attachment &amp; Human Development",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAHD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "audiology-and-neurotology-extra",
    text: "Audiology and Neurotology Extra",
    parent: "karger-journals-author-date",
    urlone: "http://www.karger.com/Journal/Home/255124",
    urltwo: "NA"
  },
  {
    id: "audiology-and-neurotology",
    text: "Audiology and Neurotology",
    parent: "karger-journals-author-date",
    urlone: "http://www.karger.com?issn=1421-9700",
    urltwo: "NA"
  },
  {
    id: "augenheilkunde-up2date",
    text: "Augenheilkunde up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "augmented-human-research",
    text: "Augmented Human Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aula-abierta",
    text: "Aula Abierta",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "auris-nasus-larynx",
    text: "Auris Nasus Larynx",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ausonius-editions",
    text: "Ausonius Ã‰ditions (French)",
    parent: "NA",
    urlone: "http://ausoniuseditions.u-bordeaux-montaigne.fr/images/PDF/NORMES-GENERALES.pdf",
    urltwo: "NA"
  },
  {
    id: "austral-ecology",
    text: "Austral Ecology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1442-9993/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "austral-entomology",
    text: "Austral Entomology",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/20521758/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "australasian-dispute-resolution-journal",
    text: "Australasian Dispute Resolution Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australasian-emergency-care",
    text: "Australasian Emergency Care",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australasian-emergency-nursing-journal",
    text: "Australasian Emergency Nursing Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australasian-journal-of-dermatology",
    text: "Australasian Journal of Dermatology",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291440-0960/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "australasian-marketing-journal",
    text: "Australasian Marketing Journal",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australasian-physical-and-engineering-sciences-in-medicine",
    text: "Australasian Physical &amp; Engineering Sciences in Medicine",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "australasian-plant-disease-notes",
    text: "Australasian Plant Disease Notes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "australasian-plant-pathology",
    text: "Australasian Plant Pathology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "australian-academic-and-research-libraries",
    text: "Australian Academic &amp; Research Libraries",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=UARL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "australian-archaeology",
    text: "Australian Archaeology",
    parent: "NA",
    urlone: "https://libguides.jcu.edu.au/archaeology/referencing/australian-archaeology",
    urltwo: "NA"
  },
  {
    id: "australian-business-law-review",
    text: "Australian Business Law Review",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australian-critical-care",
    text: "Australian Critical Care",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/australian-critical-care/1036-7314/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "australian-dental-journal",
    text: "Australian Dental Journal",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/18347819/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "australian-family-physician",
    text: "Australian Family Physician",
    parent: "vancouver",
    urlone: "http://www.racgp.org.au/afp/resources/formatting-an-afp-article/",
    urltwo: "NA"
  },
  {
    id: "australian-feminist-studies",
    text: "Australian Feminist Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/toc/cafs20/current#.U3qvCy_Wr7A",
    urltwo: "NA"
  },
  {
    id: "australian-geographer",
    text: "Australian Geographer",
    parent: "apa",
    urlone: "http://www.tandfonline.com/toc/cage20/current#.U3qvCS_Wr7A",
    urltwo: "NA"
  },
  {
    id: "australian-gst-journal",
    text: "Australian GST Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australian-guide-to-legal-citation-3rd-edition",
    text: "Australian Guide to Legal Citation 3rd edition",
    parent: "NA",
    urlone: "https://law.unimelb.edu.au/__data/assets/pdf_file/0005/2877782/AGLC3.pdf",
    urltwo: "NA"
  },
  {
    id: "australian-guide-to-legal-citation",
    text: "Australian Guide to Legal Citation 4th edition",
    parent: "NA",
    urlone: "https://law.unimelb.edu.au/mulr/aglc/about",
    urltwo: "NA"
  },
  {
    id: "australian-historical-studies",
    text: "Australian Historical Studies",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/journal.asp?issn=1031-461X&amp;linktype=44",
    urltwo: "NA"
  },
  {
    id: "australian-intellectual-property-journal",
    text: "Australian Intellectual Property Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-administrative-law",
    text: "Australian Journal of Administrative Law",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-botany",
    text: "Australian Journal of Botany",
    parent: "international-journal-of-wildland-fire",
    urlone: "http://www.publish.csiro.au/nid/69/aid/366.htm#13",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-competition-and-consumer-law",
    text: "Australian Journal of Competition and Consumer Law",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-earth-sciences",
    text: "Australian Journal of Earth Sciences",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=taje20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-grape-and-wine-research",
    text: "Australian Journal of Grape and Wine Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291755-0238/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-international-affairs",
    text: "Australian Journal of International Affairs",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/toc/caji20/current#.U3qvBS_Wr7A",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-learning-difficulties",
    text: "Australian Journal of Learning Difficulties",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RALD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-primary-health",
    text: "Australian Journal of Primary Health",
    parent: "international-journal-of-wildland-fire",
    urlone: "http://www.publish.csiro.au/nid/263/aid/11564.htm#15",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-rural-health",
    text: "Australian Journal of Rural Health",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1440-1584/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "australian-journal-of-zoology",
    text: "Australian Journal of Zoology",
    parent: "emu-austral-ornithology",
    urlone: "http://www.publish.csiro.au/nid/93/aid/392.htm#10",
    urltwo: "NA"
  },
  {
    id: "australian-mammalogy",
    text: "Australian Mammalogy",
    parent: "emu-austral-ornithology",
    urlone: "http://www.publish.csiro.au/nid/258/aid/11408.htm#6",
    urltwo: "NA"
  },
  {
    id: "australian-orthoptic-journal",
    text: "Australian Orthoptic Journal",
    parent: "vancouver",
    urlone: "http://www.orthoptics.org.au/OAA07/images/publications/aojguidelinesforauthors.pdf",
    urltwo: "NA"
  },
  {
    id: "australian-planner",
    text: "Australian Planner",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAPL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "australian-systematic-botany",
    text: "Australian Systematic Botany",
    parent: "international-journal-of-wildland-fire",
    urlone: "http://www.publish.csiro.au/nid/153/aid/398.htm#13",
    urltwo: "NA"
  },
  {
    id: "australian-tax-review",
    text: "Australian Tax Review",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "australian-veterinary-journal",
    text: "Australian Veterinary Journal",
    parent: "NA",
    urlone: "http://mc.manuscriptcentral.com/societyimages/avj/AVJ-IFA-2006.pdf",
    urltwo: "NA"
  },
  {
    id: "austrian-journal-of-development-studies",
    text: "Austrian Journal of Development Studies (Journal fÃ¼r Entwicklungspolitik)",
    parent: "NA",
    urlone: "https://www.mattersburgerkreis.at/site/en/articlesubmission",
    urltwo: "NA"
  },
  {
    id: "austrian-legal",
    text: "Austrian Legal (German - Austria)",
    parent: "NA",
    urlone: "http://www.lexisnexis.at/service/training-und-hilfe/zitieren.aspx",
    urltwo: "NA"
  },
  {
    id: "austrian-studies",
    text: "Austrian Studies",
    parent: "modern-humanities-research-association",
    urlone: "http://www.mhra.org.uk/Publications/Journals/Austrian.html",
    urltwo: "NA"
  },
  {
    id: "autoimmunity-highlights",
    text: "Autoimmunity Highlights",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "autoimmunity-reviews",
    text: "Autoimmunity Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "automated-software-engineering",
    text: "Automated Software Engineering",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "automatica",
    text: "Automatica",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "automation-in-construction",
    text: "Automation in Construction",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "automotive-and-engine-technology",
    text: "Automotive and Engine Technology",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "autonomic-neuroscience-basic-and-clinical",
    text: "Autonomic Neuroscience: Basic and Clinical",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "autonomous-agents-and-multi-agent-systems",
    text: "Autonomous Agents and Multi-Agent Systems",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "autonomous-robots",
    text: "Autonomous Robots",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "autophagy",
    text: "Autophagy",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "avian-conservation-and-ecology",
    text: "Avian Conservation and Ecology",
    parent: "NA",
    urlone: "http://www.ace-eco.org/about/submissions.php#literaturecited",
    urltwo: "NA"
  },
  {
    id: "avian-diseases",
    text: "Avian Diseases",
    parent: "NA",
    urlone: "http://www.aaap.info/guidelines-for-authors",
    urltwo: "NA"
  },
  {
    id: "avian-pathology",
    text: "Avian Pathology",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/cavpauth.asp",
    urltwo: "NA"
  },
  {
    id: "avian-research",
    text: "Avian Research",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "aviation-psychology-and-applied-human-factors",
    text: "Aviation Psychology and Applied Human Factors",
    parent: "apa",
    urlone: "http://content.apa.org/journals/apf",
    urltwo: "NA"
  },
  {
    id: "aviation-space-and-environmental-medicine",
    text: "Aviation, Space, and Environmental Medicine",
    parent: "NA",
    urlone: "https://www.editorialmanager.com/asem/account/AUTHOR_INSTRUCTIONS.pdf",
    urltwo: "NA"
  },
  {
    id: "axiomathes",
    text: "Axiomathes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "axioms",
    text: "Axioms",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "ayer",
    text: "Ayer (Spanish)",
    parent: "NA",
    urlone: "http://revistaayer.com/sites/default/files/documentos/sistema-citas-revista-ayer.pdf",
    urltwo: "NA"
  },
  {
    id: "azr-abkurzungs-und-zitierregeln-der-osterreichischen-rechtssprache-und-europarechtlicher-rechtsquellen",
    text:
      "AZR - AbkÃ¼rzungs- und Zitierregeln der Ã¶sterreichischen Rechtssprache und europarechtlicher Rechtsquellen (German - Austria)",
    parent: "NA",
    urlone: "https://www.manz.at/autoren/autorenservice/azr.html",
    urltwo: "https://github.com/PhilippTh/austrian-legal-citation-style/blob/master/README.md"
  },
  {
    id: "babes-bolyai-university-faculty-of-orthodox-theology",
    text: "Babes-Bolyai University - Faculty of Orthodox Theology (no ibid., Romanian)",
    parent: "NA",
    urlone: "http://ot.ubbcluj.ro/",
    urltwo: "NA"
  },
  {
    id: "bacteriophage",
    text: "Bacteriophage",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "baghdad-science-journal",
    text: "Baghdad Science Journal",
    parent: "NA",
    urlone: "http://www.csw-journal.org/myfile/Guidelines%20for%20Authors%20E.pdf",
    urltwo: "NA"
  },
  {
    id: "balint-journal",
    text: "Balint-Journal",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "bandung-journal-of-the-global-south",
    text: "Bandung: Journal of the Global South",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bangladesh-journal-of-child-health",
    text: "Bangladesh Journal of Child Health",
    parent: "vancouver",
    urlone: "http://www.banglajol.info/index.php/BJCH/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "basal-ganglia",
    text: "Basal Ganglia",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "basic-and-applied-ecology",
    text: "Basic and Applied Ecology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "basic-and-applied-pathology",
    text: "Basic and Applied Pathology",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291755-9294/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "basic-and-clinical-andrology",
    text: "Basic and Clinical Andrology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "basic-research-in-cardiology",
    text: "Basic Research in Cardiology",
    parent: "springer-basic-brackets-no-et-al-alphabetical",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "batteries",
    text: "Batteries",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "bba-bioenergetics",
    text: "BBA - Bioenergetics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bba-biomembranes",
    text: "BBA - Biomembranes",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bba-clinical",
    text: "BBA Clinical",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bba-gene-regulatory-mechanisms",
    text: "BBA - Gene Regulatory Mechanisms",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bba-general-subjects",
    text: "BBA - General Subjects",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bba-molecular-and-cell-biology-of-lipids",
    text: "BBA - Molecular and Cell Biology of Lipids",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bba-molecular-basis-of-disease",
    text: "BBA - Molecular Basis of Disease",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bba-molecular-cell-research",
    text: "BBA - Molecular Cell Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bba-proteins-and-proteomics",
    text: "BBA - Proteins and Proteomics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bba-reviews-on-cancer",
    text: "BBA - Reviews on Cancer",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "begell-house-apa",
    text: "Begell House - APA",
    parent: "NA",
    urlone: "http://dl.begellhouse.com/forauthors/journals/00551c876cc2f027.html",
    urltwo: "NA"
  },
  {
    id: "begell-house-chicago-author-date",
    text: "Begell House - Chicago Manual of Style",
    parent: "NA",
    urlone: "http://dl.begellhouse.com/forauthors/journals/6a7c7e10642258cc.html",
    urltwo: "NA"
  },
  {
    id: "behavior-analysis-in-practice",
    text: "Behavior Analysis in Practice",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "behavior-analysis-research-and-practice",
    text: "Behavior Analysis: Research and Practice",
    parent: "apa",
    urlone: "http://content.apa.org/journals/bar",
    urltwo: "NA"
  },
  {
    id: "behavior-genetics",
    text: "Behavior Genetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "behavior-therapy",
    text: "Behavior Therapy",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "behavioral-and-brain-functions",
    text: "Behavioral and Brain Functions",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "behavioral-development",
    text: "Behavioral Development",
    parent: "apa",
    urlone: "http://content.apa.org/journals/bdb",
    urltwo: "NA"
  },
  {
    id: "behavioral-ecology-and-sociobiology",
    text: "Behavioral Ecology and Sociobiology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "behavioral-ecology",
    text: "Behavioral Ecology",
    parent: "council-of-science-editors-author-date",
    urlone: "http://www.oxfordjournals.org/our_journals/beheco/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "behavioral-neuroscience",
    text: "Behavioral Neuroscience",
    parent: "apa",
    urlone: "http://content.apa.org/journals/bne",
    urltwo: "NA"
  },
  {
    id: "behavioral-sciences-of-terrorism-and-political-aggression",
    text: "Behavioral Sciences of Terrorism and Political Aggression",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIRT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "behavioral-sciences",
    text: "Behavioral Sciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "behaviour-research-and-therapy",
    text: "Behaviour Research and Therapy",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "behaviour",
    text: "Behaviour",
    parent: "NA",
    urlone: "http://www.brill.com/behaviour",
    urltwo: "NA"
  },
  {
    id: "behavioural-brain-research",
    text: "Behavioural Brain Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "behavioural-processes",
    text: "Behavioural Processes",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "beilstein-journal-of-nanotechnology",
    text: "Beilstein Journal of Nanotechnology",
    parent: "beilstein-journal-of-organic-chemistry",
    urlone: "https://www.beilstein-journals.org/bjnano/authorInstructions",
    urltwo: "NA"
  },
  {
    id: "beilstein-journal-of-organic-chemistry",
    text: "Beilstein Journal of Organic Chemistry",
    parent: "NA",
    urlone: "https://www.beilstein-journals.org/bjoc/authorInstructions",
    urltwo: "NA"
  },
  {
    id: "beitrage-zur-algebra-und-geometrie-contributions-to-algebra-and-geometry",
    text: "BeitrÃ¤ge zur Algebra und Geometrie / Contributions to Algebra and Geometry",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "beitrage-zur-popularmusikforschung",
    text: "BeitrÃ¤ge zur Popularmusikforschung (German)",
    parent: "gesellschaft-fur-popularmusikforschung",
    urlone:
      "http://www.transcript-verlag.de/reihen/kulturwissenschaften/musikwissenschaft/beitraege-zur-popularmusikforschung/",
    urltwo: "http://geb.uni-giessen.de/geb/volltexte/2008/5120/"
  },
  {
    id: "bell-labs-technical-journal",
    text: "Bell Labs Technical Journal",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6731002",
    urltwo: "NA"
  },
  {
    id: "beltz-padagogik",
    text: "Beltz - PÃ¤dagogik (German)",
    parent: "NA",
    urlone: "de",
    urltwo: "NA"
  },
  {
    id: "beni-suef-university-journal-of-basic-and-applied-sciences",
    text: "Beni-Suef University Journal of Basic and Applied Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "berghahn-books-author-date-en-gb",
    text: "Berghahn Books (author-date, English - UK)",
    parent: "NA",
    urlone: "https://www.berghahnbooks.com/authors/",
    urltwo: "NA"
  },
  {
    id: "berlin-school-of-economics-and-law-international-marketing-management",
    text: "Berlin School of Economics and Law - International Marketing Management",
    parent: "NA",
    urlone:
      "https://github.com/citation-style-language/styles/files/2571346/GuidelinesforThesisWriting-English-Okt.2017.pdf",
    urltwo: "NA"
  },
  {
    id: "berliner-journal-fur-soziologie",
    text: "Berliner Journal fÃ¼r Soziologie (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "best-practice-and-research-clinical-anaesthesiology",
    text: "Best Practice &amp; Research Clinical Anaesthesiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "best-practice-and-research-clinical-endocrinology-and-metabolism",
    text: "Best Practice &amp; Research Clinical Endocrinology &amp; Metabolism",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "best-practice-and-research-clinical-gastroenterology",
    text: "Best Practice &amp; Research Clinical Gastroenterology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "best-practice-and-research-clinical-haematology",
    text: "Best Practice &amp; Research Clinical Haematology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "best-practice-and-research-clinical-obstetrics-and-gynaecology",
    text: "Best Practice &amp; Research Clinical Obstetrics &amp; Gynaecology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "best-practice-and-research-clinical-rheumatology",
    text: "Best Practice &amp; Research Clinical Rheumatology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "best-practice-onkologie",
    text: "best practice onkologie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/oncology/journal/11654?detailsPage=pltci_1288034",
    urltwo: "NA"
  },
  {
    id: "beverages",
    text: "Beverages",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "biblio-3w",
    text: "Biblio 3W (Spanish)",
    parent: "NA",
    urlone: "http://www.ub.edu/geocrit/nrm-b3w.htm",
    urltwo: "http://www.ub.edu/geocrit/b-normes.htm"
  },
  {
    id: "bibliotek-for-laeger",
    text: "Bibliotek for LÃ¦ger (Danish)",
    parent: "vancouver",
    urlone: "http://www.laeger.dk/portal/pls/portal/!PORTAL.wwpob_page.show?_docname=8538885.PDF",
    urltwo: "NA"
  },
  {
    id: "bibliotheca-ephemeridum-theologicarum-lovaniensium",
    text: "Bibliotheca Ephemeridum Theologicarum Lovaniensium",
    parent: "ephemerides-theologicae-lovanienses",
    urlone: "http://www.peeters-leuven.be/pdf/instructionsETL.pdf",
    urltwo: "NA"
  },
  {
    id: "bibliotheque-d-archeologie-mediterraneenne-et-africaine-biama",
    text: "BibliothÃ¨que d'archÃ©ologie mÃ©diterranÃ©enne et africaine (BiAMA) (French)",
    parent: "NA",
    urlone: "http://ccj.cnrs.fr/spip.php?rubrique89",
    urltwo: "NA"
  },
  {
    id: "bibliotheque-detude",
    text: "BibliothÃ¨que dâ€™Ã©tude",
    parent: "institut-francais-darcheologie-orientale",
    urlone: "https://www.ifao.egnet.net/publications/publier/normes-ed/",
    urltwo: "https://www.ifao.egnet.net/publications/catalogue/BiEtud/"
  },
  {
    id: "bibliotheque-universitaire-de-medecine-vancouver",
    text: "BibliothÃ¨que universitaire de mÃ©decine - Vancouver (French)",
    parent: "NA",
    urlone: "https://www.bium.ch/guide-vancouver/",
    urltwo: "NA"
  },
  {
    id: "bibtex",
    text: "BibTeX generic citation style",
    parent: "NA",
    urlone: "http://www.bibtex.org/",
    urltwo: "NA"
  },
  {
    id: "biens-symboliques-symbolic-goods",
    text: "Biens symboliques / Symbolic Goods",
    parent: "NA",
    urlone: "https://revue.biens-symboliques.net/200",
    urltwo: "NA"
  },
  {
    id: "big-data-analytics",
    text: "Big Data Analytics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "big-data-and-cognitive-computing",
    text: "Big Data and Cognitive Computing",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "big-data-and-information-analytics",
    text: "Big Data and Information Analytics",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/270.html",
    urltwo: "NA"
  },
  {
    id: "big-data-research",
    text: "Big Data Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bijblijven",
    text: "Bijblijven (Dutch)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bioactive-carbohydrates-and-dietary-fibre",
    text: "Bioactive Carbohydrates and Dietary Fibre",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioactive-materials",
    text: "Bioactive Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioanalysis",
    text: "Bioanalysis",
    parent: "future-science-group",
    urlone: "http://www.future-science.com/loi/bio",
    urltwo: "NA"
  },
  {
    id: "bioarchaeology-international",
    text: "Bioarchaeology International",
    parent: "NA",
    urlone:
      "https://mc04.manuscriptcentral.com/societyimages/bioarchaeolint/Bioarchaeology%20International%20Author%20Guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "bioarchaeology-of-the-near-east",
    text: "Bioarchaeology of the Near East",
    parent: "NA",
    urlone: "http://www.anthropology.uw.edu.pl/",
    urltwo: "NA"
  },
  {
    id: "bioarchitecture",
    text: "Bioarchitecture",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biocatalysis-and-agricultural-biotechnology",
    text: "Biocatalysis and Agricultural Biotechnology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biochemical-and-biophysical-research-communications",
    text: "Biochemical and Biophysical Research Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biochemical-engineering-journal",
    text: "Biochemical Engineering Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biochemical-genetics",
    text: "Biochemical Genetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biochemical-journal",
    text: "Biochemical Journal",
    parent: "NA",
    urlone: "http://www.biochemj.org/bj/bji2a.htm#References",
    urltwo: "NA"
  },
  {
    id: "biochemical-pharmacology",
    text: "Biochemical Pharmacology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biochemical-society-transactions",
    text: "Biochemical Society Transactions",
    parent: "NA",
    urlone: "http://www.biochemsoctrans.org/",
    urltwo: "NA"
  },
  {
    id: "biochemical-systematics-and-ecology",
    text: "Biochemical Systematics and Ecology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biochemistry-and-biophysics-reports",
    text: "Biochemistry and Biophysics Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biochemistry-and-cell-biology",
    text: "Biochemistry and Cell Biology",
    parent: "canadian-journal-of-fisheries-and-aquatic-sciences",
    urlone: "http://www.nrcresearchpress.com/page/bcb/authors",
    urltwo: "NA"
  },
  {
    id: "biochemistry-and-molecular-biology-education",
    text: "Biochemistry and Molecular Biology Education",
    parent: "NA",
    urlone: "http://iubmb.onlinelibrary.wiley.com/hub/journal/10.1002/(ISSN)1539-3429/about/author-guidelines.html",
    urltwo: "NA"
  },
  {
    id: "biochemistry",
    text: "Biochemistry",
    parent: "NA",
    urlone: "http://pubs.acs.org/paragonplus/submission/bichaw/bichaw_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "biochimica-et-biophysica-acta",
    text: "Biochimica et Biophysica Acta",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/bba-biomembranes/0005-2736/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "biochimie-open",
    text: "Biochimie Open",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biochimie",
    text: "Biochimie",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioconjugate-chemistry",
    text: "Bioconjugate Chemistry",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/bcches/bcches_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "biocontrol-science-and-technology",
    text: "Biocontrol Science and Technology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CBST20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "biocontrol",
    text: "BioControl",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biocybernetics-and-biomedical-engineering",
    text: "Biocybernetics and Biomedical Engineering",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biodata-mining",
    text: "BioData Mining",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biodegradation",
    text: "Biodegradation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biodiversity-and-conservation",
    text: "Biodiversity and Conservation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biodiversity",
    text: "Biodiversity",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TBID20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "biodrugs",
    text: "BioDrugs",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bioelectrochemistry",
    text: "Bioelectrochemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioelectromagnetics",
    text: "Bioelectromagnetics",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291521-186X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "bioelectronics-in-medicine",
    text: "Bioelectronics in Medicine",
    parent: "future-medicine",
    urlone: "https://www.futuremedicine.com/journal/bem",
    urltwo: "NA"
  },
  {
    id: "bioenergy-research",
    text: "BioEnergy Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bioengineered",
    text: "Bioengineered",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioengineering",
    text: "Bioengineering",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "bioessays",
    text: "BioEssays",
    parent: "apa-numeric-superscript-brackets",
    urlone: "https://onlinelibrary.wiley.com/page/journal/15211878/homepage/2487_for_authors.html",
    urltwo: "NA"
  },
  {
    id: "bioethics-update",
    text: "BIOETHICS UPdate",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioethics",
    text: "Bioethics",
    parent: "developing-world-bioethics",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1467-8519/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "biofabrication",
    text: "Biofabrication",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1758-5090",
    urltwo: "NA"
  },
  {
    id: "biofilm",
    text: "Biofilm",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biofouling",
    text: "Biofouling",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GBIF20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "biofuels",
    text: "Biofuels",
    parent: "future-science-group",
    urlone: "http://www.future-science.com/loi/bfs",
    urltwo: "NA"
  },
  {
    id: "biogeochemistry",
    text: "Biogeochemistry",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biogeosciences",
    text: "Biogeosciences",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biogerontology",
    text: "Biogerontology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bioinformatics",
    text: "Bioinformatics",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/bioinformatics/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "bioinspiration-and-biomimetics",
    text: "Bioinspiration &amp; Biomimetics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1748-3190",
    urltwo: "NA"
  },
  {
    id: "biologia",
    text: "Biologia",
    parent: "NA",
    urlone: "http://www.degruyter.com/view/j/biolog",
    urltwo: "NA"
  },
  {
    id: "biological-agriculture-and-horticulture",
    text: "Biological Agriculture &amp; Horticulture",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TBAH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "biological-and-pharmaceutical-bulletin",
    text: "Biological and Pharmaceutical Bulletin",
    parent: "NA",
    urlone: "http://bpb.pharm.or.jp/document/inst_e.pdf",
    urltwo: "NA"
  },
  {
    id: "biological-conservation",
    text: "Biological Conservation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biological-control",
    text: "Biological Control",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biological-cybernetics",
    text: "Biological Cybernetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biological-invasions",
    text: "Biological Invasions",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biological-journal-of-the-linnean-society",
    text: "Biological Journal of the Linnean Society",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1095-8312/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "biological-procedures-online",
    text: "Biological Procedures Online",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biological-psychiatry",
    text: "Biological Psychiatry",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/biological-psychiatry/0006-3223/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "biological-psychology",
    text: "Biological Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biological-research",
    text: "Biological Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biological-reviews",
    text: "Biological Reviews",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291469-185X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "biological-rhythm-research",
    text: "Biological Rhythm Research",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NBRR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "biological-theory",
    text: "Biological Theory",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biological-trace-element-research",
    text: "Biological Trace Element Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biologically-inspired-cognitive-architectures",
    text: "Biologically Inspired Cognitive Architectures",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biologicals",
    text: "Biologicals",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biologics-in-therapy",
    text: "Biologics in Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biology-and-fertility-of-soils",
    text: "Biology and Fertility of Soils",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biology-and-philosophy",
    text: "Biology &amp; Philosophy",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biology-direct",
    text: "Biology Direct",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biology-letters",
    text: "Biology Letters",
    parent: "proceedings-of-the-royal-society-b",
    urlone: "https://royalsociety.org/journals/authors/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "biology-of-blood-and-marrow-transplantation",
    text: "Biology of Blood and Marrow Transplantation",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biology-of-reproduction",
    text: "Biology of Reproduction",
    parent: "NA",
    urlone: "http://www.biolreprod.org/site/IforA/BOR_InfoForAuthors_2013-04-19.pdf",
    urltwo: "NA"
  },
  {
    id: "biology-of-sex-differences",
    text: "Biology of Sex Differences",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biology-open",
    text: "Biology Open",
    parent: "the-company-of-biologists",
    urlone: "http://bio.biologists.org/site/author/manuscript_preparation.xhtml#refs",
    urltwo: "NA"
  },
  {
    id: "biology",
    text: "Biology",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "biomacromolecules",
    text: "Biomacromolecules",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/bomaf6/bomaf6_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "biomarker-research",
    text: "Biomarker Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biomarkers-and-genomic-medicine",
    text: "Biomarkers and Genomic Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomarkers-in-medicine",
    text: "Biomarkers in Medicine",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/bmm",
    urltwo: "NA"
  },
  {
    id: "biomass-and-bioenergy",
    text: "Biomass and Bioenergy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomass-conversion-and-biorefinery",
    text: "Biomass Conversion and Biorefinery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biomaterials-research",
    text: "Biomaterials Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biomaterials-science",
    text: "Biomaterials Science",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomaterials",
    text: "Biomaterials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomatter",
    text: "Biomatter",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomechanics-and-modeling-in-mechanobiology",
    text: "Biomechanics and Modeling in Mechanobiology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biomed-central",
    text: "BioMed Central",
    parent: "NA",
    urlone:
      "http://bmcbioinformatics.biomedcentral.com/submission-guidelines/preparing-your-manuscript/research-article",
    urltwo: "NA"
  },
  {
    id: "biomed-research-international",
    text: "BioMed Research International",
    parent: "NA",
    urlone: "http://www.hindawi.com/journals/bmri/guidelines/",
    urltwo: "NA"
  },
  {
    id: "biomedical-engineering-online",
    text: "BioMedical Engineering OnLine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biomedical-imaging-and-intervention-journal",
    text: "Biomedical Imaging and Intervention Journal",
    parent: "vancouver",
    urlone: "http://www.biij.org/biomedical-imaging-intervention-journal-instruction-for-authors.asp",
    urltwo: "NA"
  },
  {
    id: "biomedical-journal",
    text: "Biomedical Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomedical-materials",
    text: "Biomedical Materials",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1748-605X",
    urltwo: "NA"
  },
  {
    id: "biomedical-microdevices",
    text: "Biomedical Microdevices",
    parent: "springer-physics-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biomedical-optics-express",
    text: "Biomedical Optics Express",
    parent: "optics-express",
    urlone: "https://www.osapublishing.org/submit/templates/default.cfm",
    urltwo: "https://www.osapublishing.org/submit/style/oestyleguide.cfm"
  },
  {
    id: "biomedical-physics-and-engineering-express",
    text: "Biomedical Physics &amp; Engineering Express",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "biomedical-reports",
    text: "Biomedical Reports",
    parent: "spandidos-publications",
    urlone: "http://www.spandidos-publications.com/pages/info_for_authors",
    urltwo: "NA"
  },
  {
    id: "biomedical-reviews",
    text: "Biomedical Reviews",
    parent: "vancouver",
    urlone: "http://www.bgscb.org/articles/bmr/instructions-for-authors/instructions-for-authors-3",
    urltwo: "NA"
  },
  {
    id: "biomedical-signal-processing-and-control",
    text: "Biomedical Signal Processing and Control",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomedicine-and-aging-pathology",
    text: "Biomedicine &amp; Aging Pathology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomedicine-and-pharmacotherapy",
    text: "Biomedicine &amp; Pharmacotherapy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomedicine-and-preventive-nutrition",
    text: "Biomedicine &amp; Preventive Nutrition",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomedicine",
    text: "BioMedicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomedicines",
    text: "Biomedicines",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "biometals",
    text: "BioMetals",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biometrics",
    text: "Biometrics",
    parent: "NA",
    urlone: "http://www.biometrics.tibs.org/directions.html#references",
    urltwo: "NA"
  },
  {
    id: "biomicrofluidics",
    text: "Biomicrofluidics",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "biomimetics",
    text: "Biomimetics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "biomolecular-detection-and-quantification",
    text: "Biomolecular Detection and Quantification",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biomolecular-nmr-assignments",
    text: "Biomolecular NMR Assignments",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biomolecules",
    text: "Biomolecules",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "bionanoscience",
    text: "BioNanoScience",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bioorganic-and-medicinal-chemistry-letters",
    text: "Bioorganic &amp; Medicinal Chemistry Letters",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioorganic-and-medicinal-chemistry",
    text: "Bioorganic &amp; Medicinal Chemistry",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioorganic-chemistry",
    text: "Bioorganic Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biophysical-chemistry",
    text: "Biophysical Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biophysical-economics-and-resource-quality",
    text: "Biophysical Economics and Resource Quality",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biophysical-journal",
    text: "Biophysical Journal",
    parent: "NA",
    urlone: "http://download.cell.com/images/edimages/Biophys/Instructions_to_Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "biophysical-reviews",
    text: "Biophysical Reviews",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biophysics-reports",
    text: "Biophysics Reports",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biopolymers",
    text: "Biopolymers",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291097-0282/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "bioprinting",
    text: "Bioprinting",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioprocess-and-biosystems-engineering",
    text: "Bioprocess and Biosystems Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biopsychosocial-medicine",
    text: "BioPsychoSocial Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bioresource-technology-reports",
    text: "Bioresource Technology Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioresource-technology",
    text: "Bioresource Technology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioresources-and-bioprocessing",
    text: "Bioresources and Bioprocessing",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bioresources",
    text: "BioResources",
    parent: "NA",
    urlone: "https://www.ncsu.edu/bioresources/BioRes_Article_Template_Styles.docx",
    urltwo: "https://www.ncsu.edu/bioresources/authors.htm"
  },
  {
    id: "biorisk",
    text: "BioRisk",
    parent: "pensoft-journals",
    urlone: "https://biorisk.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "biosafety-and-health",
    text: "Biosafety and Health",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bioscience",
    text: "BioScience",
    parent: "NA",
    urlone: "https://academic.oup.com/bioscience/pages/General_Instructions#MANUSCRIPT%20PREPARATION",
    urltwo:
      "https://static.primary.prod.gcms.the-infra.com/static/site/bioscience/document/2015-bioscience-style-guide.doc?node=c6b9d18daed88f6f4d7d&amp;version=23527:b7fbc6a6c9646efff583"
  },
  {
    id: "biosemiotics",
    text: "Biosemiotics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biosensors-and-bioelectronics-x",
    text: "Biosensors and Bioelectronics: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biosensors-and-bioelectronics",
    text: "Biosensors and Bioelectronics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biosensors",
    text: "Biosensors",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "biosocieties",
    text: "BioSocieties",
    parent: "NA",
    urlone: "http://www.springer.com/social+sciences/journal/41292#References",
    urltwo: "NA"
  },
  {
    id: "biosurface-and-biotribology",
    text: "Biosurface and Biotribology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biosystems-engineering",
    text: "Biosystems Engineering",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biosystems",
    text: "BioSystems",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biota-neotropica",
    text: "Biota Neotropica",
    parent: "NA",
    urlone: "http://www.biotaneotropica.org.br/v17n3/en/instruction",
    urltwo: "NA"
  },
  {
    id: "biotechniques",
    text: "BioTechniques",
    parent: "NA",
    urlone: "http://www.biotechniques.com/multimedia/archive/00082/Instructions_for_Aut_82363a.pdf",
    urltwo: "NA"
  },
  {
    id: "biotechnology-advances",
    text: "Biotechnology Advances",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biotechnology-and-bioengineering",
    text: "Biotechnology and Bioengineering",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291097-0290/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "biotechnology-and-genetic-engineering-reviews",
    text: "Biotechnology and Genetic Engineering Reviews",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TBGR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "biotechnology-for-biofuels",
    text: "Biotechnology for Biofuels",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biotechnology-journal",
    text: "Biotechnology Journal",
    parent: "apa-numeric-superscript-brackets",
    urlone: "https://onlinelibrary.wiley.com/page/journal/18607314/homepage/2446_for_authors.html",
    urltwo: "NA"
  },
  {
    id: "biotechnology-letters",
    text: "Biotechnology Letters",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "biotechnology-progress",
    text: "Biotechnology Progress",
    parent: "american-medical-association-no-et-al",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1021/(ISSN)1520-6033/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "biotechnology-reports",
    text: "Biotechnology Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biotechnology-research-and-innovation",
    text: "Biotechnology Research and Innovation",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biotribology",
    text: "Biotribology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "biotropica",
    text: "Biotropica",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291744-7429/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "bit-numerical-mathematics",
    text: "BIT Numerical Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bitonline",
    text: "b.i.t.online (note, German)",
    parent: "NA",
    urlone: "https://www.b-i-t-online.de/heft/Autorenhinweise_und_Zitierrichtlinien_2015.pdf",
    urltwo: "NA"
  },
  {
    id: "biuletyn-polskiego-towarzystwa-jezykoznawczego",
    text: "Biuletyn Polskiego Towarzystwa JÄ™zykoznawczego (Polish)",
    parent: "NA",
    urlone: "http://ptj.civ.pl/download/polskie/biuletyn_ptj/Zalecenia-edytorskie_2.pdf",
    urltwo: "NA"
  },
  {
    id: "bjog",
    text: "BJOG",
    parent: "vancouver-superscript",
    urlone: "http://www.bjog.org/view/0/Manuscriptlayout.html",
    urltwo: "NA"
  },
  {
    id: "blood-cancer-discovery",
    text: "Blood Cancer Discovery",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "blood-cancer-journal",
    text: "Blood Cancer Journal",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/bcj/bcj_new_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "blood-cells-molecules-and-diseases",
    text: "Blood Cells, Molecules and Diseases",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "blood-purification",
    text: "Blood Purification",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9735",
    urltwo: "NA"
  },
  {
    id: "blood-reviews",
    text: "Blood Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "blood",
    text: "Blood",
    parent: "NA",
    urlone: "http://bloodjournal.hematologylibrary.org/site/authors/article_structure.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "bluebook-inline",
    text: "Bluebook Inline",
    parent: "NA",
    urlone: "https://www.legalbluebook.com/",
    urltwo: "NA"
  },
  {
    id: "bluebook-law-review",
    text: "Bluebook Law Review",
    parent: "NA",
    urlone: "https://www.legalbluebook.com/",
    urltwo: "NA"
  },
  {
    id: "bluebook2",
    text: "Bluebook Law Review (2)",
    parent: "NA",
    urlone: "https://www.legalbluebook.com/",
    urltwo: "NA"
  },
  {
    id: "bmc-anesthesiology",
    text: "BMC Anesthesiology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-biochemistry",
    text: "BMC Biochemistry",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-bioinformatics",
    text: "BMC Bioinformatics",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-biology",
    text: "BMC Biology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-biophysics",
    text: "BMC Biophysics",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-biotechnology",
    text: "BMC Biotechnology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-cancer",
    text: "BMC Cancer",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-cardiovascular-disorders",
    text: "BMC Cardiovascular Disorders",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-cell-biology",
    text: "BMC Cell Biology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-clinical-pathology",
    text: "BMC Clinical Pathology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-complementary-and-alternative-medicine",
    text: "BMC Complementary and Alternative Medicine",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-dermatology",
    text: "BMC Dermatology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-developmental-biology",
    text: "BMC Developmental Biology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-ear-nose-and-throat-disorders",
    text: "BMC Ear, Nose and Throat Disorders",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-ecology",
    text: "BMC Ecology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-emergency-medicine",
    text: "BMC Emergency Medicine",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-endocrine-disorders",
    text: "BMC Endocrine Disorders",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-evolutionary-biology",
    text: "BMC Evolutionary Biology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-family-practice",
    text: "BMC Family Practice",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-gastroenterology",
    text: "BMC Gastroenterology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-genetics",
    text: "BMC Genetics",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-genomics",
    text: "BMC Genomics",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-geriatrics",
    text: "BMC Geriatrics",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-health-services-research",
    text: "BMC Health Services Research",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-hematology",
    text: "BMC Hematology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-immunology",
    text: "BMC Immunology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-infectious-diseases",
    text: "BMC Infectious Diseases",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-international-health-and-human-rights",
    text: "BMC International Health and Human Rights",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-medical-education",
    text: "BMC Medical Education",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-medical-ethics",
    text: "BMC Medical Ethics",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-medical-genetics",
    text: "BMC Medical Genetics",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-medical-genomics",
    text: "BMC Medical Genomics",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-medical-imaging",
    text: "BMC Medical Imaging",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-medical-informatics-and-decision-making",
    text: "BMC Medical Informatics and Decision Making",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-medical-research-methodology",
    text: "BMC Medical Research Methodology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-medicine",
    text: "BMC Medicine",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-microbiology",
    text: "BMC Microbiology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-molecular-biology",
    text: "BMC Molecular Biology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-musculoskeletal-disorders",
    text: "BMC Musculoskeletal Disorders",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-nephrology",
    text: "BMC Nephrology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-neurology",
    text: "BMC Neurology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-neuroscience",
    text: "BMC Neuroscience",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-nursing",
    text: "BMC Nursing",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-nutrition",
    text: "BMC Nutrition",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-obesity",
    text: "BMC Obesity",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-ophthalmology",
    text: "BMC Ophthalmology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-oral-health",
    text: "BMC Oral Health",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-palliative-care",
    text: "BMC Palliative Care",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-pediatrics",
    text: "BMC Pediatrics",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-pharmacology-and-toxicology",
    text: "BMC Pharmacology and Toxicology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-physiology",
    text: "BMC Physiology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-plant-biology",
    text: "BMC Plant Biology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-pregnancy-and-childbirth",
    text: "BMC Pregnancy and Childbirth",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-psychiatry",
    text: "BMC Psychiatry",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-psychology",
    text: "BMC Psychology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-public-health",
    text: "BMC Public Health",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-pulmonary-medicine",
    text: "BMC Pulmonary Medicine",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-research-notes",
    text: "BMC Research Notes",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-sports-science-medicine-and-rehabilitation",
    text: "BMC Sports Science, Medicine and Rehabilitation",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-structural-biology",
    text: "BMC Structural Biology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-surgery",
    text: "BMC Surgery",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-systems-biology",
    text: "BMC Systems Biology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-urology",
    text: "BMC Urology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-veterinary-research",
    text: "BMC Veterinary Research",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-womens-health",
    text: "BMC Women's Health",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmc-zoology",
    text: "BMC Zoology",
    parent: "biomed-central",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bmj-case-reports",
    text: "BMJ Case Reports",
    parent: "bmj",
    urlone: "http://casereports.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "bmj-open-diabetes-research-and-care",
    text: "BMJ Open Diabetes Research &amp; Care",
    parent: "bmj",
    urlone: "http://drc.bmj.com",
    urltwo: "NA"
  },
  {
    id: "bmj-open-respiratory-research",
    text: "BMJ Open Respiratory Research",
    parent: "bmj",
    urlone: "http://bmjopenrespres.bmj.com",
    urltwo: "NA"
  },
  {
    id: "bmj-open",
    text: "BMJ Open",
    parent: "bmj",
    urlone: "http://bmjopen.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "bmj-quality-and-safety",
    text: "BMJ Quality &amp; Safety",
    parent: "bmj",
    urlone: "http://qualitysafety.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "bmj-quality-improvement-reports",
    text: "BMJ Quality Improvement Reports",
    parent: "bmj",
    urlone: "http://qir.bmj.com",
    urltwo: "NA"
  },
  {
    id: "bmj-supportive-and-palliative-care",
    text: "BMJ Supportive &amp; Palliative Care",
    parent: "bmj",
    urlone: "http://spcare.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "bmj",
    text: "BMJ",
    parent: "NA",
    urlone: "http://group.bmj.com/products/journals/instructions-for-authors/formatting#references",
    urltwo: "NA"
  },
  {
    id: "body-and-society",
    text: "Body &amp; Society",
    parent: "NA",
    urlone: "https://us.sagepub.com/en-us/nam/journal/body-society#ReferenceStyle",
    urltwo: "http://www.uk.sagepub.com/repository/binaries/pdf/SAGE_Harvard_reference_style.pdf"
  },
  {
    id: "body-image",
    text: "Body Image",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "body-movement-and-dance-in-psychotherapy",
    text: "Body, Movement and Dance in Psychotherapy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TBMD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "boletin-de-la-sociedad-espanola-de-ceramica-y-vidrio",
    text: "BoletÃ­n de la Sociedad EspaÃ±ola de CerÃ¡mica y Vidrio",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "boletin-de-la-sociedad-matematica-mexicana",
    text: "BoletÃ­n de la Sociedad MatemÃ¡tica Mexicana",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "boletin-de-pediatria",
    text: "BoletÃ­n de PediatrÃ­a (Spanish)",
    parent: "NA",
    urlone:
      "http://www.sccalp.org/normas-para-la-presentacion-y-publicacion-de-trabajos-en-el-boletin-de-pediatria#Presentacion",
    urltwo: "NA"
  },
  {
    id: "boletin-medico-del-hospital-infantil-de-mexico",
    text: "BoletÃ­n MÃ©dico del Hospital Infantil de MÃ©xico (Spanish)",
    parent: "vancouver",
    urlone: "http://www.scielo.org.mx/revistas/bmim/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "bollettino-d-archeologia-online",
    text: "Bollettino d'archeologia online (Italian)",
    parent: "NA",
    urlone: "http://bollettinodiarcheologiaonline.beniculturali.it/norme-redazionali/",
    urltwo: "NA"
  },
  {
    id: "bollettino-dellunione-matematica-italiana",
    text: "Bollettino dell'Unione Matematica Italiana",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bone-and-joint-research",
    text: "Bone &amp; Joint Research",
    parent: "vancouver-superscript",
    urlone: "http://www.bjr.boneandjoint.org.uk/site/menubar/info_authors.xhtml",
    urltwo: "NA"
  },
  {
    id: "bone-marrow-transplantation",
    text: "Bone Marrow Transplantation",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/bmt/bmt_new_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "bone-reports",
    text: "Bone Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bone",
    text: "Bone",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "borderline-personality-disorder-and-emotion-dysregulation",
    text: "Borderline Personality Disorder and Emotion Dysregulation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "boreal-environment-research",
    text: "Boreal Environment Research",
    parent: "NA",
    urlone: "http://www.borenv.net/",
    urltwo: "NA"
  },
  {
    id: "borsa-istanbul-review",
    text: "Borsa Istanbul Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "botanical-studies",
    text: "Botanical Studies",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "botany",
    text: "Botany",
    parent: "canadian-journal-of-fisheries-and-aquatic-sciences",
    urlone: "http://www.nrcresearchpress.com/page/cjb/authors",
    urltwo: "NA"
  },
  {
    id: "boundary-layer-meteorology",
    text: "Boundary-Layer Meteorology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "boundary-value-problems",
    text: "Boundary Value Problems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bourgogne-franche-comte-nature",
    text: "Bourgogne-Franche-ComtÃ© Nature (French)",
    parent: "NA",
    urlone: "http://www.bourgogne-nature.fr/fr/nos-revues-scientifiques/les-classiques_23_ER1.html",
    urltwo: "NA"
  },
  {
    id: "brachytherapy",
    text: "Brachytherapy",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/brachytherapy/1538-4721/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "brain-and-cognition",
    text: "Brain and Cognition",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "brain-and-development",
    text: "Brain and Development",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "brain-and-language",
    text: "Brain and Language",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "brain-behavior-and-evolution",
    text: "Brain, Behavior and Evolution",
    parent: "NA",
    urlone: "https://www.karger.com/Journal/Guidelines/223831#06",
    urltwo: "NA"
  },
  {
    id: "brain-behavior-and-immunity",
    text: "Brain Behavior and Immunity",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "brain-imaging-and-behavior",
    text: "Brain Imaging and Behavior",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "brain-informatics",
    text: "Brain Informatics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "brain-research-bulletin",
    text: "Brain Research Bulletin",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "brain-research",
    text: "Brain Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "brain-sciences",
    text: "Brain Sciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "brain-stimulation",
    text: "Brain Stimulation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "brain-structure-and-function",
    text: "Brain Structure and Function",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "brain-topography",
    text: "Brain Topography",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "brain-tumor-pathology",
    text: "Brain Tumor Pathology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "brain",
    text: "Brain",
    parent: "NA",
    urlone: "https://academic.oup.com/brain/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "brazilian-journal-of-botany",
    text: "Brazilian Journal of Botany",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "brazilian-journal-of-infectious-diseases",
    text: "Brazilian Journal of Infectious Diseases",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/brazilian-journal-of-infectious-diseases/1413-8670/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "brazilian-journal-of-microbiology",
    text: "Brazilian Journal of Microbiology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "brazilian-journal-of-physical-therapy",
    text: "Brazilian Journal of Physical Therapy",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "brazilian-journal-of-physics",
    text: "Brazilian Journal of Physics",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "brazilian-journal-of-science-and-technology",
    text: "Brazilian Journal of Science and Technology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "brazilian-journal-of-veterinary-research-and-animal-science",
    text: "Brazilian Journal of Veterinary Research and Animal Science",
    parent: "NA",
    urlone: "https://www.revistas.usp.br/bjvras/about/submissions",
    urltwo: "NA"
  },
  {
    id: "brazilian-oral-research",
    text: "Brazilian Oral Research",
    parent: "NA",
    urlone: "http://www.scielo.br/revistas/bor/iinstruc.htm",
    urltwo: "NA"
  },
  {
    id: "breast-cancer-management",
    text: "Breast Cancer Management",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/bmt",
    urltwo: "NA"
  },
  {
    id: "breast-cancer-research-and-treatment",
    text: "Breast Cancer Research and Treatment",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "breast-cancer-research",
    text: "Breast Cancer Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "breast-cancer",
    text: "Breast Cancer",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "breast-care",
    text: "Breast Care",
    parent: "karger-journals",
    urlone: "www.karger.com/Journal/Home/231161",
    urltwo: "NA"
  },
  {
    id: "brewingscience",
    text: "BrewingScience",
    parent: "NA",
    urlone: "https://www.brewingscience.de/index.php?tpl=authors",
    urltwo: "NA"
  },
  {
    id: "briefings-in-bioinformatics",
    text: "Briefings in Bioinformatics",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/bib/for_authors/msprep_submission.html",
    urltwo: "NA"
  },
  {
    id: "british-dental-journal",
    text: "British Dental Journal",
    parent: "european-journal-of-human-genetics",
    urlone: "http://www.nature.com/bdj/authors/guidelines/guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-anaesthesia",
    text: "British Journal of Anaesthesia",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/bjaint/for_authors/index.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-biomedical-science",
    text: "British Journal of Biomedical Science",
    parent: "vancouver-superscript",
    urlone: "http://www.bjbs-online.org/authors.asp",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-cancer",
    text: "British Journal of Cancer",
    parent: "NA",
    urlone: "http://www.nature.com/bjc/authors/submit.html#how-to-submit",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-clinical-pharmacology",
    text: "British Journal of Clinical Pharmacology",
    parent: "american-medical-association",
    urlone: "https://bpspubs.onlinelibrary.wiley.com/hub/journal/13652125/author-guidelines.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-clinical-psychology",
    text: "British Journal of Clinical Psychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-8260/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-dermatology",
    text: "British Journal of Dermatology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2133/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-developmental-psychology",
    text: "British Journal of Developmental Psychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-835X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-educational-psychology",
    text: "British Journal of Educational Psychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-8279/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-guidance-and-counselling",
    text: "British Journal of Guidance &amp; Counselling",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CBJG20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-haematology",
    text: "British Journal of Haematology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2141/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-health-psychology",
    text: "British Journal of Health Psychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-8287/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-industrial-relations",
    text: "British Journal of Industrial Relations",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291467-8543/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-mathematical-and-statistical-psychology",
    text: "British Journal of Mathematical and Statistical Psychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-8317/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-ophthalmology",
    text: "British Journal of Ophthalmology",
    parent: "bmj",
    urlone: "http://bjo.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-pain",
    text: "British Journal of Pain",
    parent: "sage-vancouver",
    urlone: "http://www.uk.sagepub.com/msg/bjp.htm#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-pharmacology",
    text: "British Journal of Pharmacology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1476-5381/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-political-science",
    text: "British Journal of Political Science",
    parent: "NA",
    urlone:
      "https://www.cambridge.org/core/journals/british-journal-of-political-science/information/instructions-contributors/style-guide",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-psychology",
    text: "British Journal of Psychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-8295/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-religious-education",
    text: "British Journal of Religious Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CBRE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-social-psychology",
    text: "British Journal of Social Psychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-8309/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-sociology-of-education",
    text: "British Journal of Sociology of Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CBSE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-sports-medicine",
    text: "British Journal of Sports Medicine",
    parent: "bmj",
    urlone: "http://bjsm.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "british-journal-of-surgery",
    text: "British Journal of Surgery",
    parent: "NA",
    urlone: "http://www.bjs.co.uk/view/instructionsToAuthors.html",
    urltwo: "NA"
  },
  {
    id: "british-medical-bulletin",
    text: "British Medical Bulletin",
    parent: "vancouver-superscript",
    urlone: "http://www.oxfordjournals.org/our_journals/brimed/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "brq-business-research-quarterly",
    text: "BRQ Business Research Quarterly",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "budownictwo-i-architektura-pl",
    text: "Budownictwo i Architektura",
    parent: "NA",
    urlone: "http://bud-arch.pollub.pl/index.php/pl/dla-autorow/",
    urltwo: "NA"
  },
  {
    id: "building-and-construction-law-journal",
    text: "Building and Construction Law Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "building-and-environment",
    text: "Building and Environment",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "building-research-and-information",
    text: "Building Research &amp; Information",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RBRI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "building-structure",
    text: "Building Structure (Chinese)",
    parent: "NA",
    urlone: "http://www.buildingstructure.cn/",
    urltwo: "NA"
  },
  {
    id: "buildings",
    text: "Buildings",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "buletin-agrohorti",
    text: "Buletin Agrohorti",
    parent: "NA",
    urlone: "http://journal.ipb.ac.id/index.php/bulagron/article/view/26572",
    urltwo: "NA"
  },
  {
    id: "bulletin-de-correspondance-hellenique",
    text: "Bulletin de Correspondance HellÃ©nique (French)",
    parent: "NA",
    urlone: "http://www.efa.gr/images/publication/Normes_Antiquite_V14.pdf",
    urltwo: "NA"
  },
  {
    id: "bulletin-de-la-societe-entomologique-de-france",
    text: "Bulletin de la SociÃ©tÃ© Entomologique de France",
    parent: "NA",
    urlone: "http://www.lasef.org/publications/le-bulletin-de-la-sef/",
    urltwo: "NA"
  },
  {
    id: "bulletin-de-la-societe-prehistorique-francaise",
    text: "Bulletin de la SociÃ©tÃ© prÃ©historique franÃ§aise (French)",
    parent: "NA",
    urlone: "http://www.frantiq.fr/",
    urltwo: "NA"
  },
  {
    id: "bulletin-de-linstitut-francais-darcheologie-orientale",
    text: "Bulletin de lâ€™Institut franÃ§ais dâ€™archÃ©ologie orientale",
    parent: "institut-francais-darcheologie-orientale",
    urlone: "https://www.ifao.egnet.net/publications/publier/normes-ed/",
    urltwo: "https://www.ifao.egnet.net/publications/catalogue/BIFAO/"
  },
  {
    id: "bulletin-des-sciences-mathematiques",
    text: "Bulletin des sciences mathÃ©matiques",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bulletin-du-cancer",
    text: "Bulletin du Cancer",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bulletin-du-centre-detudes-medievales-dauxerre",
    text: "Bulletin du Centre dâ€™Ã©tudes mÃ©diÃ©vales dâ€™Auxerre (French)",
    parent: "NA",
    urlone: "http://cem.revues.org/index522.html#tocfrom1n3",
    urltwo: "NA"
  },
  {
    id: "bulletin-of-earthquake-engineering",
    text: "Bulletin of Earthquake Engineering",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bulletin-of-engineering-geology-and-the-environment",
    text: "Bulletin of Engineering Geology and the Environment",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bulletin-of-environmental-contamination-and-toxicology",
    text: "Bulletin of Environmental Contamination and Toxicology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bulletin-of-faculty-of-pharmacy-cairo-university",
    text: "Bulletin of Faculty of Pharmacy, Cairo University",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bulletin-of-geosciences",
    text: "Bulletin of Geosciences",
    parent: "NA",
    urlone: "http://www.geology.cz/bulletin/instructions",
    urltwo: "NA"
  },
  {
    id: "bulletin-of-marine-science",
    text: "Bulletin of Marine Science",
    parent: "NA",
    urlone: "http://www.rsmas.miami.edu/bms/PDF/INSTRUCTIONS_TO_AUTHORS.pdf",
    urltwo: "NA"
  },
  {
    id: "bulletin-of-mathematical-biology",
    text: "Bulletin of Mathematical Biology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bulletin-of-mathematical-sciences",
    text: "Bulletin of Mathematical Sciences",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bulletin-of-the-american-meteorological-society",
    text: "Bulletin of the American Meteorological Society",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/bams",
    urltwo: "NA"
  },
  {
    id: "bulletin-of-the-american-schools-of-oriental-research",
    text: "Bulletin of the American Schools of Oriental Research",
    parent: "NA",
    urlone: "http://www.asor.org/asor-publications/basor/",
    urltwo: "NA"
  },
  {
    id: "bulletin-of-the-chemical-society-of-japan",
    text: "Bulletin of the Chemical Society of Japan",
    parent: "the-chemical-society-of-japan",
    urlone: "http://www.csj.jp/journals/styles/ref.html",
    urltwo: "NA"
  },
  {
    id: "bulletin-of-the-malaysian-mathematical-sciences-society",
    text: "Bulletin of the Malaysian Mathematical Sciences Society",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bulletin-of-the-seismological-society-of-america",
    text: "Bulletin of the Seismological Society of America",
    parent: "NA",
    urlone: "https://www.seismosoc.org/publications/bssa-submission-guidelines/",
    urltwo: "NA"
  },
  {
    id: "bulletin-of-the-world-health-organization",
    text: "Bulletin of the World Health Organization",
    parent: "vancouver",
    urlone: "http://www.who.int/bulletin/contributors/current_guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "bulletin-of-volcanology",
    text: "Bulletin of Volcanology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "bundesgesundheitsblatt-gesundheitsforschung-gesundheitsschutz",
    text: "Bundesgesundheitsblatt - Gesundheitsforschung - Gesundheitsschutz (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/journal/103?detailsPage=pltci_1426209",
    urltwo: "NA"
  },
  {
    id: "burnout-research",
    text: "Burnout Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "burns-and-trauma",
    text: "Burns &amp; Trauma",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "burns-open",
    text: "Burns Open",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "burns",
    text: "Burns",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "bursa-uludag-universitesi-egitim-bilimleri-enstitusu",
    text: "Bursa UludaÄŸ Ãœniversitesi - EÄŸitim Bilimleri EnstitÃ¼sÃ¼",
    parent: "NA",
    urlone: "http://www.uludag.edu.tr/dosyalar/egitimbilimleri/tez_yazim_kurallari_Mart_2016_D%C3%BCzeltme.pdf",
    urltwo: "NA"
  },
  {
    id: "bursa-uludag-universitesi-fen-bilimleri-enstitusu",
    text: "Bursa Uludag Ãœniversitesi Fen Bilimleri EnstitÃ¼sÃ¼",
    parent: "NA",
    urlone: "http://www.uludag.edu.tr/dosyalar/fenbilimleri/fen%20bilimleri/FBE_Tez_Yazim_Kurallari_YENI.pdf",
    urltwo: "NA"
  },
  {
    id: "bursa-uludag-universitesi-saglik-bilimleri-enstitusu",
    text: "Bursa UludaÄŸ Ãœniversitesi - SaÄŸlÄ±k Bilimleri EnstitÃ¼sÃ¼",
    parent: "NA",
    urlone: "https://uludag.edu.tr/dosyalar/saglikbilimleri/duyuruimages/Duyurular/tez_yazim_klavuzu_14_eylul_2020.pdf",
    urltwo: "NA"
  },
  {
    id: "bursa-uludag-universitesi-sosyal-bilimler-enstitusu-metinici-atif",
    text: "Bursa UludaÄŸ Ãœniversitesi - Sosyal Bilimler EnstitÃ¼sÃ¼ (metinici atif)",
    parent: "NA",
    urlone:
      "http://www.uludag.edu.tr/dosyalar/sosyalbilimler/2016%20Duyurular/SBE%20TEZ%20YAZ.%20KIL.(YEN%C4%B0)%2023.09.2016%20(1).pdf",
    urltwo: "NA"
  },
  {
    id: "bursa-uludag-universitesi-sosyal-bilimler-enstitusu",
    text: "Bursa UludaÄŸ Ãœniversitesi - Sosyal Bilimler EnstitÃ¼sÃ¼",
    parent: "NA",
    urlone:
      "http://www.uludag.edu.tr/dosyalar/sosyalbilimler/2017%20Duyrular/SBE%20TEZ%20YAZ.%20KIL.(22%20Kas%C4%B1m%202017).pdf",
    urltwo: "NA"
  },
  {
    id: "business-and-economic-horizons",
    text: "Business and Economic Horizons",
    parent: "apa",
    urlone: "https://academicpublishingplatforms.com/journal.php?journal=BEH#guides_to_authors",
    urltwo: "NA"
  },
  {
    id: "business-and-information-systems-engineering",
    text: "Business &amp; Information Systems Engineering",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "business-ethics-a-european-review",
    text: "Business Ethics: A European Review",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1467-8608/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "business-history",
    text: "Business History",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FBSH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "business-horizons",
    text: "Business Horizons",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "business-research",
    text: "Business Research",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "business-trends",
    text: "Business Trends (Trendy v podnikÃ¡nÃ­)",
    parent: "apa",
    urlone: "https://www.dfek.zcu.cz/en/tvp/#pokyny",
    urltwo: "NA"
  },
  {
    id: "byzantina-symmeikta",
    text: "BYZANTINA SYMMEIKTA",
    parent: "NA",
    urlone: "http://www.byzsym.org/index.php/bz/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "byzantine-and-modern-greek-studies",
    text: "Byzantine and Modern Greek Studies",
    parent: "NA",
    urlone:
      "https://www.cambridge.org/core/journals/byzantine-and-modern-greek-studies/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "c",
    text: "C",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "caai-transactions-on-intelligence-technology",
    text: "CAAI Transactions on Intelligence Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cahiers-d-ethnomusicologie",
    text: "Cahiers d'ethnomusicologie (French)",
    parent: "NA",
    urlone: "http://www.adem-geneve.com/fr/cahiers-d-ethnomusicologie-contribution",
    urltwo: "NA"
  },
  {
    id: "cahiers-de-la-puericultrice",
    text: "Cahiers de la puÃ©ricultrice",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cahiers-de-nutrition-et-de-dietetique",
    text: "Cahiers de Nutrition et de DiÃ©tÃ©tique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cahiers-du-centre-gustave-glotz",
    text: "Cahiers du Centre Gustave-Glotz (French)",
    parent: "NA",
    urlone: "http://www.anhima.fr/IMG/docx/normes-ccg-2013.docx",
    urltwo: "NA"
  },
  {
    id: "calcified-tissue-international",
    text: "Calcified Tissue International",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "calcolo",
    text: "Calcolo",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "calculus-of-variations-and-partial-differential-equations",
    text: "Calculus of Variations and Partial Differential Equations",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "california-agriculture",
    text: "California Agriculture",
    parent: "NA",
    urlone: "http://calag.ucanr.edu/SubmitArticles/#Matters%20of%20style",
    urltwo: "NA"
  },
  {
    id: "calphad",
    text: "Calphad",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cambridge-archaeological-journal",
    text: "Cambridge Archaeological Journal",
    parent: "mcdonald-institute-monographs",
    urlone: "http://www.mcdonald.cam.ac.uk/publications/publication-images/misc-pdf/caj-ifc",
    urltwo: "NA"
  },
  {
    id: "cambridge-journal-of-economics",
    text: "Cambridge Journal of Economics",
    parent: "NA",
    urlone: "https://academic.oup.com/cje/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "cambridge-journal-of-education",
    text: "Cambridge Journal of Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCJE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "cambridge-university-press-author-date",
    text: "Cambridge University Press (author-date)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cambridge-university-press-law-note",
    text: "Cambridge University Press - Law (note)",
    parent: "NA",
    urlone: "https://authornet.cambridge.org/information/academic/guidelines_for_authors.asp",
    urltwo: "https://authornet.cambridge.org/information/academic/downloads/General%20style%20guidelines.pdf"
  },
  {
    id: "cambridge-university-press-note",
    text: "Cambridge University Press (note)",
    parent: "NA",
    urlone: "https://authornet.cambridge.org/information/academic/guidelines_for_authors.asp",
    urltwo: "https://authornet.cambridge.org/information/academic/downloads/Style%20Guide.pdf"
  },
  {
    id: "cambridge-university-press-numeric",
    text: "Cambridge University Press (numeric)",
    parent: "NA",
    urlone: "https://authornet.cambridge.org/information/academic/guidelines_for_authors.asp",
    urltwo: "NA"
  },
  {
    id: "campus-adventiste-du-saleve-faculte-adventiste-de-theologie",
    text: "Campus adventiste du SalÃ¨ve - FacultÃ© adventiste de thÃ©ologie (French)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/668",
    urltwo: "NA"
  },
  {
    id: "canadian-association-of-radiologists-journal",
    text: "Canadian Association of Radiologists Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "canadian-biosystems-engineering",
    text: "Canadian Biosystems Engineering",
    parent: "NA",
    urlone: "https://www.csbe-scgab.ca/images/journal/prepareweb.pdf",
    urltwo: "NA"
  },
  {
    id: "canadian-family-physician",
    text: "Canadian Family Physician",
    parent: "vancouver",
    urlone: "http://www.cfp.ca/site/Authors/Instructions.xhtml",
    urltwo: "NA"
  },
  {
    id: "canadian-geotechnical-journal",
    text: "Canadian Geotechnical Journal",
    parent: "NA",
    urlone: "https://www.nrcresearchpress.com/page/cgj/authors#9d",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-african-studies-la-revue-canadienne-des-etudes-africaines",
    text: "Canadian Journal of African Studies/ La Revue canadienne des Ã©tudes africaines",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCAS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-anesthesia",
    text: "Canadian Journal of Anesthesia/Journal canadien d'anesthÃ©sie",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "canadian-journal-of-animal-science",
    text: "Canadian Journal of Animal Science",
    parent: "canadian-journal-of-soil-science",
    urlone: "http://www.aic.ca/journals/pdf/Instructions/Operations_Manual_Revised_2012.pdf",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-behavioural-science",
    text: "Canadian Journal of Behavioural Science / Revue canadienne des sciences du comportement",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cbs",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-cardiology",
    text: "Canadian Journal of Cardiology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-chemistry",
    text: "Canadian Journal of Chemistry",
    parent: "NA",
    urlone: "http://www.nrcresearchpress.com/page/cjc/authors",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-civil-engineering",
    text: "Canadian Journal of Civil Engineering",
    parent: "canadian-geotechnical-journal",
    urlone: "http://www.nrcresearchpress.com/page/cjce/authors",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-development-studies-revue-canadienne-detudes-du-developpement",
    text: "Canadian Journal of Development Studies / Revue canadienne d'Ã©tudes du dÃ©veloppement",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCJD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-dietetic-practice-and-research",
    text: "Canadian Journal of Dietetic Practice and Research",
    parent: "NA",
    urlone: "http://www.dietitians.ca/Downloadable-Content/Public/guide_for_authors.aspx",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-earth-sciences",
    text: "Canadian Journal of Earth Sciences",
    parent: "NA",
    urlone: "http://www.nrcresearchpress.com/page/cjes/authors#9d",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-economics",
    text: "Canadian Journal of Economics",
    parent: "NA",
    urlone: "http://economics.ca/cje/en/style.php",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-electrical-and-computer-engineering",
    text: "Canadian Journal of Electrical and Computer Engineering",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=9754",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-experimental-psychology",
    text: "Canadian Journal of Experimental Psychology / Revue canadienne de psychologie expÃ©rimentale",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cep",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-fisheries-and-aquatic-sciences",
    text: "Canadian Journal of Fisheries and Aquatic Sciences",
    parent: "NA",
    urlone: "http://www.nrcresearchpress.com/page/cjfas/authors",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-forest-research",
    text: "Canadian Journal of Forest Research",
    parent: "canadian-journal-of-fisheries-and-aquatic-sciences",
    urlone: "http://www.nrcresearchpress.com/page/cjfr/authors",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-kidney-health-and-disease",
    text: "Canadian Journal of Kidney Health and Disease",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "canadian-journal-of-microbiology",
    text: "Canadian Journal of Microbiology",
    parent: "canadian-journal-of-fisheries-and-aquatic-sciences",
    urlone: "http://www.nrcresearchpress.com/page/cjm/authors",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-occupational-therapy",
    text: "Canadian Journal of Occupational Therapy",
    parent: "apa",
    urlone: "http://www.caot.ca/cjot_pdfs/cjot%20ag.pdf",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-ophthalmology",
    text: "Canadian Journal of Ophthalmology/Journal canadien d'ophtalmologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-philosophy",
    text: "Canadian Journal of Philosophy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCJP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-physics",
    text: "Canadian Journal of Physics",
    parent: "NA",
    urlone: "http://www.nrcresearchpress.com/page/cjp/authors#9d",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-physiology-and-pharmacology",
    text: "Canadian Journal of Physiology and Pharmacology",
    parent: "apa",
    urlone: "http://www.nrcresearchpress.com/page/cjpp/authors#9d",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-plant-science",
    text: "Canadian Journal of Plant Science",
    parent: "canadian-journal-of-soil-science",
    urlone: "http://www.aic.ca/journals/pdf/Instructions/Operations_Manual_Revised_2012.pdf",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-public-health",
    text: "Canadian Journal of Public Health",
    parent: "NA",
    urlone: "https://journal.cpha.ca/index.php/cjph/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-soil-science",
    text: "Canadian Journal of Soil Science",
    parent: "NA",
    urlone: "http://www.nrcresearchpress.com/page/cjss/authors#21",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-surgery",
    text: "Canadian Journal of Surgery",
    parent: "vancouver-superscript",
    urlone: "http://www.cma.ca/index.php/ci_id/33487/la_id/1.htm",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-veterinary-research",
    text: "Canadian Journal of Veterinary Research",
    parent: "vancouver",
    urlone: "http://canadianveterinarians.net/publications/cjvr-instructions-authors.aspx",
    urltwo: "NA"
  },
  {
    id: "canadian-journal-of-zoology",
    text: "Canadian Journal of Zoology",
    parent: "canadian-journal-of-fisheries-and-aquatic-sciences",
    urlone: "http://www.nrcresearchpress.com/page/cjz/authors",
    urltwo: "NA"
  },
  {
    id: "canadian-pharmacists-journal",
    text: "Canadian Pharmacists Journal",
    parent: "vancouver",
    urlone: "http://www.cpjournal.ca/",
    urltwo: "NA"
  },
  {
    id: "canadian-psychology",
    text: "Canadian Psychology / Psychologie canadienne",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cap",
    urltwo: "NA"
  },
  {
    id: "canadian-public-policy",
    text: "Canadian Public Policy",
    parent: "NA",
    urlone: "http://economics.ca/cpp/en/authors.php",
    urltwo: "NA"
  },
  {
    id: "cancer-and-metabolism",
    text: "Cancer &amp; Metabolism",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cancer-and-metastasis-reviews",
    text: "Cancer and Metastasis Reviews",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cancer-biology-and-medicine",
    text: "Cancer Biology &amp; Medicine",
    parent: "vancouver",
    urlone: "http://www.cancerbiomed.org/index.php/cocr",
    urltwo: "NA"
  },
  {
    id: "cancer-biology-and-therapy",
    text: "Cancer Biology &amp; Therapy",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cancer-causes-and-control",
    text: "Cancer Causes &amp; Control",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cancer-cell-international",
    text: "Cancer Cell International",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cancer-cell",
    text: "Cancer Cell",
    parent: "cell",
    urlone: "http://www.cell.com/cancer-cell/authors",
    urltwo: "NA"
  },
  {
    id: "cancer-chemotherapy-and-pharmacology",
    text: "Cancer Chemotherapy and Pharmacology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cancer-discovery",
    text: "Cancer Discovery",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "cancer-epidemiology-biomarkers-and-prevention",
    text: "Cancer Epidemiology, Biomarkers &amp; Prevention",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "cancer-epidemiology",
    text: "Cancer Epidemiology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cancer-gene-therapy",
    text: "Cancer Gene Therapy",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-cgt.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "cancer-genetics",
    text: "Cancer Genetics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cancer-imaging",
    text: "Cancer Imaging",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cancer-immunology-immunotherapy",
    text: "Cancer Immunology, Immunotherapy",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cancer-immunology-research",
    text: "Cancer Immunology Research",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "cancer-letters",
    text: "Cancer Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cancer-microenvironment",
    text: "Cancer Microenvironment",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cancer-nanotechnology",
    text: "Cancer Nanotechnology",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cancer-prevention-research",
    text: "Cancer Prevention Research",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "cancer-radiotherapie",
    text: "Cancer / RadiothÃ©rapie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cancer-research",
    text: "Cancer Research",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "cancer-translational-medicine",
    text: "Cancer Translational Medicine",
    parent: "NA",
    urlone: "http://www.cancertm.com/contributors.asp#ref",
    urltwo: "NA"
  },
  {
    id: "cancer-treatment-and-research-communications",
    text: "Cancer Treatment and Research Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cancer-treatment-reviews",
    text: "Cancer Treatment Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cancers",
    text: "Cancers",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "canine-genetics-and-epidemiology",
    text: "Canine Genetics and Epidemiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "capitalism-nature-socialism",
    text: "Capitalism Nature Socialism",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCNS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "carbohydrate-polymers",
    text: "Carbohydrate Polymers",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "carbohydrate-research",
    text: "Carbohydrate Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "carbon-balance-and-management",
    text: "Carbon Balance and Management",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "carbon-management",
    text: "Carbon Management",
    parent: "future-science-group",
    urlone: "http://www.future-science.com/loi/cmt",
    urltwo: "NA"
  },
  {
    id: "carbon-resources-conversion",
    text: "Carbon Resources Conversion",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "carbon",
    text: "Carbon",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "carbonates-and-evaporites",
    text: "Carbonates and Evaporites",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "carcinogenesis",
    text: "Carcinogenesis",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/carcin/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "cardiff-university-biosi-and-carbs-harvard",
    text: "Cardiff University BIOSI and CARBS - Harvard",
    parent: "NA",
    urlone: "https://xerte.cardiff.ac.uk/play_12072",
    urltwo: "https://xerte.cardiff.ac.uk/play_10324"
  },
  {
    id: "cardiff-university-vancouver",
    text: "Cardiff University - Vancouver",
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "https://xerte.cardiff.ac.uk/play_4351"
  },
  {
    id: "cardio-oncology",
    text: "Cardio-Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cardiocore",
    text: "Cardiocore (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/cardiocore/1889-898X/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "cardiology-and-therapy",
    text: "Cardiology and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cardiology",
    text: "Cardiology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9751",
    urltwo: "NA"
  },
  {
    id: "cardiorenal-medicine",
    text: "Cardiorenal Medicine",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/254695",
    urltwo: "NA"
  },
  {
    id: "cardiovascular-and-interventional-radiology",
    text: "CardioVascular and Interventional Radiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cardiovascular-diabetology",
    text: "Cardiovascular Diabetology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cardiovascular-drugs-and-therapy",
    text: "Cardiovascular Drugs and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cardiovascular-intervention-and-therapeutics",
    text: "Cardiovascular Intervention and Therapeutics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cardiovascular-pathology",
    text: "Cardiovascular Pathology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cardiovascular-research",
    text: "Cardiovascular Research",
    parent: "european-society-of-cardiology",
    urlone: "http://cardiovascres.oxfordjournals.org/",
    urltwo: "NA"
  },
  {
    id: "cardiovascular-revascularization-medicine",
    text: "Cardiovascular Revascularization Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cardiovascular-toxicology",
    text: "Cardiovascular Toxicology",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cardiovascular-ultrasound",
    text: "Cardiovascular Ultrasound",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "caries-research",
    text: "Caries Research",
    parent: "karger-journals-author-date",
    urlone: "http://www.karger.com?issn=1421-976X",
    urltwo: "NA"
  },
  {
    id: "cartography-and-geographic-information-science",
    text: "Cartography and Geographic Information Science",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TCAG20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "case-reports-in-dermatology",
    text: "Case Reports in Dermatology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/239060",
    urltwo: "NA"
  },
  {
    id: "case-reports-in-gastroenterology",
    text: "Case Reports in Gastroenterology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/232833",
    urltwo: "NA"
  },
  {
    id: "case-reports-in-nephrology-and-urology",
    text: "Case Reports in Nephrology and Urology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/254804",
    urltwo: "NA"
  },
  {
    id: "case-reports-in-neurology",
    text: "Case Reports in Neurology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/238704",
    urltwo: "NA"
  },
  {
    id: "case-reports-in-oncology",
    text: "Case Reports in Oncology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/239061",
    urltwo: "NA"
  },
  {
    id: "case-reports-in-ophthalmology",
    text: "Case Reports in Ophthalmology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/251887",
    urltwo: "NA"
  },
  {
    id: "case-reports-in-womens-health",
    text: "Case Reports in Women's Health",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "case-studies-in-construction-materials",
    text: "Case Studies in Construction Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "case-studies-in-engineering-failure-analysis",
    text: "Case Studies in Engineering Failure Analysis",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "case-studies-in-fire-safety",
    text: "Case Studies in Fire Safety",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "case-studies-in-mechanical-systems-and-signal-processing",
    text: "Case Studies in Mechanical Systems and Signal Processing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "case-studies-in-nondestructive-testing-and-evaluation",
    text: "Case Studies in Nondestructive Testing and Evaluation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "case-studies-in-structural-engineering",
    text: "Case Studies in Structural Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "case-studies-in-thermal-engineering",
    text: "Case Studies in Thermal Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "case-studies-on-transport-policy",
    text: "Case Studies on Transport Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "catalysis-communications",
    text: "Catalysis Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "catalysis-in-green-chemistry-and-engineering",
    text: "Catalysis in Green Chemistry and Engineering",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/74e300906b6eb358.html",
    urltwo: "NA"
  },
  {
    id: "catalysis-letters",
    text: "Catalysis Letters",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "catalysis-science-and-technology",
    text: "Catalysis Science &amp; Technology",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "catalysis-surveys-from-asia",
    text: "Catalysis Surveys from Asia",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "catalysis-today",
    text: "Catalysis Today",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "catalysts",
    text: "Catalysts",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "catena",
    text: "Catena",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cath-lab-digest",
    text: "Cath Lab Digest",
    parent: "NA",
    urlone: "http://www.cathlabdigest.com/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "catholic-biblical-association",
    text: "Catholic Biblical Association (full note)",
    parent: "NA",
    urlone: "http://cba.cua.edu/instruct2%5Ccbqinstr%5Cinstrcbq.pdf",
    urltwo: "NA"
  },
  {
    id: "catholic-biblical-quarterly",
    text: "Catholic Biblical Quarterly",
    parent: "catholic-biblical-association",
    urlone: "http://catholicbiblical.org/publications/cbq/cbq-instructions",
    urltwo: "NA"
  },
  {
    id: "cbe-life-sciences-education",
    text: "CBE - Life Sciences Education",
    parent: "apa-old-doi-prefix",
    urlone: "http://www.lifescied.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "ceas-aeronautical-journal",
    text: "CEAS Aeronautical Journal",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ceas-space-journal",
    text: "CEAS Space Journal",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "celestial-mechanics-and-dynamical-astronomy",
    text: "Celestial Mechanics and Dynamical Astronomy",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cell-adhesion-and-migration",
    text: "Cell Adhesion &amp; Migration",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cell-and-bioscience",
    text: "Cell &amp; Bioscience",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cell-and-tissue-banking",
    text: "Cell and Tissue Banking",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cell-and-tissue-research",
    text: "Cell and Tissue Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cell-biochemistry-and-biophysics",
    text: "Cell Biochemistry and Biophysics",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cell-biology-and-toxicology",
    text: "Cell Biology and Toxicology",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cell-calcium",
    text: "Cell Calcium",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cell-chemical-biology",
    text: "Cell Chemical Biology",
    parent: "cell",
    urlone: "http://www.cell.com/cell-chemical-biology/authors",
    urltwo: "NA"
  },
  {
    id: "cell-communication-and-signaling",
    text: "Cell Communication and Signaling",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cell-cycle",
    text: "Cell Cycle",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cell-death-and-differentiation",
    text: "Cell Death &amp; Differentiation",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-cdd.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "cell-death-and-disease",
    text: "Cell Death and Disease",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-cddis.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "cell-division",
    text: "Cell Division",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cell-host-and-microbe",
    text: "Cell Host &amp; Microbe",
    parent: "cell",
    urlone: "http://www.cell.com/cell-host-microbe/authors",
    urltwo: "NA"
  },
  {
    id: "cell-metabolism",
    text: "Cell Metabolism",
    parent: "cell",
    urlone: "http://www.cell.com/cell-metabolism/authors",
    urltwo: "NA"
  },
  {
    id: "cell-numeric-superscript",
    text: "Cell journals (numeric, superscript)",
    parent: "NA",
    urlone: "http://www.cell.com/chem/authors",
    urltwo: "NA"
  },
  {
    id: "cell-numeric",
    text: "Cell journals (numeric)",
    parent: "NA",
    urlone: "http://www.cell.com/current-biology/authors",
    urltwo: "NA"
  },
  {
    id: "cell-regeneration",
    text: "Cell Regeneration",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cell-reports",
    text: "Cell Reports",
    parent: "cell",
    urlone: "http://www.cell.com/cell-reports/authors",
    urltwo: "NA"
  },
  {
    id: "cell-research",
    text: "Cell Research",
    parent: "NA",
    urlone: "http://mts-cr.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions",
    urltwo: "NA"
  },
  {
    id: "cell-stem-cell",
    text: "Cell Stem Cell",
    parent: "cell",
    urlone: "http://www.cell.com/cell-stem-cell/authors",
    urltwo: "NA"
  },
  {
    id: "cell-stress-and-chaperones",
    text: "Cell Stress and Chaperones",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cell-stress",
    text: "Cell Stress",
    parent: "microbial-cell",
    urlone: "http://www.cell-stress.com/for-authors/manuscript-preparation/#references",
    urltwo: "NA"
  },
  {
    id: "cell-structure-and-function",
    text: "Cell Structure and Function",
    parent: "NA",
    urlone: "http://www.jscb.gr.jp/csf/en/csf_regulation.html",
    urltwo: "NA"
  },
  {
    id: "cell-systems",
    text: "Cell Systems",
    parent: "cell",
    urlone: "http://www.cell.com/cell-systems/authors",
    urltwo: "NA"
  },
  {
    id: "cell-transplantation",
    text: "Cell Transplantation",
    parent: "NA",
    urlone: "https://www.cognizantcommunication.com/journal-titles/cell-transplantation",
    urltwo: "NA"
  },
  {
    id: "cell",
    text: "Cell",
    parent: "NA",
    urlone: "http://www.cell.com/authors",
    urltwo: "NA"
  },
  {
    id: "cells-tissues-organs",
    text: "Cells Tissues Organs",
    parent: "karger-journals-author-date",
    urlone: "http://www.karger.com?issn=1422-6421",
    urltwo: "NA"
  },
  {
    id: "cells",
    text: "Cells",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "cellular-and-molecular-bioengineering",
    text: "Cellular and Molecular Bioengineering",
    parent: "NA",
    urlone: "http://www.springer.com/engineering/biomedical+engineering/journal/12195",
    urltwo: "NA"
  },
  {
    id: "cellular-and-molecular-gastroenterology-and-hepatology",
    text: "Cellular and Molecular Gastroenterology and Hepatology",
    parent: "NA",
    urlone: "https://www.cmghjournal.org/content/authorinfo",
    urltwo:
      "https://www.elsevier.com/journals/cellular-and-molecular-gastroenterology-and-hepatology/2352-345x/submitting#guidelines"
  },
  {
    id: "cellular-and-molecular-immunology",
    text: "Cellular and Molecular Immunology",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-cmi.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "cellular-and-molecular-life-sciences",
    text: "Cellular and Molecular Life Sciences",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cellular-and-molecular-neurobiology",
    text: "Cellular and Molecular Neurobiology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cellular-immunology",
    text: "Cellular Immunology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cellular-logistics",
    text: "Cellular Logistics",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cellular-microbiology",
    text: "Cellular Microbiology",
    parent: "molecular-microbiology",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291462-5822/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "cellular-oncology",
    text: "Cellular Oncology",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cellular-physiology-and-biochemistry",
    text: "Cellular Physiology and Biochemistry",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9778",
    urltwo: "NA"
  },
  {
    id: "cellular-reprogramming",
    text: "Cellular Reprogramming",
    parent: "NA",
    urlone: "http://www.liebertpub.com/manuscript/cellular-reprogramming/9/",
    urltwo: "NA"
  },
  {
    id: "cellular-signalling",
    text: "Cellular Signalling",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cellulose",
    text: "Cellulose",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cement-and-concrete-composites",
    text: "Cement and Concrete Composites",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cement-and-concrete-research",
    text: "Cement and Concrete Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cen-case-reports",
    text: "CEN Case Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "centaurus",
    text: "Centaurus",
    parent: "NA",
    urlone: "http://www.blackwellpublishing.com/pdf/centaurus_style_guide.pdf",
    urltwo: "NA"
  },
  {
    id: "central-asian-survey",
    text: "Central Asian Survey",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCAS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "central-bank-review",
    text: "Central Bank Review",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "central-european-journal-of-operations-research",
    text: "Central European Journal of Operations Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "central-european-journal-of-public-health",
    text: "Central European Journal of Public Health",
    parent: "vancouver",
    urlone: "http://apps.szu.cz/svi/cejph/show_en.php?kat=author",
    urltwo: "NA"
  },
  {
    id: "central-european-journal-of-public-policy",
    text: "Central European Journal of Public Policy",
    parent: "apa",
    urlone: "https://content.sciendo.com/view/journals/cejpp/cejpp-overview.xml",
    urltwo: "https://content.sciendo.com/supplemental/-1867938537/journals/cejpp/cejpp-overview.xml"
  },
  {
    id: "central-european-journal-of-urology",
    text: "Central European Journal of Urology",
    parent: "vancouver",
    urlone: "http://www.termedia.pl/Journal/Central_European_Journal_of_Urology-57/Dla_autorow",
    urltwo: "NA"
  },
  {
    id: "centre-de-recherche-sur-les-civilisations-de-l-asie-orientale",
    text: "Centre de recherche sur les civilisations de l'Asie orientale (French)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/1385#issuecomment-73234582",
    urltwo: "NA"
  },
  {
    id: "cephalalgia",
    text: "Cephalalgia",
    parent: "sage-vancouver",
    urlone: "http://www.uk.sagepub.com/msg/cep.htm#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "ceramics-international",
    text: "Ceramics International",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cerebellum-and-ataxias",
    text: "Cerebellum &amp; Ataxias",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cerebral-cortex",
    text: "Cerebral Cortex",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/cercor/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "cerebrovascular-diseases-extra",
    text: "Cerebrovascular Diseases Extra",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/254735",
    urltwo: "NA"
  },
  {
    id: "cerebrovascular-diseases",
    text: "Cerebrovascular Diseases",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9786",
    urltwo: "NA"
  },
  {
    id: "cerevisia",
    text: "Cerevisia",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ceska-a-slovenska-neurologie-a-neurochirurgie",
    text: "ÄŒeskÃ¡ a slovenskÃ¡ neurologie a neurochirurgie",
    parent: "vancouver-brackets-only-year-no-issue",
    urlone: "http://www.csnn.eu/en/czech-slovak-neurology-instructions",
    urltwo: "NA"
  },
  {
    id: "ceska-zemedelska-univerzita-v-praze-fakulta-agrobiologie-potravinovych-a-prirodnich-zdroju",
    text: "ÄŒeskÃ¡ zemÄ›dÄ›lskÃ¡ univerzita v Praze - Fakulta agrobiologie, potravinovÃ½ch a pÅ™Ã­rodnÃ­ch zdrojÅ¯",
    parent: "NA",
    urlone:
      "http://studium.agrobiologie.cz/Nastenka_studijniho_oddeleni/Bakalarske_a_diplomove_prace/Zavazna_pravidla_citace_seznamy_pouzite_literatury.pdf",
    urltwo: "NA"
  },
  {
    id: "cesky-financni-a-ucetni-casopis",
    text: "ÄŒeskÃ½ finanÄnÃ­ a ÃºÄetnÃ­ Äasopis (Czech)",
    parent: "iso690-author-date-cs",
    urlone: "https://www.vse.cz/cfuc/text-informace-pro-autory.php",
    urltwo: "NA"
  },
  {
    id: "challenges",
    text: "Challenges",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "changer-d-epoque",
    text: "Changer d'Ã©poque (French)",
    parent: "NA",
    urlone: "http://purh.univ-rouen.fr/taxonomy/term/2",
    urltwo: "NA"
  },
  {
    id: "changing-english",
    text: "Changing English",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCEN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "channels",
    text: "Channels",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id:
      "chaos-solitons-and-fractals-the-interdisciplinary-journal-of-nonlinear-science-and-nonequilibrium-and-complex-phenomena",
    text:
      "Chaos, Solitons and Fractals: the interdisciplinary journal of Nonlinear Science, and Nonequilibrium and Complex Phenomena",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chaos-solitons-and-fractals-x",
    text: "Chaos, Solitons &amp; Fractals: X",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chaos",
    text: "Chaos",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "check-list",
    text: "Check List",
    parent: "pensoft-journals",
    urlone: "https://checklist.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "chem-catalysis",
    text: "Chem Catalysis",
    parent: "cell-numeric-superscript",
    urlone: "http://www.cell.com/chem-catalysis/authors",
    urltwo: "NA"
  },
  {
    id: "chem",
    text: "Chem",
    parent: "cell-numeric-superscript",
    urlone: "http://www.cell.com/chem/authors",
    urltwo: "NA"
  },
  {
    id: "chemengineering",
    text: "ChemEngineering",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "chemical-and-biological-technologies-in-agriculture",
    text: "Chemical and Biological Technologies in Agriculture",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chemical-and-pharmaceutical-bulletin",
    text: "Chemical and Pharmaceutical Bulletin",
    parent: "NA",
    urlone: "http://cpb.pharm.or.jp/document/inst_e.pdf",
    urltwo: "NA"
  },
  {
    id: "chemical-biology-and-drug-design",
    text: "Chemical Biology &amp; Drug Design",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1747-0285/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "chemical-biology-letters",
    text: "Chemical Biology Letters",
    parent: "integrated-science-publishing-journals",
    urlone: "http://www.pubs.iscience.in/journal/index.php/cbl/about/submissions",
    urltwo: "NA"
  },
  {
    id: "chemical-communications",
    text: "Chemical Communications",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-data-collections",
    text: "Chemical Data Collections",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-engineering-and-processing-process-intensification",
    text: "Chemical Engineering and Processing - Process Intensification",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-engineering-journal",
    text: "Chemical Engineering Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-engineering-progress",
    text: "Chemical Engineering Progress",
    parent: "NA",
    urlone: "https://www.aiche.org/resources/publications/cep/guidelines-authors",
    urltwo: "NA"
  },
  {
    id: "chemical-engineering-research-and-design",
    text: "Chemical Engineering Research and Design",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-engineering-science-x",
    text: "Chemical Engineering Science: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-engineering-science",
    text: "Chemical Engineering Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-geology",
    text: "Chemical Geology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-physics-letters-x",
    text: "Chemical Physics Letters: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-physics-letters",
    text: "Chemical Physics Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-physics",
    text: "Chemical Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-research-in-toxicology",
    text: "Chemical Research in Toxicology",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/crtoec/crtoec_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "chemical-reviews",
    text: "Chemical Reviews",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/chreay/chreay_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "chemical-science",
    text: "Chemical Science",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemical-senses",
    text: "Chemical Senses",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/chemse/for_authors/instructions.html#9",
    urltwo: "NA"
  },
  {
    id: "chemical-society-reviews",
    text: "Chemical Society Reviews",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemico-biological-interactions",
    text: "Chemico-Biological Interactions",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemie-der-erde",
    text: "Chemie der Erde",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemie-ingenieur-technik",
    text: "Chemie Ingenieur Technik",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1522-2640/homepage/2004_forauthors.html",
    urltwo: "NA"
  },
  {
    id: "chemistry-and-ecology",
    text: "Chemistry and Ecology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GCHE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "chemistry-and-physics-of-lipids",
    text: "Chemistry and Physics of Lipids",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemistry-central-journal",
    text: "Chemistry Central Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chemistry-education-research-and-practice",
    text: "Chemistry Education Research and Practice",
    parent: "NA",
    urlone:
      "http://www.rsc.org/journals-books-databases/about-journals/chemistry-education-research-practice/#journal-specific-guidelines",
    urltwo: "NA"
  },
  {
    id: "chemistry-letters",
    text: "Chemistry Letters",
    parent: "the-chemical-society-of-japan",
    urlone: "http://www.csj.jp/journals/styles/ref.html",
    urltwo: "NA"
  },
  {
    id: "chemistry-of-materials",
    text: "Chemistry of Materials",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/cmatex/cmatex_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "chemistry-world",
    text: "Chemistry World",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemoecology",
    text: "Chemoecology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chemometrics-and-intelligent-laboratory-systems",
    text: "Chemometrics and Intelligent Laboratory Systems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemosensors",
    text: "Chemosensors",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "chemosensory-perception",
    text: "Chemosensory Perception",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chemosphere",
    text: "Chemosphere",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chemotherapy",
    text: "Chemotherapy",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9794",
    urltwo: "NA"
  },
  {
    id: "chemtexts",
    text: "ChemTexts",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chest",
    text: "Chest",
    parent: "NA",
    urlone: "http://journal.publications.chestnet.org/ss/forauthors.aspx#References",
    urltwo: "NA"
  },
  {
    id: "chicago-annotated-bibliography",
    text: "Chicago Manual of Style 17th edition (note, annotated bibliography)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-author-date-16th-edition",
    text: "Chicago Manual of Style 16th edition (author-date)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-author-date-basque",
    text: "Chicago Manual of Style 16th edition (author-date, Basque)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chicago-author-date-de",
    text: "Chicago Manual of Style 16th edition (author-date, German)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-author-date-fr",
    text: "Chicago Manual of Style 17th edition (author-date), French",
    parent: "NA",
    urlone: "http://guides.bib.umontreal.ca/disciplines/483-Citer-selon-le-style-Chicago?tab=2189",
    urltwo: "NA"
  },
  {
    id: "chicago-author-date",
    text: "Chicago Manual of Style 17th edition (author-date)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-figures",
    text: "Chicago Manual of Style 16th edition (figures and illustrations)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/442",
    urltwo: "NA"
  },
  {
    id: "chicago-fullnote-bibliography-16th-edition",
    text: "Chicago Manual of Style 16th edition (full note)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-fullnote-bibliography-fr",
    text: "Chicago Manual of Style 17th edition (full note, French)",
    parent: "NA",
    urlone: "http://guides.bib.umontreal.ca/disciplines/483-Citer-selon-le-style-Chicago?tab=2191",
    urltwo: "NA"
  },
  {
    id: "chicago-fullnote-bibliography-short-title-subsequent",
    text: "Chicago Manual of Style 17th edition (full note, short title subsequent)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-fullnote-bibliography-with-ibid",
    text: "Chicago Manual of Style 17th edition (full note, with Ibid.)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-fullnote-bibliography",
    text: "Chicago Manual of Style 17th edition (full note)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-library-list",
    text: "Chicago Manual of Style 17th edition (library list)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-note-bibliography-16th-edition",
    text: "Chicago Manual of Style 16th edition (note)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-note-bibliography-with-ibid",
    text: "Chicago Manual of Style 17th edition (note, with Ibid.)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "chicago-note-bibliography",
    text: "Chicago Manual of Style 17th edition (note)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "child-abuse-and-neglect",
    text: "Child Abuse &amp; Neglect",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "child-and-adolescent-psychiatry-and-mental-health",
    text: "Child and Adolescent Psychiatry and Mental Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "child-and-adolescent-social-work-journal",
    text: "Child and Adolescent Social Work Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "child-and-youth-care-forum",
    text: "Child &amp; Youth Care Forum",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "child-indicators-research",
    text: "Child Indicators Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "child-neuropsychology",
    text: "Child Neuropsychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NCNY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "child-psychiatry-and-human-development",
    text: "Child Psychiatry &amp; Human Development",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "children-and-youth-services-review",
    text: "Children and Youth Services Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "children",
    text: "Children",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "childrens-geographies",
    text: "Children's Geographies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCHG20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "childs-nervous-system",
    text: "Child's Nervous System",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chimerism",
    text: "Chimerism",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chimia",
    text: "CHIMIA",
    parent: "NA",
    urlone:
      "https://chimia.ch/index.php?option=com_content&amp;view=category&amp;layout=blog&amp;id=109&amp;Itemid=489&amp;lang=en",
    urltwo: "NA"
  },
  {
    id: "china-communications",
    text: "China Communications",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6245522",
    urltwo: "NA"
  },
  {
    id: "china-economic-journal",
    text: "China Economic Journal",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCEJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "china-economic-review",
    text: "China Economic Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "china-eu-law-journal",
    text: "China-EU Law Journal",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "china-finance-and-economic-review",
    text: "China Finance and Economic Review",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "china-geology",
    text: "China Geology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "china-information",
    text: "China Information",
    parent: "NA",
    urlone: "https://uk.sagepub.com/en-gb/eur/journal/china-information#JournalStyle",
    urltwo: "NA"
  },
  {
    id: "china-journal-of-accounting-research",
    text: "China Journal of Accounting Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "china-journal-of-accounting-studies",
    text: "China Journal of Accounting Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCJA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "china-journal-of-economic-research",
    text: "China Journal of Economic Research",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "china-journal-of-social-work",
    text: "China Journal of Social Work",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCSW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "china-national-standard-gb-t-7714-2015-author-date",
    text: "China National Standard GB/T 7714-2015 (author-date, Chinese)",
    parent: "NA",
    urlone: "http://www.std.gov.cn/gb/search/gbDetailed?id=71F772D8055ED3A7E05397BE0A0AB82A",
    urltwo: "NA"
  },
  {
    id: "china-national-standard-gb-t-7714-2015-note",
    text: "China National Standard GB/T 7714-2015 (note, Chinese)",
    parent: "NA",
    urlone: "http://www.std.gov.cn/gb/search/gbDetailed?id=71F772D8055ED3A7E05397BE0A0AB82A",
    urltwo: "NA"
  },
  {
    id: "china-national-standard-gb-t-7714-2015-numeric",
    text: "China National Standard GB/T 7714-2015 (numeric, Chinese)",
    parent: "NA",
    urlone: "http://www.std.gov.cn/gb/search/gbDetailed?id=71F772D8055ED3A7E05397BE0A0AB82A",
    urltwo: "NA"
  },
  {
    id: "chinese-chemical-letters",
    text: "Chinese Chemical Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chinese-gb7714-1987-numeric",
    text: "China National Standard GB/T 7714-1987 (numeric, Chinese)",
    parent: "NA",
    urlone: "http://www.lib.tsinghua.edu.cn/service/GB7714-87.doc",
    urltwo: "NA"
  },
  {
    id: "chinese-gb7714-2005-author-date",
    text: "China National Standard GB/T 7714-2005 (author-date, Chinese)",
    parent: "NA",
    urlone: "http://gradschool.ustc.edu.cn/ylb/material/xw/wdxz/19.pdf",
    urltwo: "NA"
  },
  {
    id: "chinese-gb7714-2005-numeric",
    text: "China National Standard GB/T 7714-2005 (numeric, Chinese)",
    parent: "NA",
    urlone: "http://gradschool.ustc.edu.cn/ylb/material/xw/wdxz/19.pdf",
    urltwo: "NA"
  },
  {
    id: "chinese-herbal-medicines",
    text: "Chinese Herbal Medicines",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chinese-journal-of-aeronautics",
    text: "Chinese Journal of Aeronautics",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/chinese-journal-of-aeronautics/1000-9361/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "chinese-journal-of-cancer",
    text: "Chinese Journal of Cancer",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chinese-journal-of-chemical-engineering",
    text: "Chinese Journal of Chemical Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chinese-journal-of-communication",
    text: "Chinese Journal of Communication",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCJC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "chinese-journal-of-physics",
    text: "Chinese Journal of Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chinese-journal-of-population-resources-and-environment",
    text: "Chinese Journal of Population Resources and Environment",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TPRE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "chinese-journal-of-traumatology",
    text: "Chinese Journal of Traumatology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chinese-medical-journal",
    text: "Chinese Medical Journal",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/cmj/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "chinese-medicine",
    text: "Chinese Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chinese-neurosurgical-journal",
    text: "Chinese Neurosurgical Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chinese-nursing-research",
    text: "Chinese Nursing Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chinese-physics-b",
    text: "Chinese Physics B",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1674-1056",
    urltwo: "NA"
  },
  {
    id: "chinese-physics-c",
    text: "Chinese Physics C",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1674-1137",
    urltwo: "NA"
  },
  {
    id: "chinese-physics-letters",
    text: "Chinese Physics Letters",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0256-307X",
    urltwo: "NA"
  },
  {
    id: "chinese-political-science-review",
    text: "Chinese Political Science Review",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chiropractic-and-manual-therapies",
    text: "Chiropractic &amp; Manual Therapies",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chiropractic-journal-of-australia",
    text: "Chiropractic Journal of Australia",
    parent: "vancouver-superscript",
    urlone: "http://cjaonline.realviewdigital.com/",
    urltwo: "NA"
  },
  {
    id: "chromatographia",
    text: "Chromatographia",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chromatography",
    text: "Chromatography",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "chromosoma",
    text: "Chromosoma",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chromosome-research",
    text: "Chromosome Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "chronic-diseases-and-injuries-in-canada",
    text: "Chronic Diseases and Diseases in Canada",
    parent: "vancouver-superscript",
    urlone: "http://www.phac-aspc.gc.ca/publicat/cdic-mcbc/authinfo-eng.php",
    urltwo: "NA"
  },
  {
    id: "chronic-diseases-and-translational-medicine",
    text: "Chronic Diseases and Translational Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "chroniques-des-activites-archeologiques-de-l-ecole-francaise-de-rome",
    text: "Chroniques des activitÃ©s archÃ©ologiques de l'Ecole franÃ§aise de Rome",
    parent: "NA",
    urlone: "http://cefr.revues.org/216",
    urltwo: "NA"
  },
  {
    id: "chungara-revista-de-antropologia-chilena",
    text: "Chungara Revista de AntropologÃ­a Chilena (Spanish - Chile)",
    parent: "NA",
    urlone: "http://chungara.cl/index.php/es/instrucciones-para-autores",
    urltwo: "NA"
  },
  {
    id: "cilia",
    text: "Cilia",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "circuits-systems-and-signal-processing",
    text: "Circuits, Systems, and Signal Processing",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "circulation-journal",
    text: "Circulation Journal",
    parent: "NA",
    urlone: "http://www.j-circ.or.jp/english/cj/index.php/ita/",
    urltwo: "NA"
  },
  {
    id: "circulation",
    text: "Circulation",
    parent: "NA",
    urlone: "http://circ.ahajournals.org/site/misc/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "cirugia-cardiovascular",
    text: "Cirugia Cardiovascular (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/cirugia-cardiovascular/1134-0096/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "cirugia-espanola",
    text: "CirugÃ­a EspaÃ±ola (Spanish)",
    parent: "medicina-clinica",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cirugia-y-cirujanos",
    text: "CirugÃ­a y Cirujanos",
    parent: "vancouver-superscript",
    urlone: "http://www.nietoeditores.com.mx/cirugia-y-cirujanos.html",
    urltwo: "NA"
  },
  {
    id: "cirujano-general",
    text: "Cirujano General",
    parent: "vancouver-superscript",
    urlone: "http://www.medigraphic.com/pdfs/cirgen/cg-instr.pdf",
    urltwo: "NA"
  },
  {
    id: "citation-compass-apa-note",
    text: "Citation Compass (Kildekompasset) - APA (note)",
    parent: "NA",
    urlone: "http://kildekompasset.no/references/apa-6th-for-footnotes.aspx",
    urltwo: "NA"
  },
  {
    id: "cities",
    text: "Cities",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "citizen-science-theory-and-practice",
    text: "Citizen Science: Theory and Practice",
    parent: "NA",
    urlone: "http://theoryandpractice.citizenscienceassociation.org/about/submissions/#References",
    urltwo: "NA"
  },
  {
    id: "citizenship-studies",
    text: "Citizenship Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCST20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "city-and-climate-interactions",
    text: "City and Climate Interactions",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "city-and-community",
    text: "City &amp; Community",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/cc/cc.cfm",
    urltwo: "NA"
  },
  {
    id: "city-culture-and-society",
    text: "City, Culture and Society",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "city-territory-and-architecture",
    text: "City, Territory and Architecture",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "city",
    text: "City",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCIT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "civil-engineering-and-environmental-systems",
    text: "Civil Engineering and Environmental Systems",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GCEE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "civilta-italiana",
    text: "CiviltÃ  Italiana (Italian)",
    parent: "NA",
    urlone: "http://www.infoaipi.org/norm_redaz_civ_it.pdf",
    urltwo: "NA"
  },
  {
    id: "civitas-revista-de-ciencias-sociais",
    text: "Civitas: Revista de CiÃªncias Sociais",
    parent: "NA",
    urlone: "http://revistaseletronicas.pucrs.br/ojs/index.php/civitas/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "cjc-open",
    text: "CJC Open",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cladistics",
    text: "Cladistics",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291096-0031/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "clara-architecture-recherche",
    text: "CLARA Architecture/Recherche (French)",
    parent: "NA",
    urlone: "http://clararevue.ulb.be/OJS/index.php/CLARA",
    urltwo: "NA"
  },
  {
    id: "classical-and-quantum-gravity",
    text: "Classical and Quantum Gravity",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0264-9381",
    urltwo: "NA"
  },
  {
    id: "classroom-discourse",
    text: "Classroom Discourse",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCDI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "clay-minerals",
    text: "Clay Minerals",
    parent: "NA",
    urlone: "http://www.minersoc.org/submit-cm.html",
    urltwo: "NA"
  },
  {
    id: "clays-and-clay-minerals",
    text: "Clays and Clay Minerals",
    parent: "NA",
    urlone: "http://www.clays.org/JOURNAL/CCMjournsubm.html",
    urltwo: "NA"
  },
  {
    id: "clean-technologies-and-environmental-policy",
    text: "Clean Technologies and Environmental Policy",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "climacteric",
    text: "Climacteric",
    parent: "vancouver-superscript",
    urlone: "http://informahealthcare.com/page/cmt/Description#Instructions",
    urltwo: "NA"
  },
  {
    id: "climate-change-economics",
    text: "Climate Change Economics",
    parent: "NA",
    urlone: "http://www.worldscientific.com/page/cce/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "climate-change-responses",
    text: "Climate Change Responses",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "climate-dynamics",
    text: "Climate Dynamics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "climate-of-the-past",
    text: "Climate of the Past",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "climate-policy",
    text: "Climate Policy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TCPO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "climate-research",
    text: "Climate Research",
    parent: "inter-research-science-center",
    urlone: "http://www.int-res.com/journals/cr/cr-home/",
    urltwo: "NA"
  },
  {
    id: "climate-risk-management",
    text: "Climate Risk Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "climate-services",
    text: "Climate Services",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "climate",
    text: "Climate",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "climatic-change",
    text: "Climatic Change",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinica-chimica-acta",
    text: "Clinica Chimica Acta",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinica-e-investigacion-en-arteriosclerosis",
    text: "ClÃ­nica e InvestigaciÃ³n en Arteriosclerosis (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/clinica-e-investigacion-en-arteriosclerosis/0214-9168/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "clinica-y-salud",
    text: "ClÃ­nica y Salud",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-anatomy",
    text: "Clinical Anatomy",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1098-2353/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "clinical-and-experimental-medicine",
    text: "Clinical and Experimental Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-and-experimental-metastasis",
    text: "Clinical &amp; Experimental Metastasis",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-and-experimental-nephrology",
    text: "Clinical and Experimental Nephrology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-and-experimental-ophthalmology",
    text: "Clinical &amp; Experimental Ophthalmology",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1442-9071/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "clinical-and-experimental-optometry",
    text: "Clinical and Experimental Optometry",
    parent: "sage-vancouver",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14440938/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "clinical-and-experimental-pharmacology-and-physiology",
    text: "Clinical and Experimental Pharmacology and Physiology",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1440-1681/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "clinical-and-investigative-medicine",
    text: "Clinical and Investigative Medicine",
    parent: "vancouver-brackets",
    urlone: "http://cimonline.ca/index.php/cim/information/authors",
    urltwo: "NA"
  },
  {
    id: "clinical-and-molecular-allergy",
    text: "Clinical and Molecular Allergy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-and-translational-allergy",
    text: "Clinical and Translational Allergy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-and-translational-gastroenterology",
    text: "Clinical and Translational Gastroenterology",
    parent: "american-medical-association",
    urlone: "http://www.nature.com/ctg/ctg_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "clinical-and-translational-imaging",
    text: "Clinical and Translational Imaging",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-and-translational-immunology",
    text: "Clinical &amp; Translational Immunology",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-cti.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#fop",
    urltwo: "NA"
  },
  {
    id: "clinical-and-translational-medicine",
    text: "Clinical and Translational Medicine",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-and-translational-oncology",
    text: "Clinical and Translational Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-and-translational-radiation-oncology",
    text: "Clinical and Translational Radiation Oncology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-and-vaccine-immunology",
    text: "Clinical and Vaccine Immunology",
    parent: "american-society-for-microbiology",
    urlone: "http://cvi.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "clinical-autonomic-research",
    text: "Clinical Autonomic Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-biochemistry",
    text: "Clinical Biochemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-biomechanics",
    text: "Clinical Biomechanics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-breast-cancer",
    text: "Clinical Breast Cancer",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-cancer-research",
    text: "Clinical Cancer Research",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "clinical-chemistry",
    text: "Clinical Chemistry",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.clinchem.org/site/info_ar/info_authors.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "clinical-child-and-family-psychology-review",
    text: "Clinical Child and Family Psychology Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-colorectal-cancer",
    text: "Clinical Colorectal Cancer",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-diabetes-and-endocrinology",
    text: "Clinical Diabetes and Endocrinology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-drug-investigation",
    text: "Clinical Drug Investigation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-dysmorphology",
    text: "Clinical Dysmorphology",
    parent: "NA",
    urlone: "http://journals.lww.com/clindysmorphol/_layouts/15/1033/oaks.journals/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "clinical-ehealth",
    text: "Clinical eHealth",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-epidemiology-and-global-health",
    text: "Clinical Epidemiology and Global Health",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-epigenetics",
    text: "Clinical Epigenetics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-gastroenterology-and-hepatology",
    text: "Clinical Gastroenterology and Hepatology",
    parent: "NA",
    urlone: "http://www.cghjournal.org/content/authorinfo",
    urltwo: "NA"
  },
  {
    id: "clinical-genitourinary-cancer",
    text: "Clinical Genitourinary Cancer",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-hemorheology-and-microcirculation",
    text: "Clinical Hemorheology and Microcirculation",
    parent: "NA",
    urlone: "http://www.iospress.nl/journal/clinical-hemorheology-and-microcirculation/",
    urltwo: "NA"
  },
  {
    id: "clinical-hypertension",
    text: "Clinical Hypertension",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-imaging",
    text: "Clinical Imaging",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-immunology",
    text: "Clinical Immunology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-infectious-diseases",
    text: "Clinical Infectious Diseases",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/cid/for_authors/ms_prep.html#Article%20Types",
    urltwo: "NA"
  },
  {
    id: "clinical-investigation",
    text: "Clinical Investigation",
    parent: "future-science-group",
    urlone: "http://www.future-science.com/loi/cli",
    urltwo: "NA"
  },
  {
    id: "clinical-journal-of-gastroenterology",
    text: "Clinical Journal of Gastroenterology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-journal-of-sport-medicine",
    text: "Clinical Journal of Sport Medicine",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/cjsm/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "clinical-journal-of-the-american-society-of-nephrology",
    text: "Clinical Journal of the American Society of Nephrology",
    parent: "NA",
    urlone: "https://cjasn.asnjournals.org/content/authors/ifora",
    urltwo: "NA"
  },
  {
    id: "clinical-laboratory",
    text: "Clinical Laboratory",
    parent: "vancouver-brackets",
    urlone: "http://www.clin-lab-publications.com/author-guide.html",
    urltwo: "NA"
  },
  {
    id: "clinical-lipidology",
    text: "Clinical Lipidology",
    parent: "future-science-group",
    urlone: "http://www.futuremedicine.com/loi/clp",
    urltwo: "NA"
  },
  {
    id: "clinical-lung-cancer",
    text: "Clinical Lung Cancer",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-lymphoma-myeloma-and-leukemia",
    text: "Clinical Lymphoma, Myeloma and Leukemia",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-management-issues",
    text: "Clinical Management Issues",
    parent: "NA",
    urlone: "https://journals.seedmedicalpublishers.com/index.php/cmi/about/submissions",
    urltwo: "NA"
  },
  {
    id: "clinical-mass-spectrometry",
    text: "Clinical Mass Spectrometry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-medicine",
    text: "Clinical Medicine",
    parent: "vancouver-superscript",
    urlone: "http://www.rcplondon.ac.uk/resources/clinical-medicine-journal/contributions-clinical-medical",
    urltwo: "NA"
  },
  {
    id: "clinical-microbiology-and-infection",
    text: "Clinical Microbiology and Infection",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-microbiology-newsletter",
    text: "Clinical Microbiology Newsletter",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-microbiology-reviews",
    text: "Clinical Microbiology Reviews",
    parent: "american-society-for-microbiology",
    urlone: "http://cmr.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "clinical-neurology-and-neurosurgery",
    text: "Clinical Neurology and Neurosurgery",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-neurophysiology-practice",
    text: "Clinical Neurophysiology Practice",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-neurophysiology",
    text: "Clinical Neurophysiology",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-neuroradiology",
    text: "Clinical Neuroradiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-nuclear-medicine",
    text: "Clinical Nuclear Medicine",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/cnm/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "clinical-nutrition-espen",
    text: "Clinical Nutrition ESPEN",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-nutrition-experimental",
    text: "Clinical Nutrition Experimental",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-nutrition-supplements",
    text: "Clinical Nutrition Supplements",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-nutrition",
    text: "Clinical Nutrition",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-oncology",
    text: "Clinical Oncology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-oral-implants-research",
    text: "Clinical Oral Implants Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291600-0501/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "clinical-oral-investigations",
    text: "Clinical Oral Investigations",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-orthopaedics-and-related-research",
    text: "Clinical Orthopaedics and Related Research",
    parent: "NA",
    urlone: "http://www.springer.com/medicine/orthopedics/journal/11999",
    urltwo: "NA"
  },
  {
    id: "clinical-otolaryngology",
    text: "Clinical Otolaryngology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291749-4486/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "clinical-ovarian-and-other-gynecologic-cancer",
    text: "Clinical Ovarian and Other Gynecologic Cancer",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-parkinsonism-and-related-disorders",
    text: "Clinical Parkinsonism &amp; Related Disorders",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-pediatrics",
    text: "Clinical Pediatrics",
    parent: "sage-vancouver",
    urlone: "http://cpj.sagepub.com/",
    urltwo: "NA"
  },
  {
    id: "clinical-pharmacokinetics",
    text: "Clinical Pharmacokinetics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-pharmacology-and-therapeutics",
    text: "Clinical Pharmacology &amp; Therapeutics",
    parent: "NA",
    urlone: "http://www.nature.com/clpt/clptguidetoauthors.pdf",
    urltwo: "NA"
  },
  {
    id: "clinical-physiology-and-functional-imaging",
    text: "Clinical Physiology and Functional Imaging",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/1475097x/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "clinical-phytoscience",
    text: "Clinical Phytoscience",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-plasma-medicine",
    text: "Clinical Plasma Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-practice-in-pediatric-psychology",
    text: "Clinical Practice in Pediatric Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cpp",
    urltwo: "NA"
  },
  {
    id: "clinical-practice",
    text: "Clinical Practice",
    parent: "future-science-group",
    urlone: "http://www.futuremedicine.com/loi/cpr",
    urltwo: "NA"
  },
  {
    id: "clinical-proteomics",
    text: "Clinical Proteomics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-psychology-review",
    text: "Clinical Psychology Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-queries-nephrology",
    text: "Clinical Queries: Nephrology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-radiology",
    text: "Clinical Radiology",
    parent: "NA",
    urlone: "https://www.rcr.ac.uk/sites/default/files/instructions_authors_cr.pdf",
    urltwo: "NA"
  },
  {
    id: "clinical-research-in-cardiology-supplements",
    text: "Clinical Research in Cardiology Supplements",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-research-in-cardiology",
    text: "Clinical Research in Cardiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-reviews-in-allergy-and-immunology",
    text: "Clinical Reviews in Allergy &amp; Immunology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-reviews-in-bone-and-mineral-metabolism",
    text: "Clinical Reviews in Bone and Mineral Metabolism",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-rheumatology",
    text: "Clinical Rheumatology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-sarcoma-research",
    text: "Clinical Sarcoma Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-simulation-in-nursing",
    text: "Clinical Simulation in Nursing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-skin-cancer",
    text: "Clinical Skin Cancer",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-social-work-journal",
    text: "Clinical Social Work Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "clinical-spine-surgery",
    text: "Clinical Spine Surgery",
    parent: "NA",
    urlone: "http://journals.lww.com/jspinaldisorders/Pages/Information-for-Authors.aspx",
    urltwo: "NA"
  },
  {
    id: "clinical-therapeutics",
    text: "Clinical Therapeutics",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinical-trials-and-regulatory-science-in-cardiology",
    text: "Clinical Trials and Regulatory Science in Cardiology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinicians-research-digest",
    text: "Clinician's Research Digest",
    parent: "apa",
    urlone: "http://www.apa.org/pubs/journals/crd/index.aspx",
    urltwo: "NA"
  },
  {
    id: "clinics-and-research-in-hepatology-and-gastroenterology-x",
    text: "Clinics and Research in Hepatology and Gastroenterology: X",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinics-and-research-in-hepatology-and-gastroenterology",
    text: "Clinics and Research in Hepatology and Gastroenterology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clinics-in-dermatology",
    text: "Clinics in Dermatology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "clio-medica",
    text: "Clio Medica",
    parent: "NA",
    urlone: "http://www.ucl.ac.uk/histmed/downloads/clio_style_sheet.pdf",
    urltwo: "NA"
  },
  {
    id: "cliometrica",
    text: "Cliometrica",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cluster-computing",
    text: "Cluster Computing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cmaj",
    text: "CMAJ (Canadian Medical Association Journal)",
    parent: "vancouver",
    urlone: "http://www.cmaj.ca/site/authors/preparing.xhtml",
    urltwo: "NA"
  },
  {
    id: "cns-and-neurological-disorders-drug-targets",
    text: "CNS &amp; Neurological Disorders - Drug Targets",
    parent: "NA",
    urlone: "http://www.benthamscience.com/cdtcnsnd/MSandI.htm",
    urltwo: "NA"
  },
  {
    id: "cns-drugs",
    text: "CNS Drugs",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cns-oncology",
    text: "CNS Oncology",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/cns",
    urltwo: "NA"
  },
  {
    id: "coaching-an-international-journal-of-theory-research-and-practice",
    text: "Coaching: An International Journal of Theory, Research and Practice",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCOA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "coaching-theorie-and-praxis",
    text: "Coaching | Theorie &amp; Praxis (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "coastal-engineering",
    text: "Coastal Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "coatings",
    text: "Coatings",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "codesign",
    text: "CoDesign",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NCDN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "coevolution",
    text: "Coevolution",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TCEV20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "cognition-and-emotion",
    text: "Cognition &amp; Emotion",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PCEM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "cognition-technology-and-work",
    text: "Cognition, Technology &amp; Work",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cognition",
    text: "Cognition",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cognitive-and-behavioral-practice",
    text: "Cognitive and Behavioral Practice",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cognitive-computation",
    text: "Cognitive Computation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cognitive-development",
    text: "Cognitive Development",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cognitive-neurodynamics",
    text: "Cognitive Neurodynamics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cognitive-neuropsychiatry",
    text: "Cognitive Neuropsychiatry",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PCNP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "cognitive-neuropsychology",
    text: "Cognitive Neuropsychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PCGN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "cognitive-neuroscience",
    text: "Cognitive Neuroscience",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PCNS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "cognitive-processing",
    text: "Cognitive Processing",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cognitive-psychology",
    text: "Cognitive Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cognitive-research",
    text: "Cognitive Research",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cognitive-systems-research",
    text: "Cognitive Systems Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cognitive-therapy-and-research",
    text: "Cognitive Therapy and Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cold-regions-science-and-technology",
    text: "Cold Regions Science and Technology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cold-spring-harbor-laboratory-press",
    text: "Cold Spring Harbor Laboratory Press",
    parent: "NA",
    urlone: "http://genome.cshlp.org/site/misc/ifora_mspreparation.xhtml",
    urltwo: "NA"
  },
  {
    id: "collectanea-mathematica",
    text: "Collectanea Mathematica",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "collection-de-l-ecole-francaise-de-rome-full-note",
    text: "Collection de l'Ecole franÃ§aise de Rome (full note, French)",
    parent: "NA",
    urlone: "http://www.publications.efrome.it/opencms/export/sites/efrome/documenti/norme_fr.pdf",
    urltwo: "NA"
  },
  {
    id: "collection-de-l-ecole-francaise-de-rome-note",
    text: "Collection de l'Ecole franÃ§aise de Rome (note, French)",
    parent: "NA",
    urlone: "http://www.publications.efrome.it/opencms/export/sites/efrome/documenti/norme_fr.pdf",
    urltwo: "NA"
  },
  {
    id: "collection-du-centre-jean-berard",
    text: "Collection du Centre Jean-BÃ©rard (French)",
    parent: "NA",
    urlone: "http://www.centre-jean-berard.cnrs.fr/",
    urltwo: "NA"
  },
  {
    id: "collections-electroniques-de-l-inha-author-date",
    text: "Collections Ã©lectroniques de l'INHA (author-date, French)",
    parent: "NA",
    urlone: "http://inha.revues.org/2662",
    urltwo: "NA"
  },
  {
    id: "collections-electroniques-de-l-inha-full-note",
    text: "Collections Ã©lectroniques de l'INHA (full note, French)",
    parent: "NA",
    urlone: "http://inha.revues.org/2662",
    urltwo: "NA"
  },
  {
    id: "college-montmorency",
    text: "CollÃ¨ge Montmorency (note, French - Canada)",
    parent: "NA",
    urlone: "http://biblio.cmontmorency.qc.ca/tutoriels/sinformer_citer.dot",
    urltwo: "NA"
  },
  {
    id: "college-of-naturopathic-medicine",
    text: "College of Naturopathic Medicine",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/3815#issuecomment-445793526",
    urltwo: "NA"
  },
  {
    id: "collegian",
    text: "Collegian",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "colloid-and-interface-science-communications",
    text: "Colloid and Interface Science Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "colloid-and-polymer-science",
    text: "Colloid and Polymer Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "colloids-and-interfaces",
    text: "Colloids and Interfaces",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "colloids-and-surfaces-a-physicochemical-and-engineering-aspects",
    text: "Colloids and Surfaces A: Physicochemical and Engineering Aspects",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "colloids-and-surfaces-b-biointerfaces",
    text: "Colloids and Surfaces B: Biointerfaces",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "colombia-medica",
    text: "Colombia MÃ©dica",
    parent: "vancouver",
    urlone: "http://colombiamedica.univalle.edu.co/index.php/comedica/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "colombian-journal-of-anesthesiology",
    text: "Colombian Journal of Anesthesiology",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/colombian-journal-of-anesthesiology/2256-2087/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "coloproctology",
    text: "coloproctology (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/surgery/journal/53?detailsPage=pltci_1487009",
    urltwo: "NA"
  },
  {
    id: "colorado-state-university-school-of-biomedical-engineering",
    text: "Colorado State University - School of Biomedical Engineering",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "colorectal-cancer",
    text: "Colorectal Cancer",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/crc",
    urltwo: "NA"
  },
  {
    id: "combinatorial-chemistry-an-online-journal",
    text: "Combinatorial Chemistry - an Online journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "combinatorial-chemistry-and-high-throughput-screening",
    text: "Combinatorial Chemistry &amp; High Throughput Screening",
    parent: "current-proteomics",
    urlone: "http://benthamscience.com/journal/authors-guidelines.php?journalID=cchts",
    urltwo: "http://pubs.acs.org/doi/pdf/10.1021/bk-2006-STYG.ch014"
  },
  {
    id: "comision-economica-para-america-latina-y-el-caribe",
    text: "ComisiÃ³n EconÃ³mica para AmÃ©rica Latina y el Caribe (Spanish)",
    parent: "NA",
    urlone: "http://www.eclac.org",
    urltwo: "NA"
  },
  {
    id: "common-market-law-review",
    text: "Common Market Law Review",
    parent: "NA",
    urlone: "https://kluwerlawonline.com/Journals/Common+Market+Law+Review/2",
    urltwo:
      "http://wolterskluwerblogs.com/authors/wp-content/uploads/sites/60/2020/03/Author-Guidelines-Journals_COLA_HOUSERULES2019.pdf"
  },
  {
    id: "communication-education",
    text: "Communication Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "communication-et-langages",
    text: "Communication et Langages",
    parent: "NA",
    urlone: "http://www.necplus.eu/action/displayJournal?jid=CML",
    urltwo: "NA"
  },
  {
    id: "communication-teacher",
    text: "Communication Teacher",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCMT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "communications-in-mathematical-physics",
    text: "Communications in Mathematical Physics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "communications-in-mathematics-and-statistics",
    text: "Communications in Mathematics and Statistics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "communications-in-nonlinear-science-and-numerical-simulation",
    text: "Communications in Nonlinear Science and Numerical Simulation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "communications-in-theoretical-physics",
    text: "Communications in Theoretical Physics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0253-6102",
    urltwo: "NA"
  },
  {
    id: "communicative-and-integrative-biology",
    text: "Communicative &amp; Integrative Biology",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "communist-and-post-communist-studies",
    text: "Communist and Post-Communist Studies",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "community-dentistry-and-oral-epidemiology",
    text: "Community Dentistry and Oral Epidemiology",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1600-0528/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "community-mental-health-journal",
    text: "Community Mental Health Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "community-work-and-family",
    text: "Community, Work &amp; Family",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCWF20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "company-and-securities-law-journal",
    text: "Company and Securities Law Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comparative-biochemistry-and-physiology-part-a",
    text: "Comparative Biochemistry and Physiology, Part A",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comparative-biochemistry-and-physiology-part-b",
    text: "Comparative Biochemistry and Physiology, Part B",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comparative-biochemistry-and-physiology-part-c",
    text: "Comparative Biochemistry and Physiology, Part C",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comparative-biochemistry-and-physiology-part-d-genomics-and-proteomics",
    text: "Comparative Biochemistry and Physiology - Part D: Genomics and Proteomics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comparative-clinical-pathology",
    text: "Comparative Clinical Pathology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "comparative-cytogenetics",
    text: "Comparative Cytogenetics",
    parent: "pensoft-journals",
    urlone: "https://compcytogen.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "comparative-education",
    text: "Comparative Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "comparative-immunology-microbiology-and-infectious-diseases",
    text: "Comparative Immunology, Microbiology and Infectious Diseases",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comparative-medicine",
    text: "Comparative Medicine",
    parent: "journal-of-the-american-association-of-laboratory-animal-science",
    urlone: "http://aalas.publisher.ingentaconnect.com/content/aalas/cm",
    urltwo: "NA"
  },
  {
    id: "comparative-migration-studies",
    text: "Comparative Migration Studies",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "comparative-oriental-manuscript-studies-bulletin",
    text: "Comparative Oriental Manuscript Studies Bulletin",
    parent: "hiob-ludolf-centre-for-ethiopian-studies",
    urlone: "http://www1.uni-hamburg.de/COMST/bulletin.html",
    urltwo: "NA"
  },
  {
    id: "comparative-politics",
    text: "Comparative Politics",
    parent: "NA",
    urlone: "http://jcp.gc.cuny.edu/submission/style-sheet/",
    urltwo: "NA"
  },
  {
    id: "comparative-population-studies",
    text: "Comparative Population Studies",
    parent: "NA",
    urlone: "http://www.comparativepopulationstudies.de/index.php/CPoS/pages/view/AGL_E",
    urltwo: "NA"
  },
  {
    id: "compare-a-journal-of-comparative-and-international-education",
    text: "Compare: A Journal of Comparative and International Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCOM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "complementary-therapies-in-clinical-practice",
    text: "Complementary Therapies in Clinical Practice",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "complementary-therapies-in-medicine",
    text: "Complementary Therapies in Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "complex-adaptive-systems-modeling",
    text: "Complex Adaptive Systems Modeling",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "complex-analysis-and-its-synergies",
    text: "Complex Analysis and its Synergies",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "complex-analysis-and-operator-theory",
    text: "Complex Analysis and Operator Theory",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "complex-and-intelligent-systems",
    text: "Complex &amp; Intelligent Systems",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "complex-variables-and-elliptic-equations",
    text: "Complex Variables and Elliptic Equations",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GCOV20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "composite-interfaces",
    text: "Composite Interfaces",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TCOI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "composite-structures",
    text: "Composite Structures",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "composites-communications",
    text: "Composites Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "composites-mechanics-computations-applications-an-international-journal",
    text: "Composites: Mechanics, Computations, Applications: An International Journal",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/36ff4a142dec9609.html",
    urltwo: "NA"
  },
  {
    id: "composites-part-a",
    text: "Composites Part A",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "composites-part-b",
    text: "Composites Part B",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "composites-science-and-technology",
    text: "Composites Science and Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comprehensive-psychiatry",
    text: "Comprehensive Psychiatry",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comptes-rendus-biologies",
    text: "Comptes rendus - Biologies",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comptes-rendus-geoscience",
    text: "Comptes rendus - Geoscience",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comptes-rendus-mathematique",
    text: "Comptes rendus - MathÃ©matique",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comptes-rendus-mecanique",
    text: "Comptes rendus - MÃ©canique",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comptes-rendus-palevol",
    text: "Comptes rendus - Palevol",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "comptes-rendus-physique",
    text: "Comptes rendus - Physique",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computation",
    text: "Computation",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "computational-and-applied-mathematics",
    text: "Computational and Applied Mathematics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-and-mathematical-organization-theory",
    text: "Computational and Mathematical Organization Theory",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-and-structural-biotechnology-journal",
    text: "Computational and Structural Biotechnology Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computational-and-theoretical-chemistry",
    text: "Computational and Theoretical Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computational-astrophysics-and-cosmology",
    text: "Computational Astrophysics and Cosmology",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-biology-and-chemistry",
    text: "Computational Biology and Chemistry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computational-cognitive-science",
    text: "Computational Cognitive Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-condensed-matter",
    text: "Computational Condensed Matter",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computational-economics",
    text: "Computational Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-geometry-theory-and-applications",
    text: "Computational Geometry: Theory and Applications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computational-geosciences",
    text: "Computational Geosciences",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-intelligence",
    text: "Computational Intelligence",
    parent: "american-medical-association",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14678640/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "computational-management-science",
    text: "Computational Management Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-materials-science",
    text: "Computational Materials Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computational-mechanics",
    text: "Computational Mechanics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-methods-and-function-theory",
    text: "Computational Methods and Function Theory",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-optimization-and-applications",
    text: "Computational Optimization and Applications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-particle-mechanics",
    text: "Computational Particle Mechanics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-science-and-discovery",
    text: "Computational Science &amp; Discovery",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1749-4699",
    urltwo: "NA"
  },
  {
    id: "computational-social-networks",
    text: "Computational Social Networks",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-statistics-and-data-analysis",
    text: "Computational Statistics and Data Analysis",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computational-statistics",
    text: "Computational Statistics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computational-thermal-sciences-an-international-journal",
    text: "Computational Thermal Sciences: An International Journal",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/648192910890cd0e.html",
    urltwo: "NA"
  },
  {
    id: "computational-toxicology",
    text: "Computational Toxicology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-aided-design",
    text: "Computer-Aided Design",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-aided-geometric-design",
    text: "Computer Aided Geometric Design",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-architecture-letters",
    text: "Computer Architecture Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=10208",
    urltwo: "NA"
  },
  {
    id: "computer-assisted-language-learning",
    text: "Computer Assisted Language Learning",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NCAL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "computer-communications",
    text: "Computer Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-languages-systems-and-structures",
    text: "Computer Languages, Systems &amp; Structures",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-law-and-security-review-the-international-journal-of-technology-law-and-practice",
    text: "Computer Law &amp; Security Review: The International Journal of Technology Law and Practice",
    parent: "oscola",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-methods-and-programs-in-biomedicine",
    text: "Computer Methods and Programs in Biomedicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-methods-in-applied-mechanics-and-engineering",
    text: "Computer Methods in Applied Mechanics and Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-methods-in-biomechanics-and-biomedical-engineering-imaging-and-visualization",
    text: "Computer Methods in Biomechanics and Biomedical Engineering: Imaging &amp; Visualization",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TCIV20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "computer-methods-in-biomechanics-and-biomedical-engineering",
    text: "Computer Methods in Biomechanics and Biomedical Engineering",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GCMB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "computer-networks",
    text: "Computer Networks",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-physics-communications",
    text: "Computer Physics Communications",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-science-education",
    text: "Computer Science Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NCSE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "computer-science-research-and-development",
    text: "Computer Science - Research and Development",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computer-science-review",
    text: "Computer Science Review",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-speech-and-language",
    text: "Computer Speech &amp; Language",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-standards-and-interfaces",
    text: "Computer Standards &amp; Interfaces",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer-und-recht",
    text: "Computer und Recht (German)",
    parent: "NA",
    urlone: "http://www.computerundrecht.de/cr.htm",
    urltwo: "NA"
  },
  {
    id: "computer-vision-and-image-understanding",
    text: "Computer Vision and Image Understanding",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computer",
    text: "Computer",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=2",
    urltwo: "NA"
  },
  {
    id: "computerized-medical-imaging-and-graphics",
    text: "Computerized Medical Imaging and Graphics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-chemical-engineering",
    text: "Computers and Chemical Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-composition",
    text: "Computers and Composition",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-education",
    text: "Computers &amp; Education",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-electrical-engineering",
    text: "Computers and Electrical Engineering",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-electronics-in-agriculture",
    text: "Computers and Electronics in Agriculture",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-fluids",
    text: "Computers and Fluids",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-geosciences",
    text: "Computers and Geosciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-geotechnics",
    text: "Computers and Geotechnics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-graphics-x",
    text: "Computers &amp; Graphics: X",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-graphics",
    text: "Computers &amp; Graphics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-industrial-engineering",
    text: "Computers &amp; Industrial Engineering",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-mathematics-with-applications",
    text: "Computers and Mathematics with Applications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-operations-research",
    text: "Computers and Operations Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-security",
    text: "Computers &amp; Security",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-and-structures",
    text: "Computers and Structures",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-environment-and-urban-systems",
    text: "Computers, Environment and Urban Systems",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-in-biology-and-medicine",
    text: "Computers in Biology and Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-in-entertainment",
    text: "Computers in Entertainment",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "computers-in-human-behavior",
    text: "Computers in Human Behavior",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers-in-industry",
    text: "Computers in Industry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "computers",
    text: "Computers",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "computing-and-visualization-in-science",
    text: "Computing and Visualization in Science",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "computing-in-science-and-engineering",
    text: "Computing in Science &amp; Engineering",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5992",
    urltwo: "NA"
  },
  {
    id: "computing-surveys",
    text: "ACM Computing Surveys",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "computing",
    text: "Computing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "concepts-in-magnetic-resonance-part-a",
    text: "Concepts in Magnetic Resonance Part A",
    parent: "american-medical-association-no-url",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1552-5023/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "concussion",
    text: "Concussion",
    parent: "future-medicine",
    urlone: "https://www.futuremedicine.com/journal/cnc",
    urltwo: "NA"
  },
  {
    id: "condensed-matter",
    text: "Condensed Matter",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "conflict-and-health",
    text: "Conflict and Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "connection-science",
    text: "Connection Science",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCOS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "consciousness-and-cognition",
    text: "Consciousness and Cognition",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "conservation-and-society",
    text: "Conservation &amp; Society",
    parent: "NA",
    urlone: "http://www.conservationandsociety.org/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "conservation-biology",
    text: "Conservation Biology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1523-1739/homepage/Author_Style_Guide_apr2015.docx",
    urltwo: "NA"
  },
  {
    id: "conservation-genetics-resources",
    text: "Conservation Genetics Resources",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "conservation-genetics",
    text: "Conservation Genetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "conservation-letters",
    text: "Conservation Letters",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291755-263X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "conservation-physiology",
    text: "Conservation Physiology",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/conphys/for_authors/",
    urltwo: "NA"
  },
  {
    id: "constellations",
    text: "Constellations",
    parent: "chicago-fullnote-bibliography",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291467-8675/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "constitutional-political-economy",
    text: "Constitutional Political Economy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "constraints",
    text: "Constraints",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "construction-and-building-materials",
    text: "Construction and Building Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "constructive-approximation",
    text: "Constructive Approximation",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "consulting-psychology-journal-practice-and-research",
    text: "Consulting Psychology Journal: Practice and Research",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cpb",
    urltwo: "NA"
  },
  {
    id: "consumption-markets-and-culture",
    text: "Consumption Markets &amp; Culture",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GCMC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "contact-lens-and-anterior-eye",
    text: "Contact Lens and Anterior Eye",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "contaduria-y-administracion",
    text: "ContadurÃ­a y AdministraciÃ³n",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "contemporary-accounting-research",
    text: "Contemporary Accounting Research",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "https://onlinelibrary.wiley.com/page/journal/19113846/homepage/forauthors.html"
  },
  {
    id: "contemporary-arab-affairs",
    text: "Contemporary Arab Affairs",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCAA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "contemporary-british-history",
    text: "Contemporary British History",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FCBH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "contemporary-buddhism",
    text: "Contemporary Buddhism",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCBH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "contemporary-clinical-trials-communications",
    text: "Contemporary Clinical Trials Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "contemporary-clinical-trials",
    text: "Contemporary Clinical Trials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "contemporary-educational-psychology",
    text: "Contemporary Educational Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "contemporary-family-therapy",
    text: "Contemporary Family Therapy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "contemporary-islam",
    text: "Contemporary Islam",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "contemporary-italian-politics",
    text: "Contemporary Italian Politics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RITA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "contemporary-jewry",
    text: "Contemporary Jewry",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "contemporary-justice-review",
    text: "Contemporary Justice Review",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GCJR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "contemporary-school-psychology",
    text: "Contemporary School Psychology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "contemporary-social-science",
    text: "Contemporary Social Science",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSOC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "contemporary-sociology",
    text: "Contemporary Sociology",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/cs/cs.cfm",
    urltwo: "NA"
  },
  {
    id: "contemporary-south-asia",
    text: "Contemporary South Asia",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCSA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "continental-philosophy-review",
    text: "Continental Philosophy Review",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "continental-shelf-research",
    text: "Continental Shelf Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "continuity-and-change",
    text: "Continuity and Change",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/services/aop-file-manager/file/5c33779fe094043a087773c3/CON-ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "continuum-mechanics-and-thermodynamics",
    text: "Continuum Mechanics and Thermodynamics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "continuum",
    text: "Continuum",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCON20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "contraception-and-reproductive-medicine",
    text: "Contraception and Reproductive Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "contraception-x",
    text: "Contraception: X",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "contraception",
    text: "Contraception",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "contributions-to-mineralogy-and-petrology",
    text: "Contributions to Mineralogy and Petrology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "contributions-to-the-archaeology-of-egypt-nubia-and-the-levant",
    text: "Contributions to the Archaeology of Egypt, Nubia and the Levant",
    parent: "NA",
    urlone:
      "https://www.orea.oeaw.ac.at/en/publications/series/contributions-to-the-archaeology-of-egypt-nubia-and-the-levant-caenl/",
    urltwo: "NA"
  },
  {
    id: "control-and-automation",
    text: "Control &amp; Automation",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4469873",
    urltwo: "NA"
  },
  {
    id: "control-engineering-practice",
    text: "Control Engineering Practice",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "coordination-chemistry-reviews",
    text: "Coordination Chemistry Reviews",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "copd-research-and-practice",
    text: "COPD Research and Practice",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "copeia",
    text: "Copeia",
    parent: "NA",
    urlone: "http://www.asih.org/publications/instructions-authors",
    urltwo: "NA"
  },
  {
    id: "copernicus-publications",
    text: "Copernicus Publications",
    parent: "NA",
    urlone: "http://publications.copernicus.org/Copernicus_Publications_Reference_Types.pdf",
    urltwo: "NA"
  },
  {
    id: "cor-et-vasa",
    text: "Cor et Vasa",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "coral-reefs",
    text: "Coral Reefs",
    parent: "NA",
    urlone: "http://www.springer.com/life+sciences/ecology/journal/338",
    urltwo: "NA"
  },
  {
    id: "cornea",
    text: "Cornea",
    parent: "NA",
    urlone: "http://journals.lww.com/corneajrnl/_layouts/15/1033/oaks.journals/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "coronary-artery-disease",
    text: "Coronary Artery Disease",
    parent: "vancouver",
    urlone: "http://edmgr.ovid.com/cad/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "corrosion-science",
    text: "Corrosion Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "corrosion",
    text: "Corrosion",
    parent: "NA",
    urlone: "http://www.nace.org/uploadedFiles/Publications/styleguideall.pdf",
    urltwo: "NA"
  },
  {
    id: "cortex",
    text: "Cortex",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cosmetics",
    text: "Cosmetics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "cost-effectiveness-and-resource-allocation",
    text: "Cost Effectiveness and Resource Allocation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "council-of-science-editors-alphabetical",
    text: "Council of Science Editors, Citation-Name (numeric, sorted alphabetically)",
    parent: "NA",
    urlone: "http://www.scientificstyleandformat.org/Tools/SSF-Citation-Quick-Guide.html",
    urltwo: "NA"
  },
  {
    id: "council-of-science-editors-author-date",
    text: "Council of Science Editors, Name-Year (author-date)",
    parent: "NA",
    urlone: "http://library.osu.edu/sites/guides/csegd.php",
    urltwo: "NA"
  },
  {
    id: "council-of-science-editors",
    text: "Council of Science Editors, Citation-Sequence (numeric)",
    parent: "NA",
    urlone: "http://www.scientificstyleandformat.org/Tools/SSF-Citation-Quick-Guide.html",
    urltwo: "NA"
  },
  {
    id: "counselling-and-psychotherapy-research",
    text: "Counselling and Psychotherapy Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCPR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "counselling-psychology-quarterly",
    text: "Counselling Psychology Quarterly",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCPQ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "couple-and-family-psychology-research-and-practice",
    text: "Couple and Family Psychology: Research and Practice",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cfp",
    urltwo: "NA"
  },
  {
    id: "cpt-pharmacometrics-and-systems-pharmacology",
    text: "CPT: Pharmacometrics &amp; Systems Pharmacology",
    parent: "clinical-pharmacology-and-therapeutics",
    urlone: "http://www.nature.com/psp/psp_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "cranfield-university-numeric",
    text: "Cranfield University (numeric)",
    parent: "NA",
    urlone: "http://barrington.cranfield.ac.uk/help/Numbered%20Ref%20Guide%20Linked.pdf",
    urltwo: "NA"
  },
  {
    id: "creativity-and-innovation-management",
    text: "Creativity and Innovation Management",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291467-8691/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "cretaceous-research",
    text: "Cretaceous Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "crime-law-and-social-change",
    text: "Crime, Law and Social Change",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "crime-science",
    text: "Crime Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "criminal-justice-studies",
    text: "Criminal Justice Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GJUP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "criminal-law-journal",
    text: "Criminal Law Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "crisis-the-journal-of-crisis-intervention-and-suicide-prevention",
    text: "Crisis: The Journal of Crisis Intervention and Suicide Prevention",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cri",
    urltwo: "NA"
  },
  {
    id: "critical-african-studies",
    text: "Critical African Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCAF20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "critical-care-medicine",
    text: "Critical Care Medicine",
    parent: "NA",
    urlone: "http://journals.lww.com/ccmjournal/Pages/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "critical-care",
    text: "Critical Care",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "critical-criminology",
    text: "Critical Criminology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "critical-discourse-studies",
    text: "Critical Discourse Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCDS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "critical-perspectives-on-accounting",
    text: "Critical Perspectives on Accounting",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "critical-public-health",
    text: "Critical Public Health",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCPH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "critical-reviews-in-biomedical-engineering",
    text: "Critical Reviews in Biomedical Engineering",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/4b27cbfc562e21b8.html",
    urltwo: "NA"
  },
  {
    id: "critical-reviews-in-eukaryotic-gene-expression",
    text: "Critical Reviews in Eukaryotic Gene Expression",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/6dbf508d3b17c437.html",
    urltwo: "NA"
  },
  {
    id: "critical-reviews-in-immunology",
    text: "Critical Reviews in Immunology",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/2ff21abf44b19838.html",
    urltwo: "NA"
  },
  {
    id: "critical-reviews-in-neurobiology",
    text: "Critical Reviews in Neurobiology",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/7b004699754c9fe6.html",
    urltwo: "NA"
  },
  {
    id: "critical-reviews-in-oncogenesis",
    text: "Critical Reviews in Oncogenesis",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/439f422d0783386a.html",
    urltwo: "NA"
  },
  {
    id: "critical-reviews-in-oncology-hematology",
    text: "Critical Reviews in Oncology / Hematology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "critical-reviews-in-physical-and-rehabilitation-medicine",
    text: "Critical Reviews in Physical and Rehabilitation Medicine",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/757fcb0219d89390.html",
    urltwo: "NA"
  },
  {
    id: "critical-reviews-in-plant-sciences",
    text: "Critical Reviews in Plant Sciences",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=bpts20",
    urltwo: "NA"
  },
  {
    id: "critical-reviews-in-solid-state-and-materials-sciences",
    text: "Critical Reviews in Solid State and Materials Sciences",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=bsms20#refs",
    urltwo: "https://www.tandf.co.uk/journals/authors/style/reference/ref_BSMS.pdf"
  },
  {
    id: "critical-reviews-in-therapeutic-drug-carrier-systems",
    text: "Critical Reviews in Therapeutic Drug Carrier Systems",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/3667c4ae6e8fd136.html",
    urltwo: "NA"
  },
  {
    id: "critical-studies-in-media-communication",
    text: "Critical Studies in Media Communication",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCSM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "critical-studies-on-security",
    text: "Critical Studies on Security",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCSS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "critical-studies-on-terrorism",
    text: "Critical Studies on Terrorism",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RTER20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "critical-ultrasound-journal",
    text: "Critical Ultrasound Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "crop-and-pasture-science",
    text: "Crop &amp; Pasture Science",
    parent: "international-journal-of-wildland-fire",
    urlone: "http://www.publish.csiro.au/nid/62/aid/199.htm#17",
    urltwo: "NA"
  },
  {
    id: "crop-breeding-and-applied-biotechnology",
    text: "Crop Breeding and Applied Biotechnology",
    parent: "NA",
    urlone: "http://www.sbmp.org.br/cbab/siscbab/modules/tiny3/",
    urltwo: "NA"
  },
  {
    id: "crop-protection",
    text: "Crop Protection",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "crop-science",
    text: "Crop Science",
    parent: "asa-cssa-sssa",
    urlone: "https://www.crops.org/publications/cs",
    urltwo: "NA"
  },
  {
    id: "crustaceana",
    text: "Crustaceana",
    parent: "NA",
    urlone: "https://brill.com/view/journals/cr/cr-overview.xml",
    urltwo: "https://brill.com/fileasset/downloads_products/Author_Instructions/CR.pdf"
  },
  {
    id: "cryobiology",
    text: "Cryobiology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cryogenics",
    text: "Cryogenics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cryptogamie-algologie",
    text: "Cryptogamie, Algologie",
    parent: "museum-national-dhistoire-naturelle",
    urlone: "http://sciencepress.mnhn.fr/sites/default/files/periodiques/pdf/ia-algo-2019-en_0.pdf",
    urltwo: "NA"
  },
  {
    id: "cryptogamie-bryologie",
    text: "Cryptogamie, Bryologie",
    parent: "museum-national-dhistoire-naturelle",
    urlone: "http://sciencepress.mnhn.fr/sites/default/files/periodiques/pdf/ia-bryo-2019-en_0.pdf",
    urltwo: "NA"
  },
  {
    id: "cryptogamie-mycologie",
    text: "Cryptogamie, Mycologie",
    parent: "museum-national-dhistoire-naturelle",
    urlone: "http://sciencepress.mnhn.fr/sites/default/files/periodiques/pdf/ia-myco-2019-en_0.pdf",
    urltwo: "NA"
  },
  {
    id: "cryptography-and-communications",
    text: "Cryptography and Communications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cryptography",
    text: "Cryptography",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "crystal-growth-and-design",
    text: "Crystal Growth &amp; Design",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/cgdefu/cgdefu_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "crystallography-reviews",
    text: "Crystallography Reviews",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GCRY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "crystals",
    text: "Crystals",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "crystengcomm",
    text: "CrystEngComm",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "csi-transactions-on-ict",
    text: "CSI Transactions on ICT",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cuadernos-de-economia-y-direccion-de-la-empresa",
    text: "Cuadernos de EconomÃ­a y DirecciÃ³n de la Empresa",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cuadernos-de-economia",
    text: "Cuadernos de EconomÃ­a",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cuadernos-de-filologia-clasica",
    text: "Cuadernos de FilologÃ­a ClÃ¡sica. Estudios Griegos e Indoeuropeos (autor-aÃ±o, Spanish)",
    parent: "NA",
    urlone: "http://revistas.ucm.es/index.php/CFCG/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "cultivos-tropicales",
    text: "Cultivos Tropicales (Spanish)",
    parent: "NA",
    urlone: "http://ediciones.inca.edu.cu/index.php/ediciones/pages/view/autores",
    urltwo: "NA"
  },
  {
    id: "cultural-diversity-and-ethnic-minority-psychology",
    text: "Cultural Diversity and Ethnic Minority Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cdp",
    urltwo: "NA"
  },
  {
    id: "cultural-geographies",
    text: "cultural geographies",
    parent: "NA",
    urlone: "https://uk.sagepub.com/en-gb/eur/journal/cultural-geographies#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "cultural-studies-of-science-education",
    text: "Cultural Studies of Science Education",
    parent: "NA",
    urlone: "http://www.springer.com/education+%26+language/science+education/journal/11422",
    urltwo: "http://owl.english.purdue.edu/owl/resource/560/01/"
  },
  {
    id: "cultural-trends",
    text: "Cultural Trends",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CCUT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "culture-and-brain",
    text: "Culture and Brain",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "culture-and-organization",
    text: "Culture and Organization",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GSCO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "culture-and-religion",
    text: "Culture and Religion",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCAR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "culture-health-and-sexuality",
    text: "Culture, Health &amp; Sexuality",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TCHS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "culture-medicine-and-psychiatry",
    text: "Culture, Medicine, and Psychiatry",
    parent: "NA",
    urlone: "http://www.springer.com/social+sciences/anthropology+%26+archaeology/journal/11013",
    urltwo: "NA"
  },
  {
    id: "cureus",
    text: "Cureus",
    parent: "NA",
    urlone: "https://www.cureus.com/author_guide#references-section",
    urltwo: "NA"
  },
  {
    id: "current-addiction-reports",
    text: "Current Addiction Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-allergy-and-asthma-reports",
    text: "Current Allergy and Asthma Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-alzheimer-research",
    text: "Current Alzheimer Research",
    parent: "NA",
    urlone: "https://benthamscience.com/journals/current-alzheimer-research/author-guidelines/#top",
    urltwo: "NA"
  },
  {
    id: "current-anesthesiology-reports",
    text: "Current Anesthesiology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-applied-physics",
    text: "Current Applied Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-atherosclerosis-reports",
    text: "Current Atherosclerosis Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-behavioral-neuroscience-reports",
    text: "Current Behavioral Neuroscience Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-biology",
    text: "Current Biology",
    parent: "cell-numeric-superscript",
    urlone: "http://www.cell.com/current-biology/authors",
    urltwo: "NA"
  },
  {
    id: "current-bladder-dysfunction-reports",
    text: "Current Bladder Dysfunction Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-breast-cancer-reports",
    text: "Current Breast Cancer Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-cardiology-reports",
    text: "Current Cardiology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-cardiovascular-imaging-reports",
    text: "Current Cardiovascular Imaging Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-cardiovascular-risk-reports",
    text: "Current Cardiovascular Risk Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-climate-change-reports",
    text: "Current Climate Change Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-clinical-microbiology-reports",
    text: "Current Clinical Microbiology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-colorectal-cancer-reports",
    text: "Current Colorectal Cancer Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-dermatology-reports",
    text: "Current Dermatology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-developmental-disorders-reports",
    text: "Current Developmental Disorders Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-diabetes-reports",
    text: "Current Diabetes Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-emergency-and-hospital-medicine-reports",
    text: "Current Emergency and Hospital Medicine Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-environmental-health-reports",
    text: "Current Environmental Health Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-epidemiology-reports",
    text: "Current Epidemiology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-eye-research",
    text: "Current Eye Research",
    parent: "vancouver-superscript",
    urlone: "http://informahealthcare.com/page/cey/Description#Instructions",
    urltwo: "NA"
  },
  {
    id: "current-forestry-reports",
    text: "Current Forestry Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-fungal-infection-reports",
    text: "Current Fungal Infection Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-gastroenterology-reports",
    text: "Current Gastroenterology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-gene-therapy",
    text: "Current Gene Therapy",
    parent: "NA",
    urlone: "http://benthamscience.com/journals/current-gene-therapy/author-guidelines/#top",
    urltwo: "NA"
  },
  {
    id: "current-genetic-medicine-reports",
    text: "Current Genetic Medicine Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-genetics",
    text: "Current Genetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-geriatrics-reports",
    text: "Current Geriatrics Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-heart-failure-reports",
    text: "Current Heart Failure Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-hematologic-malignancy-reports",
    text: "Current Hematologic Malignancy Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-hepatology-reports",
    text: "Current Hepatology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-hiv-aids-reports",
    text: "Current HIV/AIDS Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-hypertension-reports",
    text: "Current Hypertension Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-infectious-disease-reports",
    text: "Current Infectious Disease Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-issues-in-language-planning",
    text: "Current Issues in Language Planning",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCLP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "current-issues-in-tourism",
    text: "Current Issues in Tourism",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCIT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "current-landscape-ecology-reports",
    text: "Current Landscape Ecology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-medical-research-and-opinion",
    text: "Current Medical Research and Opinion",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=icmo20",
    urltwo: "NA"
  },
  {
    id: "current-medicine-research-and-practice",
    text: "Current Medicine Research and Practice",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-microbiology",
    text: "Current Microbiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-molecular-biology-reports",
    text: "Current Molecular Biology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-neurology-and-neuroscience-reports",
    text: "Current Neurology and Neuroscience Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-nutrition-reports",
    text: "Current Nutrition Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-obesity-reports",
    text: "Current Obesity Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-obstetrics-and-gynecology-reports",
    text: "Current Obstetrics and Gynecology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-oncology-reports",
    text: "Current Oncology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-ophthalmology-reports",
    text: "Current Ophthalmology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-opinion-in-behavioral-sciences",
    text: "Current Opinion in Behavioral Sciences",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-biotechnology",
    text: "Current Opinion in Biotechnology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-cell-biology",
    text: "Current Opinion in Cell Biology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-chemical-biology",
    text: "Current Opinion in Chemical Biology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-chemical-engineering",
    text: "Current Opinion in Chemical Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-colloid-and-interface-science",
    text: "Current Opinion in Colloid &amp; Interface Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-electrochemistry",
    text: "Current Opinion in Electrochemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-endocrinology-diabetes-and-obesity",
    text: "Current Opinion in Endocrinology, Diabetes and Obesity",
    parent: "NA",
    urlone: "https://journals.lww.com/co-endocrinology/Pages/informationforauthors.aspx",
    urltwo: "http://edmgr.ovid.com/coe/accounts/ifauth.pdf"
  },
  {
    id: "current-opinion-in-environmental-sustainability",
    text: "Current Opinion in Environmental Sustainability",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-food-science",
    text: "Current Opinion in Food Science",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-genetics-and-development",
    text: "Current Opinion in Genetics &amp; Development",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-green-and-sustainable-chemistry",
    text: "Current Opinion in Green and Sustainable Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-immunology",
    text: "Current Opinion in Immunology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-insect-science",
    text: "Current Opinion in Insect Science",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-microbiology",
    text: "Current Opinion in Microbiology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-neurobiology",
    text: "Current Opinion in Neurobiology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-ophthalmology",
    text: "Current Opinion in Ophthalmology",
    parent: "vancouver-brackets",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-pharmacology",
    text: "Current Opinion in Pharmacology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-plant-biology",
    text: "Current Opinion in Plant Biology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-psychology",
    text: "Current Opinion in Psychology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-solid-state-and-materials-science",
    text: "Current Opinion in Solid State &amp; Materials Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-structural-biology",
    text: "Current Opinion in Structural Biology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-toxicology",
    text: "Current Opinion in Toxicology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-opinion-in-virology",
    text: "Current Opinion in Virology",
    parent: "current-opinion",
    urlone: "http://www.current-opinion.com/journals/",
    urltwo: "NA"
  },
  {
    id: "current-opinion",
    text: "Current Opinion journals",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/current-opinion-in-biotechnology/0958-1669/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "current-oral-health-reports",
    text: "Current Oral Health Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-osteoporosis-reports",
    text: "Current Osteoporosis Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-otorhinolaryngology-reports",
    text: "Current Otorhinolaryngology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-pain-and-headache-reports",
    text: "Current Pain and Headache Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-pathobiology-reports",
    text: "Current Pathobiology Reports",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-pediatrics-reports",
    text: "Current Pediatrics Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-pharmaceutical-design",
    text: "Current Pharmaceutical Design",
    parent: "NA",
    urlone: "http://benthamscience.com/journals/current-pharmaceutical-design/author-guidelines/#top",
    urltwo: "NA"
  },
  {
    id: "current-pharmacology-reports",
    text: "Current Pharmacology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-physical-medicine-and-rehabilitation-reports",
    text: "Current Physical Medicine and Rehabilitation Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-plant-biology",
    text: "Current Plant Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-pollution-reports",
    text: "Current Pollution Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-problems-in-cardiology",
    text: "Current Problems in Cardiology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-problems-in-pediatric-and-adolescent-health-care",
    text: "Current Problems in Pediatric and Adolescent Health Care",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-problems-in-surgery",
    text: "Current Problems in Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-proteomics",
    text: "Current Proteomics",
    parent: "NA",
    urlone: "http://benthamscience.com/journal/authors-guidelines.php?journalID=cp",
    urltwo: "http://pubs.acs.org/doi/pdf/10.1021/bk-2006-STYG.ch014"
  },
  {
    id: "current-protocols",
    text: "Current Protocols journals",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/book/10.1002/0471250953/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "current-psychiatry-reports",
    text: "Current Psychiatry Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-psychology",
    text: "Current Psychology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-pulmonology-reports",
    text: "Current Pulmonology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-radiology-reports",
    text: "Current Radiology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-research-in-biotechnology",
    text: "Current Research in Biotechnology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-research-in-cell-biology",
    text: "Current Research in Cell Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-research-in-translational-medicine",
    text: "Current Research in Translational Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-reviews-in-musculoskeletal-medicine",
    text: "Current Reviews in Musculoskeletal Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-rheumatology-reports",
    text: "Current Rheumatology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-science",
    text: "Current Science",
    parent: "NA",
    urlone: "http://www.currentscience.ac.in/php/inst_authors.php",
    urltwo: "NA"
  },
  {
    id: "current-sexual-health-reports",
    text: "Current Sexual Health Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-sleep-medicine-reports",
    text: "Current Sleep Medicine Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-stem-cell-reports",
    text: "Current Stem Cell Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-surgery-reports",
    text: "Current Surgery Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-sustainable-renewable-energy-reports",
    text: "Current Sustainable/Renewable Energy Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-therapeutic-research",
    text: "Current Therapeutic Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "current-topics-in-medicinal-chemistry",
    text: "Current Topics in Medicinal Chemistry",
    parent: "NA",
    urlone: "http://benthamscience.com/journal-files/template-files/ctmc-template.doc",
    urltwo: "NA"
  },
  {
    id: "current-transplantation-reports",
    text: "Current Transplantation Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-trauma-reports",
    text: "Current Trauma Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-treatment-options-in-allergy",
    text: "Current Treatment Options in Allergy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-treatment-options-in-cardiovascular-medicine",
    text: "Current Treatment Options in Cardiovascular Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-treatment-options-in-gastroenterology",
    text: "Current Treatment Options in Gastroenterology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-treatment-options-in-infectious-diseases",
    text: "Current Treatment Options in Infectious Diseases",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-treatment-options-in-neurology",
    text: "Current Treatment Options in Neurology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-treatment-options-in-oncology",
    text: "Current Treatment Options in Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-treatment-options-in-pediatrics",
    text: "Current Treatment Options in Pediatrics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-treatment-options-in-psychiatry",
    text: "Current Treatment Options in Psychiatry",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-treatment-options-in-rheumatology",
    text: "Current Treatment Options in Rheumatology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-tropical-medicine-reports",
    text: "Current Tropical Medicine Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-urology-reports",
    text: "Current Urology Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "current-urology",
    text: "Current Urology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/231997",
    urltwo: "NA"
  },
  {
    id: "currents-in-biblical-research",
    text: "Currents in Biblical Research",
    parent: "NA",
    urlone: "http://www.sagepub.com/journalsProdManSub.nav?prodId=Journal201735",
    urltwo: "NA"
  },
  {
    id: "currents-in-pharmacy-teaching-and-learning",
    text: "Currents in Pharmacy Teaching and Learning",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "curriculum-journal",
    text: "Curriculum Journal",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCJO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "customer-needs-and-solutions",
    text: "Customer Needs and Solutions",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cyberpsychology-behavior-and-social-networking",
    text: "Cyberpsychology, Behavior, and Social Networking",
    parent: "NA",
    urlone: "http://www.liebertpub.com/forauthors/cyberpsychology-behavior-and-social-networking/10/",
    urltwo: "NA"
  },
  {
    id: "cybium",
    text: "Cybium",
    parent: "NA",
    urlone: "http://sfi-cybium.fr/en/instructions-authors",
    urltwo: "NA"
  },
  {
    id: "cytogenetic-and-genome-research",
    text: "Cytogenetic and Genome Research",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Guidelines/224037#06",
    urltwo: "NA"
  },
  {
    id: "cytokine-and-growth-factor-reviews",
    text: "Cytokine and Growth Factor Reviews",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cytokine-x",
    text: "Cytokine: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cytokine",
    text: "Cytokine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "cytometry",
    text: "Cytometry",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1552-4930/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "cytotechnology",
    text: "Cytotechnology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "cytotherapy",
    text: "Cytotherapy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "czech-journal-of-tourism",
    text: "Czech Journal of Tourism",
    parent: "apa",
    urlone: "http://www.czechjournaloftourism.cz/page/en/27/authors/",
    urltwo: "http://www.czechjournaloftourism.cz/upload/1389533157.doc"
  },
  {
    id: "dairy-science-and-technology",
    text: "Dairy Science &amp; Technology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "dairy",
    text: "Dairy",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "dalton-transactions",
    text: "Dalton Transactions",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "danish-medical-journal",
    text: "Danish Medical Journal",
    parent: "ugeskrift-for-laeger",
    urlone: "http://www.danmedj.dk",
    urltwo: "NA"
  },
  {
    id: "daru-journal-of-pharmaceutical-sciences",
    text: "DARU Journal of Pharmaceutical Sciences",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "data-and-knowledge-engineering",
    text: "Data &amp; Knowledge Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "data-in-brief",
    text: "Data in Brief",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "data-mining-and-knowledge-discovery",
    text: "Data Mining and Knowledge Discovery",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "data-science-and-engineering",
    text: "Data Science and Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "data-science-journal",
    text: "Data Science Journal",
    parent: "NA",
    urlone: "http://datascience.codata.org/about/submissions/#References",
    urltwo: "NA"
  },
  {
    id: "data",
    text: "Data",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "database",
    text: "Database",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/databa/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "datenbank-spektrum",
    text: "Datenbank-Spektrum (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "de-buck",
    text: "De Buck (Dutch)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "de-economist",
    text: "De Economist",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "de-montfort-university-harvard",
    text: "De Montfort University - Harvard",
    parent: "NA",
    urlone: "http://www.library.dmu.ac.uk/Images/Selfstudy/Harvard.pdf",
    urltwo: "NA"
  },
  {
    id: "debate-feminista",
    text: "Debate Feminista",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "debatte-journal-of-contemporary-central-and-eastern-europe",
    text: "Debatte: Journal of Contemporary Central and Eastern Europe",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CDEB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "decision-analysis",
    text: "Decision Analysis",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/deca/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "decision-analytics",
    text: "Decision Analytics",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "decision-indian-institute-of-management-calcutta",
    text: "DECISION - Indian Institute of Management Calcutta",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "decision-sciences",
    text: "Decision Sciences",
    parent: "NA",
    urlone: "http://warrington.ufl.edu/departments/isom/DSJ/docs/style-guide-and-requirements.pdf",
    urltwo: "NA"
  },
  {
    id: "decision-support-systems",
    text: "Decision Support Systems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "decision",
    text: "Decision",
    parent: "apa",
    urlone: "http://content.apa.org/journals/dec",
    urltwo: "NA"
  },
  {
    id: "decisions-in-economics-and-finance",
    text: "Decisions in Economics and Finance",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "deep-sea-research-part-i",
    text: "Deep-Sea Research Part I",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "deep-sea-research-part-ii",
    text: "Deep-Sea Research Part II",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "defence-technology",
    text: "Defence Technology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dementia-and-geriatric-cognitive-disorders-extra",
    text: "Dementia and Geriatric Cognitive Disorders Extra",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/254733",
    urltwo: "NA"
  },
  {
    id: "dementia-and-geriatric-cognitive-disorders",
    text: "Dementia and Geriatric Cognitive Disorders",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9824",
    urltwo: "NA"
  },
  {
    id: "democratization",
    text: "Democratization",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FDEM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "demographic-research",
    text: "Demographic Research",
    parent: "NA",
    urlone: "http://www.demographic-research.org/files/demographic_research_reference_guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "demography",
    text: "Demography",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "dendrochronologia",
    text: "Dendrochronologia",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dental-abstracts",
    text: "Dental Abstracts",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dental-materials",
    text: "Dental Materials",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dentistry-journal",
    text: "Dentistry Journal",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "dentomaxillofacial-radiology",
    text: "Dentomaxillofacial Radiology",
    parent: "vancouver-superscript",
    urlone: "http://dmfr.birjournals.org/site/misc/Preparing_Your_Submission.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "der-anaesthesist",
    text: "Der Anaesthesist (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/anesthesiology/journal/101?detailsPage=pltci_2397481",
    urltwo: "NA"
  },
  {
    id: "der-chirurg",
    text: "Der Chirurg (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/surgery/journal/104?detailsPage=pltci_1286709",
    urltwo: "NA"
  },
  {
    id: "der-diabetologe",
    text: "Der Diabetologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/internal/journal/11428?detailsPage=pltci_1843427",
    urltwo: "NA"
  },
  {
    id: "der-freie-zahnarzt",
    text: "Der Freie Zahnarzt (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/dentistry/journal/12614?detailsPage=pltci_1422508",
    urltwo: "NA"
  },
  {
    id: "der-gastroenterologe",
    text: "Der Gastroenterologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/internal/journal/11377?detailsPage=pltci_1386510",
    urltwo: "NA"
  },
  {
    id: "der-gynakologe",
    text: "Der GynÃ¤kologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/gynecology/journal/129?detailsPage=pltci_1871909",
    urltwo: "NA"
  },
  {
    id: "der-hautarzt",
    text: "Der Hautarzt (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/dermatology/journal/105?detailsPage=pltci_1908811",
    urltwo: "NA"
  },
  {
    id: "der-internist",
    text: "Der Internist (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/internal/journal/108?detailsPage=pltci_1294427",
    urltwo: "NA"
  },
  {
    id: "der-junge-zahnarzt",
    text: "der junge zahnarzt (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/dentistry/journal/13279?detailsPage=pltci_2433517",
    urltwo: "NA"
  },
  {
    id: "der-kardiologe",
    text: "Der Kardiologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/internal/journal/12181?detailsPage=pltci_1300027",
    urltwo: "NA"
  },
  {
    id: "der-mkg-chirurg",
    text: "Der MKG-Chirurg (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/surgery/journal/12285?detailsPage=pltci_1422710",
    urltwo: "NA"
  },
  {
    id: "der-moderne-staat",
    text: "der moderne staat (German)",
    parent: "NA",
    urlone: "http://www.budrich-journals.de/index.php/dms/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "der-nephrologe",
    text: "Der Nephrologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/nephrology/journal/11560?detailsPage=pltci_1750408",
    urltwo: "NA"
  },
  {
    id: "der-nervenarzt",
    text: "Der Nervenarzt (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/journal/115?detailsPage=pltci_1299809",
    urltwo: "NA"
  },
  {
    id: "der-notarzt",
    text: "Der Notarzt",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "der-nuklearmediziner",
    text: "Der Nuklearmediziner",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "der-onkologe",
    text: "Der Onkologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/oncology/journal/761?detailsPage=pltci_1285712",
    urltwo: "NA"
  },
  {
    id: "der-ophthalmologe",
    text: "Der Ophthalmologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/ophthalmology/journal/347?detailsPage=pltci_1288428",
    urltwo: "NA"
  },
  {
    id: "der-orthopade",
    text: "Der OrthopÃ¤de (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/orthopedics/journal/132?detailsPage=pltci_1380934",
    urltwo: "NA"
  },
  {
    id: "der-pathologe",
    text: "Der Pathologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/pathology/journal/292?detailsPage=pltci_1288444",
    urltwo: "NA"
  },
  {
    id: "der-pneumologe",
    text: "Der Pneumologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/internal/journal/10405?detailsPage=pltci_1843409",
    urltwo: "NA"
  },
  {
    id: "der-radiologe",
    text: "Der Radiologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/radiology/journal/117?detailsPage=pltci_1290110",
    urltwo: "NA"
  },
  {
    id: "der-schmerz",
    text: "Der Schmerz (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/journal/482?detailsPage=pltci_1750609",
    urltwo: "NA"
  },
  {
    id: "der-unfallchirurg",
    text: "Der Unfallchirurg (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/journal/113?detailsPage=pltci_1380916",
    urltwo: "NA"
  },
  {
    id: "der-urologe",
    text: "Der Urologe (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/urology/journal/120?detailsPage=pltci_1290009",
    urltwo: "NA"
  },
  {
    id: "der-zoologische-garten",
    text: "Der Zoologische Garten",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dermato-endocrinology",
    text: "Dermato-Endocrinology",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dermatology-and-therapy",
    text: "Dermatology and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "dermatology-online-journal",
    text: "Dermatology Online Journal",
    parent: "NA",
    urlone: "https://escholarship.org/uc/doj/submissionguidelines",
    urltwo: "NA"
  },
  {
    id: "dermatology",
    text: "Dermatology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9832",
    urltwo: "NA"
  },
  {
    id: "dermatopathology",
    text: "Dermatopathology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/259875",
    urltwo: "NA"
  },
  {
    id: "desalination",
    text: "Desalination",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "design-automation-for-embedded-systems",
    text: "Design Automation for Embedded Systems",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "design-studies",
    text: "Design Studies",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "designed-monomers-and-polymers",
    text: "Designed Monomers and Polymers",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TDMP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "designs-codes-and-cryptography",
    text: "Designs, Codes and Cryptography",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "designs",
    text: "Designs",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "deuqua-special-publications",
    text: "DEUQUA Special Publications",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "deutsche-entomologische-zeitschrift",
    text: "Deutsche Entomologische Zeitschrift",
    parent: "pensoft-journals",
    urlone: "https://dez.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "deutsche-gesellschaft-fur-psychologie",
    text: "Deutsche Gesellschaft fÃ¼r Psychologie (German)",
    parent: "NA",
    urlone: "https://www.psychologie.uni-bonn.de/de/studium/richtlinien-zur-manuskriptgestaltung/view",
    urltwo: "https://github.com/citation-style-language/styles/pull/432"
  },
  {
    id: "deutsche-medizinische-wochenschrift",
    text: "DMW - Deutsche Medizinische Wochenschrift",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "deutsche-sprache",
    text: "Deutsche Sprache (German)",
    parent: "NA",
    urlone: "de",
    urltwo: "NA"
  },
  {
    id: "deutsches-archaologisches-institut",
    text: "Deutsches ArchÃ¤ologisches Institut",
    parent: "NA",
    urlone: "http://www.dainst.org/publikationen/publizieren-beim-dai/richtlinien",
    urltwo: "NA"
  },
  {
    id: "deutsches-arzteblatt",
    text: "Deutsches Ã„rzteblatt",
    parent: "NA",
    urlone: "https://www.aerzteblatt.de/down.asp?id=9205",
    urltwo: "NA"
  },
  {
    id: "developing-world-bioethics",
    text: "Developing World Bioethics",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14718847/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "development-and-change",
    text: "Development and Change",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291467-7660/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "development-engineering",
    text: "Development Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "development-genes-and-evolution",
    text: "Development Genes and Evolution",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "development-in-practice",
    text: "Development in Practice",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CDIP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "development-policy-review",
    text: "Development Policy Review",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291467-7679/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "development",
    text: "Development",
    parent: "the-company-of-biologists",
    urlone: "http://dev.biologists.org/site/submissions/manuscript_prep.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "developmental-and-comparative-immunology",
    text: "Developmental and Comparative Immunology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "developmental-biology",
    text: "Developmental Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "developmental-cell",
    text: "Developmental Cell",
    parent: "cell",
    urlone: "http://www.cell.com/developmental-cell/authors",
    urltwo: "NA"
  },
  {
    id: "developmental-cognitive-neuroscience",
    text: "Developmental Cognitive Neuroscience",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "developmental-neurobiology",
    text: "Developmental Neurobiology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291932-846X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "developmental-neuroscience",
    text: "Developmental Neuroscience",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9859",
    urltwo: "NA"
  },
  {
    id: "developmental-psychology",
    text: "Developmental Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/dev",
    urltwo: "NA"
  },
  {
    id: "developmental-review",
    text: "Developmental Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diabetes-and-metabolic-syndrome-clinical-research-and-reviews",
    text: "Diabetes &amp; Metabolic Syndrome: Clinical Research &amp; Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diabetes-and-metabolism-x",
    text: "Diabetes &amp; Metabolism: X",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diabetes-and-metabolism",
    text: "Diabetes &amp; Metabolism",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diabetes-and-vascular-disease-research",
    text: "Diabetes &amp; Vascular Disease Research",
    parent: "sage-vancouver",
    urlone: "http://www.uk.sagepub.com/msg/dvr.htm#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "diabetes-care",
    text: "Diabetes Care",
    parent: "vancouver",
    urlone: "http://care.diabetesjournals.org/site/misc/ifora.xhtml#Section6",
    urltwo: "NA"
  },
  {
    id: "diabetes-management",
    text: "Diabetes Management",
    parent: "future-science-group",
    urlone: "http://www.futuremedicine.com/loi/dmt",
    urltwo: "NA"
  },
  {
    id: "diabetes-research-and-clinical-practice",
    text: "Diabetes Research and Clinical Practice",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diabetes-technology-and-therapeutics",
    text: "Diabetes Technology &amp; Therapeutics",
    parent: "NA",
    urlone: "https://home.liebertpub.com/publications/diabetes-technology-and-therapeutics/11/for-authors",
    urltwo: "NA"
  },
  {
    id: "diabetes-therapy",
    text: "Diabetes Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "diabetes",
    text: "Diabetes",
    parent: "vancouver",
    urlone: "http://diabetes.diabetesjournals.org/site/misc/ifora.xhtml#Section6",
    urltwo: "NA"
  },
  {
    id: "diabetologia",
    text: "Diabetologia",
    parent: "NA",
    urlone: "http://diabetologia-journal.org/for-authors/instructions-to-authors/",
    urltwo: "NA"
  },
  {
    id: "diabetologie-und-stoffwechsel",
    text: "Diabetologie und Stoffwechsel",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "diabetology-and-metabolic-syndrome",
    text: "Diabetology &amp; Metabolic Syndrome",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "diabetology-international",
    text: "Diabetology International",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "diagnostic-and-interventional-imaging",
    text: "Diagnostic and Interventional Imaging",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diagnostic-cytopathology",
    text: "Diagnostic Cytopathology",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-0339/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "diagnostic-microbiology-and-infectious-disease",
    text: "Diagnostic Microbiology &amp; Infectious Disease",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diagnostic-pathology",
    text: "Diagnostic Pathology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "diagnostico-prenatal",
    text: "DiagnÃ³stico Prenatal (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/diagnostico-prenatal/2173-4127/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "diagnostics-in-neuropsychiatry",
    text: "Diagnostics in Neuropsychiatry",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diagnostics",
    text: "Diagnostics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "dialectical-anthropology",
    text: "Dialectical Anthropology",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "dialisis-y-trasplante",
    text: "Dialisis y Trasplante (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/dialisis-y-trasplante/1886-2845/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "diamond-and-related-materials",
    text: "Diamond &amp; Related Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diaspora-studies",
    text: "Diaspora Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RDST20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "diatom-research",
    text: "Diatom Research",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/ref_TDIA.pdf",
    urltwo: "NA"
  },
  {
    id: "die-bachelorarbeit-samac-et-al-in-text",
    text: "Die Bachelorarbeit (Samac et al.) (in-text, German)",
    parent: "NA",
    urlone:
      "http://www.amazon.de/Bachelorarbeit-P%C3%A4dagogischen-Hochschule-wissenschaftlicher-Lehrerbildung/dp/3708901886",
    urltwo: "NA"
  },
  {
    id: "die-bachelorarbeit-samac-et-al-note",
    text: "Die Bachelorarbeit (Samac et al.) (note, German)",
    parent: "NA",
    urlone:
      "http://www.amazon.de/Bachelorarbeit-P%C3%A4dagogischen-Hochschule-wissenschaftlicher-Lehrerbildung/dp/3708901886",
    urltwo: "NA"
  },
  {
    id: "die-rehabilitation",
    text: "Die Rehabilitation",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "die-wirbelsaule",
    text: "Die WirbelsÃ¤ule",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "differential-equations-and-dynamical-systems",
    text: "Differential Equations and Dynamical Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "differential-geometry-and-its-applications",
    text: "Differential Geometry and its Applications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "differentiation",
    text: "Differentiation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "digestion",
    text: "Digestion",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9867",
    urltwo: "NA"
  },
  {
    id: "digestive-and-liver-disease-supplements",
    text: "Digestive and Liver Disease Supplements",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "digestive-and-liver-disease",
    text: "Digestive and Liver Disease",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "digestive-diseases",
    text: "Digestive Diseases",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9875",
    urltwo: "NA"
  },
  {
    id: "digestive-surgery",
    text: "Digestive Surgery",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9883",
    urltwo: "NA"
  },
  {
    id: "digital-applications-in-archaeology-and-cultural-heritage",
    text: "Digital Applications in Archaeology and Cultural Heritage",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "digital-chinese-medicine",
    text: "Digital Chinese Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "digital-communications-and-networks",
    text: "Digital Communications and Networks",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "digital-creativity",
    text: "Digital Creativity",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NDCR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "digital-experiences-in-mathematics-education",
    text: "Digital Experiences in Mathematics Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "digital-investigation",
    text: "Digital Investigation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "digital-journalism",
    text: "Digital Journalism",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RDIJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "digital-scholarship-in-the-humanities",
    text: "Digital Scholarship in the Humanities",
    parent: "NA",
    urlone: "https://academic.oup.com/dsh/pages/general_instructions#Typescript%20Text",
    urltwo: "NA"
  },
  {
    id: "digital-signal-processing",
    text: "Digital Signal Processing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "din-1505-2-alphanumeric",
    text: "DIN 1505-2 (alphanumeric, German) - standard superseded by ISO-690",
    parent: "NA",
    urlone: "http://www.angst-vor-dem-leeren-blatt.de/docs/download/zitierrichtlinie_din_1505.pdf",
    urltwo: "http://www.bui.haw-hamburg.de/fileadmin/redaktion/diplom/Lorenzen__litverz.pdf"
  },
  {
    id: "din-1505-2-numeric-alphabetical",
    text: "DIN 1505-2 (numeric, sorted alphabetically, German) - standard superseded by ISO-690",
    parent: "NA",
    urlone: "http://www.bui.haw-hamburg.de/fileadmin/redaktion/diplom/Lorenzen__litverz.pdf",
    urltwo:
      "http://bibliothek.fh-potsdam.de/fileadmin/fhp_bib/dokumente/Schulungen/wissenschaftliches_Arbeiten/Zitieren_Lorenzen.pdf"
  },
  {
    id: "din-1505-2-numeric",
    text: "DIN 1505-2 (numeric, German) - standard superseded by ISO-690",
    parent: "NA",
    urlone: "http://www.bui.haw-hamburg.de/fileadmin/redaktion/diplom/Lorenzen__litverz.pdf",
    urltwo:
      "http://bibliothek.fh-potsdam.de/fileadmin/fhp_bib/dokumente/Schulungen/wissenschaftliches_Arbeiten/Zitieren_Lorenzen.pdf"
  },
  {
    id: "din-1505-2",
    text: "DIN 1505-2 (author-date, German) - standard superseded by ISO-690",
    parent: "NA",
    urlone: "http://www.bui.haw-hamburg.de/fileadmin/redaktion/diplom/Lorenzen__litverz.pdf",
    urltwo:
      "http://bibliothek.fh-potsdam.de/fileadmin/fhp_bib/dokumente/Schulungen/wissenschaftliches_Arbeiten/Zitieren_Lorenzen.pdf"
  },
  {
    id: "diplo",
    text: "Diplo",
    parent: "NA",
    urlone: "http://diplomacy.edu/",
    urltwo: "NA"
  },
  {
    id: "disability-and-health-journal",
    text: "Disability and Health Journal",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "disability-and-rehabilitation",
    text: "Disability and Rehabilitation",
    parent: "NA",
    urlone:
      "http://informahealthcare.com/userimages/ContentEditor/1306847395843/Disability_and_Rehabilitation_IFAs_May_2011.pdf",
    urltwo: "NA"
  },
  {
    id: "disability-and-society",
    text: "Disability &amp; Society",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CDSO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "disaster-and-military-medicine",
    text: "Disaster and Military Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "disaster-health",
    text: "Disaster Health",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "discourse-context-and-media",
    text: "Discourse, Context &amp; Media",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "discourse-studies-in-the-cultural-politics-of-education",
    text: "Discourse: Studies in the Cultural Politics of Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CDIS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "discovery-medicine",
    text: "Discovery Medicine",
    parent: "NA",
    urlone: "http://www.discoverymedicine.com/about/information-for-authors-of-discovery-medicine/",
    urltwo: "NA"
  },
  {
    id: "discrete-and-computational-geometry",
    text: "Discrete &amp; Computational Geometry",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "discrete-applied-mathematics",
    text: "Discrete Applied Mathematics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "discrete-event-dynamic-systems",
    text: "Discrete Event Dynamic Systems",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "discrete-mathematics",
    text: "Discrete Mathematics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "discrete-optimization",
    text: "Discrete Optimization",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "disease-a-month",
    text: "Disease-a-Month",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "disease-models-and-mechanisms",
    text: "Disease Models &amp; Mechanisms",
    parent: "the-company-of-biologists",
    urlone: "http://dmm.biologists.org/site/author/manuscript_prep.xhtml#refs",
    urltwo: "NA"
  },
  {
    id: "diseases-of-aquatic-organisms",
    text: "Diseases of Aquatic Organisms",
    parent: "inter-research-science-center",
    urlone: "http://www.int-res.com/journals/dao/dao-home/",
    urltwo: "NA"
  },
  {
    id: "diseases-of-the-colon-and-rectum",
    text: "Diseases of the Colon &amp; Rectum",
    parent: "american-medical-association",
    urlone: "http://journals.lww.com/dcrjournal/_layouts/1033/oaks.journals/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "diseases-of-the-esophagus",
    text: "Diseases of the Esophagus",
    parent: "vancouver-superscript",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "diseases",
    text: "Diseases",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "displays",
    text: "Displays",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "distributed-and-parallel-databases",
    text: "Distributed and Parallel Databases",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "distributed-computing",
    text: "Distributed Computing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "diversity-and-distributions",
    text: "Diversity and Distributions",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291472-4642/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "diversity",
    text: "Diversity",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "dna-repair",
    text: "DNA Repair",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dna-research",
    text: "DNA Research",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/dnares/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "documenta-ophthalmologica",
    text: "Documenta Ophthalmologica",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "documents-d-archeologie-francaise",
    text: "Documents d'archÃ©ologie franÃ§aise (French)",
    parent: "NA",
    urlone:
      "http://www.culture.gouv.fr/culture/dp/daf_archeo/documents_pdf/Recommandations/construire_sa_bibliographie.pdf",
    urltwo: "NA"
  },
  {
    id: "domestic-animal-endocrinology",
    text: "Domestic Animal Endocrinology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "donau-universitat-krems-department-fur-e-governance-in-wirthschaft-und-verwaltung",
    text: "Donau-UniversitÃ¤t Krems - Department fÃ¼r E-Governance in Wirtschaft und Verwaltung (German - Austria)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/files/1540162/Auszug_richitg.zitieren.pdf",
    urltwo: "NA"
  },
  {
    id: "douleurs-evaluation-diagnostic-traitement",
    text: "Douleurs Evaluation - Diagnostic - Traitement",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dramatherapy",
    text: "Dramatherapy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RDRT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "dreaming",
    text: "Dreaming",
    parent: "apa",
    urlone: "http://content.apa.org/journals/drm",
    urltwo: "NA"
  },
  {
    id: "drinking-water-engineering-and-science",
    text: "Drinking Water Engineering and Science",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "drones",
    text: "Drones",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "drug-and-alcohol-dependence",
    text: "Drug and Alcohol Dependence",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "drug-and-therapeutics-bulletin",
    text: "Drug and Therapeutics Bulletin",
    parent: "bmj",
    urlone: "http://dtb.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "drug-delivery-and-translational-research",
    text: "Drug Delivery and Translational Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "drug-development-research",
    text: "Drug Development Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1098-2299/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "drug-discovery-today-disease-mechanisms",
    text: "Drug Discovery Today: Disease Mechanisms",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "drug-discovery-today-disease-models",
    text: "Drug Discovery Today: Disease Models",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "drug-discovery-today-technologies",
    text: "Drug Discovery Today: Technologies",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "drug-discovery-today-therapeutic-strategies",
    text: "Drug Discovery Today: Therapeutic Strategies",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "drug-invention-today",
    text: "Drug Invention Today",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "drug-metabolism-and-disposition",
    text: "Drug Metabolism and Disposition",
    parent: "american-society-for-pharmacology-and-experimental-therapeutics",
    urlone: "http://dmd.aspetjournals.org/site/misc/ifora.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "drug-metabolism-and-pharmacokinetics",
    text: "Drug Metabolism and Pharmacokinetics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "drug-research",
    text: "Drug Research",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "drug-resistance-updates",
    text: "Drug Resistance Updates",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "drug-safety-case-reports",
    text: "Drug Safety - Case Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "drug-safety",
    text: "Drug Safety",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "drug-testing-and-analysis",
    text: "Drug Testing and Analysis",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291942-7611/homepage/ForAuthors.html#Ref",
    urltwo: "NA"
  },
  {
    id: "drugs-and-aging",
    text: "Drugs &amp; Aging",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "drugs-and-therapy-perspectives",
    text: "Drugs &amp; Therapy Perspectives",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "drugs-in-r-and-d",
    text: "Drugs in R&amp;D",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "drugs-of-the-future",
    text: "Drugs of the Future",
    parent: "drugs-of-today",
    urlone: "http://journals.prous.com/journals/servlet/xmlxsl/pk_journals.xml_instauthors_pr?p_JournalID=4",
    urltwo: "NA"
  },
  {
    id: "drugs-of-today",
    text: "Drugs of Today",
    parent: "NA",
    urlone: "http://journals.prous.com/journals/servlet/xmlxsl/pk_journals.xml_instauthors_pr?p_JournalID=4",
    urltwo: "NA"
  },
  {
    id: "drugs-real-world-outcomes",
    text: "Drugs - Real World Outcomes",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "drugs",
    text: "Drugs",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "drying-technology",
    text: "Drying Technology",
    parent: "taylor-and-francis-acs",
    urlone: "https://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ldrt20",
    urltwo: "NA"
  },
  {
    id: "durchstarten-zur-diplomarbeit-in-text",
    text: "Durchstarten zur Diplomarbeit (BHS) (in-text, German)",
    parent: "die-bachelorarbeit-samac-et-al-in-text",
    urlone: "http://www.veritas.at/durchstarten-zur-diplomarbeit-bhs.html",
    urltwo: "NA"
  },
  {
    id: "durchstarten-zur-diplomarbeit-note",
    text: "Durchstarten zur Diplomarbeit (BHS) (note, German)",
    parent: "die-bachelorarbeit-samac-et-al-note",
    urlone: "http://www.veritas.at/durchstarten-zur-diplomarbeit-bhs.html",
    urltwo: "NA"
  },
  {
    id: "durchstarten-zur-vorwissenschaftlichen-arbeit-in-text",
    text: "Durchstarten zur Vorwissenschaftlichen Arbeit (AHS) (in-text, German)",
    parent: "die-bachelorarbeit-samac-et-al-in-text",
    urlone: "http://www.veritas.at/durchstarten-zur-vorwissenschaftlichen-arbeit.html",
    urltwo: "NA"
  },
  {
    id: "durchstarten-zur-vorwissenschaftlichen-arbeit-note",
    text: "Durchstarten zur Vorwissenschaftlichen Arbeit (AHS) (note, German)",
    parent: "die-bachelorarbeit-samac-et-al-note",
    urlone: "http://www.veritas.at/durchstarten-zur-vorwissenschaftlichen-arbeit.html",
    urltwo: "NA"
  },
  {
    id: "dyes-and-pigments",
    text: "Dyes and Pigments",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dynamic-games-and-applications",
    text: "Dynamic Games and Applications",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "dynamical-systems",
    text: "Dynamical Systems",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CDSS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "dynamics-of-atmospheres-and-oceans",
    text: "Dynamics of Atmospheres and Oceans",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "dysphagia",
    text: "Dysphagia",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "e-and-g-quaternary-science-journal",
    text: "E&amp;G Quaternary Science Journal",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "e-and-i-elektrotechnik-und-informationstechnik",
    text: "e &amp; i Elektrotechnik und Informationstechnik (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "e-neuroforum",
    text: "e-Neuroforum",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "e3s-web-of-conferences",
    text: "E3S Web of Conferences",
    parent: "NA",
    urlone: "https://www.e3s-conferences.org/doc_journal/woc/woc_1col.doc",
    urltwo: "NA"
  },
  {
    id: "ear-and-hearing",
    text: "Ear and Hearing",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/eandh/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "early-child-development-and-care",
    text: "Early Child Development and Care",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GECD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "early-childhood-education-journal",
    text: "Early Childhood Education Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "early-childhood-research-quarterly",
    text: "Early Childhood Research Quarterly",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "early-christianity",
    text: "Early Christianity",
    parent: "NA",
    urlone: "https://www.mohr.de/en/journals/early-christianity-ec#menu4",
    urltwo: "NA"
  },
  {
    id: "early-human-development",
    text: "Early Human Development",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "early-medieval-europe",
    text: "Early Medieval Europe",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291468-0254/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "early-popular-visual-culture",
    text: "Early Popular Visual Culture",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REPV20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "early-years",
    text: "Early Years",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEYE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "earth-and-planetary-science-letters",
    text: "Earth and Planetary Science Letters",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "earth-and-space-science",
    text: "Earth and Space Science",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "earth-interactions",
    text: "Earth Interactions",
    parent: "american-meteorological-society",
    urlone: "http://earthinteractions.org/",
    urltwo: "NA"
  },
  {
    id: "earth-moon-and-planets",
    text: "Earth, Moon, and Planets",
    parent: "springer-physics-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "earth-observations-x",
    text: "Earth-Observations-X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "earth-perspectives",
    text: "Earth Perspectives",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "earth-planets-and-space",
    text: "Earth, Planets and Space",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "earth-science-informatics",
    text: "Earth Science Informatics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "earth-science-reviews",
    text: "Earth-Science Reviews",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "earth-surface-dynamics",
    text: "Earth Surface Dynamics",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "earth-surface-processes-and-landforms",
    text: "Earth Surface Processes and Landforms",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1096-9837/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "earth-system-dynamics",
    text: "Earth System Dynamics",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "earth-system-governance",
    text: "Earth System Governance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "earth-system-science-data",
    text: "Earth System Science Data",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "earthquake-engineering-and-structural-dynamics",
    text: "Earthquake Engineering &amp; Structural Dynamics",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1096-9845/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "earthquake-science",
    text: "Earthquake Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "earthquake-spectra",
    text: "Earthquake Spectra",
    parent: "NA",
    urlone: "http://earthquakespectra.org/",
    urltwo: "NA"
  },
  {
    id: "earths-future",
    text: "Earth's Future",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "east-asian-archives-of-psychiatry",
    text: "East Asian Archives of Psychiatry",
    parent: "vancouver-superscript",
    urlone: "http://easap.asia/submission-Other.html",
    urltwo: "NA"
  },
  {
    id: "east-european-jewish-affairs",
    text: "East European Jewish Affairs",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FEEJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "east-european-politics",
    text: "East European Politics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FJCS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "eastern-mediterranean-health-journal",
    text: "Eastern Mediterranean Health Journal",
    parent: "vancouver",
    urlone: "http://www.emro.who.int/emh-journal/authors/",
    urltwo: "NA"
  },
  {
    id: "eating-and-weight-disorders-studies-on-anorexia-bulimia-and-obesity",
    text: "Eating and Weight Disorders - Studies on Anorexia, Bulimia and Obesity",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eating-behaviors",
    text: "Eating Behaviors",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ebiomedicine",
    text: "EBioMedicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecclesial-practices",
    text: "Ecclesial Practices",
    parent: "NA",
    urlone: "http://www.brill.com/sites/default/files/ftp/authors_instructions/EP.pdf",
    urltwo: "NA"
  },
  {
    id: "eclinicalmedicine",
    text: "EClinicalMedicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecography",
    text: "Ecography",
    parent: "oikos",
    urlone: "http://www.ecography.org/authors/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "ecohealth",
    text: "EcoHealth",
    parent: "springer-basic-author-date-no-et-al",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ecohydrology-and-hydrobiology",
    text: "Ecohydrology &amp; Hydrobiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecole-de-technologie-superieure-apa",
    text: "Ã‰cole de technologie supÃ©rieure - APA (French - Canada)",
    parent: "NA",
    urlone: "https://etsmtl.ca/Etudiants-actuels/Cycles-sup/Realisation-etudes/Guides-gabarits/Guide_redaction",
    urltwo: "NA"
  },
  {
    id: "ecole-pratique-des-hautes-etudes-sciences-historiques-et-philologiques",
    text: "Ã‰cole Pratique des Hautes Ã‰tudes - Sciences historiques et philologiques (French)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/2006#issuecomment-206413050",
    urltwo: "NA"
  },
  {
    id: "ecological-applications",
    text: "Ecological Applications",
    parent: "ecology",
    urlone:
      "http://esajournals.onlinelibrary.wiley.com/hub/journal/10.1002/(ISSN)1939-5582/resources/author-guidelines-eap.html",
    urltwo: "NA"
  },
  {
    id: "ecological-complexity",
    text: "Ecological Complexity",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecological-economics",
    text: "Ecological Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecological-engineering",
    text: "Ecological Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecological-entomology",
    text: "Ecological Entomology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2311/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "ecological-genetics-and-genomics",
    text: "Ecological Genetics and Genomics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecological-indicators",
    text: "Ecological Indicators",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecological-informatics",
    text: "Ecological Informatics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecological-modelling",
    text: "Ecological Modelling",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecological-monographs",
    text: "Ecological Monographs",
    parent: "ecology",
    urlone:
      "http://esajournals.onlinelibrary.wiley.com/hub/journal/10.1002/(ISSN)1557-7015/resources/author-guidelines-ecm.html",
    urltwo: "NA"
  },
  {
    id: "ecological-processes",
    text: "Ecological Processes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ecological-research",
    text: "Ecological Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ecological-restoration",
    text: "Ecological Restoration",
    parent: "NA",
    urlone: "https://uwpress.wisc.edu/journals/journals/er_submissions.html",
    urltwo: "NA"
  },
  {
    id: "ecology-and-society",
    text: "Ecology and Society",
    parent: "NA",
    urlone: "http://www.ecologyandsociety.org/about/submissions.php#literaturecited",
    urltwo: "NA"
  },
  {
    id: "ecology-letters",
    text: "Ecology Letters",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291461-0248/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "ecology-of-freshwater-fish",
    text: "Ecology of Freshwater Fish",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1600-0633/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "ecology",
    text: "Ecology",
    parent: "NA",
    urlone: "http://esapubs.org/esapubs/AuthorInstructions.htm",
    urltwo: "NA"
  },
  {
    id: "econometrica",
    text: "Econometrica",
    parent: "NA",
    urlone:
      "https://www.econometricsociety.org/publications/econometrica/information-authors/instructions-preparing-articles-publication",
    urltwo: "NA"
  },
  {
    id: "econometrics-and-statistics",
    text: "Econometrics and Statistics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "econometrics",
    text: "Econometrics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "economia-politica",
    text: "Economia Politica",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "economia-y-politica",
    text: "EconomÃ­a y PolÃ­tica (Spanish - Chile)",
    parent: "NA",
    urlone: "http://www.economiaypolitica.cl/index.php/eyp/Normas-para-autores",
    urltwo: "NA"
  },
  {
    id: "economia",
    text: "EconomiA",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "economic-analysis-and-policy",
    text: "Economic Analysis and Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "economic-change-and-restructuring",
    text: "Economic Change and Restructuring",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "economic-commission-for-latin-america-and-the-caribbean",
    text: "Economic Commission for Latin America and the Caribbean",
    parent: "NA",
    urlone: "http://www.eclac.org",
    urltwo: "NA"
  },
  {
    id: "economic-geology",
    text: "Economic Geology",
    parent: "NA",
    urlone: "http://www.editorialmanager.com/seg/default.aspx",
    urltwo: "NA"
  },
  {
    id: "economic-modelling",
    text: "Economic Modelling",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "economic-systems",
    text: "Economic Systems",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "economic-theory-bulletin",
    text: "Economic Theory Bulletin",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "economic-theory",
    text: "Economic Theory",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "economics-and-human-biology",
    text: "Economics and Human Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "economics-letters",
    text: "Economics Letters",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "economics-of-education-review",
    text: "Economics of Education Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "economics-of-governance",
    text: "Economics of Governance",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "economics-of-innovation-and-new-technology",
    text: "Economics of Innovation and New Technology",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GEIN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "economics-of-transportation",
    text: "Economics of Transportation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "economie-et-statistique",
    text: "Ã‰conomie et Statistique (French)",
    parent: "NA",
    urlone: "http://www.insee.fr/fr/publications-et-services/collection.asp?id=2",
    urltwo: "NA"
  },
  {
    id: "economies",
    text: "Economies",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "ecosal-plus",
    text: "EcoSal Plus",
    parent: "american-society-for-microbiology",
    urlone: "http://www.asmscience.org/about/ecosalplus-authors",
    urltwo: "NA"
  },
  {
    id: "ecoscience",
    text: "Ã‰coscience",
    parent: "NA",
    urlone: "http://www.ecoscience.ulaval.ca/en",
    urltwo: "NA"
  },
  {
    id: "ecosistemas",
    text: "Ecosistemas (Spanish)",
    parent: "NA",
    urlone: "http://www.revistaecosistemas.net/public/journals/1/docs/Normasparaautores_RevistaEcosistemas.pdf",
    urltwo: "http://www.revistaecosistemas.net/index.php/ecosistemas/about/submissions#authorGuidelines"
  },
  {
    id: "ecosphere",
    text: "Ecosphere",
    parent: "ecology",
    urlone:
      "http://esajournals.onlinelibrary.wiley.com/hub/journal/10.1002/(ISSN)2150-8925/resources/author-guidelines-ECS2.html",
    urltwo: "NA"
  },
  {
    id: "ecosystem-health-and-sustainability",
    text: "Ecosystem Health and Sustainability",
    parent: "ecology",
    urlone:
      "http://esajournals.onlinelibrary.wiley.com/hub/journal/10.1002/(ISSN)2332-8878/resources/author-guidelines-EHS2.html",
    urltwo: "NA"
  },
  {
    id: "ecosystem-services",
    text: "Ecosystem Services",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecosystems",
    text: "Ecosystems",
    parent: "NA",
    urlone: "http://www.springer.com/life+sciences/ecology/journal/10021?detailsPage=pltci_721557#id54",
    urltwo: "NA"
  },
  {
    id: "ecotoxicology-and-environmental-safety",
    text: "Ecotoxicology and Environmental Safety",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ecotoxicology",
    text: "Ecotoxicology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "educacao-sociedade-e-culturas",
    text: "EducaÃ§Ã£o, Sociedade e Culturas",
    parent: "universidade-do-porto-faculdade-de-psicologia-e-de-ciencias-da-educacao",
    urlone:
      "https://www.fpce.up.pt/ciie/?q=publication/revista-educa%C3%A7%C3%A3o-sociedade-culturas/page/instrucoes-aos-autores",
    urltwo: "NA"
  },
  {
    id: "educacion-medica-superior",
    text: "EducaciÃ³n MÃ©dica Superior",
    parent: "vancouver",
    urlone: "http://scielo.sld.cu/scielo.php?script=sci_serial&amp;pid=0864-2141",
    urltwo: "NA"
  },
  {
    id: "educacion-medica",
    text: "EducaciÃ³n MÃ©dica (Spanish)",
    parent: "medicina-clinica",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "educacion-quimica",
    text: "EducaciÃ³n QuÃ­mica",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "education-3-13",
    text: "Education 3-13",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RETT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "education-and-information-technologies",
    text: "Education and Information Technologies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "education-and-practice",
    text: "Education &amp; Practice",
    parent: "bmj",
    urlone: "http://ep.bmj.com",
    urltwo: "NA"
  },
  {
    id: "education-economics",
    text: "Education Economics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEDE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "education-for-chemical-engineers",
    text: "Education for Chemical Engineers",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "education-sciences",
    text: "Education Sciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "educational-action-research",
    text: "Educational Action Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REAC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "educational-assessment-evaluation-and-accountability",
    text: "Educational Assessment, Evaluation and Accountability",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "educational-evaluation-and-policy-analysis",
    text: "Educational Evaluation and Policy Analysis",
    parent: "apa",
    urlone: "http://epa.sagepub.com/",
    urltwo: "NA"
  },
  {
    id: "educational-media-international",
    text: "Educational Media International",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REMI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "educational-philosophy-and-theory",
    text: "Educational Philosophy and Theory",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REPT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "educational-psychology-in-practice",
    text: "Educational Psychology in Practice",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEPP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "educational-psychology-review",
    text: "Educational Psychology Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "educational-psychology",
    text: "Educational Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEDP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "educational-research-and-evaluation",
    text: "Educational Research and Evaluation",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NERE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "educational-research-for-policy-and-practice",
    text: "Educational Research for Policy and Practice",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "educational-research-review",
    text: "Educational Research Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "educational-research",
    text: "Educational Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RERE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "educational-researcher",
    text: "Educational Researcher",
    parent: "apa",
    urlone: "http://edr.sagepub.com/",
    urltwo: "NA"
  },
  {
    id: "educational-review",
    text: "Educational Review",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEDR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "educational-studies-in-mathematics",
    text: "Educational Studies in Mathematics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "educational-studies",
    text: "Educational Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEDS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "educational-technology-research-and-development",
    text: "Educational Technology Research and Development",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "edward-elgar-business-and-social-sciences",
    text: "Edward Elgar Publishing - Business and Social Sciences",
    parent: "NA",
    urlone: "https://www.e-elgar.com/sites/e-elgar/files/images/shared/authoredguidebussocsci20151.pdf",
    urltwo: "NA"
  },
  {
    id: "efficacy-and-mechanism-evaluation",
    text: "Efficacy and Mechanism Evaluation",
    parent: "national-institute-of-health-research",
    urlone: "http://www.journalslibrary.nihr.ac.uk/information-for-authors/references",
    urltwo: "NA"
  },
  {
    id: "egretta",
    text: "Egretta (German - Austria)",
    parent: "NA",
    urlone: "https://www.zobodat.at/zeitschriftinfos/egretta_aut.pdf",
    urltwo: "NA"
  },
  {
    id: "egyptian-informatics-journal",
    text: "Egyptian Informatics Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "egyptian-journal-of-anaesthesia",
    text: "Egyptian Journal of Anaesthesia",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "egyptian-journal-of-basic-and-applied-sciences",
    text: "Egyptian Journal of Basic and Applied Sciences",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "egyptian-journal-of-chest-diseases-and-tuberculosis",
    text: "Egyptian Journal of Chest Diseases and Tuberculosis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "egyptian-journal-of-ear-nose-throat-and-allied-sciences",
    text: "Egyptian Journal of Ear, Nose, Throat and Allied Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "egyptian-journal-of-medical-human-genetics",
    text: "Egyptian Journal of Medical Human Genetics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "egyptian-journal-of-petroleum",
    text: "Egyptian Journal of Petroleum",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "egyptian-pediatric-association-gazette",
    text: "Egyptian Pediatric Association Gazette",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "eighteenth-century-life",
    text: "Eighteenth-Century Life",
    parent: "chicago-note-bibliography",
    urlone: "http://ecl.dukejournals.org/",
    urltwo: "NA"
  },
  {
    id: "einaudi",
    text: "Einaudi (Italian)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ejc-supplements",
    text: "EJC Supplements",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ejnmmi-physics",
    text: "EJNMMI Physics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ejnmmi-radiopharmacy-and-chemistry",
    text: "EJNMMI Radiopharmacy and Chemistry",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ejnmmi-research",
    text: "EJNMMI Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ekonomie-a-management",
    text: "Ekonomie a Management",
    parent: "apa",
    urlone: "http://www.ekonomie-management.cz/en/detail-instructions-for-authors/",
    urltwo: "NA"
  },
  {
    id: "eksploatacja-i-niezawodnosc",
    text: "Eksploatacja i NiezawodnoÅ›Ä‡ (Maintenance and Reliability) (English)",
    parent: "NA",
    urlone: "http://www.ein.org.pl/en-information_for_authors",
    urltwo: "NA"
  },
  {
    id: "el-profesional-de-la-informacion",
    text: "El profesional de la informaciÃ³n (Spanish)",
    parent: "NA",
    urlone: "http://www.elprofesionaldelainformacion.com/autores.html#435",
    urltwo: "NA"
  },
  {
    id: "electoral-studies",
    text: "Electoral Studies",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "electric-power-systems-research",
    text: "Electric Power Systems Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "electrical-engineering",
    text: "Electrical Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "electrocatalysis",
    text: "Electrocatalysis",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "electrochemistry-communications",
    text: "Electrochemistry Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "electrochimica-acta",
    text: "Electrochimica Acta",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "electronic-commerce-research-and-applications",
    text: "Electronic Commerce Research and Applications",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "electronic-commerce-research",
    text: "Electronic Commerce Research",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "electronic-journal-of-biotechnology",
    text: "Electronic Journal of Biotechnology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "electronic-journal-of-statistics",
    text: "Electronic Journal of Statistics",
    parent: "institute-of-mathematical-statistics",
    urlone: "https://imstat.org/journals-and-publications/electronic-journal-of-statistics/",
    urltwo: "NA"
  },
  {
    id: "electronic-markets",
    text: "Electronic Markets",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "electronic-notes-in-discrete-mathematics",
    text: "Electronic Notes in Discrete Mathematics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "electronic-notes-in-theoretical-computer-science",
    text: "Electronic Notes in Theoretical Computer Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "electronic-structure",
    text: "Electronic Structure",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "electronics-letters",
    text: "Electronics Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=2220",
    urltwo: "NA"
  },
  {
    id: "electronics",
    text: "Electronics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "electrophoresis",
    text: "Electrophoresis",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/15222683/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "elementa",
    text: "Elementa",
    parent: "NA",
    urlone: "https://home.elementascience.org/for-authors/style-guide/",
    urltwo: "http://lynn-library.libguides.com/cse"
  },
  {
    id: "elife",
    text: "eLife",
    parent: "NA",
    urlone: "https://submit.elifesciences.org/html/eLife_Author_Guide.pdf",
    urltwo: "NA"
  },
  {
    id: "elsevier-harvard-without-titles",
    text: "Elsevier - Harvard (without titles)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/biosensors-and-bioelectronics/0956-5663/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "elsevier-harvard",
    text: "Elsevier - Harvard (with titles)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/biological-conservation/0006-3207/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "elsevier-harvard2",
    text: "Elsevier - Harvard 2",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/drug-and-alcohol-dependence/0376-8716/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "elsevier-vancouver-author-date",
    text: "Elsevier - Vancouver (author-date)",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/clinical-neurophysiology/1388-2457/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "elsevier-vancouver",
    text: "Elsevier - Vancouver",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/energy/0360-5442/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "elsevier-with-titles-alphabetical",
    text: "Elsevier (numeric, with titles, sorted alphabetically)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/cryobiology/0011-2240/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "elsevier-with-titles",
    text: "Elsevier (numeric, with titles)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-hazardous-materials/0304-3894/guide-for-authors#68001",
    urltwo: "NA"
  },
  {
    id: "elsevier-without-titles",
    text: "Elsevier (numeric, without titles)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/the-journal-of-chemical-thermodynamics/0021-9614/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "embnet-journal",
    text: "EMBnet.journal",
    parent: "NA",
    urlone: "http://journal.embnet.org/index.php/embnetjournal/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "embo-press",
    text: "EMBO Press",
    parent: "NA",
    urlone: "https://www.embopress.org/page/journal/14602075/authorguide#referencesformat",
    urltwo: "NA"
  },
  {
    id: "embo-reports",
    text: "EMBO reports",
    parent: "embo-press",
    urlone: "https://www.embopress.org/page/journal/14693178/authorguide#referencesformat",
    urltwo: "NA"
  },
  {
    id: "emerald-harvard",
    text: "Emerald - Harvard",
    parent: "NA",
    urlone: "https://www.emeraldgrouppublishing.com/use-harvard-reference-system",
    urltwo: "https://www.emeraldgrouppublishing.com/journal/jmp?id=jmp#author-guidelines"
  },
  {
    id: "emergency-medicine-journal",
    text: "Emergency Medicine Journal",
    parent: "bmj",
    urlone: "http://emj.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "emergency-radiology",
    text: "Emergency Radiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "emerging-contaminants",
    text: "Emerging Contaminants",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "emerging-infectious-diseases",
    text: "Emerging Infectious Diseases",
    parent: "vancouver",
    urlone: "http://wwwnc.cdc.gov/eid/pages/manuscript-preparation.htm",
    urltwo: "NA"
  },
  {
    id: "emerging-markets-review",
    text: "Emerging Markets Review",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "emerging-microbes-and-infections",
    text: "Emerging Microbes &amp; Infections",
    parent: "european-journal-of-human-genetics",
    urlone: "http://mts-emi.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "emerging-themes-in-epidemiology",
    text: "Emerging Themes in Epidemiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "emission-control-science-and-technology",
    text: "Emission Control Science and Technology",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "emotion-space-and-society",
    text: "Emotion, Space and Society",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "emotion",
    text: "Emotion",
    parent: "apa",
    urlone: "http://content.apa.org/journals/emo",
    urltwo: "NA"
  },
  {
    id: "emotional-and-behavioural-difficulties",
    text: "Emotional and Behavioural Difficulties",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REBD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "empirica",
    text: "Empirica",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "empirical-economics",
    text: "Empirical Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "empirical-research-in-vocational-education-and-training",
    text: "Empirical Research in Vocational Education and Training",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "empirical-software-engineering",
    text: "Empirical Software Engineering",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "employee-responsibilities-and-rights-journal",
    text: "Employee Responsibilities and Rights Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "emu-austral-ornithology",
    text: "Emu - Austral Ornithology",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=temu20&amp;page=instructions#References",
    urltwo: "NA"
  },
  {
    id: "endangered-species-research",
    text: "Endangered Species Research",
    parent: "inter-research-science-center",
    urlone: "http://www.int-res.com/journals/esr/esr-home/",
    urltwo: "NA"
  },
  {
    id: "endeavour-college-of-natural-health",
    text: "Endeavour College of Natural Health",
    parent: "university-of-south-australia-harvard-2013",
    urlone: "http://docs.endeavour.edu.au/Public_Documents/Library/Information_Literacy/Reference_Citation_Guide.pdf",
    urltwo: "NA"
  },
  {
    id: "endeavour",
    text: "Endeavour",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "endo-praxis",
    text: "Endo-Praxis",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "endocrine-disruptors",
    text: "Endocrine Disruptors",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "endocrine-press",
    text: "Endocrine Press",
    parent: "NA",
    urlone: "http://press.endocrine.org/page/authors#mozTocId713371",
    urltwo: "NA"
  },
  {
    id: "endocrine-related-cancer",
    text: "Endocrine-Related Cancer",
    parent: "journal-of-molecular-endocrinology",
    urlone: "http://erc.endocrinology-journals.org/site/misc/For-Authors.xhtml#refs",
    urltwo: "NA"
  },
  {
    id: "endocrine-reviews",
    text: "Endocrine Reviews",
    parent: "endocrine-press",
    urlone: "http://press.endocrine.org/page/authors#mozTocId713371",
    urltwo: "NA"
  },
  {
    id: "endocrine",
    text: "Endocrine",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "endocrinologia-y-nutricion",
    text: "EndocrinologÃ­a y NutriciÃ³n (Spanish)",
    parent: "medicina-clinica",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "endocrinology",
    text: "Endocrinology",
    parent: "endocrine-press",
    urlone: "http://press.endocrine.org/page/authors#mozTocId713371",
    urltwo: "NA"
  },
  {
    id: "endokrinologie-informationen",
    text: "Endokrinologie Informationen",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "endoscopia",
    text: "Endoscopia (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/endoscopia/0188-9893/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "endoscopy-international-open",
    text: "Endoscopy International Open",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "endoscopy",
    text: "Endoscopy",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "energies",
    text: "Energies",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "energy-and-buildings",
    text: "Energy &amp; Buildings",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-and-environmental-science",
    text: "Energy &amp; Environmental Science",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-and-fuels",
    text: "Energy &amp; Fuels",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/enfuem/enfuem_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "energy-conversion-and-management-x",
    text: "Energy Conversion and Management: X",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-conversion-and-management",
    text: "Energy Conversion and Management",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-ecology-and-environment",
    text: "Energy, Ecology and Environment",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "energy-economics",
    text: "Energy Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-efficiency",
    text: "Energy Efficiency",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "energy-for-sustainable-development",
    text: "Energy for Sustainable Development",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-policy",
    text: "Energy Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-reports",
    text: "Energy Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-research-and-social-science",
    text: "Energy Research &amp; Social Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-storage-materials",
    text: "Energy Storage Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-strategy-reviews",
    text: "Energy Strategy Reviews",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "energy-sustainability-and-society",
    text: "Energy, Sustainability and Society",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "energy-systems",
    text: "Energy Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "energy",
    text: "Energy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "eneuro",
    text: "eNeuro",
    parent: "NA",
    urlone: "http://eneuro.org/information-for-authors#references",
    urltwo: "NA"
  },
  {
    id: "eneurologicalsci",
    text: "eNeurologicalSci",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "enfermedades-infecciosas-y-microbiologia-clinica",
    text: "Enfermedades Infecciosas y MicrobiologÃ­a ClÃ­nica (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.es/es/revistas/enfermedades-infecciosas-microbiologia-clinica-28",
    urltwo: "NA"
  },
  {
    id: "enfermeria-clinica",
    text: "EnfermerÃ­a ClÃ­nica (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/enfermeria-clinica/1130-8621/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "enfermeria-intensiva",
    text: "EnfermerÃ­a Intensiva (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/enfermeria-intensiva/1130-2399/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "engineering-analysis-with-boundary-elements",
    text: "Engineering Analysis with Boundary Elements",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "engineering-and-technology",
    text: "Engineering &amp; Technology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=10908",
    urltwo: "NA"
  },
  {
    id: "engineering-applications-of-artificial-intelligence",
    text: "Engineering Applications of Artificial Intelligence",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "engineering-failure-analysis",
    text: "Engineering Failure Analysis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "engineering-fracture-mechanics",
    text: "Engineering Fracture Mechanics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "engineering-geology-special-publications",
    text: "Engineering Geology Special Publications",
    parent: "the-geological-society-of-london",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "engineering-geology",
    text: "Engineering Geology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "engineering-in-agriculture-environment-and-food",
    text: "Engineering in Agriculture, Environment and Food",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "engineering-in-life-sciences",
    text: "Engineering in Life Sciences",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1618-2863/homepage/2129_guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "engineering-management-journal",
    text: "Engineering Management Journal",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=2221",
    urltwo: "NA"
  },
  {
    id: "engineering-optimization",
    text: "Engineering Optimization",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GENO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "engineering-research-express",
    text: "Engineering Research Express",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "engineering-science-and-technology-an-international-journal",
    text: "Engineering Science and Technology, an International Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "engineering-structures",
    text: "Engineering Structures",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "engineering-technology-and-applied-science-research",
    text: "Engineering, Technology &amp; Applied Science Research",
    parent: "NA",
    urlone: "http://etasr.com/ETASRTEMPLATE.docx",
    urltwo: "NA"
  },
  {
    id: "engineering-with-computers",
    text: "Engineering with Computers",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "engineering",
    text: "Engineering",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ens-de-lyon-centre-d-ingenierie-documentaire",
    text: "ENS de Lyon - Centre d'ingÃ©nierie documentaire (French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ensayos-sobre-politica-economica",
    text: "Ensayos sobre PolÃ­tica EconÃ³mica",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "entecho",
    text: "ENTECHO (Czech)",
    parent: "NA",
    urlone: "https://www.entecho.cz/cs-cz/o-casopisu/pokyny-pro-autory",
    urltwo: "NA"
  },
  {
    id: "enterprise-information-systems",
    text: "Enterprise Information Systems",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TEIS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "entertainment-computing",
    text: "Entertainment Computing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "entomologia-experimentalis-et-applicata",
    text: "Entomologia Experimentalis et Applicata",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291570-7458/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "entomological-society-of-america",
    text: "Entomological Society of America",
    parent: "NA",
    urlone: "http://www.entsoc.org/pubs/publish/style",
    urltwo: "NA"
  },
  {
    id: "entrepreneurship-and-regional-development",
    text: "Entrepreneurship &amp; Regional Development",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TEPN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "entrepreneurship-theory-and-practice",
    text: "Entrepreneurship Theory and Practice",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291540-6520/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "entropy",
    text: "Entropy",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "environment-and-planning-a",
    text: "Environment and Planning A",
    parent: "environment-and-planning",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environment-and-planning-b-planning-and-design",
    text: "Environment and Planning B, Planning &amp; Design",
    parent: "environment-and-planning",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environment-and-planning-c-government-and-policy",
    text: "Environment and Planning C, Government &amp; Policy",
    parent: "environment-and-planning",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environment-and-planning-d-society-and-space",
    text: "Environment and Planning D, Society &amp; Space",
    parent: "environment-and-planning",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environment-and-planning",
    text: "Environment and Planning",
    parent: "NA",
    urlone: "http://www.envplan.com/ref_examples.html",
    urltwo: "NA"
  },
  {
    id: "environment-and-urbanization",
    text: "Environment and Urbanization",
    parent: "NA",
    urlone: "https://us.sagepub.com/en-us/nam/journal/environment-and-urbanization#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "environment-development-and-sustainability",
    text: "Environment, Development and Sustainability",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environment-international",
    text: "Environment International",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environment-systems-and-decisions",
    text: "Environment Systems and Decisions",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-and-ecological-statistics",
    text: "Environmental and Ecological Statistics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-and-engineering-geoscience",
    text: "Environmental &amp; Engineering Geoscience",
    parent: "NA",
    urlone: "http://eeg.allentrack.net/html/eeginstr.pdf",
    urltwo: "NA"
  },
  {
    id: "environmental-and-experimental-botany",
    text: "Environmental and Experimental Botany",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-and-planning-law-journal",
    text: "Environmental and Planning Law Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-and-resource-economics",
    text: "Environmental and Resource Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-biology-of-fishes",
    text: "Environmental Biology of Fishes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-chemistry-letters",
    text: "Environmental Chemistry Letters",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-chemistry",
    text: "Environmental Chemistry",
    parent: "NA",
    urlone: "http://www.publish.csiro.au/en/forauthors/AuthorInstructions#27",
    urltwo: "NA"
  },
  {
    id: "environmental-communication-a-journal-of-nature-and-culture",
    text: "Environmental Communication: A Journal of Nature and Culture",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RENC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "environmental-conservation",
    text: "Environmental Conservation",
    parent: "NA",
    urlone: "http://assets.cambridge.org/ENC/ENC_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "environmental-development",
    text: "Environmental Development",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-earth-sciences",
    text: "Environmental Earth Sciences",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-economics-and-policy-studies",
    text: "Environmental Economics and Policy Studies",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-education-research",
    text: "Environmental Education Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEER20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "environmental-entomology",
    text: "Environmental Entomology",
    parent: "entomological-society-of-america",
    urlone: "http://www.entsoc.org/pubs/publish/style",
    urltwo: "NA"
  },
  {
    id: "environmental-evidence",
    text: "Environmental Evidence",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-fluid-mechanics",
    text: "Environmental Fluid Mechanics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-geochemistry-and-health",
    text: "Environmental Geochemistry and Health",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-geosciences",
    text: "Environmental Geosciences",
    parent: "american-association-of-petroleum-geologists",
    urlone: "http://deg.aapg.org/Portals/0/instructions-1.pdf",
    urltwo: "NA"
  },
  {
    id: "environmental-hazards",
    text: "Environmental Hazards",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TENH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "environmental-health-and-preventive-medicine",
    text: "Environmental Health and Preventive Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-health-perspectives",
    text: "Environmental Health Perspectives",
    parent: "NA",
    urlone: "https://ehp.niehs.nih.gov/manuscript-style/",
    urltwo: "NA"
  },
  {
    id: "environmental-health",
    text: "Environmental Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-impact-assessment-review",
    text: "Environmental Impact Assessment Review",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-innovation-and-societal-transitions",
    text: "Environmental Innovation and Societal Transitions",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-management",
    text: "Environmental Management",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-microbiology",
    text: "Environmental Microbiology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291462-2920/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "environmental-modeling-and-assessment",
    text: "Environmental Modeling &amp; Assessment",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-modelling-and-software",
    text: "Environmental Modelling and Software",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-monitoring-and-assessment",
    text: "Environmental Monitoring and Assessment",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-nanotechnology-monitoring-and-management",
    text: "Environmental Nanotechnology, Monitoring &amp; Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-pollution",
    text: "Environmental Pollution",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-processes",
    text: "Environmental Processes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-research-communications",
    text: "Environmental Research Communications",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "environmental-research-letters",
    text: "Environmental Research Letters",
    parent: "institute-of-physics-harvard",
    urlone: "http://iopscience.iop.org/1748-9326",
    urltwo: "NA"
  },
  {
    id: "environmental-research",
    text: "Environmental Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-reviews",
    text: "Environmental Reviews",
    parent: "canadian-journal-of-fisheries-and-aquatic-sciences",
    urlone: "http://www.nrcresearchpress.com/page/er/authors",
    urltwo: "NA"
  },
  {
    id: "environmental-science-and-policy",
    text: "Environmental Science and Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-science-and-pollution-research",
    text: "Environmental Science and Pollution Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-science-and-technology-letters",
    text: "Environmental Science &amp; Technology Letters",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/estlcu/estlcu_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "environmental-science-and-technology",
    text: "Environmental Science &amp; Technology",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/esthag/esthag_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "environmental-science-nano",
    text: "Environmental Science: Nano",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-science-processes-and-impacts",
    text: "Environmental Science: Processes &amp; Impacts",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-science-water-research-and-technology",
    text: "Environmental Science: Water Research &amp; Technology",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-sciences-europe",
    text: "Environmental Sciences Europe",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-systems-research",
    text: "Environmental Systems Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "environmental-technology-and-innovation",
    text: "Environmental Technology &amp; Innovation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-technology-reviews",
    text: "Environmental Technology Reviews",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TETR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "environmental-technology",
    text: "Environmental Technology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TENT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "environmental-toxicology-and-chemistry",
    text: "Environmental Toxicology and Chemistry",
    parent: "council-of-science-editors-author-date",
    urlone: "https://setac.onlinelibrary.wiley.com/hub/journal/15528618/author-guidelines",
    urltwo:
      "https://setac.onlinelibrary.wiley.com/pb-assets/assets/page/journal/15528618/_ETC%20Manuscript%20Preparation_April_2019-1555965964903.pdf"
  },
  {
    id: "environmental-toxicology-and-pharmacology",
    text: "Environmental Toxicology and Pharmacology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "environmental-values",
    text: "Environmental Values",
    parent: "NA",
    urlone: "http://www.whpress.co.uk/EV/EVinst.html",
    urltwo: "NA"
  },
  {
    id: "environments",
    text: "Environments",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "environnement-risques-et-sante",
    text: "Environnement, Risques &amp; SantÃ©",
    parent: "NA",
    urlone: "http://www.jle.com/fr/revues/ers/espace_auteur",
    urltwo: "NA"
  },
  {
    id: "enzyme-and-microbial-technology",
    text: "Enzyme and Microbial Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ephemerides-theologicae-lovanienses",
    text: "Ephemerides Theologicae Lovanienses",
    parent: "NA",
    urlone: "http://www.peeters-leuven.be/pdf/instructionsETL.pdf",
    urltwo: "NA"
  },
  {
    id: "epidemics",
    text: "Epidemics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "epidemiologie-et-sante-animale",
    text: "Ã‰pidÃ©miologie et SantÃ© Animale",
    parent: "NA",
    urlone: "http://aeema.vet-alfort.fr/public/php/instructions.php",
    urltwo: "NA"
  },
  {
    id: "epidemiology-and-infection",
    text: "Epidemiology &amp; Infection",
    parent: "NA",
    urlone:
      "https://www.cambridge.org/core/journals/epidemiology-and-infection/information/instructions-contributors#references",
    urltwo: "NA"
  },
  {
    id: "epidemiology",
    text: "Epidemiology",
    parent: "american-medical-association",
    urlone: "http://edmgr.ovid.com/epid/accounts/ifauth.htm#3",
    urltwo: "NA"
  },
  {
    id: "epigenetics-and-chromatin",
    text: "Epigenetics &amp; Chromatin",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "epigenetics",
    text: "Epigenetics",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "epigenomes",
    text: "Epigenomes",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "epigenomics",
    text: "Epigenomics",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/epi",
    urltwo: "NA"
  },
  {
    id: "epilepsia",
    text: "Epilepsia",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1528-1167/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "epilepsy-and-behavior-case-reports",
    text: "Epilepsy &amp; Behavior Case Reports",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "epilepsy-and-behavior",
    text: "Epilepsy &amp; Behavior",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "epilepsy-research",
    text: "Epilepsy Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "epileptology",
    text: "Epileptology",
    parent: "vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "epj-data-science",
    text: "EPJ Data Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "epj-nonlinear-biomedical-physics",
    text: "EPJ Nonlinear Biomedical Physics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "epj-quantum-technology",
    text: "EPJ Quantum Technology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "epj-techniques-and-instrumentation",
    text: "EPJ Techniques and Instrumentation",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "epl",
    text: "EPL",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0295-5075",
    urltwo: "NA"
  },
  {
    id: "epma-journal",
    text: "EPMA Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "equine-veterinary-education",
    text: "Equine Veterinary Education",
    parent: "NA",
    urlone: "https://beva.onlinelibrary.wiley.com/hub/journal/20423292/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "equine-veterinary-journal",
    text: "Equine Veterinary Journal",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1001/(ISSN)2042-3306/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "era-forum",
    text: "ERA Forum",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ergo",
    text: "Ergo",
    parent: "NA",
    urlone: "https://www.ergophiljournal.org/submit.html",
    urltwo: "NA"
  },
  {
    id: "ergonomics",
    text: "Ergonomics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=terg20&amp;page=instructions#mp_style",
    urltwo: "NA"
  },
  {
    id: "ergoscience",
    text: "ergoscience",
    parent: "NA",
    urlone: "http://www.schulz-kirchner.de/ergotherapie/autorenhinweis_ergoscience.htm",
    urltwo: "NA"
  },
  {
    id: "erkenntnis",
    text: "Erkenntnis",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "errata",
    text: "Errata: PublicaÃ§Ã£o sobre processos editoriais (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://errata.com.br/errata/about/submissions",
    urltwo: "NA"
  },
  {
    id: "erwerbs-obstbau",
    text: "Erwerbs-Obstbau (German)",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "escuela-nacional-de-antropologia-e-historia-author-date",
    text: "Escuela Nacional de AntropologÃ­a e Historia (autor-fecha) (Spanish - Mexico)",
    parent: "NA",
    urlone: "http://www.enah.edu.mx/index.php/difu-cul/publicaciones/normas-editoriales.pdf",
    urltwo: "NA"
  },
  {
    id: "escuela-nacional-de-antropologia-e-historia-full-note",
    text: "Escuela Nacional de AntropologÃ­a e Historia (nota completa) (Spanish - Mexico)",
    parent: "NA",
    urlone: "http://www.enah.edu.mx/index.php/difu-cul/publicaciones/normas-editoriales.pdf",
    urltwo: "NA"
  },
  {
    id: "escuela-nacional-de-antropologia-e-historia-short-note",
    text: "Escuela Nacional de AntropologÃ­a e Historia (nota corta) (Spanish - Mexico)",
    parent: "NA",
    urlone: "http://www.enah.edu.mx/index.php/difu-cul/publicaciones/normas-editoriales.pdf",
    urltwo: "NA"
  },
  {
    id: "esophagus",
    text: "Esophagus",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "estuaries-and-coasts",
    text: "Estuaries and Coasts",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "estuarine-coastal-and-shelf-science",
    text: "Estuarine, Coastal and Shelf Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "estudios-de-cultura-maya",
    text: "Estudios de Cultura Maya (Spanish - Mexico)",
    parent: "NA",
    urlone:
      "https://revistas-filologicas.unam.mx/estudios-cultura-maya/index.php/ecm/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "estudios-de-fonetica-experimental",
    text: "Estudios de FonÃ©tica Experimental",
    parent: "NA",
    urlone: "http://stel.ub.edu/labfon/en/content/publication-estudios-de-fonetica-experimental#Normes",
    urltwo: "NA"
  },
  {
    id: "estudios-de-historia-moderna-y-contemporanea-de-mexico",
    text: "Estudios de Historia Moderna y ContemporÃ¡nea de MÃ©xico",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "estudios-de-historia-novohispana",
    text: "Estudios de Historia Novohispana",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "estudios-gerenciales",
    text: "Estudios Gerenciales",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "estudios-hispanicos",
    text: "Estudios HispÃ¡nicos (Spanish)",
    parent: "NA",
    urlone: "http://www.ifr.uni.wroc.pl/es/normas-para-la-presentacion",
    urltwo: "NA"
  },
  {
    id: "ethical-theory-and-moral-practice",
    text: "Ethical Theory and Moral Practice",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ethics-and-education",
    text: "Ethics and Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEAE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "ethics-and-information-technology",
    text: "Ethics and Information Technology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ethics-and-social-welfare",
    text: "Ethics and Social Welfare",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RESW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "ethics-book-reviews",
    text: "Ethics (for book reviews)",
    parent: "NA",
    urlone: "http://www.press.uchicago.edu/ucp/journals/journal/et.html",
    urltwo: "http://dl.dropbox.com/u/23387469/StyleSheetForBookReviews.pdf"
  },
  {
    id: "ethics-in-biology-engineering-and-medicine-an-international-journal",
    text: "Ethics in Biology, Engineering and Medicine: An International Journal",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/6ed509641f7324e6.html",
    urltwo: "NA"
  },
  {
    id: "ethics-in-science-and-environmental-politics",
    text: "Ethics in Science and Environmental Politics",
    parent: "inter-research-science-center",
    urlone: "http://www.int-res.com/journals/esep/esep-home/",
    urltwo: "NA"
  },
  {
    id: "ethics-medicine-and-public-health",
    text: "Ethics, Medicine and Public Health",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ethik-in-der-medizin",
    text: "Ethik in der Medizin (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ethique-et-sante",
    text: "Ethique et SantÃ©",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ethnic-and-racial-studies",
    text: "Ethnic and Racial Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RERS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "ethnicity-and-health",
    text: "Ethnicity &amp; Health",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CETH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "ethnobiology-and-conservation",
    text: "Ethnobiology and Conservation",
    parent: "NA",
    urlone: "http://www.ethnobioconservation.com/index.php/ebc/pages/view/guidelines",
    urltwo: "NA"
  },
  {
    id: "ethnography-and-education",
    text: "Ethnography and Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REAE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "ethnologie-francaise",
    text: "Ethnologie franÃ§aise (French)",
    parent: "NA",
    urlone: "http://www.puf.com/puf_wiki/images/e/e3/Charte_Ethnologie_fran%C3%A7aise.pdf",
    urltwo: "NA"
  },
  {
    id: "ethnomusicology",
    text: "Ethnomusicology",
    parent: "NA",
    urlone: "http://www.ethnomusicology.org/?Pub_JournalAuthors",
    urltwo: "NA"
  },
  {
    id: "etransportation",
    text: "eTransportation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ets-ecole-de-technologie-superieure",
    text: "Ã‰TS - Ã‰cole de technologie supÃ©rieure (French - Canada)",
    parent: "NA",
    urlone: "http://etsmtl.ca/Etudiants-actuels/Cycles-sup/Realisation-etudes/Guides-gabarits/Guide_redaction",
    urltwo: "NA"
  },
  {
    id: "eukaryotic-cell",
    text: "Eukaryotic Cell",
    parent: "american-society-for-microbiology",
    urlone: "http://ec.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "eupa-open-proteomics",
    text: "EuPA Open Proteomics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "euphytica",
    text: "Euphytica",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurasian-business-review",
    text: "Eurasian Business Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurasian-economic-review",
    text: "Eurasian Economic Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurasian-geography-and-economics",
    text: "Eurasian Geography and Economics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REGE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "eurasian-journal-of-medical-investigation",
    text: "Eurasian Journal of Medical Investigation",
    parent: "NA",
    urlone: "http://www.ejmi.org/Instructions-for-Authors",
    urltwo: "NA"
  },
  {
    id: "eurasian-journal-of-medicine-and-oncology",
    text: "Eurasian Journal of Medicine and Oncology",
    parent: "NA",
    urlone: "http://www.ejmo.org/Instructions-for-Authors",
    urltwo: "NA"
  },
  {
    id: "eurasip-journal-on-advances-in-signal-processing",
    text: "EURASIP Journal on Advances in Signal Processing",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurasip-journal-on-audio-speech-and-music-processing",
    text: "EURASIP Journal on Audio, Speech, and Music Processing",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurasip-journal-on-bioinformatics-and-systems-biology",
    text: "EURASIP Journal on Bioinformatics and Systems Biology",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurasip-journal-on-embedded-systems",
    text: "EURASIP Journal on Embedded Systems",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurasip-journal-on-image-and-video-processing",
    text: "EURASIP Journal on Image and Video Processing",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurasip-journal-on-information-security",
    text: "EURASIP Journal on Information Security",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurasip-journal-on-wireless-communications-and-networking",
    text: "EURASIP Journal on Wireless Communications and Networking",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "euro-journal-on-computational-optimization",
    text: "EURO Journal on Computational Optimization",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "euro-journal-on-decision-processes",
    text: "EURO Journal on Decision Processes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "euro-journal-on-transportation-and-logistics",
    text: "EURO Journal on Transportation and Logistics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "euro-mediterranean-journal-for-environmental-integration",
    text: "Euro-Mediterranean Journal for Environmental Integration",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eurointervention",
    text: "EuroIntervention",
    parent: "NA",
    urlone: "https://www.pcronline.com/eurointervention/pages/authors-centre/instructions-to-authors/",
    urltwo: "NA"
  },
  {
    id: "europace",
    text: "Europace",
    parent: "NA",
    urlone: "http://europace.oxfordjournals.org/",
    urltwo: "NA"
  },
  {
    id: "european-actuarial-journal",
    text: "European Actuarial Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-addiction-research",
    text: "European Addiction Research",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9891",
    urltwo: "NA"
  },
  {
    id: "european-annals-of-otorhinolaryngology-head-and-neck-diseases",
    text: "European Annals of Otorhinolaryngology, Head and Neck diseases",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-archives-of-oto-rhino-laryngology",
    text: "European Archives of Oto-Rhino-Laryngology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-archives-of-paediatric-dentistry",
    text: "European Archives of Paediatric Dentistry",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-archives-of-psychiatry-and-clinical-neuroscience",
    text: "European Archives of Psychiatry and Clinical Neuroscience",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-biophysics-journal",
    text: "European Biophysics Journal",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-business-organization-law-review",
    text: "European Business Organization Law Review",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-cells-and-materials",
    text: "European Cells &amp; Materials",
    parent: "NA",
    urlone: "http://www.ecmjournal.org/journal/instructions_to_authors.htm#references",
    urltwo: "NA"
  },
  {
    id: "european-child-and-adolescent-psychiatry",
    text: "European Child &amp; Adolescent Psychiatry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-early-childhood-education-research-journal",
    text: "European Early Childhood Education Research Journal",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RECR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-economic-review",
    text: "European Economic Review",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-food-research-and-technology",
    text: "European Food Research and Technology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-geriatric-medicine",
    text: "European Geriatric Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-heart-journal-acute-cardiovascular-care",
    text: "European Heart Journal - Acute Cardiovascular Care",
    parent: "sage-vancouver",
    urlone: "http://www.uk.sagepub.com/msg/acc.htm#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "european-heart-journal-cardiovascular-imaging",
    text: "European Heart Journal - Cardiovascular Imaging",
    parent: "european-society-of-cardiology",
    urlone: "http://ehjcimaging.oxfordjournals.org/",
    urltwo: "NA"
  },
  {
    id: "european-heart-journal",
    text: "European Heart Journal",
    parent: "european-society-of-cardiology",
    urlone: "http://eurheartj.oxfordjournals.org/",
    urltwo: "NA"
  },
  {
    id: "european-journal-for-philosophy-of-religion",
    text: "European Journal for Philosophy of Religion",
    parent: "NA",
    urlone: "https://philosophy-of-religion.eu/docs/EJPR_Stylesheet.pdf",
    urltwo: "NA"
  },
  {
    id: "european-journal-for-philosophy-of-science",
    text: "European Journal for Philosophy of Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-for-security-research",
    text: "European Journal for Security Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-ageing",
    text: "European Journal of Ageing",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-agronomy",
    text: "European Journal of Agronomy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-anaesthesiology",
    text: "European Journal of Anaesthesiology",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/eja/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-applied-physiology",
    text: "European Journal of Applied Physiology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-business-and-economics",
    text: "European Journal of Business and Economics",
    parent: "apa",
    urlone: "http://ojs.journals.cz/index.php/EJBE/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-cancer",
    text: "European Journal of Cancer",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-cardiovascular-nursing",
    text: "European Journal of Cardiovascular Nursing",
    parent: "sage-vancouver",
    urlone: "http://www.uk.sagepub.com/msg/cnu.htm#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-cell-biology",
    text: "European Journal of Cell Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-clinical-investigation",
    text: "European Journal of Clinical Investigation",
    parent: "vancouver-brackets",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2362/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-clinical-microbiology-and-infectious-diseases",
    text: "European Journal of Clinical Microbiology &amp; Infectious Diseases",
    parent: "NA",
    urlone: "http://www.springer.com/biomed/medical+microbiology/journal/10096",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-clinical-nutrition",
    text: "European Journal of Clinical Nutrition",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/ejcn/ejcn_new_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-clinical-pharmacology",
    text: "European Journal of Clinical Pharmacology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-combinatorics",
    text: "European Journal of Combinatorics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-developmental-psychology",
    text: "European Journal of Developmental Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PEDP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-drug-metabolism-and-pharmacokinetics",
    text: "European Journal of Drug Metabolism and Pharmacokinetics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-education-and-psychology",
    text: "European Journal of Education and Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-emergency-medicine",
    text: "European Journal of Emergency Medicine",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/ejem/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-endocrinology",
    text: "European Journal of Endocrinology",
    parent: "NA",
    urlone: "http://www.eje-online.org/site/misc/For-Authors.xhtml#refs",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-engineering-education",
    text: "European Journal of Engineering Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEEE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-entomology",
    text: "European Journal of Entomology",
    parent: "NA",
    urlone: "https://www.eje.cz/artkey/inf-990000-2600_Manuscript.php",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-environmental-and-civil-engineering",
    text: "European Journal of Environmental and Civil Engineering",
    parent: "apa",
    urlone: "http://www.tandfonline.com/toc/tece20/current#.U3qvAy_Wr7A",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-epidemiology",
    text: "European Journal of Epidemiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-family-business",
    text: "European Journal of Family Business",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-forest-research",
    text: "European Journal of Forest Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-futures-research",
    text: "European Journal of Futures Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-gastroenterology-and-hepatology",
    text: "European Journal of Gastroenterology &amp; Hepatology",
    parent: "vancouver-brackets",
    urlone: "http://edmgr.ovid.com/ejgh/accounts/ifauth.htm#17",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-haematology",
    text: "European Journal of Haematology",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291600-0609/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-health-psychology",
    text: "European Journal of Health Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/ejh",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-heart-failure",
    text: "European Journal of Heart Failure",
    parent: "european-society-of-cardiology",
    urlone: "http://eurjhf.oxfordjournals.org/",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-higher-education",
    text: "European Journal of Higher Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REHE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-hospital-pharmacy",
    text: "European Journal of Hospital Pharmacy",
    parent: "bmj",
    urlone: "http://ejhp.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-human-genetics",
    text: "European Journal of Human Genetics",
    parent: "NA",
    urlone: "http://mts-ejhg.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-immunology",
    text: "European Journal of Immunology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1521-4141/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-information-systems",
    text: "European Journal of Information Systems",
    parent: "NA",
    urlone: "http://www.palgrave.com/gp/journal/41303/authors/presentation-formatting",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-integrative-medicine",
    text: "European Journal of Integrative Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-internal-medicine",
    text: "European Journal of Internal Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-international-law",
    text: "European Journal of International Law",
    parent: "NA",
    urlone: "http://www.ejil.org/about/Stylesheet_EJIL_2016.pdf",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-law-and-economics",
    text: "European Journal of Law and Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-management-and-business-economics",
    text: "European Journal of Management and Business Economics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-mathematics",
    text: "European Journal of Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-mechanics-a-solids",
    text: "European Journal of Mechanics / A Solids",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-mechanics-b-fluids",
    text: "European Journal of Mechanics / B Fluids",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-medical-genetics",
    text: "European Journal of Medical Genetics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-medical-research",
    text: "European Journal of Medical Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-medicinal-chemistry",
    text: "European Journal of Medicinal Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-microbiology-and-immunology",
    text: "European Journal of Microbiology &amp; Immunology",
    parent: "NA",
    urlone: "http://akademiai.com/loi/1886",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-neuroscience",
    text: "European Journal of Neuroscience",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291460-9568/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-nuclear-medicine-and-molecular-imaging",
    text: "European Journal of Nuclear Medicine and Molecular Imaging",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-nutrition",
    text: "European Journal of Nutrition",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-obstetrics-and-gynecology-and-reproductive-biology-x",
    text: "European Journal of Obstetrics &amp; Gynecology and Reproductive Biology: X",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-obstetrics-and-gynecology-and-reproductive-biology",
    text: "European Journal of Obstetrics &amp; Gynecology and Reproductive Biology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-oncology-nursing",
    text: "European Journal of Oncology Nursing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-operational-research",
    text: "European Journal of Operational Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-ophthalmology",
    text: "European Journal of Ophthalmology",
    parent: "NA",
    urlone: "http://www.eur-j-ophthalmol.com/pub/ejo-instructions-to-authors#References",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-oral-sciences",
    text: "European Journal of Oral Sciences",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291600-0722/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-orthopaedic-surgery-and-traumatology",
    text: "European Journal of Orthopaedic Surgery &amp; Traumatology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-paediatric-neurology",
    text: "European Journal of Paediatric Neurology",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/european-journal-of-paediatric-neurology/1090-3798/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-pain",
    text: "European Journal of Pain",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291532-2149/homepage/ForAuthors.html#References_",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-pediatrics",
    text: "European Journal of Pediatrics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-pharmaceutical-sciences",
    text: "European Journal of Pharmaceutical Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-pharmaceutics-and-biopharmaceutics",
    text: "European Journal of Pharmaceutics and Biopharmaceutics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-pharmacology",
    text: "European Journal of Pharmacology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-physics",
    text: "European Journal of Physics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0143-0807",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-plant-pathology",
    text: "European Journal of Plant Pathology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-plastic-surgery",
    text: "European Journal of Plastic Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-political-economy",
    text: "European Journal of Political Economy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-political-research",
    text: "European Journal of Political Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291475-6765/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-population",
    text: "European Journal of Population",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-preventive-cardiology",
    text: "European Journal of Preventive Cardiology",
    parent: "sage-vancouver",
    urlone: "http://www.uk.sagepub.com/msg/cpr.htm#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-protistology",
    text: "European Journal of Protistology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-psychological-assessment",
    text: "European Journal of Psychological Assessment",
    parent: "apa",
    urlone: "http://content.apa.org/journals/jpa",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-psychology-of-education",
    text: "European Journal of Psychology of Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-psychotherapy-and-counselling",
    text: "European Journal of Psychotherapy &amp; Counselling",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REJP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-psychotraumatology",
    text: "European Journal of Psychotraumatology",
    parent: "apa",
    urlone: "http://www.ejpt.net/index.php/ejpt/pages/view/guidelines",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-public-health",
    text: "European Journal of Public Health",
    parent: "NA",
    urlone: "https://academic.oup.com/eurpub/pages/Instructions_For_Authors#References",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-radiology-open",
    text: "European Journal of Radiology Open",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-radiology",
    text: "European Journal of Radiology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-social-work",
    text: "European Journal of Social Work",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CESW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-soil-biology",
    text: "European Journal of Soil Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-soil-science",
    text: "European Journal of Soil Science",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2389/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-special-needs-education",
    text: "European Journal of Special Needs Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REJS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-sport-science",
    text: "European Journal of Sport Science",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TEJS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-surgical-oncology",
    text: "European Journal of Surgical Oncology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-sustainable-development",
    text: "European Journal of Sustainable Development",
    parent: "apa",
    urlone: "http://www.ecsdev.org/index.php/publications/guidelines-for-authors",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-taxonomy",
    text: "European Journal of Taxonomy",
    parent: "NA",
    urlone: "https://europeanjournaloftaxonomy.eu/index.php/ejt/about/editorialPolicies#custom-1",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-teacher-education",
    text: "European Journal of Teacher Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CETE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-theology",
    text: "European Journal of Theology",
    parent: "NA",
    urlone: "https://www.aup.nl/nl/journal/european-journal-of-theology",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-trauma-and-dissociation",
    text: "European Journal of Trauma &amp; Dissociation",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-trauma-and-emergency-surgery",
    text: "European Journal of Trauma and Emergency Surgery",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-ultrasound",
    text: "European Journal of Ultrasound (Ultraschall in der Medizin)",
    parent: "NA",
    urlone:
      "http://ultraschall.thieme.de/documents/10157/10820/UiM-Autorenrichtlinien2016.pdf/8cf116e9-c862-4626-bc36-945a971495af",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-vascular-and-endovascular-surgery",
    text: "European Journal of Vascular and Endovascular Surgery",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/european-journal-of-vascular-and-endovascular-surgery/1078-5884/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "european-journal-of-wildlife-research",
    text: "European Journal of Wildlife Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-wood-and-wood-products",
    text: "European Journal of Wood and Wood Products",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-journal-of-work-and-organizational-psychology",
    text: "European Journal of Work and Organizational Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PEWO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-journal-on-criminal-policy-and-research",
    text: "European Journal on Criminal Policy and Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-management-journal",
    text: "European Management Journal",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-medical-health-and-pharmaceutical-journal",
    text: "European Medical, Health and Pharmaceutical Journal",
    parent: "apa",
    urlone: "http://ojs.journals.cz/index.php/EMHPJ/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "european-neurology",
    text: "European Neurology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9913",
    urltwo: "NA"
  },
  {
    id: "european-neuropsychopharmacology",
    text: "European Neuropsychopharmacology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-orthopaedics-and-traumatology",
    text: "European Orthopaedics and Traumatology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-polymer-journal",
    text: "European Polymer Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-psychiatry",
    text: "European Psychiatry",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-psychologist",
    text: "European Psychologist",
    parent: "apa",
    urlone: "http://content.apa.org/journals/epp",
    urltwo: "NA"
  },
  {
    id: "european-radiology",
    text: "European Radiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-research-in-telemedicine-la-recherche-europeenne-en-telemedecine",
    text: "European Research in Telemedicine/La Recherche EuropÃ©enne en TÃ©lÃ©mÃ©decine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-research-on-management-and-business-economics",
    text: "European research on management and business economics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-respiratory-journal",
    text: "European Respiratory Journal",
    parent: "NA",
    urlone: "http://erj.ersjournals.com/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "european-retail-research",
    text: "European Retail Research",
    parent: "NA",
    urlone: "http://unitrier.t3-hoster.com/produkt-1.html",
    urltwo: "NA"
  },
  {
    id: "european-review-of-aging-and-physical-activity",
    text: "European Review of Aging and Physical Activity",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-review-of-history-revue-europeenne-dhistoire",
    text: "European Review of History: Revue europeenne d'histoire",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CERH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-review-of-social-psychology",
    text: "European Review of Social Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PERS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-society-of-cardiology",
    text: "European Society of Cardiology",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/eurheartj/for_authors/",
    urltwo: "NA"
  },
  {
    id: "european-spine-journal",
    text: "European Spine Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-sport-management-quarterly",
    text: "European Sport Management Quarterly",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RESM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "european-surgery",
    text: "European Surgery",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-surgical-research",
    text: "European Surgical Research",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9921",
    urltwo: "NA"
  },
  {
    id: "european-thyroid-journal",
    text: "European Thyroid Journal",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/255331",
    urltwo: "NA"
  },
  {
    id: "european-transport-research-review",
    text: "European Transport Research Review",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "european-union-interinstitutional-style-guide-author-date",
    text: "European Union - Interinstitutional Style Guide (author-date)",
    parent: "NA",
    urlone: "https://publications.europa.eu/code/en/en-250904.htm",
    urltwo: "NA"
  },
  {
    id: "european-union-interinstitutional-style-guide",
    text: "European Union - Interinstitutional Style Guide (note)",
    parent: "NA",
    urlone: "https://docs.google.com/file/d/0B-opIuO3xlZsYkV4dXVfVm1PYWM/edit",
    urltwo: "http://publications.europa.eu/code/en/en-000500.htm"
  },
  {
    id: "european-urology-focus",
    text: "European Urology Focus",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-urology-oncology",
    text: "European Urology Oncology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-urology-supplements",
    text: "European Urology Supplements",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-urology",
    text: "European Urology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "european-view",
    text: "European View",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eva-berlin-konferenz",
    text: "EVA Berlin Konferenz (German)",
    parent: "NA",
    urlone: "https://www.eva-berlin.de/en/participation/call-for-papers/",
    urltwo: "NA"
  },
  {
    id: "evaluation-and-program-planning",
    text: "Evaluation and Program Planning",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "evidence-based-communication-assessment-and-intervention",
    text: "Evidence-Based Communication Assessment and Intervention",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TEBC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "evidence-based-complementary-and-alternative-medicine",
    text: "Evidence-Based Complementary and Alternative Medicine",
    parent: "NA",
    urlone: "http://www.hindawi.com/journals/ecam/guidelines/",
    urltwo: "NA"
  },
  {
    id: "evidence-based-dentistry",
    text: "Evidence-Based Dentistry",
    parent: "european-journal-of-human-genetics",
    urlone: "http://www.nature.com/ebd/for_authors.html",
    urltwo: "NA"
  },
  {
    id: "evidence-based-medicine",
    text: "Evidence-Based Medicine",
    parent: "bmj",
    urlone: "http://ebm.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "evidence-based-mental-health",
    text: "Evidence-Based Mental Health",
    parent: "bmj",
    urlone: "http://ebmh.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "evidence-based-nursing",
    text: "Evidence-Based Nursing",
    parent: "bmj",
    urlone: "http://ebn.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "evodevo",
    text: "EvoDevo",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "evolution-and-development",
    text: "Evolution &amp; Development",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291525-142X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "evolution-and-human-behavior",
    text: "Evolution and Human Behavior",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "evolution-education-and-outreach",
    text: "Evolution: Education and Outreach",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "evolution",
    text: "Evolution",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291558-5646/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "evolutionary-and-institutional-economics-review",
    text: "Evolutionary and Institutional Economics Review",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "evolutionary-anthropology",
    text: "Evolutionary Anthropology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1520-6505/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "evolutionary-behavioral-sciences",
    text: "Evolutionary Behavioral Sciences",
    parent: "apa",
    urlone: "http://content.apa.org/journals/ebs",
    urltwo: "NA"
  },
  {
    id: "evolutionary-bioinformatics",
    text: "Evolutionary Bioinformatics",
    parent: "sage-vancouver",
    urlone: "http://insights.sagepub.com/journal.php?pa=aims_and_scope&amp;journal_id=17",
    urltwo: "NA"
  },
  {
    id: "evolutionary-biology",
    text: "Evolutionary Biology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "evolutionary-ecology-research",
    text: "Evolutionary Ecology Research",
    parent: "NA",
    urlone: "http://www.evolutionary-ecology.com/advice/AdviceFrames.html",
    urltwo: "NA"
  },
  {
    id: "evolutionary-ecology",
    text: "Evolutionary Ecology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "evolutionary-intelligence",
    text: "Evolutionary Intelligence",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "evolutionary-psychological-science",
    text: "Evolutionary Psychological Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "evolutionary-systematics",
    text: "Evolutionary Systematics",
    parent: "pensoft-journals",
    urlone: "https://evolsyst.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "evolving-systems",
    text: "Evolving Systems",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "excli-journal",
    text: "EXCLI Journal",
    parent: "NA",
    urlone: "http://www.excli.de/instructions_to_authors.php",
    urltwo: "NA"
  },
  {
    id: "exercer",
    text: "exercer (French)",
    parent: "NA",
    urlone: "http://www.exercer.fr/recos_auteurs/",
    urltwo: "NA"
  },
  {
    id: "experimental-and-applied-acarology",
    text: "Experimental and Applied Acarology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "experimental-and-clinical-endocrinology-and-diabetes-reports",
    text: "Experimental and Clinical Endocrinology &amp; Diabetes Reports",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "experimental-and-clinical-endocrinology-and-diabetes",
    text: "Experimental and Clinical Endocrinology &amp; Diabetes",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "experimental-and-clinical-psychopharmacology",
    text: "Experimental and Clinical Psychopharmacology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pha",
    urltwo: "NA"
  },
  {
    id: "experimental-and-molecular-medicine",
    text: "Experimental &amp; Molecular Medicine",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-emm.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "experimental-and-molecular-pathology",
    text: "Experimental and Molecular Pathology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "experimental-and-therapeutic-medicine",
    text: "Experimental and Therapeutic Medicine",
    parent: "spandidos-publications",
    urlone: "http://www.spandidos-publications.com/pages/info_for_authors",
    urltwo: "NA"
  },
  {
    id: "experimental-and-toxicologic-pathology",
    text: "Experimental and Toxicologic Pathology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "experimental-and-translational-stroke-medicine",
    text: "Experimental &amp; Translational Stroke Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "experimental-astronomy",
    text: "Experimental Astronomy",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "experimental-biomedical-research",
    text: "Experimental Biomedical Research",
    parent: "NA",
    urlone: "http://experimentalbiomedicalresearch.com/ojs/index.php/ebr/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "experimental-brain-research",
    text: "Experimental Brain Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "experimental-cell-research",
    text: "Experimental Cell Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "experimental-dermatology",
    text: "Experimental Dermatology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291600-0625/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "experimental-economics",
    text: "Experimental Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "experimental-eye-research",
    text: "Experimental Eye Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "experimental-gerontology",
    text: "Experimental Gerontology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "experimental-hematology-and-oncology",
    text: "Experimental Hematology &amp; Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "experimental-mechanics",
    text: "Experimental Mechanics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "experimental-neurology",
    text: "Experimental Neurology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "experimental-parasitology",
    text: "Experimental Parasitology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "experimental-physiology",
    text: "Experimental Physiology",
    parent: "the-journal-of-physiology",
    urlone: "http://ep.physoc.org/site/misc/author.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "experimental-psychology",
    text: "Experimental Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/zea",
    urltwo: "NA"
  },
  {
    id: "experimental-techniques",
    text: "Experimental Techniques",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "experimental-thermal-and-fluid-science",
    text: "Experimental Thermal and Fluid Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "experiments-in-fluids",
    text: "Experiments in Fluids",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "expert-review-of-anti-infective-therapy",
    text: "Expert Review of Anti-infective Therapy",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierz20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-anticancer-therapy",
    text: "Expert Review of Anticancer Therapy",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=iery20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-cardiovascular-therapy",
    text: "Expert Review of Cardiovascular Therapy",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierk20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-clinical-immunology",
    text: "Expert Review of Clinical Immunology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierm20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-clinical-pharmacology",
    text: "Expert Review of Clinical Pharmacology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierj20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-endocrinology-and-metabolism",
    text: "Expert Review of Endocrinology &amp; Metabolism",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=iere20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-gastroenterology-and-hepatology",
    text: "Expert Review of Gastroenterology &amp; Hepatology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierh20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-hematology",
    text: "Expert Review of Hematology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierr20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-medical-devices",
    text: "Expert Review of Medical Devices",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierd20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-molecular-diagnostics",
    text: "Expert Review of Molecular Diagnostics",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=iero20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-neurotherapeutics",
    text: "Expert Review of Neurotherapeutics",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=iern20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-ophthalmology",
    text: "Expert Review of Ophthalmology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierl20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-pharmacoeconomics-and-outcomes-research",
    text: "Expert Review of Pharmacoeconomics &amp; Outcomes Research",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierp20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-precision-medicine-and-drug-development",
    text: "Expert Review of Precision Medicine &amp; Drug Development",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=tepm20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-proteomics",
    text: "Expert Review of Proteomics",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ieru20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-quality-of-life-in-cancer-care",
    text: "Expert Review of Quality of Life in Cancer Care",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=terq20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-respiratory-medicine",
    text: "Expert Review of Respiratory Medicine",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierx20#References",
    urltwo: "NA"
  },
  {
    id: "expert-review-of-vaccines",
    text: "Expert Review of Vaccines",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ierv20#References",
    urltwo: "NA"
  },
  {
    id: "expert-systems-with-applications",
    text: "Expert Systems With Applications",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "explorations-in-economic-history",
    text: "Explorations in Economic History",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "explore",
    text: "EXPLORE",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "exposure-and-health",
    text: "Exposure and Health",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "express-polymer-letters",
    text: "eXPRESS Polymer Letters",
    parent: "NA",
    urlone: "http://www.expresspolymlett.com/",
    urltwo: "NA"
  },
  {
    id: "extreme-mechanics-letters",
    text: "Extreme Mechanics Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "extreme-physiology-and-medicine",
    text: "Extreme Physiology &amp; Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "extremes",
    text: "Extremes",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "extremophiles",
    text: "Extremophiles",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eye-and-contact-lens",
    text: "Eye &amp; Contact Lens",
    parent: "american-medical-association",
    urlone: "http://edmgr.ovid.com/ecl/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "eye-and-vision",
    text: "Eye and Vision",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "eye",
    text: "Eye",
    parent: "NA",
    urlone: "http://mts-eye.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions",
    urltwo: "NA"
  },
  {
    id: "f1000research",
    text: "F1000Research",
    parent: "vancouver",
    urlone: "http://f1000research.com/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "fachhochschule-kiel-fachbereich-medien",
    text: "Fachhochschule Kiel - Fachbereich Medien (German)",
    parent: "NA",
    urlone: "https://www.fh-kiel.de/fileadmin/data/multimedia/Pruefungsangelegenheiten/Zitierkonventionen_Dez2012.pdf",
    urltwo: "NA"
  },
  {
    id: "fachhochschule-vorarlberg-author-date",
    text: "Fachhochschule Vorarlberg (author-date)",
    parent: "NA",
    urlone: "https://ilias.fhv.at/goto_ilias_fhv_at_fold_176959.html",
    urltwo: "NA"
  },
  {
    id: "fachhochschule-vorarlberg-note",
    text: "Fachhochschule Vorarlberg (note)",
    parent: "NA",
    urlone: "https://ilias.fhv.at/goto_ilias_fhv_at_fold_176959.html",
    urltwo: "NA"
  },
  {
    id: "facial-plastic-surgery-clinics-of-north-america",
    text: "Facial Plastic Surgery Clinics of North America",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/facial-plastic-surgery-clinics-of-north-america/1064-7406/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "facies",
    text: "Facies",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "facolta-teologica-dell-italia-settentrionale-milano",
    text: "FacoltÃ  Teologica dell'Italia Settentrionale - Milano (titoli di riviste non abbreviati, Italian)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/4063#issuecomment-487406439",
    urltwo: "NA"
  },
  {
    id: "familial-cancer",
    text: "Familial Cancer",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "families-systems-and-health",
    text: "Families, Systems, &amp; Health",
    parent: "apa",
    urlone: "http://content.apa.org/journals/fsh",
    urltwo: "NA"
  },
  {
    id: "family-business-review",
    text: "Family Business Review",
    parent: "NA",
    urlone: "https://uk.sagepub.com/en-gb/eur/family-business-review/journal201921#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "family-law-review",
    text: "Family Law Review",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "family-medicine",
    text: "Family Medicine",
    parent: "vancouver-superscript",
    urlone: "http://www.stfm.org/publications/familymedicine/author.cfm",
    urltwo: "NA"
  },
  {
    id: "family-science",
    text: "Family Science",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RFSC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "faraday-discussions",
    text: "Faraday Discussions",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "farmacia-hospitalaria",
    text: "Farmacia Hospitalaria (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.com/journals/farmacia-hospitalaria/1130-6343",
    urltwo: "NA"
  },
  {
    id: "farmeconomia",
    text: "Farmeconomia. Health Economics and Therapeutic Pathways",
    parent: "NA",
    urlone: "https://journals.seedmedicalpublishers.com/index.php/fe/about/submissions",
    urltwo: "NA"
  },
  {
    id: "fashion-and-textiles",
    text: "Fashion and Textiles",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fatigue-and-fracture-of-engineering-materials-and-structures",
    text: "Fatigue &amp; Fracture of Engineering Materials &amp; Structures",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14602695/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "fatigue-biomedicine-health-and-behavior",
    text: "Fatigue: Biomedicine, Health &amp; Behavior",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RFTG20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "feminist-economics",
    text: "Feminist Economics",
    parent: "NA",
    urlone: "http://www.feministeconomics.org/pages/author-instructions/style-guidelines/",
    urltwo: "NA"
  },
  {
    id: "feminist-legal-studies",
    text: "Feminist Legal Studies",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "feminist-media-studies",
    text: "Feminist Media Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RFMS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "feminist-theory",
    text: "Feminist Theory",
    parent: "NA",
    urlone: "https://journals.sagepub.com/author-instructions/FTY#ReferenceStyle",
    urltwo:
      "https://journals.sagepub.com/pb-assets/cmscontent/FTY/Feminist%20Theory%20Style%20Guide%202012_received%20on%208%20November%202012.pdf"
  },
  {
    id: "fems-microbiology-ecology",
    text: "FEMS Microbiology Ecology",
    parent: "oxford-university-press-scimed-author-date",
    urlone: "http://femspd.oxfordjournals.org/for_authors/stylesheet.html",
    urltwo: "NA"
  },
  {
    id: "fems-microbiology-letters",
    text: "FEMS Microbiology Letters",
    parent: "oxford-university-press-scimed-author-date",
    urlone: "http://femspd.oxfordjournals.org/for_authors/stylesheet.html",
    urltwo: "NA"
  },
  {
    id: "fems-microbiology-reviews",
    text: "FEMS Microbiology Reviews",
    parent: "oxford-university-press-scimed-author-date",
    urlone: "http://femspd.oxfordjournals.org/for_authors/stylesheet.html",
    urltwo: "NA"
  },
  {
    id: "fems-yeast-research",
    text: "FEMS Yeast Research",
    parent: "oxford-university-press-scimed-author-date",
    urlone: "http://femspd.oxfordjournals.org/for_authors/stylesheet.html",
    urltwo: "NA"
  },
  {
    id: "ferdinand-porsche-fern-fachhochschule",
    text: "Ferdinand Porsche Fernfachhochschule (German - Austria)",
    parent: "NA",
    urlone: "https://github.com/christoph425/ffh_csl_styles/raw/main/Richtlinien_wissenschaftliche_Arbeiten_WP.pdf",
    urltwo: "http://fernfh.ac.at/"
  },
  {
    id: "fermentation",
    text: "Fermentation",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "fertility-and-sterility",
    text: "Fertility and Sterility",
    parent: "NA",
    urlone: "http://www.fertstert.org/authorinfo",
    urltwo: "NA"
  },
  {
    id: "fertility-research-and-practice",
    text: "Fertility Research and Practice",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fetal-and-neonatal",
    text: "Fetal &amp; Neonatal",
    parent: "bmj",
    urlone: "http://fn.bmj.com",
    urltwo: "NA"
  },
  {
    id: "fetal-diagnosis-and-therapy",
    text: "Fetal Diagnosis and Therapy",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9964",
    urltwo: "NA"
  },
  {
    id: "feuillets-de-radiologie",
    text: "Feuillets de Radiologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "few-body-systems",
    text: "Few-Body Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fibers",
    text: "Fibers",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "fibrogenesis-and-tissue-repair",
    text: "Fibrogenesis &amp; Tissue Repair",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "field-crops-research",
    text: "Field Crops Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fields-mathematics-education-journal",
    text: "Fields Mathematics Education Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "finance-and-society",
    text: "Finance and Society",
    parent: "NA",
    urlone: "http://financeandsociety.ed.ac.uk//pages/view/house-style",
    urltwo: "NA"
  },
  {
    id: "finance-and-stochastics",
    text: "Finance and Stochastics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "finance-research-letters",
    text: "Finance Research Letters",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "financial-innovation",
    text: "Financial Innovation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "financial-markets-and-portfolio-management",
    text: "Financial Markets and Portfolio Management",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "finanzarchiv",
    text: "FinanzArchiv - Public Finance Analysis",
    parent: "NA",
    urlone: "http://www.mohr.de/fileadmin/user_upload/Hinweise_Autoren_PDF/FA_2014/Style_guide_4_Feb_2014_.pdf",
    urltwo: "http://www.mohr.de/en/nc/journals/economics/finanzarchiv-fa/manuscripts.html"
  },
  {
    id: "fine-focus",
    text: "Fine Focus",
    parent: "NA",
    urlone: "http://finefocus.org/submissions",
    urltwo: "NA"
  },
  {
    id: "finite-elements-in-analysis-and-design",
    text: "Finite Elements in Analysis &amp; Design",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "finite-fields-and-their-applications",
    text: "Finite Fields and Their Applications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fire-safety-journal",
    text: "Fire Safety Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fire-science-reviews",
    text: "Fire Science Reviews",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fire-technology",
    text: "Fire Technology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "first-amendment-studies",
    text: "First Amendment Studies",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RFSY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "first-break",
    text: "First Break",
    parent: "NA",
    urlone: "http://fb.eage.org/index/guidelinesforauthors?p=103",
    urltwo: "NA"
  },
  {
    id: "first-monday",
    text: "First Monday",
    parent: "NA",
    urlone: "http://firstmonday.org/ojs/index.php/fm/about/submissions#cite",
    urltwo: "NA"
  },
  {
    id: "first-world-war-studies",
    text: "First World War Studies",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RFWW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "fish-and-fisheries",
    text: "Fish and Fisheries",
    parent: "apa",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14672979/homepage/ForAuthors.html#preparingsubmissions",
    urltwo: "NA"
  },
  {
    id: "fish-and-shellfish-immunology",
    text: "Fish and Shellfish Immunology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fish-physiology-and-biochemistry",
    text: "Fish Physiology and Biochemistry",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fisheries-research",
    text: "Fisheries Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fisheries-science",
    text: "Fisheries Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fisheries",
    text: "Fisheries",
    parent: "american-fisheries-society",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=ufsh20&amp;page=instructions#.UpU_o0Mju1E",
    urltwo: "NA"
  },
  {
    id: "fishery-bulletin",
    text: "Fishery Bulletin",
    parent: "NA",
    urlone: "http://fishbull.noaa.gov/fguide.htm",
    urltwo: "NA"
  },
  {
    id: "fishes",
    text: "Fishes",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "fisterra",
    text: "Fisterra",
    parent: "vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fitoterapia",
    text: "Fitoterapia",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fixed-point-theory-and-applications",
    text: "Fixed Point Theory and Applications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "flatchem",
    text: "FlatChem",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "flavour-and-fragrance-journal",
    text: "Flavour and Fragrance Journal",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291099-1026/homepage/ForAuthors.html#Ref",
    urltwo: "NA"
  },
  {
    id: "flavour",
    text: "Flavour",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "flexible-and-printed-electronics",
    text: "Flexible &amp; Printed Electronics",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "flexible-services-and-manufacturing-journal",
    text: "Flexible Services and Manufacturing Journal",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "flora",
    text: "Flora",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "florida-entomologist",
    text: "Florida Entomologist",
    parent: "NA",
    urlone: "http://www.flaentsoc.org/auinstr.shtml",
    urltwo: "NA"
  },
  {
    id: "flow-measurement-and-instrumentation",
    text: "Flow Measurement and Instrumentation",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "flow-turbulence-and-combustion",
    text: "Flow, Turbulence and Combustion",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fluid-dynamics-research",
    text: "Fluid Dynamics Research",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1873-7005",
    urltwo: "NA"
  },
  {
    id: "fluid-phase-equilibria",
    text: "Fluid Phase Equilibria",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fluids-and-barriers-of-the-cns",
    text: "Fluids and Barriers of the CNS",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fluids",
    text: "Fluids",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "fly",
    text: "Fly",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "foerster-geisteswissenschaft",
    text: "Sascha Foerster - Geisteswissenschaft (German)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fold-and-r",
    text: "FOLD&amp;R (Fasti On Line Documents &amp; Research)",
    parent: "NA",
    urlone: "http://www.fastionline.org/folderabout.php?view=authnotes",
    urltwo: "NA"
  },
  {
    id: "folia-biologica",
    text: "Folia Biologica",
    parent: "NA",
    urlone: "https://fb.cuni.cz/instructions-to-authors-4293",
    urltwo: "NA"
  },
  {
    id: "folia-microbiologica",
    text: "Folia Microbiologica",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "folia-phoniatrica-et-logopaedica",
    text: "Folia Phoniatrica et Logopaedica",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9972",
    urltwo: "NA"
  },
  {
    id: "folia-primatologica",
    text: "Folia Primatologica",
    parent: "karger-journals-author-date",
    urlone: "http://www.karger.com/Journal/Guidelines/223842#05",
    urltwo: "NA"
  },
  {
    id: "food-additives-and-contaminants-part-a",
    text: "Food Additives &amp; Contaminants: Part A",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TFAC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "food-additives-and-contaminants-part-b",
    text: "Food Additives &amp; Contaminants: Part B",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TFAB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "food-analytical-methods",
    text: "Food Analytical Methods",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "food-and-agriculture-organization-of-the-united-nations",
    text: "Food and Agriculture Organization of the United Nations",
    parent: "NA",
    urlone: "http://www.fao.org/3/a-ac339e.pdf",
    urltwo: "NA"
  },
  {
    id: "food-and-bioprocess-technology",
    text: "Food and Bioprocess Technology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "food-and-bioproducts-processing",
    text: "Food and Bioproducts Processing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-and-chemical-toxicology",
    text: "Food and Chemical Toxicology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-and-environmental-virology",
    text: "Food and Environmental Virology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "food-and-function",
    text: "Food &amp; Function",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-and-waterborne-parasitology",
    text: "Food and Waterborne Parasitology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-biophysics",
    text: "Food Biophysics",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "food-bioscience",
    text: "Food Bioscience",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-chemistry-x",
    text: "Food Chemistry: X",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-chemistry",
    text: "Food Chemistry",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-control",
    text: "Food Control",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-digestion",
    text: "Food Digestion: Research and Current Opinion",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "food-engineering-reviews",
    text: "Food Engineering Reviews",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "food-ethics",
    text: "Food Ethics",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "food-hydrocolloids",
    text: "Food Hydrocolloids",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-microbiology",
    text: "Food Microbiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-packaging-and-shelf-life",
    text: "Food Packaging and Shelf Life",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-policy",
    text: "Food Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-quality-and-preference",
    text: "Food Quality and Preference",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-research-international",
    text: "Food Research International",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-science-and-human-wellness",
    text: "Food Science and Human Wellness",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-security",
    text: "Food Security",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "food-structure",
    text: "Food Structure",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "food-webs",
    text: "Food Webs",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "foodborne-pathogens-and-disease",
    text: "Foodborne Pathogens and Disease",
    parent: "NA",
    urlone: "https://home.liebertpub.com/publications/foodborne-pathogens-and-disease/108/for-authors",
    urltwo: "NA"
  },
  {
    id: "foods",
    text: "Foods",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "foot-and-ankle-international",
    text: "Foot &amp; Ankle International",
    parent: "american-medical-association-alphabetical",
    urlone: "http://fai.msubmit.net/cgi-bin/main.plex?form_type=display_auth_instructions",
    urltwo: "NA"
  },
  {
    id: "foot-and-ankle-surgery",
    text: "Foot and Ankle Surgery",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "foreign-policy-analysis",
    text: "Foreign Policy Analysis",
    parent: "international-studies-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forensic-anthropology",
    text: "Forensic Anthropology",
    parent: "NA",
    urlone: "http://journals.upress.ufl.edu/fa/guidelines",
    urltwo: "NA"
  },
  {
    id: "forensic-chemistry",
    text: "Forensic Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forensic-science-international-genetics-supplement-series",
    text: "Forensic Science International: Genetics Supplement Series",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forensic-science-international-genetics",
    text: "Forensic Science International: Genetics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forensic-science-international-supplement-series",
    text: "Forensic Science International Supplement Series",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forensic-science-international-synergy",
    text: "Forensic Science International: Synergy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forensic-science-international",
    text: "Forensic Science International",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forensic-science-medicine-and-pathology",
    text: "Forensic Science, Medicine, and Pathology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "forensic-science-review",
    text: "Forensic Science Review",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forensic-toxicology",
    text: "Forensic Toxicology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "forensische-psychiatrie-psychologie-kriminologie",
    text: "Forensische Psychiatrie, Psychologie, Kriminologie (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "forest-ecology-and-management",
    text: "Forest Ecology and Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forest-ecosystems",
    text: "Forest Ecosystems",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "forest-policy-and-economics",
    text: "Forest Policy and Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forest-science-and-technology",
    text: "Forest Science and Technology",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TFST20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "forest-science",
    text: "Forest Science",
    parent: "NA",
    urlone: "http://www.safnet.org/publications/forscience/guideforauthors.cfm",
    urltwo: "NA"
  },
  {
    id: "forests-trees-and-livelihoods",
    text: "Forests, Trees and Livelihoods",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TFTL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "forests",
    text: "Forests",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "formal-methods-in-system-design",
    text: "Formal Methods in System Design",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "formosan-journal-of-surgery",
    text: "Formosan Journal of Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "forschende-komplementarmedizin",
    text: "Forschende KomplementÃ¤rmedizin",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/224242",
    urltwo: "NA"
  },
  {
    id: "forschung-im-ingenieurwesen",
    text: "Forschung im Ingenieurwesen (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fortschritte-der-neurologie-psychiatrie",
    text: "Fortschritte der Neurologie - Psychiatrie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "forum-der-psychoanalyse",
    text: "Forum der Psychoanalyse (German)",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "forum-for-social-economics",
    text: "Forum for Social Economics",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RFSE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "forum-on-immunopathological-diseases-and-therapeutics",
    text: "Forum on Immunopathological Diseases and Therapeutics",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/1a654bf03faf67ac.html",
    urltwo: "NA"
  },
  {
    id: "forum-qualitative-social-research",
    text: "Forum: Qualitative Social Research (English)",
    parent: "NA",
    urlone: "http://www.qualitative-research.net/index.php/fqs/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "forum-qualitative-sozialforschung",
    text: "Forum: Qualitative Sozialforschung (German)",
    parent: "NA",
    urlone: "http://www.qualitative-research.net/index.php/fqs/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "forum",
    text: "Forum (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/oncology/journal/12312?detailsPage=pltci_2424946",
    urltwo: "NA"
  },
  {
    id: "fossil-record",
    text: "Fossil Record",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fouilles-de-linstitut-francais-darcheologie-orientale",
    text: "Fouilles de lâ€™Institut franÃ§ais dâ€™archÃ©ologie orientale",
    parent: "institut-francais-darcheologie-orientale",
    urlone: "https://www.ifao.egnet.net/publications/publier/normes-ed/",
    urltwo: "https://www.ifao.egnet.net/publications/catalogue/FIFAO/"
  },
  {
    id: "foundations-of-chemistry",
    text: "Foundations of Chemistry",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "foundations-of-physics",
    text: "Foundations of Physics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "foundations-of-science",
    text: "Foundations of Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fractal-and-fractional",
    text: "Fractal and Fractional",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "frauenheilkunde-up2date",
    text: "Frauenheilkunde up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "free-radical-biology-and-medicine",
    text: "Free Radical Biology and Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "free-radical-research",
    text: "Free Radical Research",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=ifra20#References",
    urltwo: "NA"
  },
  {
    id: "free-radicals-and-antioxidants",
    text: "Free Radicals and Antioxidants",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "freie-universitat-berlin-geographische-wissenschaften",
    text: "Freie UniversitÃ¤t Berlin - Geographische Wissenschaften (German)",
    parent: "NA",
    urlone:
      "http://www.geo.fu-berlin.de/geog/fachrichtungen/physgeog/medien/download/Studium_und_Lehre/Empfehlungen_Hausarbeiten.pdf?1373748910",
    urltwo: "NA"
  },
  {
    id: "french-historical-studies",
    text: "French Historical Studies",
    parent: "chicago-note-bibliography",
    urlone: "http://fhs.dukejournals.org/",
    urltwo: "NA"
  },
  {
    id: "french-politics",
    text: "French Politics",
    parent: "NA",
    urlone: "http://www.palgrave-journals.com/fp/author_instructions.html",
    urltwo: "NA"
  },
  {
    id: "french1",
    text: "France (tous les auteurs, numÃ©rotation, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "french2",
    text: "France (auteurs et al., numÃ©rotation, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "french3",
    text: "France (tous les auteurs, auteur-date, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "french4",
    text: "France (auteurs et al., auteur-date, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "freshwater-biology",
    text: "Freshwater Biology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2427/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "freshwater-crayfish",
    text: "Freshwater Crayfish",
    parent: "NA",
    urlone: "http://www.freshwatercrayfish.org/fc.asp?uid=Guest",
    urltwo: "NA"
  },
  {
    id: "freshwater-science",
    text: "Freshwater Science",
    parent: "NA",
    urlone: "https://www.journals.uchicago.edu/journals/fws/instruct",
    urltwo: "NA"
  },
  {
    id: "friedrich-schiller-universitat-jena-medizinische-fakultat",
    text: "Friedrich-Schiller-UniversitÃ¤t Jena - Medizinische FakultÃ¤t (German)",
    parent: "NA",
    urlone: "http://www.uniklinikum-jena.de/MedWeb_media/Downloads/Dekanat_Promotionsstelle/Formulare/Zitierweise.pdf",
    urltwo: "NA"
  },
  {
    id: "frontiers-for-young-minds",
    text: "Frontiers for Young Minds",
    parent: "frontiers-in-physics",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-aging-neuroscience",
    text: "Frontiers in Aging Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-applied-mathematics-and-statistics",
    text: "Frontiers in Applied Mathematics and Statistics",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-astronomy-and-space-sciences",
    text: "Frontiers in Astronomy and Space Sciences",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-behavioral-neuroscience",
    text: "Frontiers in Behavioral Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-bioengineering-and-biotechnology",
    text: "Frontiers in Bioengineering and Biotechnology",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-bioscience",
    text: "Frontiers in Bioscience",
    parent: "NA",
    urlone: "http://www.bioscience.org/authors",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-built-environment",
    text: "Frontiers in Built Environment",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-cardiovascular-medicine",
    text: "Frontiers in Cardiovascular Medicine",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-cell-and-developmental-biology",
    text: "Frontiers in Cell and Developmental Biology",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-cellular-and-infection-microbiology",
    text: "Frontiers in Cellular and Infection Microbiology",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-cellular-neuroscience",
    text: "Frontiers in Cellular Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-chemistry",
    text: "Frontiers in Chemistry",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-communication",
    text: "Frontiers in Communication",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-computational-neuroscience",
    text: "Frontiers in Computational Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-digital-humanities",
    text: "Frontiers in Digital Humanities",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-earth-science",
    text: "Frontiers in Earth Science",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-ecology-and-evolution",
    text: "Frontiers in Ecology and Evolution",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-ecology-and-the-environment",
    text: "Frontiers in Ecology and the Environment",
    parent: "NA",
    urlone: "http://www.frontiersinecology.org/instructions-to-authors/research-communications/",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-education",
    text: "Frontiers in Education",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-endocrinology",
    text: "Frontiers in Endocrinology",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-energy-research",
    text: "Frontiers in Energy Research",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-environmental-science",
    text: "Frontiers in Environmental Science",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-evolutionary-neuroscience",
    text: "Frontiers in Evolutionary Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-genetics",
    text: "Frontiers in Genetics",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-human-neuroscience",
    text: "Frontiers in Human Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-ict",
    text: "Frontiers in ICT",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-immunology",
    text: "Frontiers in Immunology",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-integrative-neuroscience",
    text: "Frontiers in Integrative Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-laboratory-medicine",
    text: "Frontiers in Laboratory Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-life-science",
    text: "Frontiers in Life Science",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TFLS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-marine-science",
    text: "Frontiers in Marine Science",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-materials",
    text: "Frontiers in Materials",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-mechanical-engineering",
    text: "Frontiers in Mechanical Engineering",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-medicine",
    text: "Frontiers in Medicine",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-microbiology",
    text: "Frontiers in Microbiology",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-molecular-biosciences",
    text: "Frontiers in Molecular Biosciences",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-molecular-neuroscience",
    text: "Frontiers in Molecular Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-neural-circuits",
    text: "Frontiers in Neural Circuits",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-neuroanatomy",
    text: "Frontiers in Neuroanatomy",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-neuroendocrinology",
    text: "Frontiers in Neuroendocrinology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-neuroenergetics",
    text: "Frontiers in Neuroenergetics",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-neuroengineering",
    text: "Frontiers in Neuroengineering",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-neuroinformatics",
    text: "Frontiers in Neuroinformatics",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-neurology",
    text: "Frontiers in Neurology",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-neurorobotics",
    text: "Frontiers in Neurorobotics",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-neuroscience",
    text: "Frontiers in Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-nutrition",
    text: "Frontiers in Nutrition",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-oncology",
    text: "Frontiers in Oncology",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-optics",
    text: "Frontiers in Optics",
    parent: "NA",
    urlone: "http://www.frontiersinoptics.com/getmedia/eb19a31a-673f-4304-b339-0702821cf594/FiOLS-Style-Guide.aspx",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-pediatrics",
    text: "Frontiers in Pediatrics",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-pharmacology",
    text: "Frontiers in Pharmacology",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-physics",
    text: "Frontiers in Physics",
    parent: "NA",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-physiology",
    text: "Frontiers in Physiology",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-plant-science",
    text: "Frontiers in Plant Science",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-psychiatry",
    text: "Frontiers in Psychiatry",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-psychology",
    text: "Frontiers in Psychology",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-public-health",
    text: "Frontiers in Public Health",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-research-metrics-and-analytics",
    text: "Frontiers in Research Metrics and Analytics",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-robotics-and-ai",
    text: "Frontiers in Robotics and AI",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-sociology",
    text: "Frontiers in Sociology",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-surgery",
    text: "Frontiers in Surgery",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-synaptic-neuroscience",
    text: "Frontiers in Synaptic Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-systems-neuroscience",
    text: "Frontiers in Systems Neuroscience",
    parent: "frontiers",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-veterinary-science",
    text: "Frontiers in Veterinary Science",
    parent: "frontiers-medical-journals",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-in-zoology",
    text: "Frontiers in Zoology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "frontiers-medical-journals",
    text: "Frontiers medical journals",
    parent: "NA",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines#References",
    urltwo: "NA"
  },
  {
    id: "frontiers-of-architectural-research",
    text: "Frontiers of Architectural Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "frontiers-of-biogeography",
    text: "Frontiers of Biogeography",
    parent: "NA",
    urlone: "https://escholarship.org/uc/fb/authorinformation",
    urltwo: "NA"
  },
  {
    id: "frontiers",
    text: "Frontiers journals",
    parent: "NA",
    urlone: "http://www.frontiersin.org/about/AuthorGuidelines",
    urltwo: "NA"
  },
  {
    id: "frontline-gastroenterology",
    text: "Frontline Gastroenterology",
    parent: "bmj",
    urlone: "http://fg.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "fudan-journal-of-the-humanities-and-social-sciences",
    text: "Fudan Journal of the Humanities and Social Sciences",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fuel-processing-technology",
    text: "Fuel Processing Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fuel",
    text: "Fuel",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "functional-and-integrative-genomics",
    text: "Functional &amp; Integrative Genomics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "functional-ecology",
    text: "Functional Ecology",
    parent: "apa-old-doi-prefix",
    urlone: "http://besjournals.onlinelibrary.wiley.com/hub/journal/10.1111/(ISSN)1365-2435/author-guidelines.html",
    urltwo: "NA"
  },
  {
    id: "functional-linguistics",
    text: "Functional Linguistics",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "functional-plant-biology",
    text: "Functional Plant Biology",
    parent: "international-journal-of-wildland-fire",
    urlone: "http://www.publish.csiro.au/nid/105/aid/413.htm#4",
    urltwo: "NA"
  },
  {
    id: "fungal-biology-and-biotechnology",
    text: "Fungal Biology and Biotechnology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fungal-biology-reviews",
    text: "Fungal Biology Reviews",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fungal-biology",
    text: "Fungal Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fungal-diversity",
    text: "Fungal Diversity",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fungal-ecology",
    text: "Fungal Ecology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fungal-genetics-and-biology",
    text: "Fungal Genetics and Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fusion-engineering-and-design",
    text: "Fusion Engineering and Design",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fusion-science-and-technology",
    text: "Fusion Science and Technology",
    parent: "american-nuclear-society",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=ufst20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "fuss-und-sprunggelenk",
    text: "Fuss und Sprunggelenk",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "future-business-journal",
    text: "Future Business Journal",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "future-cardiology",
    text: "Future Cardiology",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/fca",
    urltwo: "NA"
  },
  {
    id: "future-cities-and-environment",
    text: "Future Cities and Environment",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "future-computing-and-informatics-journal",
    text: "Future Computing and Informatics Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "future-dental-journal",
    text: "Future Dental Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "future-generation-computer-systems",
    text: "Future Generation Computer Systems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "future-internet",
    text: "Future Internet",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "future-journal-of-pharmaceutical-sciences",
    text: "Future Journal of Pharmaceutical sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "future-medicinal-chemistry",
    text: "Future Medicinal Chemistry",
    parent: "future-science-group",
    urlone: "http://www.future-science.com/loi/fmc",
    urltwo: "NA"
  },
  {
    id: "future-medicine",
    text: "Future Medicine journals",
    parent: "NA",
    urlone: "https://www.futuremedicine.com/authorguide/preparingyourarticle",
    urltwo: "https://www.futuremedicine.com/pb-assets/Future-Medicine-Author-Guidelines-1585216135307.pdf"
  },
  {
    id: "future-microbiology",
    text: "Future Microbiology",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/fmb",
    urltwo: "NA"
  },
  {
    id: "future-neurology",
    text: "Future Neurology",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/fnl",
    urltwo: "NA"
  },
  {
    id: "future-oncology",
    text: "Future Oncology",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/fon",
    urltwo: "NA"
  },
  {
    id: "future-rare-diseases",
    text: "Future Rare Diseases",
    parent: "future-medicine",
    urlone: "https://www.futuremedicine.com/journal/frd",
    urltwo: "NA"
  },
  {
    id: "future-science-group",
    text: "Future Science Group",
    parent: "NA",
    urlone: "https://www.futuremedicine.com/pb-assets/Nanomedicine%20Author%20Guidelines-1540302236583.pdf",
    urltwo: "NA"
  },
  {
    id: "future-virology",
    text: "Future Virology",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/fvl",
    urltwo: "NA"
  },
  {
    id: "futures",
    text: "Futures",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fuzzy-information-and-engineering",
    text: "Fuzzy Information and Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "fuzzy-optimization-and-decision-making",
    text: "Fuzzy Optimization and Decision Making",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "fuzzy-sets-and-systems",
    text: "Fuzzy Sets and Systems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "g-giappichelli-editore",
    text: "G. Giappichelli Editore (Italian)",
    parent: "NA",
    urlone: "http://www.dirittoeconomiaimpresa.it/images/PUBBLICARE_SU_DEI/DEI_Norme_redazionali.pdf",
    urltwo: "NA"
  },
  {
    id: "g3",
    text: "G3: Genes, Genomes, Genetics",
    parent: "NA",
    urlone: "http://www.g3journal.org/site/misc/ifora.xhtml#REFERENCES",
    urltwo: "NA"
  },
  {
    id: "gaceta-sanitaria",
    text: "Gaceta Sanitaria",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/gaceta-sanitaria/0213-9111",
    urltwo: "NA"
  },
  {
    id: "gait-and-posture",
    text: "Gait &amp; Posture",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "galatasaray-universitesi-sosyal-bilimler-enstitusu",
    text: "Galatasaray Ãœniversitesi Sosyal Bilimler EnstitÃ¼sÃ¼",
    parent: "NA",
    urlone: "http://dosya.gsu.edu.tr/Sayfalar/2019/2/tez-yazim-kilavuzu-645.doc",
    urltwo: "NA"
  },
  {
    id: "galaxies",
    text: "Galaxies",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "gallia-prehistoire",
    text: "Gallia PrÃ©histoire (French)",
    parent: "NA",
    urlone: "http://www.persee.fr/collection/galip",
    urltwo: "https://journals.openedition.org/galliap/430"
  },
  {
    id: "gallia",
    text: "Gallia (French)",
    parent: "NA",
    urlone: "http://www.mae.u-paris10.fr/recommandations-aux-auteurs-gallia/",
    urltwo: "NA"
  },
  {
    id: "games-and-economic-behavior",
    text: "Games and Economic Behavior",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "games",
    text: "Games",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "gastric-cancer",
    text: "Gastric Cancer",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gastroenterologia-y-hepatologia",
    text: "GastroenterologÃ­a y HepatologÃ­a (Spanish)",
    parent: "medicina-clinica",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gastroenterologie-up2date",
    text: "Gastroenterologie up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "gastroenterology",
    text: "Gastroenterology",
    parent: "NA",
    urlone: "http://gastrojournal.org/authorinfo",
    urltwo: "NA"
  },
  {
    id: "gastrointestinal-endoscopy-clinics-of-north-america",
    text: "Gastrointestinal Endoscopy Clinics of North America",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/gastrointestinal-endoscopy-clinics-of-north-america/1052-5157/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "gastrointestinal-endoscopy",
    text: "Gastrointestinal Endoscopy",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.com/journals/gastrointestinal-endoscopy/0016-5107/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "gastrointestinal-intervention",
    text: "Gastrointestinal Intervention",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/gastrointestinal-intervention/2213-1795/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "gastrointestinal-tumors",
    text: "Gastrointestinal Tumors",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/259898",
    urltwo: "NA"
  },
  {
    id: "gazeta-medica",
    text: "Gazeta MÃ©dica",
    parent: "NA",
    urlone: "http://www.gazetamedica.pt/index.php/gazeta/about/submissions",
    urltwo: "NA"
  },
  {
    id: "geburtshilfe-und-frauenheilkunde",
    text: "Geburtshilfe und Frauenheilkunde",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "gefasschirurgie",
    text: "GefÃ¤sschirurgie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/surgery/journal/772?detailsPage=pltci_1288075",
    urltwo: "NA"
  },
  {
    id: "gefassmedizin-scan",
    text: "GefÃ¤ÃŸmedizin Scan",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "geistes-und-kulturwissenschaften-heilmann",
    text: "Geistes- und Kulturwissenschaften (Heilmann) (German)",
    parent: "NA",
    urlone: "http://www.tillheilmann.info/english.php",
    urltwo: "NA"
  },
  {
    id: "gels",
    text: "Gels",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "gem-international-journal-on-geomathematics",
    text: "GEM - International Journal on Geomathematics",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gender-and-education",
    text: "Gender and Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CGEE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "gender-issues",
    text: "Gender Issues",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gender-medicine",
    text: "Gender Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gender-place-and-culture",
    text: "Gender, Place &amp; Culture",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CGPC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "gene-expression-patterns",
    text: "Gene Expression Patterns",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gene-reports",
    text: "Gene Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gene-therapy",
    text: "Gene Therapy",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-gt.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "gene-x",
    text: "Gene: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gene",
    text: "Gene",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "genealogy",
    text: "Genealogy",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "general-and-comparative-endocrinology",
    text: "General and Comparative Endocrinology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "general-hospital-psychiatry",
    text: "General Hospital Psychiatry",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "general-relativity-and-gravitation",
    text: "General Relativity and Gravitation",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "general-thoracic-and-cardiovascular-surgery",
    text: "General Thoracic and Cardiovascular Surgery",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "generic-style-rules-for-linguistics",
    text: "Generic Style Rules for Linguistics",
    parent: "NA",
    urlone: "https://www.eva.mpg.de/linguistics/past-research-resources/resources/generic-style-rules.html",
    urltwo: "NA"
  },
  {
    id: "genes-and-cancer",
    text: "Genes &amp; Cancer",
    parent: "oncotarget",
    urlone: "http://www.impactjournals.com/Genes&amp;Cancer",
    urltwo: "NA"
  },
  {
    id: "genes-and-development",
    text: "Genes &amp; Development",
    parent: "cold-spring-harbor-laboratory-press",
    urlone: "http://www.genesdev.org/misc/ifora.shtml",
    urltwo: "NA"
  },
  {
    id: "genes-and-diseases",
    text: "Genes &amp; Diseases",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "genes-and-environment",
    text: "Genes and Environment",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "genes-and-genomics",
    text: "Genes &amp; Genomics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "genes-and-immunity",
    text: "Genes &amp; Immunity",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-gene.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "genes-and-nutrition",
    text: "Genes &amp; Nutrition",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "genes-brain-and-behavior",
    text: "Genes, Brain and Behavior",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1601-183X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "genes-to-cells",
    text: "Genes to Cells",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2443/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "genes",
    text: "Genes",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "geneses",
    text: "GenÃ¨ses: Sciences sociales et histoire (French)",
    parent: "NA",
    urlone: "http://geneses.hypotheses.org/la-revue/2",
    urltwo: "NA"
  },
  {
    id: "genetic-programming-and-evolvable-machines",
    text: "Genetic Programming and Evolvable Machines",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "genetic-resources-and-crop-evolution",
    text: "Genetic Resources and Crop Evolution",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "genetica",
    text: "Genetica",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "genetics-and-molecular-biology",
    text: "Genetics and Molecular Biology",
    parent: "NA",
    urlone: "http://www.scielo.br/revistas/gmb/iinstruc.htm",
    urltwo: "NA"
  },
  {
    id: "genetics-in-medicine",
    text: "Genetics in Medicine",
    parent: "american-medical-association",
    urlone: "https://www.nature.com/gim/authors-and-referees/authors",
    urltwo: "NA"
  },
  {
    id: "genetics-selection-evolution",
    text: "Genetics Selection Evolution",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "genetics",
    text: "Genetics",
    parent: "NA",
    urlone: "http://www.genetics.org/content/prep-manuscript#references",
    urltwo: "NA"
  },
  {
    id: "genome-announcements",
    text: "Genome Announcements",
    parent: "american-society-for-microbiology",
    urlone: "http://genomea.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "genome-biology-and-evolution",
    text: "Genome Biology and Evolution",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/gbe/for_authors/",
    urltwo: "NA"
  },
  {
    id: "genome-biology",
    text: "Genome Biology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "genome-medicine",
    text: "Genome Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "genome-research",
    text: "Genome Research",
    parent: "cold-spring-harbor-laboratory-press",
    urlone: "http://genome.cshlp.org/misc/ifora_mspreparation.shtml",
    urltwo: "NA"
  },
  {
    id: "genome",
    text: "Genome",
    parent: "canadian-journal-of-fisheries-and-aquatic-sciences",
    urlone: "http://www.nrcresearchpress.com/page/gen/authors",
    urltwo: "NA"
  },
  {
    id: "genomic-medicine-biomarkers-and-health-sciences",
    text: "Genomic Medicine, Biomarkers, and Health Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "genomics-and-informatics",
    text: "Genomics &amp; Informatics",
    parent: "NA",
    urlone: "https://genominfo.org/authors/authors.php#2",
    urltwo: "NA"
  },
  {
    id: "genomics-data",
    text: "Genomics Data",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "genomics-proteomics-and-bioinformatics",
    text: "Genomics, Proteomics &amp; Bioinformatics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "genomics",
    text: "Genomics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geo-marine-letters",
    text: "Geo-Marine Letters",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geoarchaeology",
    text: "Geoarchaeology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291520-6548/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "geobiology",
    text: "Geobiology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291472-4669/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "geobios",
    text: "Geobios",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geocarto-international",
    text: "Geocarto International",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TGEI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "geochemical-perspectives-letters",
    text: "Geochemical Perspectives Letters",
    parent: "NA",
    urlone: "http://www.geochemicalperspectivesletters.org/information-for-authors",
    urltwo: "NA"
  },
  {
    id: "geochemical-transactions",
    text: "Geochemical Transactions",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geochemistry-exploration-environment-analysis",
    text: "Geochemistry: Exploration, Environment, Analysis",
    parent: "the-geological-society-of-london",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "geochemistry-geophysics-geosystems",
    text: "Geochemistry, Geophysics, Geosystems",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "geochemistry",
    text: "Geochemistry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geochimica-et-cosmochimica-acta",
    text: "Geochimica et Cosmochimica Acta",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/geochimica-et-cosmochimica-acta/0016-7037/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "geochronometria",
    text: "Geochronometria",
    parent: "NA",
    urlone: "http://www.geochronometria.pl/pdf/Instr_for_Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "geoderma-regional",
    text: "Geoderma Regional",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geoderma",
    text: "Geoderma",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geodesy-and-geodynamics",
    text: "Geodesy and Geodynamics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geodinamica-acta",
    text: "Geodinamica Acta",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TGDA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "geodiversitas",
    text: "Geodiversitas",
    parent: "museum-national-dhistoire-naturelle",
    urlone:
      "http://sciencepress.mnhn.fr/sites/default/files/periodiques/pdf/ia-geodiversitas-2018-online-only-en_0.pdf",
    urltwo: "NA"
  },
  {
    id: "geoenvironmental-disasters",
    text: "Geoenvironmental Disasters",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geoforum",
    text: "Geoforum",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geografia-fisica-e-dinamica-quaternaria",
    text: "Geografia Fisica e Dinamica Quaternaria",
    parent: "NA",
    urlone: "http://gfdq.glaciologia.it/submitting/",
    urltwo: "NA"
  },
  {
    id: "geografie-sbornik-cgs",
    text: "Geografie SbornÃ­k ÄŒGS",
    parent: "NA",
    urlone: "http://geography.cz/sbornik/about-proceedings/instructions-for-authors/",
    urltwo: "NA"
  },
  {
    id: "geografisk-tidsskrift-danish-journal-of-geography",
    text: "Geografisk Tidsskrift-Danish Journal of Geography",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RDGS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "geographica-helvetica",
    text: "Geographica Helvetica",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geographie-et-cultures",
    text: "GÃ©ographie et cultures (French)",
    parent: "NA",
    urlone: "https://journals.openedition.org/gc/605",
    urltwo: "NA"
  },
  {
    id: "geographische-zeitschrift",
    text: "Geographische Zeitschrift",
    parent: "NA",
    urlone: "http://www.steiner-verlag.de/uploads/media/2019-08_MB_Zitierrichtlinien_GZ_engl.pdf",
    urltwo: "NA"
  },
  {
    id: "geoheritage",
    text: "Geoheritage",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geoinformatica",
    text: "GeoInformatica",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geojournal",
    text: "GeoJournal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geological-magazine",
    text: "Geological Magazine",
    parent: "NA",
    urlone: "http://journals.cambridge.org/action/displayJournal?jid=GEO",
    urltwo: "NA"
  },
  {
    id: "geological-society-london-memoirs",
    text: "Geological Society, London, Memoirs",
    parent: "the-geological-society-of-london",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "geological-society-london-special-publications",
    text: "Geological Society, London, Special Publications",
    parent: "the-geological-society-of-london",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "geological-society-of-america-bulletin",
    text: "Geological Society of America Bulletin",
    parent: "the-geological-society-of-america",
    urlone: "http://gsabulletin.gsapubs.org/",
    urltwo: "NA"
  },
  {
    id: "geology-of-the-intermountain-west",
    text: "Geology of the Intermountain West",
    parent: "utah-geological-survey",
    urlone: "http://www.utahgeology.org/wp/publications/geology-of-the-intermountain-west",
    urltwo: "NA"
  },
  {
    id: "geology",
    text: "Geology",
    parent: "the-geological-society-of-america",
    urlone: "http://geosociety.org/GSA/Publications/Journals/Geology/GSA/Pubs/geology/home.aspx",
    urltwo: "NA"
  },
  {
    id: "geomatics-natural-hazards-and-risk",
    text: "Geomatics, Natural Hazards and Risk",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TGNH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "geomechanics-and-geophysics-for-geo-energy-and-geo-resources",
    text: "Geomechanics and Geophysics for Geo-Energy and Geo-Resources",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geomechanics-for-energy-and-the-environment",
    text: "Geomechanics for Energy and the Environment",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geometriae-dedicata",
    text: "Geometriae Dedicata",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geomorphology",
    text: "Geomorphology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geophysical-journal-international",
    text: "Geophysical Journal International",
    parent: "NA",
    urlone: "https://academic.oup.com/gji/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "geophysical-research-letters",
    text: "Geophysical Research Letters",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "geophysics",
    text: "Geophysics",
    parent: "NA",
    urlone: "https://seg.org/Publications/Journals/-Geophysics/Information-for-Authors/Instructions-to-authors",
    urltwo: "NA"
  },
  {
    id: "geopolitics",
    text: "Geopolitics",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/journal.asp?issn=1465-0045&amp;linktype=44",
    urltwo: "NA"
  },
  {
    id: "georesj",
    text: "GeoResJ",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "georg-august-universitat-gottingen-institut-fur-ethnologie-und-ethnologische-sammlung",
    text: "Georg-August-UniversitÃ¤t GÃ¶ttingen - Institut fÃ¼r Ethnologie und Ethnologische Sammlung (German)",
    parent: "NA",
    urlone: "http://www.uni-goettingen.de/de/86102.html",
    urltwo: "NA"
  },
  {
    id: "georisk-assessment-and-management-of-risk-for-engineered-systems-and-geohazards",
    text: "Georisk: Assessment and Management of Risk for Engineered Systems and Geohazards",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NGRK20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "geoscience-communication",
    text: "Geoscience Communication",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geoscience-letters",
    text: "Geoscience Letters",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geosciences",
    text: "Geosciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "geoscientific-instrumentation-methods-and-data-systems",
    text: "Geoscientific Instrumentation, Methods and Data Systems",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geoscientific-model-development",
    text: "Geoscientific Model Development",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geosphere",
    text: "Geosphere",
    parent: "the-geological-society-of-america",
    urlone: "http://geosphere.gsapubs.org/",
    urltwo: "NA"
  },
  {
    id: "geosystem-engineering",
    text: "Geosystem Engineering",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TGES20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "geotechnical-and-geological-engineering",
    text: "Geotechnical and Geological Engineering",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geotextiles-and-geomembranes",
    text: "Geotextiles and Geomembranes",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geothermal-energy",
    text: "Geothermal Energy",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "geothermics",
    text: "Geothermics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geriatric-mental-health-care",
    text: "Geriatric Mental Health Care",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geriatric-nursing",
    text: "Geriatric Nursing",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "geriatrics",
    text: "Geriatrics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "geriatrie-et-psychologie-neuropsychiatrie-du-vieillissement",
    text: "GÃ©riatrie et Psychologie Neuropsychiatrie du Vieillissement",
    parent: "NA",
    urlone: "https://www.jle.com/fr/revues/gpn/espace_auteur",
    urltwo: "NA"
  },
  {
    id: "german-council-of-economic-experts",
    text: "German Council of Economic Experts",
    parent: "NA",
    urlone:
      "https://www.sachverstaendigenrat-wirtschaft.de/fileadmin/dateiablage/Sonstiges/SVR_Styleguide_Literaturverzeichnis_Stand_16.03.2017.pdf",
    urltwo: "NA"
  },
  {
    id: "german-yearbook-of-international-law",
    text: "German Yearbook of International Law",
    parent: "NA",
    urlone: "https://www.wsi.uni-kiel.de/de/forschung/gyil/gyil-style-sheet-2018",
    urltwo: "NA"
  },
  {
    id: "gerontology",
    text: "Gerontology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0003",
    urltwo: "NA"
  },
  {
    id: "geropsych-the-journal-of-gerontopsychology-and-geriatric-psychiatry",
    text: "GeroPsych: The Journal of Gerontopsychology and Geriatric Psychiatry",
    parent: "apa",
    urlone: "http://content.apa.org/journals/gro",
    urltwo: "NA"
  },
  {
    id: "gesellschaft-fur-popularmusikforschung",
    text: "Gesellschaft fur Popularmusikforschung (German)",
    parent: "NA",
    urlone: "http://www.aspm-samples.de/hinweise.html",
    urltwo: "NA"
  },
  {
    id: "gesunde-pflanzen",
    text: "Gesunde Pflanzen (German)",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gewerblicher-rechtsschutz-und-urheberrecht",
    text: "Gewerblicher Rechtsschutz und Urheberrecht (German)",
    parent: "NA",
    urlone: "http://www.grur.org/fileadmin/daten_bilder/zeitschriften/GRUR-Redaktionsrichtlinien-Zeitschrift-GRUR.pdf",
    urltwo: "NA"
  },
  {
    id: "gezinstherapie-wereldwijd",
    text: "Gezinstherapie Wereldwijd (Dutch)",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gigascience",
    text: "GigaScience",
    parent: "NA",
    urlone: "https://academic.oup.com/gigascience/pages/instructions_to_authors",
    urltwo: "NA"
  },
  {
    id: "giscience-and-remote-sensing",
    text: "GIScience &amp; Remote Sensing",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TGRS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "glass-structures-and-engineering",
    text: "Glass Structures &amp; Engineering",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "glia",
    text: "GLIA",
    parent: "the-journal-of-comparative-neurology",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291098-1136/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "global-and-planetary-change",
    text: "Global and Planetary Change",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "global-biogeochemical-cycles",
    text: "Global Biogeochemical Cycles",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "global-business-perspectives",
    text: "Global Business Perspectives",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "global-change-biology",
    text: "Global Change Biology",
    parent: "apa",
    urlone: "https://onlinelibrary.wiley.com/page/journal/13652486/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "global-crime",
    text: "Global Crime",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FGLC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "global-discourse",
    text: "Global Discourse",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RGLD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "global-ecology-and-biogeography",
    text: "Global Ecology and Biogeography",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291466-8238/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "global-ecology-and-conservation",
    text: "Global Ecology and Conservation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "global-economics-and-management-review",
    text: "Global Economics and Management Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "global-energy-interconnection",
    text: "Global Energy Interconnection",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "global-environmental-change",
    text: "Global Environmental Change",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "global-finance-journal",
    text: "Global Finance Journal",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "global-food-security",
    text: "Global Food Security",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "global-heart",
    text: "Global Heart",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "global-journal-of-flexible-systems-management",
    text: "Global Journal of Flexible Systems Management",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "global-public-health",
    text: "Global Public Health",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RGPH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "global-social-welfare",
    text: "Global Social Welfare",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "global-transitions",
    text: "Global Transitions",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "globalisation-societies-and-education",
    text: "Globalisation, Societies and Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CGSE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "globalization-and-health",
    text: "Globalization and Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "glossa",
    text: "Glossa",
    parent: "NA",
    urlone: "http://www.glossa-journal.org/about/submissions/#Style%20sheet",
    urltwo: "NA"
  },
  {
    id: "glycoconjugate-journal",
    text: "Glycoconjugate Journal",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gm-crops-and-food-biotechnology-in-agriculture-and-the-food-chain",
    text: "GM Crops and Food: Biotechnology in Agriculture and the Food Chain",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gms-medizin-bibliothek-information",
    text: "GMS Medizin - Bibliothek - Information",
    parent: "vancouver",
    urlone: "http://www.egms.de/static/de/journals/mbi/about.htm",
    urltwo: "NA"
  },
  {
    id: "gold-bulletin",
    text: "Gold Bulletin",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gondwana-research",
    text: "Gondwana Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gost-r-7-0-5-2008-numeric-alphabetical",
    text: "Russian GOST R 7.0.5-2008 (numeric, sorted alphabetically, Russian)",
    parent: "NA",
    urlone: "http://protect.gost.ru/document.aspx?control=7&amp;id=173511",
    urltwo: "http://standartgost.ru/g/%D0%93%D0%9E%D0%A1%D0%A2_%D0%A0_7.0.5-2008"
  },
  {
    id: "gost-r-7-0-5-2008-numeric",
    text: "Russian GOST R 7.0.5-2008 (numeric)",
    parent: "NA",
    urlone: "http://protect.gost.ru/document.aspx?control=7&amp;id=173511",
    urltwo: "NA"
  },
  {
    id: "gost-r-7-0-5-2008",
    text: "Russian GOST R 7.0.5-2008 (Russian)",
    parent: "NA",
    urlone: "http://protect.gost.ru/document.aspx?control=7&amp;id=173511",
    urltwo: "NA"
  },
  {
    id: "government-and-opposition",
    text: "Government and Opposition",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/journals/government-and-opposition/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "government-information-quarterly",
    text: "Government Information Quarterly",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gps-solutions",
    text: "GPS Solutions",
    parent: "springer-basic-author-date-no-et-al-with-issue",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "graefes-archive-for-clinical-and-experimental-ophthalmology",
    text: "Graefe's Archive for Clinical and Experimental Ophthalmology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "granular-computing",
    text: "Granular Computing",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "granular-matter",
    text: "Granular Matter",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "graphical-models",
    text: "Graphical Models",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "graphs-and-combinatorics",
    text: "Graphs and Combinatorics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "grasas-y-aceites",
    text: "Grasas y Aceites",
    parent: "NA",
    urlone: "http://grasasyaceites.revistas.csic.es/index.php/grasasyaceites/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "green-chemistry",
    text: "Green Chemistry",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "green-energy-and-environment",
    text: "Green Energy &amp; Environment",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "green-finance",
    text: "Green Finance",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/333.html",
    urltwo: "NA"
  },
  {
    id: "green-letters",
    text: "Green Letters",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RGRL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "greenhouse-gas-measurement-and-management",
    text: "Greenhouse Gas Measurement and Management",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TGMM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "griffith-college-harvard",
    text: "Griffith College - Harvard",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/4027#issuecomment-479049130",
    urltwo: "NA"
  },
  {
    id: "groundwater-for-sustainable-development",
    text: "Groundwater for Sustainable Development",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "group-decision-and-negotiation",
    text: "Group Decision and Negotiation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "group-dynamics-theory-research-and-practice",
    text: "Group Dynamics: Theory, Research, and Practice",
    parent: "apa",
    urlone: "http://content.apa.org/journals/gdn",
    urltwo: "NA"
  },
  {
    id: "groupe-danthropologie-et-darcheologie-funeraire",
    text: "Groupe d'anthropologie et d'archÃ©ologie funÃ©raire (French)",
    parent: "NA",
    urlone:
      "https://www.gaaf-asso.fr/wp-content/uploads/Collection-Publication-du-Gaaf_Recommandations_auteurs_DEF-V3.pdf",
    urltwo: "NA"
  },
  {
    id: "growth-hormone-and-igf-research",
    text: "Growth Hormone &amp; IGF Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "grundwasser",
    text: "Grundwasser (German)",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gruppe-interaktion-organisation-zeitschrift-fur-angewandte-organisationspsychologie",
    text: "Gruppe. Interaktion. Organisation. Zeitschrift fÃ¼r Angewandte Organisationspsychologie (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gsa-today",
    text: "GSA Today",
    parent: "the-geological-society-of-america",
    urlone: "http://www.geosociety.org/gsatoday/",
    urltwo: "NA"
  },
  {
    id: "guide-des-citations-references-et-abreviations-juridiques",
    text: "Guide des citations, rÃ©fÃ©rences et abrÃ©viations juridiques 6e Ã©dition (French)",
    parent: "NA",
    urlone: "https://legalworld.wolterskluwer.be/media/4562/guide-des-citations-references-abreviations-juridiques.pdf",
    urltwo:
      "https://github.com/citation-style-language/styles/files/5551152/Guide.des.citation.references.et.abreviations.juridiques.CSL.docx"
  },
  {
    id: "guide-pour-la-redaction-et-la-presentation-des-theses-a-lusage-des-doctorants",
    text: "Guide pour la rÃ©daction et la prÃ©sentation des thÃ¨ses Ã  l'usage des doctorants (French)",
    parent: "NA",
    urlone:
      "https://bibliotheque.univ-paris-diderot.fr/sites/default/files/fichiers/guide_doct_ministere_education.pdf",
    urltwo: "NA"
  },
  {
    id: "gut-microbes",
    text: "Gut Microbes",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gut-pathogens",
    text: "Gut Pathogens",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gut",
    text: "Gut",
    parent: "bmj",
    urlone: "http://gut.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "gynakologische-endokrinologie",
    text: "GynÃ¤kologische Endokrinologie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/gynecology/journal/10304?detailsPage=pltci_1750753",
    urltwo: "NA"
  },
  {
    id: "gynecologic-and-obstetric-investigation",
    text: "Gynecologic and Obstetric Investigation",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-002X",
    urltwo: "NA"
  },
  {
    id: "gynecologic-oncology-reports",
    text: "Gynecologic Oncology Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gynecologic-oncology-research-and-practice",
    text: "Gynecologic Oncology Research and Practice",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gynecologic-oncology",
    text: "Gynecologic Oncology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gynecological-surgery",
    text: "Gynecological Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "gynecologie-obstetrique-et-fertilite",
    text: "GynÃ©cologie ObstÃ©trique et FertilitÃ©",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gynecologie-obstetrique-fertilite-and-senologie",
    text: "GynÃ©cologie ObstÃ©trique FertilitÃ© &amp; SÃ©nologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "gynecology-and-minimally-invasive-therapy",
    text: "Gynecology and Minimally Invasive Therapy",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "h-bioscience",
    text: "h bioscience",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "habitat-international",
    text: "Habitat International",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "haematologica",
    text: "Haematologica",
    parent: "NA",
    urlone: "http://www.haematologica.org/information-for-authors",
    urltwo: "NA"
  },
  {
    id: "haemophilia",
    text: "Haemophilia",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/13652516/homepage/ForAuthors.html#preparing",
    urltwo: "NA"
  },
  {
    id: "hague-journal-on-the-rule-of-law",
    text: "Hague Journal on the Rule of Law",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hainan-medical-university-journal-publisher",
    text: "Hainan Medical University Journal Publisher",
    parent: "NA",
    urlone: "http://www.hnmujp.org/guide.html",
    urltwo: "NA"
  },
  {
    id: "hamburg-school-of-food-science",
    text: "Hamburg School of Food Science (diploma, German)",
    parent: "NA",
    urlone: "http://hsfs.org/",
    urltwo: "NA"
  },
  {
    id: "hamipla-handchirurgie-mikrochirurgie-plastische-chirurgie",
    text: "HaMiPla - Handchirurgie - Mikrochirurgie - Plastische Chirurgie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "hand-surgery-and-rehabilitation",
    text: "Hand Surgery and Rehabilitation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hand",
    text: "HAND",
    parent: "NA",
    urlone: "http://www.springer.com/medicine/surgery/journal/11552",
    urltwo: "NA"
  },
  {
    id: "handbook-of-clinical-neurology",
    text: "Handbook of Clinical Neurology",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/files/1008518/HCN_Contributor_Instructions_EMSS.docx",
    urltwo: "https://www.elsevier.com/books/book-series/handbook-of-clinical-neurology"
  },
  {
    id: "handchirurgie-scan",
    text: "Handchirurgie Scan",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "hardwarex",
    text: "HardwareX",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "harm-reduction-journal",
    text: "Harm Reduction Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "harmful-algae",
    text: "Harmful Algae",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "harper-adams-university-harvard",
    text: "Harper Adams University - Harvard",
    parent: "NA",
    urlone: "https://www.harper-adams.ac.uk/university-life/library/study-skills/citing-and-referencing.cfm",
    urltwo: "https://github.com/cpina/harvard-harper-adams-university-citation-style"
  },
  {
    id: "harvard-anglia-ruskin-university",
    text: "Anglia Ruskin University - Harvard",
    parent: "NA",
    urlone: "http://libweb.anglia.ac.uk/referencing/harvard.htm",
    urltwo: "NA"
  },
  {
    id: "harvard-bournemouth-university",
    text: "Bournemouth University - Harvard",
    parent: "NA",
    urlone: "http://www.bournemouth.ac.uk/library/local-assets/how-to/docs/citing-references.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-cape-peninsula-university-of-technology",
    text: "Cape Peninsula University of Technology - Harvard",
    parent: "NA",
    urlone: "http://www.cput.ac.za/files/images_folder/cpgs/Harvard.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-cardiff-university",
    text: "Cardiff University - Harvard",
    parent: "NA",
    urlone: "https://xerte.cardiff.ac.uk/play_4069",
    urltwo: "https://xerte.cardiff.ac.uk/play_4191"
  },
  {
    id: "harvard-cite-them-right-no-et-al",
    text: 'Cite Them Right 10th edition - Harvard (no "et al.")',
    parent: "NA",
    urlone: "http://www.citethemrightonline.com/",
    urltwo: "NA"
  },
  {
    id: "harvard-cite-them-right",
    text: "Cite Them Right 10th edition - Harvard",
    parent: "NA",
    urlone: "http://www.citethemrightonline.com/",
    urltwo: "NA"
  },
  {
    id: "harvard-coventry-university",
    text: "Coventry University - Harvard",
    parent: "NA",
    urlone:
      "http://www.coventry.ac.uk/Global/03%20Study%20section%20assets/Academic%20Support/CAW/Sept%2013_%20Harvard%20Guide%20v3%200%202.pdf",
    urltwo:
      "http://www.coventry.ac.uk/study-at-coventry/student-support/academic-support/centre-for-academic-writing/support-for-students/academic-writing-resources/cu-harvard-reference-style-guide/"
  },
  {
    id: "harvard-cranfield-university",
    text: "Cranfield University - Harvard",
    parent: "NA",
    urlone:
      "http://www.som.cranfield.ac.uk/som/dinamic-content/media/mirc/Referencing/AuthorDateReferencingGuide2014.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-deakin-university",
    text: "Deakin University - Harvard",
    parent: "NA",
    urlone: "http://www.deakin.edu.au/students/studying/study-support/referencing/harvard",
    urltwo: "NA"
  },
  {
    id: "harvard-dundalk-institute-of-technology",
    text: "Dundalk Institute of Technology - Harvard",
    parent: "NA",
    urlone: "https://www.dkit.ie/library/support/guide-harvard-referencing",
    urltwo: "NA"
  },
  {
    id: "harvard-durham-university-business-school",
    text: "Durham University Business School - Harvard",
    parent: "NA",
    urlone: "http://www.dur.ac.uk/resources/library/teaching/writingyourbibliography.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-edge-hill-university",
    text: "Edge Hill University - Harvard",
    parent: "NA",
    urlone: "http://www.edgehill.ac.uk/ls/assignment-help/help-with-referencing/",
    urltwo: "NA"
  },
  {
    id: "harvard-educational-review",
    text: "Harvard Educational Review",
    parent: "apa",
    urlone: "http://www.hepg.org/page/20",
    urltwo: "NA"
  },
  {
    id: "harvard-european-archaeology",
    text: "European Archaeology - Harvard",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "harvard-fachhochschule-salzburg",
    text: "Fachhochschule Salzburg - Harvard",
    parent: "NA",
    urlone: "http://www.users.fh-salzburg.ac.at/~bhechenl/mw/images/RichtigReferenzieren.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-falmouth-university",
    text: "Falmouth University - Harvard",
    parent: "NA",
    urlone: "http://ask.fxplus.ac.uk/harvard-falmouth",
    urltwo: "NA"
  },
  {
    id: "harvard-gesellschaft-fur-bildung-und-forschung-in-europa",
    text: "Gesellschaft fÃ¼r Bildung und Forschung in Europa - Harvard (German)",
    parent: "NA",
    urlone: "http://gbfe.org/",
    urltwo: "http://www.acf.de/uploads/media/GBFE_Studienbrief_5_Form_bewahren_01.pdf"
  },
  {
    id: "harvard-imperial-college-london",
    text: "Imperial College London - Harvard",
    parent: "NA",
    urlone: "http://www.imperial.ac.uk/admin-services/library/learning-support/reference-management/harvard-style/",
    urltwo: "NA"
  },
  {
    id: "harvard-institut-fur-praxisforschung-de",
    text: "Institut fÃ¼r Praxisforschung - Harvard (Bahr &amp; Frackmann) (German)",
    parent: "NA",
    urlone: "http://www.institut-praxisforschung.com/app/download/6167762784/Harvard-Zitierweise.pdf?t=1410296308",
    urltwo: "http://www.institut-praxisforschung.com/publikationen/studienhilfen/"
  },
  {
    id: "harvard-kings-college-london",
    text: "King's College London - Harvard",
    parent: "NA",
    urlone: "http://www.kcl.ac.uk/library/help/plagiarism/citing/index.aspx",
    urltwo: "NA"
  },
  {
    id: "harvard-leeds-beckett-university",
    text: "Leeds Beckett University - Harvard",
    parent: "NA",
    urlone: "http://skillsforlearning.leedsbeckett.ac.uk/local/Quote_Unquote.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-leeds-metropolitan-university",
    text: "Leeds Metropolitan University - Harvard",
    parent: "NA",
    urlone: "http://skillsforlearning.leedsmet.ac.uk/Quote_Unquote.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-limerick",
    text: "University of Limerick (Cite it Right) - Harvard",
    parent: "NA",
    urlone: "http://www3.ul.ie/~library/pdf/citeitright.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-london-south-bank-university",
    text: "London South Bank University - Harvard",
    parent: "NA",
    urlone: "https://my.lsbu.ac.uk/my/applicant/Current-Applicants/Library/How-to-guides",
    urltwo: "NA"
  },
  {
    id: "harvard-manchester-business-school",
    text: "Manchester Business School - Harvard",
    parent: "NA",
    urlone: "http://www.library.manchester.ac.uk/academicsupport/referencing/",
    urltwo: "NA"
  },
  {
    id: "harvard-manchester-metropolitan-university",
    text: "Manchester Metropolitan University - Harvard",
    parent: "NA",
    urlone: "http://libguides.mmu.ac.uk/content.php?pid=484964&amp;sid=4325239",
    urltwo: "NA"
  },
  {
    id: "harvard-melbourne-polytechnic",
    text: "Melbourne Polytechnic - Harvard",
    parent: "NA",
    urlone: "https://melbournepolytechnic.spydus.com/reference.html",
    urltwo: "NA"
  },
  {
    id: "harvard-newcastle-university",
    text: "Newcastle University - Harvard",
    parent: "NA",
    urlone: "http://libguides.ncl.ac.uk/referencing",
    urltwo: "NA"
  },
  {
    id: "harvard-north-west-university",
    text: "North-West University - Harvard",
    parent: "NA",
    urlone: "https://libguides.nwu.ac.za/harvard-referencing/home",
    urltwo: "NA"
  },
  {
    id: "harvard-oxford-brookes-university-faculty-of-health-and-life-sciences",
    text: "Oxford Brookes University - Faculty of Health and Life Sciences - Harvard",
    parent: "NA",
    urlone: "https://shsc-int.brookes.ac.uk/documents/browse.php?fFolderId=53",
    urltwo: "NA"
  },
  {
    id: "harvard-pontificia-universidad-catolica-del-ecuador",
    text: "Pontificia Universidad CatÃ³lica del Ecuador (Spanish) - Harvard",
    parent: "NA",
    urlone: "http://www.puce.edu.ec/economia/docs/2012/Normas_Harvard_-_APA_ejemplos.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-robert-gordon-university",
    text: "Robert Gordon University - Harvard",
    parent: "NA",
    urlone: "https://libguides.rgu.ac.uk/harvard-referencing-templates",
    urltwo: "NA"
  },
  {
    id: "harvard-staffordshire-university",
    text: "Staffordshire University - Harvard",
    parent: "NA",
    urlone: "http://www.staffs.ac.uk/support_depts/infoservices/learning_support/refzone/harvard/index.jsp",
    urltwo: "NA"
  },
  {
    id: "harvard-stellenbosch-university",
    text: "Stellenbosch University - Harvard",
    parent: "NA",
    urlone: "http://libguides.sun.ac.za/content.php?pid=344501&amp;sid=2818229",
    urltwo: "NA"
  },
  {
    id: "harvard-swinburne-university-of-technology",
    text: "Swinburne University of Technology - Harvard",
    parent: "NA",
    urlone: "http://www.lib.monash.edu.au/tutorials/citing/harvard.html",
    urltwo: "http://www.swinburne.edu.au/lib/researchhelp/harvard_system.htm"
  },
  {
    id: "harvard-the-university-of-melbourne",
    text: "The University of Melbourne - Harvard",
    parent: "NA",
    urlone: "http://www.library.unimelb.edu.au/cite/",
    urltwo: "NA"
  },
  {
    id: "harvard-the-university-of-northampton",
    text: "The University of Northampton - Harvard",
    parent: "NA",
    urlone: "http://www.northampton.ac.uk/downloads/download/207/referencing-guides",
    urltwo: "NA"
  },
  {
    id: "harvard-the-university-of-sheffield-school-of-east-asian-studies",
    text: "The University of Sheffield - School of East Asian Studies - Harvard",
    parent: "NA",
    urlone: "http://www.librarydevelopment.group.shef.ac.uk/isr.html",
    urltwo: "NA"
  },
  {
    id: "harvard-the-university-of-sheffield-town-and-regional-planning",
    text: "The University of Sheffield - Town and Regional Planning - Harvard",
    parent: "NA",
    urlone: "http://www.librarydevelopment.group.shef.ac.uk/referencing.html",
    urltwo: "NA"
  },
  {
    id: "harvard-theologisches-seminar-adelshofen",
    text: "Theologisches Seminar Adelshofen - Harvard (German)",
    parent: "NA",
    urlone: "http://www.lza.de/theologisches-seminar/",
    urltwo: "NA"
  },
  {
    id: "harvard-universiti-teknologi-malaysia",
    text: "Universiti Teknologi Malaysia - Harvard",
    parent: "NA",
    urlone: "http://www.sps.utm.my/sps/?option=com_content&amp;task=view&amp;id=279&amp;Itemid=",
    urltwo: "NA"
  },
  {
    id: "harvard-universiti-tunku-abdul-rahman",
    text: "Universiti Tunku Abdul Rahman - Harvard",
    parent: "NA",
    urlone: "http://research.utar.edu.my/ipsrweb/guideline.html",
    urltwo: "NA"
  },
  {
    id: "harvard-university-for-the-creative-arts",
    text: "University for the Creative Arts - Harvard",
    parent: "NA",
    urlone: "http://www.uca.ac.uk/library/academic-support/harvard-referencing/",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-abertay-dundee",
    text: "University of Abertay Dundee - Harvard",
    parent: "NA",
    urlone: "https://portal.abertay.ac.uk/portal/page/portal/Library/Referencing",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-bath",
    text: "University of Bath - Harvard",
    parent: "NA",
    urlone: "https://library.bath.ac.uk/referencing/harvard-bath",
    urltwo: "https://github.com/alex-ball/bathbib/"
  },
  {
    id: "harvard-university-of-birmingham",
    text: "University of Birmingham - Harvard",
    parent: "NA",
    urlone: "https://intranet.birmingham.ac.uk/as/libraryservices/icite/referencing/harvard/index.aspx",
    urltwo: "http://www.oak-wood.co.uk/oss/birmingham-harvard-csl"
  },
  {
    id: "harvard-university-of-brighton-school-of-environment-and-technology",
    text: "University of Brighton School of Environment &amp; Technology - Harvard",
    parent: "NA",
    urlone: "https://student.brighton.ac.uk/mod_docs/sci_eng/environment/course_handbooks/gyggskills.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-cape-town",
    text: "University of Cape Town - Harvard",
    parent: "NA",
    urlone: "http://www.lib.uct.ac.za/wp-content/uploads/2014/02/harvard-uct-2014.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-exeter-geography",
    text: "University of Exeter - Geography - Harvard",
    parent: "NA",
    urlone:
      "https://geography.exeter.ac.uk/media/universityofexeter/schoolofgeography/pdfs/currentstudents/Referencing_and_Style_Guide.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-gloucestershire",
    text: "University of Gloucestershire - Harvard",
    parent: "harvard-cite-them-right",
    urlone: "http://ist.glos.ac.uk/referencing/harvard/",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-greenwich",
    text: "University of Greenwich - Harvard",
    parent: "NA",
    urlone: "http://www.gre.ac.uk/studyskills/referencing",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-kent",
    text: "University of Kent - Harvard",
    parent: "NA",
    urlone: "http://www.kent.ac.uk/ai/Harvard-Style-Guide-July-2014.pdf",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-leeds",
    text: "University of Leeds - Harvard",
    parent: "NA",
    urlone: "https://library.leeds.ac.uk/info/1402/referencing/50/leeds_harvard_introduction",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-sunderland",
    text: "University of Sunderland - Harvard",
    parent: "harvard-cite-them-right",
    urlone: "https://library.sunderland.ac.uk/find-resources/referencing/",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-technology-sydney",
    text: "University of Technology Sydney - Harvard",
    parent: "NA",
    urlone: "https://www.lib.uts.edu.au/help/referencing/harvard-uts-referencing-guide",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-the-west-of-england",
    text: "University of the West of England (Bristol) - Harvard",
    parent: "NA",
    urlone: "http://www.uwe.ac.uk/library/resources/general/iskillzone/referencing/uweharvard/about.html",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-the-west-of-scotland",
    text: "University of the West of Scotland - Harvard",
    parent: "NA",
    urlone: "http://moodle.uws.ac.uk/pluginfile.php/322388/mod_resource/content/1/CoRE/index.htm",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-westminster",
    text: "University of Westminster - Harvard",
    parent: "NA",
    urlone: "https://libguides.westminster.ac.uk/referencing/examples",
    urltwo:
      "https://www.westminster.ac.uk/sites/default/public-files/general-documents/Referencing%20Your%20Work%20booklet_06.1.pdf"
  },
  {
    id: "harvard-university-of-wolverhampton",
    text: "University of Wolverhampton - Harvard",
    parent: "NA",
    urlone: "http://www.wlv.ac.uk/lib/skills_for_learning/referencing/harvard_referencing.aspx",
    urltwo: "NA"
  },
  {
    id: "harvard-university-of-worcester",
    text: "University of Worcester - Harvard",
    parent: "NA",
    urlone: "http://library.worc.ac.uk/guides/study-skills/referencing",
    urltwo: "NA"
  },
  {
    id: "harvard-york-st-john-university",
    text: "York St John University - Harvard",
    parent: "NA",
    urlone: "https://www.yorksj.ac.uk/ils/searchsuccess/harvardref/",
    urltwo: "NA"
  },
  {
    id: "harvard1",
    text: "Harvard reference format 1 (deprecated)",
    parent: "harvard-cite-them-right",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "haute-ecole-de-gestion-de-geneve-iso-690",
    text: "Haute Ã©cole de gestion de GenÃ¨ve - ISO-690 (English)",
    parent: "NA",
    urlone: "https://www.hesge.ch/heg/infotheque/citations-et-references-bibliographiques",
    urltwo: "NA"
  },
  {
    id: "haute-ecole-pedagogique-fribourg",
    text: "Haute Ecole pÃ©dagogique Fribourg (French)",
    parent: "NA",
    urlone: "https://www.hepfr.ch/sites/default/files/guide_apa_hepfr.pdf",
    urltwo: "NA"
  },
  {
    id: "hautnah",
    text: "hautnah (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hawaii-international-conference-on-system-sciences-proceedings",
    text: "Hawaii International Conference on System Sciences Proceedings",
    parent: "NA",
    urlone: "http://hicss.hawaii.edu/tracks-and-minitracks/authors/",
    urltwo: "NA"
  },
  {
    id: "hawaii-journal-of-medicine-and-public-health",
    text: "Hawai'i Journal of Medicine &amp; Public Health",
    parent: "american-medical-association",
    urlone: "http://hjmph.org/submit.htm",
    urltwo: "NA"
  },
  {
    id: "hbrc-journal",
    text: "HBRC Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "head-and-face-medicine",
    text: "Head &amp; Face Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "head-and-neck-pathology",
    text: "Head and Neck Pathology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "headache",
    text: "Headache",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291526-4610/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "health-and-human-rights-journal",
    text: "Health and Human Rights Journal",
    parent: "NA",
    urlone: "https://www.hhrjournal.org/submissions/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "health-and-justice",
    text: "Health &amp; Justice",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "health-and-place",
    text: "Health and Place",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "health-and-quality-of-life-outcomes",
    text: "Health and Quality of Life Outcomes",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "health-and-social-care-in-the-community",
    text: "Health &amp; Social Care in the Community",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2524/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "health-and-technology",
    text: "Health and Technology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "health-care-analysis",
    text: "Health Care Analysis",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "health-care-management-science",
    text: "Health Care Management Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "health-economics-policy-and-law",
    text: "Health Economics, Policy and Law",
    parent: "NA",
    urlone: "http://journals.cambridge.org/action/displayJournal?jid=HEP",
    urltwo: "NA"
  },
  {
    id: "health-economics-review",
    text: "Health Economics Review",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "health-economics",
    text: "Health Economics",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1099-1050/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "health-education-research",
    text: "Health Education Research",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/healed/for_authors/index.html",
    urltwo: "NA"
  },
  {
    id: "health-information-science-and-systems",
    text: "Health Information Science and Systems",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "health-policy-and-planning",
    text: "Health Policy and Planning",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/heapol/for_authors/index.html",
    urltwo: "NA"
  },
  {
    id: "health-policy-and-technology",
    text: "Health Policy and Technology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "health-policy",
    text: "Health policy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "health-professions-education",
    text: "Health Professions Education",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "health-psychology-and-behavioral-medicine-an-open-access-journal",
    text: "Health Psychology and Behavioral Medicine: an Open Access Journal",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RHPB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "health-psychology-review",
    text: "Health Psychology Review",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RHPR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "health-psychology",
    text: "Health Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/hea",
    urltwo: "NA"
  },
  {
    id: "health-reform-observer-observatoire-des-reformes-de-sante",
    text: "Health Reform Observer - Observatoire des RÃ©formes de SantÃ©",
    parent: "NA",
    urlone: "https://escarpmentpress.org/hro-ors/pages/view/how-to-format-ms",
    urltwo: "NA"
  },
  {
    id: "health-research-policy-and-systems",
    text: "Health Research Policy and Systems",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "health-risk-and-society",
    text: "Health, Risk &amp; Society",
    parent: "apa",
    urlone: "http://www.tandfonline.com/toc/chrs20/current#.U3qvAi_Wr7A",
    urltwo: "NA"
  },
  {
    id: "health-sa-gesondheid",
    text: "Health SA Gesondheid",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "health-services-and-delivery-research",
    text: "Health Services and Delivery Research",
    parent: "national-institute-of-health-research",
    urlone: "http://www.journalslibrary.nihr.ac.uk/information-for-authors/references",
    urltwo: "NA"
  },
  {
    id: "health-services-and-outcomes-research-methodology",
    text: "Health Services and Outcomes Research Methodology",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "health-services-research",
    text: "Health Services Research",
    parent: "american-medical-association",
    urlone: "https://www.hsr.org/system/files?file=media/file/2019/12/HSR-Instructions-for-Authors-2019-Update.pdf",
    urltwo: "NA"
  },
  {
    id: "health-technology-assessment",
    text: "Health Technology Assessment",
    parent: "national-institute-of-health-research",
    urlone: "http://www.journalslibrary.nihr.ac.uk/information-for-authors/references",
    urltwo: "NA"
  },
  {
    id: "healthcare-management-forum",
    text: "Healthcare Management Forum",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "healthcare-the-journal-of-delivery-science-and-innovation",
    text: "Healthcare: The Journal of Delivery Science and Innovation",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "healthcare",
    text: "Healthcare",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "hearing-research",
    text: "Hearing Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "heart-and-lung",
    text: "Heart &amp; Lung",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "heart-and-vessels",
    text: "Heart and Vessels",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "heart-asia",
    text: "Heart Asia",
    parent: "bmj",
    urlone: "http://heartasia.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "heart-failure-clinics",
    text: "Heart Failure Clinics",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/heart-failure-clinics/1551-7136/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "heart-failure-reviews",
    text: "Heart Failure Reviews",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "heart-lung-and-circulation",
    text: "Heart, Lung and Circulation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "heart-rhythm",
    text: "Heart Rhythm",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/heart-rhythm/1547-5271/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "heart",
    text: "Heart",
    parent: "bmj",
    urlone: "http://heart.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "heat-and-mass-transfer",
    text: "Heat and Mass Transfer",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "heat-exchanger-design-updates",
    text: "Heat Exchanger Design Updates",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/30fe767f5b70553a.html",
    urltwo: "NA"
  },
  {
    id: "heat-pipe-science-and-technology-an-international-journal",
    text: "Heat Pipe Science and Technology, An International Journal",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/4b0844fc3a2ef17f.html",
    urltwo: "NA"
  },
  {
    id: "heat-transfer-research",
    text: "Heat Transfer Research",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/46784ef93dddff27.html",
    urltwo: "NA"
  },
  {
    id: "hec-forum",
    text: "HEC Forum",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "heidelberg-university-faculty-of-medicine",
    text: "Heidelberg University - Faculty of Medicine (UniversitÃ¤t Heidelberg - Medizinische FakultÃ¤t Heidelberg)",
    parent: "NA",
    urlone:
      "http://www.medizinische-fakultaet-hd.uni-heidelberg.de/fileadmin/Dekanat/Abfassungsrichtlinien_Sept_2015.pdf",
    urltwo: "NA"
  },
  {
    id: "heilberufescience",
    text: "HeilberufeScience (German)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "heiliger-dienst",
    text: "Heiliger Dienst (German)",
    parent: "NA",
    urlone: "https://www.liturgie.at/dl/lrtkJKJKkmlnkJqx4KJK/2019-04-08_AutorInnen-Hinweise.pdf",
    urltwo: "NA"
  },
  {
    id: "helgoland-marine-research",
    text: "Helgoland Marine Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "heliyon",
    text: "Heliyon",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hellenic-journal-of-cardiology",
    text: "Hellenic Journal of Cardiology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hellenic-journal-of-surgery",
    text: "Hellenic Journal of Surgery",
    parent: "vancouver-brackets",
    urlone: "http://www.springer.com/medicine/surgery/journal/13126",
    urltwo: "NA"
  },
  {
    id: "helvetica-chimica-acta",
    text: "Helvetica Chimica Acta",
    parent: "NA",
    urlone: "http://www.helvchimacta.ch/downloads/Instructions_for_Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "hematology-oncology-and-stem-cell-therapy",
    text: "Hematology/Oncology and Stem Cell Therapy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hematology-oncology-clinics-of-north-america",
    text: "Hematology/Oncology Clinics of North America",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/hematology-oncology-clinics-of-north-america/0889-8588/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "hemorrhagic-stroke",
    text: "Hemorrhagic Stroke",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "henoch",
    text: "Henoch",
    parent: "NA",
    urlone: "https://sites.google.com/site/henochmorcelliana/contact-us",
    urltwo: "NA"
  },
  {
    id: "hepatic-oncology",
    text: "Hepatic Oncology",
    parent: "future-medicine",
    urlone: "https://www.futuremedicine.com/journal/hep",
    urltwo: "NA"
  },
  {
    id: "hepatobiliary-and-pancreatic-diseases-international",
    text: "Hepatobiliary &amp; Pancreatic Diseases International",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hepatology-international",
    text: "Hepatology International",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hepatology-medicine-and-policy",
    text: "Hepatology, Medicine and Policy",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hepatology",
    text: "Hepatology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1527-3350/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "hereditary-cancer-in-clinical-practice",
    text: "Hereditary Cancer in Clinical Practice",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hereditas",
    text: "Hereditas",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "heredity",
    text: "Heredity",
    parent: "NA",
    urlone: "http://mts-hdy.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions",
    urltwo: "NA"
  },
  {
    id: "heritage-science",
    text: "Heritage Science",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hernia",
    text: "Hernia",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "herpetologica",
    text: "Herpetologica",
    parent: "NA",
    urlone: "http://herpetologistsleague.org/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "herpetozoa",
    text: "Herpetozoa",
    parent: "pensoft-journals",
    urlone: "https://herpetozoa.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "herz",
    text: "Herz (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "herzschrittmachertherapie-elektrophysiologie",
    text: "Herzschrittmachertherapie + Elektrophysiologie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/cardiology/journal/399?detailsPage=pltci_1381409",
    urltwo: "NA"
  },
  {
    id: "hesperia",
    text: "Hesperia",
    parent: "american-school-of-classical-studies-at-athens",
    urlone: "https://www.ascsa.edu.gr/publications/hesperia/hesperia-authors",
    urltwo: "NA"
  },
  {
    id: "high-ability-studies",
    text: "High Ability Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CHAS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "high-altitude-medicine-and-biology",
    text: "High Altitude Medicine &amp; Biology",
    parent: "NA",
    urlone: "http://www.liebertpub.com/forauthors/high-altitude-medicine-and-biology/65/",
    urltwo: "NA"
  },
  {
    id: "high-blood-pressure-and-cardiovascular-prevention",
    text: "High Blood Pressure &amp; Cardiovascular Prevention",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "high-energy-density-physics",
    text: "High Energy Density Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "high-pressure-research",
    text: "High Pressure Research",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GHPR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "high-temperature-material-processes-an-international-quarterly-of-high-technology-plasma-processes",
    text: "High Temperature Material Processes: An International Quarterly of High-Technology Plasma Processes",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/57d172397126f956.html",
    urltwo: "NA"
  },
  {
    id: "higher-education",
    text: "Higher Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "higher-order-and-symbolic-computation",
    text: "Higher-Order and Symbolic Computation",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hiob-ludolf-centre-for-ethiopian-studies-long-names",
    text: "Hiob Ludolf Centre for Ethiopian Studies (long names)",
    parent: "NA",
    urlone: "http://www1.uni-hamburg.de/COMST/bulletin.html",
    urltwo: "NA"
  },
  {
    id: "hiob-ludolf-centre-for-ethiopian-studies-with-url-doi",
    text: "Hiob Ludolf Centre for Ethiopian Studies (with URL/DOI)",
    parent: "NA",
    urlone: "http://www1.uni-hamburg.de/COMST/bulletin.html",
    urltwo: "NA"
  },
  {
    id: "hiob-ludolf-centre-for-ethiopian-studies",
    text: "Hiob Ludolf Centre for Ethiopian Studies",
    parent: "NA",
    urlone: "http://www1.uni-hamburg.de/COMST/bulletin.html",
    urltwo: "NA"
  },
  {
    id: "hipertension-y-riesgo-vascular",
    text: "HipertensiÃ³n y Riesgo Vascular (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/hipertension-y-riesgo-vascular/1889-1837/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "hippocampus",
    text: "Hippocampus",
    parent: "the-journal-of-comparative-neurology",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291098-1063/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "hispanic-american-historical-review",
    text: "Hispanic American Historical Review",
    parent: "chicago-note-bibliography",
    urlone: "http://hahr.dukejournals.org/",
    urltwo: "NA"
  },
  {
    id: "histochemistry-and-cell-biology",
    text: "Histochemistry and Cell Biology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "histoire-at-politique",
    text: "Histoire@Politique. Politique, culture, sociÃ©tÃ© (French)",
    parent: "NA",
    urlone: "http://www.histoire-politique.fr/pdf/H@P_Proposer_un_article.pdf",
    urltwo: "NA"
  },
  {
    id: "histoire-et-mesure",
    text: "Histoire &amp; Mesure (French)",
    parent: "NA",
    urlone: "http://histoiremesure.revues.org/1056#tocto1n4",
    urltwo: "NA"
  },
  {
    id: "histopathology",
    text: "Histopathology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2559/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "historia-mathematica",
    text: "Historia Mathematica",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "historia-scribere",
    text: "historia.scribere (German)",
    parent: "NA",
    urlone: "https://historia.scribere.at/",
    urltwo: "https://www.uibk.ac.at/zeitgeschichte/images/zitierrichtlinien_hs2019.pdf"
  },
  {
    id: "historical-materialism",
    text: "Historical Materialism",
    parent: "NA",
    urlone: "http://www.historicalmaterialism.org/instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "historical-social-research",
    text: "Historical Social Research",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "history-and-anthropology",
    text: "History and Anthropology",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GHAN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "history-and-philosophy-of-the-life-sciences",
    text: "History and Philosophy of the Life Sciences",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "history-and-theory",
    text: "History and Theory",
    parent: "NA",
    urlone: "http://www.historyandtheory.org/stylesheet.pdf",
    urltwo: "NA"
  },
  {
    id: "history-australia",
    text: "History Australia",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=raha20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "history-of-geo-and-space-sciences",
    text: "History of Geo- and Space Sciences",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "history-of-political-economy",
    text: "History of Political Economy",
    parent: "chicago-note-bibliography",
    urlone: "http://hope.dukejournals.org/",
    urltwo: "NA"
  },
  {
    id: "history-of-psychology",
    text: "History of Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/hop",
    urltwo: "NA"
  },
  {
    id: "history-of-the-human-sciences",
    text: "History of the Human Sciences",
    parent: "NA",
    urlone: "http://www.uk.sagepub.com/journals/Journal200813/manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "hiv-and-aids-review",
    text: "HIV &amp; AIDS Review",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hkie-transactions",
    text: "HKIE Transactions",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=THIE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "hmd-praxis-der-wirtschaftsinformatik",
    text: "HMD Praxis der Wirtschaftsinformatik (German)",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hno",
    text: "HNO (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/otorhinolaryngology/journal/106?detailsPage=pltci_1865911",
    urltwo: "NA"
  },
  {
    id: "hochschule-bonn-rhein-sieg",
    text: "Hochschule Bonn-Rhein-Sieg (German)",
    parent: "NA",
    urlone: "https://www.h-brs.de/files/leitfaden_fuer_die_gestaltung_schriftlicher_arbeiten_2016_version_2.0.pdf",
    urltwo: "NA"
  },
  {
    id: "hochschule-fur-soziale-arbeit-fhnw",
    text: "Hochschule fÃ¼r Soziale Arbeit FHNW (German - Switzerland)",
    parent: "NA",
    urlone:
      "http://www.studierendenportal.hsa.fhnw.ch/wordpress/wp-content/uploads/wegleitung_wissenschaftl_arbeiten.pdf",
    urltwo: "NA"
  },
  {
    id: "hochschule-fur-wirtschaft-und-recht-berlin",
    text: "Hochschule fÃ¼r Wirtschaft und Recht Berlin (German)",
    parent: "NA",
    urlone: "http://www.hwr-berlin.de/",
    urltwo: "NA"
  },
  {
    id: "hochschule-hannover-soziale-arbeit",
    text: "Hochschule Hannover - Soziale Arbeit (German)",
    parent: "NA",
    urlone:
      "https://f5.hs-hannover.de/fileadmin/HsH/Fakultaet_V/Bilder/Studium/Bachelor/Soziale_Arbeit_grundstaendig/Leitfaeden_BSW/Leitfaden_Schriftliche_Arbeiten_Soziale_Arbeit_SoSe_2018.pdf",
    urltwo: "NA"
  },
  {
    id: "hochschule-munchen-fakultat-fur-angewandte-sozialwissenschaften",
    text: "Hochschule MÃ¼nchen - FakultÃ¤t fÃ¼r Angewandte Sozialwissenschaften (German)",
    parent: "NA",
    urlone: "http://www.sw.hm.edu/publikationen/lehrmaterialien/schoenberger.de.html",
    urltwo: "NA"
  },
  {
    id: "hochschule-osnabruck-fakultat-agrarwissenschaften-und-landschaftsarchitektur",
    text: "Hochschule OsnabrÃ¼ck - FakultÃ¤t Agrarwissenschaften und Landschaftsarchitektur (German)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/3260#issuecomment-357898874",
    urltwo: "NA"
  },
  {
    id: "hochschule-pforzheim-fakultat-fur-wirtschaft-und-recht",
    text: "Hochschule Pforzheim - FakultÃ¤t fÃ¼r Wirtschaft und Recht (German)",
    parent: "NA",
    urlone: "https://businesspf.hs-pforzheim.de/",
    urltwo: "NA"
  },
  {
    id: "homeopathy",
    text: "Homeopathy",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.com/journals/homeopathy/1475-4916/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "homo",
    text: "HOMO",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hong-kong-journal-of-occupational-therapy",
    text: "Hong Kong Journal of Occupational Therapy",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hong-kong-journal-of-ophthalmology",
    text: "Hong Kong Journal of Ophthalmology",
    parent: "vancouver-superscript",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hong-kong-journal-of-radiology",
    text: "Hong Kong Journal of Radiology",
    parent: "NA",
    urlone: "http://www.hkjr.org/page/information-author",
    urltwo: "NA"
  },
  {
    id: "hong-kong-medical-journal",
    text: "Hong Kong Medical Journal",
    parent: "american-medical-association",
    urlone: "http://www.hkmj.org/author/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "hong-kong-physiotherapy-journal",
    text: "Hong Kong Physiotherapy Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hormigon-y-acero",
    text: "HormigÃ³n y Acero",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hormone-and-metabolic-research",
    text: "Hormone and Metabolic Research",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "hormone-research-in-paediatrics",
    text: "Hormone Research in Paediatrics",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/224036",
    urltwo: "NA"
  },
  {
    id: "hormones-and-behavior",
    text: "Hormones and Behavior",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hormones-and-cancer",
    text: "Hormones and Cancer",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "horticulturae",
    text: "Horticulturae",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "horticultural-plant-journal",
    text: "Horticultural Plant Journal",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "horticulture-research",
    text: "Horticulture Research",
    parent: "european-journal-of-human-genetics",
    urlone: "http://www.nature.com/public/shared-assets/hortres/GTA_Horticulture_Research.pdf",
    urltwo: "NA"
  },
  {
    id: "hospital-chronicles",
    text: "Hospital Chronicles (Nosokomiaka Chronica)",
    parent: "vancouver-superscript",
    urlone: "http://www.hospitalchronicles.gr/index.php/hchr/index",
    urltwo: "NA"
  },
  {
    id: "hospital-pharmacy",
    text: "Hospital Pharmacy",
    parent: "american-medical-association",
    urlone: "http://www.thomasland.com/HPJ_info_for_authors.html",
    urltwo: "NA"
  },
  {
    id: "housing-policy-debate",
    text: "Housing Policy Debate",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RHPD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "housing-theory-and-society",
    text: "Housing, Theory and Society",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=SHOU20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "hpb",
    text: "HPB",
    parent: "NA",
    urlone: "https://www.hpbonline.org/content/authorinfo#idp1331616",
    urltwo: "NA"
  },
  {
    id: "huisarts-en-wetenschap",
    text: "Huisarts en Wetenschap",
    parent: "vancouver-superscript",
    urlone: "http://www.henw.org/voorauteurs",
    urltwo: "NA"
  },
  {
    id: "human-brain-mapping",
    text: "Human Brain Mapping",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-0193/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "human-cell",
    text: "Human Cell",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "human-centric-computing-and-information-sciences",
    text: "Human-centric Computing and Information Sciences",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "human-development",
    text: "Human Development",
    parent: "apa",
    urlone: "http://www.karger.com?issn=1423-0054",
    urltwo: "NA"
  },
  {
    id: "human-gene-therapy-clinical-development",
    text: "Human Gene Therapy Clinical Development",
    parent: "human-gene-therapy",
    urlone: "http://www.liebertpub.com/forauthors/human-gene-therapy/19/",
    urltwo: "NA"
  },
  {
    id: "human-gene-therapy-methods",
    text: "Human Gene Therapy Methods",
    parent: "human-gene-therapy",
    urlone: "http://www.liebertpub.com/forauthors/human-gene-therapy/19/",
    urltwo: "NA"
  },
  {
    id: "human-gene-therapy",
    text: "Human Gene Therapy",
    parent: "NA",
    urlone: "http://www.liebertpub.com/forauthors/human-gene-therapy/19/",
    urltwo: "NA"
  },
  {
    id: "human-genetics",
    text: "Human Genetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "human-genomics",
    text: "Human Genomics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "human-heredity",
    text: "Human Heredity",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0062",
    urltwo: "NA"
  },
  {
    id: "human-immunology",
    text: "Human Immunology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "human-microbiome-journal",
    text: "Human Microbiome Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "human-molecular-genetics",
    text: "Human Molecular Genetics",
    parent: "database",
    urlone: "http://www.oxfordjournals.org/our_journals/hmg/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "human-movement-science",
    text: "Human Movement Science",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "human-mutation",
    text: "Human Mutation",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1098-1004/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "human-nature",
    text: "Human Nature",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "human-pathology-case-reports",
    text: "Human Pathology: Case Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "human-pathology",
    text: "Human Pathology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "human-relations",
    text: "Human Relations",
    parent: "sage-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "human-reproduction-update",
    text: "Human Reproduction Update",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/humupd/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "human-reproduction",
    text: "Human Reproduction",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/humrep/for_authors/rct.html",
    urltwo: "NA"
  },
  {
    id: "human-resource-development-international",
    text: "Human Resource Development International",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RHRD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "human-resource-management-journal",
    text: "Human Resource Management Journal",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291748-8583/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "human-resource-management-review",
    text: "Human Resource Management Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "human-resources-for-health",
    text: "Human Resources for Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "human-studies",
    text: "Human Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "human-vaccines-and-immunotherapeutics",
    text: "Human Vaccines &amp; Immunotherapeutics",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "human-wildlife-interactions",
    text: "Human-Wildlife Interactions",
    parent: "NA",
    urlone: "http://www.berrymaninstitute.org/files/uploads/pdf/HWISubmission_Guide_FinalRev.pdf",
    urltwo: "NA"
  },
  {
    id: "humanistica-lovaniensia-journal-of-neo-latin-studies",
    text: "Humanistica Lovaniensia. Journal of Neo-Latin Studies",
    parent: "NA",
    urlone: "https://www.arts.kuleuven.be/sph/HLStyleSheet",
    urltwo: "NA"
  },
  {
    id: "humanities",
    text: "Humanities",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "humboldt-state-university-environmental-resources-engineering",
    text: "Humboldt State University - Environmental Resources Engineering",
    parent: "NA",
    urlone: "https://www2.humboldt.edu/engineering/resources/technical-communication",
    urltwo: "NA"
  },
  {
    id: "husserl-studies",
    text: "Husserl Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hybrid-methods-in-engineering",
    text: "Hybrid Methods in Engineering",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/1a37c0433d303e8b.html",
    urltwo: "NA"
  },
  {
    id: "hydrobiologia",
    text: "Hydrobiologia",
    parent: "NA",
    urlone: "http://www.springer.com/life+sciences/ecology/journal/10750",
    urltwo: "NA"
  },
  {
    id: "hydrobiological-journal",
    text: "Hydrobiological Journal",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/38cb2223012b73f2.html",
    urltwo: "NA"
  },
  {
    id: "hydrogeology-journal",
    text: "Hydrogeology Journal",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hydrological-processes",
    text: "Hydrological Processes",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291099-1085/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "hydrological-sciences-journal",
    text: "Hydrological Sciences Journal",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=thsj&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "hydrology-and-earth-system-sciences",
    text: "Hydrology and Earth System Sciences",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hydrology",
    text: "Hydrology",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "hydrometallurgy",
    text: "Hydrometallurgy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hydroresearch",
    text: "HydroResearch",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "hyperfine-interactions",
    text: "Hyperfine Interactions",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "hypertension-research",
    text: "Hypertension Research",
    parent: "NA",
    urlone: "http://mc.manuscriptcentral.com/societyimages/htr/HR_GTA.pdf",
    urltwo: "NA"
  },
  {
    id: "hypotheses-in-the-life-sciences",
    text: "Hypotheses in the Life Sciences",
    parent: "NA",
    urlone: "http://www.hy-ls.org/index.php/hyls/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "iatss-research",
    text: "IATSS Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ib-tauris-note",
    text: "I.B. Tauris (note)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/files/1355290/IBT.House.Style.2016.pdf",
    urltwo: "NA"
  },
  {
    id: "ibis",
    text: "Ibis",
    parent: "NA",
    urlone: "https://www.bou.org.uk/ibis/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "ibm-journal-of-research-and-development",
    text: "IBM Journal of Research and Development",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5288520",
    urltwo: "NA"
  },
  {
    id: "ibms-bonekey",
    text: "IBMS BoneKEy",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-bonekey.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#arrange",
    urltwo: "NA"
  },
  {
    id: "ibro-reports",
    text: "IBRO Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ibsen-studies",
    text: "Ibsen Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=SIBS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "icarus",
    text: "Icarus",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ices-journal-of-marine-science",
    text: "ICES Journal of Marine Science",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/icesjms/for_authors/index.html",
    urltwo: "NA"
  },
  {
    id: "ict-express",
    text: "ICT Express",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "idcases",
    text: "IDCases",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "idojaras-quarterly-journal-of-the-hungarian-meteorological-service",
    text: "IdÅ‘jÃ¡rÃ¡s - Quarterly Journal of the Hungarian Meteorological Service",
    parent: "NA",
    urlone:
      "http://www.met.hu/downloads.php?fn=/metadmin/newspaper/2016/03/b849ea3fc271ed1ff1bb69ca01063a9a-borito-3.pdf",
    urltwo: "NA"
  },
  {
    id: "ie-comunicaciones",
    text: "IE Comunicaciones",
    parent: "NA",
    urlone: "http://dialnet.unirioja.es/servlet/revista?info=nor_estilo&amp;codigo=12765&amp;modo=popup",
    urltwo: "NA"
  },
  {
    id: "ieee-access",
    text: "IEEE Access",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6287639",
    urltwo: "NA"
  },
  {
    id: "ieee-acm-transactions-on-audio-speech-and-language-processing",
    text: "IEEE/ACM Transactions on Audio, Speech, and Language Processing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6570655",
    urltwo: "NA"
  },
  {
    id: "ieee-acm-transactions-on-computational-biology-and-bioinformatics",
    text: "IEEE/ACM Transactions on Computational Biology and Bioinformatics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8857",
    urltwo: "NA"
  },
  {
    id: "ieee-acm-transactions-on-networking",
    text: "IEEE/ACM Transactions on Networking",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=90",
    urltwo: "NA"
  },
  {
    id: "ieee-aerospace-and-electronic-systems-magazine",
    text: "IEEE Aerospace and Electronic Systems Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=62",
    urltwo: "NA"
  },
  {
    id: "ieee-annals-of-the-history-of-computing",
    text: "IEEE Annals of the History of Computing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=85",
    urltwo: "NA"
  },
  {
    id: "ieee-antennas-and-propagation-magazine",
    text: "IEEE Antennas and Propagation Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=74",
    urltwo: "NA"
  },
  {
    id: "ieee-antennas-and-wireless-propagation-letters",
    text: "IEEE Antennas and Wireless Propagation Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7727",
    urltwo: "NA"
  },
  {
    id: "ieee-asme-transactions-on-mechatronics",
    text: "IEEE/ASME Transactions on Mechatronics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=3516",
    urltwo: "NA"
  },
  {
    id: "ieee-circuits-and-systems-magazine",
    text: "IEEE Circuits and Systems Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7384",
    urltwo: "NA"
  },
  {
    id: "ieee-communications-letters",
    text: "IEEE Communications Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4234",
    urltwo: "NA"
  },
  {
    id: "ieee-communications-magazine",
    text: "IEEE Communications Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=35",
    urltwo: "NA"
  },
  {
    id: "ieee-communications-surveys-and-tutorials",
    text: "IEEE Communications Surveys &amp; Tutorials",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=9739",
    urltwo: "NA"
  },
  {
    id: "ieee-computational-intelligence-magazine",
    text: "IEEE Computational Intelligence Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=10207",
    urltwo: "NA"
  },
  {
    id: "ieee-computer-graphics-and-applications",
    text: "IEEE Computer Graphics and Applications",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=38",
    urltwo: "NA"
  },
  {
    id: "ieee-consumer-electronics-magazine",
    text: "IEEE Consumer Electronics Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5962380",
    urltwo: "NA"
  },
  {
    id: "ieee-control-systems",
    text: "IEEE Control Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5488303",
    urltwo: "NA"
  },
  {
    id: "ieee-design-and-test",
    text: "IEEE Design &amp; Test",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6221038",
    urltwo: "NA"
  },
  {
    id: "ieee-electrical-insulation-magazine",
    text: "IEEE Electrical Insulation Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=57",
    urltwo: "NA"
  },
  {
    id: "ieee-electrification-magazine",
    text: "IEEE Electrification Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6508996",
    urltwo: "NA"
  },
  {
    id: "ieee-electromagnetic-compatibility-magazine",
    text: "IEEE Electromagnetic Compatibility Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5962381",
    urltwo: "NA"
  },
  {
    id: "ieee-electron-device-letters",
    text: "IEEE Electron Device Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=55",
    urltwo: "NA"
  },
  {
    id: "ieee-embedded-systems-letters",
    text: "IEEE Embedded Systems Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4563995",
    urltwo: "NA"
  },
  {
    id: "ieee-engineering-management-review",
    text: "IEEE Engineering Management Review",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=46",
    urltwo: "NA"
  },
  {
    id: "ieee-geoscience-and-remote-sensing-letters",
    text: "IEEE Geoscience and Remote Sensing Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8859",
    urltwo: "NA"
  },
  {
    id: "ieee-geoscience-and-remote-sensing-magazine",
    text: "IEEE Geoscience and Remote Sensing Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6245518",
    urltwo: "NA"
  },
  {
    id: "ieee-industrial-electronics-magazine",
    text: "IEEE Industrial Electronics Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4154573",
    urltwo: "NA"
  },
  {
    id: "ieee-industry-applications-magazine",
    text: "IEEE Industry Applications Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=2943",
    urltwo: "NA"
  },
  {
    id: "ieee-instrumentation-and-measurement-magazine",
    text: "IEEE Instrumentation &amp; Measurement Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5289",
    urltwo: "NA"
  },
  {
    id: "ieee-intelligent-systems",
    text: "IEEE Intelligent Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=9670",
    urltwo: "NA"
  },
  {
    id: "ieee-intelligent-transportation-systems-magazine",
    text: "IEEE Intelligent Transportation Systems Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5117645",
    urltwo: "NA"
  },
  {
    id: "ieee-internet-computing",
    text: "IEEE Internet Computing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4236",
    urltwo: "NA"
  },
  {
    id: "ieee-internet-of-things-journal",
    text: "IEEE Internet of Things Journal",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6488907",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-biomedical-and-health-informatics",
    text: "IEEE Journal of Biomedical and Health Informatics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6221020",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-emerging-and-selected-topics-in-power-electronics",
    text: "IEEE Journal of Emerging and Selected Topics in Power Electronics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6245517",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-oceanic-engineering",
    text: "IEEE Journal of Oceanic Engineering",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=48",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-photovoltaics",
    text: "IEEE Journal of Photovoltaics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5503869",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-quantum-electronics",
    text: "IEEE Journal of Quantum Electronics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=3",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-selected-topics-in-applied-earth-observations-and-remote-sensing",
    text: "IEEE Journal of Selected Topics in Applied Earth Observations and Remote Sensing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4609443",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-selected-topics-in-quantum-electronics",
    text: "IEEE Journal of Selected Topics in Quantum Electronics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=2944",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-selected-topics-in-signal-processing",
    text: "IEEE Journal of Selected Topics in Signal Processing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4200690",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-solid-state-circuits",
    text: "IEEE Journal of Solid-State Circuits",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-the-electron-devices-society",
    text: "IEEE Journal of the Electron Devices Society",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6245494",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-of-translational-engineering-in-health-and-medicine",
    text: "IEEE Journal of Translational Engineering in Health and Medicine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6221039",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-on-emerging-and-selected-topics-in-circuits-and-systems",
    text: "IEEE Journal on Emerging and Selected Topics in Circuits and Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5503868",
    urltwo: "NA"
  },
  {
    id: "ieee-journal-on-selected-areas-in-communications",
    text: "IEEE Journal on Selected Areas in Communications",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=49",
    urltwo: "NA"
  },
  {
    id: "ieee-latin-america-transactions",
    text: "IEEE Latin America Transactions",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=9907",
    urltwo: "NA"
  },
  {
    id: "ieee-magnetics-letters",
    text: "IEEE Magnetics Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5165412",
    urltwo: "NA"
  },
  {
    id: "ieee-micro",
    text: "IEEE Micro",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=40",
    urltwo: "NA"
  },
  {
    id: "ieee-microwave-and-wireless-components-letters",
    text: "IEEE Microwave and Wireless Components Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7260",
    urltwo: "NA"
  },
  {
    id: "ieee-microwave-magazine",
    text: "IEEE Microwave Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6668",
    urltwo: "NA"
  },
  {
    id: "ieee-multimedia",
    text: "IEEE MultiMedia",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=93",
    urltwo: "NA"
  },
  {
    id: "ieee-nanotechnology-magazine",
    text: "IEEE Nanotechnology Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4451717",
    urltwo: "NA"
  },
  {
    id: "ieee-network",
    text: "IEEE Network",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=65",
    urltwo: "NA"
  },
  {
    id: "ieee-osa-journal-of-optical-communications-and-networking",
    text: "IEEE/OSA Journal of Optical Communications and Networking",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4563700",
    urltwo: "NA"
  },
  {
    id: "ieee-pervasive-computing",
    text: "IEEE Pervasive Computing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7756",
    urltwo: "NA"
  },
  {
    id: "ieee-photonics-journal",
    text: "IEEE Photonics Journal",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4563994",
    urltwo: "NA"
  },
  {
    id: "ieee-photonics-technology-letters",
    text: "IEEE Photonics Technology Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=68",
    urltwo: "NA"
  },
  {
    id: "ieee-potentials",
    text: "IEEE Potentials",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=45",
    urltwo: "NA"
  },
  {
    id: "ieee-power-and-energy-magazine",
    text: "IEEE Power and Energy Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8014",
    urltwo: "NA"
  },
  {
    id: "ieee-pulse",
    text: "IEEE Pulse",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5454060",
    urltwo: "NA"
  },
  {
    id: "ieee-reviews-in-biomedical-engineering",
    text: "IEEE Reviews in Biomedical Engineering",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4664312",
    urltwo: "NA"
  },
  {
    id: "ieee-revista-iberoamericana-de-tecnologias-del-aprendizaje",
    text: "IEEE Revista Iberoamericana de Tecnologias del Aprendizaje",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6245520",
    urltwo: "NA"
  },
  {
    id: "ieee-robotics-and-automation-magazine",
    text: "IEEE Robotics &amp; Automation Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=100",
    urltwo: "NA"
  },
  {
    id: "ieee-security-and-privacy",
    text: "IEEE Security &amp; Privacy",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8013",
    urltwo: "NA"
  },
  {
    id: "ieee-sensors-journal",
    text: "IEEE Sensors Journal",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7361",
    urltwo: "NA"
  },
  {
    id: "ieee-signal-processing-letters",
    text: "IEEE Signal Processing Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=97",
    urltwo: "NA"
  },
  {
    id: "ieee-signal-processing-magazine",
    text: "IEEE Signal Processing Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=79",
    urltwo: "NA"
  },
  {
    id: "ieee-software",
    text: "IEEE Software",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=52",
    urltwo: "NA"
  },
  {
    id: "ieee-solid-state-circuits-magazine",
    text: "IEEE Solid-State Circuits Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4563670",
    urltwo: "NA"
  },
  {
    id: "ieee-spectrum",
    text: "IEEE Spectrum",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6",
    urltwo: "NA"
  },
  {
    id: "ieee-systems-journal",
    text: "IEEE Systems Journal",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4267003",
    urltwo: "NA"
  },
  {
    id: "ieee-technology-and-society-magazine",
    text: "IEEE Technology and Society Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=44",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-aerospace-and-electronic-systems",
    text: "IEEE Transactions on Aerospace and Electronic Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-affective-computing",
    text: "IEEE Transactions on Affective Computing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5165369",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-antennas-and-propagation",
    text: "IEEE Transactions on Antennas and Propagation",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-applied-superconductivity",
    text: "IEEE Transactions on Applied Superconductivity",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=77",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-automatic-control",
    text: "IEEE Transactions on Automatic Control",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=9",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-automation-science-and-engineering",
    text: "IEEE Transactions on Automation Science and Engineering",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8856",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-autonomous-mental-development",
    text: "IEEE Transactions on Autonomous Mental Development",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4563672",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-biomedical-circuits-and-systems",
    text: "IEEE Transactions on Biomedical Circuits and Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4156126",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-biomedical-engineering",
    text: "IEEE Transactions on Biomedical Engineering",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=10",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-broadcasting",
    text: "IEEE Transactions on Broadcasting",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=11",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-circuits-and-systems-for-video-technology",
    text: "IEEE Transactions on Circuits and Systems for Video Technology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=76",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-circuits-and-systems-i-regular-papers",
    text: "IEEE Transactions on Circuits and Systems I: Regular Papers",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8919",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-circuits-and-systems-ii-express-briefs",
    text: "IEEE Transactions on Circuits and Systems II: Express Briefs",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8920",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-cloud-computing",
    text: "IEEE Transactions on Cloud Computing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6245519",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-communications",
    text: "IEEE Transactions on Communications",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=26",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-components-packaging-and-manufacturing-technology",
    text: "IEEE Transactions on Components, Packaging and Manufacturing Technology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5503870",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-computational-intelligence-and-ai-in-games",
    text: "IEEE Transactions on Computational Intelligence and AI in Games",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4804728",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-computer-aided-design-of-integrated-circuits-and-systems",
    text: "IEEE Transactions on Computer-Aided Design of Integrated Circuits and Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=43",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-computers",
    text: "IEEE Transactions on Computers",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=12",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-consumer-electronics",
    text: "IEEE Transactions on Consumer Electronics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=30",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-control-systems-technology",
    text: "IEEE Transactions on Control Systems Technology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=87",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-cybernetics",
    text: "IEEE Transactions on Cybernetics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6221036",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-dependable-and-secure-computing",
    text: "IEEE Transactions on Dependable and Secure Computing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8858",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-device-and-materials-reliability",
    text: "IEEE Transactions on Device and Materials Reliability",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7298",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-dielectrics-and-electrical-insulation",
    text: "IEEE Transactions on Dielectrics and Electrical Insulation",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=94",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-education",
    text: "IEEE Transactions on Education",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=13",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-electromagnetic-compatibility",
    text: "IEEE Transactions on Electromagnetic Compatibility",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=15",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-electron-devices",
    text: "IEEE Transactions on Electron Devices",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=16",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-emerging-topics-in-computing",
    text: "IEEE Transactions on Emerging Topics in Computing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6245516",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-energy-conversion",
    text: "IEEE Transactions on Energy Conversion",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=60",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-engineering-management",
    text: "IEEE Transactions on Engineering Management",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=17",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-evolutionary-computation",
    text: "IEEE Transactions on Evolutionary Computation",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4235",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-fuzzy-systems",
    text: "IEEE Transactions on Fuzzy Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=91",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-geoscience-and-remote-sensing",
    text: "IEEE Transactions on Geoscience and Remote Sensing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=36",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-haptics",
    text: "IEEE Transactions on Haptics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4543165",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-human-machine-systems",
    text: "IEEE Transactions on Human-Machine Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6221037",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-image-processing",
    text: "IEEE Transactions on Image Processing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=83",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-industrial-electronics",
    text: "IEEE Transactions on Industrial Electronics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=41",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-industrial-informatics",
    text: "IEEE Transactions on Industrial Informatics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=9424",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-industry-applications",
    text: "IEEE Transactions on Industry Applications",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=28",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-information-forensics-and-security",
    text: "IEEE Transactions on Information Forensics and Security",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=10206",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-information-theory",
    text: "IEEE Transactions on Information Theory",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=18",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-instrumentation-and-measurement",
    text: "IEEE Transactions on Instrumentation and Measurement",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=19",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-intelligent-transportation-systems",
    text: "IEEE Transactions on Intelligent Transportation Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6979",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-knowledge-and-data-engineering",
    text: "IEEE Transactions on Knowledge and Data Engineering",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=69",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-learning-technologies",
    text: "IEEE Transactions on Learning Technologies",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4620076",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-magnetics",
    text: "IEEE Transactions on Magnetics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=20",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-medical-imaging",
    text: "IEEE Transactions on Medical Imaging",
    parent: "NA",
    urlone: "http://www.ieee.org/publications_standards/publications/authors/author_templates.html",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-microwave-theory-and-techniques",
    text: "IEEE Transactions on Microwave Theory and Techniques",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=22",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-mobile-computing",
    text: "IEEE Transactions on Mobile Computing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7755",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-multimedia",
    text: "IEEE Transactions on Multimedia",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6046",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-nanobioscience",
    text: "IEEE Transactions on NanoBioscience",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7728",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-nanotechnology",
    text: "IEEE Transactions on Nanotechnology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7729",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-network-and-service-management",
    text: "IEEE Transactions on Network and Service Management",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4275028",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-neural-networks-and-learning-systems",
    text: "IEEE Transactions on Neural Networks and Learning Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5962385",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-neural-systems-and-rehabilitation-engineering",
    text: "IEEE Transactions on Neural Systems and Rehabilitation Engineering",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7333",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-nuclear-science",
    text: "IEEE Transactions on Nuclear Science",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=23",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-parallel-and-distributed-systems",
    text: "IEEE Transactions on Parallel and Distributed Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=71",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-pattern-analysis-and-machine-intelligence",
    text: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=34",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-plasma-science",
    text: "IEEE Transactions on Plasma Science",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=27",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-power-delivery",
    text: "IEEE Transactions on Power Delivery",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=61",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-power-electronics",
    text: "IEEE Transactions on Power Electronics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=63",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-power-systems",
    text: "IEEE Transactions on Power Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=59",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-professional-communication",
    text: "IEEE Transactions on Professional Communication",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=47",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-reliability",
    text: "IEEE Transactions on Reliability",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=24",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-robotics",
    text: "IEEE Transactions on Robotics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=8860",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-semiconductor-manufacturing",
    text: "IEEE Transactions on Semiconductor Manufacturing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=66",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-services-computing",
    text: "IEEE Transactions on Services Computing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4629386",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-signal-processing",
    text: "IEEE Transactions on Signal Processing",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=78",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-smart-grid",
    text: "IEEE Transactions on Smart Grid",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5165411",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-software-engineering",
    text: "IEEE Transactions on Software Engineering",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=32",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-sustainable-energy",
    text: "IEEE Transactions on Sustainable Energy",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5165391",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-systems-man-and-cybernetics-systems",
    text: "IEEE Transactions on Systems, Man, and Cybernetics: Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6221021",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-terahertz-science-and-technology",
    text: "IEEE Transactions on Terahertz Science and Technology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5503871",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-ultrasonics-ferroelectrics-and-frequency-control",
    text: "IEEE Transactions on Ultrasonics, Ferroelectrics and Frequency Control",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=58",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-vehicular-technology",
    text: "IEEE Transactions on Vehicular Technology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=25",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-very-large-scale-integration-systems",
    text: "IEEE Transactions on Very Large Scale Integration Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=92",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-visualization-and-computer-graphics",
    text: "IEEE Transactions on Visualization and Computer Graphics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=2945",
    urltwo: "NA"
  },
  {
    id: "ieee-transactions-on-wireless-communications",
    text: "IEEE Transactions on Wireless Communications",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7693",
    urltwo: "NA"
  },
  {
    id: "ieee-vehicular-technology-magazine",
    text: "IEEE Vehicular Technology Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=10209",
    urltwo: "NA"
  },
  {
    id: "ieee-wireless-communications-letters",
    text: "IEEE Wireless Communications Letters",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5962382",
    urltwo: "NA"
  },
  {
    id: "ieee-wireless-communications",
    text: "IEEE Wireless Communications",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=7742",
    urltwo: "NA"
  },
  {
    id: "ieee-with-url",
    text: "IEEE (with URL)",
    parent: "NA",
    urlone: "https://ieeeauthorcenter.ieee.org/wp-content/uploads/IEEE-Reference-Guide.pdf",
    urltwo: "https://journals.ieeeauthorcenter.ieee.org/your-role-in-article-production/ieee-editorial-style-manual/"
  },
  {
    id: "ieee-women-in-engineering-magazine",
    text: "IEEE Women in Engineering Magazine",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4509581",
    urltwo: "NA"
  },
  {
    id: "ieee",
    text: "IEEE",
    parent: "NA",
    urlone: "https://ieeeauthorcenter.ieee.org/wp-content/uploads/IEEE-Reference-Guide.pdf",
    urltwo: "https://journals.ieeeauthorcenter.ieee.org/your-role-in-article-production/ieee-editorial-style-manual/"
  },
  {
    id: "ieice-transactions-on-communications",
    text: "IEICE Transactions on Communications",
    parent: "the-institute-of-electronics-information-and-communication-engineers",
    urlone: "http://www.ieice.org/eng/shiori/mokuji_cs.html",
    urltwo: "NA"
  },
  {
    id: "ieice-transactions-on-electronics",
    text: "IEICE Transactions on Electronics",
    parent: "the-institute-of-electronics-information-and-communication-engineers",
    urlone: "http://www.ieice.org/eng/shiori/mokuji_es.html",
    urltwo: "NA"
  },
  {
    id: "ieice-transactions-on-fundamentals-of-electronics-communications-and-computer-sciences",
    text: "IEICE Transactions on Fundamentals of Electronics, Communications and Computer Sciences",
    parent: "the-institute-of-electronics-information-and-communication-engineers",
    urlone: "http://www.ieice.org/eng/shiori/mokuji_ess.html",
    urltwo: "NA"
  },
  {
    id: "ieice-transactions-on-information-and-systems",
    text: "IEICE Transactions on Information and Systems",
    parent: "the-institute-of-electronics-information-and-communication-engineers",
    urlone: "http://www.ieice.org/eng/shiori/mokuji_iss.html",
    urltwo: "NA"
  },
  {
    id: "ieri-procedia",
    text: "IERI Procedia",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "iet-biometrics",
    text: "IET Biometrics",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6072579",
    urltwo: "NA"
  },
  {
    id: "iet-circuits-devices-and-systems",
    text: "IET Circuits, Devices &amp; Systems",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4123966",
    urltwo: "NA"
  },
  {
    id: "iet-communications",
    text: "IET Communications",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4105970",
    urltwo: "NA"
  },
  {
    id: "iet-computer-vision",
    text: "IET Computer Vision",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4159597",
    urltwo: "NA"
  },
  {
    id: "iet-computers-and-digital-techniques",
    text: "IET Computers &amp; Digital Techniques",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4117424",
    urltwo: "NA"
  },
  {
    id: "iet-control-theory-and-applications",
    text: "IET Control Theory &amp; Applications",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4079545",
    urltwo: "NA"
  },
  {
    id: "iet-electric-power-applications",
    text: "IET Electric Power Applications",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4079749",
    urltwo: "NA"
  },
  {
    id: "iet-electrical-systems-in-transportation",
    text: "IET Electrical Systems in Transportation",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5704588",
    urltwo: "NA"
  },
  {
    id: "iet-generation-transmission-and-distribution",
    text: "IET Generation, Transmission &amp; Distribution",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4082359",
    urltwo: "NA"
  },
  {
    id: "iet-image-processing",
    text: "IET Image Processing",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4149689",
    urltwo: "NA"
  },
  {
    id: "iet-information-security",
    text: "IET Information Security",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4149673",
    urltwo: "NA"
  },
  {
    id: "iet-intelligent-transport-systems",
    text: "IET Intelligent Transport Systems",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4149681",
    urltwo: "NA"
  },
  {
    id: "iet-micro-and-nano-letters",
    text: "IET Micro &amp; Nano Letters",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=11102",
    urltwo: "NA"
  },
  {
    id: "iet-microwaves-antennas-and-propagation",
    text: "IET Microwaves, Antennas &amp; Propagation",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4126157",
    urltwo: "NA"
  },
  {
    id: "iet-nanobiotechnology",
    text: "IET Nanobiotechnology",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4123961",
    urltwo: "NA"
  },
  {
    id: "iet-networks",
    text: "IET Networks",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6072580",
    urltwo: "NA"
  },
  {
    id: "iet-optoelectronics",
    text: "IET Optoelectronics",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4117432",
    urltwo: "NA"
  },
  {
    id: "iet-power-electronics",
    text: "IET Power Electronics",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4475725",
    urltwo: "NA"
  },
  {
    id: "iet-radar-sonar-and-navigation",
    text: "IET Radar, Sonar &amp; Navigation",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4119394",
    urltwo: "NA"
  },
  {
    id: "iet-renewable-power-generation",
    text: "IET Renewable Power Generation",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4159946",
    urltwo: "NA"
  },
  {
    id: "iet-science-measurement-and-technology",
    text: "IET Science, Measurement &amp; Technology",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4105888",
    urltwo: "NA"
  },
  {
    id: "iet-signal-processing",
    text: "IET Signal Processing",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4159607",
    urltwo: "NA"
  },
  {
    id: "iet-software",
    text: "IET Software",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4124007",
    urltwo: "NA"
  },
  {
    id: "iet-synthetic-biology",
    text: "IET Synthetic Biology",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4290605",
    urltwo: "NA"
  },
  {
    id: "iet-systems-biology",
    text: "IET Systems Biology",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=4100185",
    urltwo: "NA"
  },
  {
    id: "iet-wireless-sensor-systems",
    text: "IET Wireless Sensor Systems",
    parent: "the-institution-of-engineering-and-technology",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5704589",
    urltwo: "NA"
  },
  {
    id: "ifac-journal-of-systems-and-control",
    text: "IFAC Journal of Systems and Control",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "iforest",
    text: "iForest",
    parent: "NA",
    urlone: "http://www.sisef.it/iforest/users/?id=authors",
    urltwo: "NA"
  },
  {
    id: "igaku-toshokan",
    text: "Igaku Toshokan (Japanese)",
    parent: "NA",
    urlone: "http://plaza.umin.ac.jp/~jmla/kikanshi/kitei.html",
    urltwo: "NA"
  },
  {
    id: "ihj-cardiovascular-case-reports",
    text: "IHJ Cardiovascular Case Reports",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "iica-catie",
    text: "IICA-CATIE (Spanish)",
    parent: "NA",
    urlone: "http://repiica.iica.int/docs/B4013e/B4013e.pdf",
    urltwo: "NA"
  },
  {
    id: "iimb-management-review",
    text: "IIMB Management Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ijc-heart-and-vasculature",
    text: "IJC Heart &amp; Vasculature",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ijc-metabolic-and-endocrine",
    text: "IJC Metabolic &amp; Endocrine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ilahiyat-studies",
    text: "Ilahiyat Studies (full note)",
    parent: "NA",
    urlone: "http://ilahiyatstudies.org/index.php/journal/about/submissions",
    urltwo: "NA"
  },
  {
    id: "im-gesprach",
    text: "Im GesprÃ¤ch - Hefte der Martin Buber-Gesellschaft (German)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/1674/files#r38487900",
    urltwo: "NA"
  },
  {
    id: "ima-journal-of-applied-mathematics",
    text: "IMA Journal of Applied Mathematics",
    parent: "institute-of-mathematics-and-its-applications",
    urlone: "https://academic.oup.com/imamat/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "ima-journal-of-management-mathematics",
    text: "IMA Journal of Management Mathematics",
    parent: "institute-of-mathematics-and-its-applications",
    urlone: "https://academic.oup.com/imaman/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "ima-journal-of-mathematical-control-and-information",
    text: "IMA Journal of Mathematical Control and Information",
    parent: "institute-of-mathematics-and-its-applications",
    urlone: "https://academic.oup.com/imamci/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "ima-journal-of-numerical-analysis",
    text: "IMA Journal of Numerical Analysis",
    parent: "institute-of-mathematics-and-its-applications",
    urlone: "https://academic.oup.com/imajna/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "image-and-vision-computing",
    text: "Image and Vision Computing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "imagerie-de-la-femme",
    text: "Imagerie de la Femme",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "imaging-in-medicine",
    text: "Imaging in Medicine",
    parent: "future-science-group",
    urlone: "http://www.futuremedicine.com/loi/iim",
    urltwo: "NA"
  },
  {
    id: "immigrants-and-minorities",
    text: "Immigrants &amp; Minorities",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FIMM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "immunity-and-ageing",
    text: "Immunity &amp; Ageing",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "immunity",
    text: "Immunity",
    parent: "cell",
    urlone: "http://www.cell.com/immunity/authors",
    urltwo: "NA"
  },
  {
    id: "immuno-analyse-et-biologie-specialisee",
    text: "Immuno-analyse et biologie spÃ©cialisÃ©e",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "immuno-oncology-technology",
    text: "Immuno-Oncology Technology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "immunobiology",
    text: "Immunobiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "immunogenetics",
    text: "Immunogenetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "immunologic-research",
    text: "Immunologic Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "immunological-reviews",
    text: "Immunological Reviews",
    parent: "american-medical-association",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1600-065X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "immunology-and-cell-biology",
    text: "Immunology &amp; Cell Biology",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-icb.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#fop",
    urltwo: "NA"
  },
  {
    id: "immunology-letters",
    text: "Immunology Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "immunomics",
    text: "Immunomics",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "immunotherapy",
    text: "Immunotherapy",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/imt",
    urltwo: "NA"
  },
  {
    id: "impact-assessment-and-project-appraisal",
    text: "Impact Assessment and Project Appraisal",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TIAP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "implementation-science",
    text: "Implementation Science",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "in-analysis",
    text: "In Analysis",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "in-practice",
    text: "In Practice",
    parent: "bmj",
    urlone: "http://inpractice.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "in-silico-cell-and-tissue-science",
    text: "In Silico Cell and Tissue Science",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "in-silico-pharmacology",
    text: "In Silico Pharmacology",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "in-vitro-cellular-and-developmental-biology-animal",
    text: "In Vitro Cellular &amp; Developmental Biology - Animal",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "in-vitro-cellular-and-developmental-biology-plant",
    text: "In Vitro Cellular &amp; Developmental Biology - Plant",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-dermatology-online-journal",
    text: "Indian Dermatology Online Journal",
    parent: "NA",
    urlone: "http://www.idoj.in/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-geotechnical-journal",
    text: "Indian Geotechnical Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-heart-journal",
    text: "Indian Heart Journal",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-anaesthesia",
    text: "Indian Journal of Anaesthesia",
    parent: "vancouver-brackets",
    urlone: "http://www.ijaweb.org/contributors.asp#tm",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-cancer",
    text: "Indian Journal of Cancer",
    parent: "vancouver-superscript",
    urlone: "http://www.indianjcancer.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-clinical-biochemistry",
    text: "Indian Journal of Clinical Biochemistry",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-critical-care-medicine",
    text: "Indian Journal of Critical Care Medicine",
    parent: "vancouver-superscript",
    urlone: "http://www.ijccm.org/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-dentistry",
    text: "Indian Journal of Dentistry",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-dermatology-venereology-and-leprology",
    text: "Indian Journal of Dermatology, Venereology and Leprology",
    parent: "vancouver-superscript",
    urlone: "http://www.ijdvl.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-dermatology",
    text: "Indian Journal of Dermatology",
    parent: "vancouver-superscript",
    urlone: "http://www.e-ijd.org/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-gastroenterology",
    text: "Indian Journal of Gastroenterology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-gynecologic-oncology",
    text: "Indian Journal of Gynecologic Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-hematology-and-blood-transfusion",
    text: "Indian Journal of Hematology and Blood Transfusion",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-human-genetics",
    text: "Indian Journal of Human Genetics",
    parent: "vancouver-brackets",
    urlone: "http://www.ijhg.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-medical-informatics",
    text: "Indian Journal of Medical Informatics",
    parent: "vancouver-brackets",
    urlone: "http://ijmi.org/index.php/ijmi/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-medical-microbiology",
    text: "Indian Journal of Medical Microbiology",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.ijmm.org/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-medical-research",
    text: "Indian Journal of Medical Research",
    parent: "NA",
    urlone: "http://www.ijmr.org.in/contributors.asp",
    urltwo: "http://www.nlm.nih.gov/bsd/uniform_requirements.html"
  },
  {
    id: "indian-journal-of-medical-sciences",
    text: "Indian Journal of Medical Sciences",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.indianjmedsci.org/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-medical-specialities",
    text: "Indian Journal of Medical Specialities",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-microbiology",
    text: "Indian Journal of Microbiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-occupational-and-environmental-medicine",
    text: "Indian Journal of Occupational &amp; Environmental Medicine",
    parent: "vancouver-brackets",
    urlone: "http://www.ijoem.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-ophthalmology",
    text: "Indian Journal of Ophthalmology",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.ijo.in/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-orthopaedics",
    text: "Indian Journal of Orthopaedics",
    parent: "NA",
    urlone: "http://www.ijoonline.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-otolaryngology-and-head-and-neck-surgery",
    text: "Indian Journal of Otolaryngology and Head &amp; Neck Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-pharmacology",
    text: "Indian Journal of Pharmacology",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.ijp-online.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-physics",
    text: "Indian Journal of Physics",
    parent: "NA",
    urlone: "https://www.editorialmanager.com/injp/default.aspx",
    urltwo:
      "http://www.springer.com/cda/content/document/cda_downloaddocument/Note+to+Contributors_IJP.pdf?SGWID=0-0-45-819298-p173873837"
  },
  {
    id: "indian-journal-of-plant-physiology",
    text: "Indian Journal of Plant Physiology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-plastic-surgery",
    text: "Indian Journal of Plastic Surgery",
    parent: "vancouver-superscript",
    urlone: "http://www.ijps.org/contributors.asp#ref",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-rheumatology",
    text: "Indian Journal of Rheumatology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-surgery",
    text: "Indian Journal of Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-surgical-oncology",
    text: "Indian Journal of Surgical Oncology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-thoracic-and-cardiovascular-surgery",
    text: "Indian Journal of Thoracic and Cardiovascular Surgery",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "indian-journal-of-traditional-knowledge",
    text: "Indian Journal of Traditional Knowledge",
    parent: "NA",
    urlone: "http://nopr.niscair.res.in/ijtk_inst_auth.htm",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-transplantation",
    text: "Indian Journal of Transplantation",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-tuberculosis",
    text: "Indian Journal of Tuberculosis",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "indian-journal-of-urology",
    text: "Indian Journal of Urology",
    parent: "vancouver-brackets",
    urlone: "http://www.indianjurol.com/contributors.asp#ref",
    urltwo: "NA"
  },
  {
    id: "indian-pacing-and-electrophysiology-journal",
    text: "Indian Pacing and Electrophysiology Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "indiana",
    text: "INDIANA",
    parent: "NA",
    urlone: "http://journals.iai.spk-berlin.de/index.php/indiana/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "indoor-air",
    text: "Indoor Air",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291600-0668/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "industrial-and-engineering-chemistry-research",
    text: "Industrial &amp; Engineering Chemistry Research",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/iecred/iecred_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "industrial-crops-and-products",
    text: "Industrial Crops &amp; Products",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "industrial-marketing-management",
    text: "Industrial Marketing Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "industrial-relations",
    text: "Industrial Relations",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/1468232x/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "infant-behavior-and-development",
    text: "Infant Behavior and Development",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "infant-observation",
    text: "Infant Observation",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIOB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "infectio",
    text: "Infectio (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/infectio/0123-9392/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "infection-and-immunity",
    text: "Infection and Immunity",
    parent: "american-society-for-microbiology",
    urlone: "http://iai.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "infection-control-and-hospital-epidemiology",
    text: "Infection Control &amp; Hospital Epidemiology",
    parent: "american-medical-association-no-url",
    urlone: "http://www.press.uchicago.edu/journals/iche/instruct",
    urltwo: "NA"
  },
  {
    id: "infection-disease-and-health",
    text: "Infection, Disease &amp; Health",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "infection-genetics-and-evolution",
    text: "Infection, Genetics and Evolution",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "infection-prevention-in-practice",
    text: "Infection Prevention in Practice",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "infection",
    text: "Infection",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "infectious-agents-and-cancer",
    text: "Infectious Agents and Cancer",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "infectious-disease-clinics-of-north-america",
    text: "Infectious Disease Clinics of North America",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/infectious-disease-clinics-of-north-america/0891-5520/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "infectious-disease-modelling",
    text: "Infectious Disease Modelling",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "infectious-diseases-and-therapy",
    text: "Infectious Diseases and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "infectious-diseases-of-poverty",
    text: "Infectious Diseases of Poverty",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "inflammation-research",
    text: "Inflammation Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "inflammation",
    text: "Inflammation",
    parent: "springer-humanities-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "inflammatory-bowel-diseases",
    text: "Inflammatory Bowel Diseases",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291536-4844/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "inflammopharmacology",
    text: "Inflammopharmacology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "influenza-and-other-respiratory-viruses",
    text: "Influenza and Other Respiratory Viruses",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1750-2659/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "infoclio-de",
    text: "infoclio.ch (German - Switzerland)",
    parent: "NA",
    urlone: "https://www.infoclio.ch/de/node/133932",
    urltwo: "NA"
  },
  {
    id: "infoclio-fr-nocaps",
    text: "infoclio.ch (sans majuscules, French)",
    parent: "NA",
    urlone: "https://www.infoclio.ch/fr/node/138218",
    urltwo: "NA"
  },
  {
    id: "infoclio-fr-smallcaps",
    text: "infoclio.ch (petites majuscules, French)",
    parent: "NA",
    urlone: "https://www.infoclio.ch/fr/node/138218",
    urltwo: "NA"
  },
  {
    id: "infomin",
    text: "Infomin",
    parent: "NA",
    urlone: "http://www.infomin.co.cu/index.php/i/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "informal-logic",
    text: "Informal Logic",
    parent: "NA",
    urlone: "https://informallogic.ca/index.php/informal_logic/about/submissions",
    urltwo: "NA"
  },
  {
    id: "informatics-in-medicine-unlocked",
    text: "Informatics in Medicine Unlocked",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "informatics",
    text: "Informatics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "informatik-spektrum",
    text: "Informatik-Spektrum (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "information-and-communications-technology-law",
    text: "Information &amp; Communications Technology Law",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CICT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "information-and-computation",
    text: "Information and Computation",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-and-management",
    text: "Information &amp; Management",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-and-organization",
    text: "Information and Organization",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-and-software-technology",
    text: "Information and Software Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-communication-and-society",
    text: "Information, Communication &amp; Society",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RICS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "information-economics-and-policy",
    text: "Information Economics and Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-fusion",
    text: "Information Fusion",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-processing-and-management",
    text: "Information Processing and Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-processing-in-agriculture",
    text: "Information Processing in Agriculture",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-processing-letters",
    text: "Information Processing Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-retrieval-journal",
    text: "Information Retrieval Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "information-sciences",
    text: "Information Sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-security-technical-report",
    text: "Information Security Technical Report",
    parent: "vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-systems-and-e-business-management",
    text: "Information Systems and e-Business Management",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "information-systems-frontiers",
    text: "Information Systems Frontiers",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "information-systems-journal",
    text: "Information Systems Journal",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2575/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "information-systems-research",
    text: "Information Systems Research",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/isre/instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "information-systems",
    text: "Information Systems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "information-technology-and-management",
    text: "Information Technology and Management",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "information-technology-and-tourism",
    text: "Information Technology &amp; Tourism",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "information-technology-for-development",
    text: "Information Technology for Development",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TITD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "information",
    text: "Information",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "informationspraxis",
    text: "Informationspraxis (German)",
    parent: "harvard-gesellschaft-fur-bildung-und-forschung-in-europa",
    urlone: "https://journals.ub.uni-heidelberg.de/index.php/ip/about/submissions#authorGuidelines",
    urltwo: "http://www.acf.de/uploads/media/GBFE_Studienbrief_5_Form_bewahren_01.pdf"
  },
  {
    id: "informs-journal-on-computing",
    text: "INFORMS Journal on Computing",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/ijoc/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "informs-transactions-on-education",
    text: "INFORMS Transactions on Education",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/ited/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "infrared-physics-and-technology",
    text: "Infrared Physics and Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "infrastructure-complexity",
    text: "Infrastructure Complexity",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "infrastructures",
    text: "Infrastructures",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "ingenieria-agricola",
    text: "IngenierÃ­a AgrÃ­cola (Spanish)",
    parent: "NA",
    urlone: "http://www.actaf.co.cu/revistas/Revista%20IAGRIC/normasedito.pdf",
    urltwo: "NA"
  },
  {
    id: "injury-epidemiology",
    text: "Injury Epidemiology",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "injury-extra",
    text: "Injury Extra",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "injury-prevention",
    text: "Injury Prevention",
    parent: "bmj",
    urlone: "http://injuryprevention.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "injury",
    text: "Injury",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "innovation-and-development",
    text: "Innovation and Development",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIAD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "innovation-in-language-learning-and-teaching",
    text: "Innovation in Language Learning and Teaching",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RILL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "innovation-the-european-journal-of-social-science-research",
    text: "Innovation: The European Journal of Social Science Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CIEJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "innovations-in-education-and-teaching-international",
    text: "Innovations in Education and Teaching International",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIIE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "innovations-in-systems-and-software-engineering",
    text: "Innovations in Systems and Software Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "innovative-food-science-and-emerging-technologies",
    text: "Innovative Food Science and Emerging Technologies",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "innovative-higher-education",
    text: "Innovative Higher Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "innovative-infrastructure-solutions",
    text: "Innovative Infrastructure Solutions",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "inorganic-chemistry-communications",
    text: "Inorganic Chemistry Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "inorganic-chemistry-frontiers",
    text: "Inorganic Chemistry Frontiers",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "inorganic-chemistry",
    text: "Inorganic Chemistry",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/inocaj/inocaj_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "inorganica-chimica-acta",
    text: "Inorganica Chimica Acta",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "inorganics",
    text: "Inorganics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "insect-biochemistry-and-molecular-biology",
    text: "Insect Biochemistry and Molecular Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "insectes-sociaux",
    text: "Insectes Sociaux",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "insects",
    text: "Insects",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "insights-into-imaging",
    text: "Insights into Imaging",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "insolvency-law-journal",
    text: "Insolvency Law Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "instap-academic-press",
    text: "INSTAP Academic Press",
    parent: "NA",
    urlone: "http://instappress.com/submitting-manuscripts/style-guide/",
    urltwo: "NA"
  },
  {
    id: "institut-catholique-de-paris",
    text: "Institut Catholique de Paris (French)",
    parent: "transversalites",
    urlone: "https://github.com/citation-style-language/styles/pull/4374#issuecomment-565168897",
    urltwo: "NA"
  },
  {
    id: "institut-francais-darcheologie-orientale-en",
    text: "Institut franÃ§ais d'archÃ©ologie orientale (English)",
    parent: "NA",
    urlone:
      "https://www.ifao.egnet.net/uploads/publications/normes/IFAO_publications_normes_bibliographiques_pub_egypto_2020_angl.pdf",
    urltwo: "NA"
  },
  {
    id: "institut-francais-darcheologie-orientale",
    text: "Institut franÃ§ais d'archÃ©ologie orientale (French)",
    parent: "NA",
    urlone:
      "https://www.ifao.egnet.net/uploads/publications/normes/IFAO_publications_normes_bibliographiques_pub_egypto_2020_fr.pdf",
    urltwo: "NA"
  },
  {
    id: "institut-national-de-la-recherche-scientifique-sciences-sociales",
    text: "Institut national de la recherche scientifique - Sciences sociales (author-date, French)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "http://sdis.inrs.ca/guide-des-memoires-et-theses"
  },
  {
    id: "institut-national-de-recherches-archeologiques-preventives",
    text: "Institut national de recherches archÃ©ologiques prÃ©ventives (French)",
    parent: "NA",
    urlone: "https://multimedia.inrap.fr/Ressources-documentaires/Tutoriels-FAQ/p-20507-Memento-bibliographique.htm",
    urltwo: "NA"
  },
  {
    id: "institut-national-de-sante-publique-du-quebec-napp",
    text: "Institut national de santÃ© publique du QuÃ©bec - NAPP (French - Canada)",
    parent: "NA",
    urlone: "http://www.inspq.qc.ca/pdf/publications/CollectionTOPO/TOPO-NAPP_styleguide.pdf",
    urltwo: "NA"
  },
  {
    id: "institut-national-de-sante-publique-du-quebec-topo",
    text: "Institut national de santÃ© publique du QuÃ©bec - TOPO (French - Canada)",
    parent: "NA",
    urlone: "http://www.inspq.qc.ca/pdf/publications/CollectionTOPO/TOPO-NAPP_styleguide.pdf",
    urltwo: "NA"
  },
  {
    id: "institut-pertanian-bogor",
    text: "Institut Pertanian Bogor: Pedoman Penulisan Karya Ilmiah Edisi ke-3 (Indonesian)",
    parent: "NA",
    urlone: "http://ppki.staff.ipb.ac.id/unduhan/",
    urltwo: "https://drive.google.com/file/d/0B0YEuQotqd_BQVpkR0lHNHctSHM/view"
  },
  {
    id: "institut-teknologi-bandung-sekolah-pascasarjana",
    text: "Institut Teknologi Bandung - Sekolah Pascasarjana",
    parent: "NA",
    urlone: "http://www.sps.itb.ac.id/in/pedoman-tesis-dan-disertasi/",
    urltwo: "https://itb-sps.github.io/csl/"
  },
  {
    id: "institute-for-operations-research-and-the-management-sciences",
    text: "Institute for Operations Research and the Management Sciences",
    parent: "NA",
    urlone: "https://www.informs.org/Find-Research-Publications/INFORMS-Journals/Author-Portal/LaTeX-Style-Files",
    urltwo: "NA"
  },
  {
    id: "institute-of-mathematical-statistics",
    text: "Institute of Mathematical Statistics journals",
    parent: "NA",
    urlone: "http://www.imstat.org/aop/manprep.htm",
    urltwo: "NA"
  },
  {
    id: "institute-of-mathematics-and-its-applications",
    text: "Institute of Mathematics and its Applications",
    parent: "NA",
    urlone: "https://academic.oup.com/teamat/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "institute-of-physics-harvard",
    text: "Institute of Physics - Harvard",
    parent: "NA",
    urlone: "http://authors.iop.org/atom/help.nsf/7765E7F03B07DE5080257020002E4D9B/$File/IOPWordGuidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "institute-of-physics-numeric",
    text: "Institute of Physics (numeric)",
    parent: "NA",
    urlone: "http://authors.iop.org/atom/help.nsf/7765E7F03B07DE5080257020002E4D9B/$File/IOPWordGuidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "instituto-brasileiro-de-informacao-em-ciencia-e-tecnologia-abnt-initials",
    text: "Instituto Brasileiro de InformaÃ§Ã£o em CiÃªncia e Tecnologia - ABNT (autoria abreviada)",
    parent: "NA",
    urlone: "http://insumo.ibict.br/produtos/csl-ibict/",
    urltwo: "NA"
  },
  {
    id: "instituto-brasileiro-de-informacao-em-ciencia-e-tecnologia-abnt",
    text: "Instituto Brasileiro de InformaÃ§Ã£o em CiÃªncia e Tecnologia - ABNT (autoria completa)",
    parent: "NA",
    urlone: "http://insumo.ibict.br/produtos/csl-ibict/",
    urltwo: "NA"
  },
  {
    id: "instituto-de-investigaciones-sobre-la-universidad-y-la-educacion-moderno",
    text: "Instituto de Investigaciones sobre la Universidad y la EducaciÃ³n - Moderno (autor-fecha, Spanish)",
    parent: "NA",
    urlone: "http://www.iisue.unam.mx/iisue/avisos/pautas-editoriales-del-IISUE.pdf",
    urltwo: "NA"
  },
  {
    id: "instituto-de-pesquisas-energeticas-e-nucleares",
    text: "Instituto de Pesquisas EnergÃ©ticas e Nucleares (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://www.ipen.br/portal_por/conteudo/biblioteca/arquivos/GuiaIPEN_2017-10-24_versao_4.pdf",
    urltwo: "NA"
  },
  {
    id: "instituto-de-pesquisas-tecnologicas",
    text: "Instituto de Pesquisas TecnolÃ³gicas (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://www.ipt.br/en",
    urltwo: "NA"
  },
  {
    id: "instituto-superior-de-teologia-de-las-islas-canarias",
    text: "Instituto Superior de TeologÃ­a de las Islas Canarias (Spanish)",
    parent: "NA",
    urlone: "http://www.istic.es",
    urltwo: "NA"
  },
  {
    id: "instructional-science",
    text: "Instructional Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "instruments",
    text: "Instruments",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "insurance-mathematics-and-economics",
    text: "Insurance Mathematics and Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "integral-equations-and-operator-theory",
    text: "Integral Equations and Operator Theory",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "integral-transforms-and-special-functions",
    text: "Integral Transforms and Special Functions",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GITR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "integrated-research-advances",
    text: "Integrated Research Advances",
    parent: "integrated-science-publishing-journals",
    urlone: "http://www.pubs.iscience.in/journal/index.php/ira/about/submissions#onlineSubmissions",
    urltwo: "NA"
  },
  {
    id: "integrated-science-publishing-journals",
    text: "Integrated Science Publishing journals",
    parent: "NA",
    urlone: "http://pubs.iscience.in/authors",
    urltwo: "NA"
  },
  {
    id: "integrating-materials-and-manufacturing-innovation",
    text: "Integrating Materials and Manufacturing Innovation",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "integration",
    text: "Integration",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "integrative-and-comparative-biology",
    text: "Integrative &amp; Comparative Biology",
    parent: "NA",
    urlone: "https://academic.oup.com/icb/pages/Manuscript_Preparation_Submission",
    urltwo: "NA"
  },
  {
    id: "integrative-biology",
    text: "Integrative Biology",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "integrative-medicine-international",
    text: "Integrative Medicine International",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/261486",
    urltwo: "NA"
  },
  {
    id: "integrative-medicine-research",
    text: "Integrative Medicine Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "integrative-psychological-and-behavioral-science",
    text: "Integrative Psychological and Behavioral Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "intellectual-economics",
    text: "Intellectual Economics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "intelligence",
    text: "Intelligence",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "intelligent-buildings-international",
    text: "Intelligent Buildings International",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TIBI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "intelligent-industrial-systems",
    text: "Intelligent Industrial Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "intelligent-service-robotics",
    text: "Intelligent Service Robotics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "intensive-and-critical-care-nursing",
    text: "Intensive &amp; Critical Care Nursing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "intensive-care-medicine-experimental",
    text: "Intensive Care Medicine Experimental",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "intensive-care-medicine",
    text: "Intensive Care Medicine",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "intensivmedizin-up2date",
    text: "Intensivmedizin up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "inter-asia-cultural-studies",
    text: "Inter-Asia Cultural Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIAC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "inter-research-science-center",
    text: "Inter-Research Science Center",
    parent: "NA",
    urlone: "https://www.int-res.com/journals/guidelines-for-authors/guidelines-authors/",
    urltwo: "NA"
  },
  {
    id: "inter-ro",
    text: "INTER: Romanian Review for Theological and Religious Studies",
    parent: "NA",
    urlone: "http://www.inter-review.ro/index.php/en/authors-guidelines/editorial-policies",
    urltwo: "NA"
  },
  {
    id: "interacting-with-computers",
    text: "Interacting with Computers",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "interaction-design-and-architectures",
    text: "Interaction Design &amp; Architecture(s)",
    parent: "NA",
    urlone: "http://www.mifav.uniroma2.it/inevent/events/idea2010/index.php?s=101&amp;a=7",
    urltwo: "NA"
  },
  {
    id: "interactive-cardiovascular-and-thoracic-surgery",
    text: "Interactive CardioVascular and Thoracic Surgery",
    parent: "NA",
    urlone: "https://academic.oup.com/icvts/pages/Submission_Online#Manuscript%20format%20and%20style",
    urltwo: "NA"
  },
  {
    id: "interactive-learning-environments",
    text: "Interactive Learning Environments",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NILE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "interbloc",
    text: "Interbloc",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "interchange",
    text: "Interchange",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "intercultural-education",
    text: "Intercultural Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CEJI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "interdisciplinary-neurosurgery-advanced-techniques-and-case-management",
    text: "Interdisciplinary Neurosurgery: Advanced Techniques and Case Management",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "interdisciplinary-sciences-computational-life-sciences",
    text: "Interdisciplinary Sciences: Computational Life Sciences",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "interdisziplinare-anthropologie",
    text: "InterdisziplinÃ¤re Anthropologie (German)",
    parent: "NA",
    urlone: "https://jbia.weebly.com/uploads/1/0/1/1/101122/hinweise_f%C3%BCr_autoren_2020.pdf",
    urltwo: "NA"
  },
  {
    id: "interdisziplinare-zeitschrift-fur-technologie-und-lernen",
    text: "InterdisziplinÃ¤re Zeitschrift fÃ¼r Technologie und Lernen (German)",
    parent: "NA",
    urlone: "http://www.itel-journal.org/index.php/itel/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "interface-focus",
    text: "Interface Focus",
    parent: "proceedings-of-the-royal-society-b",
    urlone: "https://royalsociety.org/journals/authors/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "interfaces",
    text: "Interfaces",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/inte/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "interfacial-phenomena-and-heat-transfer",
    text: "Interfacial Phenomena and Heat Transfer",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/728e68e739b67efe.html",
    urltwo: "NA"
  },
  {
    id: "intermetallics",
    text: "Intermetallics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "internal-and-emergency-medicine",
    text: "Internal and Emergency Medicine",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-advances-in-economic-research",
    text: "International Advances in Economic Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-affairs-forum",
    text: "International Affairs Forum",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIAF20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-aquatic-research",
    text: "International Aquatic Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-archives-of-allergy-and-immunology",
    text: "International Archives of Allergy and Immunology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0097",
    urltwo: "NA"
  },
  {
    id: "international-archives-of-medicine",
    text: "International Archives of Medicine",
    parent: "vancouver",
    urlone: "http://imed.pub/ojs/index.php/iam/about/submissions",
    urltwo: "NA"
  },
  {
    id: "international-archives-of-occupational-and-environmental-health",
    text: "International Archives of Occupational and Environmental Health",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-archives-of-science-and-technology",
    text: "International Archives of Science and Technology",
    parent: "integrated-science-publishing-journals",
    urlone: "http://www.pubs.iscience.in/journal/index.php/iast/about/submissions#onlineSubmissions",
    urltwo: "NA"
  },
  {
    id: "international-atomic-energy-agency",
    text: "International Atomic Energy Agency",
    parent: "NA",
    urlone: "http://www-pub.iaea.org/mtcd/publications/pdf/iaea_stylemanual_06.pdf",
    urltwo: "NA"
  },
  {
    id: "international-biodeterioration-and-biodegradation",
    text: "International Biodeterioration &amp; Biodegradation",
    parent: "NA",
    urlone:
      "https://www.elsevier.com/journals/international-biodeterioration-and-biodegradation/0964-8305?generatepdf=true",
    urltwo: "NA"
  },
  {
    id: "international-brazilian-journal-of-urology",
    text: "International Brazilian Journal Of Urology",
    parent: "NA",
    urlone: "http://www.intbrazjurol.com.br/journal-information/information-for-authors-2/",
    urltwo: "NA"
  },
  {
    id: "international-breastfeeding-journal",
    text: "International Breastfeeding Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-business-review",
    text: "International Business Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-cancer-conference-journal",
    text: "International Cancer Conference Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-communication-of-chinese-culture",
    text: "International Communication of Chinese Culture",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-communications-in-heat-and-mass-transfer",
    text: "International Communications in Heat and Mass Transfer",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-comparative-jurisprudence",
    text: "International Comparative Jurisprudence",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-conference-on-human-computer-interaction-proceedings",
    text: "International Conference on Human-Computer Interaction",
    parent: "springer-lecture-notes-in-computer-science",
    urlone: "http://www.hcii2013.org/userfiles/files/Springer%20CS%20Proceedings%20Author%20Guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "international-conference-on-information-systems-development",
    text: "International Conference on Information Systems Development",
    parent: "NA",
    urlone: "http://isd2014.foi.hr/calls.php#subdets",
    urltwo: "NA"
  },
  {
    id: "international-critical-thought",
    text: "International Critical Thought",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RICT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-dairy-journal",
    text: "International Dairy Journal",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-development-policy",
    text: "International Development Policy",
    parent: "NA",
    urlone: "https://poldev.revues.org/2085?file=1",
    urltwo: "NA"
  },
  {
    id: "international-economic-journal",
    text: "International Economic Journal",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIEJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-economics-and-economic-policy",
    text: "International Economics and Economic Policy",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-economics",
    text: "International Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-emergency-nursing",
    text: "International Emergency Nursing",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-energy-agency-organisation-for-economic-co-operation-and-development",
    text: "International Energy Agency - Organisation for Economic Co-operation and Development",
    parent: "NA",
    urlone: "https://www.oecd.org/about/publishing/OECD-Style-Guide-Third-Edition.pdf",
    urltwo: "NA"
  },
  {
    id: "international-entrepreneurship-and-management-journal",
    text: "International Entrepreneurship and Management Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-environmental-agreements-politics-law-and-economics",
    text: "International Environmental Agreements: Politics, Law and Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-gambling-studies",
    text: "International Gambling Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIGS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-immunopharmacology",
    text: "International Immunopharmacology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-information-and-library-review",
    text: "International Information and Library Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-islamic-university-malaysia-ahmad-ibrahim-kulliyyah-of-laws",
    text: "International Islamic University Malaysia - Ahmad Ibrahim Kulliyyah of Laws",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-for-academic-development",
    text: "International Journal for Academic Development",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIJA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-for-educational-and-vocational-guidance",
    text: "International Journal for Educational and Vocational Guidance",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-for-educational-integrity",
    text: "International Journal for Educational Integrity",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-for-equity-in-health",
    text: "International Journal for Equity in Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-for-ion-mobility-spectrometry",
    text: "International Journal for Ion Mobility Spectrometry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-for-multiscale-computational-engineering",
    text: "International Journal for Multiscale Computational Engineering",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/61fd1b191cf7e96f.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-for-numerical-methods-in-biomedical-engineering",
    text: "International Journal for Numerical Methods in Biomedical Engineering",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)2040-7947/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-for-parasitology-drugs-and-drug-resistance",
    text: "International Journal for Parasitology: Drugs and Drug Resistance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-for-parasitology-parasites-and-wildlife",
    text: "International Journal for Parasitology: Parasites and Wildlife",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-for-parasitology",
    text: "International Journal for Parasitology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-for-philosophy-of-religion",
    text: "International Journal for Philosophy of Religion",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-for-quality-research",
    text: "International Journal for Quality Research",
    parent: "apa",
    urlone: "http://www.ijqr.net/guide_for_authors.php",
    urltwo: "NA"
  },
  {
    id: "international-journal-for-the-advancement-of-counselling",
    text: "International Journal for the Advancement of Counselling",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-for-the-semiotics-of-law-revue-internationale-de-semiotique-juridique",
    text: "International Journal for the Semiotics of Law - Revue internationale de SÃ©miotique juridique",
    parent: "springer-humanities-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-for-uncertainty-quantification",
    text: "International Journal for Uncertainty Quantification",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/52034eb04b657aea.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-accounting-information-systems",
    text: "International Journal of Accounting Information Systems",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-accounting",
    text: "International Journal of Accounting",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-adhesion-and-adhesives",
    text: "International Journal of Adhesion and Adhesives",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-adipose-tissue-and-stem-cells",
    text: "International Journal of Adipose Tissue and Stem Cells",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-adolescence-and-youth",
    text: "International Journal of Adolescence and Youth",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RADY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-advanced-nuclear-reactor-design-and-technology",
    text: "International Journal of Advanced Nuclear Reactor Design and Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-advanced-structural-engineering",
    text: "International Journal of Advanced Structural Engineering",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-advances-in-engineering-sciences-and-applied-mathematics",
    text: "International Journal of Advances in Engineering Sciences and Applied Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-africa-nursing-sciences",
    text: "International Journal of Africa Nursing Sciences",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-ambient-energy",
    text: "International Journal of Ambient Energy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TAEN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-antimicrobial-agents",
    text: "International Journal of Antimicrobial Agents",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-applied-and-computational-mathematics",
    text: "International Journal of Applied and Computational Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-applied-earth-observations-and-geoinformation",
    text: "International Journal of Applied Earth Observations and Geoinformation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-approximate-reasoning",
    text: "International Journal of Approximate Reasoning",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-art-therapy",
    text: "International Journal of Art Therapy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RART20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-artificial-intelligence-in-education",
    text: "International Journal of Artificial Intelligence in Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-audiology",
    text: "International Journal of Audiology",
    parent: "NA",
    urlone: "http://informahealthcare.com/userimages/ContentEditor/1273231534162/IJA_IFA_online.pdf",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-behavioral-medicine",
    text: "International Journal of Behavioral Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-behavioral-nutrition-and-physical-activity",
    text: "International Journal of Behavioral Nutrition and Physical Activity",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-bilingual-education-and-bilingualism",
    text: "International Journal of Bilingual Education and Bilingualism",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RBEB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-biochemistry-and-cell-biology",
    text: "International Journal of Biochemistry and Cell Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-biodiversity-science-ecosystem-services-and-management",
    text: "International Journal of Biodiversity Science, Ecosystem Services &amp; Management",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TBSM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-biological-macromolecules",
    text: "International Journal of Biological Macromolecules",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-biological-sciences",
    text: "International Journal of Biological Sciences",
    parent: "vancouver",
    urlone: "http://www.biolsci.org/ms/author",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-biometeorology",
    text: "International Journal of Biometeorology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-bipolar-disorders",
    text: "International Journal of Bipolar Disorders",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-cancer",
    text: "International Journal of Cancer",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-0215/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-cardiology-hypertension",
    text: "International Journal of Cardiology Hypertension",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-cardiology",
    text: "International Journal of Cardiology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-chemical-and-analytical-science",
    text: "International Journal of Chemical and Analytical Science",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-child-care-and-education-policy",
    text: "International Journal of Child Care and Education Policy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-child-computer-interaction",
    text: "International Journal of Child-Computer Interaction",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-childrens-spirituality",
    text: "International Journal of Children's Spirituality",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CIJC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-circuit-theory-and-applications",
    text: "International Journal of Circuit Theory and Applications",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291097-007X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-climatology",
    text: "International Journal of Climatology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-0088/homepage/ForAuthors.html#reference_style",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-clinical-and-health-psychology",
    text: "International Journal of Clinical and Health Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-clinical-oncology",
    text: "International Journal of Clinical Oncology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-clinical-pharmacy",
    text: "International Journal of Clinical Pharmacy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-clinical-rheumatology",
    text: "International Journal of Clinical Rheumatology",
    parent: "future-science-group",
    urlone: "http://www.futuremedicine.com/loi/ijr",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-coal-geology",
    text: "International Journal of Coal Geology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-coal-science-and-technology",
    text: "International Journal of Coal Science &amp; Technology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-colorectal-disease",
    text: "International Journal of Colorectal Disease",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-communication",
    text: "International Journal of Communication",
    parent: "apa",
    urlone: "http://ijoc.org/index.php/ijoc/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-comparative-and-applied-criminal-justice",
    text: "International Journal of Comparative and Applied Criminal Justice",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCAC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-computational-fluid-dynamics",
    text: "International Journal of Computational Fluid Dynamics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GCFD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-computer-assisted-radiology-and-surgery",
    text: "International Journal of Computer Assisted Radiology and Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-computer-supported-collaborative-learning",
    text: "International Journal of Computer-Supported Collaborative Learning",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-computer-vision",
    text: "International Journal of Computer Vision",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-concrete-structures-and-materials",
    text: "International Journal of Concrete Structures and Materials",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-control",
    text: "International Journal of Control",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TCON20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-cross-cultural-management",
    text: "International Journal of Cross Cultural Management",
    parent: "sage-harvard",
    urlone: "http://ccm.sagepub.com/",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-culture-and-mental-health",
    text: "International Journal of Culture and Mental Health",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCCM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-data-science-and-analytics",
    text: "International Journal of Data Science and Analytics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-dental-science-and-research",
    text: "International Journal of Dental Science and Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-design-creativity-and-innovation",
    text: "International Journal of Design Creativity and Innovation",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TDCI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-design",
    text: "International Journal of Design",
    parent: "apa",
    urlone: "http://www.ijdesign.org/ojs/index.php/IJDesign/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-developmental-neuroscience",
    text: "International Journal of Developmental Neuroscience",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-dharma-studies",
    text: "International Journal of Dharma Studies",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-diabetes-in-developing-countries",
    text: "International Journal of Diabetes in Developing Countries",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-diabetes-mellitus",
    text: "International Journal of Diabetes Mellitus",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-digital-earth",
    text: "International Journal of Digital Earth",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJDE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-disability-development-and-education",
    text: "International Journal of Disability, Development and Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CIJD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-disaster-risk-reduction",
    text: "International Journal of Disaster Risk Reduction",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-disaster-risk-science",
    text: "International Journal of Disaster Risk Science",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-drug-policy",
    text: "International Journal of Drug Policy",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-dynamics-and-control",
    text: "International Journal of Dynamics and Control",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-early-childhood",
    text: "International Journal of Early Childhood",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-early-years-education",
    text: "International Journal of Early Years Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CIEY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-earth-sciences",
    text: "International Journal of Earth Sciences",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-education-and-research",
    text: "International Journal of Education and Research",
    parent: "apa",
    urlone: "http://www.ijern.com/Author-Guideline.php",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-educational-development",
    text: "International Journal of Educational Development",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-educational-research",
    text: "International Journal of Educational Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-educational-technology-in-higher-education",
    text: "International Journal of Educational Technology in Higher Education",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-electrical-power-and-energy-systems",
    text: "International Journal of Electrical Power and Energy Systems",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-electrochemical-science",
    text: "International Journal of Electrochemical Science",
    parent: "NA",
    urlone: "http://www.electrochemsci.org/instruction.htm",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-electronic-commerce",
    text: "International Journal of Electronic Commerce",
    parent: "NA",
    urlone: "http://www.ijec-web.org/information-for-contributors/",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-electronics-letters",
    text: "International Journal of Electronics Letters",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TETL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-electronics",
    text: "International Journal of Electronics",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TETN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-emergency-medicine",
    text: "International Journal of Emergency Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-endocrine-oncology",
    text: "International Journal of Endocrine Oncology",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/ije",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-energetic-materials-and-chemical-propulsion",
    text: "International Journal of Energetic Materials and Chemical Propulsion",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/17bbb47e377ce023.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-energy-and-environmental-engineering",
    text: "International Journal of Energy and Environmental Engineering",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-energy-for-a-clean-environment",
    text: "International Journal of Energy for a Clean Environment",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/6d18a859536a7b02.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-engineering-science",
    text: "International Journal of Engineering Science",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-environmental-health-research",
    text: "International Journal of Environmental Health Research",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CIJE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-environmental-research-and-public-health",
    text: "International Journal of Environmental Research and Public Health",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-environmental-science-and-technology",
    text: "International Journal of Environmental Science and Technology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-epidemiology",
    text: "International Journal of Epidemiology",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/ije/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-epilepsy",
    text: "International Journal of Epilepsy",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-ethics-education",
    text: "International Journal of Ethics Education",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-exercise-science",
    text: "International Journal of Exercise Science",
    parent: "NA",
    urlone: "http://digitalcommons.wku.edu/ijes/styleguide.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-fashion-design-technology-and-education",
    text: "International Journal of Fashion Design, Technology and Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TFDT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-fatigue",
    text: "International Journal of Fatigue",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-financial-studies",
    text: "International Journal of Financial Studies",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-fluid-mechanics-research",
    text: "International Journal of Fluid Mechanics Research",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/71cb29ca5b40f8f8.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-food-contamination",
    text: "International Journal of Food Contamination",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-food-microbiology",
    text: "International Journal of Food Microbiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-food-science-and-technology",
    text: "International Journal of Food Science &amp; Technology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2621/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-forecasting",
    text: "International Journal of Forecasting",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-forest-engineering",
    text: "International Journal of Forest Engineering",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TIFE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-fracture",
    text: "International Journal of Fracture",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-fuzzy-systems",
    text: "International Journal of Fuzzy Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-game-theory",
    text: "International Journal of Game Theory",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-gastronomy-and-food-science",
    text: "International Journal of Gastronomy and Food Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-general-systems",
    text: "International Journal of General Systems",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GGEN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-geo-engineering",
    text: "International Journal of Geo-Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-geomechanics",
    text: "International Journal of Geomechanics",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/ijgnai",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-geosynthetics-and-ground-engineering",
    text: "International Journal of Geosynthetics and Ground Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-geriatric-psychiatry",
    text: "International Journal of Geriatric Psychiatry",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291099-1166/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-gerontology",
    text: "International Journal of Gerontology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-greenhouse-gas-control",
    text: "International Journal of Greenhouse Gas Control",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-gynecology-and-obstetrics",
    text: "International Journal of Gynecology and Obstetrics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-health-economics-and-management",
    text: "International Journal of Health Economics and Management",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-health-geographics",
    text: "International Journal of Health Geographics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-health-promotion-and-education",
    text: "International Journal of Health Promotion and Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RHPE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-heat-and-fluid-flow",
    text: "International Journal of Heat and Fluid Flow",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-heat-and-mass-transfer",
    text: "International Journal of Heat and Mass Transfer",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-hematologic-oncology",
    text: "International Journal of Hematologic Oncology",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/ijh",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-hematology",
    text: "International Journal of Hematology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-hospitality-management",
    text: "International Journal of Hospitality Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-housing-policy",
    text: "International Journal of Housing Policy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REUJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-human-computer-studies",
    text: "International Journal of Human - Computer Studies",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-humanoid-robotics",
    text: "International Journal of Humanoid Robotics",
    parent: "NA",
    urlone: "http://www.worldscientific.com/sda/1037/ws-ijhr.pdf",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-hydrogen-energy",
    text: "International Journal of Hydrogen Energy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-hygiene-and-environmental-health",
    text: "International Journal of Hygiene and Environmental Health",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-hyperthermia",
    text: "International Journal of Hyperthermia",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=ihyt20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-impact-engineering",
    text: "International Journal of Impact Engineering",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-implant-dentistry",
    text: "International Journal of Implant Dentistry",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-impotence-research",
    text: "International Journal of Impotence Research",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-ijir.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#preparation",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-inclusive-education",
    text: "International Journal of Inclusive Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TIED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-industrial-chemistry",
    text: "International Journal of Industrial Chemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-industrial-ergonomics",
    text: "International Journal of Industrial Ergonomics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-industrial-organization",
    text: "International Journal of Industrial Organization",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-infectious-diseases",
    text: "International Journal of Infectious Diseases",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-information-management",
    text: "International Journal of Information Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-information-security",
    text: "International Journal of Information Security",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-injury-control-and-safety-promotion",
    text: "International Journal of Injury Control and Safety Promotion",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NICS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-innovation-studies",
    text: "International Journal of Innovation Studies",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-intelligent-transportation-systems-research",
    text: "International Journal of Intelligent Transportation Systems Research",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-intercultural-relations",
    text: "International Journal of Intercultural Relations",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-jungian-studies",
    text: "International Journal of Jungian Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIJJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-language-and-communication-disorders",
    text: "International Journal of Language &amp; Communication Disorders",
    parent: "NA",
    urlone:
      "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1460-6984/homepage/ForAuthors.html#Manuscript_Components",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-law-and-psychiatry",
    text: "International Journal of Law and Psychiatry",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-law-crime-and-justice",
    text: "International Journal of Law, Crime and Justice",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-leadership-in-education",
    text: "International Journal of Leadership in Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TEDL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-legal-medicine",
    text: "International Journal of Legal Medicine",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-lexicography",
    text: "International Journal of Lexicography",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/lexico/for_authors/index.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-lifelong-education",
    text: "International Journal of Lifelong Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TLED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-lightweight-materials-and-manufacture",
    text: "International Journal of Lightweight Materials and Manufacture",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-logistics-research-and-applications",
    text: "International Journal of Logistics Research and Applications",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJOL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-machine-learning-and-cybernetics",
    text: "International Journal of Machine Learning and Cybernetics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-machine-tools-and-manufacture",
    text: "International Journal of Machine Tools and Manufacture",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-management-reviews",
    text: "International Journal of Management Reviews",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1468-2370/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-management-science-and-engineering-management",
    text: "International Journal of Management Science and Engineering Management",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TMSE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-marine-energy",
    text: "International Journal of Marine Energy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-mass-spectrometry",
    text: "International Journal of Mass Spectrometry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-material-forming",
    text: "International Journal of Material Forming",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-mathematical-education-in-science-and-technology",
    text: "International Journal of Mathematical Education in Science and Technology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TMES20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-mechanical-and-materials-engineering",
    text: "International Journal of Mechanical and Materials Engineering",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-mechanical-sciences",
    text: "International Journal of Mechanical Sciences",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-mechanics-and-materials-in-design",
    text: "International Journal of Mechanics and Materials in Design",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-medical-informatics",
    text: "International Journal of Medical Informatics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-medical-microbiology",
    text: "International Journal of Medical Microbiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-medical-sciences",
    text: "International Journal of Medical Sciences",
    parent: "vancouver",
    urlone: "http://www.medsci.org/ms/author",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-medicinal-mushrooms",
    text: "International Journal of Medicinal Mushrooms",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/708ae68d64b17c52.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-mental-health-and-addiction",
    text: "International Journal of Mental Health and Addiction",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-mental-health-promotion",
    text: "International Journal of Mental Health Promotion",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIJM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-mental-health-systems",
    text: "International Journal of Mental Health Systems",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-mineral-processing",
    text: "International Journal of Mineral Processing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-mining-science-and-technology",
    text: "International Journal of Mining Science and Technology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-molecular-medicine",
    text: "International Journal of Molecular Medicine",
    parent: "spandidos-publications",
    urlone: "http://www.spandidos-publications.com/pages/info_for_authors",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-molecular-sciences",
    text: "International Journal of Molecular Sciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-multilingualism",
    text: "International Journal of Multilingualism",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMJM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-multimedia-information-retrieval",
    text: "International Journal of Multimedia Information Retrieval",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-multiphase-flow",
    text: "International Journal of Multiphase Flow",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-mycobacteriology",
    text: "International Journal of Mycobacteriology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-naval-architecture-and-ocean-engineering",
    text: "International Journal of Naval Architecture and Ocean Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-neonatal-screening",
    text: "International Journal of Neonatal Screening",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-non-linear-mechanics",
    text: "International Journal of Non-Linear Mechanics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-nuclear-security",
    text: "International Journal of Nuclear Security",
    parent: "NA",
    urlone: "http://trace.tennessee.edu/ijns/policies.html#formatting",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-nursing-practice",
    text: "International Journal of Nursing Practice",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291440-172X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-nursing-sciences",
    text: "International Journal of Nursing Sciences",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-nursing-studies",
    text: "International Journal of Nursing Studies",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-obesity-supplements",
    text: "International Journal of Obesity Supplements",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/ijo/ijo_new_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-obesity",
    text: "International Journal of Obesity",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/ijo/ijo_new_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-obstetric-anesthesia",
    text: "International Journal of Obstetric Anesthesia",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/international-journal-of-obstetric-anesthesia/0959-289X/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-occupational-medicine-and-environmental-health",
    text: "International Journal of Occupational Medicine and Environmental Health",
    parent: "NA",
    urlone: "http://versita.com/serial/ijomeh/#tabs-submission",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-odonatology",
    text: "International Journal of Odonatology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TIJO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-oncology",
    text: "International Journal of Oncology",
    parent: "spandidos-publications",
    urlone: "http://www.spandidos-publications.com/pages/info_for_authors",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-oral-and-maxillofacial-surgery",
    text: "International Journal of Oral and Maxillofacial Surgery",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/international-journal-of-oral-and-maxillofacial-surgery/0901-5027/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-oral-science",
    text: "International Journal of Oral Science",
    parent: "american-medical-association",
    urlone: "http://mts-ijos.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-orthopaedic-and-trauma-nursing",
    text: "International Journal of Orthopaedic and Trauma Nursing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-osteoarchaeology",
    text: "International Journal of Osteoarchaeology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291099-1212/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-osteopathic-medicine",
    text: "International Journal of Osteopathic Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-paleopathology",
    text: "International Journal of Paleopathology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-parallel-programming",
    text: "International Journal of Parallel Programming",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-pavement-engineering",
    text: "International Journal of Pavement Engineering",
    parent: "taylor-and-francis-harvard-x",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=gpav20&amp;page=instructions#mp_style",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-pavement-research-and-technology",
    text: "International Journal of Pavement Research and Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-pediatric-endocrinology",
    text: "International Journal of Pediatric Endocrinology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-pediatric-otorhinolaryngology-case-reports",
    text: "International Journal of Pediatric Otorhinolaryngology Case Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-pediatric-otorhinolaryngology-extra",
    text: "International Journal of Pediatric Otorhinolaryngology Extra",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-pediatric-otorhinolaryngology",
    text: "International Journal of Pediatric Otorhinolaryngology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-pediatrics-and-adolescent-medicine",
    text: "International Journal of Pediatrics and Adolescent Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-peptide-research-and-therapeutics",
    text: "International Journal of Peptide Research and Therapeutics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-pest-management",
    text: "International Journal of Pest Management",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TTPM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-pharmaceutics-x",
    text: "International Journal of Pharmaceutics: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-pharmaceutics",
    text: "International Journal of Pharmaceutics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-pharmacy-practice",
    text: "International Journal of Pharmacy Practice",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%292042-7174/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-philosophy-and-theology",
    text: "International Journal of Philosophy and Theology",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJPT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-physiology-and-pathophysiology",
    text: "International Journal of Physiology and Pathophysiology",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/6ec4ba27650016b1.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-plant-sciences",
    text: "International Journal of Plant Sciences",
    parent: "NA",
    urlone: "http://www.press.uchicago.edu/journals/ijps/instruct.html?journal=ijps",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-plasticity",
    text: "International Journal of Plasticity",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-plastics-technology",
    text: "International Journal of Plastics Technology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-play-therapy",
    text: "International Journal of Play Therapy",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pla",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-play",
    text: "International Journal of Play",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIJP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-politics-culture-and-society",
    text: "International Journal of Politics, Culture, and Society",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-polymer-analysis-and-characterization",
    text: "International Journal of Polymer Analysis and Characterization",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?journalCode=gpac20&amp;page=instructions#refs",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-polymeric-materials-and-polymeric-biomaterials",
    text: "International Journal of Polymeric Materials and Polymeric Biomaterials",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=gpom20",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-population-data-science",
    text: "International Journal of Population Data Science",
    parent: "NA",
    urlone: "https://ijpds.org/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-pressure-vessels-and-piping",
    text: "International Journal of Pressure Vessels and Piping",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-primatology",
    text: "International Journal of Primatology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-production-economics",
    text: "International Journal of Production Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-production-research",
    text: "International Journal of Production Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TPRS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-project-management",
    text: "International Journal of Project Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-psychology",
    text: "International Journal of Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PIJP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-psychophysiology",
    text: "International Journal of Psychophysiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-public-health",
    text: "International Journal of Public Health",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-qualitative-studies-in-education",
    text: "International Journal of Qualitative Studies in Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TQSE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-quality-innovation",
    text: "International Journal of Quality Innovation",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-quantum-chemistry",
    text: "International Journal of Quantum Chemistry",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-461X",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-radiation-biology",
    text: "International Journal of Radiation Biology",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=irab20",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-radiation-oncology-biology-physics",
    text: "International Journal of Radiation Oncology, Biology, Physics",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/international-journal-of-radiation-oncology-biology-physics/0360-3016/guide-for-authors#manu",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-rail-transportation",
    text: "International Journal of Rail Transportation",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/loi/TJRT20#.U3qu_i_Wr7A",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-recycling-of-organic-waste-in-agriculture",
    text: "International Journal of Recycling of Organic Waste in Agriculture",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-refractory-metals-and-hard-materials",
    text: "International Journal of Refractory Metals and Hard Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-refrigeration",
    text: "International Journal of Refrigeration",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-remote-sensing",
    text: "International Journal of Remote Sensing",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TRES20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-research-and-method-in-education",
    text: "International Journal of Research &amp; Method in Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CWSE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-research-in-exercise-physiology",
    text: "International Journal of Research in Exercise Physiology",
    parent: "NA",
    urlone: "https://ijrep.org/#about",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-research-in-marketing",
    text: "International Journal of Research in Marketing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-research-in-undergraduate-mathematics-education",
    text: "International Journal of Research in Undergraduate Mathematics Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-retina-and-vitreous",
    text: "International Journal of Retina and Vitreous",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-rock-mechanics-and-mining-sciences",
    text: "International Journal of Rock Mechanics and Mining Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-science-and-mathematics-education",
    text: "International Journal of Science and Mathematics Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-science-education-part-b",
    text: "International Journal of Science Education, Part B",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-science-education",
    text: "International Journal of Science Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TSED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-sediment-research",
    text: "International Journal of Sediment Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-simulation-modelling",
    text: "International Journal of Simulation Modelling",
    parent: "NA",
    urlone: "http://www.ijsimm.com/notes_for_contributors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-social-research-methodology",
    text: "International Journal of Social Research Methodology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TSRM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-social-robotics",
    text: "International Journal of Social Robotics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-solids-and-structures",
    text: "International Journal of Solids and Structures",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-spatial-data-infrastructures-research",
    text: "International Journal of Spatial Data Infrastructures Research",
    parent: "NA",
    urlone: "http://ijsdir.jrc.ec.europa.eu/index.php/ijsdir/pages/view/authors",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-speech-technology",
    text: "International Journal of Speech Technology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-spine-surgery",
    text: "International Journal of Spine Surgery",
    parent: "american-medical-association",
    urlone: "http://ijssurgery.com/content/Author-Instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-sport-and-exercise-psychology",
    text: "International Journal of Sport and Exercise Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIJS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-sports-medicine",
    text: "International Journal of Sport Medicine",
    parent: "NA",
    urlone: "http://www.thieme.com//media/ita/pubid926886473.pdf",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-std-and-aids",
    text: "International Journal of STD &amp; AIDS",
    parent: "vancouver-superscript",
    urlone: "http://ijsa.rsmjournals.com/site/misc/authors.xhtml",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-stem-education",
    text: "International Journal of STEM Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-stomatology-and-occlusion-medicine",
    text: "international journal of stomatology &amp; occlusion medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-stress-management",
    text: "International Journal of Stress Management",
    parent: "apa",
    urlone: "http://content.apa.org/journals/str",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-surgery-case-reports",
    text: "International Journal of Surgery Case Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-surgery-open",
    text: "International Journal of Surgery Open",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-surgery-protocols",
    text: "International Journal of Surgery Protocols",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-surgery",
    text: "International Journal of Surgery",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-sustainable-built-environment",
    text: "International Journal of Sustainable Built Environment",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-sustainable-development-and-world-ecology",
    text: "International Journal of Sustainable Development &amp; World Ecology",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TSDW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-sustainable-energy",
    text: "International Journal of Sustainable Energy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GSOL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-system-assurance-engineering-and-management",
    text: "International Journal of System Assurance Engineering and Management",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-systematic-and-evolutionary-microbiology",
    text: "International Journal of Systematic and Evolutionary Microbiology",
    parent: "microbiology-society",
    urlone: "http://ijs.microbiologyresearch.org/content/journal/ijsem",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-technology-and-design-education",
    text: "International Journal of Technology and Design Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-the-cardiovascular-academy",
    text: "International Journal of the Cardiovascular Academy",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-the-economics-of-business",
    text: "International Journal of the Economics of Business",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CIJB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-theoretical-physics",
    text: "International Journal of Theoretical Physics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-thermal-sciences",
    text: "International Journal of Thermal Sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-thermophysics",
    text: "International Journal of Thermophysics",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-of-transportation-science-and-technology",
    text: "International Journal of Transportation Science and Technology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-turbomachinery-propulsion-and-power",
    text: "International Journal of Turbomachinery, Propulsion and Power",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-urban-and-regional-research",
    text: "International Journal of Urban and Regional Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1468-2427/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-urban-sciences",
    text: "International Journal of Urban Sciences",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJUS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-urban-sustainable-development",
    text: "International Journal of Urban Sustainable Development",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJUE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-veterinary-science-and-medicine",
    text: "International Journal of Veterinary Science and Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-water-resources-development",
    text: "International Journal of Water Resources Development",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CIJW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-wildland-fire",
    text: "International Journal of Wildland Fire",
    parent: "NA",
    urlone: "http://www.publish.csiro.au/nid/117/aid/424.htm",
    urltwo: "NA"
  },
  {
    id: "international-journal-of-womens-dermatology",
    text: "International Journal of Women's Dermatology",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-journal-on-algae",
    text: "International Journal on Algae",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/7dd4467e7de5b7ef.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-on-digital-libraries",
    text: "International Journal on Digital Libraries",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-on-document-analysis-and-recognition",
    text: "International Journal on Document Analysis and Recognition",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-on-innovations-in-online-education",
    text: "International Journal on Innovations in Online Education",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/42d578d554327415.html",
    urltwo: "NA"
  },
  {
    id: "international-journal-on-interactive-design-and-manufacturing",
    text: "International Journal on Interactive Design and Manufacturing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-journal-on-software-tools-for-technology-transfer",
    text: "International Journal on Software Tools for Technology Transfer",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-microbiology",
    text: "International Microbiology",
    parent: "NA",
    urlone: "http://www.springer.com/life+sciences/microbiology/journal/10123",
    urltwo: "NA"
  },
  {
    id: "international-nano-letters",
    text: "International Nano Letters",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-ophthalmology",
    text: "International Ophthalmology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-organization",
    text: "International Organization",
    parent: "NA",
    urlone: "http://assets.cambridge.org/INO/INO_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "international-orthodontics",
    text: "International Orthodontics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-orthopaedics",
    text: "International Orthopaedics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-perspectives-in-psychology-research-practice-consultation",
    text: "International Perspectives in Psychology: Research, Practice, Consultation",
    parent: "apa",
    urlone: "http://content.apa.org/journals/ipp",
    urltwo: "NA"
  },
  {
    id: "international-pig-veterinary-society-congress-proceedings",
    text: "International Pig Veterinary Society Congress Proceedings",
    parent: "NA",
    urlone: "http://vw.theipvs.com/",
    urltwo: "NA"
  },
  {
    id: "international-political-sociology",
    text: "International Political Sociology",
    parent: "international-studies-association",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291749-5687/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-research-in-geographical-and-environmental-education",
    text: "International Research in Geographical and Environmental Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RGEE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-review-of-applied-economics",
    text: "International Review of Applied Economics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CIRA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-review-of-economics-and-finance",
    text: "International Review of Economics and Finance",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-review-of-economics-education",
    text: "International Review of Economics Education",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-review-of-economics",
    text: "International Review of Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-review-of-education",
    text: "International Review of Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-review-of-financial-analysis",
    text: "International Review of Financial Analysis",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-review-of-law-and-economics",
    text: "International Review of Law &amp; Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-review-of-law-computers-and-technology",
    text: "International Review of Law, Computers &amp; Technology",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CIRL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-review-of-sport-and-exercise-psychology",
    text: "International Review of Sport and Exercise Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIRS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-review-of-the-red-cross",
    text: "International Review of the Red Cross",
    parent: "NA",
    urlone: "https://www.icrc.org/en/international-review/contributors",
    urltwo: "NA"
  },
  {
    id: "international-review-on-public-and-nonprofit-marketing",
    text: "International Review on Public and Nonprofit Marketing",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-security",
    text: "International Security",
    parent: "NA",
    urlone: "https://www.belfercenter.org/journal-international-security/overview#!style-guide",
    urltwo: "NA"
  },
  {
    id: "international-soil-and-water-conservation-research",
    text: "International Soil and Water Conservation Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-strategic-management-review",
    text: "International Strategic Management Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "international-studies-association",
    text: "International Studies Association",
    parent: "NA",
    urlone: "http://www.blackwellpublishing.com/pdf/ISAJournals_technical_stylesheets_11apr11.pdf",
    urltwo: "NA"
  },
  {
    id: "international-studies-in-catholic-education",
    text: "International Studies in Catholic Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RICE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-studies-in-sociology-of-education",
    text: "International Studies in Sociology of Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RISS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-studies-in-the-philosophy-of-science",
    text: "International Studies in the Philosophy of Science",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CISP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "international-studies-perspectives",
    text: "International Studies Perspectives",
    parent: "international-studies-association",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291528-3585/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-studies-quarterly",
    text: "International Studies Quarterly",
    parent: "international-studies-association",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291468-2478/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-studies-review",
    text: "International Studies Review",
    parent: "international-studies-association",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291468-2486/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "international-surgery",
    text: "International Surgery",
    parent: "vancouver-superscript",
    urlone: "http://www.internationalsurgery.org/",
    urltwo: "NA"
  },
  {
    id: "international-tax-and-public-finance",
    text: "International Tax and Public Finance",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-transactions-on-systems-science-and-applications",
    text: "International Transactions on Systems Science and Applications",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-union-of-crystallography",
    text: "International Union of Crystallography journals",
    parent: "NA",
    urlone: "http://journals.iucr.org/d/services/notesforauthors.html",
    urltwo: "NA"
  },
  {
    id: "international-urogynecology-journal",
    text: "International Urogynecology Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "international-urology-and-nephrology",
    text: "International Urology and Nephrology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "internet-interventions",
    text: "Internet Interventions",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "internet-of-things",
    text: "Internet of Things",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "interventional-cardiology",
    text: "Interventional Cardiology",
    parent: "future-science-group",
    urlone: "http://www.futuremedicine.com/loi/ica",
    urltwo: "NA"
  },
  {
    id: "interventional-neurology",
    text: "Interventional Neurology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/255379",
    urltwo: "NA"
  },
  {
    id: "intervirology",
    text: "Intervirology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0100",
    urltwo: "NA"
  },
  {
    id: "intravital",
    text: "IntraVital",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "intrinsically-disordered-proteins",
    text: "Intrinsically Disordered Proteins",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "inventaire-general-du-patrimoine-culturel-iso-690-full-note-with-ibid",
    text: "Inventaire gÃ©nÃ©ral du patrimoine culturel - ISO-690 (full note, with Ibid., French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "inventaire-general-du-patrimoine-culturel-iso-690-full-note",
    text: "Inventaire gÃ©nÃ©ral du patrimoine culturel - ISO-690 (full note, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "inventaire-general-du-patrimoine-culturel-iso-690-note",
    text: "Inventaire gÃ©nÃ©ral du patrimoine culturel - ISO-690 (note, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "inventiones-mathematicae",
    text: "Inventiones mathematicae",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "inventions",
    text: "Inventions",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "inverse-problems-in-science-and-engineering",
    text: "Inverse Problems in Science and Engineering",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GIPE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "inverse-problems",
    text: "Inverse Problems",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0266-5611",
    urltwo: "NA"
  },
  {
    id: "invertebrate-biology",
    text: "Invertebrate Biology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291744-7410/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "invertebrate-neuroscience",
    text: "Invertebrate Neuroscience",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "invertebrate-systematics",
    text: "Invertebrate Systematics",
    parent: "emu-austral-ornithology",
    urlone: "http://www.publish.csiro.au/nid/123/aid/428.htm#6",
    urltwo: "NA"
  },
  {
    id: "investigaciones-de-historia-economica",
    text: "Investigaciones de Historia EconÃ³mica",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "investigational-new-drugs",
    text: "Investigational New Drugs",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "investigative-genetics",
    text: "Investigative Genetics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "investigative-ophthalmology-and-visual-science",
    text: "Investigative Ophthalmology &amp; Visual Science",
    parent: "american-medical-association",
    urlone: "http://www.iovs.org/site/misc/author.xhtml",
    urltwo: "NA"
  },
  {
    id: "investigative-radiology",
    text: "Investigative Radiology",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/ir/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "invisu",
    text: "InVisu",
    parent: "NA",
    urlone: "http://invisu.inha.fr/-ZOTERO-",
    urltwo: "NA"
  },
  {
    id: "iok-informationen-aus-orthodontie-and-kieferorthopadie",
    text: "IOK - Informationen aus Orthodontie &amp; KieferorthopÃ¤die",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "ionics",
    text: "Ionics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "iop-conference-series-earth-and-environmental-science",
    text: "IOP Conference Series: Earth and Environmental Science",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1755-1315",
    urltwo: "NA"
  },
  {
    id: "iop-conference-series-materials-science-and-engineering",
    text: "IOP Conference Series: Materials Science and Engineering",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1757-899X",
    urltwo: "NA"
  },
  {
    id: "iop-scinotes",
    text: "IOP SciNotes",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "ios-press-books",
    text: "IOS Press (books)",
    parent: "NA",
    urlone: "http://www.iospress.nl/service/authors/",
    urltwo: "NA"
  },
  {
    id: "ipag-business-school-apa",
    text: "IPAG Business School - APA",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/3937#issuecomment-464334736",
    urltwo: "NA"
  },
  {
    id: "iranian-journal-of-allergy-asthma-and-immunology",
    text: "Iranian Journal of Allergy, Asthma, and Immunology",
    parent: "vancouver",
    urlone: "http://www.ijaai.ir/view/instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "iranian-journal-of-basic-medical-sciences",
    text: "Iranian Journal of Basic Medical Sciences",
    parent: "NA",
    urlone: "http://ijbms.mums.ac.ir/journal/authors.note",
    urltwo: "NA"
  },
  {
    id: "iranian-journal-of-pharmaceutical-research",
    text: "Iranian Journal of Pharmaceutical Research",
    parent: "NA",
    urlone: "http://ijpr.sbmu.ac.ir/journal/authors.note",
    urltwo: "NA"
  },
  {
    id: "iranian-polymer-journal",
    text: "Iranian Polymer Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "irbm-news",
    text: "IRBM News",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "irbm",
    text: "IRBM",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "irish-educational-studies",
    text: "Irish Educational Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIES20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "irish-historical-studies",
    text: "Irish Historical Studies",
    parent: "NA",
    urlone: "http://www.irishhistoricalstudies.ie/rulesforcontribs.pdf",
    urltwo: "NA"
  },
  {
    id: "irish-journal-of-medical-science",
    text: "Irish Journal of Medical Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "irish-journal-of-psychological-medicine",
    text: "Irish Journal of Psychological Medicine",
    parent: "vancouver-superscript",
    urlone: "http://www.ijpm.ie",
    urltwo: "NA"
  },
  {
    id: "irish-studies-review",
    text: "Irish Studies Review",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CISR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "irish-veterinary-journal",
    text: "Irish Veterinary Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "irrigation-science",
    text: "Irrigation Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "isa-transactions",
    text: "ISA Transactions",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "isabella-stewart-gardner-museum",
    text: "Isabella Stewart Gardner Museum",
    parent: "NA",
    urlone: "https://www.gardnermuseum.org/",
    urltwo: "https://github.com/citation-style-language/styles/files/1177458/ISG.Museum.House.Style.pdf"
  },
  {
    id: "iscience",
    text: "iScience",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "islam-and-christian-muslim-relations",
    text: "Islam and Christian-Muslim Relations",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CICM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "islets",
    text: "Islets",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "isnad-dipnotlu",
    text: "Ä°SNAD AtÄ±f Sistemi 2. Edisyon (dipnotlu)",
    parent: "NA",
    urlone: "https://www.isnadsistemi.org",
    urltwo: "https://www.isnadsistemi.org/guide"
  },
  {
    id: "isnad-metinici",
    text: "Ä°SNAD AtÄ±f Sistemi 2. Edisyon (metiniÃ§i)",
    parent: "NA",
    urlone: "https://www.isnadsistemi.org",
    urltwo: "https://www.isnadsistemi.org/guide"
  },
  {
    id: "isnad",
    text: "Ä°SNAD AtÄ±f Sistemi 1. Edisyon (dipnotlu)",
    parent: "NA",
    urlone: "http://www.isnadsistemi.org/",
    urltwo: "http://www.isnadsistemi.org/guide"
  },
  {
    id: "iso690-author-date-cs",
    text: "ISO-690 (author-date, Czech)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/issues/149",
    urltwo: "NA"
  },
  {
    id: "iso690-author-date-de",
    text: "ISO-690 (author-date, German)",
    parent: "NA",
    urlone: "http://www.iso.org/iso/catalogue_detail.htm?csnumber=43320",
    urltwo: "NA"
  },
  {
    id: "iso690-author-date-en",
    text: "ISO-690 (author-date, English)",
    parent: "NA",
    urlone: "http://www.iso.org/iso/catalogue_detail.htm?csnumber=43320",
    urltwo: "NA"
  },
  {
    id: "iso690-author-date-es",
    text: "ISO-690 (author-date, Spanish)",
    parent: "NA",
    urlone: "https://www.aenor.com/normas-y-libros/buscador-de-normas/UNE?c=N0051162",
    urltwo: "http://uc3m.libguides.com/guias_tematicas/citas_bibliograficas/une-iso-690"
  },
  {
    id: "iso690-author-date-fr-no-abstract",
    text: "ISO-690 (author-date, no abstract, French)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/405",
    urltwo: "NA"
  },
  {
    id: "iso690-author-date-fr",
    text: "ISO-690 (author-date, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "iso690-author-date-pt-br",
    text: "ISO-690 (author-date, Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://www.iso.org/iso/catalogue_detail.htm?csnumber=43320",
    urltwo: "NA"
  },
  {
    id: "iso690-author-date-sk",
    text: "ISO-690 (author-date, Slovak)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/846",
    urltwo: "NA"
  },
  {
    id: "iso690-full-note-cs",
    text: "ISO-690 (full note, Czech)",
    parent: "NA",
    urlone: "https://www.citace.com/CSN-ISO-690.pdf",
    urltwo: "NA"
  },
  {
    id: "iso690-full-note-sk",
    text: "ISO-690 (full note, Slovak)",
    parent: "NA",
    urlone: "https://frcth.uniba.sk/fileadmin/rkcmbf/dekanat/legislativa/VP_1_2018_zaverecne_prace_01.pdf",
    urltwo: "NA"
  },
  {
    id: "iso690-full-note-with-ibid-ro",
    text: "ISO-690 (full note, with Ibid., Romanian)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/4465#issuecomment-568176675",
    urltwo: "NA"
  },
  {
    id: "iso690-note-cs",
    text: "ISO-690 (note, without bibliography, Czech)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/594",
    urltwo: "NA"
  },
  {
    id: "iso690-note-fr",
    text: "ISO-690 (note, no abstract, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "iso690-numeric-brackets-cs",
    text: "ISO-690 (numeric, brackets, Czech)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/466",
    urltwo: "NA"
  },
  {
    id: "iso690-numeric-cs",
    text: "ISO-690 (numeric, parentheses, Czech)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/issues/149",
    urltwo: "NA"
  },
  {
    id: "iso690-numeric-en",
    text: "ISO-690 (numeric, English)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "iso690-numeric-fr",
    text: "ISO-690 (numeric, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "iso690-numeric-lt",
    text: "ISO-690 (numeric, Lithuanian)",
    parent: "NA",
    urlone: "http://www.kf.vu.lt/uploads/docs/Studiju%20dokumentai/metodiniai_nurodymai090204.pdf",
    urltwo: "NA"
  },
  {
    id: "iso690-numeric-sk",
    text: "ISO-690 (numeric, Slovak)",
    parent: "NA",
    urlone: "http://vili.uniba.sk/AK/citovanie_priklady.pdf",
    urltwo: "NA"
  },
  {
    id: "isotopes-in-environmental-and-health-studies",
    text: "Isotopes in Environmental and Health Studies",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GIEH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "isprs-international-journal-of-geo-information",
    text: "ISPRS International Journal of Geo-Information",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "isprs-journal-of-photogrammetry-and-remote-sensing",
    text: "ISPRS Journal of Photogrammetry and Remote Sensing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "israel-journal-of-ecology-and-evolution",
    text: "Israel Journal of Ecology &amp; Evolution",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TIEE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "israel-journal-of-health-policy-research",
    text: "Israel Journal of Health Policy Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "israel-journal-of-plant-sciences",
    text: "Israel Journal of Plant Sciences",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TIPS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "israel-medical-association-journal",
    text: "Israel Medical Association Journal",
    parent: "NA",
    urlone: "http://www.ima.org.il/ima/formstorage/type7/instructionsimaj.pdf",
    urltwo: "NA"
  },
  {
    id: "issues-in-environmental-science-and-technology",
    text: "Issues in Environmental Science and Technology",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "istanbul-universitesi-sosyal-bilimler-enstitusu",
    text: "Ä°stanbul Ãœniversitesi Sosyal Bilimler EnstitÃ¼sÃ¼ (Turkish)",
    parent: "NA",
    urlone: "http://cdn.istanbul.edu.tr/FileHandler2.ashx?f=tez.hazirlama.yonergesi.pdf",
    urltwo: "NA"
  },
  {
    id: "it-professional",
    text: "IT Professional",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=6294",
    urltwo: "NA"
  },
  {
    id: "italian-botanist",
    text: "Italian Botanist",
    parent: "pensoft-journals",
    urlone: "https://italianbotanist.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "italian-economic-journal",
    text: "Italian Economic Journal",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "italian-journal-of-agronomy",
    text: "Italian Journal of Agronomy",
    parent: "NA",
    urlone: "http://www.agronomy.it/index.php/agro/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "italian-journal-of-pediatrics",
    text: "Italian Journal of Pediatrics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "italus-hortus",
    text: "Italus Hortus",
    parent: "NA",
    urlone: "http://www.soihs.it/italushortus/manuscript_submission.aspx",
    urltwo: "NA"
  },
  {
    id: "ithaque",
    text: "Ithaque (French - Canada)",
    parent: "NA",
    urlone: "http://www.revueithaque.org/soumissions/normes-de-presentation/",
    urltwo: "NA"
  },
  {
    id: "iubmb-life",
    text: "IUBMB Life",
    parent: "NA",
    urlone: "https://iubmb.onlinelibrary.wiley.com/hub/journal/15216551/author-guidelines.html",
    urltwo: "NA"
  },
  {
    id: "iucrj",
    text: "IUCrJ",
    parent: "international-union-of-crystallography",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ius-ecclesiae",
    text: "Ius Ecclesiae",
    parent: "NA",
    urlone: "http://iusecclesiae.pusc.it",
    urltwo: "NA"
  },
  {
    id: "iza-journal-of-european-labor-studies",
    text: "IZA Journal of European Labor Studies",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "iza-journal-of-labor-and-development",
    text: "IZA Journal of Labor &amp; Development",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "iza-journal-of-labor-economics",
    text: "IZA Journal of Labor Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "iza-journal-of-labor-policy",
    text: "IZA Journal of Labor Policy",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "iza-journal-of-migration",
    text: "IZA Journal of Migration",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "izmir-katip-celebi-universitesi-sosyal-bilimler-enstitusu",
    text: "Ä°zmir Katip Ã‡elebi Ãœniversitesi - Sosyal Bilimler EnstitÃ¼sÃ¼ (Turkish)",
    parent: "NA",
    urlone: "http://sosyalbilimler.ikc.edu.tr/Share/8B70923243C5673C1A7E71C85E2E9DEC",
    urltwo: "NA"
  },
  {
    id: "izvestiya-mathematics",
    text: "Izvestiya: Mathematics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1064-5632",
    urltwo: "NA"
  },
  {
    id: "ja-clinical-reports",
    text: "JA Clinical Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "jaad-case-reports",
    text: "JAAD Case Reports",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "jaapa",
    text: "JAAPA (Journal of the American Academy of Physician Assistants)",
    parent: "american-medical-association",
    urlone: "http://www.jaapa.com/for-authors/section/507/",
    urltwo: "NA"
  },
  {
    id: "jacc-cardiovascular-imaging",
    text: "JACC: Cardiovascular Imaging",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/jacc-cardiovascular-imaging/1936-878X/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "jacc-cardiovascular-interventions",
    text: "JACC: Cardiovascular Interventions",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/jacc-cardiovascular-interventions/1936-8798/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "jahrbuch-der-osterreichischen-byzantinischen-gesellschaft",
    text: "Jahrbuch der Ã–sterreichischen Byzantinischen Gesellschaft",
    parent: "NA",
    urlone: "http://www.byzneo.univie.ac.at/jahrbuch-der-oesterreichischen-byzantinistik/zitierregeln-englisch/",
    urltwo: "NA"
  },
  {
    id: "jahrbuch-fur-evangelikale-theologie",
    text: "Jahrbuch fÃ¼r evangelikale Theologie (German)",
    parent: "NA",
    urlone: "http://www.afet.de/jahrbuch/Richtlinien.pdf",
    urltwo: "NA"
  },
  {
    id: "jahresbericht-der-deutschen-mathematiker-vereinigung",
    text: "Jahresbericht der Deutschen Mathematiker-Vereinigung",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "jak-stat",
    text: "JAK-STAT",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "jama-dermatology",
    text: "JAMA Dermatology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "jama",
    text: "JAMA (The Journal of the American Medical Association)",
    parent: "american-medical-association",
    urlone:
      "http://jama.jamanetwork.com/public/instructionsForAuthors.aspx#ManuscriptPreparationandSubmissionRequirements",
    urltwo: "NA"
  },
  {
    id: "jamia",
    text: "JAMIA",
    parent: "bmj",
    urlone: "http://jamia.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "japan-and-the-world-economy",
    text: "Japan &amp; The World Economy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "japan-journal-of-industrial-and-applied-mathematics",
    text: "Japan Journal of Industrial and Applied Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "japanese-dental-science-review",
    text: "Japanese Dental Science Review",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "japanese-journal-of-applied-physics",
    text: "Japanese Journal of Applied Physics",
    parent: "NA",
    urlone: "https://iopscience.iop.org/journal/1347-4065/page/Author_Guidelines",
    urltwo:
      "https://cms.iopscience.org/fca83bc8-15ad-11e9-9ac9-e38b18a16377/Instructions%20for%20Preparation%20of%20Manuscript.pdf"
  },
  {
    id: "japanese-journal-of-ophthalmology",
    text: "Japanese Journal of Ophthalmology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "japanese-journal-of-radiology",
    text: "Japanese Journal of Radiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "javnost-the-public",
    text: "Javnost - The Public",
    parent: "NA",
    urlone: "http://www.javnost-thepublic.org/notes-for-authors/",
    urltwo: "NA"
  },
  {
    id: "jbic-journal-of-biological-inorganic-chemistry",
    text: "JBIC Journal of Biological Inorganic Chemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "jcc-open",
    text: "JCC Open",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "jeugdbeleid",
    text: "Jeugdbeleid (Dutch)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "jgz-tijdschrift-voor-jeugdgezondheidszorg",
    text: "JGZ Tijdschrift voor jeugdgezondheidszorg (Dutch)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "jhep-reports",
    text: "JHEP Reports",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "jmm-case-reports",
    text: "JMM Case Reports",
    parent: "microbiology-society",
    urlone: "http://jmmcr.sgmjournals.org/",
    urltwo: "NA"
  },
  {
    id: "jmv-journal-de-medecine-vasculaire",
    text: "JMV-Journal de MÃ©decine Vasculaire",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "john-benjamins-publishing-company-iconicity-in-language-and-literature",
    text: "John Benjamins Publishing Company - Iconicity in Language and Literature",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/files/2571238/BenjaminsStylesheet.pdf",
    urltwo: "https://benjamins.com/catalog/ill"
  },
  {
    id: "john-benjamins-publishing-company-linguistik-aktuell-linguistics-today",
    text: "John Benjamins Publishing Company - Linguistik Aktuell/Linguistics Today",
    parent: "NA",
    urlone: "https://benjamins.com/catalog/la",
    urltwo: "NA"
  },
  {
    id: "johnson-matthey-technology-review",
    text: "Johnson Matthey Technology Review",
    parent: "NA",
    urlone: "http://www.technology.matthey.com/for-authors/#references",
    urltwo: "NA"
  },
  {
    id: "joint-bone-spine",
    text: "Joint Bone Spine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "jom",
    text: "JOM",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "jornal-de-pediatria",
    text: "Jornal de Pediatria",
    parent: "vancouver-superscript",
    urlone: "http://www.scielo.br/revistas/jped/iinstruc.htm",
    urltwo: "NA"
  },
  {
    id: "joule",
    text: "Joule",
    parent: "cell-numeric-superscript",
    urlone: "http://www.cell.com/joule/authors",
    urltwo: "NA"
  },
  {
    id: "journal-and-proceedings-of-the-royal-society-of-new-south-wales",
    text: "Journal and Proceedings of the Royal Society of New South Wales",
    parent: "NA",
    urlone: "https://royalsoc.org.au/society-publications/information-for-authors",
    urltwo: "https://royalsoc.org.au/images/pdf/journal/RSNSW.Styleguide.pdf"
  },
  {
    id: "journal-club-ains",
    text: "Journal Club AINS",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "journal-de-chirurgie-viscerale",
    text: "Journal de Chirurgie ViscÃ©rale",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-de-gynecologie-obstetrique-et-biologie-de-la-reproduction",
    text: "Journal de GynÃ©cologie ObstÃ©trique et Biologie de la Reproduction",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-de-la-societe-des-americanistes",
    text: "Journal de la SociÃ©tÃ© des amÃ©ricanistes",
    parent: "NA",
    urlone: "https://journals.openedition.org/jsa/7663",
    urltwo: "NA"
  },
  {
    id: "journal-de-mathematiques-pures-et-appliquees",
    text: "Journal de mathÃ©matiques pures et appliquÃ©es",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-de-mycologie-medicale",
    text: "Journal de Mycologie MÃ©dicale",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-de-pediatrie-et-de-puericulture",
    text: "Journal de pÃ©diatrie et de puÃ©riculture",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-de-radiologie-diagnostique-et-interventionnelle",
    text: "Journal de Radiologie diagnostique et interventionnelle",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-de-readaptation-medicale",
    text: "Journal de rÃ©adaptation mÃ©dicale",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-de-therapie-comportementale-et-cognitive",
    text: "Journal de ThÃ©rapie Comportementale et Cognitive",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-de-traumatologie-du-sport",
    text: "Journal de Traumatologie du Sport",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-des-anti-infectieux",
    text: "Journal des Anti-infectieux",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-des-maladies-vasculaires",
    text: "Journal des Maladies Vasculaires",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-dimagerie-diagnostique-et-interventionnelle",
    text: "Journal d'imagerie diagnostique et interventionnelle",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-europeen-des-urgences-et-de-reanimation",
    text: "Journal EuropÃ©en des Urgences et de RÃ©animation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-for-general-philosophy-of-science",
    text: "Journal for General Philosophy of Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-for-immunotherapy-of-cancer",
    text: "Journal for ImmunoTherapy of Cancer",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-for-labour-market-research",
    text: "Journal for Labour Market Research",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-for-nature-conservation",
    text: "Journal for Nature Conservation",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-for-the-history-of-astronomy",
    text: "Journal for the History of Astronomy",
    parent: "NA",
    urlone: "http://www.shpltd.co.uk/style.html",
    urltwo: "NA"
  },
  {
    id: "journal-for-veterinary-medicine-biotechnology-and-biosafety",
    text: "Journal for Veterinary Medicine, Biotechnology and Biosafety",
    parent: "NA",
    urlone: "http://jvmbbs.kharkov.ua/rules.php",
    urltwo: "NA"
  },
  {
    id: "journal-francais-dophtalmologie",
    text: "Journal FranÃ§ais d'Ophtalmologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-fur-asthetische-chirurgie",
    text: "Journal fÃ¼r Ã„sthetische Chirurgie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/surgery/journal/12631?detailsPage=pltci_1750803",
    urltwo: "NA"
  },
  {
    id: "journal-fur-kunstgeschichte",
    text: "Journal fÃ¼r Kunstgeschichte",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/2043",
    urltwo: "NA"
  },
  {
    id: "journal-fur-mathematik-didaktik",
    text: "Journal fÃ¼r Mathematik-Didaktik (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-fur-verbraucherschutz-und-lebensmittelsicherheit",
    text: "Journal fÃ¼r Verbraucherschutz und Lebensmittelsicherheit (German)",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-3d-printing-in-medicine",
    text: "Journal of 3D Printing in Medicine",
    parent: "future-medicine",
    urlone: "https://www.futuremedicine.com/journal/3dp",
    urltwo: "NA"
  },
  {
    id: "journal-of-abnormal-child-psychology",
    text: "Journal of Abnormal Child Psychology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-abnormal-psychology",
    text: "Journal of Abnormal Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/abn",
    urltwo: "NA"
  },
  {
    id: "journal-of-academic-ethics",
    text: "Journal of Academic Ethics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-accounting-and-economics",
    text: "Journal of Accounting and Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-accounting-and-public-policy",
    text: "Journal of Accounting and Public Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-accounting-education",
    text: "Journal of Accounting Education",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-accounting-literature",
    text: "Journal of Accounting Literature",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-acoustics",
    text: "Journal of Acoustics",
    parent: "NA",
    urlone: "https://joa.hapres.com/JOA_GuideforAuthors.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-acute-disease",
    text: "Journal of Acute Disease",
    parent: "hainan-medical-university-journal-publisher",
    urlone: "http://jadweb.org/zy/f3.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-acute-medicine",
    text: "Journal of Acute Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-adhesion-science-and-technology",
    text: "Journal of Adhesion Science and Technology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TAST20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-adolescence",
    text: "Journal of Adolescence",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-adolescent-health",
    text: "Journal of Adolescent Health",
    parent: "NA",
    urlone: "http://www.jahonline.org/authorinfo#idp1329360",
    urltwo: "NA"
  },
  {
    id: "journal-of-adult-development",
    text: "Journal of Adult Development",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-advanced-research",
    text: "Journal of Advanced Research",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-advances-in-modeling-earth-systems",
    text: "Journal of Advances in Modeling Earth Systems",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "journal-of-adventure-education-and-outdoor-learning",
    text: "Journal of Adventure Education &amp; Outdoor Learning",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAOL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-advertising-research",
    text: "Journal of Advertising Research",
    parent: "NA",
    urlone: "http://www.journalofadvertisingresearch.com/content/submit-manuscript",
    urltwo: "http://www.journalofadvertisingresearch.com/sites/default/files/Additional_assets/JAR%20Guidelines.pdf"
  },
  {
    id: "journal-of-aerosol-medicine-and-pulmonary-drug-delivery",
    text: "Journal of Aerosol Medicine and Pulmonary Drug Delivery",
    parent: "NA",
    urlone: "http://www.liebertpub.com/manuscript/journal-of-aerosol-medicine-brand-pulmonary-drug-delivery/24/",
    urltwo: "NA"
  },
  {
    id: "journal-of-aerosol-science",
    text: "Journal of Aerosol Science",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-aerospace-engineering",
    text: "Journal of Aerospace Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jaeeez",
    urltwo: "NA"
  },
  {
    id: "journal-of-aerospace-information-systems",
    text: "Journal of Aerospace Information Systems",
    parent: "american-institute-of-aeronautics-and-astronautics",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-affective-disorders",
    text: "Journal of Affective Disorders",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-african-american-studies",
    text: "Journal of African American Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-african-cultural-studies",
    text: "Journal of African Cultural Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJAC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-african-earth-sciences",
    text: "Journal of African Earth Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-african-trade",
    text: "Journal of African Trade",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-aging-studies",
    text: "Journal of Aging Studies",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-agricultural-and-applied-economics",
    text: "Journal of Agricultural and Applied Economics",
    parent: "NA",
    urlone:
      "https://www.cambridge.org/core/journals/journal-of-agricultural-and-applied-economics/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "journal-of-agricultural-and-environmental-ethics",
    text: "Journal of Agricultural and Environmental Ethics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-agricultural-and-food-chemistry",
    text: "Journal of Agricultural and Food Chemistry",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/page/jafcau/submission/reference-guidelines.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-agricultural-and-resource-economics",
    text: "Journal of Agricultural and Resource Economics",
    parent: "NA",
    urlone: "http://www.waeaonline.org/publications/jare/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-agricultural-safety-and-health",
    text: "Journal of Agricultural Safety and Health",
    parent: "american-society-of-agricultural-and-biological-engineers",
    urlone: "https://doi.org/10.13031/issn.1074-7583",
    urltwo: "NA"
  },
  {
    id: "journal-of-air-transport-management",
    text: "Journal of Air Transport Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-aircraft",
    text: "Journal of Aircraft",
    parent: "american-institute-of-aeronautics-and-astronautics",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-algebra",
    text: "Journal of Algebra",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-algebraic-combinatorics",
    text: "Journal of Algebraic Combinatorics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-alloys-and-compounds",
    text: "Journal of Alloys and Compounds",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-alzheimers-disease",
    text: "Journal of Alzheimer's Disease",
    parent: "NA",
    urlone: "https://www.j-alz.com/prep",
    urltwo: "NA"
  },
  {
    id: "journal-of-ambient-intelligence-and-humanized-computing",
    text: "Journal of Ambient Intelligence and Humanized Computing",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-american-association-for-pediatric-ophthalmology-and-strabismus",
    text: "Journal of American Association for Pediatric Ophthalmology and Strabismus",
    parent: "vancouver-superscript",
    urlone: "http://www.ophsource.org/periodicals/ympa/authorinfo#org",
    urltwo: "NA"
  },
  {
    id: "journal-of-analytical-and-applied-pyrolysis",
    text: "Journal of Analytical and Applied Pyrolysis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-analytical-atomic-spectrometry",
    text: "Journal of Analytical Atomic Spectrometry",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-analytical-science-and-technology",
    text: "Journal of Analytical Science and Technology",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-analytical-toxicology",
    text: "Journal of Analytical Toxicology",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/jat/for_authors/",
    urltwo: "NA"
  },
  {
    id: "journal-of-anesthesia-history",
    text: "Journal of Anesthesia History",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-anesthesia",
    text: "Journal of Anesthesia",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-animal-ecology",
    text: "Journal of Animal Ecology",
    parent: "apa-old-doi-prefix",
    urlone: "http://besjournals.onlinelibrary.wiley.com/hub/journal/10.1111/(ISSN)1365-2656/author-guidelines.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-animal-physiology-and-animal-nutrition",
    text: "Journal of Animal Physiology and Animal Nutrition",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1439-0396/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-animal-science-and-biotechnology",
    text: "Journal of Animal Science and Biotechnology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-animal-science-and-technology",
    text: "Journal of Animal Science and Technology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-animal-science",
    text: "Journal of Animal Science",
    parent: "NA",
    urlone: "https://www.animalsciencepublications.org/publications/jas/infora",
    urltwo: "NA"
  },
  {
    id: "journal-of-anthropological-archaeology",
    text: "Journal of Anthropological Archaeology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-antimicrobial-chemotherapy",
    text: "Journal of Antimicrobial Chemotherapy",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/jac/for_authors/#Journal%20style",
    urltwo: "NA"
  },
  {
    id: "journal-of-anxiety-disorders",
    text: "Journal of Anxiety Disorders",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-aoac-international",
    text: "Journal of AOAC International",
    parent: "NA",
    urlone: "http://www.aoac.org/aoac_prod_imis/AOAC/AOAC_Member/PUBSCF/JAOACCF/JN_M.aspx",
    urltwo: "http://www.aoac.org/aoac_prod_imis/AOAC_Docs/Journal/Author_Resources.pdf"
  },
  {
    id: "journal-of-applied-animal-research",
    text: "Journal of Applied Animal Research",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TAAR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-animal-science",
    text: "Journal of Applied Animal Science",
    parent: "NA",
    urlone: "http://www.vs.mahidol.ac.th/jaas",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-biomedicine",
    text: "Journal of Applied Biomedicine",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-clinical-medical-physics",
    text: "Journal of Applied Clinical Medical Physics",
    parent: "NA",
    urlone: "http://www.jacmp.org/index.php/jacmp/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-crystallography",
    text: "Journal of Applied Crystallography",
    parent: "international-union-of-crystallography",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-developmental-psychology",
    text: "Journal of Applied Developmental Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-ecology",
    text: "Journal of Applied Ecology",
    parent: "apa-old-doi-prefix",
    urlone:
      "http://besjournals.onlinelibrary.wiley.com/hub/journal/10.1111/(ISSN)1365-2664/about/author-guidelines.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-electrochemistry",
    text: "Journal of Applied Electrochemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-applied-entomology",
    text: "Journal of Applied Entomology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1439-0418/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-genetics",
    text: "Journal of Applied Genetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-applied-geophysics",
    text: "Journal of Applied Geophysics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-logic",
    text: "Journal of Applied Logic",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-mathematics-and-computing",
    text: "Journal of Applied Mathematics and Computing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-applied-mechanics",
    text: "Journal of Applied Mechanics",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://appliedmechanics.asmedigitalcollection.asme.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-meteorology-and-climatology",
    text: "Journal of Applied Meteorology and Climatology",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/apme",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-non-classical-logics",
    text: "Journal of Applied Non-Classical Logics",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TNCL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-pharmaceutical-research",
    text: "Journal of Applied Pharmaceutical Research",
    parent: "NA",
    urlone: "http://www.japtronline.com/index.php/JOAPR/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-philosophy",
    text: "Journal of Applied Philosophy",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291468-5930/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-phycology",
    text: "Journal of Applied Phycology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-applied-physics",
    text: "Journal of Applied Physics",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-physiology",
    text: "Journal of Applied Physiology",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-polymer-science",
    text: "Journal of Applied Polymer Science",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-4628/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-psychology",
    text: "Journal of Applied Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/apl",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-remote-sensing",
    text: "Journal of Applied Remote Sensing",
    parent: "spie-journals",
    urlone: "http://remotesensing.spiedigitallibrary.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-research-and-technology",
    text: "Journal of Applied Research and Technology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-research-in-memory-and-cognition",
    text: "Journal of Applied Research in Memory and Cognition",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-research-on-medicinal-and-aromatic-plants",
    text: "Journal of Applied Research on Medicinal and Aromatic Plants",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-applied-volcanology",
    text: "Journal of Applied Volcanology",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-applied-water-engineering-and-research",
    text: "Journal of Applied Water Engineering and Research",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJAW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-aquatic-animal-health",
    text: "Journal of Aquatic Animal Health",
    parent: "american-fisheries-society",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=uahh20&amp;page=instructions#.UpVAC0Mju1E",
    urltwo: "NA"
  },
  {
    id: "journal-of-archaeological-method-and-theory",
    text: "Journal of Archaeological Method and Theory",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-archaeological-research",
    text: "Journal of Archaeological Research",
    parent: "NA",
    urlone: "http://www.springer.com/social+sciences/anthropology+%26+archaeology/journal/10814",
    urltwo: "NA"
  },
  {
    id: "journal-of-archaeological-science-reports",
    text: "Journal of Archaeological Science: Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-archaeological-science",
    text: "Journal of Archaeological Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-architectural-engineering",
    text: "Journal of Architectural Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jaeied",
    urltwo: "NA"
  },
  {
    id: "journal-of-arid-environments",
    text: "Journal of Arid Environments",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-arrhythmia",
    text: "Journal of Arrhythmia",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-arthroscopy-and-joint-surgery",
    text: "Journal of Arthroscopy and Joint Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-artificial-organs",
    text: "Journal of Artificial Organs",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-asia-pacific-entomology",
    text: "Journal of Asia-Pacific Entomology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-asian-earth-sciences-x",
    text: "Journal of Asian Earth Sciences: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-asian-earth-sciences",
    text: "Journal of Asian Earth Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-asian-economics",
    text: "Journal of Asian Economics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-asian-natural-products-research",
    text: "Journal of Asian Natural Products Research",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GANP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-assisted-reproduction-and-genetics",
    text: "Journal of Assisted Reproduction and Genetics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-astronomical-telescopes-instruments-and-systems",
    text: "Journal of Astronomical Telescopes, Instruments, and Systems",
    parent: "spie-journals",
    urlone: "http://astronomicaltelescopes.spiedigitallibrary.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-atmospheric-and-oceanic-technology",
    text: "Journal of Atmospheric and Oceanic Technology",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/atot",
    urltwo: "NA"
  },
  {
    id: "journal-of-atmospheric-and-solar-terrestrial-physics",
    text: "Journal of Atmospheric and Solar-Terrestrial Physics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-atmospheric-chemistry",
    text: "Journal of Atmospheric Chemistry",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-atrial-fibrillation",
    text: "Journal of Atrial Fibrillation",
    parent: "NA",
    urlone: "http://www.jafib.com/index.php?cont=author_submit",
    urltwo: "NA"
  },
  {
    id: "journal-of-australian-strength-and-conditioning",
    text: "Journal of Australian Strength &amp; Conditioning",
    parent: "NA",
    urlone: "https://www.strengthandconditioning.org/images/publications/jasc-author-guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-autism-and-developmental-disorders",
    text: "Journal of Autism and Developmental Disorders",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-autoimmunity",
    text: "Journal of Autoimmunity",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-automated-reasoning",
    text: "Journal of Automated Reasoning",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-automation-and-information-sciences",
    text: "Journal of Automation and Information Sciences",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/2b6239406278e43e.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-avian-biology",
    text: "Journal of Avian Biology",
    parent: "NA",
    urlone: "http://www.avianbiology.org/authors/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-ayurveda-and-integrative-medicine",
    text: "Journal of Ayurveda and Integrative Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-bacteriology",
    text: "Journal of Bacteriology",
    parent: "american-society-for-microbiology",
    urlone: "http://jb.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "journal-of-baltic-studies",
    text: "Journal of Baltic Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RBAL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-banking-and-finance-law-and-practice",
    text: "Journal of Banking and Finance Law and Practice",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-banking-and-finance",
    text: "Journal of Banking and Finance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-basic-microbiology",
    text: "Journal of Basic Microbiology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291521-4028/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-behavior-health-and-social-issues",
    text: "Journal of Behavior, Health &amp; Social Issues",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-behavior-therapy-and-experimental-psychiatry",
    text: "Journal of Behavior Therapy and Experimental Psychiatry",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-behavioral-and-experimental-economics",
    text: "Journal of Behavioral and Experimental Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-behavioral-and-experimental-finance",
    text: "Journal of Behavioral and Experimental Finance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-behavioral-education",
    text: "Journal of Behavioral Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-behavioral-medicine",
    text: "Journal of Behavioral Medicine",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-beliefs-and-values",
    text: "Journal of Beliefs &amp; Values",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJBV20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-big-data",
    text: "Journal of Big Data",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-bio-and-tribo-corrosion",
    text: "Journal of Bio- and Tribo-Corrosion",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-bioeconomics",
    text: "Journal of Bioeconomics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-bioenergetics-and-biomembranes",
    text: "Journal of Bioenergetics and Biomembranes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-biogeography",
    text: "Journal of Biogeography",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2699/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-biological-chemistry",
    text: "The Journal of Biological Chemistry",
    parent: "NA",
    urlone: "http://www.jbc.org/site/misc/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "journal-of-biological-education",
    text: "Journal of Biological Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJBE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-biological-engineering",
    text: "Journal of Biological Engineering",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-biological-physics",
    text: "Journal of Biological Physics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-biological-regulators-and-homeostatic-agents",
    text: "Journal of Biological Regulators &amp; Homeostatic Agents",
    parent: "NA",
    urlone: "http://www.biolifesas.org/JBRHA.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-biological-research-thessaloniki",
    text: "Journal of Biological Research-Thessaloniki",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-biomaterials-science-polymer-edition",
    text: "Journal of Biomaterials Science, Polymer Edition",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TBSP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-biomechanical-engineering",
    text: "Journal of Biomechanical Engineering",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://biomechanical.asmedigitalcollection.asme.org/journal.aspx?journalid=114",
    urltwo: "NA"
  },
  {
    id: "journal-of-biomechanics",
    text: "Journal of Biomechanics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-biomedical-and-therapeutic-sciences",
    text: "Journal of Biomedical and Therapeutic Sciences",
    parent: "integrated-science-publishing-journals",
    urlone: "http://www.pubs.iscience.in/journal/index.php/jbts/about/submissions",
    urltwo: "NA"
  },
  {
    id: "journal-of-biomedical-informatics-x",
    text: "Journal of Biomedical Informatics: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-biomedical-informatics",
    text: "Journal of Biomedical Informatics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-biomedical-materials-research-part-a",
    text: "Journal of Biomedical Materials Research Part A",
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1552-4965/homepage/ForAuthors.html"
  },
  {
    id: "journal-of-biomedical-optics",
    text: "Journal of Biomedical Optics",
    parent: "spie-journals",
    urlone: "http://biomedicaloptics.spiedigitallibrary.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-biomedical-science",
    text: "Journal of Biomedical Science",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-biomedical-semantics",
    text: "Journal of Biomedical Semantics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-biomolecular-nmr",
    text: "Journal of Biomolecular NMR",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-biomolecular-screening",
    text: "Journal of Biomolecular Screening",
    parent: "society-for-laboratory-automation-and-screening",
    urlone: "http://www.slas.org/publications/scientific-journals/author-instructions/",
    urltwo: "NA"
  },
  {
    id: "journal-of-biomolecular-structure-and-dynamics",
    text: "Journal of Biomolecular Structure and Dynamics",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TBSD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-biosafety-and-biosecurity",
    text: "Journal of Biosafety and Biosecurity",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-biosciences",
    text: "Journal of Biosciences",
    parent: "NA",
    urlone: "https://www.springer.com/life+sciences/journal/12038",
    urltwo:
      "http://www.springer.com/cda/content/document/cda_downloaddocument/JB-instruction+for+authors.pdf?SGWID=0-0-45-412498-p173709705"
  },
  {
    id: "journal-of-biotechnology-x",
    text: "Journal of Biotechnology: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-biotechnology",
    text: "Journal of Biotechnology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-bodywork-and-movement-therapies",
    text: "Journal of Bodywork &amp; Movement Therapies",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-bone-and-mineral-metabolism",
    text: "Journal of Bone and Mineral Metabolism",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-bone-and-mineral-research",
    text: "Journal of Bone and Mineral Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1523-4681/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-bone-oncology",
    text: "Journal of Bone Oncology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-brachial-plexus-and-peripheral-nerve-injury",
    text: "Journal of Brachial Plexus and Peripheral Nerve Injury",
    parent: "NA",
    urlone: "http://www.thieme.com/media/ita/JBPPNI_AuthorsInstructions.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-breast-cancer",
    text: "Journal of Breast Cancer",
    parent: "NA",
    urlone: "https://ejbc.kr/index.php?body=instruction",
    urltwo: "NA"
  },
  {
    id: "journal-of-breath-research",
    text: "Journal of Breath Research",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1752-7163",
    urltwo: "NA"
  },
  {
    id: "journal-of-bridge-engineering",
    text: "Journal of Bridge Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jbenf2",
    urltwo: "NA"
  },
  {
    id: "journal-of-building-engineering",
    text: "Journal of Building Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-building-pathology-and-rehabilitation",
    text: "Journal of Building Pathology and Rehabilitation",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-building-performance-simulation",
    text: "Journal of Building Performance Simulation",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TBPS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-burn-care-and-research",
    text: "Journal of Burn Care &amp; Research",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/jbcr/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-business-and-economic-statistics",
    text: "Journal of Business &amp; Economic Statistics",
    parent: "american-statistical-association",
    urlone: "http://amstat.tfjournals.com/asa-style-guide/",
    urltwo: "NA"
  },
  {
    id: "journal-of-business-and-psychology",
    text: "Journal of Business and Psychology",
    parent: "apa",
    urlone: "https://www.springer.com/psychology/journal/10869",
    urltwo: "NA"
  },
  {
    id: "journal-of-business-economics",
    text: "Journal of Business Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-business-ethics",
    text: "Journal of Business Ethics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-business-logistics",
    text: "Journal of Business Logistics",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)2158-1592/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-business-research",
    text: "Journal of Business Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-business-venturing-insights",
    text: "Journal of Business Venturing Insights",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-business-venturing",
    text: "Journal of Business Venturing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cachexia-sarcopenia-and-muscle",
    text: "Journal of Cachexia, Sarcopenia and Muscle",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1007/13539.2190-6009/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-cancer-education",
    text: "Journal of Cancer Education",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-cancer-policy",
    text: "Journal of Cancer Policy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cancer-research-and-clinical-oncology",
    text: "Journal of Cancer Research and Clinical Oncology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-cancer-research-and-practice",
    text: "Journal of Cancer Research and Practice",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cancer-research-and-therapeutics",
    text: "Journal of Cancer Research and Therapeutics",
    parent: "vancouver-brackets",
    urlone: "http://www.cancerjournal.net/contributors.asp#preparation",
    urltwo: "NA"
  },
  {
    id: "journal-of-cancer-survivorship",
    text: "Journal of Cancer Survivorship",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-cardiology-cases",
    text: "Journal of Cardiology Cases",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cardiology",
    text: "Journal of Cardiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cardiothoracic-and-vascular-anesthesia",
    text: "Journal of Cardiothoracic and Vascular Anesthesia",
    parent: "NA",
    urlone: "http://www.jcardioanesthesia.com/authorinfo",
    urltwo: "NA"
  },
  {
    id: "journal-of-cardiothoracic-surgery",
    text: "Journal of Cardiothoracic Surgery",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-cardiovascular-computed-tomography",
    text: "Journal of Cardiovascular Computed Tomography",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cardiovascular-development-and-disease",
    text: "Journal of Cardiovascular Development and Disease",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-cardiovascular-disease-research",
    text: "Journal of Cardiovascular Disease Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cardiovascular-magnetic-resonance",
    text: "Journal of Cardiovascular Magnetic Resonance",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-cardiovascular-pharmacology-and-therapeutics",
    text: "Journal of Cardiovascular Pharmacology &amp; Therapeutics",
    parent: "american-medical-association",
    urlone:
      "http://www.uk.sagepub.com/journalsProdDesc.nav?prodId=Journal201790&amp;crossRegion=eur#tabview=manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "journal-of-cardiovascular-translational-research",
    text: "Journal of Cardiovascular Translational Research",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-catalysis",
    text: "Journal of Catalysis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cataract-and-refractive-surgery",
    text: "Journal of Cataract &amp; Refractive Surgery",
    parent: "american-medical-association",
    urlone: "http://jcrsjournal.org/authorinfo",
    urltwo: "NA"
  },
  {
    id: "journal-of-cell-communication-and-signaling",
    text: "Journal of Cell Communication and Signaling",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-cell-science",
    text: "Journal of Cell Science",
    parent: "the-company-of-biologists",
    urlone: "http://jcs.biologists.org/site/author/manuscript_preparation.xhtml#refs",
    urltwo: "NA"
  },
  {
    id: "journal-of-cellular-and-molecular-medicine",
    text: "Journal of Cellular and Molecular Medicine",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1582-4934/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-cellular-biochemistry",
    text: "Journal of Cellular Biochemistry",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-4644",
    urltwo: "NA"
  },
  {
    id: "journal-of-cellular-immunotherapy",
    text: "Journal of Cellular Immunotherapy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cereal-science",
    text: "Journal of Cereal Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cerebral-blood-flow-and-metabolism",
    text: "Journal of Cerebral Blood Flow &amp; Metabolism",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mc.manuscriptcentral.com/societyimages/jcbfm/JCBFM%20Guide%20to%20Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-change-management",
    text: "Journal of Change Management",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJCM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-chemical-and-engineering-data",
    text: "Journal of Chemical &amp; Engineering Data",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jceaax/jceaax_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-chemical-biology",
    text: "Journal of Chemical Biology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-chemical-crystallography",
    text: "Journal of Chemical Crystallography",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-chemical-ecology",
    text: "Journal of Chemical Ecology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-chemical-education",
    text: "Journal of Chemical Education",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jceda8/jceda8_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-chemical-information-and-modeling",
    text: "Journal of Chemical Information and Modeling",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jcisd8/jcisd8_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-chemical-neuroanatomy",
    text: "Journal of Chemical Neuroanatomy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-chemical-theory-and-computation",
    text: "Journal of Chemical Theory and Computation",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jctcce/jctcce_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-cheminformatics",
    text: "Journal of Cheminformatics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-chemistry-and-chemical-engineering",
    text: "Journal of Chemistry and Chemical Engineering",
    parent: "NA",
    urlone: "http://www.davidpublishing.com/davidpublishing/Upfile/8/19/2012/2012081969041905.pdf",
    urltwo: "http://www.davidpublishing.com/journals_info.asp?jId=1047"
  },
  {
    id: "journal-of-chemometrics",
    text: "Journal of Chemometrics",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1099-128X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-chemotherapy",
    text: "Journal of Chemotherapy",
    parent: "vancouver-superscript",
    urlone: "http://www.ingentaconnect.com/content/maney/joc",
    urltwo: "NA"
  },
  {
    id: "journal-of-child-and-adolescent-psychopharmacology",
    text: "Journal of Child and Adolescent Psychopharmacology",
    parent: "NA",
    urlone: "http://www.liebertpub.com/manuscript/cap",
    urltwo: "NA"
  },
  {
    id: "journal-of-child-and-adolescent-trauma",
    text: "Journal of Child &amp; Adolescent Trauma",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-child-and-family-studies",
    text: "Journal of Child and Family Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-children-and-media",
    text: "Journal of Children and Media",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCHM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-children-and-poverty",
    text: "Journal of Children and Poverty",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJCP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-childrens-orthopaedics",
    text: "Journal of Children's Orthopaedics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-chinese-economic-and-business-studies",
    text: "Journal of Chinese Economic and Business Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCEA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-chinese-management",
    text: "Journal of Chinese Management",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-chinese-political-science",
    text: "Journal of Chinese Political Science",
    parent: "springer-humanities-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-chinese-studies",
    text: "Journal of Chinese Studies",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-chiropractic-humanities",
    text: "Journal of Chiropractic Humanities",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-chiropractic-medicine",
    text: "Journal of Chiropractic Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-choice-modelling",
    text: "Journal of Choice Modelling",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-chromatography-a",
    text: "Journal of Chromatography A",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-chromatography-b",
    text: "Journal of Chromatography B",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-civil-litigation-and-practice",
    text: "Journal of Civil Litigation and Practice",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-civil-structural-health-monitoring",
    text: "Journal of Civil Structural Health Monitoring",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-cleaner-production",
    text: "Journal of Cleaner Production",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-climate",
    text: "Journal of Climate",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/clim",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-and-experimental-hepatology",
    text: "Journal of Clinical and Experimental Hepatology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-and-experimental-neuropsychology",
    text: "Journal of Clinical and Experimental Neuropsychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NCEN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-and-translational-endocrinology-case-reports",
    text: "Journal of Clinical and Translational Endocrinology: Case Reports",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-and-translational-endocrinology",
    text: "Journal of Clinical &amp; Translational Endocrinology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-anesthesia",
    text: "Journal of Clinical Anesthesia",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-epidemiology",
    text: "Journal of Clinical Epidemiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-gastroenterology",
    text: "Journal of Clinical Gastroenterology",
    parent: "vancouver-superscript",
    urlone: "http://journals.lww.com/jcge/Pages/InstructionsforAuthors.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-immunology",
    text: "Journal of Clinical Immunology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-clinical-lipidology",
    text: "Journal of Clinical Lipidology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-medicine",
    text: "Journal of Clinical Medicine",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-microbiology",
    text: "Journal of Clinical Microbiology",
    parent: "american-society-for-microbiology",
    urlone: "http://jcm.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-monitoring-and-computing",
    text: "Journal of Clinical Monitoring and Computing",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-clinical-movement-disorders",
    text: "Journal of Clinical Movement Disorders",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-clinical-neurology",
    text: "Journal of Clinical Neurology",
    parent: "NA",
    urlone: "http://submit.thejcn.com/about/author_ins.php",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-neurophysiology",
    text: "Journal of Clinical Neurophysiology",
    parent: "NA",
    urlone: "http://journals.lww.com/clinicalneurophys/_layouts/15/1033/oaks.journals/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-neuroscience",
    text: "Journal of Clinical Neuroscience",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-oncology",
    text: "Journal of Clinical Oncology",
    parent: "NA",
    urlone: "http://jco.ascopubs.org/site/ifc/prepguide.xhtml#Reference_Instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-orthopaedics-and-trauma",
    text: "Journal of Clinical Orthopaedics and Trauma",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-pathology",
    text: "Journal of Clinical Pathology",
    parent: "bmj",
    urlone: "http://jcp.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-psychology-in-medical-settings",
    text: "Journal of Clinical Psychology in Medical Settings",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-clinical-rheumatology",
    text: "Journal of Clinical Rheumatology",
    parent: "NA",
    urlone: "http://journals.lww.com/jclinrheum/_layouts/15/1033/oaks.journals/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-sleep-medicine",
    text: "Journal of Clinical Sleep Medicine",
    parent: "NA",
    urlone: "http://www.aasmnet.org/jcsm/authors.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-tuberculosis-and-other-mycobacterial-diseases",
    text: "Journal of Clinical Tuberculosis and Other Mycobacterial Diseases",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-clinical-virology",
    text: "Journal of Clinical Virology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cloud-computing",
    text: "Journal of Cloud Computing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-co-operative-organization-and-management",
    text: "Journal of Co-operative Organization and Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-co2-utilization",
    text: "Journal of CO2 Utilization",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-coastal-conservation",
    text: "Journal of Coastal Conservation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-coastal-life-medicine",
    text: "Journal of Coastal Life Medicine",
    parent: "hainan-medical-university-journal-publisher",
    urlone: "http://www.jclmm.com/index_files/f3.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-cognitive-psychology",
    text: "Journal of Cognitive Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PECP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-cold-regions-engineering",
    text: "Journal of Cold Regions Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jcrgei",
    urltwo: "NA"
  },
  {
    id: "journal-of-colloid-and-interface-science",
    text: "Journal of Colloid And Interface Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-combinatorial-optimization",
    text: "Journal of Combinatorial Optimization",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-combinatorial-theory-series-a",
    text: "Journal of Combinatorial Theory, Series A",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-combinatorics",
    text: "Journal of Combinatorics",
    parent: "NA",
    urlone: "http://www.e-publications.org/intlpress/support/",
    urltwo: "NA"
  },
  {
    id: "journal-of-commodity-markets",
    text: "Journal of Commodity Markets",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-common-market-studies",
    text: "Journal of Common Market Studies",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291468-5965/homepage/ForAuthors.html#Style",
    urltwo: "NA"
  },
  {
    id: "journal-of-communication-disorders",
    text: "Journal of Communication Disorders",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-communications-and-networks",
    text: "Journal of Communications and Networks",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5449605",
    urltwo: "NA"
  },
  {
    id: "journal-of-community-genetics",
    text: "Journal of Community Genetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-community-health",
    text: "Journal of Community Health",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-comparative-asian-development",
    text: "Journal of Comparative Asian Development",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCAD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-comparative-economics",
    text: "Journal of Comparative Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-comparative-effectiveness-research",
    text: "Journal of Comparative Effectiveness Research",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/cer",
    urltwo: "NA"
  },
  {
    id: "journal-of-comparative-pathology",
    text: "Journal of Comparative Pathology",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/journal-of-comparative-pathology/0021-9975/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-comparative-physiology-a",
    text: "Journal of Comparative Physiology A",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-comparative-physiology-b",
    text: "Journal of Comparative Physiology B",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-comparative-psychology",
    text: "Journal of Comparative Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/com",
    urltwo: "NA"
  },
  {
    id: "journal-of-compassionate-health-care",
    text: "Journal of Compassionate Health Care",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-competitiveness",
    text: "Journal of Competitiveness",
    parent: "apa",
    urlone: "http://www.cjournal.cz/index.php?hid=pokyny",
    urltwo: "NA"
  },
  {
    id: "journal-of-complexity",
    text: "Journal of Complexity",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-composites-for-construction",
    text: "Journal of Composites for Construction",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jccof2",
    urltwo: "NA"
  },
  {
    id: "journal-of-composites-science",
    text: "Journal of Composites Science",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-computational-and-applied-mathematics",
    text: "Journal of Computational and Applied Mathematics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-computational-and-graphical-statistics",
    text: "Journal of Computational and Graphical Statistics",
    parent: "american-statistical-association",
    urlone: "http://amstat.tfjournals.com/asa-style-guide/",
    urltwo: "NA"
  },
  {
    id: "journal-of-computational-and-nonlinear-dynamics",
    text: "Journal of Computational and Nonlinear Dynamics",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://computationalnonlinear.asmedigitalcollection.asme.org/journal.aspx?journalid=115",
    urltwo: "NA"
  },
  {
    id: "journal-of-computational-chemistry",
    text: "Journal of Computational Chemistry",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1096-987X/homepage/jcc_author_guidelines.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-computational-design-and-engineering",
    text: "Journal of Computational Design and Engineering",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-computational-electronics",
    text: "Journal of Computational Electronics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-computational-neuroscience",
    text: "Journal of Computational Neuroscience",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-computational-physics-x",
    text: "Journal of Computational Physics: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-computational-physics",
    text: "Journal of Computational Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-computational-science",
    text: "Journal of Computational Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-computational-surgery",
    text: "Journal of Computational Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-computer-aided-molecular-design",
    text: "Journal of Computer-Aided Molecular Design",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-computer-and-system-sciences",
    text: "Journal of Computer and System Sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-computer-applications-in-archaeology",
    text: "Journal of Computer Applications in Archaeology",
    parent: "NA",
    urlone: "http://journal.caa-international.org/about/submissions/#References",
    urltwo: "NA"
  },
  {
    id: "journal-of-computer-information-systems",
    text: "Journal of Computer Information Systems",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/journalInformation?journalCode=ucis20",
    urltwo: "NA"
  },
  {
    id: "journal-of-computer-languages",
    text: "Journal of Computer Languages",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-computer-virology-and-hacking-techniques",
    text: "Journal of Computer Virology and Hacking Techniques",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-computers-in-education",
    text: "Journal of Computers in Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-computing-and-information-science-in-engineering",
    text: "Journal of Computing and information Science in Engineering",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://computingengineering.asmedigitalcollection.asme.org/journal.aspx?journalid=116",
    urltwo: "NA"
  },
  {
    id: "journal-of-computing-in-civil-engineering",
    text: "Journal of Computing in Civil Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jccee5",
    urltwo: "NA"
  },
  {
    id: "journal-of-computing-in-higher-education",
    text: "Journal of Computing in Higher Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-congenital-cardiology",
    text: "Journal of Congenital Cardiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-construction-engineering-and-management",
    text: "Journal of Construction Engineering and Management",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jcemd4",
    urltwo: "NA"
  },
  {
    id: "journal-of-constructional-steel-research",
    text: "Journal of Constructional Steel Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-consulting-and-clinical-psychology",
    text: "Journal of Consulting and Clinical Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/ccp",
    urltwo: "NA"
  },
  {
    id: "journal-of-consumer-policy",
    text: "Journal of Consumer Policy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-consumer-psychology",
    text: "Journal of Consumer Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-consumer-research",
    text: "Journal of Consumer Research",
    parent: "NA",
    urlone: "http://www.ejcr.org/newstylesheet.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-contaminant-hydrology",
    text: "Journal of Contaminant Hydrology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-contemporary-accounting-and-economics",
    text: "Journal of Contemporary Accounting &amp; Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-contemporary-african-studies",
    text: "Journal of Contemporary African Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJCA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-contemporary-asia",
    text: "Journal of Contemporary Asia",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJOC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-contemporary-european-studies",
    text: "Journal of Contemporary European Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJEA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-contemporary-medicine",
    text: "Journal of Contemporary Medicine",
    parent: "NA",
    urlone: "http://dergipark.gov.tr/gopctd/page/369",
    urltwo: "NA"
  },
  {
    id: "journal-of-contemporary-psychotherapy",
    text: "Journal of Contemporary Psychotherapy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-contextual-behavioral-science",
    text: "Journal of Contextual Behavioral Science",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-control-automation-and-electrical-systems",
    text: "Journal of Control, Automation and Electrical Systems",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-controlled-release",
    text: "Journal of Controlled Release",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-corporate-finance",
    text: "Journal of Corporate Finance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cosmology-and-astroparticle-physics",
    text: "Journal of Cosmology and Astroparticle Physics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1475-7516",
    urltwo: "NA"
  },
  {
    id: "journal-of-counseling-psychology",
    text: "Journal of Counseling Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cou",
    urltwo: "NA"
  },
  {
    id: "journal-of-criminal-justice-education",
    text: "Journal of Criminal Justice Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCJE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-criminal-justice",
    text: "Journal of Criminal Justice",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-critical-care",
    text: "Journal of Critical Care",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-crohns-and-colitis-supplements",
    text: "Journal of Crohn's and Colitis Supplements",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-crohns-and-colitis-supplements/1873-9954/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-crohns-and-colitis",
    text: "Journal of Crohn's and Colitis",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-crohns-and-colitis/1873-9946/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-cross-cultural-gerontology",
    text: "Journal of Cross-Cultural Gerontology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-cryptographic-engineering",
    text: "Journal of Cryptographic Engineering",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-crystal-growth",
    text: "Journal of Crystal Growth",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-cultural-economics",
    text: "Journal of Cultural Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-cultural-economy",
    text: "Journal of Cultural Economy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJCE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-cultural-heritage",
    text: "Journal of Cultural Heritage",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-current-ophthalmology",
    text: "Journal of Current Ophthalmology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-curriculum-studies",
    text: "Journal of Curriculum Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TCUS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-cystic-fibrosis",
    text: "Journal of Cystic Fibrosis",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-dairy-science",
    text: "Journal of Dairy Science",
    parent: "NA",
    urlone: "http://www.journalofdairyscience.org/content/inst-auth",
    urltwo: "NA"
  },
  {
    id: "journal-of-data-and-information-quality",
    text: "Journal of Data and Information Quality",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "journal-of-decision-systems",
    text: "Journal of Decision Systems",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJDS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-dental-education",
    text: "Journal of Dental Education",
    parent: "vancouver-superscript",
    urlone: "http://www.jdentaled.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "journal-of-dental-research",
    text: "Journal of Dental Research",
    parent: "council-of-science-editors-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-dental-sciences",
    text: "Journal of Dental Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-dentistry-x",
    text: "Journal of Dentistry: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-dentistry",
    text: "Journal of Dentistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-dermatological-science-supplement",
    text: "Journal of Dermatological Science Supplement",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-dermatological-science",
    text: "Journal of Dermatological Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-dermatology-and-dermatologic-surgery",
    text: "Journal of Dermatology &amp; Dermatologic Surgery",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-destination-marketing-and-management",
    text: "Journal of Destination Marketing &amp; Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-development-economics",
    text: "Journal of Development Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-development-effectiveness",
    text: "Journal of Development Effectiveness",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJDE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-development-studies",
    text: "Journal of Development Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FJDS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-developmental-and-behavioral-pediatrics",
    text: "Journal of Developmental &amp; Behavioral Pediatrics",
    parent: "american-medical-association-no-url",
    urlone: "https://journals.lww.com/jrnldbp/Pages/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-developmental-and-life-course-criminology",
    text: "Journal of Developmental and Life-Course Criminology",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-developmental-and-physical-disabilities",
    text: "Journal of Developmental and Physical Disabilities",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-developmental-biology",
    text: "Journal of Developmental Biology",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-diabetes-and-its-complications",
    text: "Journal of Diabetes and Its Complications",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-diabetes-and-metabolic-disorders",
    text: "Journal of Diabetes &amp; Metabolic Disorders",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-differential-equations",
    text: "Journal of Differential Equations",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-discrete-algorithms",
    text: "Journal of Discrete Algorithms",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-disease-cause-and-control",
    text: "Journal of Disease Cause and Control",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-display-technology",
    text: "Journal of Display Technology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=9425",
    urltwo: "NA"
  },
  {
    id: "journal-of-diversity-in-higher-education",
    text: "Journal of Diversity in Higher Education",
    parent: "apa",
    urlone: "http://content.apa.org/journals/dhe",
    urltwo: "NA"
  },
  {
    id: "journal-of-drug-delivery-science-and-technology",
    text: "Journal of Drug Delivery Science and Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-dynamic-behavior-of-materials",
    text: "Journal of Dynamic Behavior of Materials",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-dynamic-systems-measurement-and-control",
    text: "Journal of Dynamic Systems, Measurement, and Control",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://dynamicsystems.asmedigitalcollection.asme.org/journal.aspx?journalid=117",
    urltwo: "NA"
  },
  {
    id: "journal-of-dynamical-and-control-systems",
    text: "Journal of Dynamical and Control Systems",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-dynamics-and-differential-equations",
    text: "Journal of Dynamics and Differential Equations",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-early-christian-studies",
    text: "Journal of Early Christian Studies",
    parent: "NA",
    urlone: "https://www.press.jhu.edu/journals/journal-early-christian-studies/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-east-asian-linguistics",
    text: "Journal of East Asian Linguistics",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-eastern-african-studies",
    text: "Journal of Eastern African Studies",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJEA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-eating-disorders",
    text: "Journal of Eating Disorders",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-echocardiography",
    text: "Journal of Echocardiography",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-ecology",
    text: "Journal of Ecology",
    parent: "apa-old-doi-prefix",
    urlone: "http://besjournals.onlinelibrary.wiley.com/hub/journal/10.1111/(ISSN)1365-2745/author-guidelines.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-econometrics",
    text: "Journal of Econometrics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-economic-behavior-and-organization",
    text: "Journal of Economic Behavior and Organization",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-economic-dynamics-and-control",
    text: "Journal of Economic Dynamics and Control",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-economic-entomology",
    text: "Journal of Economic Entomology",
    parent: "entomological-society-of-america",
    urlone: "http://www.entsoc.org/pubs/publish/style",
    urltwo: "NA"
  },
  {
    id: "journal-of-economic-growth",
    text: "Journal of Economic Growth",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-economic-interaction-and-coordination",
    text: "Journal of Economic Interaction and Coordination",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-economic-methodology",
    text: "Journal of Economic Methodology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJEC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-economic-policy-reform",
    text: "Journal of Economic Policy Reform",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GPRE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-economic-psychology",
    text: "Journal of Economic Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-economic-structures",
    text: "Journal of Economic Structures",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-economic-theory",
    text: "Journal of Economic Theory",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-economics-and-business",
    text: "Journal of Economics and Business",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-economics-and-finance",
    text: "Journal of Economics and Finance",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-economics-finance-and-administrative-science",
    text: "Journal of Economics, Finance and Administrative Science",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-economics",
    text: "Journal of Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-ecotourism",
    text: "Journal of Ecotourism",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RECO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-education-and-work",
    text: "Journal of Education and Work",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJEW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-education-for-teaching",
    text: "Journal of Education for Teaching",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJET20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-education-policy",
    text: "Journal of Education Policy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TEDP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-educational-and-behavioral-statistics",
    text: "Journal of Educational and Behavioral Statistics",
    parent: "apa",
    urlone: "http://www.sagepub.com/journalsProdDesc.nav?prodId=Journal201853#tabview=manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "journal-of-educational-change",
    text: "Journal of Educational Change",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-educational-evaluation-for-health-professions",
    text: "Journal of Educational Evaluation for Health Professions",
    parent: "vancouver-brackets",
    urlone: "http://www.jeehp.org/authors/authors.php",
    urltwo: "NA"
  },
  {
    id: "journal-of-educational-psychology",
    text: "Journal of Educational Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/edu",
    urltwo: "NA"
  },
  {
    id: "journal-of-egyptian-history",
    text: "Journal of Egyptian History",
    parent: "NA",
    urlone: "https://brill.com/view/journals/jeh/jeh-overview.xml",
    urltwo: "NA"
  },
  {
    id: "journal-of-elasticity",
    text: "Journal of Elasticity",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-elections-public-opinion-and-parties",
    text: "Journal of Elections, Public Opinion &amp; Parties",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=fbep&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-electrical-systems-and-information-technology",
    text: "Journal of Electrical Systems and Information Technology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-electroanalytical-chemistry",
    text: "Journal of Electroanalytical Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-electrocardiology",
    text: "Journal of Electrocardiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-electroceramics",
    text: "Journal of Electroceramics",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-electromagnetic-waves-and-applications",
    text: "Journal of Electromagnetic Waves and Applications",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TEWA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-electromyography-and-kinesiology",
    text: "Journal of Electromyography and Kinesiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-electron-spectroscopy-and-related-phenomena",
    text: "Journal of Electron Spectroscopy and Related Phenomena",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-electronic-imaging",
    text: "Journal of Electronic Imaging",
    parent: "spie-journals",
    urlone: "http://electronicimaging.spiedigitallibrary.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-electronic-materials",
    text: "Journal of Electronic Materials",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-electronic-packaging",
    text: "Journal of Electronic Packaging",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://electronicpackaging.asmedigitalcollection.asme.org/journal.aspx?journalid=118",
    urltwo: "NA"
  },
  {
    id: "journal-of-electronic-testing",
    text: "Journal of Electronic Testing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-electrostatics",
    text: "Journal of Electrostatics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-emergency-nursing",
    text: "Journal of Emergency Nursing",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-empirical-finance",
    text: "Journal of Empirical Finance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-endocrinological-investigation",
    text: "Journal of Endocrinological Investigation",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-endocrinology",
    text: "Journal of Endocrinology",
    parent: "journal-of-molecular-endocrinology",
    urlone: "http://joe.endocrinology-journals.org/site/misc/For-Authors.xhtml#refs",
    urltwo: "NA"
  },
  {
    id: "journal-of-endodontics",
    text: "Journal of Endodontics",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-endodontics/0099-2399/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-energy-chemistry",
    text: "Journal of Energy Chemistry",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-energy-engineering",
    text: "Journal of Energy Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jleed9",
    urltwo: "NA"
  },
  {
    id: "journal-of-energy-resources-technology",
    text: "Journal of Energy Resources Technology",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://energyresources.asmedigitalcollection.asme.org/journal.aspx?journalid=119",
    urltwo: "NA"
  },
  {
    id: "journal-of-energy-storage",
    text: "Journal of Energy Storage",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-engineering-and-technology-management",
    text: "Journal of Engineering and Technology Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-engineering-for-gas-turbines-and-power",
    text: "Journal of Engineering for Gas Turbines and Power",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://gasturbinespower.asmedigitalcollection.asme.org/journal.aspx?journalid=120",
    urltwo: "NA"
  },
  {
    id: "journal-of-engineering-materials-and-technology",
    text: "Journal of Engineering Materials and Technology",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://materialstechnology.asmedigitalcollection.asme.org/journal.aspx?journalid=121",
    urltwo: "NA"
  },
  {
    id: "journal-of-engineering-mathematics",
    text: "Journal of Engineering Mathematics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-engineering-mechanics",
    text: "Journal of Engineering Mechanics",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jenmdt",
    urltwo: "NA"
  },
  {
    id: "journal-of-english-for-academic-purposes",
    text: "Journal of English for Academic Purposes",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-enhanced-heat-transfer",
    text: "Journal of Enhanced Heat Transfer",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/4c8f5faa331b09ea.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-chemical-engineering",
    text: "Journal of Environmental Chemical Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-economics-and-management",
    text: "Journal of Environmental Economics and Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-economics-and-policy",
    text: "Journal of Environmental Economics and Policy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TEEP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-engineering-and-landscape-management",
    text: "Journal of Environmental Engineering and Landscape Management",
    parent: "apa",
    urlone: "https://journals.vgtu.lt/index.php/JEELM/guidelinesforauthors",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-engineering",
    text: "Journal of Environmental Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/joeedu",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-health-science-and-engineering",
    text: "Journal of Environmental Health Science and Engineering",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-environmental-management",
    text: "Journal of Environmental Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-pathology-toxicology-and-oncology",
    text: "Journal of Environmental Pathology, Toxicology and Oncology",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/0ff459a57a4c08d0.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-planning-and-management",
    text: "Journal of Environmental Planning and Management",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJEP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-policy-and-planning",
    text: "Journal of Environmental Policy &amp; Planning",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJOE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-psychology",
    text: "Journal of Environmental Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-quality",
    text: "Journal of Environmental Quality",
    parent: "asa-cssa-sssa",
    urlone: "https://www.agronomy.org/files/publications/jeq-instructions-authors.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-radioactivity",
    text: "Journal of Environmental Radioactivity",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-science-and-health-part-a",
    text: "Journal of Environmental Science and Health, Part A",
    parent: "journal-of-environmental-science-and-health-part-b",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=lesa20",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-science-and-health-part-b",
    text: "Journal of Environmental Science and Health, Part B",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=lesb20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-sciences",
    text: "Journal of Environmental Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-environmental-studies-and-sciences",
    text: "Journal of Environmental Studies and Sciences",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-epidemiology-and-community-health",
    text: "Journal of Epidemiology and Community Health",
    parent: "bmj",
    urlone: "http://jech.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "journal-of-epidemiology-and-global-health",
    text: "Journal of Epidemiology and Global Health",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-epidemiology",
    text: "Journal of Epidemiology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-equine-veterinary-science",
    text: "Journal of Equine Veterinary Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-eta-maritime-science",
    text: "Journal of ETA Maritime Science",
    parent: "NA",
    urlone: "http://www.jemsjournal.org/Default.aspx?p=Guide-for-Authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-ethnic-and-migration-studies",
    text: "Journal of Ethnic and Migration Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJMS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-ethnic-foods",
    text: "Journal of Ethnic Foods",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-ethnobiology-and-ethnomedicine",
    text: "Journal of Ethnobiology and Ethnomedicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-ethnobiology",
    text: "Journal of Ethnobiology",
    parent: "NA",
    urlone: "https://ethnobiology.org/publications/journal/guidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-ethnopharmacology",
    text: "Journal of Ethnopharmacology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-ethology",
    text: "Journal of Ethology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-eucornea",
    text: "Journal of EuCornea",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-eurasian-studies",
    text: "Journal of Eurasian Studies",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-european-public-policy",
    text: "Journal of European Public Policy",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/pdf/rjpp-refs.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-evolution-and-health",
    text: "Journal of Evolution and Health",
    parent: "NA",
    urlone: "http://jevohealth.com/journal/styleguide.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-evolutionary-biology",
    text: "Journal of Evolutionary Biology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1420-9101/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-evolutionary-economics",
    text: "Journal of Evolutionary Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-exercise-science-and-fitness",
    text: "Journal of Exercise Science &amp; Fitness",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-algorithmics",
    text: "Journal of Experimental Algorithmics",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-and-clinical-assisted-reproduction",
    text: "Journal of Experimental &amp; Clinical Assisted Reproduction",
    parent: "vancouver-brackets",
    urlone: "http://www.jexpclinassistreprod.com/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-and-clinical-cancer-research",
    text: "Journal of Experimental &amp; Clinical Cancer Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-experimental-and-theoretical-artificial-intelligence",
    text: "Journal of Experimental &amp; Theoretical Artificial Intelligence",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TETA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-botany",
    text: "Journal of Experimental Botany",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/exbotj/for_authors/index.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-child-psychology",
    text: "Journal of Experimental Child Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-criminology",
    text: "Journal of Experimental Criminology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-experimental-marine-biology-and-ecology",
    text: "Journal of Experimental Marine Biology and Ecology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-nanoscience",
    text: "Journal of Experimental Nanoscience",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJEN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-orthopaedics",
    text: "Journal of Experimental Orthopaedics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-experimental-psychology-animal-learning-and-cognition",
    text: "Journal of Experimental Psychology: Animal Learning and Cognition",
    parent: "apa",
    urlone: "http://content.apa.org/journals/xan",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-psychology-applied",
    text: "Journal of Experimental Psychology: Applied",
    parent: "apa",
    urlone: "http://content.apa.org/journals/xap",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-psychology-general",
    text: "Journal of Experimental Psychology: General",
    parent: "apa",
    urlone: "http://content.apa.org/journals/xge",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-psychology-human-perception-and-performance",
    text: "Journal of Experimental Psychology: Human Perception and Performance",
    parent: "apa",
    urlone: "http://content.apa.org/journals/xhp",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-psychology-learning-memory-and-cognition",
    text: "Journal of Experimental Psychology: Learning, Memory, and Cognition",
    parent: "apa",
    urlone: "http://content.apa.org/journals/xlm",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-social-psychology",
    text: "Journal of Experimental Social Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-experimental-zoology",
    text: "Journal of Experimental Zoology",
    parent: "the-journal-of-comparative-neurology",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291932-5231/homepage/ForAuthors.html#6",
    urltwo: "NA"
  },
  {
    id: "journal-of-exposure-science-and-environmental-epidemiology",
    text: "Journal of Exposure Science and Environmental Epidemiology",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mc.manuscriptcentral.com/societyimages/jesee/JESEE_ItA.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-failure-analysis-and-prevention",
    text: "Journal of Failure Analysis and Prevention",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-family-and-economic-issues",
    text: "Journal of Family and Economic Issues",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-family-business-strategy",
    text: "Journal of Family Business Strategy",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-family-planning-and-reproductive-health-care",
    text: "Journal of Family Planning and Reproductive Health Care",
    parent: "bmj",
    urlone: "http://jfprhc.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "journal-of-family-psychology",
    text: "Journal of Family Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/fam",
    urltwo: "NA"
  },
  {
    id: "journal-of-family-violence",
    text: "Journal of Family Violence",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-fetal-medicine",
    text: "Journal of Fetal Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-field-ornithology",
    text: "Journal of Field Ornithology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291557-9263/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-finance",
    text: "Journal of Finance",
    parent: "NA",
    urlone: "http://www.afajof.org/details/page/2870711/Submissions.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-financial-economics",
    text: "Journal of Financial Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-financial-intermediation",
    text: "Journal of Financial Intermediation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-financial-markets",
    text: "Journal of Financial Markets",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-financial-services-research",
    text: "Journal of Financial Services Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-financial-stability",
    text: "Journal of Financial Stability",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-fish-biology",
    text: "Journal of Fish Biology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1095-8649/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-fish-diseases",
    text: "Journal of Fish Diseases",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2761/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-flow-visualization-and-image-processing",
    text: "Journal of Flow Visualization and Image Processing",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/52b74bd3689ab10b.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-fluency-disorders",
    text: "Journal of Fluency Disorders",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-fluids-and-structures",
    text: "Journal of Fluids and Structures",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-fluids-engineering",
    text: "Journal of Fluids Engineering",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://fluidsengineering.asmedigitalcollection.asme.org/journal.aspx?journalid=122",
    urltwo: "NA"
  },
  {
    id: "journal-of-fluorescence",
    text: "Journal of Fluorescence",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-fluorine-chemistry",
    text: "Journal of Fluorine Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-food-and-drug-analysis",
    text: "Journal of Food and Drug Analysis",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-food-composition-and-analysis",
    text: "Journal of Food Composition and Analysis",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-food-engineering",
    text: "Journal of Food Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-food-measurement-and-characterization",
    text: "Journal of Food Measurement and Characterization",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-food-protection",
    text: "Journal of Food Protection",
    parent: "NA",
    urlone: "http://www.foodprotection.org/publications/instructions-for-authors/pdf/jfp-author-instructions.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-food-science-and-technology",
    text: "Journal of Food Science and Technology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-foot-and-ankle-research",
    text: "Journal of Foot and Ankle Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-foraminiferal-research",
    text: "Journal of Foraminiferal Research",
    parent: "NA",
    urlone: "https://pubs.geoscienceworld.org/jfr",
    urltwo: "NA"
  },
  {
    id: "journal-of-forensic-and-legal-medicine",
    text: "Journal of Forensic and Legal Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-forensic-psychiatry-and-psychology",
    text: "Journal of Forensic Psychiatry &amp; Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJFP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-forensic-radiology-and-imaging",
    text: "Journal of Forensic Radiology and Imaging",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-forensic-sciences",
    text: "Journal of Forensic Sciences",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291556-4029/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-forest-economics",
    text: "Journal of Forest Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-forest-research",
    text: "Journal of Forest Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-forestry-research",
    text: "Journal of Forestry Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-fourier-analysis-and-applications",
    text: "Journal of Fourier Analysis and Applications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-frailty-and-aging",
    text: "Journal of Frailty &amp; Aging",
    parent: "NA",
    urlone: "http://www.jfrailtyaging.com/instructions-for-authors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-freshwater-ecology",
    text: "Journal of Freshwater Ecology",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJFE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-frugal-innovation",
    text: "Journal of Frugal Innovation",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-fuel-cell-science-and-technology",
    text: "Journal of Fuel Cell Science and Technology",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://fuelcellscience.asmedigitalcollection.asme.org/journal.aspx?journalid=123",
    urltwo: "NA"
  },
  {
    id: "journal-of-functional-analysis",
    text: "Journal of Functional Analysis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-functional-biomaterials",
    text: "Journal of Functional Biomaterials",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-functional-foods",
    text: "Journal of Functional Foods",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-functional-morphology-and-kinesiology",
    text: "Journal of Functional Morphology and Kinesiology",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-fungi",
    text: "Journal of Fungi",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-further-and-higher-education",
    text: "Journal of Further and Higher Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJFH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-fusion-energy",
    text: "Journal of Fusion Energy",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-gambling-studies",
    text: "Journal of Gambling Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-gastroenterology-and-hepatology",
    text: "Journal of Gastroenterology and Hepatology",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291440-1746/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-gastroenterology",
    text: "Journal of Gastroenterology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-gastrointestinal-and-liver-diseases",
    text: "Journal of Gastrointestinal and Liver Diseases",
    parent: "vancouver-brackets",
    urlone: "http://www.jgld.ro/guidelines.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-gastrointestinal-cancer",
    text: "Journal of Gastrointestinal Cancer",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-gender-studies",
    text: "Journal of Gender Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJGS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-general-internal-medicine",
    text: "Journal of General Internal Medicine",
    parent: "american-medical-association",
    urlone: "http://www.springer.com/medicine/internal/journal/11606",
    urltwo: "NA"
  },
  {
    id: "journal-of-general-plant-pathology",
    text: "Journal of General Plant Pathology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-general-virology",
    text: "Journal of General Virology",
    parent: "microbiology-society",
    urlone: "http://jgv.microbiologyresearch.org/content/journal/jgv",
    urltwo: "NA"
  },
  {
    id: "journal-of-genetic-counseling",
    text: "Journal of Genetic Counseling",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-genetic-engineering-and-biotechnology",
    text: "Journal of Genetic Engineering and Biotechnology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-genetics-and-genomics",
    text: "Journal of Genetics and Genomics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-geochemical-exploration",
    text: "Journal of Geochemical Exploration",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-geodesy",
    text: "Journal of Geodesy",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-geodynamics",
    text: "Journal of Geodynamics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-geographical-systems",
    text: "Journal of Geographical Systems",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-geography-in-higher-education",
    text: "Journal of Geography in Higher Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJGH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-geometry-and-physics",
    text: "Journal of Geometry and Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-geometry",
    text: "Journal of Geometry",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-geophysical-research-atmospheres",
    text: "Journal of Geophysical Research: Atmospheres",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "journal-of-geophysical-research-biogeosciences",
    text: "Journal of Geophysical Research: Biogeosciences",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "journal-of-geophysical-research-earth-surface",
    text: "Journal of Geophysical Research: Earth Surface",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "journal-of-geophysical-research-oceans",
    text: "Journal of Geophysical Research: Oceans",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "journal-of-geophysical-research-planets",
    text: "Journal of Geophysical Research: Planets",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "journal-of-geophysical-research-solid-earth",
    text: "Journal of Geophysical Research: Solid Earth",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "journal-of-geophysical-research-space-physics",
    text: "Journal of Geophysical Research: Space Physics",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "journal-of-geophysical-research",
    text: "Journal of Geophysical Research",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "journal-of-geophysics-and-engineering",
    text: "Journal of Geophysics and Engineering",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1742-2140",
    urltwo: "NA"
  },
  {
    id: "journal-of-geosciences",
    text: "Journal of Geosciences",
    parent: "springer-basic-author-date",
    urlone: "http://www.jgeosci.org/instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-geotechnical-and-geoenvironmental-engineering",
    text: "Journal of Geotechnical and Geoenvironmental Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jggefk",
    urltwo: "NA"
  },
  {
    id: "journal-of-geriatric-oncology",
    text: "Journal of Geriatric Oncology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-geriatric-psychiatry-and-neurology",
    text: "Journal of Geriatric Psychiatry and Neurology",
    parent: "NA",
    urlone:
      "https://us.sagepub.com/en-us/nam/journal-of-geriatric-psychiatry-and-neurology/journal201662#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-gerontological-nursing",
    text: "Journal of Gerontological Nursing",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/journals/jgn/%7E/media/8A197680ADBD49FB8FC45AD04B7FE063.ashx",
    urltwo: "NA"
  },
  {
    id: "journal-of-ginseng-research",
    text: "Journal of Ginseng Research",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-glaciology",
    text: "Journal of Glaciology",
    parent: "NA",
    urlone: "http://www.igsoc.org/production/Journal_instructions_2014.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-glaucoma",
    text: "Journal of Glaucoma",
    parent: "american-medical-association",
    urlone: "http://edmgr.ovid.com/jog/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-global-antimicrobial-resistance",
    text: "Journal of Global Antimicrobial Resistance",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-global-entrepreneurship-research",
    text: "Journal of Global Entrepreneurship Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-global-fashion-marketing",
    text: "Journal of Global Fashion Marketing",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RGFM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-global-optimization",
    text: "Journal of Global Optimization",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-global-scholars-of-marketing-science",
    text: "Journal of Global Scholars of Marketing Science",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RGAM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-graphic-novels-and-comics",
    text: "Journal of Graphic Novels and Comics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RCOM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-great-lakes-research",
    text: "Journal of Great Lakes Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-grid-computing",
    text: "Journal of Grid Computing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-guidance-control-and-dynamics",
    text: "Journal of Guidance, Control, and Dynamics",
    parent: "american-institute-of-aeronautics-and-astronautics",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-gynecologic-oncology",
    text: "Journal of Gynecologic Oncology",
    parent: "vancouver-brackets",
    urlone: "http://www.ejgo.org/journal_info/journal_info01.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-gynecology-obstetrics-and-human-reproduction",
    text: "Journal of Gynecology Obstetrics and Human Reproduction",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-hainan-medical-university",
    text: "Journal of Hainan Medical University",
    parent: "hainan-medical-university-journal-publisher",
    urlone: "http://www.hnykdxxb.com/author1.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-hand-and-microsurgery",
    text: "Journal of Hand and Microsurgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-hand-therapy",
    text: "Journal of Hand Therapy",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-happiness-studies",
    text: "Journal of Happiness Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-hazardous-materials",
    text: "Journal of Hazardous Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-hazardous-toxic-and-radioactive-waste",
    text: "Journal of Hazardous, Toxic, and Radioactive Waste",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jhtrbp",
    urltwo: "NA"
  },
  {
    id: "journal-of-health-and-social-behavior",
    text: "Journal of Health and Social Behavior",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/jhsb/jhsb.cfm",
    urltwo: "NA"
  },
  {
    id: "journal-of-health-economics",
    text: "Journal of Health Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-health-population-and-nutrition",
    text: "Journal of Health, Population and Nutrition",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-hearing-science",
    text: "Journal of Hearing Science",
    parent: "NA",
    urlone: "http://www.journalofhearingscience.com/index.php?/content/get_cont/3",
    urltwo: "NA"
  },
  {
    id: "journal-of-heat-transfer",
    text: "Journal of Heat Transfer",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://heattransfer.asmedigitalcollection.asme.org/journal.aspx?journalid=124",
    urltwo: "NA"
  },
  {
    id: "journal-of-hematology-and-oncology",
    text: "Journal of Hematology &amp; Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-hematopathology",
    text: "Journal of Hematopathology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-hepatology",
    text: "Journal of Hepatology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-herbal-medicine",
    text: "Journal of Herbal Medicine",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-heritage-tourism",
    text: "Journal of Heritage Tourism",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJHT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-heuristics",
    text: "Journal of Heuristics",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-high-technology-management-research",
    text: "Journal of High Technology Management Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-highway-and-transportation-research-and-development",
    text: "Journal of Highway and Transportation Research and Development",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jhtrcq",
    urltwo: "NA"
  },
  {
    id: "journal-of-hip-preservation-surgery",
    text: "Journal of Hip Preservation Surgery",
    parent: "oxford-university-press-scimed-numeric",
    urlone: "http://www.oxfordjournals.org/our_journals/jhps/for_authors/manuscript_instructions.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-historical-geography",
    text: "Journal of Historical Geography",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-historical-linguistics",
    text: "Journal of Historical Linguistics",
    parent: "NA",
    urlone: "https://benjamins.com/#catalog/journals/jhl/submission",
    urltwo: "https://www.benjamins.com/series/jhl/jhl_stylesheet.pdf"
  },
  {
    id: "journal-of-homotopy-and-related-structures",
    text: "Journal of Homotopy and Related Structures",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-hospital-infection",
    text: "Journal of Hospital Infection",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-hospital-total-quality-management",
    text: "Journal of Hospital Total Quality Management",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-hospitality-and-tourism-management",
    text: "Journal of Hospitality and Tourism Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-hospitality-leisure-sport-and-tourism-education",
    text: "Journal of Hospitality, Leisure, Sport &amp; Tourism Education",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-housing-and-the-built-environment",
    text: "Journal of Housing and the Built Environment",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-housing-economics",
    text: "Journal of Housing Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-human-evolution",
    text: "Journal of Human Evolution",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-human-evolution/0047-2484/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "journal-of-human-hypertension",
    text: "Journal of Human Hypertension",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-jhh.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#preparation",
    urltwo: "NA"
  },
  {
    id: "journal-of-human-rights-and-social-work",
    text: "Journal of Human Rights and Social Work",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-hydraulic-engineering",
    text: "Journal of Hydraulic Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jhend8",
    urltwo: "NA"
  },
  {
    id: "journal-of-hydro-environment-research",
    text: "Journal of Hydro-environment Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-hydrologic-engineering",
    text: "Journal of Hydrologic Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jhyeff",
    urltwo: "NA"
  },
  {
    id: "journal-of-hydrology-regional-studies",
    text: "Journal of Hydrology: Regional Studies",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-hydrology-x",
    text: "Journal of Hydrology X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-hydrology",
    text: "Journal of Hydrology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-hydrometeorology",
    text: "Journal of Hydrometeorology",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/hydr",
    urltwo: "NA"
  },
  {
    id: "journal-of-hymenoptera-research",
    text: "Journal of Hymenoptera Research",
    parent: "pensoft-journals",
    urlone: "https://jhr.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "journal-of-hypertension",
    text: "Journal of Hypertension",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/jh/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-iberian-and-latin-american-studies",
    text: "Journal of Iberian and Latin American Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJIL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-imaging",
    text: "Journal of Imaging",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-immigrant-and-minority-health",
    text: "Journal of Immigrant and Minority Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-immunological-methods",
    text: "Journal of Immunological Methods",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-immunology-and-regenerative-medicine",
    text: "Journal of Immunology and Regenerative Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-inclusion-phenomena-and-macrocyclic-chemistry",
    text: "Journal of Inclusion Phenomena and Macrocyclic Chemistry",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-indian-association-for-child-and-adolescent-mental-health",
    text: "Journal of Indian Association for Child and Adolescent Mental Health",
    parent: "vancouver",
    urlone: "http://www.jiacam.org/guidelines1.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-indian-association-of-pediatric-surgeons",
    text: "Journal of Indian Association of Pediatric Surgeons",
    parent: "vancouver-brackets",
    urlone: "http://www.jiaps.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "journal-of-indian-college-of-cardiology",
    text: "Journal of Indian College of Cardiology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-indian-council-of-philosophical-research",
    text: "Journal of Indian Council of Philosophical Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-indian-philosophy",
    text: "Journal of Indian Philosophy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-individual-differences",
    text: "Journal of Individual Differences",
    parent: "apa",
    urlone: "http://content.apa.org/journals/jid",
    urltwo: "NA"
  },
  {
    id: "journal-of-industrial-and-engineering-chemistry",
    text: "Journal of Industrial and Engineering Chemistry",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/journal-of-industrial-and-engineering-chemistry/1226-086X/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-industrial-ecology",
    text: "Journal of Industrial Ecology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1530-9290/homepage/ForAuthors.html",
    urltwo: "http://www.yale.edu/jie/JIEstyle4authors.pdf"
  },
  {
    id: "journal-of-industrial-engineering-international",
    text: "Journal of Industrial Engineering International",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-industrial-information-integration",
    text: "Journal of Industrial Information Integration",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-industrial-microbiology-and-biotechnology",
    text: "Journal of Industrial Microbiology &amp; Biotechnology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-industry-competition-and-trade",
    text: "Journal of Industry, Competition and Trade",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-inequalities-and-applications",
    text: "Journal of Inequalities and Applications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-infection-and-chemotherapy",
    text: "Journal of Infection and Chemotherapy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-infection-and-public-health",
    text: "Journal of Infection and Public Health",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-infection",
    text: "Journal of Infection",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-infection/0163-4453/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-infectious-diseases",
    text: "Journal of Infectious Diseases",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/jid/for_authors/ms_prep.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-inflammation",
    text: "Journal of Inflammation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-information-security-and-applications",
    text: "Journal of Information Security and Applications",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-information-technology",
    text: "Journal of Information Technology",
    parent: "NA",
    urlone: "http://www.palgrave-journals.com/jit/author_instructions.html#Preparation-of-manuscripts",
    urltwo: "NA"
  },
  {
    id: "journal-of-informetrics",
    text: "Journal of Informetrics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-infrastructure-systems",
    text: "Journal of Infrastructure Systems",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jitse4",
    urltwo: "NA"
  },
  {
    id: "journal-of-inherited-metabolic-disease",
    text: "Journal of Inherited Metabolic Disease",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-innate-immunity",
    text: "Journal of Innate Immunity",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/234234",
    urltwo: "NA"
  },
  {
    id: "journal-of-innovation-and-entrepreneurship",
    text: "Journal of Innovation and Entrepreneurship",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-innovation-and-knowledge",
    text: "Journal of Innovation &amp; Knowledge",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-innovation-in-digital-ecosystems",
    text: "Journal of Innovation in Digital Ecosystems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-inorganic-and-organometallic-polymers-and-materials",
    text: "Journal of Inorganic and Organometallic Polymers and Materials",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-inorganic-biochemistry",
    text: "Journal of Inorganic Biochemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-insect-behavior",
    text: "Journal of Insect Behavior",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-insect-conservation",
    text: "Journal of Insect Conservation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-insect-physiology",
    text: "Journal of Insect Physiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-institute-of-medicine",
    text: "Journal of Institute of Medicine",
    parent: "vancouver-superscript",
    urlone: "http://www.jiom.com.np/pmanu.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-institutional-and-theoretical-economics",
    text: "Journal of Institutional and Theoretical Economics",
    parent: "NA",
    urlone: "http://www.mohr.de/fileadmin/user_upload/Hinweise_Autoren_PDF/JITE_Vorlagen/EncAuth2013.pdf",
    urltwo:
      "http://www.mohr.de/en/nc/journals/economics/journal-of-institutional-and-theoretical-economics-jite/manuscripts.html"
  },
  {
    id: "journal-of-instrumentation",
    text: "Journal of Instrumentation",
    parent: "NA",
    urlone: "http://iopscience.iop.org/1748-0221",
    urltwo: "NA"
  },
  {
    id: "journal-of-integrated-omics",
    text: "Journal of Integrated OMICS",
    parent: "NA",
    urlone: "http://www.jiomics.com/ojs/index.php/jio/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-integrated-science-and-technology",
    text: "Journal of Integrated Science and Technology",
    parent: "integrated-science-publishing-journals",
    urlone: "http://www.pubs.iscience.in/journal/index.php/jist/about/submissions",
    urltwo: "NA"
  },
  {
    id: "journal-of-integrative-environmental-sciences",
    text: "Journal of Integrative Environmental Sciences",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NENS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-integrative-medicine",
    text: "Journal of Integrative Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-intellectual-and-developmental-disability",
    text: "Journal of Intellectual &amp; Developmental Disability",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJID20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-intelligence",
    text: "Journal of Intelligence",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-intelligent-and-robotic-systems",
    text: "Journal of Intelligent &amp; Robotic Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-intelligent-information-systems",
    text: "Journal of Intelligent Information Systems",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-intelligent-manufacturing",
    text: "Journal of Intelligent Manufacturing",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-intensive-care",
    text: "Journal of Intensive Care",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-interaction-science",
    text: "Journal of Interaction Science",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-interactive-marketing",
    text: "Journal of Interactive Marketing",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-interactive-marketing/1094-9968/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "journal-of-intercultural-communication-research",
    text: "Journal of Intercultural Communication Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJIC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-internal-medicine",
    text: "Journal of Internal Medicine",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2796/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-academy-of-physical-therapy-research",
    text: "Journal of International Academy of Physical Therapy Research",
    parent: "american-medical-association",
    urlone: "http://journal.iaptr.org/ForContributors/01.php",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-accounting-auditing-and-taxation",
    text: "Journal of International Accounting, Auditing and Taxation",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-and-comparative-social-policy",
    text: "Journal of International and Comparative Social Policy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJCS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-business-studies",
    text: "Journal of International Business Studies",
    parent: "NA",
    urlone: "https://www.palgrave.com/gp/journal/41267/authors/presentation-formatting",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-economic-law",
    text: "Journal of International Economic Law",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/jielaw/for_authors/stylesheet_2.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-economics",
    text: "Journal of International Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-entrepreneurship",
    text: "Journal of International Entrepreneurship",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-international-financial-markets-institutions-and-money",
    text: "Journal of International Financial Markets, Institutions &amp; Money",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-humanitarian-action",
    text: "Journal of International Humanitarian Action",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-international-management",
    text: "Journal of International Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-marketing",
    text: "Journal of International Marketing",
    parent: "american-marketing-association",
    urlone: "https://www.ama.org/publications/JournalOfInternationalMarketing/Pages/JIM_Accepted_Ms.aspx#references",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-migration-and-integration",
    text: "Journal of International Migration and Integration",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-international-money-and-finance",
    text: "Journal of International Money and Finance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-peacekeeping",
    text: "Journal of International Peacekeeping",
    parent: "NA",
    urlone: "https://brill.com/fileasset/downloads_products/Author_Instructions/JOUP.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-international-relations-and-development",
    text: "Journal of International Relations and Development",
    parent: "NA",
    urlone: "http://www.springer.com/political+science/journal/41268",
    urltwo: "NA"
  },
  {
    id: "journal-of-internet-services-and-applications",
    text: "Journal of Internet Services and Applications",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-interprofessional-education-and-practice",
    text: "Journal of Interprofessional Education &amp; Practice",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-intervention-and-statebuilding",
    text: "Journal of Intervention and Statebuilding",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RISB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-interventional-cardiac-electrophysiology",
    text: "Journal of Interventional Cardiac Electrophysiology",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-interventional-medicine",
    text: "Journal of Interventional Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-invasive-and-interventional-cardiology",
    text: "Journal of Invasive and Interventional Cardiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-invertebrate-pathology",
    text: "Journal of Invertebrate Pathology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-investigative-dermatology-symposium-proceedings",
    text: "Journal of Investigative Dermatology Symposium Proceedings",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-investigative-dermatology",
    text: "Journal of Investigative Dermatology",
    parent: "NA",
    urlone: "http://www.jidonline.org/content/authorinfo",
    urltwo: "NA"
  },
  {
    id: "journal-of-irrigation-and-drainage-engineering",
    text: "Journal of Irrigation and Drainage Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jidedh",
    urltwo: "NA"
  },
  {
    id: "journal-of-israeli-history",
    text: "Journal of Israeli History",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FJIH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-jewish-studies",
    text: "Journal of Jewish Studies",
    parent: "NA",
    urlone: "https://www.jjs-online.net/authors/style_guide",
    urltwo: "NA"
  },
  {
    id: "journal-of-judicial-administration",
    text: "Journal of Judicial Administration",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-king-saud-university-computer-and-information-sciences",
    text: "Journal of King Saud University - Computer and Information Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-king-saud-university-engineering-sciences",
    text: "Journal of King Saud University - Engineering Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-king-saud-university-languages-and-translation",
    text: "Journal of King Saud University - Languages and Translation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-king-saud-university-science",
    text: "Journal of King Saud University - Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-knee-surgery",
    text: "Journal of Knee Surgery",
    parent: "american-medical-association",
    urlone: "http://www.thieme.com//media/ita/pubid-510861353.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-korean-medical-science",
    text: "Journal of Korean Medical Science",
    parent: "vancouver",
    urlone: "http://jkms.org/index.php?main=instruction",
    urltwo: "NA"
  },
  {
    id: "journal-of-korean-neurosurgical-society",
    text: "Journal of Korean Neurosurgical Society",
    parent: "NA",
    urlone: "http://www.jkns.or.kr/authors/authors.php",
    urltwo: "NA"
  },
  {
    id: "journal-of-labor-research",
    text: "Journal of Labor Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-laboratory-automation",
    text: "Journal of Laboratory Automation",
    parent: "society-for-laboratory-automation-and-screening",
    urlone: "http://www.slas.org/publications/scientific-journals/author-instructions/",
    urltwo: "NA"
  },
  {
    id: "journal-of-laboratory-physicians",
    text: "Journal of Laboratory Physicians",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.jlponline.org/contributors.asp#ref",
    urltwo: "NA"
  },
  {
    id: "journal-of-land-use-science",
    text: "Journal of Land Use Science",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TLUS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-landscape-ecology",
    text: "Journal of Landscape Ecology",
    parent: "apa",
    urlone: "http://www.journaloflandscapeecology.cz/index.php?page=guide",
    urltwo: "NA"
  },
  {
    id: "journal-of-latin-american-cultural-studies",
    text: "Journal of Latin American Cultural Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJLA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-latina-o-psychology",
    text: "Journal of Latinx Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/lat",
    urltwo: "NA"
  },
  {
    id: "journal-of-law-and-medicine",
    text: "Journal of Law and Medicine",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-legal-affairs-and-dispute-resolution-in-engineering-and-construction",
    text: "Journal of Legal Affairs and Dispute Resolution in Engineering and Construction",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jladah",
    urltwo: "NA"
  },
  {
    id: "journal-of-leukocyte-biology",
    text: "Journal of Leukocyte Biology",
    parent: "NA",
    urlone: "https://jlb.onlinelibrary.wiley.com/hub/journal/19383673/author-information",
    urltwo: "NA"
  },
  {
    id: "journal-of-lightwave-technology",
    text: "Journal of Lightwave Technology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=50",
    urltwo: "NA"
  },
  {
    id: "journal-of-limnology",
    text: "Journal of Limnology",
    parent: "NA",
    urlone: "http://www.jlimnol.it/index.php/jlimnol/about/submissions",
    urltwo: "NA"
  },
  {
    id: "journal-of-linguistics",
    text: "Journal of Linguistics",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/journals/journal-of-linguistics/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "journal-of-lipid-research",
    text: "Journal of Lipid Research",
    parent: "NA",
    urlone: "http://www.jlr.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "journal-of-location-based-services",
    text: "Journal of Location Based Services",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TLBS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-logic-and-algebraic-programming",
    text: "Journal of Logic and Algebraic Programming",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-logic-language-and-information",
    text: "Journal of Logic, Language and Information",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-logical-and-algebraic-methods-in-programming",
    text: "Journal of Logical and Algebraic Methods in Programming",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-long-term-effects-of-medical-implants",
    text: "Journal of Long-Term Effects of Medical Implants",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/1bef42082d7a0fdf.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-loss-prevention-in-the-process-industries",
    text: "Journal of Loss Prevention in the Process Industries",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-low-power-electronics-and-applications",
    text: "Journal of Low Power Electronics and Applications",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-low-temperature-physics",
    text: "Journal of Low Temperature Physics",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-luminescence",
    text: "Journal of Luminescence",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-machine-learning-for-modeling-and-computing",
    text: "Journal of Machine Learning for Modeling and Computing",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/558048804a15188a.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-macroeconomics",
    text: "Journal of Macroeconomics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-magnetic-resonance-imaging",
    text: "Journal Of Magnetic Resonance Imaging",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1522-2586/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-magnetic-resonance",
    text: "Journal of Magnetic Resonance",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-magnetism-and-magnetic-materials",
    text: "Journal of Magnetism and Magnetic Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-mammalogy",
    text: "Journal of Mammalogy",
    parent: "NA",
    urlone: "http://www.mammalsociety.org/journal-mammalogy",
    urltwo: "NA"
  },
  {
    id: "journal-of-mammary-gland-biology-and-neoplasia",
    text: "Journal of Mammary Gland Biology and Neoplasia",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-management-and-governance",
    text: "Journal of Management &amp; Governance",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-management-control",
    text: "Journal of Management Control",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-management-in-engineering",
    text: "Journal of Management in Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jmenea",
    urltwo: "NA"
  },
  {
    id: "journal-of-management-information-systems",
    text: "Journal of Management Information Systems",
    parent: "NA",
    urlone: "http://www.jmis-web.org/info-co/",
    urltwo: "NA"
  },
  {
    id: "journal-of-management-science-and-engineering",
    text: "Journal of Management Science and Engineering",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-management-studies",
    text: "Journal of Management Studies",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1467-6486/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-management",
    text: "Journal of Management",
    parent: "NA",
    urlone: "http://www.sagepub.com/upm-data/46742_JOM_Style_Guide_Revised_2011.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-managerial-psychology",
    text: "Journal of Managerial Psychology",
    parent: "emerald-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-manipulative-and-physiological-therapeutics",
    text: "Journal of Manipulative and Physiological Therapeutics",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-manufacturing-and-materials-processing",
    text: "Journal of Manufacturing and Materials Processing",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-manufacturing-processes",
    text: "Journal of Manufacturing Processes",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-manufacturing-science-and-engineering",
    text: "Journal of Manufacturing Science and Engineering",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://manufacturingscience.asmedigitalcollection.asme.org/journal.aspx?journalid=125",
    urltwo: "NA"
  },
  {
    id: "journal-of-manufacturing-systems",
    text: "Journal of Manufacturing Systems",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-maps",
    text: "Journal of Maps",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJOM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-marine-and-island-cultures",
    text: "Journal of Marine and Island Cultures",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-marine-science-and-engineering",
    text: "Journal of Marine Science and Engineering",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-marine-science-and-technology",
    text: "Journal of Marine Science and Technology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-marine-systems",
    text: "Journal of Marine Systems",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-maritime-archaeology",
    text: "Journal of Maritime Archaeology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-marketing-communications",
    text: "Journal of Marketing Communications",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJMC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-marketing-for-higher-education",
    text: "Journal of Marketing for Higher Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=WMHE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-marketing-management",
    text: "Journal of Marketing Management",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJMM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-marketing-research",
    text: "Journal of Marketing Research",
    parent: "american-marketing-association",
    urlone: "https://www.ama.org/publications/JournalOfMarketingResearch/Pages/JMR_Accepted_Ms.aspx#references",
    urltwo: "NA"
  },
  {
    id: "journal-of-marketing",
    text: "Journal of Marketing",
    parent: "american-marketing-association",
    urlone: "https://www.ama.org/Publications/JournalofMarketing/Pages/JMAccepted.aspx#3",
    urltwo: "NA"
  },
  {
    id: "journal-of-material-cycles-and-waste-management",
    text: "Journal of Material Cycles and Waste Management",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-materials-chemistry-a",
    text: "Journal of Materials Chemistry A",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-chemistry-b",
    text: "Journal of Materials Chemistry B",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-chemistry-c",
    text: "Journal of Materials Chemistry C",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-engineering-and-performance",
    text: "Journal of Materials Engineering and Performance",
    parent: "journal-of-thermal-spray-technology",
    urlone: "http://www.springer.com/materials/characterization+%26+evaluation/journal/11665",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-in-civil-engineering",
    text: "Journal of Materials in Civil Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jmcee7",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-nanoscience",
    text: "Journal of Materials NanoScience",
    parent: "integrated-science-publishing-journals",
    urlone: "http://www.pubs.iscience.in/journal/index.php/jmns/about/submissions",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-processing-tech",
    text: "Journal of Materials Processing Tech.",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-research-and-technology",
    text: "Journal of Materials Research and Technology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-research",
    text: "Journal of Materials Research",
    parent: "NA",
    urlone: "http://www.mrs.org/jmr-format",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-science-and-technology",
    text: "Journal of Materials Science &amp; Technology",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-materials-science-materials-in-electronics",
    text: "Journal of Materials Science: Materials in Electronics",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-materials-science-materials-in-medicine",
    text: "Journal of Materials Science: Materials in Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-materials-science",
    text: "Journal of Materials Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-materiomics",
    text: "Journal of Materiomics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-mathematical-behavior",
    text: "Journal of Mathematical Behavior",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-mathematical-biology",
    text: "Journal of Mathematical Biology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-mathematical-chemistry",
    text: "Journal of Mathematical Chemistry",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-mathematical-economics",
    text: "Journal of Mathematical Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-mathematical-fluid-mechanics",
    text: "Journal of Mathematical Fluid Mechanics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-mathematical-imaging-and-vision",
    text: "Journal of Mathematical Imaging and Vision",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-mathematical-physics",
    text: "Journal of Mathematical Physics",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-mathematical-psychology",
    text: "Journal of Mathematical Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-mathematics-and-music",
    text: "Journal of Mathematics and Music",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TMAM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-mathematics-in-industry",
    text: "Journal of Mathematics in Industry",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-mathematics-teacher-education",
    text: "Journal of Mathematics Teacher Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-maxillofacial-and-oral-surgery",
    text: "Journal of Maxillofacial and Oral Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-mechanical-design",
    text: "Journal of Mechanical Design",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://mechanicaldesign.asmedigitalcollection.asme.org/journal.aspx?journalid=126",
    urltwo: "NA"
  },
  {
    id: "journal-of-mechanical-science-and-technology",
    text: "Journal of Mechanical Science and Technology",
    parent: "NA",
    urlone: "http://www.j-mst.org/Introduction_2.asp",
    urltwo: "NA"
  },
  {
    id: "journal-of-mechanisms-and-robotics",
    text: "Journal of Mechanisms and Robotics",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://mechanismsrobotics.asmedigitalcollection.asme.org/journal.aspx?journalid=127",
    urltwo: "NA"
  },
  {
    id: "journal-of-media-psychology-theories-methods-and-applications",
    text: "Journal of Media Psychology: Theories, Methods, and Applications",
    parent: "apa",
    urlone: "http://content.apa.org/journals/jmp",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-and-biological-engineering",
    text: "Journal of Medical and Biological Engineering",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-medical-case-reports",
    text: "Journal of Medical Case Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-medical-devices",
    text: "Journal of Medical Devices",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://medicaldevices.asmedigitalcollection.asme.org/journal.aspx?journalid=128",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-entomology",
    text: "Journal of Medical Entomology",
    parent: "entomological-society-of-america",
    urlone: "http://www.entsoc.org/pubs/publish/style",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-ethics",
    text: "Journal of Medical Ethics",
    parent: "bmj",
    urlone: "http://jme.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-genetics",
    text: "Journal of Medical Genetics",
    parent: "NA",
    urlone: "http://group.bmj.com/products/journals/instructions-for-authors/formatting#references",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-imaging-and-radiation-oncology",
    text: "Journal of Medical Imaging and Radiation Oncology",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1754-9485/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-imaging-and-radiation-sciences",
    text: "Journal of Medical Imaging and Radiation Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-imaging",
    text: "Journal of Medical Imaging",
    parent: "spie-journals",
    urlone: "http://medicalimaging.spiedigitallibrary.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-internet-research",
    text: "Journal of Medical Internet Research",
    parent: "NA",
    urlone: "http://www.jmir.org/cms/view/Instructions_for_Authors:Instructions_for_Authors_of_JMIR#References",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-microbiology",
    text: "Journal of Medical Microbiology",
    parent: "microbiology-society",
    urlone: "http://jmm.microbiologyresearch.org/content/journal/jmm",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-physics",
    text: "Journal of Medical Physics",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.jmp.org.in/contributors.asp#ref",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-toxicology",
    text: "Journal of Medical Toxicology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-medical-ultrasonics",
    text: "Journal of Medical Ultrasonics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-medical-ultrasound",
    text: "Journal of Medical Ultrasound",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-medical-virology",
    text: "Journal of Medical Virology",
    parent: "american-medical-association",
    urlone: "https://onlinelibrary.wiley.com/page/journal/10969071/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-medicinal-chemistry",
    text: "Journal of Medicinal Chemistry",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jmcmar/jmcmar_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-medicine-and-the-person",
    text: "Journal of Medicine and the Person",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-membrane-science",
    text: "Journal of Membrane Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-memory-and-language",
    text: "Journal of Memory and Language",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-mens-health",
    text: "Journal of Men's Health",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-micro-and-nano-manufacturing",
    text: "Journal of Micro and Nano-Manufacturing",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://micronanomanufacturing.asmedigitalcollection.asme.org/journal.aspx?journalid=137",
    urltwo: "NA"
  },
  {
    id: "journal-of-micro-bio-robotics",
    text: "Journal of Micro-Bio Robotics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-micro-nanolithography-mems-and-moems",
    text: "Journal of Micro/Nanolithography, MEMS, and MOEMS",
    parent: "spie-journals",
    urlone: "http://nanolithography.spiedigitallibrary.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-microbiological-methods",
    text: "Journal of Microbiological Methods",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-microbiology-and-biology-education",
    text: "Journal of Microbiology &amp; Biology Education",
    parent: "american-society-for-microbiology",
    urlone: "http://jmbesubmissions.asm.org/index.php/jmbe/about/submissions1935-7885",
    urltwo: "NA"
  },
  {
    id: "journal-of-microbiology-immunology-and-infection",
    text: "Journal of Microbiology, Immunology and Infection",
    parent: "vancouver",
    urlone:
      "http://www.elsevier.com/journals/journal-of-microbiology-immunology-and-infection/1684-1182/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-microelectromechanical-systems",
    text: "Journal of Microelectromechanical Systems",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=84",
    urltwo: "NA"
  },
  {
    id: "journal-of-micromechanics-and-microengineering",
    text: "Journal of Micromechanics and Microengineering",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0960-1317",
    urltwo: "NA"
  },
  {
    id: "journal-of-micropalaeontology",
    text: "Journal of Micropalaeontology",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-microscopy-and-ultrastructure",
    text: "Journal of Microscopy and Ultrastructure",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-midwifery-and-womens-health",
    text: "Journal of Midwifery &amp; Women's Health",
    parent: "american-medical-association",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291542-2011/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-minimal-access-surgery",
    text: "Journal of Minimal Access Surgery",
    parent: "vancouver",
    urlone: "http://www.journalofmas.com/contributors.asp#preparation",
    urltwo: "NA"
  },
  {
    id: "journal-of-minimally-invasive-gynecology",
    text: "Journal of Minimally Invasive Gynecology",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-minimally-invasive-gynecology/1553-4650/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-modern-italian-studies",
    text: "Journal of Modern Italian Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMIS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-modern-jewish-studies",
    text: "Journal of Modern Jewish Studies",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CMJS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-modern-power-systems-and-clean-energy",
    text: "Journal of Modern Power Systems and Clean Energy",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-modern-transportation",
    text: "Journal of Modern Transportation",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-molecular-and-cellular-cardiology",
    text: "Journal of Molecular and Cellular Cardiology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-biology",
    text: "Journal of Molecular Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-catalysis-a-chemical",
    text: "Journal of Molecular Catalysis. A, Chemical",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-catalysis-b-enzymatic",
    text: "Journal of Molecular Catalysis. B, Enzymatic",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-cell-biology",
    text: "Journal of Molecular Cell Biology",
    parent: "NA",
    urlone: "https://academic.oup.com/jmcb/pages/general_instructions#MANUSCRIPT%20ORGANIZATION",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-endocrinology",
    text: "Journal of Molecular Endocrinology",
    parent: "NA",
    urlone: "http://jme.endocrinology-journals.org/site/misc/For-Authors.xhtml#refs",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-evolution",
    text: "Journal of Molecular Evolution",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-molecular-graphics-and-modelling",
    text: "Journal of Molecular Graphics and Modelling",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-histology",
    text: "Journal of Molecular Histology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-molecular-liquids",
    text: "Journal of Molecular Liquids",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-medicine",
    text: "Journal of Molecular Medicine",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-molecular-microbiology-and-biotechnology",
    text: "Journal of Molecular Microbiology and Biotechnology",
    parent: "karger-journals-author-date",
    urlone: "http://www.karger.com/Journal/Home/228391",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-modeling",
    text: "Journal of Molecular Modeling",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-molecular-neuroscience",
    text: "Journal of Molecular Neuroscience",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-molecular-psychiatry",
    text: "Journal of Molecular Psychiatry",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-molecular-recognition",
    text: "Journal of Molecular Recognition",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1099-1352/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-signaling",
    text: "Journal of Molecular Signaling",
    parent: "NA",
    urlone: "https://www.jmolecularsignaling.com/about/submissions/#References",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-spectroscopy",
    text: "Journal of Molecular Spectroscopy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-molecular-structure",
    text: "Journal of Molecular Structure",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-monetary-economics",
    text: "Journal of Monetary Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-moral-education",
    text: "Journal of Moral Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJME20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-morphology",
    text: "Journal of Morphology",
    parent: "apa-no-doi-no-issue",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291097-4687/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-multilingual-and-multicultural-development",
    text: "Journal of Multilingual and Multicultural Development",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMMM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-multinational-financial-management",
    text: "Journal of Multinational Financial Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-muscle-research-and-cell-motility",
    text: "Journal of Muscle Research and Cell Motility",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-musculoskeletal-research",
    text: "Journal of Musculoskeletal Research",
    parent: "NA",
    urlone: "https://www.worldscientific.com/toc/jmr/23/01",
    urltwo: "NA"
  },
  {
    id: "journal-of-music-technology-and-education",
    text: "Journal of Music Technology and Education",
    parent: "NA",
    urlone: "http://www.intellectbooks.co.uk/journals/page/index,name=journalstyleguide/",
    urltwo: "NA"
  },
  {
    id: "journal-of-nanobiotechnology",
    text: "Journal of Nanobiotechnology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-nanomechanics-and-micromechanics",
    text: "Journal of Nanomechanics and Micromechanics",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jnmoco",
    urltwo: "NA"
  },
  {
    id: "journal-of-nanoparticle-research",
    text: "Journal of Nanoparticle Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-nanophotonics",
    text: "Journal of Nanophotonics",
    parent: "spie-journals",
    urlone: "http://nanophotonics.spiedigitallibrary.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-nanoscience-and-nanotechnology",
    text: "Journal of Nanoscience and Nanotechnology",
    parent: "NA",
    urlone: "http://www.aspbs.com/jnn/inst-auth_jnn.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-nanostructure-in-chemistry",
    text: "Journal of Nanostructure in Chemistry",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-nanotechnology-in-engineering-and-medicine",
    text: "Journal of Nanotechnology in Engineering and Medicine",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://nanoengineeringmedical.asmedigitalcollection.asme.org/journal.aspx?journalid=129",
    urltwo: "NA"
  },
  {
    id: "journal-of-natural-gas-geoscience",
    text: "Journal of Natural Gas Geoscience",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-natural-gas-science-and-engineering",
    text: "Journal of Natural Gas Science and Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-natural-history",
    text: "Journal of Natural History",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TNAH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-natural-medicines",
    text: "Journal of Natural Medicines",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-natural-products",
    text: "Journal of Natural Products",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jnprdf/jnprdf_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-natural-resources-policy-research",
    text: "Journal of Natural Resources Policy Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJNR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-negative-results-in-biomedicine",
    text: "Journal of Negative Results in BioMedicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-neolithic-archaeology",
    text: "Journal of Neolithic Archaeology",
    parent: "NA",
    urlone: "http://www.jna.uni-kiel.de/index.php/jna/about/submissions",
    urltwo: "NA"
  },
  {
    id: "journal-of-neonatal-nursing",
    text: "Journal of Neonatal Nursing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-nephrology",
    text: "Journal of Nephrology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-network-and-computer-applications",
    text: "Journal of Network and Computer Applications",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-network-and-systems-management",
    text: "Journal of Network and Systems Management",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-neural-engineering",
    text: "Journal of Neural Engineering",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1741-2552",
    urltwo: "NA"
  },
  {
    id: "journal-of-neural-transmission",
    text: "Journal of Neural Transmission",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-neuro-oncology",
    text: "Journal of Neuro-Oncology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-neurochemistry",
    text: "Journal of Neurochemistry",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1471-4159",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurodevelopmental-disorders",
    text: "Journal of Neurodevelopmental Disorders",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-neuroendocrinology",
    text: "Journal of Neuroendocrinology",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/13652826/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-neuroengineering-and-rehabilitation",
    text: "Journal of NeuroEngineering and Rehabilitation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-neuroimmune-pharmacology",
    text: "Journal of Neuroimmune Pharmacology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-neuroimmunology",
    text: "Journal of Neuroimmunology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-neuroinflammation",
    text: "Journal of Neuroinflammation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-neurointerventional-surgery",
    text: "Journal of NeuroInterventional surgery",
    parent: "bmj",
    urlone: "http://jnis.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurolinguistics",
    text: "Journal of Neurolinguistics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurological-disorders",
    text: "Journal of Neurological Disorders",
    parent: "NA",
    urlone: "http://esciencecentral.org/journals/instructionsforauthors-neurological-disorders-open-access.php",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurology-neurosurgery-and-psychiatry",
    text: "Journal of Neurology, Neurosurgery, and Psychiatry",
    parent: "bmj",
    urlone: "http://jnnp.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurology",
    text: "Journal of Neurology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-neuropathology-and-experimental-neurology",
    text: "Journal of Neuropathology &amp; Experimental Neurology",
    parent: "vancouver",
    urlone: "http://edmgr.ovid.com/jnen/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurophysiology",
    text: "Journal of Neurophysiology",
    parent: "NA",
    urlone: "https://www.physiology.org/author-info.manuscript-composition",
    urltwo: "NA"
  },
  {
    id: "journal-of-neuropsychology",
    text: "Journal of Neuropsychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1748-6653/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-neuroradiology",
    text: "Journal of Neuroradiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-neuroscience-and-neuroengineering",
    text: "Journal of Neuroscience and Neuroengineering",
    parent: "NA",
    urlone: "http://www.aspbs.com/jnsne/inst-auth_jnsne.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-neuroscience-methods",
    text: "Journal of Neuroscience Methods",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-neuroscience-psychology-and-economics",
    text: "Journal of Neuroscience, Psychology, and Economics",
    parent: "apa",
    urlone: "http://content.apa.org/journals/npe",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurosurgery-pediatrics",
    text: "Journal of Neurosurgery: Pediatrics",
    parent: "journal-of-neurosurgery",
    urlone: "http://thejns.org/",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurosurgery-spine",
    text: "Journal of Neurosurgery: Spine",
    parent: "journal-of-neurosurgery",
    urlone: "http://thejns.org/",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurosurgery",
    text: "Journal of Neurosurgery",
    parent: "NA",
    urlone: "http://jns.msubmit.net/html/Instructions_to_Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurotrauma",
    text: "Journal of Neurotrauma",
    parent: "NA",
    urlone: "http://www.liebertpub.com/manuscript/journal-of-neurotrauma/39/",
    urltwo: "NA"
  },
  {
    id: "journal-of-neurovirology",
    text: "Journal of NeuroVirology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-new-music-research",
    text: "Journal of New Music Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NNMR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-new-zealand-grasslands",
    text: "Journal of New Zealand Grasslands",
    parent: "NA",
    urlone: "https://www.nzgajournal.org.nz/index.php/JoNZG/about/submissions",
    urltwo: "NA"
  },
  {
    id: "journal-of-non-crystalline-solids-x",
    text: "Journal of Non-Crystalline Solids: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-non-crystalline-solids",
    text: "Journal of Non-Crystalline Solids",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-non-newtonian-fluid-mechanics",
    text: "Journal of Non-Newtonian Fluid Mechanics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-nondestructive-evaluation",
    text: "Journal of Nondestructive Evaluation",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-nonlinear-science",
    text: "Journal of Nonlinear Science",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-nonverbal-behavior",
    text: "Journal of Nonverbal Behavior",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-northeast-agricultural-university",
    text: "Journal of Northeast Agricultural University",
    parent: "vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-nuclear-cardiology",
    text: "Journal of Nuclear Cardiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-nuclear-materials",
    text: "Journal of Nuclear Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-nuclear-medicine-technology",
    text: "Journal of Nuclear Medicine Technology",
    parent: "american-medical-association",
    urlone: "http://interactive.snm.org/docs/jnmt_infoauth.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-nuclear-science-and-technology",
    text: "Journal of Nuclear Science and Technology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/toc/tnst20/current#.U3qvFi_Wr7A",
    urltwo: "NA"
  },
  {
    id: "journal-of-nursing-education",
    text: "Journal of Nursing Education",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/~/media/Files/Journals/General%20PDFs/JNE/JNEguidelines.ashx",
    urltwo: "NA"
  },
  {
    id: "journal-of-nursing-regulation",
    text: "Journal of Nursing Regulation",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-nutrigenetics-and-nutrigenomics",
    text: "Journal of Nutrigenetics and Nutrigenomics",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/232009",
    urltwo: "NA"
  },
  {
    id: "journal-of-nutrition-and-intermediary-metabolism",
    text: "Journal of Nutrition &amp; Intermediary Metabolism",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-nutrition-education-and-behavior",
    text: "Journal of Nutrition Education and Behavior",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-nutrition",
    text: "Journal of Nutrition",
    parent: "NA",
    urlone: "http://jn.nutrition.org/site/misc/ifora-full.pdf",
    urltwo: "http://www.nlm.nih.gov/bsd/uniform_requirements.html"
  },
  {
    id: "journal-of-obsessive-compulsive-and-related-disorders",
    text: "Journal of Obsessive-Compulsive and Related Disorders",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-obstetric-gynecologic-and-neonatal-nursing",
    text: "Journal of Obstetric, Gynecologic &amp; Neonatal Nursing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-occupational-and-organizational-psychology",
    text: "Journal of Occupational and Organizational Psychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-8325/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-occupational-health-psychology",
    text: "Journal of Occupational Health Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/ocp",
    urltwo: "NA"
  },
  {
    id: "journal-of-occupational-medicine-and-toxicology",
    text: "Journal of Occupational Medicine and Toxicology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-occupational-rehabilitation",
    text: "Journal of Occupational Rehabilitation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-occupational-science",
    text: "Journal of Occupational Science",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=ROCC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-ocean-engineering-and-marine-energy",
    text: "Journal of Ocean Engineering and Marine Energy",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-ocean-engineering-and-science",
    text: "Journal of Ocean Engineering and Science",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-oceanography",
    text: "Journal of Oceanography",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-ocular-pharmacology-and-therapeutics",
    text: "Journal of Ocular Pharmacology and Therapeutics",
    parent: "american-medical-association",
    urlone: "http://www.liebertpub.com/manuscript/jop",
    urltwo: "NA"
  },
  {
    id: "journal-of-offshore-mechanics-and-arctic-engineering",
    text: "Journal of Offshore Mechanics and Arctic Engineering",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://offshoremechanics.asmedigitalcollection.asme.org/journal.aspx?journalid=130",
    urltwo: "NA"
  },
  {
    id: "journal-of-oncological-sciences",
    text: "Journal of Oncological Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-open-innovation-technology-market-and-complexity",
    text: "Journal of Open Innovation: Technology, Market, and Complexity",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-operations-management",
    text: "Journal of Operations Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-ophthalmic-inflammation-and-infection",
    text: "Journal of Ophthalmic Inflammation and Infection",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-optics-official-publication-of-the-optical-society-of-india",
    text: "Journal of Optics - official publication of the Optical Society of India",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-optics",
    text: "Journal of Optics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/2040-8986",
    urltwo: "NA"
  },
  {
    id: "journal-of-optimization-theory-and-applications",
    text: "Journal of Optimization Theory and Applications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-optometry",
    text: "Journal of Optometry",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-oral-and-maxillofacial-surgery-medicine-and-pathology",
    text: "Journal of Oral and Maxillofacial Surgery, Medicine, and Pathology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-oral-and-maxillofacial-surgery",
    text: "Journal of Oral and Maxillofacial Surgery",
    parent: "NA",
    urlone: "http://www.joms.org/authorinfo",
    urltwo: "NA"
  },
  {
    id: "journal-of-oral-biology-and-craniofacial-research",
    text: "Journal of Oral Biology and Craniofacial Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-oral-biosciences",
    text: "Journal of Oral Biosciences",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-organometallic-chemistry",
    text: "Journal of Organometallic Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-ornithology",
    text: "Journal of Ornithology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-orofacial-orthopedics",
    text: "Journal of Orofacial Orthopedics / Fortschritte der KieferorthopÃ¤die",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-orthopaedic-and-sports-physical-therapy",
    text: "Journal of Orthopaedic &amp; Sports Physical Therapy",
    parent: "american-medical-association-alphabetical",
    urlone: "http://www.jospt.org/page/authors/prepareManuscript",
    urltwo: "NA"
  },
  {
    id: "journal-of-orthopaedic-research",
    text: "Journal of Orthopaedic Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1554-527X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-orthopaedic-science",
    text: "Journal of Orthopaedic Science",
    parent: "vancouver-brackets-no-et-al",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-orthopaedic-surgery-and-research",
    text: "Journal of Orthopaedic Surgery and Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-orthopaedic-translation",
    text: "Journal of Orthopaedic Translation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-orthopaedic-trauma",
    text: "Journal of Orthopaedic Trauma",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/jot/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-orthopaedics-and-traumatology",
    text: "Journal of Orthopaedics and Traumatology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-orthopaedics-trauma-and-rehabilitation",
    text: "Journal of Orthopaedics, Trauma and Rehabilitation",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/journal-of-orthopaedics-trauma-and-rehabilitation/2210-4917/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-orthopaedics",
    text: "Journal of Orthopaedics",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-orthoptera-research",
    text: "Journal of Orthoptera Research",
    parent: "pensoft-journals",
    urlone: "https://jor.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "journal-of-otolaryngology-head-and-neck-surgery",
    text: "Journal of Otolaryngology - Head &amp; Neck Surgery",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-otology",
    text: "Journal of Otology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-otorhinolaryngology-hearing-and-balance-medicine",
    text: "Journal of Otorhinolaryngology, Hearing and Balance Medicine",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-outdoor-activities",
    text: "Journal of Outdoor Activities",
    parent: "apa",
    urlone: "https://www.pf.ujep.cz/en/ostatni/journal-of-outdoor-activities/brief-instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-outdoor-recreation-and-tourism",
    text: "Journal of Outdoor Recreation and Tourism",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-ovarian-research",
    text: "Journal of Ovarian Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-paediatrics-and-child-health",
    text: "Journal of Paediatrics and Child Health",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1440-1754/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-palaeogeography",
    text: "Journal of Palaeogeography",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-paleolimnology",
    text: "Journal of Paleolimnology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-paleontology",
    text: "Journal of Paleontology",
    parent: "NA",
    urlone: "http://www.journalofpaleontology.org/instructions.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-palliative-medicine",
    text: "Journal of Palliative Medicine",
    parent: "vancouver-superscript",
    urlone: "http://www.liebertpub.com/manuscript/journal-of-palliative-medicine/41/",
    urltwo: "NA"
  },
  {
    id: "journal-of-parallel-and-distributed-computing",
    text: "Journal of Parallel and Distributed Computing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-parasitic-diseases",
    text: "Journal of Parasitic Diseases",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-patient-safety-and-infection-control",
    text: "Journal of Patient Safety &amp; Infection Control",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-peace-education",
    text: "Journal of Peace Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJPE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-peace-research",
    text: "Journal of Peace Research",
    parent: "NA",
    urlone: "http://file.prio.no/journals/JPR/JPR-Notes-for-Authors-140909.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-pediatric-and-adolescent-gynecology",
    text: "Journal of Pediatric and Adolescent Gynecology",
    parent: "vancouver",
    urlone:
      "http://www.elsevier.com/journals/journal-of-pediatric-and-adolescent-gynecology/1083-3188/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-pediatric-gastroenterology-and-nutrition",
    text: "Journal of Pediatric Gastroenterology and Nutrition",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/jpgn/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-pediatric-health-care",
    text: "Journal of Pediatric Health Care",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pediatric-neuropsychology",
    text: "Journal of Pediatric Neuropsychology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-pediatric-neurosciences",
    text: "Journal of Pediatric Neurosciences",
    parent: "vancouver-superscript",
    urlone: "http://pediatricneurosciences.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "journal-of-pediatric-nursing",
    text: "Journal of Pediatric Nursing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pediatric-ophthalmology-and-strabismus",
    text: "Journal of Pediatric Ophthalmology &amp; Strabismus",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/~/media/Files/Journals/General%20PDFs/JPOS/JPOSinfoauth.ashx",
    urltwo: "NA"
  },
  {
    id: "journal-of-pediatric-surgery-case-reports",
    text: "Journal of Pediatric Surgery Case Reports",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pediatric-surgery",
    text: "Journal of Pediatric Surgery",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pediatric-urology",
    text: "Journal of Pediatric Urology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-peptide-science",
    text: "Journal of Peptide Science",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291099-1387/homepage/ForAuthors.html#Ref",
    urltwo: "http://onlinelibrary.wiley.com/doi/10.1002/psc.2633/pdf"
  },
  {
    id: "journal-of-performance-of-constructed-facilities",
    text: "Journal of Performance of Constructed Facilities",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jpcfev",
    urltwo: "NA"
  },
  {
    id: "journal-of-perianesthesia-nursing",
    text: "Journal of PeriAnesthesia Nursing",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-perinatal-medicine",
    text: "Journal of Perinatal Medicine",
    parent: "NA",
    urlone: "http://www.degruyter.com/view/supplement/s16193997_Instructions_for_Authors_en.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-perinatology",
    text: "Journal of Perinatology",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-jper.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#preparation",
    urltwo: "NA"
  },
  {
    id: "journal-of-periodontal-research",
    text: "Journal of Periodontal Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1600-0765/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-periodontology",
    text: "Journal of Periodontology",
    parent: "vancouver-superscript",
    urlone: "http://www.joponline.org/userimages/ContentEditor/1124388816475/Instructions_to_Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-personality-and-social-psychology",
    text: "Journal of Personality and Social Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/psp",
    urltwo: "NA"
  },
  {
    id: "journal-of-personalized-medicine",
    text: "Journal of Personalized Medicine",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-personnel-psychology",
    text: "Journal of Personnel Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/prs",
    urltwo: "NA"
  },
  {
    id: "journal-of-pest-science",
    text: "Journal of Pest Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-petroleum-exploration-and-production-technology",
    text: "Journal of Petroleum Exploration and Production Technology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-petroleum-science-and-engineering",
    text: "Journal of Petroleum Science and Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-petrology",
    text: "Journal of Petrology",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/petroj/for_authors/index.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-pharmaceutical-analysis",
    text: "Journal of Pharmaceutical Analysis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pharmaceutical-and-biomedical-analysis",
    text: "Journal of Pharmaceutical and Biomedical Analysis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pharmaceutical-health-care-and-sciences",
    text: "Journal of Pharmaceutical Health Care and Sciences",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-pharmaceutical-innovation",
    text: "Journal of Pharmaceutical Innovation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-pharmaceutical-investigation",
    text: "Journal of Pharmaceutical Investigation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-pharmaceutical-policy-and-practice",
    text: "Journal of Pharmaceutical Policy and Practice",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-pharmaceutical-sciences",
    text: "Journal of Pharmaceutical Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pharmacokinetics-and-pharmacodynamics",
    text: "Journal of Pharmacokinetics and Pharmacodynamics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-pharmacological-and-toxicological-methods",
    text: "Journal of Pharmacological and Toxicological Methods",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pharmacological-sciences",
    text: "Journal of Pharmacological Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pharmacology-and-pharmacotherapeutics",
    text: "Journal of Pharmacology &amp; Pharmacotherapeutics",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.jpharmacol.com/contributors.asp#ac",
    urltwo: "NA"
  },
  {
    id: "journal-of-pharmacy-and-pharmacology",
    text: "Journal of Pharmacy and Pharmacology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%292042-7158/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-pharmacy-practice-and-research",
    text: "Journal of Pharmacy Practice and Research",
    parent: "vancouver-superscript",
    urlone: "http://jppr.shpa.org.au/lib/pdf/Information_for_authors.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-pharmacy-research",
    text: "Journal of Pharmacy Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-phase-equilibria-and-diffusion",
    text: "Journal of Phase Equilibria and Diffusion",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-philosophical-logic",
    text: "Journal of Philosophical Logic",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-phonetics",
    text: "Journal of Phonetics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-photochemistry-and-photobiology-a-chemistry",
    text: "Journal of Photochemistry &amp; Photobiology, A: Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-photochemistry-and-photobiology-b-biology",
    text: "Journal of Photochemistry &amp; Photobiology, B: Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-photochemistry-and-photobiology-c-photochemistry-reviews",
    text: "Journal of Photochemistry &amp; Photobiology, C: Photochemistry Reviews",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-photonics-for-energy",
    text: "Journal of Photonics for Energy",
    parent: "spie-journals",
    urlone: "http://photonicsforenergy.spiedigitallibrary.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-phycology",
    text: "Journal of Phycology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291529-8817/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-physical-and-chemical-reference-data",
    text: "Journal of Physical and Chemical Reference Data",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-physical-oceanography",
    text: "Journal of Physical Oceanography",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/phoc",
    urltwo: "NA"
  },
  {
    id: "journal-of-physical-therapy-science",
    text: "Journal of Physical Therapy Science",
    parent: "NA",
    urlone: "https://www.jstage.jst.go.jp/jstage/pub/jpts/pdf/regulation_en.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-physics-a-mathematical-and-theoretical",
    text: "Journal of Physics A: Mathematical and Theoretical",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1751-8121",
    urltwo: "NA"
  },
  {
    id: "journal-of-physics-and-chemistry-of-solids",
    text: "Journal of Physics and Chemistry of Solids",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-physics-b-atomic-molecular-and-optical-physics",
    text: "Journal of Physics B: Atomic, Molecular and Optical Physics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0953-4075",
    urltwo: "NA"
  },
  {
    id: "journal-of-physics-communications",
    text: "Journal of Physics Communications",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "journal-of-physics-condensed-matter",
    text: "Journal of Physics: Condensed Matter",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0953-8984",
    urltwo: "NA"
  },
  {
    id: "journal-of-physics-conference-series",
    text: "Journal of Physics: Conference Series",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1742-6596",
    urltwo: "NA"
  },
  {
    id: "journal-of-physics-d-applied-physics",
    text: "Journal of Physics D: Applied Physics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0022-3727",
    urltwo: "NA"
  },
  {
    id: "journal-of-physics-g-nuclear-and-particle-physics",
    text: "Journal of Physics G: Nuclear and Particle Physics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0954-3899",
    urltwo: "NA"
  },
  {
    id: "journal-of-physiological-anthropology",
    text: "Journal of Physiological Anthropology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-physiology-and-biochemistry",
    text: "Journal of Physiology and Biochemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-physiology-paris",
    text: "Journal of Physiology - Paris",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-physiotherapy",
    text: "Journal of Physiotherapy",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pierre-fauchard-academy",
    text: "Journal of Pierre Fauchard Academy",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pipeline-systems-engineering-and-practice",
    text: "Journal of Pipeline Systems Engineering and Practice",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jpsea2",
    urltwo: "NA"
  },
  {
    id: "journal-of-plankton-research",
    text: "Journal of Plankton Research",
    parent: "NA",
    urlone: "https://academic.oup.com/plankt/pages/ms_prep_submission#references",
    urltwo: "NA"
  },
  {
    id: "journal-of-plant-biochemistry-and-biotechnology",
    text: "Journal of Plant Biochemistry and Biotechnology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-plant-ecology",
    text: "Journal of Plant Ecology",
    parent: "NA",
    urlone: "https://academic.oup.com/jpe/pages/General_Instructions#Literature%20Citation",
    urltwo: "NA"
  },
  {
    id: "journal-of-plant-growth-regulation",
    text: "Journal of Plant Growth Regulation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-plant-interactions",
    text: "Journal of Plant Interactions",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJPI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-plant-nutrition-and-soil-science",
    text: "Journal of Plant Nutrition and Soil Science",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291522-2624/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-plant-physiology",
    text: "Journal of Plant Physiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-plant-protection-research",
    text: "Journal of Plant Protection Research",
    parent: "NA",
    urlone: "http://www.plantprotection.pl/Instructions-for-Authors,972.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-plant-research",
    text: "Journal of Plant Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-plastic-reconstructive-and-aesthetic-surgery",
    text: "Journal of Plastic, Reconstructive &amp; Aesthetic Surgery",
    parent: "vancouver-superscript",
    urlone:
      "http://www.elsevier.com/journals/journal-of-plastic-reconstructive-and-aesthetic-surgery/1748-6815/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-poetry-therapy",
    text: "Journal of Poetry Therapy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJPT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-police-and-criminal-psychology",
    text: "Journal of Police and Criminal Psychology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-policing-intelligence-and-counter-terrorism",
    text: "Journal of Policing, Intelligence and Counter Terrorism",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPIC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-policy-modeling",
    text: "Journal of Policy Modeling",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-policy-research-in-tourism-leisure-and-events",
    text: "Journal of Policy Research in Tourism, Leisure and Events",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPRT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-political-ideologies",
    text: "Journal of Political Ideologies",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?journalCode=cjpi20&amp;page=instructions",
    urltwo: "https://www.tandf.co.uk//journals/authors/CJPI_references.pdf"
  },
  {
    id: "journal-of-political-philosophy",
    text: "Journal of Political Philosophy",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291467-9760/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-pollination-ecology",
    text: "Journal of Pollination Ecology",
    parent: "NA",
    urlone: "http://www.pollinationecology.org/user-files/JPE_Instruction%20for%20authors.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-polymer-research",
    text: "Journal of Polymer Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-polymer-science-part-a-polymer-chemistry",
    text: "Journal of Polymer Science Part A: Polymer Chemistry",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1099-0518/homepage/ForAuthors.html#anchor53",
    urltwo: "NA"
  },
  {
    id: "journal-of-polymer-science-part-b-polymer-physics",
    text: "Journal of Polymer Science Part B: Polymer Physics",
    parent: "journal-of-polymer-science-part-a-polymer-chemistry",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1099-0488/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-polymers-and-the-environment",
    text: "Journal of Polymers and the Environment",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-population-ageing",
    text: "Journal of Population Ageing",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-population-economics",
    text: "Journal of Population Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-population-research",
    text: "Journal of Population Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-porous-materials",
    text: "Journal of Porous Materials",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-porous-media",
    text: "Journal of Porous Media",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/49dcde6d4c0809db.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-porphyrins-and-phthalocyanines",
    text: "Journal of Porphyrins and Phthalocyanines",
    parent: "NA",
    urlone: "https://www.worldscientific.com/page/jpp/submission-guidelines",
    urltwo: "http://jpp.fontismedia.com/jpp/guides/Notice_to_Authors.pdf"
  },
  {
    id: "journal-of-postcolonial-writing",
    text: "Journal of Postcolonial Writing",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJPW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-postgraduate-medicine",
    text: "Journal of Postgraduate Medicine",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.jpgmonline.com/contributors.asp#Ref",
    urltwo: "NA"
  },
  {
    id: "journal-of-power-sources",
    text: "Journal of Power Sources",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pragmatics",
    text: "Journal of Pragmatics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pressure-vessel-technology",
    text: "Journal of Pressure Vessel Technology",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://pressurevesseltech.asmedigitalcollection.asme.org/journal.aspx?journalid=131",
    urltwo: "NA"
  },
  {
    id: "journal-of-primary-health-care",
    text: "Journal of Primary Health Care",
    parent: "vancouver",
    urlone: "http://www.rnzcgp.org.nz/information-for-authors/",
    urltwo: "NA"
  },
  {
    id: "journal-of-process-control",
    text: "Journal of Process Control",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-product-innovation-management",
    text: "Journal of Product Innovation Management",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291540-5885/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-productivity-analysis",
    text: "Journal of Productivity Analysis",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-professional-issues-in-engineering-education-and-practice",
    text: "Journal of Professional Issues in Engineering Education and Practice",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jpepe3",
    urltwo: "NA"
  },
  {
    id: "journal-of-professional-nursing",
    text: "Journal of Professional Nursing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-property-research",
    text: "Journal of Property Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJPR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-propulsion-and-power",
    text: "Journal of Propulsion and Power",
    parent: "american-institute-of-aeronautics-and-astronautics",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-prosthodontic-research",
    text: "Journal of Prosthodontic Research",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-proteome-research",
    text: "Journal of Proteome Research",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jprobs/jprobs_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-proteomics",
    text: "Journal of Proteomics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pseudo-differential-operators-and-applications",
    text: "Journal of Pseudo-Differential Operators and Applications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-psychiatric-and-mental-health-nursing",
    text: "Journal of Psychiatric and Mental Health Nursing",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2850/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-psychiatric-research",
    text: "Journal of Psychiatric Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-psychiatry-and-neuroscience",
    text: "Journal of Psychiatry &amp; Neuroscience",
    parent: "NA",
    urlone: "http://www.cma.ca/jpn/instructions_for_authors#refs",
    urltwo: "NA"
  },
  {
    id: "journal-of-psycholinguistic-research",
    text: "Journal of Psycholinguistic Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-psychopathology-and-behavioral-assessment",
    text: "Journal of Psychopathology and Behavioral Assessment",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-psychophysiology",
    text: "Journal of Psychophysiology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/jop",
    urltwo: "NA"
  },
  {
    id: "journal-of-psychosocial-nursing-and-mental-health-services",
    text: "Journal of Psychosocial Nursing and Mental Health Services",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/~/media/Files/Journals/General%20PDFs/JPN/JPNguidelines.ashx",
    urltwo: "NA"
  },
  {
    id: "journal-of-psychosocial-rehabilitation-and-mental-health",
    text: "Journal of Psychosocial Rehabilitation and Mental Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-psychosomatic-research",
    text: "Journal of Psychosomatic Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-psychotherapy-integration",
    text: "Journal of Psychotherapy Integration",
    parent: "apa",
    urlone: "http://content.apa.org/journals/int",
    urltwo: "NA"
  },
  {
    id: "journal-of-public-economics",
    text: "Journal of Public Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-public-health",
    text: "Journal of Public Health",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-public-policy-and-marketing",
    text: "Journal of Public Policy &amp; Marketing",
    parent: "american-marketing-association",
    urlone: "https://www.ama.org/publications/JournalOfPublicPolicyAndMarketing/Pages/JPPMAccepted.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-purchasing-and-supply-management",
    text: "Journal of Purchasing and Supply Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-pure-and-applied-algebra",
    text: "Journal of Pure and Applied Algebra",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-quantitative-criminology",
    text: "Journal of Quantitative Criminology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-quantitative-economics",
    text: "Journal of Quantitative Economics",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-quantitative-spectroscopy-and-radiative-transfer",
    text: "Journal of Quantitative Spectroscopy and Radiative Transfer",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-racial-and-ethnic-health-disparities",
    text: "Journal of Racial and Ethnic Health Disparities",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-radiation-oncology",
    text: "Journal of Radiation Oncology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-radiation-research-and-applied-sciences",
    text: "Journal of Radiation Research and Applied Sciences",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-radiation-research",
    text: "Journal of Radiation Research",
    parent: "oxford-university-press-scimed-numeric",
    urlone: "http://www.oxfordjournals.org/our_journals/jrr/for_authors/manuscript_instructions.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-radio-electronics",
    text: "Journal of Radio Electronics",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/49791ac230bedeec.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-radioanalytical-and-nuclear-chemistry",
    text: "Journal of Radioanalytical and Nuclear Chemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-radiological-protection",
    text: "Journal of Radiological Protection",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0952-4746",
    urltwo: "NA"
  },
  {
    id: "journal-of-radiology-nursing",
    text: "Journal of Radiology Nursing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-rail-transport-planning-and-management",
    text: "Journal of Rail Transport Planning &amp; Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-raman-spectroscopy",
    text: "Journal of Raman Spectroscopy",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1097-4555/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-rare-earths",
    text: "Journal of Rare Earths",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-rational-emotive-and-cognitive-behavior-therapy",
    text: "Journal of Rational-Emotive &amp; Cognitive-Behavior Therapy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-real-time-image-processing",
    text: "Journal of Real-Time Image Processing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-reconstructive-microsurgery",
    text: "Journal of Reconstructive Microsurgery",
    parent: "NA",
    urlone: "https://www.thieme.de/de/journal-reconstructive-microsurgery/authors-9770.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-refractive-surgery",
    text: "Journal of Refractive Surgery",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/journals/jrs/submit-an-article",
    urltwo: "NA"
  },
  {
    id: "journal-of-refugee-studies",
    text: "Journal of Refugee Studies",
    parent: "NA",
    urlone: "https://academic.oup.com/jrs/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-regulatory-economics",
    text: "Journal of Regulatory Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-rehabilitation-research-and-development",
    text: "Journal of Rehabilitation Research &amp; Development",
    parent: "vancouver-brackets",
    urlone: "http://www.rehab.research.va.gov/jrrd/index.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-reliable-intelligent-environments",
    text: "Journal of Reliable Intelligent Environments",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-religion-and-health",
    text: "Journal of Religion and Health",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-religious-education",
    text: "Journal of Religious Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-remanufacturing",
    text: "Journal of Remanufacturing",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-renewable-and-sustainable-energy",
    text: "Journal of Renewable and Sustainable Energy",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-reproductive-and-infant-psychology",
    text: "Journal of Reproductive and Infant Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJRI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-reproductive-health-and-medicine",
    text: "Journal of Reproductive Health and Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-reproductive-immunology",
    text: "Journal of Reproductive Immunology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-research-in-personality",
    text: "Journal of Research in Personality",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-research-in-science-teaching",
    text: "Journal of Research in Science Teaching",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291098-2736/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-retailing-and-consumer-services",
    text: "Journal of Retailing and Consumer Services",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-retailing",
    text: "Journal of Retailing",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-retailing/0022-4359/guide-for-authors#68000",
    urltwo: "https://github.com/citation-style-language/styles/pull/1175"
  },
  {
    id: "journal-of-rheumatology",
    text: "Journal of Rheumatology",
    parent: "NA",
    urlone: "http://www.jrheum.com/guideforauthors.html#References",
    urltwo: "NA"
  },
  {
    id: "journal-of-risk-and-financial-management",
    text: "Journal of Risk and Financial Management",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-risk-and-uncertainty",
    text: "Journal of Risk and Uncertainty",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-risk-research",
    text: "Journal of Risk Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJRR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-robotic-surgery",
    text: "Journal of Robotic Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-rock-mechanics-and-geotechnical-engineering",
    text: "Journal of Rock Mechanics and Geotechnical Engineering",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-roman-archaeology-a",
    text: "Journal of Roman Archaeology (A)",
    parent: "NA",
    urlone: "http://www.journalofromanarch.com/GuidelinesForAuthors.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-roman-archaeology-b",
    text: "Journal of Roman Archaeology (B)",
    parent: "NA",
    urlone: "http://www.journalofromanarch.com/GuidelinesForAuthors.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-rural-mental-health",
    text: "Journal of Rural Mental Health",
    parent: "apa",
    urlone: "http://content.apa.org/journals/rmh",
    urltwo: "NA"
  },
  {
    id: "journal-of-rural-studies",
    text: "Journal of Rural Studies",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-safety-research",
    text: "Journal of Safety Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-saudi-chemical-society",
    text: "Journal of Saudi Chemical Society",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-scheduling",
    text: "Journal of Scheduling",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-school-psychology",
    text: "Journal of School Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-science-advanced-materials-and-devices",
    text: "Journal of Science: Advanced Materials and Devices",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-science-and-medicine-in-sport",
    text: "Journal of Science and Medicine in Sport",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-science-and-medicine-in-sport/1440-2440/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-science-education-and-technology",
    text: "Journal of Science Education and Technology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-scientific-computing",
    text: "Journal of Scientific Computing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-sea-research",
    text: "Journal of Sea Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-second-language-writing",
    text: "Journal of Second Language Writing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-seismology",
    text: "Journal of Seismology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-semiconductors",
    text: "Journal of Semiconductors",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1674-4926",
    urltwo: "NA"
  },
  {
    id: "journal-of-sensor-and-actuator-networks",
    text: "Journal of Sensor and Actuator Networks",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "journal-of-sensors-and-sensor-systems",
    text: "Journal of Sensors and Sensor Systems",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-separation-science",
    text: "Journal of Separation Science",
    parent: "NA",
    urlone:
      "http://onlinelibrary.wiley.com/store/10.1002/%28ISSN%291615-9314/asset/homepages/JSS_Instructions_to_Authors_Oct_2014.pdf?v=1&amp;s=4dc7d097074f20228608d95eaee70ac06e14008a&amp;isAguDoi=false",
    urltwo: "NA"
  },
  {
    id: "journal-of-sexual-aggression",
    text: "Journal of Sexual Aggression",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJSA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-shipping-and-trade",
    text: "Journal of Shipping and Trade",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-shoulder-and-elbow-surgery",
    text: "Journal of Shoulder and Elbow Surgery",
    parent: "NA",
    urlone: "http://journals.elsevierhealth.com/periodicals/ymse/authorinfo",
    urltwo: "NA"
  },
  {
    id: "journal-of-signal-processing-systems",
    text: "Journal of Signal Processing Systems",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-simulation",
    text: "Journal of Simulation",
    parent: "NA",
    urlone: "http://www.palgrave-journals.com/jos/author_instructions.html#References",
    urltwo: "NA"
  },
  {
    id: "journal-of-sleep-research",
    text: "Journal of Sleep Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2869/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-small-animal-practice",
    text: "Journal of Small Animal Practice",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/17485827/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-small-business-and-entrepreneurship",
    text: "Journal of Small Business &amp; Entrepreneurship",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSBE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-small-business-management",
    text: "Journal of Small Business Management",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/1540627x/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-social-and-economic-development",
    text: "Journal of Social and Economic Development",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-social-archaeology",
    text: "Journal of Social Archaeology",
    parent: "NA",
    urlone: "http://www.uk.sagepub.com/msg/jsa.htm#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "journal-of-social-philosophy",
    text: "Journal of Social Philosophy",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1467-9833/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-socio-economics",
    text: "Journal of Socio-Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-software-engineering-research-and-development",
    text: "Journal of Software Engineering Research and Development",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-soil-and-water-conservation",
    text: "Journal of Soil and Water Conservation",
    parent: "NA",
    urlone: "http://www.jswconline.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "journal-of-soil-science-and-plant-nutrition",
    text: "Journal of Soil Science and Plant Nutrition",
    parent: "NA",
    urlone: "https://www.springer.com/journal/42729/submission-guidelines#Instructions%20for%20Authors_References",
    urltwo: "NA"
  },
  {
    id: "journal-of-soils-and-sediments",
    text: "Journal of Soils and Sediments",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-sol-gel-science-and-technology",
    text: "Journal of Sol-Gel Science and Technology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-solar-energy-engineering",
    text: "Journal of Solar Energy Engineering",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://solarenergyengineering.asmedigitalcollection.asme.org/journal.aspx?journalid=132",
    urltwo: "NA"
  },
  {
    id: "journal-of-solid-state-chemistry",
    text: "Journal of Solid State Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-solid-state-electrochemistry",
    text: "Journal of Solid State Electrochemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-solid-state-lighting",
    text: "Journal of Solid State Lighting",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-solution-chemistry",
    text: "Journal of Solution Chemistry",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-sound-and-vibration",
    text: "Journal of Sound and Vibration",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-south-american-earth-sciences",
    text: "Journal of South American Earth Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-spacecraft-and-rockets",
    text: "Journal of Spacecraft and Rockets",
    parent: "american-institute-of-aeronautics-and-astronautics",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-sport-and-health-science",
    text: "Journal of Sport and Health Science",
    parent: "NA",
    urlone: "http://www.jshs.org.cn/EN/column/column290.shtml",
    urltwo: "NA"
  },
  {
    id: "journal-of-sport-and-tourism",
    text: "Journal of Sport &amp; Tourism",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJTO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-sports-science-and-medicine",
    text: "Journal of Sports Science &amp; Medicine",
    parent: "NA",
    urlone: "https://www.jssm.org/newauthors.php",
    urltwo: "NA"
  },
  {
    id: "journal-of-sports-sciences",
    text: "Journal of Sports Sciences",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJSP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-statistical-computation-and-simulation",
    text: "Journal of Statistical Computation and Simulation",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GSCS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-statistical-distributions-and-applications",
    text: "Journal of Statistical Distributions and Applications",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-statistical-mechanics-theory-and-experiment",
    text: "Journal of Statistical Mechanics: Theory and Experiment",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1742-5468",
    urltwo: "NA"
  },
  {
    id: "journal-of-statistical-physics",
    text: "Journal of Statistical Physics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-statistical-planning-and-inference",
    text: "Journal of Statistical Planning and Inference",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-steroid-biochemistry-and-molecular-biology",
    text: "Journal of Steroid Biochemistry and Molecular Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-stomatology-oral-and-maxillofacial-surgery",
    text: "Journal of Stomatology oral and Maxillofacial Surgery",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-stored-products-research",
    text: "Journal of Stored Products Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-strategic-information-systems",
    text: "Journal of Strategic Information Systems",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-strategic-marketing",
    text: "Journal of Strategic Marketing",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJSM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-strength-and-conditioning-research",
    text: "Journal of Strength and Conditioning Research",
    parent: "NA",
    urlone: "http://journals.lww.com/nsca-jscr/Pages/InstructionsforAuthors.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-structural-and-functional-genomics",
    text: "Journal of Structural and Functional Genomics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-structural-biology-x",
    text: "Journal of Structural Biology: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-structural-biology",
    text: "Journal of Structural Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-structural-engineering",
    text: "Journal of Structural Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jsendh",
    urltwo: "NA"
  },
  {
    id: "journal-of-structural-geology",
    text: "Journal of Structural Geology",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/journal-of-structural-geology/0191-8141/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-studies-on-alcohol-and-drugs",
    text: "Journal of Studies on Alcohol and Drugs",
    parent: "NA",
    urlone: "http://www.jsad.com/jsad/static/instructions.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-substance-abuse-treatment",
    text: "Journal of Substance Abuse Treatment",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-sulfur-chemistry",
    text: "Journal of Sulfur Chemistry",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GSRP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-superconductivity-and-novel-magnetism",
    text: "Journal of Superconductivity and Novel Magnetism",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-surfactants-and-detergents",
    text: "Journal of Surfactants and Detergents",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-surgery-and-medicine",
    text: "Journal of Surgery and Medicine",
    parent: "NA",
    urlone: "http://dergipark.gov.tr/josam/page/3139",
    urltwo: "NA"
  },
  {
    id: "journal-of-surgical-education",
    text: "Journal of Surgical Education",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-surgical-research",
    text: "Journal of Surgical Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-surveying-engineering",
    text: "Journal of Surveying Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jsued2",
    urltwo: "NA"
  },
  {
    id: "journal-of-sustainable-cement-based-materials",
    text: "Journal of Sustainable Cement-Based Materials",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TSCM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-sustainable-finance-and-investment",
    text: "Journal of Sustainable Finance &amp; Investment",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TSFI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-sustainable-metallurgy",
    text: "Journal of Sustainable Metallurgy",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-sustainable-mining",
    text: "Journal of Sustainable Mining",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-sustainable-tourism",
    text: "Journal of Sustainable Tourism",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSUS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-sustainable-water-in-the-built-environment",
    text: "Journal of Sustainable Water in the Built Environment",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jswbay",
    urltwo: "NA"
  },
  {
    id: "journal-of-symbolic-computation",
    text: "Journal of Symbolic Computation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-synchrotron-radiation",
    text: "Journal of Synchrotron Radiation",
    parent: "international-union-of-crystallography",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-systematic-palaeontology",
    text: "Journal of Systematic Palaeontology",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/ref_tjsp.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-systems-architecture",
    text: "Journal of Systems Architecture",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-systems-engineering-and-electronics",
    text: "Journal of Systems Engineering and Electronics",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5971804",
    urltwo: "NA"
  },
  {
    id: "journal-of-taibah-university-for-science",
    text: "Journal of Taibah University for Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-terramechanics",
    text: "Journal of Terramechanics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-academy-of-marketing-science",
    text: "Journal of the Academy of Marketing Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-academy-of-nutrition-and-dietetics",
    text: "Journal of the Academy of Nutrition and Dietetics",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-acm",
    text: "Journal of the ACM",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-air-and-waste-management-association",
    text: "Journal of the Air &amp; Waste Management Association",
    parent: "NA",
    urlone: "http://www.awma.org/publications/journal/submit-a-manuscript",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-academy-of-audiology",
    text: "Journal of the American Academy of Audiology",
    parent: "NA",
    urlone: "http://www.audiology.org/publications-resources/journal-american-academy-audiology/instructions-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-academy-of-dermatology",
    text: "Journal of the American Academy of Dermatology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-academy-of-orthopaedic-surgeons",
    text: "Journal of the American Academy of Orthopaedic Surgeons",
    parent: "NA",
    urlone: "http://www.jaaos.org/site/misc/authinst.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-animal-hospital-association",
    text: "Journal of the American Animal Hospital Association",
    parent: "NA",
    urlone: "https://www.jaaha.org/page/authorinstructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-association-of-laboratory-animal-science",
    text: "Journal of the American Association of Laboratory Animal Science",
    parent: "NA",
    urlone: "http://www.aalas.org/publications/cm_jaalas_info_for_au.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-board-of-family-medicine",
    text: "Journal of the American Board of Family Medicine",
    parent: "american-medical-association",
    urlone: "http://www.jabfm.org/site/misc/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-ceramic-society",
    text: "Journal of the American Ceramic Society",
    parent: "NA",
    urlone: "https://ceramics.onlinelibrary.wiley.com/hub/authors_instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-chemical-society",
    text: "Journal of the American Chemical Society",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jacsat/jacsat_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-college-of-cardiology",
    text: "Journal of the American College of Cardiology",
    parent: "NA",
    urlone: "http://content.onlinejacc.org/misc/ifora.dtl",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-college-of-clinical-wound-specialists",
    text: "Journal of the American College of Clinical Wound Specialists",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-college-of-radiology",
    text: "Journal of the American College of Radiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-college-of-surgeons",
    text: "Journal of the American College of Surgeons",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/journal-of-the-american-college-of-surgeons/1072-7515/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-heart-association",
    text: "Journal of the American Heart Association",
    parent: "NA",
    urlone: "http://jaha.ahajournals.org/content/author-instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-oil-chemists-society",
    text: "Journal of the American Oil Chemists' Society",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-american-pharmacists-association",
    text: "Journal of the American Pharmacists Association",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-society-for-mass-spectrometry",
    text: "Journal of The American Society for Mass Spectrometry",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-american-society-of-brewing-chemists",
    text: "Journal of the American Society of Brewing Chemists",
    parent: "NA",
    urlone: "http://www.asbcnet.org/journal/author.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-society-of-cytopathology",
    text: "Journal of the American Society of Cytopathology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-society-of-echocardiography",
    text: "Journal of the American Society of Echocardiography",
    parent: "vancouver-superscript",
    urlone:
      "http://www.elsevier.com/journals/journal-of-the-american-society-of-echocardiography/0894-7317/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-society-of-nephrology",
    text: "Journal of the American Society of Nephrology",
    parent: "NA",
    urlone: "http://jasn.asnjournals.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-statistical-association",
    text: "Journal of the American Statistical Association",
    parent: "american-statistical-association",
    urlone: "http://amstat.tfjournals.com/asa-style-guide/",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-veterinary-medical-association",
    text: "Journal of the American Veterinary Medical Association",
    parent: "american-veterinary-medical-association",
    urlone: "https://www.avma.org/News/Journals/Pages/javma-manuscript-style.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-american-water-resources-association",
    text: "Journal of the American Water Resources Association",
    parent: "NA",
    urlone: "http://www.awra.org/jawra/citationexamples.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-anatomical-society-of-india",
    text: "Journal of the Anatomical Society of India",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-asia-pacific-economy",
    text: "Journal of the Asia Pacific Economy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJAP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-association-for-information-science-and-technology",
    text: "Journal of the Association for Information Science and Technology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1532-2890/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-association-for-information-systems",
    text: "Journal of the Association for Information Systems",
    parent: "NA",
    urlone: "http://aisel.aisnet.org/jais/authorinfo.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-association-for-research-in-otolaryngology",
    text: "Journal of the Association for Research in Otolaryngology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-association-for-vascular-access",
    text: "Journal of the Association for Vascular Access",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-association-of-arab-universities-for-basic-and-applied-sciences",
    text: "Journal of the Association of Arab Universities for Basic and Applied Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-association-of-environmental-and-resource-economists",
    text: "Journal of the Association of Environmental and Resource Economists",
    parent: "NA",
    urlone: "http://www.journals.uchicago.edu/journals/jaere/instruct",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-atmospheric-sciences",
    text: "Journal of the Atmospheric Sciences",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/atsc",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-botanical-research-institute-of-texas",
    text: "Journal of the Botanical Research Institute of Texas",
    parent: "NA",
    urlone: "https://www.brit.org/jbrit/guidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-brazilian-chemical-society",
    text: "Journal of the Brazilian Chemical Society",
    parent: "NA",
    urlone: "http://jbcs.sbq.org.br/",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-brazilian-computer-society",
    text: "Journal of the Brazilian Computer Society",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-brazilian-society-of-mechanical-sciences-and-engineering",
    text: "Journal of the Brazilian Society of Mechanical Sciences and Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-canadian-chiropractic-association",
    text: "Journal of the Canadian Chiropractic Association",
    parent: "vancouver-superscript",
    urlone: "http://www.jcca-online.org/SubmissionPolicy.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-chinese-advanced-materials-society",
    text: "Journal of the Chinese Advanced Materials Society",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TADM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-chinese-institute-of-engineers",
    text: "Journal of the Chinese Institute of Engineers",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TCIE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-college-of-physicians-and-surgeons-pakistan",
    text: "Journal of the College of Physicians and Surgeons Pakistan",
    parent: "vancouver",
    urlone: "http://www.jcpsp.pk/",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-economic-science-association",
    text: "Journal of the Economic Science Association",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-egyptian-mathematical-society",
    text: "Journal of the Egyptian Mathematical Society",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-egyptian-national-cancer-institute",
    text: "Journal of the Egyptian National Cancer Institute",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-egyptian-public-health-association",
    text: "Journal of the Egyptian Public Health Association",
    parent: "vancouver-superscript",
    urlone: "http://edmgr.ovid.com/jepha/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-egyptian-society-of-cardio-thoracic-surgery",
    text: "Journal of the Egyptian Society of Cardio-Thoracic Surgery",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-electrochemical-society",
    text: "Journal of The Electrochemical Society",
    parent: "NA",
    urlone: "http://www.electrochem.org/dl/support/assets/jes_inst.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-energy-institute",
    text: "Journal of the Energy Institute",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-european-academy-of-dermatology-and-venereology",
    text: "Journal of the European Academy of Dermatology and Venereology",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14683083/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-european-ceramic-society",
    text: "Journal of the European Ceramic Society",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-formosan-medical-association",
    text: "Journal of the Formosan Medical Association",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.com/journals/journal-of-the-formosan-medical-association/0929-6646/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-franklin-institute",
    text: "Journal of the Franklin Institute",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-geological-society",
    text: "Journal of the Geological Society",
    parent: "the-geological-society-of-london",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-history-of-collections",
    text: "Journal of the History of Collections",
    parent: "NA",
    urlone: "https://academic.oup.com/jhc/pages/general_instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-indian-academy-of-wood-science",
    text: "Journal of the Indian Academy of Wood Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-indian-law-institute",
    text: "Journal of the Indian Law Institute",
    parent: "NA",
    urlone: "http://www.ili.ac.in/cstyle.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-indian-ocean-region",
    text: "Journal of the Indian Ocean Region",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIOR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-indian-society-for-probability-and-statistics",
    text: "Journal of the Indian Society for Probability and Statistics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-indian-society-of-pedodontics-and-preventive-dentistry",
    text: "Journal of the Indian Society of Pedodontics &amp; Preventive Dentistry",
    parent: "vancouver-brackets",
    urlone: "http://www.jisppd.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-indian-society-of-remote-sensing",
    text: "Journal of the Indian Society of Remote Sensing",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-institution-of-engineers-series-a",
    text: "Journal of The Institution of Engineers: Series A",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-institution-of-engineers-series-b",
    text: "Journal of The Institution of Engineers: Series B",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-institution-of-engineers-series-c",
    text: "Journal of The Institution of Engineers: Series C",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-institution-of-engineers-series-d",
    text: "Journal of The Institution of Engineers: Series D",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-institution-of-engineers-series-e",
    text: "Journal of The Institution of Engineers: Series E",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-international-aids-society",
    text: "Journal of the International AIDS Society",
    parent: "vancouver-brackets",
    urlone: "http://www.jiasociety.org/index.php/jias/about/submissions#references",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-international-society-of-sports-nutrition",
    text: "Journal of the International Society of Sports Nutrition",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-iranian-chemical-society",
    text: "Journal of the Iranian Chemical Society",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-japanese-and-international-economies",
    text: "Journal of The Japanese and International Economies",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-knowledge-economy",
    text: "Journal of the Knowledge Economy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-korean-society-for-applied-biological-chemistry",
    text: "Journal of the Korean Society for Applied Biological Chemistry",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-korean-society-of-civil-engineers",
    text: "Journal of the Korean Society of Civil Engineers",
    parent: "NA",
    urlone: "http://www.kscejournal.or.kr/plugins/sites/ksce/volume.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-korean-statistical-society",
    text: "Journal of the Korean Statistical Society",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-marine-biological-association-of-the-united-kingdom",
    text: "Journal of the Marine Biological Association of the United Kingdom",
    parent: "NA",
    urlone: "http://assets.cambridge.org/MBI/MBI_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-mechanical-behavior-of-biomedical-materials",
    text: "Journal of the Mechanical Behavior of Biomedical Materials",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-mechanics-and-physics-of-solids",
    text: "Journal of the Mechanics and Physics of Solids",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-medical-library-association",
    text: "Journal of the Medical Library Association",
    parent: "vancouver-brackets-no-et-al",
    urlone: "http://www.mlanet.org/publications/jmla/jmlainfo.html#ref_style",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-national-cancer-institute",
    text: "Journal of the National Cancer Institute",
    parent: "american-medical-association",
    urlone: "http://www.oxfordjournals.org/our_journals/jnci/for_authors/ms_prep.html#specificrequirements",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-national-medical-association",
    text: "Journal of the National Medical Association",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-neurological-sciences",
    text: "Journal of the Neurological Sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-nigerian-mathematical-society",
    text: "Journal of the Nigerian Mathematical Society",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-operations-research-society-of-china",
    text: "Journal of the Operations Research Society of China",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-the-optical-society-of-america-a",
    text: "Journal of the Optical Society of America A",
    parent: "the-optical-society",
    urlone: "https://www.osapublishing.org/submit/templates/default.cfm",
    urltwo: "https://www.osapublishing.org/submit/style/oestyleguide.cfm"
  },
  {
    id: "journal-of-the-optical-society-of-america-b",
    text: "Journal of the Optical Society of America B",
    parent: "the-optical-society",
    urlone: "https://www.osapublishing.org/submit/templates/default.cfm",
    urltwo: "https://www.osapublishing.org/submit/style/oestyleguide.cfm"
  },
  {
    id: "journal-of-the-philosophy-of-sport",
    text: "Journal of the Philosophy of Sport",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJPS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-royal-anthropological-institute",
    text: "Journal of the Royal Anthropological Institute",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1467-9655/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-royal-army-medical-corps",
    text: "Journal of the Royal Army Medical Corps",
    parent: "bmj",
    urlone: "http://jramc.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-royal-society-interface",
    text: "Journal of the Royal Society Interface",
    parent: "proceedings-of-the-royal-society-b",
    urlone: "https://royalsociety.org/journals/authors/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-royal-society-of-medicine",
    text: "Journal of the Royal Society of Medicine",
    parent: "vancouver-superscript",
    urlone: "http://jrsm.rsmjournals.com/site/misc/authors.xhtml",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-royal-society-of-new-zealand",
    text: "Journal of the Royal Society of New Zealand",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "https://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=tnzr20#refs",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-royal-society-of-western-australia",
    text: "Journal of the Royal Society of Western Australia",
    parent: "NA",
    urlone: "https://www.rswa.org.au/publications/RSWA%20Guide%20to%20authors%202019.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-royal-statistical-society",
    text: "Journal of the Royal Statistical Society",
    parent: "NA",
    urlone: "https://rss.onlinelibrary.wiley.com/hub/journal/1467985x/author-guidelines",
    urltwo: "https://wol-prod-cdn.literatumonline.com/pb-assets/hub-assets/rss/Notes_for_Authors_2019-1552404816963.pdf"
  },
  {
    id: "journal-of-the-saudi-heart-association",
    text: "Journal of the Saudi Heart Association",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-saudi-society-of-agricultural-sciences",
    text: "Journal of the Saudi Society of Agricultural Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-saudi-society-of-dermatology-and-dermatologic-surgery",
    text: "Journal of the Saudi Society of Dermatology &amp; Dermatologic Surgery",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-science-of-food-and-agriculture",
    text: "Journal of the Science of Food and Agriculture",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/10970010/homepage/forauthors.html#preparing",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-serbian-chemical-society",
    text: "Journal of the Serbian Chemical Society",
    parent: "NA",
    urlone: "http://www.shd-pub.org.rs/index.php/JSCS/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-society-of-archivists",
    text: "Journal of the Society of Archivists",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/toc/cjsa20/current#.U3qu_C_Wr7A",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-south-african-veterinary-association",
    text: "Journal of the South African Veterinary Association",
    parent: "NA",
    urlone: "http://aosis.co.za/documents/Harvard_Reference_Style_Guide.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-taiwan-institute-of-chemical-engineers",
    text: "Journal of the Taiwan Institute of Chemical Engineers",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-textile-institute",
    text: "Journal of The Textile Institute",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TJTI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-warburg-and-courtauld-institutes",
    text: "Journal of the Warburg and Courtauld Institutes",
    parent: "NA",
    urlone: "https://warburg.sas.ac.uk/publications/journal-warburg-and-courtauld-institutes",
    urltwo: "NA"
  },
  {
    id: "journal-of-the-world-federation-of-orthodontists",
    text: "Journal of the World Federation of Orthodontists",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-theoretical-and-applied-physics",
    text: "Journal of Theoretical and Applied Physics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-theoretical-and-philosophical-psychology",
    text: "Journal of Theoretical and Philosophical Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/teo",
    urltwo: "NA"
  },
  {
    id: "journal-of-theoretical-biology",
    text: "Journal of Theoretical Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-theoretical-probability",
    text: "Journal of Theoretical Probability",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-therapeutic-ultrasound",
    text: "Journal of Therapeutic Ultrasound",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-thermal-analysis-and-calorimetry",
    text: "Journal of Thermal Analysis and Calorimetry",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-thermal-biology",
    text: "Journal of Thermal Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-thermal-science-and-engineering-applications",
    text: "Journal of Thermal Science and Engineering Applications",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://thermalscienceapplication.asmedigitalcollection.asme.org/journal.aspx?journalid=133",
    urltwo: "NA"
  },
  {
    id: "journal-of-thermal-spray-technology",
    text: "Journal of Thermal Spray Technology",
    parent: "NA",
    urlone:
      "http://www.springer.com/cda/content/document/cda_downloaddocument/JTST+Author+Guidelines.pdf?SGWID=0-0-45-1510268-p173658292",
    urltwo: "NA"
  },
  {
    id: "journal-of-thermophysics-and-heat-transfer",
    text: "Journal of Thermophysics and Heat Transfer",
    parent: "american-institute-of-aeronautics-and-astronautics",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-thoracic-oncology",
    text: "Journal of Thoracic Oncology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-threat-assessment-and-management",
    text: "Journal of Threat Assessment and Management",
    parent: "apa",
    urlone: "http://content.apa.org/journals/tam",
    urltwo: "NA"
  },
  {
    id: "journal-of-threatened-taxa",
    text: "Journal of Threatened Taxa",
    parent: "NA",
    urlone: "https://www.threatenedtaxa.org/index.php/JoTT/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-thrombosis-and-haemostasis",
    text: "Journal of Thrombosis and Haemostasis",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291538-7836/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-thrombosis-and-thrombolysis",
    text: "Journal of Thrombosis and Thrombolysis",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-tissue-viability",
    text: "Journal of Tissue Viability",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-tourism-and-cultural-change",
    text: "Journal of Tourism and Cultural Change",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RTCC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-trace-elements-in-medicine-and-biology",
    text: "Journal of Trace Elements in Medicine and Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-traditional-and-complementary-medicine",
    text: "Journal of Traditional and Complementary Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-traditional-chinese-medical-sciences",
    text: "Journal of Traditional Chinese Medical Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-traditional-chinese-medicine",
    text: "Journal of Traditional Chinese Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-traffic-and-transportation-engineering",
    text: "Journal of Traffic and Transportation Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-translational-autoimmunity",
    text: "Journal of Translational Autoimmunity",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-translational-medicine",
    text: "Journal of Translational Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-transport-and-health",
    text: "Journal of Transport &amp; Health",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-transport-geography",
    text: "Journal of Transport Geography",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-transportation-engineering",
    text: "Journal of Transportation Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jtpedi",
    urltwo: "NA"
  },
  {
    id: "journal-of-transportation-security",
    text: "Journal of Transportation Security",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-trauma-management-and-outcomes",
    text: "Journal of Trauma Management &amp; Outcomes",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-tribology",
    text: "Journal of Tribology",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://tribology.asmedigitalcollection.asme.org/journal.aspx?journalid=134",
    urltwo: "NA"
  },
  {
    id: "journal-of-tropical-ecology",
    text: "Journal of Tropical Ecology",
    parent: "NA",
    urlone: "http://journals.cambridge.org/images/fileUpload/documents/tro_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "journal-of-tropical-life-science",
    text: "Journal of Tropical Life Science",
    parent: "NA",
    urlone: "http://www.jtrolis.ub.ac.id/index.php/jtrolis/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-trust-management",
    text: "Journal of Trust Management",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-trust-research",
    text: "Journal of Trust Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJTR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-turbomachinery",
    text: "Journal of Turbomachinery",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://turbomachinery.asmedigitalcollection.asme.org/journal.aspx?journalid=135",
    urltwo: "NA"
  },
  {
    id: "journal-of-uncertainty-analysis-and-applications",
    text: "Journal of Uncertainty Analysis and Applications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-unconventional-oil-and-gas-resources",
    text: "Journal of Unconventional Oil and Gas Resources",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-universal-computer-science",
    text: "Journal of Universal Computer Science",
    parent: "NA",
    urlone: "http://www.jucs.org/ujs/jucs/info/submissions/style_guide.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-unmanned-vehicle-systems",
    text: "Journal of Unmanned Vehicle Systems",
    parent: "canadian-journal-of-fisheries-and-aquatic-sciences",
    urlone: "http://www.nrcresearchpress.com/page/juvs/authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-urban-and-environmental-engineering",
    text: "Journal of Urban and Environmental Engineering",
    parent: "NA",
    urlone: "http://periodicos.ufpb.br/ojs2/index.php/juee/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-urban-design",
    text: "Journal of Urban Design",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJUD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-urban-economics",
    text: "Journal of Urban Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-urban-health",
    text: "Journal of Urban Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-urban-management",
    text: "Journal of Urban Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-urban-planning-and-development",
    text: "Journal of Urban Planning and Development",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jupddm",
    urltwo: "NA"
  },
  {
    id: "journal-of-urban-technology",
    text: "Journal of Urban Technology",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=cjut20&amp;#style",
    urltwo: "https://www.tandf.co.uk//journals/authors/style/layout/style_cjut.pdf"
  },
  {
    id: "journal-of-urbanism-international-research-on-placemaking-and-urban-sustainability",
    text: "Journal of Urbanism: International Research on Placemaking and Urban Sustainability",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJOU20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-vacuum-science-and-technology-a-vacuum-surfaces-and-films",
    text: "Journal of Vacuum Science &amp; Technology A: Vacuum, Surfaces, and Films",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-vacuum-science-and-technology-b-microelectronics-and-nanometer-structures",
    text: "Journal of Vacuum Science &amp; Technology B: Microelectronics and Nanometer Structures",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-value-inquiry",
    text: "The Journal of Value Inquiry",
    parent: "NA",
    urlone: "http://www.springer.com/philosophy/philosophical+traditions/journal/10790",
    urltwo:
      "http://www.springer.com/cda/content/document/cda_downloaddocument/INQU_Style+Guidelines+Revised.pdf?SGWID=0-0-45-1416553-p35668873"
  },
  {
    id: "journal-of-vascular-research",
    text: "Journal of Vascular Research",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0135",
    urltwo: "NA"
  },
  {
    id: "journal-of-vascular-surgery-cases-and-innovative-techniques",
    text: "Journal of Vascular Surgery Cases and Innovative Techniques",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-vascular-surgery",
    text: "Journal of Vascular Surgery",
    parent: "vancouver-superscript",
    urlone: "https://www.editorialmanager.com/jvs/account/Information%20for%20authors.html#13",
    urltwo: "NA"
  },
  {
    id: "journal-of-vegetation-science",
    text: "Journal of Vegetation Science",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1654-1103/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-venomous-animals-and-toxins-including-tropical-diseases",
    text: "Journal of Venomous Animals and Toxins including Tropical Diseases",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-vertebrate-biology",
    text: "Journal of Vertebrate Biology",
    parent: "NA",
    urlone: "https://www.jvertbiol.cz/submission.php#literature",
    urltwo: "NA"
  },
  {
    id: "journal-of-vertebrate-paleontology",
    text: "Journal of Vertebrate Paleontology",
    parent: "NA",
    urlone: "http://vertpaleo.org/Publications/Journal-of-Vertebrate-Paleontology/Information-for-Authors.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-veterinary-behavior",
    text: "Journal of Veterinary Behavior",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-veterinary-cardiology",
    text: "Journal of Veterinary Cardiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-veterinary-diagnostic-investigation",
    text: "Journal of Veterinary Diagnostic Investigation",
    parent: "NA",
    urlone:
      "https://uk.sagepub.com/en-gb/eur/journal-of-veterinary-diagnostic-investigation/journal202058#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-veterinary-internal-medicine",
    text: "Journal of Veterinary Internal Medicine",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291939-1676/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-vibration-and-acoustics",
    text: "Journal of Vibration and Acoustics",
    parent: "american-society-of-mechanical-engineers",
    urlone: "http://vibrationacoustics.asmedigitalcollection.asme.org/journal.aspx?journalid=136",
    urltwo: "NA"
  },
  {
    id: "journal-of-virological-methods",
    text: "Journal of Virological Methods",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-virology",
    text: "Journal of Virology",
    parent: "american-society-for-microbiology",
    urlone: "http://jvi.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "journal-of-visceral-surgery",
    text: "Journal of Visceral Surgery",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-vision",
    text: "Journal of Vision",
    parent: "apa",
    urlone: "http://www.journalofvision.org/site/misc/peer_review.xhtml#AR",
    urltwo: "NA"
  },
  {
    id: "journal-of-visual-communication-and-image-representation",
    text: "Journal of Visual Communication and Image Representation",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-visual-languages-and-computing",
    text: "Journal of Visual Languages and Computing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-visualization",
    text: "Journal of Visualization",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-visualized-experiments",
    text: "Journal of Visualized Experiments",
    parent: "NA",
    urlone: "https://www.jove.com/author",
    urltwo: "NA"
  },
  {
    id: "journal-of-vocational-behavior",
    text: "Journal of Vocational Behavior",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-vocational-education-and-training",
    text: "Journal of Vocational Education &amp; Training",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJVE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-voice",
    text: "Journal of Voice",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-volcanology-and-geothermal-research",
    text: "Journal of Volcanology and Geothermal Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-water-process-engineering",
    text: "Journal of Water Process Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-water-resources-planning-and-management",
    text: "Journal of Water Resources Planning and Management",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jwrmd5",
    urltwo: "NA"
  },
  {
    id: "journal-of-water-sanitation-and-hygiene-for-development",
    text: "Journal of Water Sanitation and Hygiene for Development",
    parent: "NA",
    urlone: "http://washdev.iwaponline.com/content/instructions-authors",
    urltwo: "NA"
  },
  {
    id: "journal-of-waterway-port-coastal-and-ocean-engineering",
    text: "Journal of Waterway, Port, Coastal, and Ocean Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/jwped5",
    urltwo: "NA"
  },
  {
    id: "journal-of-web-semantics",
    text: "Journal of Web Semantics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-wildlife-diseases",
    text: "Journal of Wildlife Diseases",
    parent: "NA",
    urlone: "http://www.wildlifedisease.org/wda/PUBLICATIONS/JournalofWildlifeDiseases/OnlineSubmissionandReview.aspx",
    urltwo: "NA"
  },
  {
    id: "journal-of-wind-engineering-and-industrial-aerodynamics",
    text: "Journal of Wind Engineering &amp; Industrial Aerodynamics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-wine-research",
    text: "Journal of Wine Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJWR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-women-and-minorities-in-science-and-engineering",
    text: "Journal of Women and Minorities in Science and Engineering",
    parent: "begell-house-apa",
    urlone: "http://dl.begellhouse.com/forauthors/journals/00551c876cc2f027.html",
    urltwo: "NA"
  },
  {
    id: "journal-of-wood-science",
    text: "Journal of Wood Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-world-business",
    text: "Journal of World Business",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-world-prehistory",
    text: "Journal of World Prehistory",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-world-systems-research",
    text: "Journal of World Systems Research",
    parent: "american-sociological-association",
    urlone: "http://www.jwsr.org/",
    urltwo: "NA"
  },
  {
    id: "journal-of-wound-ostomy-and-continence-nursing",
    text: "Journal of Wound, Ostomy and Continence Nursing",
    parent: "vancouver",
    urlone: "http://edmgr.ovid.com/jwocn/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "journal-of-young-pharmacists",
    text: "Journal of Young Pharmacists",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "journal-of-youth-and-adolescence",
    text: "Journal of Youth and Adolescence",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-of-youth-studies",
    text: "Journal of Youth Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJYS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journal-of-zoo-and-wildlife-medicine",
    text: "Journal of Zoo and Wildlife Medicine",
    parent: "NA",
    urlone: "http://www.bioone.org/doi/pdf/10.1638/1042-7260-44.4.1147",
    urltwo: "NA"
  },
  {
    id: "journal-of-zoo-biology",
    text: "Journal of Zoo Biology",
    parent: "NA",
    urlone: "http://www.escijournals.net/JZB/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "journal-of-zoology",
    text: "Journal of Zoology",
    parent: "NA",
    urlone: "https://zslpublications.onlinelibrary.wiley.com/hub/journal/14697998/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "journal-on-computing-and-cultural-heritage",
    text: "Journal on Computing and Cultural Heritage",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "journal-on-data-semantics",
    text: "Journal on Data Semantics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journal-on-efficiency-and-responsibility-in-education-and-science",
    text: "Journal on Efficiency and Responsibility in Education and Science",
    parent: "NA",
    urlone: "https://www.eriesjournal.com/index.php/eries/about/submissions",
    urltwo: "NA"
  },
  {
    id: "journal-on-emerging-technologies-in-computing-systems",
    text: "ACM Journal on Emerging Technologies in Computing Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "journal-on-multimodal-user-interfaces",
    text: "Journal on Multimodal User Interfaces",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "journalism-practice",
    text: "Journalism Practice",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJOP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journalism-studies",
    text: "Journalism Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJOS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "journalistica",
    text: "Journalistica (Danish)",
    parent: "NA",
    urlone: "http://ojs.statsbiblioteket.dk/index.php/journalistica/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "jphys-complexity",
    text: "JPhys Complexity",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "jphys-energy",
    text: "JPhys Energy",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "jphys-materials",
    text: "JPhys Materials",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "jphys-photonics",
    text: "JPhys Photonics",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "jpras-open",
    text: "JPRAS Open",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "juristische-schulung",
    text: "Juristische Schulung (German)",
    parent: "NA",
    urlone: "http://rsw.beck.de/rsw/upload/JuS/jusautorenhinweise.pdf",
    urltwo: "NA"
  },
  {
    id: "juristische-zitierweise-offentliches-recht",
    text: "Juristische Zitierweise - Ã–ffentliches Recht (German)",
    parent: "NA",
    urlone: "https://maximilianpetras.de/2020/07/22/zitierstil/",
    urltwo: "NA"
  },
  {
    id: "juristische-zitierweise-schweizer",
    text: "Juristische Zitierweise Schweizer (Ryser BÃ¼schi et al.) (German - Switzerland)",
    parent: "NA",
    urlone: "http://www.worldcat.org/oclc/1013162746",
    urltwo: "NA"
  },
  {
    id: "juristische-zitierweise",
    text: "Juristische Zitierweise (StÃ¼ber) (German)",
    parent: "NA",
    urlone: "www.niederle-media.de/Zitieren.pdf",
    urltwo: "NA"
  },
  {
    id: "jurnal-elektrik",
    text: "Jurnal ELEKTRIK (Indonesian)",
    parent: "politeknik-negeri-manado-jurnal-p3m",
    urlone: "http://p3m.polimdo.ac.id/jurnal/index.php/ELEKTRIK/information/authors",
    urltwo: "NA"
  },
  {
    id: "jurnal-ilmu-dan-teknologi-hasil-ternak",
    text: "Jurnal Ilmu dan Teknologi Hasil Ternak",
    parent: "NA",
    urlone: "http://jitek.ub.ac.id/index.php/jitek/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "jurnal-masina-nipake",
    text: "Jurnal Masina Nipake (Indonesian)",
    parent: "politeknik-negeri-manado-jurnal-p3m",
    urlone: "http://p3m.polimdo.ac.id/jurnal/index.php/MASINA-NIPAKE/about/submissions",
    urltwo: "NA"
  },
  {
    id: "jurnal-pangan-dan-agroindustri",
    text: "Jurnal Pangan dan Agroindustri (Indonesian)",
    parent: "NA",
    urlone: "http://jpa.ub.ac.id/index.php/jpa/about/submissions#onlineSubmissions",
    urltwo: "NA"
  },
  {
    id: "jurnal-sains-farmasi-dan-klinis",
    text: "Jurnal Sains Farmasi &amp; Klinis",
    parent: "NA",
    urlone: "http://jsfk.ffarmasi.unand.ac.id/index.php/jsfk/pages/view/reference",
    urltwo: "NA"
  },
  {
    id: "jurnal-teknologi-infrastruktur-berkelanjutan",
    text: "Jurnal Teknologi Infrastruktur Berkelanjutan (Indonesian)",
    parent: "politeknik-negeri-manado-jurnal-p3m",
    urlone: "http://p3m.polimdo.ac.id/jurnal/index.php/JTIB/about/submissions#references",
    urltwo: "NA"
  },
  {
    id: "justice-quarterly",
    text: "Justice Quarterly",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJQY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "jyvaskylan-yliopisto-kemian-laitos",
    text: "JyvÃ¤skylÃ¤n yliopisto - Kemian laitos (Finnish)",
    parent: "NA",
    urlone: "https://www.jyu.fi/science/fi/kemia/opiskelu/linkkeja-ja-materiaalia/opinnaytetyon-kirjoitusohje-2017.pdf",
    urltwo: "NA"
  },
  {
    id: "kaohsiung-journal-of-medical-sciences",
    text: "Kaohsiung Journal of Medical Sciences",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "karabuk-university-graduate-school-of-natural-and-applied-sciences",
    text: "Karabuk University - Graduate School of Natural and Applied Sciences",
    parent: "NA",
    urlone: "http://fbe.karabuk.edu.tr/yuklenen/files/Ornek-Tez-dr.docx",
    urltwo: "NA"
  },
  {
    id: "karbala-international-journal-of-modern-science",
    text: "Karbala International Journal of Modern Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "kardiologie-up2date",
    text: "Kardiologie up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "karger-journals-author-date",
    text: "Karger journals (author-date)",
    parent: "NA",
    urlone: "http://www.karger.com/Journal/Guidelines/224213#04",
    urltwo: "NA"
  },
  {
    id: "karger-journals",
    text: "Karger journals",
    parent: "NA",
    urlone: "http://www.karger.com/Journal/Guidelines/223832#05",
    urltwo: "NA"
  },
  {
    id: "karlstad-universitet-harvard",
    text: "Karlstad Universitet - Harvard (Swedish)",
    parent: "NA",
    urlone: "https://www.libguides.kau.se/harvard",
    urltwo: "NA"
  },
  {
    id: "karstenia",
    text: "Karstenia",
    parent: "NA",
    urlone: "http://karstenia.fi/instructions-to-authors/#Manuscript%20preparation",
    urltwo: "NA"
  },
  {
    id: "kasetsart-journal-of-social-sciences",
    text: "Kasetsart Journal of Social Sciences",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "keel-ja-kirjandus",
    text: "Keel ja Kirjandus (Estonian)",
    parent: "NA",
    urlone: "et",
    urltwo: "NA"
  },
  {
    id: "kerala-journal-of-ophthalmology",
    text: "Kerala Journal of Ophthalmology",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.kjophthal.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "ki-kunstliche-intelligenz",
    text: "KI - KÃ¼nstliche Intelligenz",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "kidney-and-blood-pressure-research",
    text: "Kidney &amp; Blood Pressure Research",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0143",
    urltwo: "NA"
  },
  {
    id: "kidney-disease-and-transplantation",
    text: "Kidney Disease and Transplantation",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "kidney-international-supplements",
    text: "Kidney International Supplements",
    parent: "american-medical-association",
    urlone: "http://www.nature.com/kisup",
    urltwo: "NA"
  },
  {
    id: "kidney-international",
    text: "Kidney International",
    parent: "american-medical-association",
    urlone: "https://www.kidney-international.org/content/authorinfo#idp1849328",
    urltwo: "NA"
  },
  {
    id: "kidney-research-and-clinical-practice",
    text: "Kidney Research and Clinical Practice",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/kidney-research-and-clinical-practice/2211-9132/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "kind-and-adolescent",
    text: "Kind &amp; Adolescent (Dutch)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "kindheit-und-entwicklung",
    text: "Kindheit und Entwicklung",
    parent: "NA",
    urlone: "http://www.hogrefe.de/zeitschriften/kindheit-und-entwicklung/",
    urltwo: "NA"
  },
  {
    id: "kinesitherapie-la-revue",
    text: "KinÃ©sithÃ©rapie, la revue",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "klinische-monatsblatter-fur-augenheilkunde",
    text: "Klinische MonatsblÃ¤tter fÃ¼r Augenheilkunde",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "klinische-neurophysiologie",
    text: "Klinische Neurophysiologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "klinische-padiatrie",
    text: "Klinische PÃ¤diatrie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "knee-surgery-and-related-research",
    text: "Knee Surgery &amp; Related Research",
    parent: "NA",
    urlone: "http://www.jksrr.org/authors/sub01.html",
    urltwo: "NA"
  },
  {
    id: "knee-surgery-sports-traumatology-arthroscopy",
    text: "Knee Surgery, Sports Traumatology, Arthroscopy",
    parent: "NA",
    urlone: "http://www.kssta.org/index.php?id=42",
    urltwo: "NA"
  },
  {
    id: "knowledge-and-information-systems",
    text: "Knowledge and Information Systems",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "knowledge-and-management-of-aquatic-ecosystems",
    text: "Knowledge &amp; Management of Aquatic Ecosystems",
    parent: "NA",
    urlone: "https://www.kmae-journal.org/author-information/instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "knowledge-based-systems",
    text: "Knowledge-Based Systems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "kolner-zeitschrift-fur-soziologie-und-sozialpsychologie",
    text: "KÃ¶lner Zeitschrift fÃ¼r Soziologie und Sozialpsychologie (German)",
    parent: "NA",
    urlone: "http://kzfss.uni-koeln.de/die-zeitschrift/konventionen/#accordion-2-t1",
    urltwo: "NA"
  },
  {
    id: "kommunikation-und-recht",
    text: "Kommunikation und Recht (German)",
    parent: "NA",
    urlone: "http://online.ruw.de/news/media/1/Autorenhinweise-KR-Aufstze-6032.pdf",
    urltwo: "http://online.ruw.de/news/media/1/Autorenhinweise-KR-Rechtsprechungskommentar-6136.pdf"
  },
  {
    id: "kona-powder-and-particle-journal",
    text: "KONA Powder and Particle Journal",
    parent: "NA",
    urlone: "http://www.kona.or.jp/jp/journal/info.html",
    urltwo: "NA"
  },
  {
    id: "kontakt",
    text: "KONTAKT",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "korean-journal-of-anesthesiology",
    text: "Korean Journal of Anesthesiology",
    parent: "NA",
    urlone: "http://ekja.org/index.php?body=instruction",
    urltwo: "NA"
  },
  {
    id: "korean-journal-of-radiology",
    text: "Korean Journal of Radiology",
    parent: "NA",
    urlone: "https://www.kjronline.org/index.php?body=Instruction",
    urltwo: "NA"
  },
  {
    id: "korean-social-science-journal",
    text: "Korean Social Science Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "krankenhaushygiene-up2date",
    text: "Krankenhaushygiene up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "kritische-ausgabe",
    text: "Kritische Ausgabe (German)",
    parent: "NA",
    urlone: "http://kritische-ausgabe.de/",
    urltwo: "NA"
  },
  {
    id: "ksce-journal-of-civil-engineering",
    text: "KSCE Journal of Civil Engineering",
    parent: "NA",
    urlone: "http://www.springer.com/engineering/civil+engineering/journal/12205",
    urltwo: "NA"
  },
  {
    id: "kth-royal-institute-of-technology-school-of-computer-science-and-communication-sv",
    text: "KTH Royal Institute of Technology - School of Computer Science and Communication (Swedish)",
    parent: "NA",
    urlone: "http://www.nada.kth.se/utbildning/grukth/exjobb/dokument/rapportmall.doc",
    urltwo: "NA"
  },
  {
    id: "kth-royal-institute-of-technology-school-of-computer-science-and-communication",
    text: "KTH Royal Institute of Technology - School of Computer Science and Communication",
    parent: "NA",
    urlone: "http://www.nada.kth.se/utbildning/grukth/exjobb/dokument/rapportmalleng.doc",
    urltwo: "NA"
  },
  {
    id: "kunstakademie-munster",
    text: "Kunstakademie MÃ¼nster (German)",
    parent: "NA",
    urlone:
      "https://www.kunstakademie-muenster.de/fileadmin/media/pdf/Studienordnungen/Allgemeine_Unterlagen/Infomappe_Leitfaden_wissenschaftliches_Arbeiten_201900605.pdf",
    urltwo: "NA"
  },
  {
    id: "l-homme",
    text: "L'homme &#8211; Revue franÃ§aise d'anthropologie",
    parent: "NA",
    urlone: "http://www.editions.ehess.fr/revues/lhomme/",
    urltwo: "NA"
  },
  {
    id: "la-presse-medicale",
    text: "La Presse MÃ©dicale",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "la-radiologia-medica",
    text: "La radiologia medica",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "la-revue-de-linfirmiere",
    text: "La revue de l'infirmiÃ¨re",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "la-revue-de-medecine-interne",
    text: "La revue de mÃ©decine interne",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "la-revue-de-medecine-legale",
    text: "La revue de mÃ©decine lÃ©gale",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "la-revue-de-sante-scolaire-et-universitaire",
    text: "La revue de santÃ© scolaire et universitaire",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "la-revue-dhomeopathie",
    text: "La Revue d'HomÃ©opathie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "la-revue-gestion-et-organisation",
    text: "La Revue Gestion et Organisation",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "la-revue-sage-femme",
    text: "La Revue Sage-Femme",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "la-rivista-italiana-della-medicina-di-laboratorio-italian-journal-of-laboratory-medicine",
    text: "La Rivista Italiana della Medicina di Laboratorio - Italian Journal of Laboratory Medicine (Italian)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "la-trobe-university-apa",
    text: "La Trobe University - APA 6th edition",
    parent: "NA",
    urlone: "http://www.lib.latrobe.edu.au/referencing-tool/apa-6",
    urltwo: "NA"
  },
  {
    id: "la-trobe-university-harvard",
    text: "La Trobe University - Harvard",
    parent: "NA",
    urlone: "http://www.lib.latrobe.edu.au/referencing-tool/harvard",
    urltwo: "NA"
  },
  {
    id: "lab-animal",
    text: "Lab Animal",
    parent: "nature",
    urlone: "http://www.labanimal.com/laban/authors/index.html#format",
    urltwo: "NA"
  },
  {
    id: "lab-on-a-chip",
    text: "Lab on a Chip",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "labor-history",
    text: "Labor History",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CLAH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "laboratory-animal-science-professional",
    text: "Laboratory Animal Science Professional",
    parent: "NA",
    urlone: "https://www.aalas.org/publications/information-for-authors/las-pro",
    urltwo: "NA"
  },
  {
    id: "laboratory-investigation",
    text: "Laboratory Investigation",
    parent: "nature",
    urlone: "http://www.nature.com/labinvest/",
    urltwo: "NA"
  },
  {
    id: "labour-economics",
    text: "Labour Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "laide-soignante",
    text: "L'aide-soignante",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "lakartidningen",
    text: "LÃ¤kartidningen",
    parent: "vancouver-brackets",
    urlone: "http://www.lakartidningen.se",
    urltwo: "NA"
  },
  {
    id: "lancaster-university-harvard",
    text: "Lancaster University - Harvard",
    parent: "NA",
    urlone: "http://lancaster.libguides.com/harvard/",
    urltwo: "https://lancaster.libguides.com/ld.php?content_id=32483134"
  },
  {
    id: "land-degradation-and-development",
    text: "Land Degradation &amp; Development",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1099-145X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "land-use-policy",
    text: "Land Use Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "land",
    text: "Land",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "landes-bioscience-journals",
    text: "Landes Bioscience Journals",
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "http://www.landesbioscience.com/journals/intravital/guidelines/"
  },
  {
    id: "landscape-and-ecological-engineering",
    text: "Landscape and Ecological Engineering",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "landscape-and-urban-planning",
    text: "Landscape and Urban Planning",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "landscape-ecology",
    text: "Landscape Ecology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "landscape-research",
    text: "Landscape Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CLAR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "landslides",
    text: "Landslides",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "langenbecks-archives-of-surgery",
    text: "Langenbeck's Archives of Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "langmuir",
    text: "Langmuir",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/langd5/langd5_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "language-and-cognitive-processes",
    text: "Language and Cognitive Processes",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PLCP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "language-and-communication",
    text: "Language and Communication",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "language-and-education",
    text: "Language and Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RLAE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "language-and-intercultural-communication",
    text: "Language and Intercultural Communication",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMLI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "language-awareness",
    text: "Language Awareness",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMLA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "language-culture-and-curriculum",
    text: "Language, Culture and Curriculum",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RLCC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "language-in-society",
    text: "Language in Society",
    parent: "NA",
    urlone: "http://assets.cambridge.org/LSY/LSY_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "language-policy",
    text: "Language Policy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "language-resources-and-evaluation",
    text: "Language Resources and Evaluation",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "language-sciences",
    text: "Language Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "language-testing-in-asia",
    text: "Language Testing in Asia",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "language",
    text: "Language",
    parent: "NA",
    urlone: "http://www.linguisticsociety.org/files/style-sheet.pdf",
    urltwo: "NA"
  },
  {
    id: "languages",
    text: "Languages",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "lannee-sociologique",
    text: "Lâ€™AnnÃ©e sociologique (French)",
    parent: "NA",
    urlone: "https://www.puf.com/collections/AnnÃ©e_sociologique_-l--",
    urltwo: "NA"
  },
  {
    id: "lanthropologie",
    text: "L'Anthropologie",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "laparoscopic-endoscopic-and-robotic-surgery",
    text: "Laparoscopic, Endoscopic and Robotic Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "large-scale-assessments-in-education",
    text: "Large-scale Assessments in Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "laryngo-rhino-otologie",
    text: "Laryngo-Rhino-Otologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "laser-and-photonics-reviews",
    text: "Laser &amp; Photonics Reviews",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1863-8899/homepage/2414_Author_Guidelines.html",
    urltwo: "NA"
  },
  {
    id: "laser-physics-letters",
    text: "Laser Physics Letters",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1612-202X",
    urltwo: "NA"
  },
  {
    id: "laser-physics",
    text: "Laser Physics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1555-6611",
    urltwo: "NA"
  },
  {
    id: "lasers-in-manufacturing-and-materials-processing",
    text: "Lasers in Manufacturing and Materials Processing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "lasers-in-medical-science",
    text: "Lasers in Medical Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "laterality-asymmetries-of-body-brain-and-cognition",
    text: "Laterality: Asymmetries of Body, Brain and Cognition",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PLAT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "latin-american-and-caribbean-ethnic-studies",
    text: "Latin American and Caribbean Ethnic Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RLAC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "latin-american-economic-review",
    text: "Latin American Economic Review",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "latin-american-perspectives",
    text: "Latin American Perspectives",
    parent: "NA",
    urlone: "http://latinamericanperspectives.com/wp-content/uploads/2011/10/ManuscriptPreparation.pdf",
    urltwo: "NA"
  },
  {
    id: "latin-american-research-review",
    text: "Latin American Research Review",
    parent: "NA",
    urlone: "http://lasa.international.pitt.edu/eng/larr/submission.asp",
    urltwo: "NA"
  },
  {
    id: "lauterbornia",
    text: "Lauterbornia - Internationale Zeitschrift fÃ¼r Faunistik und Floristik des SÃ¼ÃŸwassers (German)",
    parent: "NA",
    urlone: "http://blog.lauterbornia.de/verfasserrichtlinien/",
    urltwo: "NA"
  },
  {
    id: "law-and-critique",
    text: "Law and Critique",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "law-and-human-behavior",
    text: "Law and Human Behavior",
    parent: "apa",
    urlone: "http://content.apa.org/journals/lhb",
    urltwo: "NA"
  },
  {
    id: "law-and-society-review",
    text: "Law &amp; Society Review",
    parent: "NA",
    urlone: "http://www.lawandsociety.org/review/review_stylesheet.html",
    urltwo: "NA"
  },
  {
    id: "law-culture-and-the-humanities",
    text: "Law, Culture and the Humanities",
    parent: "cultural-geographies",
    urlone: "https://us.sagepub.com/en-us/nam/journal/law-culture-and-humanities#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "law-technology-and-humans",
    text: "Law, Technology and Humans",
    parent: "NA",
    urlone: "https://lthj.qut.edu.au/public/journals/5/LTH_Author_Guidelines_2020_2021.pdf",
    urltwo: "NA"
  },
  {
    id: "laws",
    text: "Laws",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "lcgc",
    text: "LCGC",
    parent: "NA",
    urlone: "http://www.chromatographyonline.com/lcgc-author-guidelines",
    urltwo: "NA"
  },
  {
    id: "le-mouvement-social",
    text: "Le Mouvement Social (French)",
    parent: "NA",
    urlone: "http://www.lemouvementsocial.net/presentation-des-manuscrits/",
    urltwo: "NA"
  },
  {
    id: "le-pharmacien-hospitalier-et-clinicien",
    text: "Le Pharmacien Hospitalier et Clinicien",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "le-praticien-en-anesthesie-reanimation",
    text: "Le Praticien en anesthÃ©sie rÃ©animation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "le-tapuscrit-author-date",
    text: "Le tapuscrit (Ã‰cole des hautes Ã©tudes en sciences sociales) (author-date, French)",
    parent: "NA",
    urlone: "http://www.editions.ehess.fr/ouvrages/ouvrage/le-tapuscrit/",
    urltwo: "NA"
  },
  {
    id: "le-tapuscrit-note",
    text: "Le tapuscrit (Ã‰cole des hautes Ã©tudes en sciences sociales) (note, French)",
    parent: "NA",
    urlone: "http://www.editions.ehess.fr/ouvrages/ouvrage/le-tapuscrit/",
    urltwo: "NA"
  },
  {
    id: "leadership-and-management-in-engineering",
    text: "Leadership and Management in Engineering",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/lmeeaz",
    urltwo: "NA"
  },
  {
    id: "learning-and-individual-differences",
    text: "Learning and Individual Differences",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "learning-and-instruction",
    text: "Learning and Instruction",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "learning-and-memory",
    text: "Learning &amp; Memory",
    parent: "cold-spring-harbor-laboratory-press",
    urlone: "http://learnmem.cshlp.org/",
    urltwo: "NA"
  },
  {
    id: "learning-and-motivation",
    text: "Learning and Motivation",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "learning-culture-and-social-interaction",
    text: "Learning, Culture and Social Interaction",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "learning-environments-research",
    text: "Learning Environments Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "learning-media-and-technology",
    text: "Learning, Media and Technology",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CJEM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "legal-and-criminological-psychology",
    text: "Legal and Criminological Psychology",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-8333/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "legal-medicine",
    text: "Legal Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "leiden-journal-of-international-law",
    text: "Leiden Journal of International Law",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/services/aop-file-manager/file/575adb7dfcaa6b122ec738b3",
    urltwo: "NA"
  },
  {
    id: "leidraad-voor-juridische-auteurs",
    text: "Leidraad voor juridische auteurs (Dutch)",
    parent: "NA",
    urlone: "http://auteur.kluwer.nl/auteursmiddelen-en-instructies",
    urltwo: "NA"
  },
  {
    id: "leisure-loisir",
    text: "Leisure/Loisir",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RLOI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "leisure-studies",
    text: "Leisure Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RLST20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "lencephale",
    text: "L'EncÃ©phale",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "lendocrinologo",
    text: "L'Endocrinologo (Italian)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "leonardo-music-journal",
    text: "Leonardo Music Journal",
    parent: "leonardo",
    urlone: "https://www.leonardo.info/preparing-your-materials-journals#refs",
    urltwo: "NA"
  },
  {
    id: "leonardo",
    text: "Leonardo",
    parent: "NA",
    urlone: "https://www.leonardo.info/preparing-your-materials-journals",
    urltwo: "NA"
  },
  {
    id: "leprosy-review",
    text: "Leprosy Review",
    parent: "vancouver-superscript",
    urlone: "http://www.leprahealthinaction.org/category/information/leprosy-review/advice-on-article-submission/",
    urltwo: "NA"
  },
  {
    id: "les-journees-de-la-recherche-avicole",
    text: "Les JournÃ©es de la Recherche Avicole (French)",
    parent: "NA",
    urlone: "http://www.journees-de-la-recherche.org/JRA/page-JRA1024.php?page=fr",
    urltwo: "NA"
  },
  {
    id: "les-journees-de-la-recherche-porcine",
    text: "Les JournÃ©es de la Recherche Porcine (French)",
    parent: "NA",
    urlone: "http://www.journees-recherche-porcine.com/consig/index.htm",
    urltwo: "NA"
  },
  {
    id: "les-nouvelles-de-l-archeologie",
    text: "Les nouvelles de l'archÃ©ologie (French)",
    parent: "NA",
    urlone: "https://journals.openedition.org/nda/1554",
    urltwo: "NA"
  },
  {
    id: "lethaia",
    text: "Lethaia",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291502-3931/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "lettera-matematica",
    text: "Lettera Matematica",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "letters-in-applied-microbiology",
    text: "Letters in Applied Microbiology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1472-765X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "letters-in-mathematical-physics",
    text: "Letters in Mathematical Physics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "letters-in-spatial-and-resource-sciences",
    text: "Letters in Spatial and Resource Sciences",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "lettres-et-sciences-humaines-fr",
    text: "Lettres et Sciences Humaines (biblio et notes, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "leukemia-research-reports",
    text: "Leukemia Research Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "leukemia-research",
    text: "Leukemia Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "leukemia-supplements",
    text: "Leukemia Supplements",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-leu.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions",
    urltwo: "NA"
  },
  {
    id: "leukemia",
    text: "Leukemia",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/leu/leu_new_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "leviathan",
    text: "Leviathan (German)",
    parent: "NA",
    urlone: "http://www.wzb.eu/de/publikationen/leviathan",
    urltwo: "NA"
  },
  {
    id: "levolution-psychiatrique",
    text: "L'Ã‰volution psychiatrique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "lexicography",
    text: "Lexicography",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "library-and-information-science-research",
    text: "Library and Information Science Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "library-collections-acquisitions-and-technical-services",
    text: "Library Collections, Acquisitions and Technical Services",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "lien-social-et-politiques",
    text: "Lien social et Politiques (French)",
    parent: "NA",
    urlone: "http://lsp.inrs.ca/la-revue/",
    urltwo: "http://lsp.inrs.ca/wp-content/uploads/2016/06/LSP-Directives-aux-auteurs-depot_initial_FINAL_Mars-2019.pdf"
  },
  {
    id: "life-sciences-in-space-research",
    text: "Life Sciences in Space Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "life-sciences-society-and-policy",
    text: "Life Sciences, Society and Policy",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "life-sciences",
    text: "Life Sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "life",
    text: "Life",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "lifetime-data-analysis",
    text: "Lifetime Data Analysis",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "light-science-and-applications",
    text: "Light: Science &amp; Applications",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-lsa.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "limnetica",
    text: "Limnetica",
    parent: "NA",
    urlone: "http://www.limnetica.com/",
    urltwo: "http://www.limnetica.com/pdf/autores.pdf"
  },
  {
    id: "limnologica",
    text: "Limnologica",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "limnology-and-oceanography",
    text: "Limnology and Oceanography",
    parent: "NA",
    urlone: "http://www.aslo.org/lo/instructions/authors.html#References",
    urltwo: "NA"
  },
  {
    id: "limnology",
    text: "Limnology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "lindbergia",
    text: "Lindbergia",
    parent: "oikos",
    urlone: "http://www.lindbergia.org/authors/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "linear-algebra-and-its-applications",
    text: "Linear Algebra and Its Applications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "linear-and-multilinear-algebra",
    text: "Linear and Multilinear Algebra",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GLMA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "lingua-sinica",
    text: "Lingua Sinica",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "lingua",
    text: "Lingua",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "linguistics-and-education",
    text: "Linguistics and Education",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "linguistics-and-philosophy",
    text: "Linguistics and Philosophy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "lipids-in-health-and-disease",
    text: "Lipids in Health and Disease",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "lipids",
    text: "Lipids",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "liquid-crystals-reviews",
    text: "Liquid Crystals Reviews",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TLCR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "liquid-crystals-today",
    text: "Liquid Crystals Today",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TLCY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "liquid-crystals",
    text: "Liquid Crystals",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TLCT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "list-forum-fur-wirtschafts-und-finanzpolitik",
    text: "List Forum fÃ¼r Wirtschafts- und Finanzpolitik (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "lithos",
    text: "LITHOS",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "lithosphere",
    text: "Lithosphere",
    parent: "the-geological-society-of-america",
    urlone: "http://lithosphere.gsapubs.org/",
    urltwo: "NA"
  },
  {
    id: "liver-cancer",
    text: "Liver Cancer",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/255487",
    urltwo: "NA"
  },
  {
    id: "liver-international",
    text: "Liver International",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1478-3231/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "liver-research",
    text: "Liver Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "liverpool-john-moores-university-harvard",
    text: "Liverpool John Moores University - Harvard",
    parent: "NA",
    urlone: "https://www.ljmu.ac.uk/~/media/library/library-skills/harvardreferencingfullguidenew.pdf?la=en",
    urltwo: "NA"
  },
  {
    id: "liverpool-law-review",
    text: "Liverpool Law Review",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "livestock-science",
    text: "Livestock Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "lluelles-lexisnexis",
    text: "LexisNexis QuÃ©bec (Guide Lluelles, no Ibid., French - Canada)",
    parent: "lluelles-no-ibid",
    urlone: "http://f-mb.github.io/cslegal/",
    urltwo: "NA"
  },
  {
    id: "lluelles-no-ibid",
    text:
      "Guide des rÃ©fÃ©rences pour la rÃ©daction juridique 7e Ã©dition (Notes complÃ¨tes) (Guide Lluelles, no Ibid., French - Canada)",
    parent: "NA",
    urlone: "http://f-mb.github.io/cslegal/",
    urltwo: "NA"
  },
  {
    id: "lluelles",
    text: "Guide des rÃ©fÃ©rences pour la rÃ©daction juridique 7e Ã©dition (Guide Lluelles, French - Canada)",
    parent: "NA",
    urlone: "http://f-mb.github.io/cslegal/",
    urltwo: "http://www.editionsthemis.com/livres/livre-3844-guide-des-references-pour-la-redaction-juridique.html"
  },
  {
    id: "lo-scalpello-otodi-educational",
    text: "LO SCALPELLO-OTODI Educational (Italian)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "local-environment",
    text: "Local Environment",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CLOE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "local-government-law-journal",
    text: "Local Government Law Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "logica-universalis",
    text: "Logica Universalis",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "logistics-research",
    text: "Logistics Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "logistics",
    text: "Logistics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "loisir-et-societe-society-and-leisure",
    text: "Loisir et SociÃ©tÃ© / Society and Leisure",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RLES20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "london-metropolitan-university-harvard",
    text: "London Metropolitan University - Harvard",
    parent: "NA",
    urlone:
      "http://student.londonmet.ac.uk/library/subject-guides-and-research-support/referencing-and-copyright/referencing/",
    urltwo: "NA"
  },
  {
    id: "london-review-of-international-law",
    text: "London Review of International Law",
    parent: "NA",
    urlone: "https://academic.oup.com/lril/pages/General_Instructions",
    urltwo:
      "https://static.primary.prod.gcms.the-infra.com/static/site/lril/document/style-guide.docx?node=b9d2bf7e238350df2230&amp;version=104499:13d1826d3ed8adee4689"
  },
  {
    id: "london-south-bank-university-numeric",
    text: "London South Bank University (numeric)",
    parent: "NA",
    urlone: "https://my.lsbu.ac.uk/assets/documents/library/Numeric%20referencing%20helpsheet%2028.pdf",
    urltwo: "NA"
  },
  {
    id: "long-range-planning",
    text: "Long Range Planning",
    parent: "soil-biology-and-biochemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "longevity-sciences-and-medicine",
    text: "Longevity Sciences and Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "low-temperature-physics",
    text: "Low Temperature Physics",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "lubricants",
    text: "Lubricants",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "lund-university-school-of-economics-and-management",
    text: "Lund University School of Economics and Management",
    parent: "NA",
    urlone: "http://libguides.lub.lu.se/ld.php?content_id=12012699",
    urltwo: "NA"
  },
  {
    id: "lung-cancer-management",
    text: "Lung Cancer Management",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/lmt",
    urltwo: "NA"
  },
  {
    id: "lung-cancer",
    text: "Lung Cancer",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "lung-india",
    text: "Lung India",
    parent: "vancouver-superscript",
    urlone: "http://www.lungindia.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "lung",
    text: "Lung",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "lupus-science-and-medicine",
    text: "Lupus Science &amp; Medicine",
    parent: "bmj",
    urlone: "http://lupus.bmj.com",
    urltwo: "NA"
  },
  {
    id: "lwt",
    text: "LWT",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mabs",
    text: "mAbs",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "macedonian-journal-of-medical-sciences",
    text: "Macedonian Journal of Medical Sciences",
    parent: "vancouver-brackets",
    urlone: "http://versita.metapress.com/content/121290/",
    urltwo: "NA"
  },
  {
    id: "machine-learning-science-and-technology",
    text: "Machine Learning: Science and Technology",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "machine-learning",
    text: "Machine Learning",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "machine-translation",
    text: "Machine Translation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "machine-vision-and-applications",
    text: "Machine Vision and Applications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "machines",
    text: "Machines",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "macromolecular-reaction-engineering",
    text: "Macromolecular Reaction Engineering",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1862-8338/homepage/2465_authors.html",
    urltwo: "NA"
  },
  {
    id: "macromolecules",
    text: "Macromolecules",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/mamobx/mamobx_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "magister",
    text: "Magister",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "magnetic-resonance-imaging",
    text: "Magnetic Resonance Imaging",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "magnetic-resonance-in-medicine",
    text: "Magnetic Resonance in Medicine",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/15222594",
    urltwo: "NA"
  },
  {
    id: "magnetic-resonance-materials-in-physics-biology-and-medicine",
    text: "Magnetic Resonance Materials in Physics, Biology and Medicine",
    parent: "NA",
    urlone: "http://www.springer.com/medicine/radiology/journal/10334",
    urltwo: "NA"
  },
  {
    id: "magnetochemistry",
    text: "Magnetochemistry",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "maison-de-l-orient-et-de-la-mediterranee-en",
    text: "Maison de l'Orient et de la MÃ©diterranÃ©e (English)",
    parent: "NA",
    urlone: "https://www.mom.fr/sites/mom.fr/files/img/Publications/mom-editions_3_normes-bibliographie.pdf",
    urltwo: "NA"
  },
  {
    id: "maison-de-l-orient-et-de-la-mediterranee",
    text: "Maison de l'Orient et de la MÃ©diterranÃ©e (French)",
    parent: "NA",
    urlone: "https://www.mom.fr/sites/mom.fr/files/img/Publications/mom-editions_3_normes-bibliographie.pdf",
    urltwo: "NA"
  },
  {
    id: "malaria-journal",
    text: "Malaria Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "malaysian-orthopaedic-journal",
    text: "Malaysian Orthopaedic Journal",
    parent: "NA",
    urlone: "http://www.morthoj.org/instructions-for-authors2.php",
    urltwo: "NA"
  },
  {
    id: "mammal-research",
    text: "Mammal Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mammal-review",
    text: "Mammal Review",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-2907/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "mammalia",
    text: "Mammalia",
    parent: "NA",
    urlone: "https://www.degruyter.com/view/journals/mamm/mamm-overview.xml?tab_body=editorialContent-68857",
    urltwo: "http://www.degruyter.com/view/supplement/s18641547_Instructions_for_Authors.pdf"
  },
  {
    id: "mammalian-biology",
    text: "Mammalian Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mammalian-genome",
    text: "Mammalian Genome",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "management-accounting-research",
    text: "Management Accounting Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "management-et-avenir",
    text: "Management &amp; Avenir (French)",
    parent: "NA",
    urlone: "http://www.managementetavenir.net/la-recherche/soumettre-un-article-independant.html",
    urltwo: "NA"
  },
  {
    id: "management-international-review",
    text: "Management International Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "management-international",
    text: "Management international",
    parent: "NA",
    urlone: "http://www.managementinternational.ca/en/authors-section/im-style-guide/",
    urltwo: "NA"
  },
  {
    id: "management-review-quarterly",
    text: "Management Review Quarterly",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "management-science",
    text: "Management Science",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/mnsc/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "managing-leisure",
    text: "Managing Leisure",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMLE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "manchester-university-press-author-date",
    text: "Manchester University Press monographs (author-date)",
    parent: "NA",
    urlone: "https://manchesteruniversitypress.co.uk/the-peer-review-and-commissioning-process/",
    urltwo: "NA"
  },
  {
    id: "manchester-university-press",
    text: "Manchester University Press monographs (note)",
    parent: "NA",
    urlone: "https://manchesteruniversitypress.co.uk/the-peer-review-and-commissioning-process/",
    urltwo: "NA"
  },
  {
    id: "manedsskrift-for-almen-praksis",
    text: "MÃ¥nedsskrift for almen praksis (Danish)",
    parent: "vancouver",
    urlone: "http://www.maanedsskriftet.dk/index.php/carecms/engine/index/176",
    urltwo: "NA"
  },
  {
    id: "manual-therapy",
    text: "Manual Therapy",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "manuelle-medizin",
    text: "Manuelle Medizin (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/journal/337?detailsPage=pltci_1697909",
    urltwo: "NA"
  },
  {
    id: "manufacturing-and-service-operations-management",
    text: "Manufacturing &amp; Service Operations Management",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/msom/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "manufacturing-letters",
    text: "Manufacturing Letters",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "manuscripta-mathematica",
    text: "Manuscripta Mathematica",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "marine-and-coastal-fisheries",
    text: "Marine and Coastal Fisheries",
    parent: "american-fisheries-society",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=umcf20&amp;page=instructions#.UpVAZUMju1E",
    urltwo: "NA"
  },
  {
    id: "marine-and-freshwater-behaviour-and-physiology",
    text: "Marine and Freshwater Behaviour and Physiology",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GMFW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "marine-and-freshwater-research",
    text: "Marine &amp; Freshwater Research",
    parent: "emu-austral-ornithology",
    urlone: "http://www.publish.csiro.au/nid/129/aid/434.htm#7",
    urltwo: "NA"
  },
  {
    id: "marine-and-petroleum-geology",
    text: "Marine and Petroleum Geology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "marine-biodiversity-records",
    text: "Marine Biodiversity Records",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "marine-biodiversity",
    text: "Marine Biodiversity",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "marine-biology",
    text: "Marine Biology",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/environment/aquatic+sciences/journal/227"
  },
  {
    id: "marine-biotechnology",
    text: "Marine Biotechnology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "marine-chemistry",
    text: "Marine Chemistry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "marine-drugs",
    text: "Marine Drugs",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "marine-ecology-progress-series",
    text: "Marine Ecology Progress Series",
    parent: "inter-research-science-center",
    urlone: "http://www.int-res.com/journals/meps/meps-home/",
    urltwo: "NA"
  },
  {
    id: "marine-environmental-research",
    text: "Marine Environmental Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "marine-genomics",
    text: "Marine Genomics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "marine-geology",
    text: "Marine Geology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "marine-geophysical-research",
    text: "Marine Geophysical Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "marine-mammal-science",
    text: "Marine Mammal Science",
    parent: "NA",
    urlone: "https://www.marinemammalscience.org/journal/guide-for-authors/",
    urltwo: "NA"
  },
  {
    id: "marine-micropaleontology",
    text: "Marine Micropaleontology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "marine-ornithology",
    text: "Marine Ornithology",
    parent: "NA",
    urlone: "http://www.marineornithology.org/content/get.cgi?p=subm",
    urltwo: "NA"
  },
  {
    id: "marine-policy",
    text: "Marine Policy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "marine-pollution-bulletin",
    text: "Marine Pollution Bulletin",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "marine-structures",
    text: "Marine Structures",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "marine-systems-and-ocean-technology",
    text: "Marine Systems &amp; Ocean Technology",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "marine-turtle-newsletter",
    text: "Marine Turtle Newsletter",
    parent: "NA",
    urlone: "http://seaturtle.org/mtn/authors.shtml",
    urltwo: "NA"
  },
  {
    id: "maritime-policy-and-management",
    text: "Maritime Policy &amp; Management",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TMPM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "maritime-studies",
    text: "Maritime Studies",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "marketing-letters",
    text: "Marketing Letters",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "marmara-universitesi-turkiyat-arastirmalari-enstitusu",
    text: "Marmara Ãœniversitesi - TÃ¼rkiyat AraÅŸtÄ±rmalarÄ± EnstitÃ¼sÃ¼ (Turkish)",
    parent: "NA",
    urlone: "http://dosya.marmara.edu.tr/tae/Ara%C5%9Ft%C4%B1rma/tae-tez-yazim-klavuzu05.12.2014.doc",
    urltwo: "NA"
  },
  {
    id: "masarykova-univerzita-pravnicka-fakulta",
    text: "Masarykova univerzita - PrÃ¡vnickÃ¡ fakulta",
    parent: "NA",
    urlone: "https://is.muni.cz/do/law/ud/predp/smer/Smernice_dekana_c._3-2020_O_citacich_dokumentu.pdf",
    urltwo: "NA"
  },
  {
    id: "mastozoologia-neotropical",
    text: "Mastozoologia Neotropical",
    parent: "NA",
    urlone: "http://www.sarem.org.ar/wp-content/uploads/2014/03/SAREM_MastNeotrop_NormasEditoriales-dic2013.pdf",
    urltwo: "NA"
  },
  {
    id: "materialia",
    text: "Materialia",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-and-design",
    text: "Materials &amp; Design",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-and-structures",
    text: "Materials and Structures",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "materials-characterization",
    text: "Materials Characterization",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-chemistry-and-physics",
    text: "Materials Chemistry and Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-discovery",
    text: "Materials Discovery",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-express",
    text: "Materials Express",
    parent: "NA",
    urlone: "http://www.aspbs.com/mex/inst-auth_mex.htm",
    urltwo: "NA"
  },
  {
    id: "materials-for-renewable-and-sustainable-energy",
    text: "Materials for Renewable and Sustainable Energy",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "materials-horizons",
    text: "Materials Horizons",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-letters-x",
    text: "Materials Letters: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-letters",
    text: "Materials Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-research-bulletin",
    text: "Materials Research Bulletin",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-research-express",
    text: "Materials Research Express",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "materials-research-letters",
    text: "Materials Research Letters",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TMRL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "materials-science-and-engineering-a",
    text: "Materials Science &amp; Engineering A",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-science-and-engineering-b",
    text: "Materials Science &amp; Engineering B",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-science-and-engineering-c",
    text: "Materials Science &amp; Engineering C",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-science-and-engineering-r",
    text: "Materials Science &amp; Engineering R",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-science-for-energy-technologies",
    text: "Materials Science for Energy Technologies",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-science-in-semiconductor-processing",
    text: "Materials Science in Semiconductor Processing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-technology",
    text: "Materials Technology",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=ymte20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "materials-today-advances",
    text: "Materials Today Advances",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-today-bio",
    text: "Materials Today Bio",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-today-chemistry",
    text: "Materials Today Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-today-communications",
    text: "Materials Today Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-today-energy",
    text: "Materials Today Energy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-today-nano",
    text: "Materials Today Nano",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-today-physics",
    text: "Materials Today Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-today-proceedings",
    text: "Materials Today: Proceedings",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-today-sustainability",
    text: "Materials Today Sustainability",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials-today",
    text: "Materials Today",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "materials",
    text: "Materials",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "maternal-and-child-health-journal",
    text: "Maternal and Child Health Journal",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "maternal-health-neonatology-and-perinatology",
    text: "Maternal Health, Neonatology and Perinatology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematical-and-computational-applications",
    text: "Mathematical and Computational Applications",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "mathematical-and-computer-modelling",
    text: "Mathematical and Computer Modelling",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mathematical-biosciences-and-engineering",
    text: "Mathematical Biosciences and Engineering",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/295.html",
    urltwo: "NA"
  },
  {
    id: "mathematical-biosciences",
    text: "Mathematical Biosciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mathematical-geosciences",
    text: "Mathematical Geosciences",
    parent: "NA",
    urlone: "https://www.springer.com/earth+sciences+and+geography/journal/11004?detailsPage=pltci_3160931",
    urltwo: "NA"
  },
  {
    id: "mathematical-medicine-and-biology",
    text: "Mathematical Medicine and Biology",
    parent: "institute-of-mathematics-and-its-applications",
    urlone: "https://academic.oup.com/imammb/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "mathematical-methods-of-operations-research",
    text: "Mathematical Methods of Operations Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematical-physics-analysis-and-geometry",
    text: "Mathematical Physics, Analysis and Geometry",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematical-programming-computation",
    text: "Mathematical Programming Computation",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematical-programming",
    text: "Mathematical Programming",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematical-sciences",
    text: "Mathematical Sciences",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematical-social-sciences",
    text: "Mathematical Social Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mathematics-and-computers-in-simulation",
    text: "Mathematics and Computers in Simulation",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/mathematics-and-computers-in-simulation/0378-4754/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "mathematics-and-financial-economics",
    text: "Mathematics and Financial Economics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematics-education-research-journal",
    text: "Mathematics Education Research Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematics-in-computer-science",
    text: "Mathematics in Computer Science",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematics-in-engineering",
    text: "Mathematics in Engineering",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/284.html",
    urltwo: "NA"
  },
  {
    id: "mathematics-in-industry-case-studies",
    text: "Mathematics-in-Industry Case Studies",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematics-of-control-signals-and-systems",
    text: "Mathematics of Control, Signals, and Systems",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematics",
    text: "Mathematics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "mathematische-annalen",
    text: "Mathematische Annalen",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematische-semesterberichte",
    text: "Mathematische Semesterberichte (German)",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mathematische-zeitschrift",
    text: "Mathematische Zeitschrift",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "matrix-biology-plus",
    text: "Matrix Biology Plus",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "matrix-biology",
    text: "Matrix Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "matter-and-radiation-at-extremes",
    text: "Matter and Radiation at Extremes",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "maturitas",
    text: "Maturitas",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "maxillofacial-plastic-and-reconstructive-surgery",
    text: "Maxillofacial Plastic and Reconstructive Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mayo-clinic-proceedings-innovations-quality-and-outcomes",
    text: "Mayo Clinic Proceedings: Innovations, Quality &amp; Outcomes",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mayo-clinic-proceedings",
    text: "Mayo Clinic Proceedings",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mbio",
    text: "mBio",
    parent: "american-society-for-microbiology",
    urlone: "http://mbio.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "mcdonald-institute-monographs",
    text: "McDonald Institute Monographs",
    parent: "NA",
    urlone: "http://www.mcdonald.cam.ac.uk/publications/publication-images/mcd-instructions",
    urltwo: "NA"
  },
  {
    id: "mcgill-en",
    text: "Canadian Guide to Uniform Legal Citation 9th edition (McGill Guide)",
    parent: "NA",
    urlone: "https://lawjournal.mcgill.ca/cite-guide",
    urltwo: "NA"
  },
  {
    id: "mcgill-fr",
    text: "Manuel canadien de la rÃ©fÃ©rence juridique 9e Ã©dition (Guide McGill, French - Canada)",
    parent: "NA",
    urlone: "https://lawjournal.mcgill.ca/cite-guide",
    urltwo: "NA"
  },
  {
    id: "measurement-science-and-technology",
    text: "Measurement Science and Technology",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0957-0233",
    urltwo: "NA"
  },
  {
    id: "measurement",
    text: "Measurement",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "meat-science",
    text: "Meat Science",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "meccanica",
    text: "Meccanica",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mechanical-sciences",
    text: "Mechanical Sciences",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mechanical-systems-and-signal-processing",
    text: "Mechanical Systems and Signal Processing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mechanics-of-advanced-materials-and-modern-processes",
    text: "Mechanics of Advanced Materials and Modern Processes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mechanics-of-materials",
    text: "Mechanics of Materials",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mechanics-of-time-dependent-materials",
    text: "Mechanics of Time-Dependent Materials",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mechanics-research-communications",
    text: "Mechanics Research Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mechanism-and-machine-theory",
    text: "Mechanism and Machine Theory",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mechanisms-of-ageing-and-development",
    text: "Mechanisms of Ageing and Development",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mechanisms-of-development",
    text: "Mechanisms of Development",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mechatronics",
    text: "Mechatronics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medchemcomm",
    text: "MedChemComm",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medecine-du-sommeil",
    text: "MÃ©decine du Sommeil",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medecine-et-droit",
    text: "MÃ©decine et Droit",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medecine-et-maladies-infectieuses",
    text: "MÃ©decine et Maladies Infectieuses",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medecine-intensive-reanimation",
    text: "MÃ©decine Intensive RÃ©animation",
    parent: "NA",
    urlone: "https://rea.revuesonline.com/pour-les-auteurs/instructions-aux-auteurs",
    urltwo: "NA"
  },
  {
    id: "medecine-nucleaire",
    text: "MÃ©decine NuclÃ©aire",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medecine-palliative-soins-de-support-accompagnement-ethique",
    text: "MÃ©decine Palliative Soins de Support - Accompagnement - Ethique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medecine-sciences",
    text: "mÃ©decine/sciences",
    parent: "NA",
    urlone: "http://www.medecinesciences.org",
    urltwo: "NA"
  },
  {
    id: "media-culture-and-society",
    text: "Media, Culture &amp; Society",
    parent: "NA",
    urlone: "http://www.sagepub.com/journals/Journal200958/manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "mediaeval-studies",
    text: "Mediaeval Studies",
    parent: "NA",
    urlone: "http://www.pims.ca/publications/journal-mediaeval-studies",
    urltwo: "NA"
  },
  {
    id: "medialni-studia",
    text: "MediÃ¡lnÃ­ studia",
    parent: "apa",
    urlone: "https://www.medialnistudia.fsv.cuni.cz/en/autor-s-manual",
    urltwo: "NA"
  },
  {
    id: "medical-acupuncture",
    text: "Medical Acupuncture",
    parent: "vancouver-superscript",
    urlone: "http://www.liebertpub.com/manuscript/acu",
    urltwo: "NA"
  },
  {
    id: "medical-and-biological-engineering-and-computing",
    text: "Medical &amp; Biological Engineering &amp; Computing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "medical-care",
    text: "Medical Care",
    parent: "vancouver-superscript",
    urlone: "http://edmgr.ovid.com/mdc/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "medical-dosimetry",
    text: "Medical Dosimetry",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/medical-dosimetry/0958-3947/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "medical-education",
    text: "Medical Education",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2923/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "medical-engineering-and-physics",
    text: "Medical Engineering and Physics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medical-epigenetics",
    text: "Medical Epigenetics",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/243034",
    urltwo: "NA"
  },
  {
    id: "medical-gas-research",
    text: "Medical Gas Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "medical-history",
    text: "Medical History",
    parent: "NA",
    urlone: "http://assets.cambridge.org/MDH/MDH_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "medical-humanities",
    text: "Medical Humanities",
    parent: "bmj",
    urlone: "http://mh.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "medical-hypotheses",
    text: "Medical Hypotheses",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medical-image-analysis",
    text: "Medical Image Analysis",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medical-journal-armed-forces-india",
    text: "Medical Journal Armed Forces India",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medical-microbiology-and-immunology",
    text: "Medical Microbiology and Immunology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "medical-molecular-morphology",
    text: "Medical Molecular Morphology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "medical-mycology-case-reports",
    text: "Medical Mycology Case Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medical-oncology",
    text: "Medical Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "medical-photonics",
    text: "Medical Photonics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medical-physics",
    text: "Medical Physics",
    parent: "american-medical-association",
    urlone: "https://aapm.onlinelibrary.wiley.com/hub/journal/24734209/about/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "medical-principles-and-practice",
    text: "Medical Principles and Practice",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0151",
    urltwo: "NA"
  },
  {
    id: "medical-science-educator",
    text: "Medical Science Educator",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "medical-sciences",
    text: "Medical Sciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "medicina-clinica",
    text: "Medicina ClÃ­nica (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.es/es-revista-medicina-clinica-2-normas-publicacion#69000",
    urltwo: "NA"
  },
  {
    id: "medicina-delle-dipendenze-italian-journal-of-the-addictions",
    text: "Medicina delle Dipendenze - Italian Journal of the Addictions (Italian)",
    parent: "NA",
    urlone:
      "https://www.medicinadelledipendenze.it/rivista/istruzioni-per-gli-autori/criteri-generali-per-la-pubblicazione.html",
    urltwo: "NA"
  },
  {
    id: "medicina-fluminensis",
    text: "Medicina Fluminensis",
    parent: "vancouver-superscript",
    urlone: "http://hrcak.srce.hr/upute/guide_authors_Medicina_Fluminensis.pdf",
    urltwo: "NA"
  },
  {
    id: "medicina-intensiva",
    text: "Medicina Intensiva (Spanish)",
    parent: "medicina-clinica",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medicina-reproductiva-y-embriologia-clinica",
    text: "Medicina Reproductiva y EmbriologÃ­a ClÃ­nica",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medicina",
    text: "Medicina",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medicinal-chemistry-research",
    text: "Medicinal Chemistry Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "medicinal-research-reviews",
    text: "Medicinal Research Reviews",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1098-1128/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "medicine-and-science-in-sports-and-exercise",
    text: "Medicine &amp; Science in Sports &amp; Exercise",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/msse/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "medicine-conflict-and-survival",
    text: "Medicine, Conflict and Survival",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FMCS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "medicine-health-care-and-philosophy",
    text: "Medicine, Health Care and Philosophy",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "medicine-in-drug-discovery",
    text: "Medicine in Drug Discovery",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medicine-in-microecology",
    text: "Medicine in Microecology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medicine-in-novel-technology-and-devices",
    text: "Medicine in Novel Technology and Devices",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medicine-in-omics",
    text: "Medicine in Omics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "medicine-studies",
    text: "Medicine Studies",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "medicine",
    text: "Medicine",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.com/journals/medicine/1357-3039/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "medicines",
    text: "Medicines",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "medicinskiy-akademicheskiy-zhurnal",
    text: "Medicinskiy Akademicheskiy Zhurnal",
    parent: "NA",
    urlone: "http://iemspb.ru/science/magazines/maj/publication-rules/",
    urltwo: "NA"
  },
  {
    id: "mediterranean-journal-of-chemistry",
    text: "Mediterranean Journal of Chemistry",
    parent: "NA",
    urlone: "www.medjchem.com/index.php/medjchem/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "mediterranean-journal-of-infection-microbes-and-antimicrobials",
    text: "Mediterranean Journal of Infection, Microbes and Antimicrobials",
    parent: "NA",
    urlone: "http://www.mjima.org/static.php?id=4",
    urltwo: "NA"
  },
  {
    id: "mediterranean-journal-of-mathematics",
    text: "Mediterranean Journal of Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mediterranean-journal-of-nutrition-and-metabolism",
    text: "Mediterranean Journal of Nutrition and Metabolism",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mediterranean-politics",
    text: "Mediterranean Politics",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=fmed20",
    urltwo: "NA"
  },
  {
    id: "medizinische-genetik",
    text: "medizinische genetik (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/gynecology/journal/11825?detailsPage=pltci_1386224",
    urltwo: "NA"
  },
  {
    id: "medizinische-klinik-intensivmedizin-und-notfallmedizin",
    text: "Medizinische Klinik - Intensivmedizin und Notfallmedizin (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/internal/journal/63?detailsPage=pltci_1834608",
    urltwo: "NA"
  },
  {
    id: "melanges-de-l-ecole-francaise-de-rome-antiquite",
    text: "MÃ©langes de l'Ecole franÃ§aise de Rome - AntiquitÃ©",
    parent: "chroniques-des-activites-archeologiques-de-l-ecole-francaise-de-rome",
    urlone: "http://mefra.revues.org/223",
    urltwo: "NA"
  },
  {
    id: "melanges-de-l-ecole-francaise-de-rome-italie-et-mediterranee-modernes-et-contemporaines",
    text: "MÃ©langes de l'Ecole franÃ§aise de Rome - Italie et MÃ©diterranÃ©e modernes et contemporaines",
    parent: "chroniques-des-activites-archeologiques-de-l-ecole-francaise-de-rome",
    urlone: "http://mefrim.revues.org/407",
    urltwo: "NA"
  },
  {
    id: "melanges-de-l-ecole-francaise-de-rome-moyen-age",
    text: "MÃ©langes de l'Ecole franÃ§aise de Rome - Moyen Ã‚ge",
    parent: "chroniques-des-activites-archeologiques-de-l-ecole-francaise-de-rome",
    urlone: "http://mefrm.revues.org/90",
    urltwo: "NA"
  },
  {
    id: "melanoma-management",
    text: "Melanoma Management",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com",
    urltwo: "NA"
  },
  {
    id: "melbourne-school-of-theology",
    text: "Melbourne School of Theology",
    parent: "NA",
    urlone: "http://www.mst.edu.au/wp-content/uploads/2018/06/MST-Essay-Guide-15.3.pdf",
    urltwo: "NA"
  },
  {
    id: "membranes",
    text: "Membranes",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "memetic-computing",
    text: "Memetic Computing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "memo-magazine-of-european-medical-oncology",
    text: "memo - Magazine of European Medical Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "memorias-do-instituto-oswaldo-cruz",
    text: "MemÃ³rias do Instituto Oswaldo Cruz",
    parent: "NA",
    urlone: "http://memorias.ioc.fiocruz.br/instructions-to-authors",
    urltwo: "NA"
  },
  {
    id: "memory",
    text: "Memory",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PMEM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "mental-health-and-physical-activity",
    text: "Mental Health and Physical Activity",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mental-health-and-prevention",
    text: "Mental Health &amp; Prevention",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mental-health-and-substance-use",
    text: "Mental Health and Substance Use",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMHS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "mental-health-religion-and-culture",
    text: "Mental Health, Religion &amp; Culture",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CMHR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "mentoring-and-tutoring-partnership-in-learning",
    text: "Mentoring &amp; Tutoring: Partnership in Learning",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CMET20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "mercator-institut-fur-sprachforderung-und-deutsch-als-zweitsprache",
    text: "Mercator-Institut fÃ¼r SprachfÃ¶rderung und Deutsch als Zweitsprache (German)",
    parent: "NA",
    urlone: "https://uni-koeln.sciebo.de/s/Db576OBLhvmiI4t",
    urltwo: "NA"
  },
  {
    id: "mercatus-center",
    text: "Mercatus Center",
    parent: "NA",
    urlone: "https://chimsit.sharepoint.com/Mercatus/Mercatus%20Document%20Library/Mercatus-StyleGuide_v2.1.pdf",
    urltwo: "NA"
  },
  {
    id: "meta-gene",
    text: "Meta Gene",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metabarcoding-and-metagenomics",
    text: "Metabarcoding and Metagenomics",
    parent: "pensoft-journals",
    urlone: "https://mbmg.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "metabolic-brain-disease",
    text: "Metabolic Brain Disease",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "metabolic-engineering-communications",
    text: "Metabolic Engineering Communications",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metabolic-engineering",
    text: "Metabolic Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metabolism-open",
    text: "Metabolism Open",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metabolism",
    text: "Metabolism",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metabolites",
    text: "Metabolites",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "metabolomics",
    text: "Metabolomics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "metacognition-and-learning",
    text: "Metacognition and Learning",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "metal-powder-report",
    text: "Metal Powder Report",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metallography-microstructure-and-analysis",
    text: "Metallography, Microstructure, and Analysis",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "metallomics",
    text: "Metallomics",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metallurgical-and-materials-transactions-a",
    text: "Metallurgical and Materials Transactions A",
    parent: "NA",
    urlone: "http://www.springer.com/materials/special+types/journal/11663",
    urltwo: "NA"
  },
  {
    id: "metallurgical-and-materials-transactions-b",
    text: "Metallurgical and Materials Transactions B",
    parent: "metallurgical-and-materials-transactions-a",
    urlone: "http://www.springer.com/materials/special+types/journal/11663",
    urltwo: "NA"
  },
  {
    id: "metallurgical-and-materials-transactions-e",
    text: "Metallurgical and Materials Transactions E",
    parent: "metallurgical-and-materials-transactions-a",
    urlone: "http://www.springer.com/materials/special+types/journal/40553",
    urltwo: "NA"
  },
  {
    id: "metallurgical-and-materials-transactions",
    text: "Metallurgical and Materials Transactions",
    parent: "NA",
    urlone: "http://www.tms.org/pubs/journals/MT/MTauthorguide.aspx",
    urltwo: "NA"
  },
  {
    id: "metals",
    text: "Metals",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "metamaterials",
    text: "Metamaterials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metascience",
    text: "Metascience",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "meteoritics-and-planetary-science",
    text: "Meteoritics &amp; Planetary Science",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1945-5100/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "meteorological-applications",
    text: "Meteorological Applications",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1469-8080/homepage/ForAuthors.html#reference_style",
    urltwo: "NA"
  },
  {
    id: "meteorological-monographs",
    text: "Meteorological Monographs",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/amsm",
    urltwo: "NA"
  },
  {
    id: "meteorology-and-atmospheric-physics",
    text: "Meteorology and Atmospheric Physics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "method-and-theory-in-the-study-of-religion",
    text: "Method &amp; Theory in the Study of Religion",
    parent: "NA",
    urlone: "https://brill.com/view/journals/mtsr/mtsr-overview.xml",
    urltwo: "https://brill.com/fileasset/downloads_products/Author_Instructions/MTSR.pdf"
  },
  {
    id: "methodology-and-computing-in-applied-probability",
    text: "Methodology and Computing in Applied Probability",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "methodology-european-journal-of-research-methods-for-the-behavioral-and-social-sciences",
    text: "Methodology: European Journal of Research Methods for the Behavioral and Social Sciences",
    parent: "apa",
    urlone: "http://content.apa.org/journals/med",
    urltwo: "NA"
  },
  {
    id: "methods-and-applications-in-fluorescence",
    text: "Methods and Applications in Fluorescence",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/2050-6120",
    urltwo: "NA"
  },
  {
    id: "methods-and-protocols",
    text: "Methods and Protocols",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "methods-in-ecology-and-evolution",
    text: "Methods in Ecology and Evolution",
    parent: "NA",
    urlone: "http://www.methodsinecologyandevolution.org/view/0/authorGuidelines.html",
    urltwo: "NA"
  },
  {
    id: "methods-in-oceanography",
    text: "Methods in Oceanography",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "methods-in-psychology",
    text: "Methods in Psychology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "methods-of-information-in-medicine",
    text: "Methods of Information in Medicine",
    parent: "NA",
    urlone: "https://www.thieme.com/books-main/clinical-informatics/product/4439-methods-of-information-in-medicine",
    urltwo: "https://www.thieme.com/media/ita/Methods_authors_instructions.pdf"
  },
  {
    id: "methods",
    text: "Methods",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "methodsx",
    text: "MethodsX",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metiers-de-la-petite-enfance",
    text: "MÃ©tiers de la petite enfance",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "metrika",
    text: "Metrika",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "metrologia",
    text: "Metrologia",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0026-1394",
    urltwo: "NA"
  },
  {
    id: "metropol-verlag",
    text: "Metropol Verlag (German)",
    parent: "NA",
    urlone: "http://metropol-verlag.de/wp-content/uploads/2016/06/Manuskriptgestaltung.pdf",
    urltwo: "NA"
  },
  {
    id: "metropolia-university-of-applied-sciences-harvard",
    text: "Metropolia University of Applied Sciences - Harvard (Finnish)",
    parent: "NA",
    urlone: "https://wiki.metropolia.fi/pages/viewpage.action?pageId=57182767",
    urltwo: "https://wiki.metropolia.fi/pages/viewpage.action?pageId=57182787"
  },
  {
    id: "metropolitiques",
    text: "MÃ©tropolitiques (French)",
    parent: "NA",
    urlone: "http://www.metropolitiques.eu/Charte-editoriale,6.html",
    urltwo: "NA"
  },
  {
    id: "micro-and-nano-engineering",
    text: "Micro and Nano Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "micro-and-nano-systems-letters",
    text: "Micro and Nano Systems Letters",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "microarrays",
    text: "Microarrays",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "microbes-and-infection",
    text: "Microbes and Infection",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microbial-biotechnology",
    text: "Microbial Biotechnology",
    parent: "environmental-microbiology",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1751-7915/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "microbial-cell-factories",
    text: "Microbial Cell Factories",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "microbial-cell",
    text: "Microbial Cell",
    parent: "NA",
    urlone: "http://microbialcell.com/for-authors-2/manuscript-preparation/#format_guidelines",
    urltwo: "NA"
  },
  {
    id: "microbial-drug-resistance",
    text: "Microbial Drug Resistance",
    parent: "NA",
    urlone: "https://home.liebertpub.com/publications/microbial-drug-resistance/44/for-authors",
    urltwo: "NA"
  },
  {
    id: "microbial-ecology",
    text: "Microbial Ecology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "microbial-genomics",
    text: "Microbial Genomics",
    parent: "microbiology-society",
    urlone: "http://mgen.microbiologyresearch.org/content/journal/mgen",
    urltwo: "NA"
  },
  {
    id: "microbial-pathogenesis",
    text: "Microbial Pathogenesis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microbial-risk-analysis",
    text: "Microbial Risk Analysis",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microbiological-research",
    text: "Microbiological Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microbiology-and-molecular-biology-reviews",
    text: "Microbiology and Molecular Biology Reviews",
    parent: "american-society-for-microbiology",
    urlone: "http://mmbr.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "microbiology-australia",
    text: "Microbiology Australia",
    parent: "trends-journals",
    urlone: "http://microbiology.publish.csiro.au/nid/214.htm",
    urltwo: "NA"
  },
  {
    id: "microbiology-society",
    text: "Microbiology Society",
    parent: "NA",
    urlone: "http://www.microbiologyresearch.org/authors/information-for-authors#references",
    urltwo: "NA"
  },
  {
    id: "microbiology-spectrum",
    text: "Microbiology Spectrum",
    parent: "american-society-for-microbiology",
    urlone: "http://www.asmscience.org/content/journal/microbiolspec",
    urltwo: "NA"
  },
  {
    id: "microbiology",
    text: "Microbiology",
    parent: "microbiology-society",
    urlone: "http://mic.microbiologyresearch.org/content/journal/micro",
    urltwo: "NA"
  },
  {
    id: "microbiome",
    text: "Microbiome",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "microchemical-journal",
    text: "Microchemical Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microchimica-acta",
    text: "Microchimica Acta",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "microelectronic-engineering",
    text: "Microelectronic Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microelectronics-journal",
    text: "Microelectronics Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microelectronics-reliability",
    text: "Microelectronics Reliability",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microfluidics-and-nanofluidics",
    text: "Microfluidics and Nanofluidics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "microgravity-science-and-technology",
    text: "Microgravity Science and Technology",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "micromachines",
    text: "Micromachines",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "micron",
    text: "Micron",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microorganisms",
    text: "Microorganisms",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "microporous-and-mesoporous-materials",
    text: "Microporous and Mesoporous Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microprocessors-and-microsystems",
    text: "Microprocessors and Microsystems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "microscopy-and-microanalysis",
    text: "Microscopy and Microanalysis",
    parent: "NA",
    urlone: "http://assets.cambridge.org/MAM/MAM_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "microsystem-technologies",
    text: "Microsystem Technologies",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "microvascular-research",
    text: "Microvascular Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "middle-east-critique",
    text: "Middle East Critique",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/layout/style_ccri.pdf",
    urltwo: "NA"
  },
  {
    id: "middle-east-fertility-society-journal",
    text: "Middle East Fertility Society Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "midwestern-baptist-theological-seminary",
    text: "Midwestern Baptist Theological Seminary",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/4363#issuecomment-546532333",
    urltwo: "NA"
  },
  {
    id: "midwifery",
    text: "Midwifery",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "migration-and-development",
    text: "Migration and Development",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMAD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "milan-journal-of-mathematics",
    text: "Milan Journal of Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "military-medical-research",
    text: "Military Medical Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "military-medicine",
    text: "Military Medicine",
    parent: "vancouver-superscript",
    urlone: "http://www.amsus.org/index.php/journal/submit-a-manuscript?start=5",
    urltwo: "NA"
  },
  {
    id: "mimbar-hukum",
    text: "Mimbar Hukum",
    parent: "NA",
    urlone: "https://jurnal.ugm.ac.id/jmh/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "mimesis-edizioni",
    text: "Mimesis Edizioni (Italian)",
    parent: "NA",
    urlone: "http://mimesisedizioni.it/Norme-editoriali-Mimesis.pdf",
    urltwo: "NA"
  },
  {
    id: "mind-and-language",
    text: "Mind &amp; Language",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1468-0017/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "mind-and-society",
    text: "Mind &amp; Society",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mindfulness-and-compassion",
    text: "Mindfulness &amp; Compassion",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mindfulness",
    text: "Mindfulness",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "minds-and-machines",
    text: "Minds and Machines",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mine-water-and-the-environment",
    text: "Mine Water and the Environment",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mineral-economics",
    text: "Mineral Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mineralium-deposita",
    text: "Mineralium Deposita",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mineralogical-magazine",
    text: "Mineralogical Magazine",
    parent: "NA",
    urlone: "http://www.minersoc.org/submit-mm.html",
    urltwo: "NA"
  },
  {
    id: "mineralogy-and-petrology",
    text: "Mineralogy and Petrology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "minerals-engineering",
    text: "Minerals Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "minerals",
    text: "Minerals",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "minerva-medica",
    text: "Minerva Medica",
    parent: "vancouver-superscript",
    urlone: "http://www.minervamedica.it/en/journals/minerva-medica/notice-to-authors.php",
    urltwo: "NA"
  },
  {
    id: "minerva",
    text: "Minerva",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mis-quarterly",
    text: "MIS Quarterly",
    parent: "NA",
    urlone: "http://www.misq.org/manuscript-guidelines",
    urltwo: "NA"
  },
  {
    id: "mitigation-and-adaptation-strategies-for-global-change",
    text: "Mitigation and Adaptation Strategies for Global Change",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mitochondrion",
    text: "Mitochondrion",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mitteilungen-klosterneuburg",
    text: "Mitteilungen Klosterneuburg",
    parent: "apa",
    urlone: "http://mitt-klosterneuburg.com/guideline.html",
    urltwo: "NA"
  },
  {
    id: "mobile-dna",
    text: "Mobile DNA",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mobile-genetic-elements",
    text: "Mobile Genetic Elements",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mobile-networks-and-applications",
    text: "Mobile Networks and Applications",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mobilities",
    text: "Mobilities",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMOB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "modeling-earth-systems-and-environment",
    text: "Modeling Earth Systems and Environment",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "modelling-and-simulation-in-materials-science-and-engineering",
    text: "Modelling and Simulation in Materials Science and Engineering",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0965-0393",
    urltwo: "NA"
  },
  {
    id: "modern-electronic-materials",
    text: "Modern Electronic Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "modern-humanities-research-association-author-date",
    text: "Modern Humanities Research Association 3rd edition (author-date)",
    parent: "NA",
    urlone: "http://www.mhra.org.uk/Publications/Books/StyleGuide/download.shtml",
    urltwo: "NA"
  },
  {
    id: "modern-humanities-research-association",
    text: "Modern Humanities Research Association 3rd edition (note with bibliography)",
    parent: "NA",
    urlone: "http://www.mhra.org.uk/Publications/Books/StyleGuide/download.shtml",
    urltwo: "NA"
  },
  {
    id: "modern-italy",
    text: "Modern Italy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CMIT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "modern-language-association-6th-edition-note",
    text: "Modern Language Association 6th edition (note)",
    parent: "NA",
    urlone: "http://www.utoledo.edu/library/help/guides/docs/MLAstyle.pdf",
    urltwo: "NA"
  },
  {
    id: "modern-language-association-7th-edition-underline",
    text: "Modern Language Association 7th edition (underline)",
    parent: "NA",
    urlone: "http://owl.english.purdue.edu/owl/section/2/11/",
    urltwo: "NA"
  },
  {
    id: "modern-language-association-7th-edition-with-url",
    text: "Modern Language Association 7th edition (with URL)",
    parent: "NA",
    urlone: "http://owl.english.purdue.edu/owl/section/2/11/",
    urltwo: "NA"
  },
  {
    id: "modern-language-association-7th-edition",
    text: "Modern Language Association 7th edition",
    parent: "NA",
    urlone: "http://owl.english.purdue.edu/owl/section/2/11/",
    urltwo: "NA"
  },
  {
    id: "modern-language-association",
    text: "Modern Language Association 8th edition",
    parent: "NA",
    urlone: "http://style.mla.org",
    urltwo: "NA"
  },
  {
    id: "modern-language-review",
    text: "Modern Language Review",
    parent: "modern-humanities-research-association",
    urlone: "http://www.mhra.org.uk/Publications/Journals/mlr.html",
    urltwo: "NA"
  },
  {
    id: "modern-pathology",
    text: "Modern Pathology",
    parent: "NA",
    urlone: "https://www.nature.com/modpathol/authors-and-referees/authors#how-to-submit",
    urltwo: "NA"
  },
  {
    id: "modern-phytomorphology",
    text: "Modern Phytomorphology",
    parent: "NA",
    urlone: "https://phytomorphology.org/journal/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "mohr-siebeck-recht",
    text: "Mohr Siebeck - Recht (German - Austria)",
    parent: "NA",
    urlone: "http://www.mohr.de/fileadmin/user_upload/Hinweise_Autoren_PDF/Merkblaetter/merkjura2006.pdf",
    urltwo: "NA"
  },
  {
    id: "molbank",
    text: "Molbank",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "molecular-and-biochemical-parasitology",
    text: "Molecular &amp; Biochemical Parasitology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-and-cellular-biochemistry",
    text: "Molecular and Cellular Biochemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-and-cellular-biology",
    text: "Molecular and Cellular Biology",
    parent: "american-society-for-microbiology",
    urlone: "http://mcb.asm.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "molecular-and-cellular-endocrinology",
    text: "Molecular and Cellular Endocrinology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-and-cellular-neuroscience",
    text: "Molecular and Cellular Neuroscience",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-and-cellular-pediatrics",
    text: "Molecular and Cellular Pediatrics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-and-cellular-probes",
    text: "Molecular and Cellular Probes",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-and-cellular-proteomics",
    text: "Molecular &amp; Cellular Proteomics",
    parent: "NA",
    urlone: "http://www.mcponline.org/site/misc/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "molecular-and-cellular-therapies",
    text: "Molecular and Cellular Therapies",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-and-clinical-oncology",
    text: "Molecular and Clinical Oncology",
    parent: "spandidos-publications",
    urlone: "http://www.spandidos-publications.com/pages/info_for_authors",
    urltwo: "NA"
  },
  {
    id: "molecular-aspects-of-medicine",
    text: "Molecular Aspects of Medicine",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-astrophysics",
    text: "Molecular Astrophysics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-autism",
    text: "Molecular Autism",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-biology-and-evolution",
    text: "Molecular Biology and Evolution",
    parent: "NA",
    urlone: "https://academic.oup.com/mbe/pages/General_Author_Guidelines#References",
    urltwo: "NA"
  },
  {
    id: "molecular-biology-of-the-cell",
    text: "Molecular Biology of the Cell",
    parent: "NA",
    urlone: "https://www.molbiolcell.org/info-for-authors",
    urltwo: "NA"
  },
  {
    id: "molecular-biology-reports",
    text: "Molecular Biology Reports",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-biology",
    text: "Molecular Biology",
    parent: "NA",
    urlone: "http://www.molecbio.ru/?view=for_authors",
    urltwo: "http://www.molecbio.ru/suppl/guid.pdf"
  },
  {
    id: "molecular-biosystems",
    text: "Molecular BioSystems",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-biotechnology",
    text: "Molecular Biotechnology",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-brain",
    text: "Molecular Brain",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-breeding",
    text: "Molecular Breeding",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-cancer-research",
    text: "Molecular Cancer Research",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "molecular-cancer-therapeutics",
    text: "Molecular Cancer Therapeutics",
    parent: "american-association-for-cancer-research",
    urlone: "http://www.aacrjournals.org/site/InstrAuthors/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "molecular-cancer",
    text: "Molecular Cancer",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-catalysis",
    text: "Molecular Catalysis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-cell",
    text: "Molecular Cell",
    parent: "cell",
    urlone: "http://www.cell.com/molecular-cell/authors",
    urltwo: "NA"
  },
  {
    id: "molecular-cytogenetics",
    text: "Molecular Cytogenetics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-data-science",
    text: "Molecular Data Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-diagnosis-and-therapy",
    text: "Molecular Diagnosis &amp; Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-diversity",
    text: "Molecular Diversity",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-ecology",
    text: "Molecular Ecology",
    parent: "apa-old-doi-prefix",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-294X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "molecular-endocrinology",
    text: "Molecular Endocrinology",
    parent: "endocrine-press",
    urlone: "http://press.endocrine.org/page/authors#mozTocId713371",
    urltwo: "NA"
  },
  {
    id: "molecular-genetics-and-genomics",
    text: "Molecular Genetics and Genomics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-genetics-and-metabolism-reports",
    text: "Molecular Genetics and Metabolism Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-genetics-and-metabolism",
    text: "Molecular Genetics and Metabolism",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-imaging-and-biology",
    text: "Molecular Imaging and Biology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-immunology",
    text: "Molecular Immunology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-medicine-reports",
    text: "Molecular Medicine Reports",
    parent: "spandidos-publications",
    urlone: "http://www.spandidos-publications.com/pages/info_for_authors",
    urltwo: "NA"
  },
  {
    id: "molecular-metabolism",
    text: "Molecular Metabolism",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/molecular-metabolism/2212-8778/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "molecular-microbiology",
    text: "Molecular Microbiology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-2958/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "molecular-neurobiology",
    text: "Molecular Neurobiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-neurodegeneration",
    text: "Molecular Neurodegeneration",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-nutrition-and-food-research",
    text: "Molecular Nutrition &amp; Food Research",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/16134133/homepage/2216_forauthors.html#refs",
    urltwo: "NA"
  },
  {
    id: "molecular-oncology",
    text: "Molecular Oncology",
    parent: "NA",
    urlone:
      "http://febs.onlinelibrary.wiley.com/hub/journal/10.1002/(ISSN)1878-0261/journal-menu/author-guidelines.html#References",
    urltwo: "NA"
  },
  {
    id: "molecular-pain",
    text: "Molecular Pain",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "molecular-pharmaceutics",
    text: "Molecular Pharmaceutics",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/mpohbp/mpohbp_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "molecular-pharmacology",
    text: "Molecular Pharmacology",
    parent: "american-society-for-pharmacology-and-experimental-therapeutics",
    urlone: "http://molpharm.aspetjournals.org/site/misc/ifora.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "molecular-phylogenetics-and-evolution",
    text: "Molecular Phylogenetics and Evolution",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-plant-microbe-interactions",
    text: "Molecular Plant-Microbe Interactions",
    parent: "NA",
    urlone: "http://apsjournals.apsnet.org/loi/mpmi",
    urltwo: "NA"
  },
  {
    id: "molecular-plant-pathology",
    text: "Molecular Plant Pathology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291364-3703/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "molecular-plant",
    text: "Molecular Plant",
    parent: "NA",
    urlone: "http://www.cell.com/molecular-plant/authors",
    urltwo: "NA"
  },
  {
    id: "molecular-psychiatry-letters",
    text: "Molecular Psychiatry (letters to the editor)",
    parent: "NA",
    urlone: "http://mts-mp.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#style",
    urltwo: "NA"
  },
  {
    id: "molecular-psychiatry",
    text: "Molecular Psychiatry",
    parent: "NA",
    urlone: "http://media.nature.com/full/nature-assets/mp/mp_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "molecular-simulation",
    text: "Molecular Simulation",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GMOS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "molecular-systems-biology",
    text: "Molecular Systems Biology",
    parent: "embo-press",
    urlone: "https://www.embopress.org/page/journal/17444292/authorguide#referencesformat",
    urltwo: "NA"
  },
  {
    id: "molecular-systems-design-and-engineering",
    text: "Molecular Systems Design &amp; Engineering",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "molecular-therapy-methods-and-clinical-development",
    text: "Molecular Therapy - Methods &amp; Clinical Development",
    parent: "cell-numeric-superscript",
    urlone: "https://www.cell.com/molecular-therapy-family/home",
    urltwo: "NA"
  },
  {
    id: "molecular-therapy-nucleic-acids",
    text: "Molecular Therapy - Nucleic Acids",
    parent: "cell-numeric-superscript",
    urlone: "https://www.cell.com/molecular-therapy-family/home",
    urltwo: "NA"
  },
  {
    id: "molecular-therapy-oncolytics",
    text: "Molecular Therapy - Oncolytics",
    parent: "cell-numeric-superscript",
    urlone: "https://www.cell.com/molecular-therapy-family/home",
    urltwo: "NA"
  },
  {
    id: "molecular-therapy",
    text: "Molecular Therapy",
    parent: "cell-numeric-superscript",
    urlone: "https://www.cell.com/molecular-therapy-family/home",
    urltwo: "NA"
  },
  {
    id: "molecular-vision",
    text: "Molecular Vision",
    parent: "american-medical-association",
    urlone: "http://www.molvis.org/molvis/instructions.html",
    urltwo: "NA"
  },
  {
    id: "molecules",
    text: "Molecules",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "monash-bioethics-review",
    text: "Monash Bioethics Review",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "monash-university-csiro",
    text: "Monash University - CSIRO",
    parent: "NA",
    urlone: "https://guides.lib.monash.edu/citing-referencing/csiro",
    urltwo: "NA"
  },
  {
    id: "monash-university-harvard",
    text: "Monash University - Harvard",
    parent: "NA",
    urlone: "http://guides.lib.monash.edu/citing-referencing/harvard",
    urltwo: "NA"
  },
  {
    id: "monatshefte-fur-chemie-chemical-monthly",
    text: "Monatshefte fÃ¼r Chemie - Chemical Monthly",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "monatshefte-fur-mathematik",
    text: "Monatshefte fÃ¼r Mathematik",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "monatsschrift-kinderheilkunde",
    text: "Monatsschrift Kinderheilkunde (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/pediatrics/journal/112?detailsPage=pltci_1892629",
    urltwo: "NA"
  },
  {
    id: "mondes-en-developpement",
    text: "Mondes en dÃ©veloppement (French)",
    parent: "NA",
    urlone: "http://www.mondesendeveloppement.eu/pages/auteurs/consignes-aux-auteurs.html",
    urltwo: "https://www.cairn.info/revue-mondes-en-developpement.htm"
  },
  {
    id: "monthly-weather-review",
    text: "Monthly Weather Review",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/mwre",
    urltwo: "NA"
  },
  {
    id: "moore-theological-college",
    text: "Moore Theological College",
    parent: "NA",
    urlone: "http://study.moore.edu.au/fileadmin/user_upload/study/StudentHandbook.pdf",
    urltwo: "NA"
  },
  {
    id: "moorlands-college",
    text: "Moorlands College",
    parent: "NA",
    urlone: "http://www.moorlands.ac.uk/",
    urltwo: "NA"
  },
  {
    id: "morphologie",
    text: "Morphologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "morphology",
    text: "Morphology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "morphomuseum",
    text: "MorphoMuseuM",
    parent: "palaeovertebrata",
    urlone: "http://morphomuseum.com/instructionsToAuthors",
    urltwo: "NA"
  },
  {
    id: "mortality",
    text: "Mortality",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CMRT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "motivation-and-emotion",
    text: "Motivation and Emotion",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "motivation-science",
    text: "Motivation Science",
    parent: "apa",
    urlone: "http://content.apa.org/journals/mot",
    urltwo: "NA"
  },
  {
    id: "motricite-cerebrale",
    text: "MotricitÃ© cÃ©rÃ©brale",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mount-sinai-journal-of-medicine",
    text: "Mount Sinai Journal of Medicine",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291931-7581/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "movement-ecology",
    text: "Movement Ecology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "msphere",
    text: "mSphere",
    parent: "american-society-for-microbiology",
    urlone: "http://msphere.asm.org/content/organization-and-format",
    urltwo: "NA"
  },
  {
    id: "msystems",
    text: "mSystems",
    parent: "american-society-for-microbiology",
    urlone: "http://msystems.asm.org/content/organization-and-format",
    urltwo: "NA"
  },
  {
    id: "mucosal-immunology",
    text: "Mucosal Immunology",
    parent: "clinical-pharmacology-and-therapeutics",
    urlone: "http://www.nature.com/mi/instructions_for_authors.html#References",
    urltwo: "NA"
  },
  {
    id: "multibody-system-dynamics",
    text: "Multibody System Dynamics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "multidimensional-systems-and-signal-processing",
    text: "Multidimensional Systems and Signal Processing",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "multidisciplinary-digital-publishing-institute",
    text: "Multidisciplinary Digital Publishing Institute",
    parent: "NA",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "multidisciplinary-respiratory-medicine",
    text: "Multidisciplinary Respiratory Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "multifunctional-materials",
    text: "Multifunctional Materials",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "multilingual-education",
    text: "Multilingual Education",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "multimed",
    text: "Multimed (Spanish)",
    parent: "NA",
    urlone: "http://www.revmultimed.sld.cu/templates/images/INSTRUCCIONES%20PARA%20AUTORES%20REVISTA%20MULTIMED.pdf",
    urltwo: "NA"
  },
  {
    id: "multimedia-systems",
    text: "Multimedia Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "multimedia-tools-and-applications",
    text: "Multimedia Tools and Applications",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "multimodal-technologies-and-interaction",
    text: "Multimodal Technologies and Interaction",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "multiphase-science-and-technology",
    text: "Multiphase Science and Technology",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/5af8c23d50e0a883.html",
    urltwo: "NA"
  },
  {
    id: "multiple-sclerosis-and-demyelinating-disorders",
    text: "Multiple Sclerosis and Demyelinating Disorders",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "multiple-sclerosis-and-related-disorders",
    text: "Multiple Sclerosis and Related Disorders",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "multiple-sclerosis-journal",
    text: "Multiple Sclerosis Journal",
    parent: "NA",
    urlone: "http://www.uk.sagepub.com/repository/binaries/pdf/MSJInstructions_to_Authors-21_04_08.pdf",
    urltwo: "NA"
  },
  {
    id: "muscle-and-nerve",
    text: "Muscle &amp; Nerve",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/10974598/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "musculoskeletal-science-and-practice",
    text: "Musculoskeletal Science and Practice",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "musculoskeletal-surgery",
    text: "MUSCULOSKELETAL SURGERY",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "museum-management-and-curatorship",
    text: "Museum Management and Curatorship",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMMC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "museum-national-dhistoire-naturelle",
    text: "MusÃ©um national d'Histoire naturelle",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "music-education-research",
    text: "Music Education Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CMUE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "music-theory-spectrum",
    text: "Music Theory Spectrum",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/mts/for_authors/mts%20style%20guide.pdf",
    urltwo: "NA"
  },
  {
    id: "mutagenesis",
    text: "Mutagenesis",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/mutage/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "mutation-research-fundamental-and-molecular-mechanisms-of-mutagenesis",
    text: "Mutation Research - Fundamental and Molecular Mechanisms of Mutagenesis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mutation-research-genetic-toxicology-and-environmental-mutagenesis",
    text: "Mutation Research - Genetic Toxicology and Environmental Mutagenesis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mutation-research-reviews-in-mutation-research",
    text: "Mutation Research-Reviews in Mutation Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mux-the-journal-of-mobile-user-experience",
    text: "mUX: The Journal of Mobile User Experience",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mycokeys",
    text: "MycoKeys",
    parent: "pensoft-journals",
    urlone: "https://mycokeys.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "mycologia",
    text: "Mycologia",
    parent: "NA",
    urlone: "http://www.mycologia.org/content/105/1/230.full",
    urltwo: "NA"
  },
  {
    id: "mycological-progress",
    text: "Mycological Progress",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mycology",
    text: "Mycology",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TMYC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "mycopathologia",
    text: "Mycopathologia",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mycorrhiza",
    text: "Mycorrhiza",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "mycoscience",
    text: "Mycoscience",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "mycotoxin-research",
    text: "Mycotoxin Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "myrmecological-news",
    text: "Myrmecological News",
    parent: "NA",
    urlone: "http://myrmecologicalnews.org/cms/index.php?option=com_content&amp;view=article&amp;id=31&amp;Itemid=40",
    urltwo: "https://github.com/citation-style-language/styles/pull/498"
  },
  {
    id: "nano-biomedicine-and-engineering",
    text: "Nano Biomedicine and Engineering",
    parent: "NA",
    urlone: "http://nanobe.org/index.php?journal=nbe&amp;page=about&amp;op=submissions#onlineSubmissions",
    urltwo: "NA"
  },
  {
    id: "nano-communication-networks",
    text: "Nano Communication Networks",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nano-convergence",
    text: "Nano Convergence",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nano-energy",
    text: "Nano Energy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nano-express",
    text: "Nano Express",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "nano-futures",
    text: "Nano Futures",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "nano-letters",
    text: "Nano Letters",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/nalefd/nalefd_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "nano-micro-letters",
    text: "Nano-Micro Letters",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nano-structures-and-nano-objects",
    text: "Nano-Structures &amp; Nano-Objects",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nano-today",
    text: "Nano Today",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nanoethics",
    text: "NanoEthics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nanoimpact",
    text: "NanoImpact",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nanomaterials",
    text: "Nanomaterials",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "nanomedicine",
    text: "Nanomedicine",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/nnm",
    urltwo: "NA"
  },
  {
    id: "nanoscale-horizons",
    text: "Nanoscale Horizons",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nanoscale-research-letters",
    text: "Nanoscale Research Letters",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nanoscale",
    text: "Nanoscale",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nanoscience-and-technology-an-international-journal",
    text: "Nanoscience and Technology: An International Journal",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/11e12455066dab5d.html",
    urltwo: "NA"
  },
  {
    id: "nanoscience-methods",
    text: "Nanoscience Methods",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TNME20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "nanotechnology-and-precision-engineering",
    text: "Nanotechnology and Precision Engineering",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nanotechnology-for-environmental-engineering",
    text: "Nanotechnology for Environmental Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nanotechnology",
    text: "Nanotechnology",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0957-4484",
    urltwo: "NA"
  },
  {
    id: "natbib-plainnat-author-date",
    text: "natbib - plainnat (author-date)",
    parent: "NA",
    urlone: "https://www.bibtex.com/s/bibliography-style-natbib-plainnat/",
    urltwo: "NA"
  },
  {
    id: "national-academy-science-letters",
    text: "National Academy Science Letters",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "national-accounting-review",
    text: "National Accounting Review",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/350.html",
    urltwo: "NA"
  },
  {
    id: "national-archives-of-australia",
    text: "National Archives of Australia",
    parent: "NA",
    urlone: "http://naa.gov.au/about-us/publications/fact-sheets/fs07.aspx",
    urltwo: "NA"
  },
  {
    id: "national-identities",
    text: "National Identities",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CNID20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "national-institute-of-health-research",
    text: "National Institute of Health Research",
    parent: "NA",
    urlone: "http://www.journalslibrary.nihr.ac.uk/information-for-authors/references",
    urltwo: "NA"
  },
  {
    id: "national-institute-of-organisation-dynamics-australia-harvard",
    text: "National Institute of Organisation Dynamics Australia - Harvard",
    parent: "NA",
    urlone: "https://www.nioda.org.au/wp-content/uploads/2019/07/NIODA-Referencing.pdf",
    urltwo: "NA"
  },
  {
    id: "national-institute-of-technology-karnataka",
    text: "National Institute of Technology Karnataka",
    parent: "NA",
    urlone: "http://nitk.ac.in/sites/default/files/public/curriculum/PG-Curriculum2016-revised.pdf",
    urltwo: "NA"
  },
  {
    id: "national-institute-of-technology-tiruchirappalli",
    text: "National Institute of Technology, Tiruchirappalli",
    parent: "NA",
    urlone: "https://www.nitt.edu/home/academics/rules/Thesis_Guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "national-library-of-medicine-brackets-no-et-al",
    text: 'National Library of Medicine (brackets, no "et al.")',
    parent: "vancouver-brackets-no-et-al",
    urlone: "http://www.ncbi.nlm.nih.gov/books/NBK7256/",
    urltwo: "NA"
  },
  {
    id: "national-library-of-medicine-grant-proposals",
    text: "National Library of Medicine (grant proposals with PMCID/PMID)",
    parent: "http://www.ncbi.nlm.nih.gov/books/NBK7260/#A41037",
    urlone: "http://www.nlm.nih.gov/pubs/formats/recommendedformats1991-full.pdf",
    urltwo: "http://publicaccess.nih.gov/citation_methods.htm"
  },
  {
    id: "national-library-of-medicine",
    text: "National Library of Medicine",
    parent: "vancouver",
    urlone: "http://www.ncbi.nlm.nih.gov/books/NBK7256/",
    urltwo: "NA"
  },
  {
    id: "national-marine-fisheries-service-national-environmental-policy-act",
    text: "National Marine Fisheries Service - National Environmental Policy Act",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/4426#issue-347066423",
    urltwo: "NA"
  },
  {
    id: "national-medical-journal-of-china",
    text: "National Medical Journal of China",
    parent: "vancouver-brackets",
    urlone: "http://www.nmjc.net.cn/tgxz.asp",
    urltwo: "NA"
  },
  {
    id: "national-natural-science-foundation-of-china",
    text: "National Natural Science Foundation of China (Chinese)",
    parent: "NA",
    urlone:
      "https://isisn.nsfc.gov.cn/egrantres/template/person/%E5%8F%82%E4%B8%8E%E4%BA%BA%E6%A8%A1%E6%9D%BF-%E9%80%9A%E7%94%A8.doc",
    urltwo: "NA"
  },
  {
    id: "national-science-foundation-grant-proposals",
    text: "National Science Foundation (grant proposals)",
    parent: "NA",
    urlone: "https://www.nsf.gov/pubs/policydocs/pappg19_1/pappg_2.jsp#IIC2e",
    urltwo: "NA"
  },
  {
    id: "national-university-of-singapore-department-of-geography-harvard",
    text: "National University of Singapore - Department of Geography - Harvard",
    parent: "NA",
    urlone: "http://www.fas.nus.edu.sg/geog/documents/undergraduate/HT_Guide_JG_2017.pdf",
    urltwo: "NA"
  },
  {
    id: "nationalities-papers",
    text: "Nationalities Papers",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CNAP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "nations-and-nationalism",
    text: "Nations and Nationalism",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14698129/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "natur-und-landschaft",
    text: "Natur und Landschaft (German)",
    parent: "NA",
    urlone: "https://www.natur-und-landschaft.de/de/service/beitrag-einreichen-1",
    urltwo: "NA"
  },
  {
    id: "naturae",
    text: "Naturae",
    parent: "museum-national-dhistoire-naturelle",
    urlone: "http://sciencepress.mnhn.fr/sites/default/files/periodiques/pdf/ia-naturae-2016-fr_vf.pdf",
    urltwo: "NA"
  },
  {
    id: "natural-computing",
    text: "Natural Computing",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "natural-gas-industry-b",
    text: "Natural Gas Industry B",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "natural-hazards-and-earth-system-sciences",
    text: "Natural Hazards and Earth System Sciences",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "natural-hazards-review",
    text: "Natural Hazards Review",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/nhrefo",
    urltwo: "NA"
  },
  {
    id: "natural-hazards",
    text: "Natural Hazards",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "natural-language-and-linguistic-theory",
    text: "Natural Language &amp; Linguistic Theory",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "natural-language-semantics",
    text: "Natural Language Semantics",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "natural-product-reports",
    text: "Natural Product Reports",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "natural-product-research",
    text: "Natural Product Research",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GNPL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "natural-products-and-bioprospecting",
    text: "Natural Products and Bioprospecting",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "natural-resources-research",
    text: "Natural Resources Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nature-biotechnology",
    text: "Nature Biotechnology",
    parent: "nature",
    urlone: "http://www.nature.com/nbt/pdf/gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-cell-biology",
    text: "Nature Cell Biology",
    parent: "nature",
    urlone: "http://www.nature.com/ncb/pdf/ncb_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-chemical-biology",
    text: "Nature Chemical Biology",
    parent: "nature",
    urlone: "http://www.nature.com/nchembio/pdf/nchembio_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-chemistry",
    text: "Nature Chemistry",
    parent: "nature",
    urlone: "http://www.nature.com/nchem/authors/submit.html#References",
    urltwo: "NA"
  },
  {
    id: "nature-climate-change",
    text: "Nature Climate Change",
    parent: "nature",
    urlone: "http://www.nature.com/nclimate/pdf/guide-for-authors.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-communications",
    text: "Nature Communications",
    parent: "nature",
    urlone: "http://www.nature.com/ncomms/authors/submit.html#References",
    urltwo: "NA"
  },
  {
    id: "nature-conservation",
    text: "Nature Conservation",
    parent: "pensoft-journals",
    urlone: "https://natureconservation.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "nature-digest",
    text: "Nature Digest",
    parent: "nature",
    urlone: "http://www.nature.com/ndigest/index.html",
    urltwo: "NA"
  },
  {
    id: "nature-genetics",
    text: "Nature Genetics",
    parent: "nature",
    urlone: "http://www.nature.com/ng/pdf/gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-geoscience",
    text: "Nature Geoscience",
    parent: "nature",
    urlone: "http://www.nature.com/ngeo/authors/how_submit.html#References",
    urltwo: "NA"
  },
  {
    id: "nature-immunology",
    text: "Nature Immunology",
    parent: "nature",
    urlone: "http://www.nature.com/ni/pdf/gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-materials",
    text: "Nature Materials",
    parent: "nature",
    urlone: "http://www.nature.com/nmat/authors/submit/index.html#references",
    urltwo: "NA"
  },
  {
    id: "nature-medicine",
    text: "Nature Medicine",
    parent: "nature",
    urlone: "http://www.nature.com/nm/pdf/gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-methods",
    text: "Nature Methods",
    parent: "nature",
    urlone: "http://www.nature.com/nmeth/pdf/gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-nanotechnology",
    text: "Nature Nanotechnology",
    parent: "nature",
    urlone: "http://www.nature.com/nnano/authors/submit/index.html#References",
    urltwo: "NA"
  },
  {
    id: "nature-neuroscience-brief-communications",
    text: "Nature Neuroscience (brief communications)",
    parent: "NA",
    urlone: "http://www.nature.com/neuro/authors/submit/index.html#manuscript",
    urltwo: "NA"
  },
  {
    id: "nature-neuroscience",
    text: "Nature Neuroscience",
    parent: "nature",
    urlone: "http://www.nature.com/neuro/authors/submit/index.html",
    urltwo: "NA"
  },
  {
    id: "nature-no-et-al",
    text: 'Nature (no "et al.")',
    parent: "NA",
    urlone: "http://mts-am.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "nature-no-superscript",
    text: "Nature (no superscript)",
    parent: "NA",
    urlone: "http://www.nature.com/clpt/clptguidetoauthors.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-photonics",
    text: "Nature Photonics",
    parent: "nature",
    urlone: "http://www.nature.com/nphoton/authors/submit/index.html#References",
    urltwo: "NA"
  },
  {
    id: "nature-physics",
    text: "Nature Physics",
    parent: "nature",
    urlone: "http://www.nature.com/nphys/authors/submit/index.html#References",
    urltwo: "NA"
  },
  {
    id: "nature-protocols",
    text: "Nature Protocols",
    parent: "nature",
    urlone: "http://www.nature.com/nprot/info/gta.html",
    urltwo: "NA"
  },
  {
    id: "nature-publishing-group-vancouver",
    text: "Nature Publishing Group - Vancouver",
    parent: "NA",
    urlone: "http://www.nature.com/tpj/TPJ_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-cancer",
    text: "Nature Reviews Cancer",
    parent: "nature",
    urlone: "http://mts-nrc.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-cardiology",
    text: "Nature Reviews Cardiology",
    parent: "nature",
    urlone: "http://www.nature.com/nrcardio/info/guide_reviews.html#ManuscriptPreparation",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-clinical-oncology",
    text: "Nature Reviews Clinical Oncology",
    parent: "nature",
    urlone: "http://www.nature.com/nrclinonc",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-drug-discovery",
    text: "Nature Reviews Drug Discovery",
    parent: "nature",
    urlone: "http://www.nature.com/nrd",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-endocrinology",
    text: "Nature Reviews Endocrinology",
    parent: "nature",
    urlone: "http://www.nature.com/nrendo",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-gastroenterology-and-hepatology",
    text: "Nature Reviews Gastroenterology &amp; Hepatology",
    parent: "nature",
    urlone: "http://www.nature.com/nrgastro",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-genetics",
    text: "Nature Reviews Genetics",
    parent: "nature",
    urlone: "http://www.nature.com/nrg",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-immunology",
    text: "Nature Reviews Immunology",
    parent: "nature",
    urlone: "http://www.nature.com/nri",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-microbiology",
    text: "Nature Reviews Microbiology",
    parent: "nature",
    urlone: "http://www.nature.com/nrmicro",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-molecular-cell-biology",
    text: "Nature Reviews Molecular Cell Biology",
    parent: "nature",
    urlone: "http://www.nature.com/nrm",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-nephrology",
    text: "Nature Reviews Nephrology",
    parent: "nature",
    urlone: "http://www.nature.com/nrneph",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-neurology",
    text: "Nature Reviews Neurology",
    parent: "nature",
    urlone: "http://www.nature.com/nrneurol",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-neuroscience",
    text: "Nature Reviews Neuroscience",
    parent: "nature",
    urlone: "http://www.nature.com/nrn",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-rheumatology",
    text: "Nature Reviews Rheumatology",
    parent: "nature",
    urlone: "http://www.nature.com/nrrheum",
    urltwo: "NA"
  },
  {
    id: "nature-reviews-urology",
    text: "Nature Reviews Urology",
    parent: "nature",
    urlone: "http://www.nature.com/nrurol",
    urltwo: "NA"
  },
  {
    id: "nature-structural-and-molecular-biology",
    text: "Nature Structural &amp; Molecular Biology",
    parent: "nature",
    urlone: "http://www.nature.com/nsmb/pdf/gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nature",
    text: "Nature",
    parent: "NA",
    urlone: "http://www.nature.com/nature/authors/gta/index.html#a5.4",
    urltwo: "http://www.nature.com/srep/publish/guidelines#references"
  },
  {
    id: "natures-sciences-societes",
    text: "Natures Sciences SociÃ©tÃ©s",
    parent: "NA",
    urlone: "https://www.nss-journal.org/author-information/instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "naunyn-schmiedebergs-archives-of-pharmacology",
    text: "Naunyn-Schmiedeberg's Archives of Pharmacology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nauplius",
    text: "Nauplius",
    parent: "NA",
    urlone: "http://www.scielo.br/revistas/nau/iinstruc.htm",
    urltwo: "NA"
  },
  {
    id: "navigation",
    text: "Navigation",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%292161-4296/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "nccr-mediality",
    text: "NCCR Mediality. Medienwandel - Medienwechsel - Medienwissen (German)",
    parent: "NA",
    urlone: "http://www.mediality.ch",
    urltwo: "NA"
  },
  {
    id: "ndt-and-e-international",
    text: "NDT and E International",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "necmettin-erbakan-universitesi-fen-ve-muhendislik-bilimleri-dergisi",
    text: "Necmettin Erbakan Ãœniversitesi Fen ve MÃ¼hendislik Bilimleri Dergisi (Turkish)",
    parent: "NA",
    urlone: "http://dergipark.gov.tr/neufmbd/writing-rules",
    urltwo: "NA"
  },
  {
    id: "nederlands-tijdschrift-voor-geneeskunde",
    text: "Nederlands Tijdschrift Voor Geneeskunde (Dutch)",
    parent: "vancouver-superscript",
    urlone: "http://www.ntvg.nl/over_ntvg/instructies_voor_literatuur",
    urltwo: "NA"
  },
  {
    id: "nefrologia-latinoamericana",
    text: "NefrologÃ­a Latinoamericana",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "negative-results",
    text: "Negative Results",
    parent: "cell",
    urlone: "http://www.negative-results.org/index.php/instructions-for-authors/#References",
    urltwo: "NA"
  },
  {
    id: "nehet",
    text: "NeHeT (French)",
    parent: "NA",
    urlone: "http://sfe-egyptologie.website/index.php/publications/la-revue-nehet",
    urltwo: "NA"
  },
  {
    id: "nejm-catalyst-innovations-in-care-delivery",
    text: "NEJM Catalyst Innovations in Care Delivery",
    parent: "NA",
    urlone: "https://catalyst.nejm.org/author-center/article-types",
    urltwo: "NA"
  },
  {
    id: "neobiota",
    text: "NeoBiota",
    parent: "pensoft-journals",
    urlone: "https://neobiota.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "neohelicon",
    text: "Neohelicon",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neonatologie-scan",
    text: "Neonatologie Scan",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "neonatology",
    text: "Neonatology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1661-7819",
    urltwo: "NA"
  },
  {
    id: "neophilologus",
    text: "Neophilologus",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neotropical-biology-and-conservation",
    text: "Neotropical Biology and Conservation",
    parent: "pensoft-journals",
    urlone: "https://neotropical.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "neotropical-entomology",
    text: "Neotropical Entomology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nephrologie-et-therapeutique",
    text: "Nephrologie et ThÃ©rapeutique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nephrology-dialysis-transplantation",
    text: "Nephrology Dialysis Transplantation",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/ndt/for_authors/general.html#REFERENCES",
    urltwo: "NA"
  },
  {
    id: "nephrology",
    text: "Nephrology",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291440-1797/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "nephron-clinical-practice",
    text: "Nephron Clinical Practice",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/228539",
    urltwo: "NA"
  },
  {
    id: "nephron-experimental-nephrology",
    text: "Nephron Experimental Nephrology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/228540",
    urltwo: "NA"
  },
  {
    id: "nephron-extra",
    text: "Nephron Extra",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/255126",
    urltwo: "NA"
  },
  {
    id: "nephron-physiology",
    text: "Nephron Physiology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/228541",
    urltwo: "NA"
  },
  {
    id: "nephron",
    text: "Nephron",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/223854",
    urltwo: "NA"
  },
  {
    id: "netherlands-heart-journal",
    text: "Netherlands Heart Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "netherlands-international-law-review",
    text: "Netherlands International Law Review",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "netnomics-economic-research-and-electronic-networking",
    text: "NETNOMICS: Economic Research and Electronic Networking",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "network-modeling-analysis-in-health-informatics-and-bioinformatics",
    text: "Network Modeling Analysis in Health Informatics and Bioinformatics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "networks-and-spatial-economics",
    text: "Networks and Spatial Economics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neue-juristische-wochenschrift",
    text: "Neue Juristische Wochenschrift (German)",
    parent: "NA",
    urlone: "http://rsw.beck.de/rsw/upload/NJW/Autorenhinweise_NJW_1-2014.pdf",
    urltwo: "NA"
  },
  {
    id: "neumologia-y-cirugia-de-torax",
    text: "NeumologÃ­a y CirugÃ­a de TÃ³rax",
    parent: "vancouver",
    urlone: "http://new.medigraphic.com/cgi-bin/instrucciones.cgi?IDREVISTA=18",
    urltwo: "NA"
  },
  {
    id: "neural-computing-and-applications",
    text: "Neural Computing and Applications",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neural-development",
    text: "Neural Development",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neural-networks",
    text: "Neural Networks",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neural-plasticity",
    text: "Neural Plasticity",
    parent: "NA",
    urlone: "https://www.hindawi.com/journals/np/guidelines/",
    urltwo: "NA"
  },
  {
    id: "neural-processing-letters",
    text: "Neural Processing Letters",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neuro-oncology",
    text: "Neuro-Oncology",
    parent: "american-medical-association",
    urlone: "http://www.oxfordjournals.org/our_journals/neuonc/for_authors/manuscript_instructions.html#References",
    urltwo: "NA"
  },
  {
    id: "neurobiology-of-aging-science",
    text: "Neurobiology of Aging Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurobiology-of-aging",
    text: "Neurobiology of Aging",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurobiology-of-disease",
    text: "Neurobiology of Disease",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurobiology-of-learning-and-memory",
    text: "Neurobiology of Learning and Memory",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurobiology-of-pain",
    text: "Neurobiology of Pain",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurobiology-of-sleep-and-circadian-rhythms",
    text: "Neurobiology of Sleep and Circadian Rhythms",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurobiology-of-stress",
    text: "Neurobiology of Stress",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurocase",
    text: "Neurocase",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NNCS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "neurochemical-research",
    text: "Neurochemical Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neurochemistry-international",
    text: "Neurochemistry International",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurochirurgie",
    text: "Neurochirurgie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurocomputing",
    text: "Neurocomputing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurocritical-care",
    text: "Neurocritical Care",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neurodegenerative-disease-management",
    text: "Neurodegenerative Disease Management",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/nmt",
    urltwo: "NA"
  },
  {
    id: "neurodegenerative-diseases",
    text: "Neurodegenerative Diseases",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/229093",
    urltwo: "NA"
  },
  {
    id: "neuroendocrinology-letters",
    text: "Neuroendocrinology Letters",
    parent: "NA",
    urlone: "http://www.nel.edu/instructions.htm",
    urltwo: "NA"
  },
  {
    id: "neuroendocrinology",
    text: "Neuroendocrinology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0194",
    urltwo: "NA"
  },
  {
    id: "neuroepidemiology",
    text: "Neuroepidemiology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0208",
    urltwo: "NA"
  },
  {
    id: "neuroepigenetics",
    text: "Neuroepigenetics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuroethics",
    text: "Neuroethics",
    parent: "springer-humanities-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neuroforum",
    text: "Neuroforum (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neurogenetics",
    text: "neurogenetics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neuroimage-clinical",
    text: "NeuroImage: Clinical",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuroimage",
    text: "NeuroImage",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuroimaging-clinics-of-north-america",
    text: "Neuroimaging Clinics of North America",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/neuroimaging-clinics-of-north-america/1052-5149/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "neuroimmunomodulation",
    text: "Neuroimmunomodulation",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0216",
    urltwo: "NA"
  },
  {
    id: "neuroinformatics",
    text: "Neuroinformatics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neurologia-argentina",
    text: "NeurologÃ­a Argentina (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/neurologia-argentina/1853-0028/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "neurologia-i-neurochirurgia-polska",
    text: "Neurologia i Neurochirurgia Polska",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurologia",
    text: "NeurologÃ­a (Spanish)",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/neurologia/0213-4853/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "neurological-sciences",
    text: "Neurological Sciences",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neurology-and-therapy",
    text: "Neurology and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neurology-india",
    text: "Neurology India",
    parent: "NA",
    urlone: "http://www.neurologyindia.com/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "neurology-international-open",
    text: "Neurology International Open",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "neurology-psychiatry-and-brain-research",
    text: "Neurology, Psychiatry and Brain Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurology",
    text: "Neurology",
    parent: "NA",
    urlone: "http://www.neurology.org/site/misc/auth2.xhtml#REFERENCESTYLE",
    urltwo: "NA"
  },
  {
    id: "neurometals",
    text: "NeuroMetals",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neuromolecular-medicine",
    text: "NeuroMolecular Medicine",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neuromuscular-disorders",
    text: "Neuromuscular Disorders",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuron",
    text: "Neuron",
    parent: "cell",
    urlone: "http://www.cell.com/neuron/authors",
    urltwo: "NA"
  },
  {
    id: "neuropathological-diseases",
    text: "Neuropathological Diseases",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/186c6b1c2324cc08.html",
    urltwo: "NA"
  },
  {
    id: "neuropathology",
    text: "Neuropathology",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291440-1789/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "neuropeptides",
    text: "Neuropeptides",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuropharmacology",
    text: "Neuropharmacology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurophotonics",
    text: "Neurophotonics",
    parent: "spie-journals",
    urlone: "http://neurophotonics.spiedigitallibrary.org/journal.aspx",
    urltwo: "NA"
  },
  {
    id: "neurophysiologie-clinique",
    text: "Neurophysiologie Clinique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuropraxis",
    text: "Neuropraxis (Dutch)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neuropsychiatric-electrophysiology",
    text: "Neuropsychiatric Electrophysiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neuropsychiatrie-de-lenfance-et-de-ladolescence",
    text: "Neuropsychiatrie de l'enfance et de l'adolescence",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuropsychiatrie",
    text: "neuropsychiatrie (German)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neuropsychiatry",
    text: "Neuropsychiatry",
    parent: "future-science-group",
    urlone: "http://www.futuremedicine.com/loi/npy",
    urltwo: "NA"
  },
  {
    id: "neuropsychobiology",
    text: "Neuropsychobiology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0224",
    urltwo: "NA"
  },
  {
    id: "neuropsychologia",
    text: "Neuropsychologia",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuropsychological-rehabilitation",
    text: "Neuropsychological Rehabilitation",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PNRH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "neuropsychology-review",
    text: "Neuropsychology Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neuropsychology",
    text: "Neuropsychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/neu",
    urltwo: "NA"
  },
  {
    id: "neuropsychopharmacology",
    text: "Neuropsychopharmacology",
    parent: "NA",
    urlone: "http://mts-npp.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions",
    urltwo: "NA"
  },
  {
    id: "neuroradiologie-scan",
    text: "Neuroradiologie Scan",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "neuroradiology",
    text: "Neuroradiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neurorehabilitation-and-neural-repair",
    text: "Neurorehabilitation and Neural Repair",
    parent: "NA",
    urlone: "http://www.sagepub.com/journalsProdDesc.nav?ct_p=manuscriptSubmission&amp;prodId=Journal201625",
    urltwo: "NA"
  },
  {
    id: "neuroreport",
    text: "NeuroReport",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/nr/accounts/ifauth.htm#23",
    urltwo: "NA"
  },
  {
    id: "neuroscience-and-biobehavioral-reviews",
    text: "Neuroscience and Biobehavioral Reviews",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuroscience-letters",
    text: "Neuroscience Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuroscience-research",
    text: "Neuroscience Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neuroscience",
    text: "Neuroscience",
    parent: "the-journal-of-neuroscience",
    urlone: "http://www.elsevier.com/journals/neuroscience/0306-4522/guide-for-authors#68001",
    urltwo: "NA"
  },
  {
    id: "neurosignals",
    text: "Neurosignals",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1424-8638",
    urltwo: "NA"
  },
  {
    id: "neurosurgery-clinics-of-north-america",
    text: "Neurosurgery Clinics of North America",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/neurosurgery-clinics-of-north-america/1042-3680/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "neurosurgery",
    text: "Neurosurgery",
    parent: "american-medical-association",
    urlone: "http://journals.lww.com/neurosurgery/Pages/InformationforAuthors.aspx",
    urltwo: "NA"
  },
  {
    id: "neurosurgical-focus",
    text: "Neurosurgical Focus",
    parent: "journal-of-neurosurgery",
    urlone: "http://thejns.org/",
    urltwo: "NA"
  },
  {
    id: "neurosurgical-review",
    text: "Neurosurgical Review",
    parent: "springer-basic-brackets-no-et-al-alphabetical",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neurotoxicity-research",
    text: "Neurotoxicity Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "neurotoxicology-and-teratology",
    text: "Neurotoxicology and Teratology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurotoxicology",
    text: "Neurotoxicology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "neurourology-and-urodynamics",
    text: "Neurourology and Urodynamics",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291520-6777/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "neurovascular-imaging",
    text: "Neurovascular Imaging",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "new-astronomy-reviews",
    text: "New Astronomy Reviews",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "new-astronomy",
    text: "New Astronomy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "new-biotechnology",
    text: "New BIOTECHNOLOGY",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "new-forests",
    text: "New Forests",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "new-genetics-and-society",
    text: "New Genetics and Society",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CNGS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "new-harts-rules-the-oxford-style-guide",
    text: "New Hart's Rules: The Oxford Style Guide",
    parent: "NA",
    urlone: "http://www.oxforddictionaries.com/secondary/harts_rules/",
    urltwo: "NA"
  },
  {
    id: "new-horizons-in-clinical-case-reports",
    text: "New Horizons in Clinical Case Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "new-horizons-in-translational-medicine",
    text: "New Horizons in Translational Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "new-ideas-in-psychology",
    text: "New Ideas in Psychology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "new-journal-of-chemistry",
    text: "New Journal of Chemistry",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "new-journal-of-physics",
    text: "New Journal of Physics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1367-2630",
    urltwo: "NA"
  },
  {
    id: "new-microbes-and-new-infections",
    text: "New Microbes and New Infections",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "new-negatives-in-plant-science",
    text: "New Negatives in Plant Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "new-phytologist",
    text: "New Phytologist",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291469-8137/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "new-review-of-film-and-television-studies",
    text: "New Review of Film and Television Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RFTS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "new-solutions",
    text: "New Solutions",
    parent: "NA",
    urlone: "http://baywood.com/authors/Guidelines/NS_Guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "new-testament-studies",
    text: "New Testament Studies",
    parent: "NA",
    urlone: "http://assets.cambridge.org/NTS/NTS_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "new-writing",
    text: "New Writing",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMNW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "new-zealand-dental-journal",
    text: "New Zealand Dental Journal",
    parent: "NA",
    urlone:
      "https://www.researchgate.net/profile/Lee_Smith28/publication/315481211_Understanding_Bachelor_of_Oral_Health_Student_and_educators%27_understandings_of_professionalism/links/58e2dedd92851c1b9d6a0796/Understanding-Bachelor-of-Oral-Health-Student-and-educators-understandings-of-professionalism.pdf",
    urltwo: "NA"
  },
  {
    id: "new-zealand-economic-papers",
    text: "New Zealand Economic Papers",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RNZP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "new-zealand-journal-of-agricultural-research",
    text: "New Zealand Journal of Agricultural Research",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "https://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=tnza20#refs",
    urltwo: "NA"
  },
  {
    id: "new-zealand-journal-of-educational-studies",
    text: "New Zealand Journal of Educational Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "new-zealand-journal-of-forestry-science",
    text: "New Zealand Journal of Forestry Science",
    parent: "NA",
    urlone: "http://nzjforestryscience.nz/index.php/nzjfs/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "new-zealand-journal-of-history",
    text: "New Zealand Journal of History",
    parent: "NA",
    urlone:
      "https://www.auckland.ac.nz/content/dam/uoa/auckland/arts/about-the-faculty/humanities/nzjh-style-guide.pdf",
    urltwo: "NA"
  },
  {
    id: "new-zealand-journal-of-medical-laboratory-science",
    text: "New Zealand Journal of Medical Laboratory Science",
    parent: "vancouver",
    urlone: "http://www.nzimls.org.nz/about-the-journal.html",
    urltwo: ""
  },
  {
    id: "new-zealand-plant-protection",
    text: "New Zealand Plant Protection",
    parent: "NA",
    urlone: "http://www.nzpps.org/conference.php?pg=authorship",
    urltwo: "NA"
  },
  {
    id: "new-zealand-veterinary-journal",
    text: "New Zealand Veterinary Journal",
    parent: "NA",
    urlone: "http://www.vetjournal.org.nz/sites/default/files/domain-19/NZVJ%20Instructions%20for%20Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "newborn-and-infant-nursing-reviews",
    text: "Newborn and Infant Nursing Reviews",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nfs-journal",
    text: "NFS Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nigerian-food-journal",
    text: "Nigerian Food Journal",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nigerian-journal-of-genetics",
    text: "Nigerian Journal of Genetics",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nigerian-journal-of-pharmaceutical-sciences",
    text: "Nigerian Journal of Pharmaceutical Sciences",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nigerian-journal-of-physiological-sciences",
    text: "Nigerian Journal Of Physiological Sciences",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nigerian-medical-journal",
    text: "Nigerian Medical Journal",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nitric-oxide",
    text: "Nitric Oxide",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "njas-wageningen-journal-of-life-sciences",
    text: "NJAS - Wageningen Journal of Life Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "non-coding-rna-research",
    text: "Non-coding RNA Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "non-coding-rna",
    text: "Non-Coding RNA",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "nonlinear-analysis-hybrid-systems",
    text: "Nonlinear Analysis: Hybrid Systems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nonlinear-analysis-real-world-applications",
    text: "Nonlinear Analysis: Real World Applications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nonlinear-analysis",
    text: "Nonlinear Analysis",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nonlinear-differential-equations-and-applications-nodea",
    text: "Nonlinear Differential Equations and Applications NoDEA",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nonlinear-dynamics",
    text: "Nonlinear Dynamics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nonlinear-processes-in-geophysics",
    text: "Nonlinear Processes in Geophysics",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nonlinearity",
    text: "Nonlinearity",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0951-7715",
    urltwo: "NA"
  },
  {
    id: "nordic-journal-of-botany",
    text: "Nordic Journal of Botany",
    parent: "oikos",
    urlone: "http://www.nordicjbotany.org/authors/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "nordic-journal-of-music-therapy",
    text: "Nordic Journal of Music Therapy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RNJM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "nordic-psychology",
    text: "Nordic Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RNPY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "nordic-pulp-and-paper-research-journal",
    text: "Nordic Pulp &amp; Paper Research Journal",
    parent: "NA",
    urlone: "http://www.npprj.se/html/main.asp?i=168&amp;h=2&amp;b=1&amp;c=100",
    urltwo: "NA"
  },
  {
    id: "nordic-social-work-research",
    text: "Nordic Social Work Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RNSW20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "norma-portuguesa-405",
    text: "Norma Portuguesa 405 (Portuguese)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/413",
    urltwo: "NA"
  },
  {
    id: "norois",
    text: "Norois (French)",
    parent: "NA",
    urlone: "https://journals.openedition.org/norois/1025#tocto1n11",
    urltwo: "NA"
  },
  {
    id: "norsk-apa-manual-note",
    text: "Norsk APA-manual - APA 7th edition (note)",
    parent: "NA",
    urlone: "https://kildekompasset.no/referansestiler/apa-7th-tilpasset-fotnoter",
    urltwo: "NA"
  },
  {
    id: "norsk-apa-manual",
    text: "Norsk APA-manual - APA 7th edition (author-date)",
    parent: "NA",
    urlone: "https://www.unit.no/tjenester/norsk-apa-referansestil",
    urltwo: "NA"
  },
  {
    id: "north-american-journal-of-aquaculture",
    text: "North American Journal of Aquaculture",
    parent: "american-fisheries-society",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=unaj20&amp;page=instructions#.UpVAx0Mju1E",
    urltwo: "NA"
  },
  {
    id: "north-american-journal-of-economics-and-finance",
    text: "North American Journal of Economics and Finance",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "north-american-journal-of-fisheries-management",
    text: "North American Journal of Fisheries Management",
    parent: "american-fisheries-society",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=ujfm20&amp;page=instructions#.UpU_eUMju1E",
    urltwo: "NA"
  },
  {
    id: "north-carolina-medical-journal",
    text: "North Carolina Medical Journal",
    parent: "american-medical-association",
    urlone: "http://www.ncmedicaljournal.com/submissions/original-articles/",
    urltwo: "NA"
  },
  {
    id: "northeastern-naturalist",
    text: "Northeastern Naturalist",
    parent: "NA",
    urlone: "http://www.eaglehill.us/programs/journals/nena/NENAauthor.pdf",
    urltwo: "NA"
  },
  {
    id: "northern-territory-law-journal-and-reports",
    text: "Northern Territory Law Journal and Reports",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nota-lepidopterologica",
    text: "Nota Lepidopterologica",
    parent: "pensoft-journals",
    urlone: "https://nl.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "notfall-rettungsmedizin",
    text: "Notfall +  Rettungsmedizin (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone:
      "http://www.springer.com/medicine/critical+care+and+emergency+medicine/journal/10049?detailsPage=pltci_2531731",
    urltwo: "NA"
  },
  {
    id: "notfallmedizin-up2date",
    text: "Notfallmedizin up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "nottingham-trent-university-library-harvard",
    text: "Nottingham Trent University Library - Harvard",
    parent: "NA",
    urlone: "https://www4.ntu.ac.uk/library/developing_skills/referencing_plagiarism/referencing_support/index.html",
    urltwo: "https://now.ntu.ac.uk/d2l/lor/viewer/view.d2l?ou=52836&amp;loIdentId=25435&amp;contentTopicId=1019510"
  },
  {
    id: "nouvelles-perspectives-en-sciences-sociales",
    text: "Nouvelles perspectives en sciences sociales (French - Canada)",
    parent: "NA",
    urlone: "http://npssrevue.ca/guide/biblio/",
    urltwo: "NA"
  },
  {
    id: "nowa-audiofonologia",
    text: "Nowa Audiofonologia (Polish)",
    parent: "NA",
    urlone: "http://www.nowaaudiofonologia.pl/instructions",
    urltwo: "NA"
  },
  {
    id: "npg-asia-materials",
    text: "NPG Asia Materials",
    parent: "nature-no-et-al",
    urlone: "http://mts-am.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "npg-neurologie-psychiatrie-geriatrie",
    text: "NPG Neurologie - Psychiatrie - GÃ©riatrie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nriag-journal-of-astronomy-and-geophysics",
    text: "NRIAG Journal of Astronomy and Geophysics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-and-particle-physics-proceedings",
    text: "Nuclear and Particle Physics Proceedings",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-energy-and-technology",
    text: "Nuclear Energy and Technology",
    parent: "pensoft-journals",
    urlone: "https://nucet.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "nuclear-engineering-and-design",
    text: "Nuclear Engineering and Design",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-engineering-and-technology",
    text: "Nuclear Engineering and Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-fusion",
    text: "Nuclear Fusion",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0029-5515",
    urltwo: "NA"
  },
  {
    id: "nuclear-inst-and-methods-in-physics-research-a",
    text: "Nuclear Inst. and Methods in Physics Research, A",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-inst-and-methods-in-physics-research-b",
    text: "Nuclear Inst. and Methods in Physics Research, B",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-materials-and-energy",
    text: "Nuclear Materials and Energy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-medicine-and-biology",
    text: "Nuclear Medicine and Biology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-medicine-and-molecular-imaging",
    text: "Nuclear Medicine and Molecular Imaging",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nuclear-physics-section-a",
    text: "Nuclear Physics, Section A",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-physics-section-b",
    text: "Nuclear Physics, Section B",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nuclear-receptor-signaling",
    text: "Nuclear Receptor Signaling",
    parent: "NA",
    urlone: "http://www.nrsignaling.org/authors",
    urltwo: "NA"
  },
  {
    id: "nuclear-science-and-engineering",
    text: "Nuclear Science and Engineering",
    parent: "american-nuclear-society",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=unse20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "nuclear-technology",
    text: "Nuclear Technology",
    parent: "american-nuclear-society",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=unct20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "nucleic-acids-research-web-server-issue",
    text: "Nucleic Acids Research - Web Server Issue",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/nar/for_authors/msprep_submission.html",
    urltwo: "https://academic.oup.com/nar/pages/submission_webserver"
  },
  {
    id: "nucleic-acids-research",
    text: "Nucleic Acids Research",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/nar/for_authors/msprep_submission.html",
    urltwo: "NA"
  },
  {
    id: "nucleus",
    text: "Nucleus",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "numerical-algorithms",
    text: "Numerical Algorithms",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "numerische-mathematik",
    text: "Numerische Mathematik",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nurse-education-in-practice",
    text: "Nurse Education in Practice",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nurse-education-today",
    text: "Nurse Education Today",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nurse-leader",
    text: "Nurse Leader",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nursing-for-womens-health",
    text: "Nursing for Women's Health",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nursing-inquiry",
    text: "Nursing Inquiry",
    parent: "chicago-author-date",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1440-1800/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "nursing-outlook",
    text: "Nursing Outlook",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nursingplus-open",
    text: "NursingPlus Open",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nutrient-cycling-in-agroecosystems",
    text: "Nutrient Cycling in Agroecosystems",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nutrients",
    text: "Nutrients",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "nutrition-and-diabetes",
    text: "Nutrition and Diabetes",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/nutd/nutd_new_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "nutrition-and-metabolism",
    text: "Nutrition &amp; Metabolism",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nutrition-clinique-et-metabolisme",
    text: "Nutrition clinique et mÃ©tabolisme",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nutrition-journal",
    text: "Nutrition Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "nutrition-metabolism-and-cardiovascular-diseases",
    text: "Nutrition, Metabolism and Cardiovascular Diseases",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nutrition-research-reviews",
    text: "Nutrition Research Reviews",
    parent: "NA",
    urlone:
      "https://www.cambridge.org/core/journals/nutrition-research-reviews/information/instructions-contributors#detailedmanuscriptpreparation",
    urltwo: "NA"
  },
  {
    id: "nutrition-research",
    text: "Nutrition Research",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "nutrition-reviews",
    text: "Nutrition Reviews",
    parent: "american-medical-association",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291753-4887/homepage/ForAuthors.html#refs",
    urltwo: "NA"
  },
  {
    id: "nutrition",
    text: "Nutrition",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "o-bib",
    text: "o-bib (German)",
    parent: "infoclio-de",
    urlone: "https://www.o-bib.de/about/submissions",
    urltwo: "NA"
  },
  {
    id: "obafemi-awolowo-university-faculty-of-technology",
    text: "Obafemi Awolowo University - Faculty of Technology",
    parent: "NA",
    urlone: "http://oautekconf.org/oau_style_guide.pdf",
    urltwo: "NA"
  },
  {
    id: "obere-extremitat",
    text: "Obere ExtremitÃ¤t (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/orthopedics/journal/11678?detailsPage=pltci_2428554",
    urltwo: "NA"
  },
  {
    id: "obesity-facts",
    text: "Obesity Facts",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/233731",
    urltwo: "NA"
  },
  {
    id: "obesity-medicine",
    text: "Obesity Medicine",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "obesity-research-and-clinical-practice",
    text: "Obesity Research &amp; Clinical Practice",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "obesity-surgery",
    text: "Obesity Surgery",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "obesity",
    text: "Obesity",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291930-739X/homepage/ForAuthors.html",
    urltwo: "http://onlinelibrary.wiley.com/doi/10.1002/oby.20821/pdf"
  },
  {
    id: "obstetrics-and-gynecology-science",
    text: "Obstetrics &amp; Gynecology Science",
    parent: "NA",
    urlone: "https://www.ogscience.org/index.php?body=instructions",
    urltwo: "NA"
  },
  {
    id: "occupation-participation-and-health",
    text: "Occupation, Participation and Health",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/~/media/Files/Journals/General%20PDFs/OTJR/OTJR_Guidelines.ashx",
    urltwo: "NA"
  },
  {
    id: "occupational-and-environmental-medicine",
    text: "Occupational and Environmental Medicine",
    parent: "bmj",
    urlone: "http://oem.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "occupational-medicine",
    text: "Occupational Medicine",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/occmed/for_authors/submission_online.html",
    urltwo: "NA"
  },
  {
    id: "ocean-and-coastal-management",
    text: "Ocean and Coastal Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ocean-dynamics",
    text: "Ocean Dynamics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ocean-engineering",
    text: "Ocean Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ocean-modelling",
    text: "Ocean Modelling",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ocean-science",
    text: "Ocean Science",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "oceanography",
    text: "Oceanography",
    parent: "NA",
    urlone: "http://tos.org/oceanography/guidelines",
    urltwo: "NA"
  },
  {
    id: "oceanologia",
    text: "Oceanologia",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ocular-immunology-and-inflammation",
    text: "Ocular Immunology &amp; Inflammation",
    parent: "american-medical-association",
    urlone: "http://informahealthcare.com/page/oii/Description#Instructions",
    urltwo: "NA"
  },
  {
    id: "ocular-oncology-and-pathology",
    text: "Ocular Oncology and Pathology",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/261183",
    urltwo: "NA"
  },
  {
    id: "odontology",
    text: "Odontology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "oecologia-australis",
    text: "Oecologia Australis",
    parent: "NA",
    urlone: "https://revistas.ufrj.br/index.php/oa/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "oecologia",
    text: "Oecologia",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "offa",
    text: "Offa - Berichte und Mitteilungen zur Urgeschichte, FrÃ¼hgeschichte und MittelalterarchÃ¤ologie",
    parent: "NA",
    urlone: "https://www.ufg.uni-kiel.de/de/schriften/verlinkte-dateien/offa-richtlinien-2018",
    urltwo: "NA"
  },
  {
    id: "oikos",
    text: "Oikos",
    parent: "NA",
    urlone: "http://www.oikos.ekol.lu.se/",
    urltwo: "NA"
  },
  {
    id: "omega",
    text: "Omega",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "oncogene",
    text: "Oncogene",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/onc/guide_for_authors.pdf",
    urltwo: "NA"
  },
  {
    id: "oncogenesis",
    text: "Oncogenesis",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/oncsis/guide_for_authors.pdf",
    urltwo: "NA"
  },
  {
    id: "oncoimmunology",
    text: "OncoImmunology",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=koni20",
    urltwo: "NA"
  },
  {
    id: "oncology-and-therapy",
    text: "Oncology and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "oncology-letters",
    text: "Oncology Letters",
    parent: "spandidos-publications",
    urlone: "http://www.spandidos-publications.com/pages/info_for_authors",
    urltwo: "NA"
  },
  {
    id: "oncology-reports",
    text: "Oncology Reports",
    parent: "spandidos-publications",
    urlone: "http://www.spandidos-publications.com/pages/info_for_authors",
    urltwo: "NA"
  },
  {
    id: "oncology-research-and-treatment",
    text: "Oncology Research and Treatment",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/224106",
    urltwo: "NA"
  },
  {
    id: "oncology-signaling",
    text: "Oncology Signaling",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "oncology",
    text: "Oncology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0232",
    urltwo: "NA"
  },
  {
    id: "oncoscience",
    text: "Oncoscience",
    parent: "oncotarget",
    urlone: "http://www.impactjournals.com/oncoscience",
    urltwo: "NA"
  },
  {
    id: "oncotarget",
    text: "Oncotarget",
    parent: "NA",
    urlone: "http://www.impactjournals.com/oncotarget",
    urltwo: "NA"
  },
  {
    id: "onderstepoort-journal-of-veterinary-research",
    text: "Onderstepoort Journal of Veterinary Research",
    parent: "NA",
    urlone: "http://www.ojvr.org/index.php/ojvr/pages/view/authors",
    urltwo: "http://openjournals.net/files/Ref/HARVARD2009%20Reference%20guide.pdf"
  },
  {
    id: "one-earth",
    text: "One Earth",
    parent: "cell-numeric-superscript",
    urlone: "https://www.cell.com/one-earth/author",
    urltwo: "NA"
  },
  {
    id: "one-health",
    text: "One Health",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "online-currents",
    text: "Online Currents",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "online-social-networks-and-media",
    text: "Online Social Networks and Media",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "op-journal",
    text: "OP Journal",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "open-biology",
    text: "Open Biology",
    parent: "proceedings-of-the-royal-society-b",
    urlone: "https://royalsociety.org/journals/authors/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "open-economies-review",
    text: "Open Economies Review",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "open-geospatial-data-software-and-standards",
    text: "Open Geospatial Data, Software and Standards",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "open-heart",
    text: "Open Heart",
    parent: "bmj",
    urlone: "http://openheart.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "open-learning-the-journal-of-open-distance-and-e-learning",
    text: "Open Learning: The Journal of Open, Distance and e-Learning",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=COPL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "open-window",
    text: "Open Window - Harvard",
    parent: "NA",
    urlone: "https://www.openwindow.co.za/wp-content/uploads/2018/11/2018-OW-Referencing-Guide-OPTIMISED-1.pdf",
    urltwo: "NA"
  },
  {
    id: "opennano",
    text: "OpenNano",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "operational-research",
    text: "Operational Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "operations-management-research",
    text: "Operations Management Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "operations-research-for-health-care",
    text: "Operations Research for Health Care",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "operations-research-letters",
    text: "Operations Research Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "operations-research-perspectives",
    text: "Operations Research Perspectives",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "operations-research",
    text: "Operations Research",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/opre/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "operative-dentistry",
    text: "Operative Dentistry",
    parent: "NA",
    urlone: "https://www.jopdent.com/authors/authors.html",
    urltwo: "NA"
  },
  {
    id: "operative-orthopadie-und-traumatologie",
    text: "Operative OrthopÃ¤die und Traumatologie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/orthopedics/journal/64?detailsPage=pltci_1661227",
    urltwo: "NA"
  },
  {
    id: "ophthalmic-and-physiological-optics",
    text: "Ophthalmic &amp; Physiological Optics",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1475-1313/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "ophthalmic-epidemiology",
    text: "Ophthalmic Epidemiology",
    parent: "american-medical-association",
    urlone: "http://informahealthcare.com/page/ope/Description#Instructions",
    urltwo: "NA"
  },
  {
    id: "ophthalmic-genetics",
    text: "Ophthalmic Genetics",
    parent: "american-medical-association",
    urlone: "http://informahealthcare.com/page/opg/Description#Instructions",
    urltwo: "NA"
  },
  {
    id: "ophthalmic-plastic-and-reconstructive-surgery",
    text: "Ophthalmic Plastic and Reconstructive Surgery",
    parent: "american-medical-association",
    urlone: "http://edmgr.ovid.com/oprs/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "ophthalmic-research",
    text: "Ophthalmic Research",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0259",
    urltwo: "NA"
  },
  {
    id: "ophthalmic-surgery-lasers-and-imaging-retina",
    text: "Ophthalmic Surgery, Lasers &amp; Imaging Retina",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/~/media/Journals/OSLI/cnav/OSLI%20InfoForAuthors.pdf",
    urltwo: "NA"
  },
  {
    id: "ophthalmologica-extra",
    text: "Ophthalmologica Extra",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/257560",
    urltwo: "NA"
  },
  {
    id: "ophthalmologica",
    text: "Ophthalmologica",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0267",
    urltwo: "NA"
  },
  {
    id: "ophthalmology-and-therapy",
    text: "Ophthalmology and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ophthalmology-retina",
    text: "Ophthalmology Retina",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/ophthalmology-retina/2468-6530/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "ophthalmology",
    text: "Ophthalmology",
    parent: "NA",
    urlone: "http://www.aaojournal.org/authorinfo#style",
    urltwo: "NA"
  },
  {
    id: "opsearch",
    text: "OPSEARCH",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "optica",
    text: "Optica",
    parent: "the-optical-society",
    urlone: "https://www.osapublishing.org/submit/templates/default.cfm",
    urltwo: "https://www.osapublishing.org/submit/style/oestyleguide.cfm"
  },
  {
    id: "optical-and-quantum-electronics",
    text: "Optical and Quantum Electronics",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "optical-engineering",
    text: "Optical Engineering",
    parent: "spie-journals",
    urlone: "http://spie.org/x867.xml",
    urltwo: "NA"
  },
  {
    id: "optical-fiber-technology",
    text: "Optical Fiber Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "optical-materials-express",
    text: "Optical Materials Express",
    parent: "optics-express",
    urlone: "https://www.osapublishing.org/submit/templates/default.cfm",
    urltwo: "https://www.osapublishing.org/submit/style/oestyleguide.cfm"
  },
  {
    id: "optical-materials-x",
    text: "Optical Materials: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "optical-materials",
    text: "Optical Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "optical-nanoscopy",
    text: "Optical Nanoscopy",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "optical-review",
    text: "Optical Review",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "optical-switching-and-networking",
    text: "Optical Switching and Networking",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "optics-and-laser-technology",
    text: "Optics and Laser Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "optics-and-lasers-in-engineering",
    text: "Optics and Lasers in Engineering",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "optics-and-photonics-news",
    text: "Optics and Photonics News",
    parent: "the-optical-society",
    urlone: "https://www.osapublishing.org/submit/templates/default.cfm",
    urltwo: "https://www.osapublishing.org/submit/style/oestyleguide.cfm"
  },
  {
    id: "optics-communications",
    text: "Optics Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "optics-express",
    text: "Optics Express",
    parent: "NA",
    urlone: "https://www.osapublishing.org/submit/style/oestyleguide.cfm",
    urltwo: "NA"
  },
  {
    id: "optics-letters",
    text: "Optics Letters",
    parent: "NA",
    urlone: "https://www.osapublishing.org/submit/templates/wordwp/OL_legacy.docx",
    urltwo: "NA"
  },
  {
    id: "optik",
    text: "Optik",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "optimization-and-engineering",
    text: "Optimization and Engineering",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "optimization-letters",
    text: "Optimization Letters",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "optimization",
    text: "Optimization",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GOPT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "opto-electronic-advances",
    text: "Opto-Electronic Advances",
    parent: "NA",
    urlone: "http://www.oejournal.org/J/OEA/Channel/15",
    urltwo: "NA"
  },
  {
    id: "opto-electronics-review",
    text: "Opto-Electronics Review",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "optometry-and-vision-science",
    text: "Optometry &amp; Vision Science",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/ovs/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "opuscula",
    text: "Opuscula",
    parent: "NA",
    urlone: "http://ecsi.se/wp-content/uploads/2018/11/20180200-Guide_for_contributors.pdf",
    urltwo: "NA"
  },
  {
    id: "or-spectrum",
    text: "OR Spectrum",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "oral-and-maxillofacial-surgery-cases",
    text: "Oral and Maxillofacial Surgery Cases",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "oral-and-maxillofacial-surgery",
    text: "Oral and Maxillofacial Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "oral-diseases",
    text: "Oral Diseases",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291601-0825/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "oral-oncology",
    text: "Oral Oncology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "oral-radiology",
    text: "Oral Radiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "oral-surgery-oral-medicine-oral-pathology-and-oral-radiology",
    text: "Oral Surgery, Oral Medicine, Oral Pathology and Oral Radiology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "order",
    text: "Order",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ore-geology-reviews",
    text: "Ore Geology Reviews",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "organic-agriculture",
    text: "Organic Agriculture",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "organic-and-biomolecular-chemistry",
    text: "Organic &amp; Biomolecular Chemistry",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "organic-chemistry-frontiers",
    text: "Organic Chemistry Frontiers",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "organic-electronics",
    text: "Organic Electronics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "organic-geochemistry",
    text: "Organic Geochemistry",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/organic-geochemistry/0146-6380/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "organic-letters",
    text: "Organic Letters",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/orlef7/orlef7_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "organic-process-research-and-development",
    text: "Organic Process Research &amp; Development",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/oprdfk/oprdfk_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "organisationsberatung-supervision-coaching",
    text: "Organisationsberatung, Supervision, Coaching (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "organised-sound",
    text: "Organised Sound",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/journals/organised-sound/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "organisms-diversity-and-evolution",
    text: "Organisms Diversity &amp; Evolution",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "organization-science",
    text: "Organization Science",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/orsc/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "organization-studies",
    text: "Organization Studies",
    parent: "apa-no-doi-no-issue",
    urlone: "http://www.uk.sagepub.com/repository/binaries/pdf/APA_reference_style.pdf",
    urltwo: "NA"
  },
  {
    id: "organization",
    text: "Organization",
    parent: "NA",
    urlone: "http://www.uk.sagepub.com/journals/Journal200981/manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "organizational-behavior-and-human-decision-processes",
    text: "Organizational Behavior and Human Decision Processes",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "organogenesis",
    text: "Organogenesis",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "organometallics",
    text: "Organometallics",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/orgnd7/orgnd7_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "organon",
    text: "Organon",
    parent: "NA",
    urlone: "http://www.ejournals.eu/Organon/menu/937/",
    urltwo: "NA"
  },
  {
    id: "oriental-pharmacy-and-experimental-medicine",
    text: "Oriental Pharmacy and Experimental Medicine",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "origins-of-life-and-evolution-of-biospheres",
    text: "Origins of Life and Evolution of Biospheres",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "orl",
    text: "ORL",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0275",
    urltwo: "NA"
  },
  {
    id: "ornitologia-neotropical",
    text: "OrnitologÃ­a Neotropical",
    parent: "NA",
    urlone: "http://www.neotropicalornithology.org/",
    urltwo: "NA"
  },
  {
    id: "orphanet-journal-of-rare-diseases",
    text: "Orphanet Journal of Rare Diseases",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ortho-magazine",
    text: "Ortho Magazine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "orthodontic-waves",
    text: "Orthodontic Waves",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "orthopadie-und-unfallchirurgie-up2date",
    text: "OrthopÃ¤die und Unfallchirurgie up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "orthopaedics-and-traumatology-surgery-and-research",
    text: "Orthopaedics &amp; Traumatology: Surgery &amp; Research",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "orthopedic-clinics-of-north-america",
    text: "Orthopedic Clinics of North America",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/orthopedic-clinics-of-north-america/0030-5898/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "orthopedics",
    text: "Orthopedics",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/~/media/Files/Journals/General%20PDFs/ORTHO/OBJinfoauth.pdf",
    urltwo: "NA"
  },
  {
    id: "oryx",
    text: "Oryx",
    parent: "NA",
    urlone: "http://www.oryxthejournal.org/files_for_linking/OryxInstructionsForContributors.pdf",
    urltwo: "NA"
  },
  {
    id: "osa-continuum",
    text: "OSA Continuum",
    parent: "the-optical-society",
    urlone: "https://www.osapublishing.org/submit/templates/default.cfm",
    urltwo: "https://www.osapublishing.org/submit/style/oestyleguide.cfm"
  },
  {
    id: "oscola-no-ibid",
    text: "OSCOLA (Oxford University Standard for Citation of Legal Authorities) (no Ibid.)",
    parent: "NA",
    urlone: "http://www.law.ox.ac.uk/publications/oscola.php",
    urltwo: "NA"
  },
  {
    id: "oscola",
    text: "OSCOLA (Oxford University Standard for Citation of Legal Authorities)",
    parent: "NA",
    urlone: "http://www.law.ox.ac.uk/publications/oscola.php",
    urltwo: "NA"
  },
  {
    id: "osteoarthritis-and-cartilage",
    text: "Osteoarthritis and Cartilage",
    parent: "american-medical-association",
    urlone: "http://www.elsevier.com/journals/osteoarthritis-and-cartilage/1063-4584/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "osteoporosis-and-sarcopenia",
    text: "Osteoporosis and Sarcopenia",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "osteoporosis-international",
    text: "Osteoporosis International",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "osterreichische-zeitschrift-fur-politikwissenschaft",
    text: "Ã–sterreichische Zeitschrift fÃ¼r Politikwissenschaft (German - Austria)",
    parent: "NA",
    urlone: "http://www.oezp.at/authors_e.php",
    urltwo: "NA"
  },
  {
    id: "osterreichische-zeitschrift-fur-soziologie",
    text: "Ã–sterreichische Zeitschrift fÃ¼r Soziologie (German)",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ostrava-journal-of-english-philology",
    text: "Ostrava Journal of English Philology",
    parent: "modern-language-association",
    urlone: "http://ff.osu.cz/kaa/13256/guidelines-for-contributors/",
    urltwo: "NA"
  },
  {
    id: "otolaryngologia-polska",
    text: "Otolaryngologia Polska",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "otolaryngology-case-reports",
    text: "Otolaryngology Case Reports",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "otolaryngology-head-and-neck-surgery",
    text: "Otolaryngology - Head and Neck Surgery",
    parent: "american-medical-association",
    urlone: "https://www.editorialmanager.com/otohns/accounts/AuthorPg.htm#refs",
    urltwo: "NA"
  },
  {
    id: "otto-von-guricke-universitat-magdeburg-medizinische-fakultat-numeric",
    text: "Otto-von-Guericke-UniversitÃ¤t Magdeburg - Medizinische FakultÃ¤t (numeric)",
    parent: "NA",
    urlone: "https://www.med.uni-magdeburg.de/promotion.html",
    urltwo: "NA"
  },
  {
    id: "owbarth-verlag",
    text: "O.W. Barth Verlag (German)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/581",
    urltwo: "NA"
  },
  {
    id: "oxford-art-journal",
    text: "Oxford Art Journal",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/oxartj/for_authors/index.html",
    urltwo: "NA"
  },
  {
    id: "oxford-centre-for-mission-studies-harvard",
    text: "Oxford Centre for Mission Studies - Harvard",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/662",
    urltwo: "NA"
  },
  {
    id: "oxford-german-studies",
    text: "Oxford German Studies",
    parent: "modern-humanities-research-association",
    urlone: "http://maneypublishing.com/index.php/journals/ogs/",
    urltwo: "NA"
  },
  {
    id: "oxford-review-of-education",
    text: "Oxford Review of Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CORE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "oxford-studies-in-ancient-philosophy",
    text: "Oxford Studies in Ancient Philosophy",
    parent: "NA",
    urlone: "http://www.oup.co.uk/academic/authors/instruct/series/osap/",
    urltwo: "NA"
  },
  {
    id: "oxford-studies-on-the-roman-economy",
    text: "Oxford Studies on the Roman Economy",
    parent: "NA",
    urlone: "http://oxrep.classics.ox.ac.uk/docs/OSREHouseStyle.pdf",
    urltwo: "NA"
  },
  {
    id: "oxford-the-university-of-new-south-wales",
    text: "The University of New South Wales - Oxford",
    parent: "NA",
    urlone: "https://acrobat.com/app.html#d=D4L9pqzbr*cjd5-FXKAdFw",
    urltwo: "NA"
  },
  {
    id: "oxford-university-press-humsoc",
    text: "Oxford University Press HUMSOC",
    parent: "NA",
    urlone:
      "http://www.oxfordjournals.org/our_journals/rev/for_authors/manuscript_instructions.html#Journal%20copyediting%20style",
    urltwo: "NA"
  },
  {
    id: "oxford-university-press-note",
    text: "Oxford University Press (note)",
    parent: "NA",
    urlone: "https://global.oup.com/academic/authors/author-guidelines/reference-styles/numbered-notes/",
    urltwo: "https://global.oup.com/academic/authors/author-guidelines/reference-styles/numbered-notes/us/"
  },
  {
    id: "oxford-university-press-scimed-author-date",
    text: "Oxford University Press SciMed (author-date)",
    parent: "NA",
    urlone: "http://femsre.oxfordjournals.org/for_authors/stylesheet.html",
    urltwo: "NA"
  },
  {
    id: "oxford-university-press-scimed-numeric",
    text: "Oxford University Press SciMed (numeric)",
    parent: "NA",
    urlone: "https://academic.oup.com/DocumentLibrary/JRR/mini_style_checklist.pdf",
    urltwo: "NA"
  },
  {
    id: "oxidation-of-metals",
    text: "Oxidation of Metals",
    parent: "NA",
    urlone: "http://www.springer.com/materials/special+types/journal/11085?detailsPage=pltci_3407509",
    urltwo: "NA"
  },
  {
    id: "oxymag",
    text: "Oxymag",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pacific-basin-finance-journal",
    text: "Pacific-Basin Finance Journal",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pacific-conservation-biology",
    text: "Pacific Conservation Biology",
    parent: "NA",
    urlone: "https://www.publish.csiro.au/pc/forauthors/AuthorInstructions#13",
    urltwo: "NA"
  },
  {
    id: "pacific-journal-of-mathematics-for-industry",
    text: "Pacific Journal of Mathematics for Industry",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pacific-science-review-a-natural-science-and-engineering",
    text: "Pacific Science Review A: Natural Science and Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pacific-science-review-b-humanities-and-social-sciences",
    text: "Pacific Science Review B: Humanities and Social Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pacific-science-review",
    text: "Pacific Science Review",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pacific-science",
    text: "Pacific Science",
    parent: "NA",
    urlone: "https://pacificscience.msubmit.net/cgi-bin/main.plex?form_type=display_auth_instructions",
    urltwo: "NA"
  },
  {
    id: "padagogische-hochschule-bern-institut-vorschulstufe-und-primarstufe",
    text: "PÃ¤dagogische Hochschule Bern - Institut Vorschulstufe und Primarstufe",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/4427#issuecomment-572441363",
    urltwo: "NA"
  },
  {
    id: "padagogische-hochschule-fachhochschule-nordwestschweiz",
    text: "PÃ¤dagogische Hochschule Fachhochschule Nordwestschweiz (German - Switzerland)",
    parent: "NA",
    urlone: "http://www.schreiben.zentrumlesen.ch/stud_zitieren_quellenverzeichnis_empfehlungen_schreibberatung.cfm",
    urltwo: "http://www.schreiben.zentrumlesen.ch/stud_zitieren_quellenverzeichnis.cfm"
  },
  {
    id: "padagogische-hochschule-heidelberg",
    text: "PÃ¤dagogische Hochschule Heidelberg (German)",
    parent: "NA",
    urlone: "http://www.ph-heidelberg.de/",
    urltwo: "NA"
  },
  {
    id: "padagogische-hochschule-vorarlberg",
    text: "PÃ¤dagogische Hochschule Vorarlberg (German)",
    parent: "NA",
    urlone:
      "http://www.ph-vorarlberg.ac.at/fileadmin/user_upload/RED_inst/RED_bac/Richtlinien_wissArb__April_2010_korrNa_end.pdf",
    urltwo: "NA"
  },
  {
    id: "paddy-and-water-environment",
    text: "Paddy and Water Environment",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "padiatrie-und-padologie",
    text: "PÃ¤diatrie &amp; PÃ¤dologie (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "padiatrie-up2date",
    text: "PÃ¤diatrie up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "paediatric-and-perinatal-epidemiology",
    text: "Paediatric and Perinatal Epidemiology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-3016/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "paediatric-respiratory-reviews",
    text: "Paediatric Respiratory Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pain-and-therapy",
    text: "Pain and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pain-management-nursing",
    text: "Pain Management Nursing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pain-management",
    text: "Pain Management",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/pmt",
    urltwo: "NA"
  },
  {
    id: "pain",
    text: "PAIN",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/pain/0304-3959/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "pakistan-journal-of-agricultural-sciences",
    text: "Pakistan Journal of Agricultural Sciences",
    parent: "NA",
    urlone: "https://www.pakjas.com.pk/InstructionsToAuthors.php",
    urltwo: "https://www.pakjas.com.pk/Author%20instructions.pdf"
  },
  {
    id: "pakistan-journal-of-medical-research",
    text: "Pakistan Journal of Medical Research",
    parent: "vancouver",
    urlone: "http://www.pmrc.org.pk/pjmr.htm",
    urltwo: "NA"
  },
  {
    id: "pakistan-journal-of-otolaryngology-head-and-neck-surgery",
    text: "Pakistan Journal of Otolaryngology, Head and Neck Surgery",
    parent: "vancouver-superscript",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pakistani-veterinary-journal",
    text: "Pakistan Veterinary Journal",
    parent: "NA",
    urlone: "http://www.pvj.com.pk/Instructions.htm",
    urltwo: "NA"
  },
  {
    id: "palaeobiodiversity-and-palaeoenvironments",
    text: "Palaeobiodiversity and Palaeoenvironments",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "palaeodiversity",
    text: "Palaeodiversity",
    parent: "NA",
    urlone: "http://www.palaeodiversity.org/authors.htm",
    urltwo: "NA"
  },
  {
    id: "palaeogeography-palaeoclimatology-palaeoecology",
    text: "Palaeogeography, Palaeoclimatology, Palaeoecology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "palaeontographica-abteilung-b-palaeobotany-palaeophytology",
    text: "Palaeontographica Abteilung B: Palaeobotany - Palaeophytology",
    parent: "NA",
    urlone: "https://www.schweizerbart.de/journals/palb/instructions",
    urltwo: "NA"
  },
  {
    id: "palaeontologia-electronica",
    text: "Palaeontologia Electronica",
    parent: "NA",
    urlone: "http://palaeo-electronica.org/guide2011.pdf",
    urltwo: "NA"
  },
  {
    id: "palaeontology",
    text: "Palaeontology",
    parent: "NA",
    urlone: "https://www.palass.org/sites/default/files/media/publications/for_authors/ITA_2016_v1.pdf",
    urltwo: "NA"
  },
  {
    id: "palaeovertebrata",
    text: "Palaeovertebrata",
    parent: "NA",
    urlone: "http://palaeovertebrata.com/instructionsToAuthors",
    urltwo: "NA"
  },
  {
    id: "palaeoworld",
    text: "Palaeoworld",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "palaios",
    text: "Palaios",
    parent: "NA",
    urlone: "http://palaios.ku.edu/instructions2011.pdf",
    urltwo: "NA"
  },
  {
    id: "palaontologische-zeitschrift",
    text: "PalÃ¤ontologische Zeitschrift",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "paleobiology",
    text: "Paleobiology",
    parent: "NA",
    urlone: "http://www.paleosoc.org/content/AuthorContribInstr.html",
    urltwo: "NA"
  },
  {
    id: "paleoceanography",
    text: "Paleoceanography",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "palynology",
    text: "Palynology",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TPAL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "pancreatology",
    text: "Pancreatology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "papillomavirus-research",
    text: "Papillomavirus Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "parallel-computing",
    text: "Parallel Computing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "parasite-epidemiology-and-control",
    text: "Parasite Epidemiology and Control",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "parasite",
    text: "Parasite",
    parent: "NA",
    urlone: "https://www.parasite-journal.org/author-information/instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "parasites-and-vectors",
    text: "Parasites &amp; Vectors",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "parasitology-international",
    text: "Parasitology International",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "parasitology-research",
    text: "Parasitology Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "parasitology",
    text: "Parasitology",
    parent: "NA",
    urlone: "http://assets.cambridge.org/PAR/PAR_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "parkinsonism-and-related-disorders",
    text: "Parkinsonism and Related Disorders",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "particle-and-fibre-toxicology",
    text: "Particle and Fibre Toxicology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "particuology",
    text: "Particuology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "past-and-present",
    text: "Past &amp; Present",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/past/for_authors/style_guide.docx",
    urltwo: "NA"
  },
  {
    id: "pastoral-care-in-education",
    text: "Pastoral Care in Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "pastoral-psychology",
    text: "Pastoral Psychology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pastoralism",
    text: "Pastoralism",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pathobiology",
    text: "Pathobiology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0291",
    urltwo: "NA"
  },
  {
    id: "pathogenesis",
    text: "Pathogenesis",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pathogens-and-disease",
    text: "Pathogens and Disease",
    parent: "oxford-university-press-scimed-author-date",
    urlone: "http://femspd.oxfordjournals.org/for_authors/stylesheet.html",
    urltwo: "NA"
  },
  {
    id: "pathogens",
    text: "Pathogens",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "pathology-and-oncology-research",
    text: "Pathology &amp; Oncology Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pathology-international",
    text: "Pathology International",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1440-1827/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "pathology-research-and-practice",
    text: "Pathology - Research and Practice",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pathology",
    text: "Pathology",
    parent: "vancouver-superscript",
    urlone: "http://edmgr.ovid.com/pat/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "pathophysiology",
    text: "Pathophysiology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "patient-education-and-counseling",
    text: "Patient Education and Counseling",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "patient-safety-in-surgery",
    text: "Patient Safety in Surgery",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pattern-analysis-and-applications",
    text: "Pattern Analysis and Applications",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pattern-recognition-letters",
    text: "Pattern Recognition Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pattern-recognition",
    text: "Pattern Recognition",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "peace-and-conflict-journal-of-peace-psychology",
    text: "Peace and Conflict: Journal of Peace Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pac",
    urltwo: "NA"
  },
  {
    id: "pedagogicka-orientace",
    text: "PedagogickÃ¡ orientace",
    parent: "apa",
    urlone: "https://journals.muni.cz/pedor/about/editorialPolicies#sectionPolicies",
    urltwo: "NA"
  },
  {
    id: "pedagogies-an-international-journal",
    text: "Pedagogies: An International Journal",
    parent: "apa",
    urlone: "http://www.tandfonline.com/toc/hped20/current#.U3qu-y_Wr7A",
    urltwo: "NA"
  },
  {
    id: "pedagogy-culture-and-society",
    text: "Pedagogy, Culture &amp; Society",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPCS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "pediatria-polska",
    text: "Pediatria Polska",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pediatric-allergy-and-immunology",
    text: "Pediatric Allergy and Immunology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1399-3038/homepage/Society.html",
    urltwo: "NA"
  },
  {
    id: "pediatric-anesthesia",
    text: "Pediatric Anesthesia",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1460-9592/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "pediatric-annals",
    text: "Pediatric Annals",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/journals/pedann/submit-an-article",
    urltwo: "NA"
  },
  {
    id: "pediatric-blood-and-cancer",
    text: "Pediatric Blood &amp; Cancer",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291545-5017/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "pediatric-cardiology",
    text: "Pediatric Cardiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pediatric-critical-care-medicine",
    text: "Pediatric Critical Care Medicine",
    parent: "critical-care-medicine",
    urlone: "http://journals.lww.com/pccmjournal/pages/default.aspx",
    urltwo: "NA"
  },
  {
    id: "pediatric-dental-journal",
    text: "Pediatric Dental Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pediatric-dentistry-case-reports",
    text: "Pediatric Dentistry Case Reports",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pediatric-diabetes",
    text: "Pediatric Diabetes",
    parent: "american-medical-association",
    urlone: "https://onlinelibrary.wiley.com/page/journal/13995448/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "pediatric-drugs",
    text: "Pediatric Drugs",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pediatric-hematology-oncology-journal",
    text: "Pediatric Hematology Oncology Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pediatric-infectious-disease-journal",
    text: "Pediatric Infectious Disease Journal",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/pidj/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "pediatric-infectious-disease",
    text: "Pediatric Infectious Disease",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pediatric-nephrology",
    text: "Pediatric Nephrology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pediatric-neurology",
    text: "Pediatric Neurology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pediatric-neurosurgery",
    text: "Pediatric Neurosurgery",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0305",
    urltwo: "NA"
  },
  {
    id: "pediatric-physical-therapy",
    text: "Pediatric Physical Therapy",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/ped-pt/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "pediatric-practice-and-research",
    text: "Pediatric Practice and Research",
    parent: "NA",
    urlone: "http://dergipark.gov.tr/pprjournal/page/1277",
    urltwo: "NA"
  },
  {
    id: "pediatric-pulmonology",
    text: "Pediatric Pulmonology",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/10990496/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "pediatric-radiology",
    text: "Pediatric Radiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pediatric-research",
    text: "Pediatric Research",
    parent: "NA",
    urlone: "http://www.nature.com/pr/pr_instructions_to_authors.pdf",
    urltwo: "NA"
  },
  {
    id: "pediatric-rheumatology",
    text: "Pediatric Rheumatology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pediatric-surgery-international",
    text: "Pediatric Surgery International",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pediatric-urology-case-reports",
    text: "Pediatric Urology Case Reports",
    parent: "NA",
    urlone: "http://pediatricurologycasereports.com/ojs/index.php/pucr/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "pediatrics-international",
    text: "Pediatrics International",
    parent: "vancouver",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291442-200X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "pediatrics",
    text: "Pediatrics",
    parent: "american-medical-association",
    urlone: "http://pediatrics.aappublications.org/site/misc/Pediatrics_Author_Guidelines.pdf",
    urltwo: "NA"
  },
  {
    id: "pedobiologia-journal-of-soil-ecology",
    text: "Pedobiologia - Journal of Soil Ecology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "peer-to-peer-networking-and-applications",
    text: "Peer-to-Peer Networking and Applications",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "peerj",
    text: "PeerJ",
    parent: "NA",
    urlone: "https://peerj.com/about/author-instructions/",
    urltwo: "NA"
  },
  {
    id: "pensoft-journals",
    text: "Pensoft Journals",
    parent: "NA",
    urlone: "https://zookeys.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "peptides",
    text: "Peptides",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "perfectionnement-en-pediatrie",
    text: "Perfectionnement en PÃ©diatrie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "performance-enhancement-and-health",
    text: "Performance Enhancement &amp; Health",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "performance-evaluation",
    text: "Performance Evaluation",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "periodica-mathematica-hungarica",
    text: "Periodica Mathematica Hungarica",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "periodicum-biologorum",
    text: "Periodicum Biologorum",
    parent: "NA",
    urlone: "http://hrcak.srce.hr/ojs/index.php/periodicum_biologorum/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "periodontology-2000",
    text: "Periodontology 2000",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1600-0757/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "perioperative-care-and-operating-room-management",
    text: "Perioperative Care and Operating Room Management",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "perioperative-medicine",
    text: "Perioperative Medicine",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "permafrost-and-periglacial-processes",
    text: "Permafrost and Periglacial Processes",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291099-1530/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "person-centered-and-experiential-psychotherapies",
    text: "Person-Centered &amp; Experiential Psychotherapies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPCP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "personal-and-ubiquitous-computing",
    text: "Personal and Ubiquitous Computing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "personality-and-individual-differences",
    text: "Personality and Individual Differences",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "personality-disorders-theory-research-and-treatment",
    text: "Personality Disorders: Theory, Research, and Treatment",
    parent: "apa",
    urlone: "http://content.apa.org/journals/per",
    urltwo: "NA"
  },
  {
    id: "personalized-medicine-in-psychiatry",
    text: "Personalized Medicine in Psychiatry",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "personalized-medicine-universe",
    text: "Personalized Medicine Universe",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "personalized-medicine",
    text: "Personalized Medicine",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/pme",
    urltwo: "NA"
  },
  {
    id: "perspectives-in-ecology-and-conservation",
    text: "Perspectives in Ecology and Conservation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "perspectives-in-medicine",
    text: "Perspectives in Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "perspectives-in-plant-ecology-evolution-and-systematics",
    text: "Perspectives in Plant Ecology, Evolution and Systematics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "perspectives-in-science",
    text: "Perspectives in Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "perspectives-on-medical-education",
    text: "Perspectives on Medical Education",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "perspectives-on-politics",
    text: "Perspectives on Politics",
    parent: "american-political-science-association",
    urlone: "http://journals.cambridge.org/action/displayJournal?jid=PPS",
    urltwo: "NA"
  },
  {
    id: "perspectives-on-sexual-and-reproductive-health",
    text: "Perspectives on Sexual and Reproductive Health",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1931-2393/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "perspectives-policy-and-practice-in-higher-education",
    text: "Perspectives: Policy and Practice in Higher Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TPSP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "perspectives",
    text: "Perspectives",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RMPS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "pervasive-and-mobile-computing",
    text: "Pervasive and Mobile Computing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pesquisa-agropecuaria-brasileira",
    text: "Pesquisa AgropecuÃ¡ria Brasileira (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://seer.sct.embrapa.br/index.php/pab/about/submissions#authorGuidelines",
    urltwo: "http://www.scielo.br/revistas/pab/iinstruc.htm"
  },
  {
    id: "pest-management-science",
    text: "Pest Management Science",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1526-4998/homepage/ForAuthors.html#preparing",
    urltwo: "NA"
  },
  {
    id: "pesticide-biochemistry-and-physiology",
    text: "Pesticide Biochemistry and Physiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pesticides-people-and-nature",
    text: "Pesticides, People and Nature",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/33b67499180f0876.html",
    urltwo: "NA"
  },
  {
    id: "petit-chicago-author-date",
    text: "Petit Chicago (author-date, French - Canada)",
    parent: "NA",
    urlone: "http://guides.bib.umontreal.ca/disciplines/159-Sociologie",
    urltwo: "http://guides.bib.umontreal.ca/uploads/uploads/original/Petit_Chicago_Plus_Cycles_Sup_.pdf"
  },
  {
    id: "petroleum-geoscience",
    text: "Petroleum Geoscience",
    parent: "the-geological-society-of-london",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "petroleum-research",
    text: "Petroleum Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "petroleum-science",
    text: "Petroleum Science",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "petroleum",
    text: "Petroleum",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pflugers-archiv-european-journal-of-physiology",
    text: "PflÃ¼gers Archiv - European Journal of Physiology",
    parent: "springer-basic-brackets-no-et-al-alphabetical",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pharmaceutical-bioprocessing",
    text: "Pharmaceutical Bioprocessing",
    parent: "future-science-group",
    urlone: "http://www.future-science.com/loi/pbp",
    urltwo: "NA"
  },
  {
    id: "pharmaceutical-medicine",
    text: "Pharmaceutical Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pharmaceutical-methods",
    text: "Pharmaceutical Methods",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pharmaceutical-patent-analyst",
    text: "Pharmaceutical Patent Analyst",
    parent: "future-science-group",
    urlone: "http://www.future-science.com/loi/ppa",
    urltwo: "NA"
  },
  {
    id: "pharmaceutical-research",
    text: "Pharmaceutical Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pharmaceuticals",
    text: "Pharmaceuticals",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "pharmaceutics",
    text: "Pharmaceutics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "pharmaceutisch-weekblad",
    text: "Pharmaceutisch Weekblad (Dutch)",
    parent: "vancouver-brackets",
    urlone: "http://www.pw.nl/auteursinformatie",
    urltwo: "NA"
  },
  {
    id: "pharmacia",
    text: "Pharmacia",
    parent: "pensoft-journals",
    urlone: "https://pharmacia.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "pharmacoeconomics-italian-research-articles",
    text: "PharmacoEconomics Italian Research Articles",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pharmacoeconomics-spanish-research-articles",
    text: "PharmacoEconomics Spanish Research Articles (Spanish)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pharmacoeconomics",
    text: "PharmacoEconomics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pharmacoepidemiology-and-drug-safety",
    text: "Pharmacoepidemiology and Drug Safety",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291099-1557/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "pharmacogenomics",
    text: "Pharmacogenomics",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/pgs",
    urltwo: "NA"
  },
  {
    id: "pharmacognosy-journal",
    text: "Pharmacognosy Journal",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pharmacognosy-magazine",
    text: "Pharmacognosy Magazine",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.phcog.com/contributors.asp#pm",
    urltwo: "NA"
  },
  {
    id: "pharmacognosy-reviews",
    text: "Pharmacognosy Reviews",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.phcogrev.com/contributors.asp#pm",
    urltwo: "NA"
  },
  {
    id: "pharmacological-reports",
    text: "Pharmacological Reports",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pharmacological-research",
    text: "Pharmacological Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pharmacological-reviews",
    text: "Pharmacological Reviews",
    parent: "american-society-for-pharmacology-and-experimental-therapeutics",
    urlone: "http://pharmrev.aspetjournals.org/site/misc/ifora.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "pharmacology-and-therapeutics",
    text: "Pharmacology and Therapeutics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pharmacology-biochemistry-and-behavior",
    text: "Pharmacology, Biochemistry and Behavior",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pharmacology",
    text: "Pharmacology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0313",
    urltwo: "NA"
  },
  {
    id: "pharmacopsychiatry",
    text: "Pharmacopsychiatry",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "pharmacotherapy",
    text: "Pharmacotherapy",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291875-9114/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "pharmacy-today",
    text: "Pharmacy Today",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pharmacy",
    text: "Pharmacy",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "pharmanutrition",
    text: "PharmaNutrition",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "phase-transitions",
    text: "Phase Transitions",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GPHT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "phenomenology-and-the-cognitive-sciences",
    text: "Phenomenology and the Cognitive Sciences",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "philippika",
    text: "Philippika (German)",
    parent: "NA",
    urlone: "http://www.harrassowitz-verlag.de/category_281.ahtml",
    urltwo: "NA"
  },
  {
    id: "philippine-political-science-journal",
    text: "Philippine Political Science Journal",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPSJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "philipps-universitat-marburg-note",
    text: "Philipps-UniversitÃ¤t Marburg - Erziehungswissenschaften (note, German)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/files/1839191/ZitierrichtlinienFB21Lehramt.pdf",
    urltwo: "NA"
  },
  {
    id: "philosophia-scientiae",
    text: "Philosophia ScientiÃ¦",
    parent: "NA",
    urlone: "http://philosophiascientiae.revues.org/633",
    urltwo: "NA"
  },
  {
    id: "philosophia",
    text: "Philosophia",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "philosophical-explorations",
    text: "Philosophical Explorations",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPEX20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "philosophical-psychology",
    text: "Philosophical Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CPHP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "philosophical-studies",
    text: "Philosophical Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "philosophical-transactions-of-the-royal-society-a",
    text: "Philosophical Transactions of the Royal Society A",
    parent: "proceedings-of-the-royal-society-b",
    urlone: "https://royalsociety.org/journals/authors/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "philosophical-transactions-of-the-royal-society-b",
    text: "Philosophical Transactions of the Royal Society B",
    parent: "proceedings-of-the-royal-society-b",
    urlone: "https://royalsociety.org/journals/authors/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "philosophies",
    text: "Philosophies",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "philosophiques",
    text: "Philosophiques (French)",
    parent: "NA",
    urlone: "http://philosophiques.ca/directives-pour-la-preparation-des-manuscrits/",
    urltwo: "NA"
  },
  {
    id: "philosophy-and-public-affairs",
    text: "Philosophy &amp; Public Affairs",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/10884963/homepage/forauthors.html#_5._PREPARING_THE",
    urltwo: "NA"
  },
  {
    id: "philosophy-and-technology",
    text: "Philosophy &amp; Technology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "philosophy-ethics-and-humanities-in-medicine",
    text: "Philosophy, Ethics, and Humanities in Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "philosophy-of-management",
    text: "Philosophy of Management",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "photoacoustics",
    text: "Photoacoustics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "photochemical-and-photobiological-sciences",
    text: "Photochemical &amp; Photobiological Sciences",
    parent: "royal-society-of-chemistry-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "photochemistry-and-photobiology",
    text: "Photochemistry and Photobiology",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/17511097/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "photodiagnosis-and-photodynamic-therapy",
    text: "Photodiagnosis and Photodynamic Therapy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "photonic-network-communications",
    text: "Photonic Network Communications",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "photonics",
    text: "Photonics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "photosynthesis-research",
    text: "Photosynthesis Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "photosynthetica",
    text: "Photosynthetica",
    parent: "NA",
    urlone: "https://ps.ueb.cas.cz/artkey/inf-990000-0200_For-Authors.php",
    urltwo: "NA"
  },
  {
    id: "phycological-research",
    text: "Phycological Research",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291440-1835/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "phyllomedusa",
    text: "Phyllomedusa",
    parent: "NA",
    urlone: "http://www.phyllomedusa.esalq.usp.br/instruction.pdf",
    urltwo: "NA"
  },
  {
    id: "physica-a-statistical-mechanics-and-its-applications",
    text: "Physica A: Statistical Mechanics and its Applications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physica-b-physics-of-condensed-matter",
    text: "Physica B: Physics of Condensed Matter",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physica-c-superconductivity-and-its-applications",
    text: "Physica C: Superconductivity and its applications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physica-d-nonlinear-phenomena",
    text: "Physica D: Nonlinear Phenomena",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physica-e-low-dimensional-systems-and-nanostructures",
    text: "Physica E: Low-dimensional Systems and Nanostructures",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physica-medica",
    text: "Physica Medica",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physica-scripta",
    text: "Physica Scripta",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1402-4896",
    urltwo: "NA"
  },
  {
    id: "physical-biology",
    text: "Physical Biology",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1478-3975",
    urltwo: "NA"
  },
  {
    id: "physical-chemistry-chemical-physics",
    text: "Physical Chemistry Chemical Physics",
    parent: "royal-society-of-chemistry-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-communication",
    text: "Physical Communication",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-education-and-sport-pedagogy",
    text: "Physical Education and Sport Pedagogy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CPES20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "physical-review-a",
    text: "Physical Review A",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-accelerators-and-beams",
    text: "Physical Review Accelerators and Beams",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-applied",
    text: "Physical Review Applied",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-b",
    text: "Physical Review B",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-c",
    text: "Physical Review C",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-d",
    text: "Physical Review D",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-e",
    text: "Physical Review E",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-fluids",
    text: "Physical Review Fluids",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-letters",
    text: "Physical Review Letters",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-materials",
    text: "Physical Review Materials",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-physics-education-research",
    text: "Physical Review Physics Education Research",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-research",
    text: "Physical Review Research",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-review-x",
    text: "Physical Review X",
    parent: "american-physics-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physical-therapy-in-sport",
    text: "Physical Therapy in Sport",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-and-chemistry-of-liquids",
    text: "Physics and Chemistry of Liquids",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GPCH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "physics-and-chemistry-of-minerals",
    text: "Physics and Chemistry of Minerals",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "physics-and-chemistry-of-the-earth",
    text: "Physics and Chemistry of the Earth",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-and-imaging-in-radiation-oncology",
    text: "Physics and Imaging in Radiation Oncology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-education",
    text: "Physics Education",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0031-9120",
    urltwo: "NA"
  },
  {
    id: "physics-in-medicine-and-biology",
    text: "Physics in Medicine and Biology",
    parent: "institute-of-physics-harvard",
    urlone: "http://iopscience.iop.org/0031-9155",
    urltwo: "NA"
  },
  {
    id: "physics-in-medicine",
    text: "Physics in Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-letters-a",
    text: "Physics Letters A",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-letters-b",
    text: "Physics Letters B",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-of-fluids",
    text: "Physics of Fluids",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "physics-of-life-reviews",
    text: "Physics of Life Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-of-plasmas",
    text: "Physics of Plasmas",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "physics-of-the-dark-universe",
    text: "Physics of the Dark Universe",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-of-the-earth-and-planetary-interiors",
    text: "Physics of the Earth and Planetary Interiors",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-reports",
    text: "Physics Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physics-uspekhi",
    text: "Physics-Uspekhi",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1063-7869",
    urltwo: "NA"
  },
  {
    id: "physikalische-medizin-rehabilitationsmedizin-kurortmedizin",
    text: "Physikalische Medizin, Rehabilitationsmedizin, Kurortmedizin",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "physiologia-plantarum",
    text: "Physiologia Plantarum",
    parent: "NA",
    urlone: "http://physiologiaplantarum.org/instructions-for-manuscrip/#Citations%20and%20References",
    urltwo: "NA"
  },
  {
    id: "physiological-and-biochemical-zoology",
    text: "Physiological and Biochemical Zoology",
    parent: "NA",
    urlone: "http://www.press.uchicago.edu/journals/pbz/instruct.html?journal=pbz",
    urltwo: "NA"
  },
  {
    id: "physiological-and-molecular-plant-pathology",
    text: "Physiological and Molecular Plant Pathology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physiological-genomics",
    text: "Physiological Genomics",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physiological-measurement",
    text: "Physiological Measurement",
    parent: "institute-of-physics-harvard",
    urlone: "http://iopscience.iop.org/0967-3334",
    urltwo: "NA"
  },
  {
    id: "physiological-reviews",
    text: "Physiological Reviews",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physiology-and-behavior",
    text: "Physiology &amp; Behavior",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physiology-and-molecular-biology-of-plants",
    text: "Physiology and Molecular Biology of Plants",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "physiology",
    text: "Physiology",
    parent: "american-physiological-society",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "physiotherapy",
    text: "Physiotherapy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "phytochemistry-letters",
    text: "Phytochemistry Letters",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "phytochemistry-reviews",
    text: "Phytochemistry Reviews",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "phytochemistry",
    text: "Phytochemistry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "phytokeys",
    text: "PhytoKeys",
    parent: "pensoft-journals",
    urlone: "https://phytokeys.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "phytomedicine",
    text: "Phytomedicine",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "phytoparasitica",
    text: "Phytoparasitica",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "phytopathologia-mediterranea",
    text: "Phytopathologia Mediterranea",
    parent: "NA",
    urlone: "https://oajournals.fupress.net/index.php/pm/about/submissions",
    urltwo: "NA"
  },
  {
    id: "phytopathology",
    text: "Phytopathology",
    parent: "american-phytopathological-society",
    urlone: "http://apsjournals.apsnet.org/loi/phyto",
    urltwo: "NA"
  },
  {
    id: "phytotaxa",
    text: "Phytotaxa",
    parent: "NA",
    urlone: "https://www.mapress.com/j/pt/pages/view/forauthors",
    urltwo: "NA"
  },
  {
    id: "pid-psychotherapie-im-dialog",
    text: "PiD - Psychotherapie im Dialog",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "pilot-and-feasibility-studies",
    text: "Pilot and Feasibility Studies",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pisa-university-press",
    text: "Pisa University Press",
    parent: "NA",
    urlone: "http://www.pisauniversitypress.it/",
    urltwo: "NA"
  },
  {
    id: "pituitary",
    text: "Pituitary",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "placenta",
    text: "Placenta",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "planetary-and-space-science",
    text: "Planetary and Space Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "planetary-science",
    text: "Planetary Science",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "planning-perspectives",
    text: "Planning Perspectives",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPPE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "planning-practice-and-research",
    text: "Planning Practice &amp; Research",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=cppr20&amp;page=instructions#mp_style",
    urltwo: "NA"
  },
  {
    id: "planning-theory-and-practice",
    text: "Planning Theory &amp; Practice",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPTP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "plant-and-cell-physiology",
    text: "Plant &amp; Cell Physiology",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/pcp/for_authors/general.html#advice",
    urltwo: "NA"
  },
  {
    id: "plant-and-soil",
    text: "Plant and Soil",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-biology",
    text: "Plant Biology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291438-8677/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "plant-biotechnology-journal",
    text: "Plant Biotechnology Journal",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1467-7652/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "plant-biotechnology-reports",
    text: "Plant Biotechnology Reports",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-cell-and-environment",
    text: "Plant, Cell &amp; Environment",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1365-3040/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "plant-cell-reports",
    text: "Plant Cell Reports",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-cell-tissue-and-organ-culture",
    text: "Plant Cell, Tissue and Organ Culture",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-disease",
    text: "Plant Disease",
    parent: "american-phytopathological-society",
    urlone: "http://apsjournals.apsnet.org/loi/pdis",
    urltwo: "NA"
  },
  {
    id: "plant-diversity",
    text: "Plant Diversity",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "plant-ecology",
    text: "Plant Ecology",
    parent: "springer-basic-author-date-no-et-al",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-foods-for-human-nutrition",
    text: "Plant Foods for Human Nutrition",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-gene",
    text: "Plant Gene",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "plant-genetic-resources-characterization-and-utilization",
    text: "Plant Genetic Resources - Characterization and Utilization",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/journals/plant-genetic-resources/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "plant-growth-regulation",
    text: "Plant Growth Regulation",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-methods",
    text: "Plant Methods",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-molecular-biology-reporter",
    text: "Plant Molecular Biology Reporter",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-molecular-biology",
    text: "Plant Molecular Biology",
    parent: "springer-basic-author-date-no-et-al",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-pathology",
    text: "Plant Pathology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-3059/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "plant-physiology-and-biochemistry",
    text: "Plant Physiology and Biochemistry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "plant-physiology",
    text: "Plant Physiology",
    parent: "NA",
    urlone: "http://www.plantphysiol.org/site/misc/ifora.xhtml#Literature%20cited",
    urltwo: "NA"
  },
  {
    id: "plant-reproduction",
    text: "Plant Reproduction",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plant-science",
    text: "Plant Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "plant-signaling-and-behavior",
    text: "Plant Signaling &amp; Behavior",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "plant-sociology",
    text: "Plant Sociology",
    parent: "pensoft-journals",
    urlone: "https://plantsociology.arphahub.com/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "plant-systematics-and-evolution",
    text: "Plant Systematics and Evolution",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "planta-medica-international-open",
    text: "Planta Medica International Open",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "planta-medica",
    text: "Planta Medica",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "planta",
    text: "Planta",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plants",
    text: "Plants",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "plasma-chemistry-and-plasma-processing",
    text: "Plasma Chemistry and Plasma Processing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plasma-medicine",
    text: "Plasma Medicine",
    parent: "vancouver",
    urlone: "http://dl.begellhouse.com/forauthors/journals/5a5b4a3d419387fb.html",
    urltwo: "NA"
  },
  {
    id: "plasma-physics-and-controlled-fusion",
    text: "Plasma Physics and Controlled Fusion",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0741-3335",
    urltwo: "NA"
  },
  {
    id: "plasma-research-express",
    text: "Plasma Research Express",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "plasma-science-and-technology",
    text: "Plasma Science and Technology",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1009-0630",
    urltwo: "NA"
  },
  {
    id: "plasma-sources-science-and-technology",
    text: "Plasma Sources Science and Technology",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0963-0252",
    urltwo: "NA"
  },
  {
    id: "plasmid",
    text: "Plasmid",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "plasmonics",
    text: "Plasmonics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "plastic-and-reconstructive-surgery-global-open",
    text: "Plastic and Reconstructive Surgery - Global open.",
    parent: "american-medical-association",
    urlone: "http://journals.lww.com/prsgo/Pages/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "plastic-and-reconstructive-surgery",
    text: "Plastic and Reconstructive Surgery",
    parent: "american-medical-association",
    urlone: "http://edmgr.ovid.com/prs/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "plos-biology",
    text: "PLOS Biology",
    parent: "plos",
    urlone: "http://www.plosbiology.org/static/guidelines#preparation",
    urltwo: "NA"
  },
  {
    id: "plos-computational-biology",
    text: "PLOS Computational Biology",
    parent: "plos",
    urlone: "http://www.ploscompbiol.org/static/guidelines#organization",
    urltwo: "NA"
  },
  {
    id: "plos-genetics",
    text: "PLOS Genetics",
    parent: "plos",
    urlone: "http://www.plosgenetics.org/static/guidelines#organization",
    urltwo: "NA"
  },
  {
    id: "plos-medicine",
    text: "PLOS Medicine",
    parent: "plos",
    urlone: "http://www.plosmedicine.org/static/guidelines#preparation",
    urltwo: "NA"
  },
  {
    id: "plos-neglected-tropical-diseases",
    text: "PLOS Neglected Tropical Diseases",
    parent: "plos",
    urlone: "http://www.plosntds.org/static/guidelines#organization",
    urltwo: "NA"
  },
  {
    id: "plos-one",
    text: "PLOS ONE",
    parent: "plos",
    urlone: "http://www.plosone.org/static/guidelines#references",
    urltwo: "NA"
  },
  {
    id: "plos-pathogens",
    text: "PLOS Pathogens",
    parent: "plos",
    urlone: "http://www.plospathogens.org/static/guidelines#organization",
    urltwo: "NA"
  },
  {
    id: "plos",
    text: "Public Library of Science",
    parent: "NA",
    urlone: "http://www.plosone.org/static/guidelines#references",
    urltwo: "NA"
  },
  {
    id: "pm-and-r",
    text: "PM&amp;R",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pnas",
    text: "Proceedings of the National Academy of Sciences of the United States of America",
    parent: "NA",
    urlone: "http://www.pnas.org/site/authors/procedures.xhtml",
    urltwo: "NA"
  },
  {
    id: "pneumologie",
    text: "Pneumologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "podosophia",
    text: "Podosophia (Dutch)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "podzemna-voda",
    text: "PodzemnÃ¡ voda (Slovak)",
    parent: "NA",
    urlone: "http://sah-podzemnavoda.sk/cms/page.php?8",
    urltwo: "NA"
  },
  {
    id: "poetics",
    text: "Poetics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "polar-biology",
    text: "Polar Biology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "polar-science",
    text: "Polar Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "police-practice-and-research",
    text: "Police Practice and Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GPPR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "policy-and-society",
    text: "Policy and Society",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "policy-sciences",
    text: "Policy Sciences",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "policy-studies",
    text: "Policy Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CPOS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "polish-annals-of-medicine",
    text: "Polish Annals of Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "polish-journal-of-applied-psychology",
    text: "Polish Journal of Applied Psychology",
    parent: "apa",
    urlone: "http://www.pjap.psychologia.uni.wroc.pl/contributors.php",
    urltwo: "NA"
  },
  {
    id: "polish-legal",
    text: "Polish Legal (Polish)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/383",
    urltwo: "NA"
  },
  {
    id: "politeknik-negeri-manado-jurnal-p3m",
    text: "Politeknik Negeri Manado - Jurnal Pusat Penelitian dan Pengabdian Kepada Masyarakat (Indonesian)",
    parent: "NA",
    urlone: "http://p3m.polimdo.ac.id/jurnal/index.php/JTIB/about/submissions#references",
    urltwo: "NA"
  },
  {
    id: "political-behavior",
    text: "Political Behavior",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "political-geography",
    text: "Political Geography",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "political-studies",
    text: "Political Studies",
    parent: "NA",
    urlone: "http://www.psa.ac.uk/members/membership-benefits/publications/political-studies",
    urltwo: "NA"
  },
  {
    id: "politics-groups-and-identities",
    text: "Politics, Groups, and Identities",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPGI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "politikon",
    text: "Politikon",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CPSA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "politische-vierteljahresschrift",
    text: "Politische Vierteljahresschrift (German)",
    parent: "NA",
    urlone: "https://www.pvs.nomos.de/fileadmin/pvs/doc/PVS_Autorenhinweise_2017_deutsch.pdf",
    urltwo: "NA"
  },
  {
    id: "politix",
    text: "Politix (French)",
    parent: "NA",
    urlone: "http://politix.cairn.info/recommandations-aux-auteurs/",
    urltwo: "NA"
  },
  {
    id: "polski-przeglad-otorynolaryngologiczny",
    text: "Polski PrzeglÄ…d Otorynolaryngologiczny",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "polyhedron",
    text: "Polyhedron",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "polymer-bulletin",
    text: "Polymer Bulletin",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "polymer-chemistry",
    text: "Polymer Chemistry",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "polymer-degradation-and-stability",
    text: "Polymer Degradation and Stability",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "polymer-journal",
    text: "Polymer Journal",
    parent: "nature-no-et-al",
    urlone: "http://mts-pj.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "polymer-reviews",
    text: "Polymer Reviews",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=lmsc19&amp;page=instructions#.UxwLpEMju1E",
    urltwo: "NA"
  },
  {
    id: "polymer-testing",
    text: "Polymer Testing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "polymer",
    text: "Polymer",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "polymers",
    text: "Polymers",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "pontifical-athenaeum-regina-apostolorum",
    text: "Pontifical Athenaeum Regina Apostolorum",
    parent: "NA",
    urlone: "http://www.uprait.org/",
    urltwo: "NA"
  },
  {
    id: "pontifical-biblical-institute",
    text: "Pontifical Biblical Institute",
    parent: "NA",
    urlone: "http://www.biblico.it/pubblicazioni/sb36_bazylinski_engl.htm",
    urltwo: "NA"
  },
  {
    id: "pontifical-gregorian-university-de",
    text: "Pontifical Gregorian University (German)",
    parent: "pontifical-gregorian-university",
    urlone: "http://www.unigre.it",
    urltwo: "NA"
  },
  {
    id: "pontifical-gregorian-university-en",
    text: "Pontifical Gregorian University (English)",
    parent: "pontifical-gregorian-university",
    urlone: "http://www.unigre.it",
    urltwo: "NA"
  },
  {
    id: "pontifical-gregorian-university-es",
    text: "Pontifical Gregorian University (Spanish)",
    parent: "pontifical-gregorian-university",
    urlone: "http://www.unigre.it",
    urltwo: "NA"
  },
  {
    id: "pontifical-gregorian-university-fr",
    text: "Pontifical Gregorian University (French)",
    parent: "pontifical-gregorian-university",
    urlone: "http://www.unigre.it",
    urltwo: "NA"
  },
  {
    id: "pontifical-gregorian-university",
    text: "Pontifical Gregorian University (Italian)",
    parent: "NA",
    urlone: "http://www.unigre.it",
    urltwo: "NA"
  },
  {
    id: "population-and-environment",
    text: "Population and Environment",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "population-ecology",
    text: "Population Ecology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "population-en",
    text: "Population (English)",
    parent: "population",
    urlone: "http://www.journal-population.com/submission-guidelines/",
    urltwo: "NA"
  },
  {
    id: "population-health-metrics",
    text: "Population Health Metrics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "population-research-and-policy-review",
    text: "Population Research and Policy Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "population-space-and-place",
    text: "Population, Space and Place",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291544-8452/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "population",
    text: "Population (French)",
    parent: "NA",
    urlone: "http://www.revue-population.fr/soumettre-un-article/",
    urltwo: "NA"
  },
  {
    id: "porcine-health-management",
    text: "Porcine Health Management",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "portuguese-economic-journal",
    text: "Portuguese Economic Journal",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "portuguese-studies",
    text: "Portuguese Studies",
    parent: "modern-humanities-research-association",
    urlone: "http://www.mhra.org.uk/Publications/Journals/Portuguese.html",
    urltwo: "NA"
  },
  {
    id: "positivity",
    text: "Positivity",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "post-soviet-affairs",
    text: "Post-Soviet Affairs",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPSA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "postepy-higieny-i-medycyny-doswiadczalnej",
    text: "PostÄ™py Higieny i Medycyny DoÅ›wiadczalnej (Polish)",
    parent: "NA",
    urlone: "http://www.phmd.pl/page.php?IDpage=117",
    urltwo: "NA"
  },
  {
    id: "postepy-psychiatrii-i-neurologii",
    text: "PostÄ™py Psychiatrii i Neurologii",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "postgraduate-medical-journal",
    text: "Postgraduate Medical Journal",
    parent: "bmj",
    urlone: "http://pmj.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "postgraduate-medicine",
    text: "Postgraduate Medicine",
    parent: "american-medical-association",
    urlone: "https://postgradmed.org/authors",
    urltwo: "NA"
  },
  {
    id: "postharvest-biology-and-technology",
    text: "Postharvest Biology and Technology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "potato-research",
    text: "Potato Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "potential-analysis",
    text: "Potential Analysis",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "poultry-science",
    text: "Poultry Science",
    parent: "NA",
    urlone: "http://ps.fass.org/site/misc/PS_Inst_for_Contrib.pdf",
    urltwo: "NA"
  },
  {
    id: "pour-reussir-note",
    text: "Pour rÃ©ussir (note, French - Canada)",
    parent: "NA",
    urlone: "http://www.cheneliere.ca/7901-livre-pour-reussir-6e-edition.html",
    urltwo: "NA"
  },
  {
    id: "powder-technology",
    text: "Powder Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ppmp-psychotherapie-psychosomatik-medizinische-psychologie",
    text: "PPmP - Psychotherapie - Psychosomatik - Medizinische Psychologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "practical-laboratory-medicine",
    text: "Practical Laboratory Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "practical-neurology",
    text: "Practical Neurology",
    parent: "bmj",
    urlone: "http://pn.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "practical-radiation-oncology",
    text: "Practical Radiation Oncology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "practice-innovations",
    text: "Practice Innovations",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pri",
    urltwo: "NA"
  },
  {
    id: "practice-periodical-on-structural-design-and-construction",
    text: "Practice Periodical on Structural Design and Construction",
    parent: "american-society-of-civil-engineers",
    urlone: "http://ascelibrary.org/journal/ppscfx",
    urltwo: "NA"
  },
  {
    id: "practice",
    text: "Practice",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CPRA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "pratique-medicale-et-chirurgicale-de-lanimal-de-compagnie",
    text: "Pratique mÃ©dicale et chirurgicale de l'animal de compagnie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pratique-neurologique-fmc",
    text: "Pratique Neurologique - FMC",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pratiques-en-nutrition",
    text: "Pratiques en nutrition",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pratiques-psychologiques",
    text: "Pratiques psychologiques",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pravention-und-gesundheitsforderung",
    text: "PrÃ¤vention und GesundheitsfÃ¶rderung (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/health+informatics/journal/11553?detailsPage=pltci_2424431",
    urltwo: "NA"
  },
  {
    id: "pravnik",
    text: "PrÃ¡vnÃ­k (Czech)",
    parent: "NA",
    urlone:
      "https://www.ilaw.cas.cz/casopisy-a-knihy/casopisy/casopis-pravnik/pro-autory/pokyny-pro-autory-citace.html",
    urltwo: "NA"
  },
  {
    id: "precambrian-research",
    text: "Precambrian Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "precision-agriculture",
    text: "Precision Agriculture",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "precision-engineering",
    text: "Precision Engineering",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pregnancy-hypertension-an-international-journal-of-womens-cardiovascular-health",
    text: "Pregnancy Hypertension: An International Journal of Women's Cardiovascular Health",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "prehistoires-mediterraneennes",
    text: "PrÃ©histoires mÃ©diterranÃ©ennes",
    parent: "NA",
    urlone: "https://journals.openedition.org/pm/",
    urltwo: "NA"
  },
  {
    id: "prehospital-emergency-care",
    text: "Prehospital Emergency Care",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=ipec20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "preslia",
    text: "Preslia - The Journal of the Czech Botanical Society",
    parent: "NA",
    urlone: "http://www.preslia.cz/guide.html",
    urltwo: "NA"
  },
  {
    id: "presses-universitaires-de-paris-nanterre",
    text: "Presses universitaires de Paris Nanterre (note, French)",
    parent: "NA",
    urlone:
      "http://scd.u-paris10.fr/services/boite-a-outils-pour-la-recherche-/ressources-pratiques-591002.kjsp?RH=1390906967545",
    urltwo: "https://presses.u-paris10.fr/wp-content/uploads/2017/02/ConsignesAuteurs.pdf"
  },
  {
    id: "presses-universitaires-de-rennes-archeologie-et-culture",
    text: "Presses Universitaires de Rennes - ArchÃ©ologie et Culture (French)",
    parent: "NA",
    urlone: "http://pur-editions.fr/pdf/consignes_auteurs_archeo.pdf",
    urltwo: "NA"
  },
  {
    id: "presses-universitaires-de-rennes",
    text: "Presses Universitaires de Rennes (French)",
    parent: "NA",
    urlone: "http://www.pur-editions.fr/",
    urltwo: "NA"
  },
  {
    id: "presses-universitaires-de-strasbourg-note",
    text: "Presses universitaires de Strasbourg (note, French)",
    parent: "NA",
    urlone: "http://pus.unistra.fr/html/WYSIWYGfiles/files/Note-aux-auteurs_finale.pdf",
    urltwo: "https://git.unistra.fr/dev-pus/styles-csl/blob/master/README.md#pus-note-ibid-france"
  },
  {
    id: "prevention-science",
    text: "Prevention Science",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "preventive-medicine",
    text: "Preventive Medicine",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "preventive-veterinary-medicine",
    text: "Preventive Veterinary Medicine",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "prifysgol-caerdydd-harvard",
    text: "Prifysgol Caerdydd - Harvard (Welsh)",
    parent: "harvard-cardiff-university",
    urlone: "https://xerte.cardiff.ac.uk/play_4069",
    urltwo: "https://xerte.cardiff.ac.uk/play_5253"
  },
  {
    id: "primary-care-clinics-in-office-practice",
    text: "Primary Care: Clinics in Office Practice",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/primary-care-clinics-in-office-practice/0095-4543/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "primary-care-diabetes",
    text: "Primary Care Diabetes",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "primate-biology",
    text: "Primate Biology",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "primates",
    text: "Primates",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "prion",
    text: "Prion",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "probabilistic-engineering-mechanics",
    text: "Probabilistic Engineering Mechanics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "probability-theory-and-related-fields",
    text: "Probability Theory and Related Fields",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "probiotics-and-antimicrobial-proteins",
    text: "Probiotics and Antimicrobial Proteins",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "procedia-early-career-research",
    text: "Procedia Early Career Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "procedia-economics-and-finance",
    text: "Procedia Economics and Finance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "procedia-manufacturing",
    text: "Procedia Manufacturing",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "procedia-materials-science",
    text: "Procedia Materials Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "procedia-structural-integrity",
    text: "Procedia Structural Integrity",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "proceedings-of-the-geologists-association",
    text: "Proceedings of the Geologists' Association",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "proceedings-of-the-ieee",
    text: "Proceedings of the IEEE",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5",
    urltwo: "NA"
  },
  {
    id: "proceedings-of-the-international-association-of-hydrological-sciences",
    text: "Proceedings of the International Association of Hydrological Sciences",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "proceedings-of-the-joint-international-grassland-and-international-rangeland-congress-2021",
    text: "Proceedings of the Joint International Grassland &amp; International Rangeland Congress 2021",
    parent: "NA",
    urlone:
      "http://2020kenya-igc-irc.rangelandcongress.org/wp-content/uploads/sites/6/2018/10/IGC-IRC-Paper-Template.docx",
    urltwo: "NA"
  },
  {
    id: "proceedings-of-the-national-academy-of-sciences-india-section-a-physical-sciences",
    text: "Proceedings of the National Academy of Sciences, India Section A: Physical Sciences",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "proceedings-of-the-national-academy-of-sciences-india-section-b-biological-sciences",
    text: "Proceedings of the National Academy of Sciences, India Section B: Biological Sciences",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "proceedings-of-the-royal-society-a",
    text: "Proceedings of the Royal Society A",
    parent: "proceedings-of-the-royal-society-b",
    urlone: "https://royalsociety.org/journals/authors/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "proceedings-of-the-royal-society-b",
    text: "Proceedings of the Royal Society B",
    parent: "NA",
    urlone: "https://royalsociety.org/journals/authors/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "proceedings-of-the-yorkshire-geological-society",
    text: "Proceedings of the Yorkshire Geological Society",
    parent: "the-geological-society-of-london",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "proceedings-of-the-zoological-society",
    text: "Proceedings of the Zoological Society",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "proceedings",
    text: "Proceedings",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "process-biochemistry",
    text: "Process Biochemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "process-safety-and-environmental-protection",
    text: "Process Safety and Environmental Protection",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "processes",
    text: "Processes",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "processing-and-application-of-ceramics",
    text: "Processing and Application of Ceramics",
    parent: "NA",
    urlone: "http://www.tf.uns.ac.rs/publikacije/PAC/guideforauthors.html",
    urltwo: "NA"
  },
  {
    id: "production-and-manufacturing-research",
    text: "Production &amp; Manufacturing Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TPMR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "production-engineering",
    text: "Production Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "production-planning-and-control",
    text: "Production Planning &amp; Control",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TPPC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "professional-psychology-research-and-practice",
    text: "Professional Psychology: Research and Practice",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pro",
    urltwo: "NA"
  },
  {
    id: "programme-grants-for-applied-research",
    text: "Programme Grants for Applied Research",
    parent: "national-institute-of-health-research",
    urlone: "http://www.journalslibrary.nihr.ac.uk/information-for-authors/references",
    urltwo: "NA"
  },
  {
    id: "progres-en-urologie-fmc",
    text: "ProgrÃ¨s en Urologie - FMC",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progres-en-urologie",
    text: "ProgrÃ¨s en Urologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-additive-manufacturing",
    text: "Progress in Additive Manufacturing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "progress-in-aerospace-sciences",
    text: "Progress in Aerospace Sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-artificial-intelligence",
    text: "Progress in Artificial Intelligence",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "progress-in-biomaterials",
    text: "Progress in Biomaterials",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "progress-in-biomedical-engineering",
    text: "Progress in Biomedical Engineering",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "progress-in-biophysics-and-molecular-biology",
    text: "Progress in Biophysics and Molecular Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-cardiovascular-diseases",
    text: "Progress in Cardiovascular Diseases",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-crystal-growth-and-characterization-of-materials",
    text: "Progress in Crystal Growth and Characterization of Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-disaster-science",
    text: "Progress in Disaster Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-earth-and-planetary-science",
    text: "Progress in Earth and Planetary Science",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "progress-in-energy-and-combustion-science",
    text: "Progress in Energy and Combustion Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-energy",
    text: "Progress in Energy",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "progress-in-histochemistry-and-cytochemistry",
    text: "Progress in Histochemistry and Cytochemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-lipid-research",
    text: "Progress in Lipid Research",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-materials-science",
    text: "Progress in Materials Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-neurobiology",
    text: "Progress in Neurobiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-neuropsychopharmacology-and-biological-psychiatry",
    text: "Progress in Neuropsychopharmacology &amp; Biological Psychiatry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-nuclear-energy",
    text: "Progress in Nuclear Energy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-nuclear-magnetic-resonance-spectroscopy",
    text: "Progress in Nuclear Magnetic Resonance Spectroscopy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-oceanography",
    text: "Progress in Oceanography",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-organic-coatings",
    text: "Progress in Organic Coatings",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-orthodontics",
    text: "Progress in Orthodontics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "progress-in-particle-and-nuclear-physics",
    text: "Progress in Particle and Nuclear Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-pediatric-cardiology",
    text: "Progress in Pediatric Cardiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-planning",
    text: "Progress in Planning",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-polymer-science",
    text: "Progress in Polymer Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-quantum-electronics",
    text: "Progress in Quantum Electronics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-retinal-and-eye-research",
    text: "Progress in Retinal and Eye Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-solid-state-chemistry",
    text: "Progress in Solid State Chemistry",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "progress-in-surface-science",
    text: "Progress in Surface Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "proinflow",
    text: "ProInflow (note, Czech)",
    parent: "NA",
    urlone: "http://pro.inflow.cz/pro-autory",
    urltwo: "NA"
  },
  {
    id: "property-law-review",
    text: "Property Law Review",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "propulsion-and-power-research",
    text: "Propulsion and Power Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "prospects",
    text: "PROSPECTS",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "prostaglandins-and-other-lipid-mediators",
    text: "Prostaglandins and Other Lipid Mediators",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "prostaglandins-leukotrienes-and-essential-fatty-acids",
    text: "Prostaglandins, Leukotrienes and Essential Fatty Acids",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "prostate-cancer-and-prostatic-diseases",
    text: "Prostate Cancer and Prostatic Diseases",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-pcan.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "prostate-international",
    text: "Prostate International",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "protein-and-cell",
    text: "Protein &amp; Cell",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "protein-engineering-design-and-selection",
    text: "Protein Engineering Design and Selection",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/proeng/for_authors/manuscript.html",
    urltwo: "NA"
  },
  {
    id: "protein-expression-and-purification",
    text: "Protein Expression and Purification",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "protein-science",
    text: "Protein Science",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1469-896X/homepage/ForAuthors.html#Ref",
    urltwo: "NA"
  },
  {
    id: "proteome-science",
    text: "Proteome Science",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "proteomes",
    text: "Proteomes",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "proteomics",
    text: "PROTEOMICS",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291615-9861/homepage/ForAuthors.html#form",
    urltwo: "NA"
  },
  {
    id: "protoplasma",
    text: "Protoplasma",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ps-political-science-and-politics",
    text: "PS: Political Science &amp; Politics",
    parent: "american-political-science-association",
    urlone: "https://www.apsanet.org/content_2819.cfm",
    urltwo: "NA"
  },
  {
    id: "psicologia-educativa",
    text: "PsicologÃ­a Educativa",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "psych-up2date",
    text: "Psych up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "psychiatric-annals",
    text: "Psychiatric Annals",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "psychiatric-clinics-of-north-america",
    text: "Psychiatric Clinics of North America",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/psychiatric-clinics-of-north-america/0193-953X/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "psychiatric-rehabilitation-journal",
    text: "Psychiatric Rehabilitation Journal",
    parent: "apa",
    urlone: "http://content.apa.org/journals/prj",
    urltwo: "NA"
  },
  {
    id: "psychiatric-services",
    text: "Psychiatric Services",
    parent: "NA",
    urlone: "http://ps.psychiatryonline.org/ps_ifora.aspx",
    urltwo: "NA"
  },
  {
    id: "psychiatrische-praxis",
    text: "Psychiatrische Praxis",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "psychiatry-and-clinical-neurosciences",
    text: "Psychiatry and Clinical Neurosciences",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1440-1819/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "psychiatry-research-neuroimaging",
    text: "Psychiatry Research: Neuroimaging",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "psychiatry-research",
    text: "Psychiatry Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "psychoanalytic-psychology",
    text: "Psychoanalytic Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pap",
    urltwo: "NA"
  },
  {
    id: "psychoanalytic-psychotherapy",
    text: "Psychoanalytic Psychotherapy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPPS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "psychodynamic-practice",
    text: "Psychodynamic Practice",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPCO20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "psychological-assessment",
    text: "Psychological Assessment",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pas",
    urltwo: "NA"
  },
  {
    id: "psychological-bulletin",
    text: "Psychological Bulletin",
    parent: "apa",
    urlone: "http://content.apa.org/journals/bul",
    urltwo: "NA"
  },
  {
    id: "psychological-injury-and-law",
    text: "Psychological Injury and Law",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "psychological-medicine",
    text: "Psychological Medicine",
    parent: "NA",
    urlone: "http://assets.cambridge.org/PSM/PSM_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "psychological-methods",
    text: "Psychological Methods",
    parent: "apa",
    urlone: "http://content.apa.org/journals/met",
    urltwo: "NA"
  },
  {
    id: "psychological-research",
    text: "Psychological Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "psychological-review",
    text: "Psychological Review",
    parent: "apa",
    urlone: "http://content.apa.org/journals/rev",
    urltwo: "NA"
  },
  {
    id: "psychological-services",
    text: "Psychological Services",
    parent: "apa",
    urlone: "http://content.apa.org/journals/ser",
    urltwo: "NA"
  },
  {
    id: "psychological-studies",
    text: "Psychological Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "psychological-trauma-theory-research-practice-and-policy",
    text: "Psychological Trauma: Theory, Research, Practice, and Policy",
    parent: "apa",
    urlone: "http://content.apa.org/journals/tra",
    urltwo: "NA"
  },
  {
    id: "psychologie-francaise",
    text: "Psychologie franÃ§aise",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "psychology-and-aging",
    text: "Psychology and Aging",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pag",
    urltwo: "NA"
  },
  {
    id: "psychology-and-health",
    text: "Psychology &amp; Health",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GPSH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "psychology-and-neuroscience",
    text: "Psychology &amp; Neuroscience",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pne",
    urltwo: "NA"
  },
  {
    id: "psychology-and-psychotherapy-theory-research-and-practice",
    text: "Psychology and Psychotherapy: Theory, Research and Practice",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)2044-8341/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "psychology-and-sexuality",
    text: "Psychology &amp; Sexuality",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPSE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "psychology-crime-and-law",
    text: "Psychology, Crime &amp; Law",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GPCL20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "psychology-health-and-medicine",
    text: "Psychology, Health &amp; Medicine",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CPHM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "psychology-of-addictive-behaviors",
    text: "Psychology of Addictive Behaviors",
    parent: "apa",
    urlone: "http://content.apa.org/journals/adb",
    urltwo: "NA"
  },
  {
    id: "psychology-of-aesthetics-creativity-and-the-arts",
    text: "Psychology of Aesthetics, Creativity, and the Arts",
    parent: "apa",
    urlone: "http://content.apa.org/journals/aca",
    urltwo: "NA"
  },
  {
    id: "psychology-of-consciousness-theory-research-and-practice",
    text: "Psychology of Consciousness: Theory, Research, and Practice",
    parent: "apa",
    urlone: "http://content.apa.org/journals/cns",
    urltwo: "NA"
  },
  {
    id: "psychology-of-men-and-masculinity",
    text: "Psychology of Men &amp; Masculinities",
    parent: "apa",
    urlone: "http://content.apa.org/journals/men",
    urltwo: "NA"
  },
  {
    id: "psychology-of-popular-media-culture",
    text: "Psychology of Popular Media Culture",
    parent: "apa",
    urlone: "http://content.apa.org/journals/ppm",
    urltwo: "NA"
  },
  {
    id: "psychology-of-religion-and-spirituality",
    text: "Psychology of Religion and Spirituality",
    parent: "apa",
    urlone: "http://content.apa.org/journals/rel",
    urltwo: "NA"
  },
  {
    id: "psychology-of-sexual-orientation-and-gender-diversity",
    text: "Psychology of Sexual Orientation and Gender Diversity",
    parent: "apa",
    urlone: "http://content.apa.org/journals/sgd",
    urltwo: "NA"
  },
  {
    id: "psychology-of-sport-and-exercise",
    text: "Psychology of Sport &amp; Exercise",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "psychology-of-violence",
    text: "Psychology of Violence",
    parent: "apa",
    urlone: "http://content.apa.org/journals/vio",
    urltwo: "NA"
  },
  {
    id: "psychology-of-well-being",
    text: "Psychology of Well-Being",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "psychology-public-policy-and-law",
    text: "Psychology, Public Policy, and Law",
    parent: "apa",
    urlone: "http://content.apa.org/journals/law",
    urltwo: "NA"
  },
  {
    id: "psychometrika",
    text: "Psychometrika",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "psychomusicology-music-mind-and-brain",
    text: "Psychomusicology: Music, Mind, and Brain",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pmu",
    urltwo: "NA"
  },
  {
    id: "psychoneuroendocrinology",
    text: "Psychoneuroendocrinology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "psychopathology",
    text: "Psychopathology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-033X",
    urltwo: "NA"
  },
  {
    id: "psychopharmacology",
    text: "Psychopharmacology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "psychopraxis-neuropraxis",
    text: "psychopraxis. neuropraxis (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "psychosis",
    text: "Psychosis",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPSY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "psychosocial-intervention",
    text: "Psychosocial Intervention",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "psychosomatic-medicine",
    text: "Psychosomatic Medicine",
    parent: "NA",
    urlone: "http://journals.lww.com/psychosomaticmedicine/pages/instructionsforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "psychosomatics",
    text: "Psychosomatics",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/psychosomatics/0033-3182/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "psychotherapeut",
    text: "Psychotherapeut (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/psychology/psychology+general/journal/278?detailsPage=pltci_1754309",
    urltwo: "NA"
  },
  {
    id: "psychotherapie-forum",
    text: "Psychotherapie Forum (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "psychotherapy-and-psychosomatics",
    text: "Psychotherapy and Psychosomatics",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0348",
    urltwo: "NA"
  },
  {
    id: "psychotherapy-research",
    text: "Psychotherapy Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TPSR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "psychotherapy",
    text: "Psychotherapy",
    parent: "apa",
    urlone: "http://content.apa.org/journals/pst",
    urltwo: "NA"
  },
  {
    id: "public-choice",
    text: "Public Choice",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "public-health-forum",
    text: "Public Health Forum",
    parent: "vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "public-health-genomics",
    text: "Public Health Genomics",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/224224",
    urltwo: "NA"
  },
  {
    id: "public-health-nutrition",
    text: "Public Health Nutrition",
    parent: "NA",
    urlone: "http://assets.cambridge.org/PHN/PHN_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "public-health-research",
    text: "Public Health Research",
    parent: "national-institute-of-health-research",
    urlone: "http://www.journalslibrary.nihr.ac.uk/information-for-authors/references",
    urltwo: "NA"
  },
  {
    id: "public-health-reviews",
    text: "Public Health Reviews",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "public-health",
    text: "Public Health",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.com/journals/public-health/0033-3506/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "public-law-review",
    text: "Public Law Review",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "public-organization-review",
    text: "Public Organization Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "public-relations-review",
    text: "Public Relations Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "public-transport",
    text: "Public Transport",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "publications",
    text: "Publications",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "publishing-research-quarterly",
    text: "Publishing Research Quarterly",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "publizistik",
    text: "Publizistik (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "puerto-rico-health-sciences-journal",
    text: "Puerto Rico Health Sciences Journal",
    parent: "vancouver",
    urlone: "http://prhsj.rcm.upr.edu/index.php/prhsj/about/submissions#onlineSubmissions",
    urltwo: "NA"
  },
  {
    id: "pulmonary-pharmacology-and-therapeutics",
    text: "Pulmonary Pharmacology &amp; Therapeutics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "pulmonary-therapy",
    text: "Pulmonary Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "pulse",
    text: "Pulse",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/257208",
    urltwo: "NA"
  },
  {
    id: "pure-and-applied-geophysics",
    text: "Pure and Applied Geophysics",
    parent: "NA",
    urlone: "https://www.springer.com/birkhauser/geo+science/journal/24?detailsPage=pltci_3078163",
    urltwo: "NA"
  },
  {
    id: "purinergic-signalling",
    text: "Purinergic Signalling",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "qjm",
    text: "QJM: An International Journal of Medicine",
    parent: "vancouver",
    urlone: "http://www.oxfordjournals.org/our_journals/qjmedj/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "quaderni-degli-avogadro-colloquia",
    text: "Quaderni degli Avogadro Colloquia",
    parent: "NA",
    urlone: "http://www.soc.chim.it/it/avogadro_colloquia",
    urltwo: "NA"
  },
  {
    id: "quaderni-italiani-di-psichiatria",
    text: "Quaderni Italiani di Psichiatria",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "quaderni-materialisti",
    text: "Quaderni Materialisti (Italian)",
    parent: "NA",
    urlone: "http://www.quadernimaterialisti.unimib.it/?p=107",
    urltwo: "NA"
  },
  {
    id: "qualitative-psychology",
    text: "Qualitative Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/qua",
    urltwo: "NA"
  },
  {
    id: "qualitative-sociology",
    text: "Qualitative Sociology",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "qualitative-theory-of-dynamical-systems",
    text: "Qualitative Theory of Dynamical Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "quality-and-quantity",
    text: "Quality &amp; Quantity",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "quality-of-life-research",
    text: "Quality of Life Research",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "quantitative-finance-and-economics",
    text: "Quantitative Finance and Economics",
    parent: "aims-press",
    urlone: "https://www.aimspress.com/news/256.html",
    urltwo: "NA"
  },
  {
    id: "quantitative-marketing-and-economics",
    text: "Quantitative Marketing and Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "quantum-beam-science",
    text: "Quantum Beam Science",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "quantum-electronics",
    text: "Quantum Electronics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1063-7818",
    urltwo: "NA"
  },
  {
    id: "quantum-information-processing",
    text: "Quantum Information Processing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "quantum-science-and-technology",
    text: "Quantum Science &amp; Technology",
    parent: "institute-of-physics-numeric",
    urlone: "https://publishingsupport.iopscience.iop.org/authoring-for-journals/?step=2",
    urltwo: "NA"
  },
  {
    id: "quantum-studies-mathematics-and-foundations",
    text: "Quantum Studies: Mathematics and Foundations",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "quarterly-journal-of-engineering-geology-and-hydrogeology",
    text: "Quarterly Journal of Engineering Geology and Hydrogeology",
    parent: "the-geological-society-of-london",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "quarterly-journal-of-speech",
    text: "Quarterly Journal of Speech",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RQJS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "quarterly-review-of-economics-and-finance",
    text: "Quarterly Review of Economics and Finance",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "quaternaire",
    text: "Quaternaire (French)",
    parent: "NA",
    urlone: "https://journals.openedition.org/quaternaire/1243#tocto2n3",
    urltwo: "NA"
  },
  {
    id: "quaternary-geochronology",
    text: "Quaternary Geochronology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "quaternary-international",
    text: "Quaternary International",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/quaternary-international/1040-6182/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "quaternary-research",
    text: "Quaternary Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "quaternary-science-reviews",
    text: "Quaternary Science Reviews",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "queen-margaret-university-harvard",
    text: "Queen Margaret University - Harvard",
    parent: "NA",
    urlone: "https://libguides.qmu.ac.uk/ld.php?content_id=26019494",
    urltwo: "NA"
  },
  {
    id: "queueing-systems",
    text: "Queueing Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "r-and-d-management",
    text: "R&amp;D Management",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1467-9310/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "race-and-social-problems",
    text: "Race and Social Problems",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "race-ethnicity-and-education",
    text: "Race Ethnicity and Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CREE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "radiation-and-environmental-biophysics",
    text: "Radiation and Environmental Biophysics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "radiation-measurements",
    text: "Radiation Measurements",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "radiation-oncology",
    text: "Radiation Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "radiation-physics-and-chemistry",
    text: "Radiation Physics and Chemistry",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "radio-physics-and-radio-astronomy",
    text: "Radio Physics and Radio Astronomy",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/6fd1549c0e2c05da.html",
    urltwo: "NA"
  },
  {
    id: "radio-science",
    text: "Radio Science",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "radiochimica-acta",
    text: "Radiochimica Acta",
    parent: "NA",
    urlone: "https://www.degruyter.com/view/j/ract",
    urltwo: "NA"
  },
  {
    id: "radiographics",
    text: "RadioGraphics",
    parent: "NA",
    urlone: "http://radiographics.rsna.org/site/pia/manprep12.xhtml",
    urltwo: "NA"
  },
  {
    id: "radiography",
    text: "Radiography",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/radiography/1078-8174/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "radiologia",
    text: "RadiologÃ­a",
    parent: "vancouver",
    urlone: "http://www.elsevier.es/sites/default/files/elsevier/NormOrga/119normas.pdf",
    urltwo: "NA"
  },
  {
    id: "radiologic-clinics-of-north-america",
    text: "Radiologic Clinics of North America",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/radiologic-clinics-of-north-america/0033-8389/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "radiological-physics-and-technology",
    text: "Radiological Physics and Technology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "radiologie-up2date",
    text: "Radiologie up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "radiology-case-reports",
    text: "Radiology Case Reports",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "radiology-of-infectious-diseases",
    text: "Radiology of Infectious Diseases",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "radiology",
    text: "Radiology",
    parent: "NA",
    urlone: "https://pubs.rsna.org/page/radiology/author-instructions#references",
    urltwo: "NA"
  },
  {
    id: "radiopaedia",
    text: "Radiopaedia.org",
    parent: "NA",
    urlone: "http://radiopaedia.org/articles/references-1",
    urltwo: "NA"
  },
  {
    id: "radiopraxis",
    text: "Radiopraxis",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "radiotherapy-and-oncology",
    text: "Radiotherapy and Oncology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "raffles-bulletin-of-zoology",
    text: "Raffles Bulletin of Zoology",
    parent: "NA",
    urlone: "https://lkcnhm.nus.edu.sg/publications/raffles-bulletin-of-zoology/instructions-to-authors/",
    urltwo: "NA"
  },
  {
    id: "rai-revista-de-administracao-e-inovacao",
    text: "RAI Revista de AdministraÃ§Ã£o e InovaÃ§Ã£o",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rangeland-ecology-and-management",
    text: "Rangeland Ecology &amp; Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rapid-communications-in-mass-spectrometry",
    text: "Rapid Communications in Mass Spectrometry",
    parent: "american-medical-association",
    urlone: "https://onlinelibrary.wiley.com/page/journal/10970231/homepage/forauthors.html#ReferenceStyleandEndNote",
    urltwo: "NA"
  },
  {
    id: "rare-diseases",
    text: "Rare Diseases",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rare-metals",
    text: "Rare Metals",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "rausp-management-journal",
    text: "RAUSP Management Journal",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reach",
    text: "REACH",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reaction-chemistry-and-engineering",
    text: "Reaction Chemistry &amp; Engineering",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reaction-kinetics-mechanisms-and-catalysis",
    text: "Reaction Kinetics, Mechanisms and Catalysis",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reactive-and-functional-polymers",
    text: "Reactive and Functional Polymers",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reading-and-writing",
    text: "Reading and Writing",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "real-time-systems",
    text: "Real-Time Systems",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "recent-patents-on-drug-delivery-and-formulation",
    text: "Recent Patents on Drug Delivery &amp; Formulation",
    parent: "NA",
    urlone: "http://benthamscience.com/journals/recent-patents-on-drug-delivery-and-formulation/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "recherches-en-sciences-de-gestion",
    text: "Recherches en Sciences de Gestion (French)",
    parent: "NA",
    urlone: "http://164.132.163.82/SiteIseor/rsdg.asp",
    urltwo: "https://github.com/citation-style-language/styles/files/1095178/Normes.de.presentation.janvier.2014.pdf"
  },
  {
    id: "rechtsmedizin",
    text: "Rechtsmedizin (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/journal/194?detailsPage=pltci_1301191",
    urltwo: "NA"
  },
  {
    id: "recycling",
    text: "Recycling",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "redox-biology",
    text: "Redox Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reflective-practice",
    text: "Reflective Practice",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CREP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "rege-revista-de-gestao",
    text: "REGE - Revista de GestÃ£o",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "regenerative-engineering-and-translational-medicine",
    text: "Regenerative Engineering and Translational Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "regenerative-medicine-research",
    text: "Regenerative Medicine Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "regenerative-medicine",
    text: "Regenerative Medicine",
    parent: "future-medicine",
    urlone: "http://www.futuremedicine.com/loi/rme",
    urltwo: "NA"
  },
  {
    id: "regenerative-therapy",
    text: "Regenerative Therapy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "regional-environmental-change",
    text: "Regional Environmental Change",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "regional-science-and-urban-economics",
    text: "Regional Science and Urban Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "regional-studies-in-marine-science",
    text: "Regional Studies in Marine Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "regulatory-peptides",
    text: "Regulatory Peptides",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "regulatory-toxicology-and-pharmacology",
    text: "Regulatory Toxicology and Pharmacology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rehabilitation-psychology",
    text: "Rehabilitation Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/rep",
    urltwo: "NA"
  },
  {
    id: "reinforced-plastics",
    text: "Reinforced Plastics",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reliability-engineering-and-system-safety",
    text: "Reliability Engineering and System Safety",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "religion-in-the-roman-empire",
    text: "Religion in the Roman Empire",
    parent: "NA",
    urlone: "https://www.mohr.de/fileadmin/user_upload/Zeitschriften/Richtlinien_Autoren/2016/RRE_Style_sheet-neu.pdf",
    urltwo: "NA"
  },
  {
    id: "religions",
    text: "Religions",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "remote-sensing-applications-society-and-environment",
    text: "Remote Sensing Applications: Society and Environment",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "remote-sensing-of-environment",
    text: "Remote Sensing of Environment",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "remote-sensing",
    text: "Remote Sensing",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "renal-replacement-therapy",
    text: "Renal Replacement Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "rendiconti-del-circolo-matematico-di-palermo",
    text: "Rendiconti del Circolo Matematico di Palermo",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "rendiconti-lincei",
    text: "Rendiconti Lincei",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "renewable-agriculture-and-food-systems",
    text: "Renewable Agriculture and Food Systems",
    parent: "NA",
    urlone:
      "https://www.cambridge.org/core/journals/renewable-agriculture-and-food-systems/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "renewable-and-sustainable-energy-reviews",
    text: "Renewable and Sustainable Energy Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "renewable-energy-focus",
    text: "Renewable Energy Focus",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "renewable-energy",
    text: "Renewable Energy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "renewables-wind-water-and-solar",
    text: "Renewables: Wind, Water, and Solar",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reports-of-practical-oncology-and-radiotherapy",
    text: "Reports of Practical Oncology and Radiotherapy",
    parent: "NA",
    urlone:
      "http://www.elsevier.com/journals/reports-of-practical-oncology-and-radiotherapy/1507-1367/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "reports-on-progress-in-physics",
    text: "Reports on Progress in Physics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0034-4885",
    urltwo: "NA"
  },
  {
    id: "representation",
    text: "Representation",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?journalCode=rrep20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "reproduction-fertility-and-development",
    text: "Reproduction, Fertility and Development",
    parent: "NA",
    urlone: "http://www.publish.csiro.au/rd/forauthors/AuthorInstructions#13",
    urltwo: "NA"
  },
  {
    id: "reproduction-in-domestic-animals",
    text: "Reproduction in Domestic Animals",
    parent: "apa",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291439-0531/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "reproduction",
    text: "Reproduction",
    parent: "NA",
    urlone: "http://www.reproduction-online.org/site/misc/For-Authors.xhtml#refs",
    urltwo: "NA"
  },
  {
    id: "reproductive-biology-and-endocrinology",
    text: "Reproductive Biology and Endocrinology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reproductive-biology",
    text: "Reproductive Biology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reproductive-biomedicine-and-society-online",
    text: "Reproductive Biomedicine &amp; Society Online",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reproductive-biomedicine-online",
    text: "Reproductive BioMedicine Online",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reproductive-health",
    text: "Reproductive Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reproductive-medicine-and-biology",
    text: "Reproductive Medicine and Biology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reproductive-toxicology",
    text: "Reproductive Toxicology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "requirements-engineering",
    text: "Requirements Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "res-publica",
    text: "Res Publica",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-and-education-promotion-association",
    text: "Research and Education Promotion Association",
    parent: "NA",
    urlone: "https://repa.jp/journals/editorial-guide-and-citation-style/",
    urltwo: "NA"
  },
  {
    id: "research-and-practice-in-technology-enhanced-learning",
    text: "Research and Practice in Technology Enhanced Learning",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-evaluation",
    text: "Research Evaluation",
    parent: "oxford-university-press-humsoc",
    urlone:
      "http://www.oxfordjournals.org/our_journals/rev/for_authors/manuscript_instructions.html#Journal%20copyediting%20style",
    urltwo: "NA"
  },
  {
    id: "research-in-accounting-regulation",
    text: "Research in Accounting Regulation",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-astronomy-and-astrophysics",
    text: "Research in Astronomy and Astrophysics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1674-4527",
    urltwo: "NA"
  },
  {
    id: "research-in-autism-spectrum-disorders",
    text: "Research in Autism Spectrum Disorders",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-dance-education",
    text: "Research in Dance Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CRID20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "research-in-developmental-disabilities",
    text: "Research in Developmental Disabilities",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-drama-education-the-journal-of-applied-theatre-and-performance",
    text: "Research in Drama Education: The Journal of Applied Theatre and Performance",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CRDE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "research-in-economics",
    text: "Research in Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-engineering-design",
    text: "Research in Engineering Design",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-in-gerontological-nursing",
    text: "Research in Gerontological Nursing",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/~/media/Files/Journals/General%20PDFs/RGN/RGNInfoauth.ashx",
    urltwo: "NA"
  },
  {
    id: "research-in-globalization",
    text: "Research in Globalization",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-higher-education",
    text: "Research in Higher Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-in-international-business-and-finance",
    text: "Research in International Business and Finance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-mathematics-education",
    text: "Research in Mathematics Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RRME20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "research-in-microbiology",
    text: "Research in Microbiology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-number-theory",
    text: "Research in Number Theory",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-in-organizational-behavior",
    text: "Research in Organizational Behavior",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-post-compulsory-education",
    text: "Research in Post-Compulsory Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPCE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "research-in-science-and-technological-education",
    text: "Research in Science &amp; Technological Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CRST20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "research-in-science-education",
    text: "Research in Science Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-in-social-and-administrative-pharmacy",
    text: "Research in Social and Administrative Pharmacy",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-social-stratification-and-mobility",
    text: "Research in Social Stratification and Mobility",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-the-mathematical-sciences",
    text: "Research in the Mathematical Sciences",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-in-transportation-business-and-management",
    text: "Research in Transportation Business &amp; Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-transportation-economics",
    text: "Research in Transportation Economics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-in-veterinary-science",
    text: "Research in Veterinary Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-institute-for-nature-and-forest",
    text: "Research Institute for Nature and Forest (Instituut voor Natuur- en Bosonderzoek)",
    parent: "NA",
    urlone: "https://inbomd-examples.netlify.app/citation_style/nl/index.html",
    urltwo: "NA"
  },
  {
    id: "research-integrity-and-peer-review",
    text: "Research Integrity and Peer Review",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-involvement-and-engagement",
    text: "Research Involvement and Engagement",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-on-biomedical-engineering",
    text: "Research on Biomedical Engineering",
    parent: "NA",
    urlone: "http://www.rbejournal.org/authors",
    urltwo: "NA"
  },
  {
    id: "research-on-chemical-intermediates",
    text: "Research on Chemical Intermediates",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "research-papers-in-education",
    text: "Research Papers in Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RRED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "research-policy-x",
    text: "Research Policy: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-policy",
    text: "Research Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "research-results-in-pharmacology",
    text: "Research Results in Pharmacology",
    parent: "pensoft-journals",
    urlone: "https://rrpharmacology.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "resource-and-energy-economics",
    text: "Resource and Energy Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "resource-efficient-technologies",
    text: "Resource-Efficient Technologies",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "resources-conservation-and-recycling",
    text: "Resources, Conservation &amp; Recycling",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "resources-policy",
    text: "Resources Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "resources",
    text: "Resources",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "respiration",
    text: "Respiration",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0356",
    urltwo: "NA"
  },
  {
    id: "respiratory-investigation",
    text: "Respiratory Investigation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "respiratory-medicine-and-research",
    text: "Respiratory Medicine and Research",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "respiratory-medicine-case-reports",
    text: "Respiratory Medicine Case Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "respiratory-medicine-x",
    text: "Respiratory Medicine: X",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "respiratory-medicine",
    text: "Respiratory Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "respiratory-physiology-and-neurobiology",
    text: "Respiratory Physiology &amp; Neurobiology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "respiratory-research",
    text: "Respiratory Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "restoration-ecology",
    text: "Restoration Ecology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1526-100X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "results-in-applied-mathematics",
    text: "Results in Applied Mathematics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "results-in-chemistry",
    text: "Results in Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "results-in-engineering",
    text: "Results in Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "results-in-immunology",
    text: "Results in Immunology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "results-in-materials",
    text: "Results in Materials",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "results-in-mathematics",
    text: "Results in Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "results-in-pharma-sciences",
    text: "Results in Pharma Sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "results-in-physics",
    text: "Results in Physics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "resuscitation",
    text: "Resuscitation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rethinking-ecology",
    text: "Rethinking Ecology",
    parent: "pensoft-journals",
    urlone: "https://rethinkingecology.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "rethinking-history",
    text: "Rethinking History",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RRHI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "retina",
    text: "Retina",
    parent: "american-medical-association",
    urlone: "http://edmgr.ovid.com/retina/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "retrovirology",
    text: "Retrovirology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "retten",
    text: "retten!",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "reumatologia-clinica",
    text: "ReumatologÃ­a Clinica (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.reumatologiaclinica.org/en/guia-autores/",
    urltwo: "NA"
  },
  {
    id: "rever-revista-de-estudos-da-religiao",
    text: "REVER - Revista de Estudos da ReligiÃ£o",
    parent: "NA",
    urlone: "http://revistas.pucsp.br/rever/about/editorialPolicies#custom-0",
    urltwo: "NA"
  },
  {
    id: "review-journal-of-autism-and-developmental-disorders",
    text: "Review Journal of Autism and Developmental Disorders",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-accounting-studies",
    text: "Review of Accounting Studies",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-african-political-economy",
    text: "Review of African Political Economy",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CREA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "review-of-communication",
    text: "Review of Communication",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RROC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "review-of-derivatives-research",
    text: "Review of Derivatives Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-development-finance",
    text: "Review of Development Finance",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "review-of-economic-design",
    text: "Review of Economic Design",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-economic-dynamics",
    text: "Review of Economic Dynamics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "review-of-economic-perspectives",
    text: "Review of Economic Perspectives (NÃ¡rodohospodÃ¡rskÃ½ obzor)",
    parent: "iso690-author-date-en",
    urlone: "https://www.degruyter.com/view/j/revecp",
    urltwo: "NA"
  },
  {
    id: "review-of-economics-of-the-household",
    text: "Review of Economics of the Household",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-educational-research",
    text: "Review of Educational Research",
    parent: "apa",
    urlone: "http://www.sagepub.com/journals/Journal201854/manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "review-of-financial-economics",
    text: "Review of Financial Economics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "review-of-industrial-organization",
    text: "Review of Industrial Organization",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-international-studies",
    text: "Review of International Studies",
    parent: "NA",
    urlone:
      "https://www.cambridge.org/core/journals/review-of-international-studies/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "review-of-managerial-science",
    text: "Review of Managerial Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-palaeobotany-and-palynology",
    text: "Review of Palaeobotany and Palynology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "review-of-philosophy-and-psychology",
    text: "Review of Philosophy and Psychology",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-political-economy",
    text: "Review of Political Economy",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?journalCode=crpe20&amp;page=instructions#mp_style",
    urltwo: "NA"
  },
  {
    id: "review-of-quantitative-finance-and-accounting",
    text: "Review of Quantitative Finance and Accounting",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-regional-research",
    text: "Review of Regional Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-religious-research",
    text: "Review of Religious Research",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "review-of-research-in-education",
    text: "Review of Research in Education",
    parent: "apa",
    urlone: "http://www.sagepub.com/journals/Journal201855/manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "review-of-scientific-instruments",
    text: "Review of Scientific Instruments",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "review-of-world-economics",
    text: "Review of World Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reviews-in-endocrine-and-metabolic-disorders",
    text: "Reviews in Endocrine and Metabolic Disorders",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reviews-in-environmental-science-and-bio-technology",
    text: "Reviews in Environmental Science and Bio/Technology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reviews-in-fish-biology-and-fisheries",
    text: "Reviews in Fish Biology and Fisheries",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reviews-in-physics",
    text: "Reviews in Physics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reviews-in-vascular-medicine",
    text: "Reviews in Vascular Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "reviews-of-environmental-contamination-and-toxicology",
    text: "Reviews of Environmental Contamination and Toxicology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "reviews-of-geophysics",
    text: "Reviews of Geophysics",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "reviews-of-modern-physics-with-titles",
    text: "Reviews of Modern Physics (with titles)",
    parent: "NA",
    urlone: "http://rmp.aps.org/files/rmpgui08.pdf",
    urltwo: "NA"
  },
  {
    id: "revista-argentina-de-antropologia-biologica",
    text: "Revista Argentina de Antropologia Biologica (Spanish)",
    parent: "NA",
    urlone: "http://revistas.unlp.edu.ar/index.php/raab/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "revista-argentina-de-cirugia",
    text: "Revista Argentina de CirugÃ­a (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.scielo.org.ar/revistas/racir/iinstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-biblica",
    text: "Revista BÃ­blica (Spanish)",
    parent: "NA",
    urlone: "https://www.revistabiblica.com/publicacion/",
    urltwo: "NA"
  },
  {
    id: "revista-brasileira-de-ciencia-do-solo",
    text: "Revista Brasileira de CiÃªncia do Solo",
    parent: "NA",
    urlone: "http://www.scielo.br/revistas/rbcs/iinstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-brasileira-de-ciencias-do-esporte",
    text: "Revista Brasileira de CiÃªncias do Esporte",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-brasileira-de-entomologia",
    text: "Revista Brasileira de Entomologia",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-brasileira-de-farmacognosia",
    text: "Revista Brasileira de Farmacognosia",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-brasileira-de-reumatologia",
    text: "Revista Brasileira de Reumatologia (Brazilian Journal of Rheumatology)",
    parent: "vancouver-superscript",
    urlone: "http://www.scielo.br/revistas/rbr/iinstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-chilena-de-derecho-y-tecnologia",
    text: "Revista Chilena de Derecho y TecnologÃ­a (Spanish - Chile)",
    parent: "NA",
    urlone: "http://www.rchdt.uchile.cl/index.php/RCHDT/about/submissions",
    urltwo: "NA"
  },
  {
    id: "revista-chilena-de-historia-natural",
    text: "Revista Chilena de Historia Natural",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "revista-chilena-de-pediatria",
    text: "Revista Chilena de PediatrÃ­a (Spanish)",
    parent: "vancouver",
    urlone: "http://www.scielo.cl/revistas/rcp/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-ciencias-tecnicas-agropecuarias",
    text: "Revista Ciencias TÃ©cnicas Agropecuarias (Spanish)",
    parent: "NA",
    urlone: "http://www.rcta.unah.edu.cu/public/journals/1/Normas_asiento_bibliogr%C3%A1fico.pdf",
    urltwo: "NA"
  },
  {
    id: "revista-clinica-espanola",
    text: "Revista ClÃ­nica EspaÃ±ola (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.sciencedirect.com/science/journal/00142565",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-alimentacion-y-nutiricion",
    text: "Revista Cubana de AlimentaciÃ³n y NutriciÃ³n (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.revicubalimentanut.sld.cu/PaginaPrincipal.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-cirugia",
    text: "Revista Cubana de CirugÃ­a (Spanish)",
    parent: "vancouver-superscript",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-endocrinologia",
    text: "Revista Cubana de EndocrinologÃ­a (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://bvs.sld.cu/revistas/end/",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-enfermeria",
    text: "Revista Cubana de EnfermerÃ­a (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/enf/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-estomatologia",
    text: "Revista Cubana de EstomatologÃ­a (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/est/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-farmacia",
    text: "Revista Cubana de Farmacia (Spanish)",
    parent: "vancouver",
    urlone: "http://scielo.sld.cu/revistas/far/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-hematologia-immunologia-y-hemoterapia",
    text: "Revista Cubana de HematologÃ­a, InmunologÃ­a y Hemoterapia (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://bvs.sld.cu/revistas/hih/",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-higiene-y-epidemiologia",
    text: "Revista Cubana de Higiene y EpidemiologÃ­a (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/hie/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-investigaciones-biomedicas",
    text: "Revista Cubana de Investigaciones BiomÃ©dicas (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/ibi/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-medicina-general-integral",
    text: "Revista Cubana de Medicina General Integral (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/mgi/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-medicina-militar",
    text: "Revista Cubana de Medicina Militar (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/mil/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-medicina-tropical",
    text: "Revista Cubana de Medicina Tropical (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/mtr/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-medicina",
    text: "Revista Cubana de Medicina (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/med/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-meteorologia",
    text: "Revista Cubana de Meteorologia",
    parent: "NA",
    urlone: "http://rcm.insmet.cu/index.php/rcm/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-obstetricia-y-ginecologia",
    text: "Revista Cubana de Obstetricia y GinecologÃ­a (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/gin/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-oftalmologia",
    text: "Revista Cubana de Oftalmologia (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/oft/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-ortopedia-y-traumatologia",
    text: "Revista Cubana de Ortopedia y TraumatologÃ­a (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/ort/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-pediatria",
    text: "Revista Cubana de PediatrÃ­a (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/ped/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cubana-de-salud-publica",
    text: "Revista Cubana de Salud PÃºblica (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://scielo.sld.cu/revistas/rcsp/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-cuicuilco",
    text: "Revista Cuicuilco (Spanish - Mexico)",
    parent: "escuela-nacional-de-antropologia-e-historia-author-date",
    urlone: "http://www.revistacuicuilco.enah.edu.mx/revista/",
    urltwo: "NA"
  },
  {
    id: "revista-da-sociedade-brasileira-de-medicina-tropical",
    text: "Revista da Sociedade Brasileira de Medicina Tropical",
    parent: "NA",
    urlone: "http://www.scielo.br/revistas/rsbmt/iinstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-de-administracao",
    text: "Revista de AdministraÃ§Ã£o",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-de-biologia-marina-y-oceanografia",
    text: "Revista de BiologÃ­a Marina y OceanografÃ­a",
    parent: "NA",
    urlone: "https://revbiolmar.uv.cl/Instructions%202019.pdf",
    urltwo: "NA"
  },
  {
    id: "revista-de-biologia-tropical",
    text: "Revista de BiologÃ­a Tropical (International Journal of Tropical Biology and Conservation)",
    parent: "NA",
    urlone: "http://biologiatropical.ucr.ac.cr/",
    urltwo: "NA"
  },
  {
    id: "revista-de-contabilidad",
    text: "Revista de Contabilidad",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-de-filologia-espanola",
    text: "Revista de FilologÃ­a EspaÃ±ola (Spanish)",
    parent: "NA",
    urlone: "http://revistadefilologiaespaÃ±ola.revistas.csic.es/index.php/rfe/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "revista-de-gastroenterologia-de-mexico",
    text: "Revista de GastroenterologÃ­a de MÃ©xico (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.revistagastroenterologiamexico.org/",
    urltwo: "NA"
  },
  {
    id: "revista-de-la-educacion-superior",
    text: "Revista de la educaciÃ³n superior",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-de-la-real-academia-de-ciencias-exactas-fisicas-y-naturales-serie-a-matematicas",
    text: "Revista de la Real Academia de Ciencias Exactas, FÃ­sicas y Naturales. Serie A. MatemÃ¡ticas",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "revista-de-logopedia-foniatria-y-audiologia",
    text: "Revista de Logopedia, FoniatrÃ­a y AudiologÃ­a",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-de-psicodidactica",
    text: "Revista de PsicodidÃ¡ctica",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-de-psicologia-del-trabajo-y-de-las-organizaciones",
    text: "Revista de PsicologÃ­a del Trabajo y de las Organizaciones",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-de-psiquiatria-y-salud-mental",
    text: "Revista de PsiquiatrÃ­a y Salud Mental (Spanish)",
    parent: "medicina-clinica",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-del-laboratorio-clinico",
    text: "Revista del Laboratorio ClÃ­nico (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.sciencedirect.com/science/journal/18884008",
    urltwo: "NA"
  },
  {
    id: "revista-do-instituto-de-medicina-tropical-de-sao-paulo",
    text: "Revista do Instituto de Medicina Tropical de SÃ£o Paulo",
    parent: "NA",
    urlone: "http://www.scielo.br/revistas/rimtsp/iinstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-espanola-de-anestesiologia-y-reanimacion",
    text: "Revista EspaÃ±ola de AnestesiologÃ­a y ReanimaciÃ³n (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.es/es/revistas/revista-espa%C3%B1ola-anestesiologia-reanimacion-344-0",
    urltwo: "NA"
  },
  {
    id: "revista-espanola-de-cardiologia",
    text: "Revista EspaÃ±ola de CardiologÃ­a",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-espanola-de-nutricion-humana-y-dietetica",
    text: "Revista Espanola de Nutricion Humana y Dietetica (Spanish)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-espanola-de-salud-publica",
    text: "Revista EspaÃ±ola de Salud PÃºblica (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.scielosp.org/revistas/resp/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-fave-seccion-ciencias-agrarias",
    text: "Revista FAVE - SecciÃ³n Ciencias Agrarias (Spanish)",
    parent: "NA",
    urlone: "http://www.fca.unl.edu.ar/pages/investigacion/revista-fave/normas-de-publicacion.php",
    urltwo: "NA"
  },
  {
    id: "revista-iberoamericana-de-psicologia-y-salud",
    text: "Revista Iberoamericana de PsicologÃ­a y Salud",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-internacional-de-metodos-numericos-para-calculo-y-diseno-en-ingenieria",
    text: "Revista Internacional de MÃ©todos NumÃ©ricos para CÃ¡lculo y DiseÃ±o en IngenierÃ­a",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-latinoamericana-de-metalurgia-y-materiales",
    text: "Revista Latinoamericana de Metalurgia y Materiales",
    parent: "NA",
    urlone: "http://www.rlmm.org/ojs-files/download.php?f=RLMM-InstrucAutor%20Plantilla.doc",
    urltwo: "NA"
  },
  {
    id: "revista-latinoamericana-de-psicologia",
    text: "Revista Latinoamericana de PsicologÃ­a",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-latinoamericana-de-recursos-naturales",
    text: "Revista Latinoamericana de Recursos Naturales",
    parent: "NA",
    urlone: "http://www.itson.mx/publicaciones/rlrn/Paginas/guia.aspx",
    urltwo: "NA"
  },
  {
    id: "revista-matematica-complutense",
    text: "Revista MatemÃ¡tica Complutense",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "revista-materia",
    text: "Revista MatÃ©ria",
    parent: "NA",
    urlone: "https://www.scielo.br/revistas/rmat/iinstruc.htm",
    urltwo: "https://www.scielo.br/revistas/rmat/arquivo_modelo_Ingles.docx"
  },
  {
    id: "revista-medica-clinica-las-condes",
    text: "Revista MÃ©dica ClÃ­nica Las Condes",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-medica-de-chile",
    text: "Revista MÃ©dica de Chile (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://www.scielo.cl/revistas/rmc/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "revista-medica-del-instituto-mexicano-del-seguro-social",
    text: "Revista MÃ©dica del Instituto Mexicano del Seguro Social (Spanish)",
    parent: "vancouver-superscript",
    urlone: "http://revistamedica.imss.gob.mx/",
    urltwo: "NA"
  },
  {
    id: "revista-mexicana-de-biodiversidad",
    text: "Revista Mexicana de Biodiversidad",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-mexicana-de-trastornos-alimentarios",
    text: "Revista Mexicana de Trastornos Alimentarios",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revista-noesis",
    text: "Revista NÃ³esis",
    parent: "NA",
    urlone: "http://erevistas.uacj.mx/ojs/index.php/RevistasUACJ/article/view/1153/994",
    urltwo: "NA"
  },
  {
    id: "revista-panamericana-de-salud-publica",
    text: "Revista Panamericana de Salud PÃºblica (Pan American Journal of Public Health)",
    parent: "vancouver",
    urlone: "http://new.paho.org/journal/index.php?option=com_content&amp;task=view&amp;id=35&amp;Itemid=155#2.10",
    urltwo: "NA"
  },
  {
    id: "revista-peruana-de-medicina-experimental-y-salud-publica",
    text: "Revista Peruana de Medicina Experimental y Salud PÃºblica (Spanish)",
    parent: "NA",
    urlone: "https://rpmesp.ins.gob.pe/index.php/rpmesp/pages/view/instrucciones",
    urltwo: "NA"
  },
  {
    id: "revista-portuguesa-de-arqueologia",
    text: "Revista Portuguesa de Arqueologia",
    parent: "NA",
    urlone: "http://www.patrimoniocultural.gov.pt/pt/shop/catalog/revista-portuguesa-de-arqueologia/",
    urltwo: "NA"
  },
  {
    id: "revista-portuguesa-de-cardiologia",
    text: "Revista Portuguesa de Cardiologia",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.com/journals/revista-portuguesa-de-cardiologia-english-edition/2174-2049",
    urltwo: "NA"
  },
  {
    id: "revista-virtual-de-quimica",
    text: "Revista Virtual de QuÃ­mica (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://www.uff.br/RVQ/instrucaoaosautores.pdf",
    urltwo: "NA"
  },
  {
    id: "revue-archeologique-de-lest",
    text: "Revue archÃ©ologique de lâ€™Est (French)",
    parent: "NA",
    urlone: "https://journals.openedition.org/rae/1161#tocfrom2n1",
    urltwo: "NA"
  },
  {
    id: "revue-archeologique-du-centre-de-la-france",
    text: "Revue archÃ©ologique du Centre de la France (French)",
    parent: "NA",
    urlone: "http://racf.revues.org/920#tocto2n12",
    urltwo: "NA"
  },
  {
    id: "revue-archeologique",
    text: "Revue ArchÃ©ologique (French)",
    parent: "NA",
    urlone: "https://www.puf.com/sites/default/files/kcfinder/files/Recommandationsauxauteurs.pdf",
    urltwo: "NA"
  },
  {
    id: "revue-d-elevage-et-de-medecine-veterinaire-des-pays-tropicaux",
    text: "Revue d'Ã©levage et de mÃ©decine vÃ©tÃ©rinaire des pays tropicaux (French)",
    parent: "NA",
    urlone: "http://remvt.cirad.fr/gb/presentation/note.html",
    urltwo: "NA"
  },
  {
    id: "revue-de-chirurgie-orthopedique-et-traumatologique",
    text: "Revue de Chirurgie Orthopedique et Traumatologique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-de-medecine-veterinaire",
    text: "Revue de MÃ©decine VÃ©tÃ©rinaire (French)",
    parent: "NA",
    urlone: "http://www.revmedvet.com/recommandations_fr.html",
    urltwo: "NA"
  },
  {
    id: "revue-de-micropaleontologie",
    text: "Revue de micropalÃ©ontologie",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-de-pneumologie-clinique",
    text: "Revue de Pneumologie clinique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-de-qumran",
    text: "Revue de QumrÃ¢n",
    parent: "NA",
    urlone: "http://revuedequmran.fr/index.php/en/contributors",
    urltwo: "NA"
  },
  {
    id: "revue-de-stomatologie-de-chirurgie-maxillo-faciale-et-de-chirurgie-orale",
    text: "Revue de Stomatologie, de Chirurgie Maxillo-faciale et de Chirurgie Orale",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-depidemiologie-et-de-sante-publique",
    text: "Revue d'Epidemiologie et de SantÃ© Publique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-des-etudes-byzantines",
    text: "Revue des Ã‰tudes Byzantines",
    parent: "NA",
    urlone: "http://poj.peeters-leuven.be/content.php?url=journal&amp;journal_code=REB",
    urltwo: "NA"
  },
  {
    id: "revue-des-maladies-respiratoires",
    text: "Revue des Maladies Respiratoires",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-des-nouvelles-technologies-de-l-information",
    text: "Revue des Nouvelles Technologies de l'Information (French)",
    parent: "NA",
    urlone: "http://editions-rnti.fr/?m=infosoumission",
    urltwo: "NA"
  },
  {
    id: "revue-dhistoire-des-sciences-humaines",
    text: "Revue d'histoire des sciences humaines (French)",
    parent: "NA",
    urlone: "https://journals.openedition.org/rhsh/1273",
    urltwo: "NA"
  },
  {
    id: "revue-dhistoire-moderne-et-contemporaine",
    text: "Revue d'histoire moderne et contemporaine (French)",
    parent: "NA",
    urlone: "http://www.cairn.info/revue-d-histoire-moderne-et-contemporaine.htm",
    urltwo: "NA"
  },
  {
    id: "revue-doncologie-hematologie-pediatrique",
    text: "Revue d'Oncologie HÃ©matologie PÃ©diatrique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-du-podologue",
    text: "Revue du podologue",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-du-rhumatisme-monographies",
    text: "Revue du Rhumatisme monographies",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-du-rhumatisme",
    text: "Revue du rhumatisme",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-europeenne-de-psychologie-appliquee",
    text: "Revue europÃ©enne de psychologie appliquÃ©e",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-europeenne-des-migrations-internationales",
    text: "Revue EuropeÌenne des Migrations Internationales",
    parent: "NA",
    urlone: "https://remi.revues.org/5848",
    urltwo: "NA"
  },
  {
    id: "revue-francaise-d-administration-publique",
    text: "Revue franÃ§aise d'administration publique (French)",
    parent: "NA",
    urlone: "https://www.ena.fr/La-recherche-a-l-ENA/Publications/rfap",
    urltwo: "NA"
  },
  {
    id: "revue-francaise-dallergologie",
    text: "Revue FranÃ§aise d'Allergologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-francaise-de-gestion",
    text: "Revue franÃ§aise de gestion (French)",
    parent: "NA",
    urlone: "https://rfg.revuesonline.com/pour-les-auteurs/instructions-aux-auteurs",
    urltwo: "NA"
  },
  {
    id: "revue-francaise-de-sociologie-en",
    text: "Revue franÃ§aise de sociologie (English)",
    parent: "revue-francaise-de-sociologie",
    urlone: "http://www.rfs-revue.com/",
    urltwo: "NA"
  },
  {
    id: "revue-francaise-de-sociologie",
    text: "Revue franÃ§aise de sociologie (French)",
    parent: "NA",
    urlone: "http://www.rfs-revue.com/",
    urltwo: "NA"
  },
  {
    id: "revue-francophone-de-cicatrisation",
    text: "Revue Francophone de Cicatrisation",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-francophone-dorthoptie",
    text: "Revue Francophone d'Orthoptie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-francophone-internationale-de-recherche-infirmiere",
    text: "Revue francophone internationale de recherche infirmiÃ¨re",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-neurologique",
    text: "Revue Neurologique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "revue-veterinaire-clinique",
    text: "Revue vÃ©tÃ©rinaire Clinique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rheologica-acta",
    text: "Rheologica Acta",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "rheuma-plus",
    text: "rheuma plus (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "rheumatology-and-therapy",
    text: "Rheumatology and Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "rheumatology-international",
    text: "Rheumatology International",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "rheumatology",
    text: "Rheumatology",
    parent: "vancouver-brackets",
    urlone: "http://www.oxfordjournals.org/our_journals/brheum/for_authors/manuscript_preparation.html#References",
    urltwo: "NA"
  },
  {
    id: "rhinology",
    text: "Rhinology",
    parent: "NA",
    urlone: "https://www.rhinologyjournal.com/Instructions.php",
    urltwo: "NA"
  },
  {
    id: "rhizosphere",
    text: "Rhizosphere",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rhodora",
    text: "Rhodora",
    parent: "NA",
    urlone: "http://www.rhodora.org/rhodora/pdf/Rhod-inst2012.pdf",
    urltwo: "NA"
  },
  {
    id: "ribagua-revista-iberoamericana-del-agua",
    text: "RIBAGUA - Revista Iberoamericana del Agua",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rice",
    text: "Rice",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "ricerche-di-matematica",
    text: "Ricerche di Matematica",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "risk-analysis",
    text: "Risk Analysis",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291539-6924/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "risks",
    text: "Risks",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "ritid",
    text: "RitiÃ°: tÃ­marit HugvÃ­sindastofnunar (Icelandic)",
    parent: "NA",
    urlone: "http://hugras.is/ritid/ritid-leidbeiningar-til-hofunda-um-fragang-greina",
    urltwo: "NA"
  },
  {
    id: "rivista-italiana-di-paleontologia-e-stratigrafia",
    text: "Rivista Italiana di Paleontologia e Stratigrafia",
    parent: "NA",
    urlone: "https://riviste.unimi.it/index.php/RIPS/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "rmit-university-harvard",
    text: "RMIT University - Harvard",
    parent: "NA",
    urlone: "https://www.lib.rmit.edu.au/easy-cite/",
    urltwo: "NA"
  },
  {
    id: "rna-biology",
    text: "RNA Biology",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rna",
    text: "RNA",
    parent: "cold-spring-harbor-laboratory-press",
    urlone: "http://rnajournal.cshlp.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "road-materials-and-pavement-design",
    text: "Road Materials and Pavement Design",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TRMP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "robomech-journal",
    text: "ROBOMECH Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "robotics-and-autonomous-systems",
    text: "Robotics and Autonomous Systems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "robotics-and-biomimetics",
    text: "Robotics and Biomimetics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "robotics-and-computer-integrated-manufacturing",
    text: "Robotics and Computer-Integrated Manufacturing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "robotics",
    text: "Robotics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "rock-mechanics-and-rock-engineering",
    text: "Rock Mechanics and Rock Engineering",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "rofo",
    text: "RÃ¶Fo: Fortschritte auf dem Gebiet der RÃ¶ntgenstrahlen und bildgebenden Verfahren",
    parent: "NA",
    urlone:
      "http://roefo.thieme.de/c/document_library/get_file?uuid=45dabb23-098a-4981-8a4a-0f1d74a7f2c7&amp;groupId=10157",
    urltwo: "NA"
  },
  {
    id: "romanian-humanities",
    text: "Romanian Humanities (Romanian)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rorschachiana",
    text: "Rorschachiana",
    parent: "apa",
    urlone: "http://content.apa.org/journals/ror",
    urltwo: "NA"
  },
  {
    id: "rose-school",
    text: "ROSE School",
    parent: "NA",
    urlone: "http://www.roseschool.it/",
    urltwo: "NA"
  },
  {
    id: "rossiiskii-fiziologicheskii-zhurnal-imeni-i-m-sechenova",
    text: "Ð Ð¾ÑÑÐ¸Ð¹ÑÐºÐ¸Ð¹ Ñ„Ð¸Ð·Ð¸Ð¾Ð»Ð¾Ð³Ð¸Ñ‡ÐµÑÐºÐ¸Ð¹ Ð¶ÑƒÑ€Ð½Ð°Ð» Ð¸Ð¼. Ð˜.Ðœ. Ð¡ÐµÑ‡ÐµÐ½Ð¾Ð²Ð° (Russian)",
    parent: "NA",
    urlone: "http://www.iephb.ru/rule_av_2013.pdf",
    urltwo: "NA"
  },
  {
    id: "royal-college-of-nursing-harvard",
    text: "Royal College of Nursing - Harvard",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/4255#issuecomment-526675875",
    urltwo: "NA"
  },
  {
    id: "royal-society-of-chemistry-with-titles",
    text: "Royal Society of Chemistry (with titles)",
    parent: "NA",
    urlone: "http://www.rsc.org/learn-chemistry/resource/download/res00001664/cmp00004956/pdf",
    urltwo: "http://www.rsc.org/journals-books-databases/journal-authors-reviewers/prepare-your-article/#format-layout"
  },
  {
    id: "royal-society-of-chemistry",
    text: "Royal Society of Chemistry",
    parent: "NA",
    urlone: "http://www.rsc.org/learn-chemistry/resource/download/res00001664/cmp00004956/pdf",
    urltwo: "http://www.rsc.org/journals-books-databases/journal-authors-reviewers/prepare-your-article/#format-layout"
  },
  {
    id: "royal-society-open-science",
    text: "Royal Society Open Science",
    parent: "proceedings-of-the-royal-society-b",
    urlone: "https://royalsociety.org/journals/authors/author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "rsc-advances",
    text: "RSC Advances",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "rtf-scan",
    text: "RTF Scan",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ruhr-universitat-bochum-lehrstuhl-fur-industrial-sales-and-service-engineering",
    text: "Ruhr-UniversitÃ¤t Bochum - Lehrstuhl fÃ¼r Industrial Sales and Service Engineering",
    parent: "NA",
    urlone: "http://www.isse.rub.de/mam/leitfaden_zum_verfassen_fachwissenschaftlicher_arbeiten.pdf",
    urltwo: "NA"
  },
  {
    id: "ruhr-universitat-bochum-medizinische-fakultat-numeric",
    text: "Ruhr-UniversitÃ¤t Bochum - Medizinische FakultÃ¤t (numerisch, German)",
    parent: "NA",
    urlone:
      "https://www.medizin.ruhr-uni-bochum.de/mam/content/dekanat/sachgebiete_service/promotion/merkblatt_zur_abfassung_der_dissertation_21.02.19.pdf",
    urltwo: "NA"
  },
  {
    id: "russian-chemical-reviews",
    text: "Russian Chemical Reviews",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0036-021X",
    urltwo: "NA"
  },
  {
    id: "russian-geology-and-geophysics",
    text: "Russian Geology and Geophysics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "russian-journal-of-communication",
    text: "Russian Journal of Communication",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RRJC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "russian-linguistics",
    text: "Russian Linguistics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "russian-mathematical-surveys",
    text: "Russian Mathematical Surveys",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0036-0279",
    urltwo: "NA"
  },
  {
    id: "safety-and-health-at-work",
    text: "Safety and Health at Work",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "safety-in-health",
    text: "Safety in Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "safety-science",
    text: "Safety Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "safety",
    text: "Safety",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "sage-harvard",
    text: "SAGE - Harvard",
    parent: "NA",
    urlone: "https://uk.sagepub.com/sites/default/files/sage_harvard_reference_style_0.pdf",
    urltwo: "NA"
  },
  {
    id: "sage-vancouver-brackets",
    text: "SAGE - Vancouver (brackets)",
    parent: "NA",
    urlone: "http://www.uk.sagepub.com/repository/binaries/pdf/SAGE_Vancouver_reference_style.pdf",
    urltwo: "NA"
  },
  {
    id: "sage-vancouver",
    text: "SAGE - Vancouver",
    parent: "NA",
    urlone: "http://www.uk.sagepub.com/repository/binaries/pdf/SAGE_Vancouver_reference_style.pdf",
    urltwo: "NA"
  },
  {
    id: "saint-paul-university-faculty-of-canon-law",
    text: "Saint Paul University - Faculty of Canon Law",
    parent: "NA",
    urlone: "https://ustpaul.ca/upload-files/CanonLaw/cours2015-2016/2015_-_Guidelines_-_12th_ed.pdf",
    urltwo: "NA"
  },
  {
    id: "salud-colectiva",
    text: "Salud Colectiva (Spanish)",
    parent: "vancouver",
    urlone: "http://www.scielo.org.ar/revistas/sc/einstruc.htm",
    urltwo: "NA"
  },
  {
    id: "salud-publica-de-mexico",
    text: "Salud PÃºblica de MÃ©xico",
    parent: "vancouver",
    urlone: "http://bvs.insp.mx/rsp/info_aut_es/",
    urltwo: "NA"
  },
  {
    id: "samples",
    text: "Samples (German)",
    parent: "gesellschaft-fur-popularmusikforschung",
    urlone: "http://www.gfpm-samples.de/hinweise.html",
    urltwo: "NA"
  },
  {
    id: "sanamed",
    text: "Sanamed",
    parent: "NA",
    urlone: "http://www.sanamed.rs/OJS/index.php/Sanamed/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "sanidad-militar",
    text: "Sanidad Militar",
    parent: "vancouver",
    urlone: "http://www.portalcultura.mde.es/publicaciones/revistas/sanidad_militar/index.html",
    urltwo: "NA"
  },
  {
    id: "sao-paulo-journal-of-mathematical-sciences",
    text: "SÃ£o Paulo Journal of Mathematical Sciences",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "saudi-journal-of-biological-sciences",
    text: "Saudi Journal of Biological Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "saudi-pharmaceutical-journal",
    text: "Saudi Pharmaceutical Journal",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sbornik-mathematics",
    text: "Sbornik: Mathematics",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1064-5616",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-disability-research",
    text: "Scandinavian Journal of Disability Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=SJDR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-educational-research",
    text: "Scandinavian Journal of Educational Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSJE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-forest-research",
    text: "Scandinavian Journal of Forest Research",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=SFOR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-hospitality-and-tourism",
    text: "Scandinavian Journal of Hospitality and Tourism",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=SJHT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-infectious-diseases",
    text: "Scandinavian Journal of Infectious Diseases",
    parent: "NA",
    urlone: "http://informahealthcare.com/page/inf/Description#Instructions",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-management",
    text: "Scandinavian Journal of Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-medicine-and-science-in-sports",
    text: "Scandinavian Journal of Medicine &amp; Science in Sports",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1600-0838/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-pain",
    text: "Scandinavian Journal of Pain",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-public-health",
    text: "Scandinavian Journal of Public Health",
    parent: "sage-vancouver-brackets",
    urlone: "https://uk.sagepub.com/en-gb/eur/journal/scandinavian-journal-public-health#JournalLayout",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-rheumatology",
    text: "Scandinavian Journal of Rheumatology",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/healthcare/IRHE.pdf",
    urltwo: "NA"
  },
  {
    id: "scandinavian-journal-of-trauma-resuscitation-and-emergency-medicine",
    text: "Scandinavian Journal of Trauma, Resuscitation and Emergency Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "scandinavian-journal-of-work-environment-and-health",
    text: "Scandinavian Journal of Work, Environment &amp; Health",
    parent: "NA",
    urlone: "http://www.sjweh.fi/instructions.php#preparation",
    urltwo: "NA"
  },
  {
    id: "scandinavian-political-studies",
    text: "Scandinavian Political Studies",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1467-9477/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "schizophrenia-research-cognition",
    text: "Schizophrenia Research: Cognition",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "schizophrenia-research",
    text: "Schizophrenia Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "scholarship-of-teaching-and-learning-in-psychology",
    text: "Scholarship of Teaching and Learning in Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/stl",
    urltwo: "NA"
  },
  {
    id: "school-effectiveness-and-school-improvement",
    text: "School Effectiveness and School Improvement",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NSES20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "school-leadership-and-management",
    text: "School Leadership &amp; Management",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSLM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "school-mental-health",
    text: "School Mental Health",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "school-psychology-quarterly",
    text: "School Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/spq",
    urltwo: "NA"
  },
  {
    id: "schweizerische-zeitschrift-fur-ganzheitsmedizin",
    text: "Schweizerische Zeitschrift fÃ¼r Ganzheitsmedizin",
    parent: "karger-journals",
    urlone: "http://www.karger.com/Journal/Home/253638",
    urltwo: "NA"
  },
  {
    id: "science-advances",
    text: "Science Advances",
    parent: "science",
    urlone: "http://advances.sciencemag.org/content/information-authors",
    urltwo: "NA"
  },
  {
    id: "science-and-education",
    text: "Science &amp; Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "science-and-engineering-ethics",
    text: "Science and Engineering Ethics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "science-and-justice",
    text: "Science &amp; Justice",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "science-and-technology-for-the-built-environment",
    text: "Science and Technology for the Built Environment",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=uhvc21&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "science-and-technology-of-advanced-materials",
    text: "Science and Technology of Advanced Materials",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1468-6996",
    urltwo: "NA"
  },
  {
    id: "science-bulletin",
    text: "Science Bulletin",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "science-china-chemistry",
    text: "Science China Chemistry",
    parent: "NA",
    urlone: "http://engine.scichina.com/authorCenter/guide/Manuscript/FTxe43yy66E6x8JT6",
    urltwo: "https://www.springer.com/chemistry/journal/11426"
  },
  {
    id: "science-china-earth-sciences",
    text: "SCIENCE CHINA Earth Sciences",
    parent: "NA",
    urlone: "https://www.springer.com/earth+sciences+and+geography/journal/11430",
    urltwo: "NA"
  },
  {
    id: "science-china-life-sciences",
    text: "SCIENCE CHINA Life Sciences",
    parent: "NA",
    urlone: "http://www.springer.com/life+sciences/journal/11427",
    urltwo: "NA"
  },
  {
    id: "science-et-sports",
    text: "Science et Sports",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "science-foundation-in-china",
    text: "Science Foundation in China",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/1005-0841",
    urltwo: "NA"
  },
  {
    id: "science-of-computer-programming",
    text: "Science of Computer Programming",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "science-of-the-total-environment",
    text: "Science of the Total Environment",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "science-translational-medicine",
    text: "Science Translational Medicine",
    parent: "NA",
    urlone: "http://stm.sciencemag.org/site/misc/auth_inst_ra.xhtml#8",
    urltwo: "NA"
  },
  {
    id: "science-without-titles",
    text: "Science (without titles)",
    parent: "NA",
    urlone: "http://www.sciencemag.org/about/authors/prep/res/refs.dtl",
    urltwo: "NA"
  },
  {
    id: "science",
    text: "Science",
    parent: "NA",
    urlone: "http://www.sciencemag.org/about/authors/prep/res/refs.dtl",
    urltwo: "NA"
  },
  {
    id: "scienceasia",
    text: "ScienceAsia",
    parent: "NA",
    urlone: "http://www.scienceasia.org/scias_guide.pdf",
    urltwo: "NA"
  },
  {
    id: "sciences-po-ecole-doctorale-author-date",
    text: "Sciences Po - Ecole doctorale (author-date, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sciences-po-ecole-doctorale-note-french",
    text: "Sciences Po - Ã‰cole doctorale (note, French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "scientia-agriculturae-bohemica",
    text: "Scientia Agriculturae Bohemica",
    parent: "NA",
    urlone: "http://sab.czu.cz/?r=5601",
    urltwo: "NA"
  },
  {
    id: "scientia-horticulturae",
    text: "Scientia Horticulturae",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "scientia-iranica",
    text: "Scientia Iranica",
    parent: "NA",
    urlone: "http://scientiairanica.sharif.edu/journal/authors.note",
    urltwo: "NA"
  },
  {
    id: "scientia-pharmaceutica",
    text: "Scientia Pharmaceutica",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "scientific-african",
    text: "Scientific African",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "scientific-drilling",
    text: "Scientific Drilling",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "scientific-phone-apps-and-mobile-devices",
    text: "Scientific Phone Apps and Mobile Devices",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "scientific-reports",
    text: "Scientific Reports",
    parent: "nature",
    urlone: "http://www.nature.com/srep/authors/submit.html#references",
    urltwo: "NA"
  },
  {
    id: "scientific-review-engineering-and-environmental-sciences",
    text:
      "Scientific Review Engineering and Environmental Sciences (PrzeglÄ…d Naukowy InÅ¼ynieria i KsztaÅ‚towanie Åšrodowiska)",
    parent: "NA",
    urlone: "http://iks_pn.sggw.pl/Instruction_PN_2016a.pdf",
    urltwo: "NA"
  },
  {
    id: "scientometrics",
    text: "Scientometrics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "scoliosis",
    text: "Scoliosis",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "scottish-journal-of-geology",
    text: "Scottish Journal of Geology",
    parent: "the-geological-society-of-london",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "scrinium",
    text: "Scrinium",
    parent: "NA",
    urlone: "http://www.brill.com/sites/default/files/ftp/authors_instructions/SCRI.pdf",
    urltwo: "NA"
  },
  {
    id: "scripta-materialia",
    text: "Scripta Materialia",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "securitas-vialis",
    text: "Securitas Vialis (Spanish)",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "security-informatics",
    text: "Security Informatics",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sedimentary-geology",
    text: "Sedimentary Geology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sedimentology",
    text: "Sedimentology",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/13653091/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "seed-science-and-technology",
    text: "Seed Science and Technology",
    parent: "NA",
    urlone: "https://www.seedtest.org/en/instructions-to-contributors-_content---1--1089.html",
    urltwo: "NA"
  },
  {
    id: "seed-science-research",
    text: "Seed Science Research",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/journals/seed-science-research/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "seismological-research-letters",
    text: "Seismological Research Letters",
    parent: "NA",
    urlone: "https://www.seismosoc.org/publications/srl-authorsinfo/",
    urltwo: "NA"
  },
  {
    id: "seizure-european-journal-of-epilepsy",
    text: "Seizure: European Journal of Epilepsy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sekolah-tinggi-meteorologi-klimatologi-dan-geofisika",
    text: "Sekolah Tinggi Meteorologi Klimatologi dan Geofisika (Indonesian)",
    parent: "NA",
    urlone: "https://stmkg.ac.id/",
    urltwo: "https://aruya.medium.com/cara-menambahkan-custom-citation-style-stmkg-pada-aplikasi-mendeley-2354b66217a2"
  },
  {
    id: "selcuk-tip-dergisi",
    text: "SelÃ§uk TÄ±p Dergisi (Medical Journal of SelÃ§uk)",
    parent: "vancouver",
    urlone: "http://www.selcuktipdergisi.org/en/instructionforauthors.pdf",
    urltwo: "NA"
  },
  {
    id: "selecta-mathematica",
    text: "Selecta Mathematica",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "self-and-identity",
    text: "Self and Identity",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PSAI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "semiconductor-science-and-technology",
    text: "Semiconductor Science and Technology",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0268-1242",
    urltwo: "NA"
  },
  {
    id: "semigroup-forum",
    text: "Semigroup Forum",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "seminaire-saint-sulpice-ecole-theologie",
    text: "SÃ©minaire Saint-Sulpice - Ecole ThÃ©ologie (French)",
    parent: "NA",
    urlone: "http://www.seminairesaintsulpice.fr/",
    urltwo: "NA"
  },
  {
    id: "seminars-in-arthritis-and-rheumatism",
    text: "Seminars in Arthritis and Rheumatism",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-arthroplasty",
    text: "Seminars in Arthroplasty",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-cancer-biology",
    text: "Seminars in Cancer Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-cell-and-developmental-biology",
    text: "Seminars in Cell and Developmental Biology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-colon-and-rectal-surgery",
    text: "Seminars in Colon and Rectal Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-diagnostic-pathology",
    text: "Seminars in Diagnostic Pathology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-fetal-and-neonatal-medicine",
    text: "Seminars in Fetal and Neonatal Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-immunology",
    text: "Seminars in Immunology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-immunopathology",
    text: "Seminars in Immunopathology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "seminars-in-oncology-nursing",
    text: "Seminars in Oncology Nursing",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-orthodontics",
    text: "Seminars in Orthodontics",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-pediatric-neurology",
    text: "Seminars in Pediatric Neurology",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/seminars-in-pediatric-neurology/1071-9091/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "seminars-in-pediatric-surgery",
    text: "Seminars in Pediatric Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-perinatology",
    text: "Seminars in Perinatology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-spine-surgery",
    text: "Seminars in Spine Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "seminars-in-vascular-surgery",
    text: "Seminars in Vascular Surgery",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "senologie",
    text: "Senologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "sensing-and-bio-sensing-research",
    text: "Sensing and Bio-Sensing Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sensing-and-imaging",
    text: "Sensing and Imaging",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sensors-and-actuators-a-physical",
    text: "Sensors &amp; Actuators: A. Physical",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sensors-and-actuators-b-chemical",
    text: "Sensors &amp; Actuators: B. Chemical",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sensors",
    text: "Sensors",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "separation-and-purification-technology",
    text: "Separation and Purification Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "separations",
    text: "Separations",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "serdica-journal-of-computing",
    text: "Serdica Journal of Computing",
    parent: "NA",
    urlone: "http://serdica-comp.math.bas.bg/index.php/serdicajcomputing/about/submissions#authorGuidelines",
    urltwo: "http://serdica-comp.math.bas.bg/public/site/sjc_sample.pdf"
  },
  {
    id: "serials-review",
    text: "Serials Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "series",
    text: "SERIEs",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "service-business",
    text: "Service Business",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "service-medical-de-l-assurance-maladie",
    text: "Service MÃ©dical de l'Assurance Maladie (French)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/2191",
    urltwo: "NA"
  },
  {
    id: "service-oriented-computing-and-applications",
    text: "Service Oriented Computing and Applications",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "service-science",
    text: "Service Science",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/serv/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "set-valued-and-variational-analysis",
    text: "Set-Valued and Variational Analysis",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sex-education",
    text: "Sex Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "sex-roles",
    text: "Sex Roles",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sexologies",
    text: "Sexologies",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sexual-and-relationship-therapy",
    text: "Sexual and Relationship Therapy",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSMT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "sexual-and-reproductive-healthcare",
    text: "Sexual &amp; Reproductive Healthcare",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sexual-development",
    text: "Sexual Development",
    parent: "NA",
    urlone: "http://www.karger.com/Journal/Guidelines/231547#07",
    urltwo: "NA"
  },
  {
    id: "sexual-health",
    text: "Sexual Health",
    parent: "NA",
    urlone: "http://www.publish.csiro.au/sh/forauthors/authorinstructions#15",
    urltwo: "NA"
  },
  {
    id: "sexual-medicine-reviews",
    text: "Sexual Medicine Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sexual-medicine",
    text: "Sexual Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sexuality-and-culture",
    text: "Sexuality &amp; Culture",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sexuality-and-disability",
    text: "Sexuality and Disability",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sexuality-research-and-social-policy",
    text: "Sexuality Research and Social Policy",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sexually-transmitted-diseases",
    text: "Sexually Transmitted Diseases",
    parent: "vancouver-superscript",
    urlone: "http://journals.lww.com/stdjournal/Pages/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "sexually-transmitted-infections",
    text: "Sexually Transmitted Infections",
    parent: "bmj",
    urlone: "http://sti.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "shakespeare",
    text: "Shakespeare",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/toc/rshk20/current#.U3qu-C_Wr7A",
    urltwo: "NA"
  },
  {
    id: "shape-memory-and-superelasticity",
    text: "Shape Memory and Superelasticity",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sheffield-hallam-university-history",
    text: "Sheffield Hallam University - History",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "ships-and-offshore-structures",
    text: "Ships and Offshore Structures",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TSOS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "shock-waves",
    text: "Shock Waves",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "shock",
    text: "Shock",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/shk/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "signal-image-and-video-processing",
    text: "Signal, Image and Video Processing",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "signal-processing-image-communication",
    text: "Signal Processing: Image Communication",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "signal-processing",
    text: "Signal Processing",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "signals",
    text: "Signals",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "silicon",
    text: "Silicon",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "simulation-modelling-practice-and-theory",
    text: "Simulation Modelling Practice and Theory",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "singapore-dental-journal",
    text: "Singapore Dental Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sinusitis",
    text: "Sinusitis",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "sist02",
    text: "SIST02 (Japanese)",
    parent: "NA",
    urlone: "http://sti.jst.go.jp/sist/handbook/sist02_2007/main.htm",
    urltwo: "NA"
  },
  {
    id: "skeletal-muscle",
    text: "Skeletal Muscle",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "skeletal-radiology",
    text: "Skeletal Radiology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "skene-journal-of-theatre-and-drama-studies",
    text: "SkenÃ¨. Journal of Theatre and Drama Studies",
    parent: "NA",
    urlone: "https://skenejournal.skeneproject.it/index.php/JTDS/information/authors",
    urltwo: "NA"
  },
  {
    id: "skin-pharmacology-and-physiology",
    text: "Skin Pharmacology and Physiology",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1660-5527",
    urltwo: "NA"
  },
  {
    id: "slavonic-and-east-european-review",
    text: "Slavonic &amp; East European Review",
    parent: "modern-humanities-research-association",
    urlone: "http://www.mhra.org.uk/Publications/Journals/seer.html",
    urltwo: "NA"
  },
  {
    id: "sleep-and-biological-rhythms",
    text: "Sleep and Biological Rhythms",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sleep-and-breathing",
    text: "Sleep and Breathing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sleep-health-journal-of-the-national-sleep-foundation",
    text: "Sleep Health: Journal of the National Sleep Foundation",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sleep-medicine-reviews",
    text: "Sleep Medicine Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sleep-medicine-x",
    text: "Sleep Medicine: X",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sleep-medicine",
    text: "Sleep Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sleep-science",
    text: "Sleep Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sleep",
    text: "SLEEP",
    parent: "american-medical-association",
    urlone: "https://academic.oup.com/sleep/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "small-business-economics",
    text: "Small Business Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "small-gtpases",
    text: "Small GTPases",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "small-ruminant-research",
    text: "Small Ruminant Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "small-scale-forestry",
    text: "Small-scale Forestry",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "small-wars-and-insurgencies",
    text: "Small Wars &amp; Insurgencies",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FSWI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "small",
    text: "Small",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1613-6829/homepage/2296_authors.html",
    urltwo: "NA"
  },
  {
    id: "smart-health",
    text: "Smart Health",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "smart-learning-environments",
    text: "Smart Learning Environments",
    parent: "springer-physics-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "smart-materials-and-structures",
    text: "Smart Materials and Structures",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0964-1726",
    urltwo: "NA"
  },
  {
    id: "smart-water",
    text: "Smart Water",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "smithsonian-institution-scholarly-press-author-date",
    text: "Smithsonian Institution Scholarly Press (author-date)",
    parent: "NA",
    urlone: "https://scholarlypress.si.edu/resources/guidelinesforms/",
    urltwo: "NA"
  },
  {
    id: "smithsonian-institution-scholarly-press-botany",
    text: "Smithsonian Institution Scholarly Press - Botany (author-date)",
    parent: "NA",
    urlone: "https://scholarlypress.si.edu/resources/guidelinesforms/",
    urltwo: "NA"
  },
  {
    id: "smithsonian-institution-scholarly-press-note",
    text: "Smithsonian Institution Scholarly Press (note)",
    parent: "NA",
    urlone: "https://scholarlypress.si.edu/resources/guidelinesforms/",
    urltwo: "NA"
  },
  {
    id: "smyrna-tip-dergisi",
    text: "Smyrna TÄ±p Dergisi (Turkish)",
    parent: "NA",
    urlone: "http://www.smyrnatipdergisi.com/?kategori=Yazarlara%20Bilgi",
    urltwo: "NA"
  },
  {
    id: "soccer-and-society",
    text: "Soccer &amp; Society",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FSAS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "social-and-environmental-accountability-journal",
    text: "Social and Environmental Accountability Journal",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REAJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "social-and-natural-sciences-journal",
    text: "Social and Natural Sciences Journal",
    parent: "apa",
    urlone: "http://ojs.journals.cz/index.php/SNSJ/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "social-choice-and-welfare",
    text: "Social Choice and Welfare",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "social-cognitive-and-affective-neuroscience",
    text: "Social Cognitive and Affective Neuroscience",
    parent: "NA",
    urlone: "https://academic.oup.com/scan/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "social-dynamics",
    text: "Social Dynamics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSDY20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "social-identities",
    text: "Social Identities",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSID20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "social-indicators-research",
    text: "Social Indicators Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "social-influence",
    text: "Social Influence",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PSIF20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "social-justice-research",
    text: "Social Justice Research",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "social-movement-studies",
    text: "Social Movement Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSMS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "social-network-analysis-and-mining",
    text: "Social Network Analysis and Mining",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "social-networks",
    text: "Social Networks",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "social-neuroscience",
    text: "Social Neuroscience",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PSNS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "social-psychiatry-and-psychiatric-epidemiology",
    text: "Social Psychiatry and Psychiatric Epidemiology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "social-psychology-of-education",
    text: "Social Psychology of Education",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "social-psychology-quarterly",
    text: "Social Psychology Quarterly",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/spq/social_psychology_quarterly.cfm",
    urltwo: "NA"
  },
  {
    id: "social-psychology",
    text: "Social Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/zsp",
    urltwo: "NA"
  },
  {
    id: "social-science-and-medicine",
    text: "Social Science &amp; Medicine",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "social-science-research",
    text: "Social Science Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "social-sciences-and-humanities-open",
    text: "Social Sciences &amp; Humanities Open",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "social-sciences",
    text: "Social Sciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "social-semiotics",
    text: "Social Semiotics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSOS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "social-studies-of-science",
    text: "Social Studies of Science",
    parent: "sage-harvard",
    urlone: "www.sagepub.com/journals/Journal200907/manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "social-work-education",
    text: "Social Work Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSWE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "sociedade-brasileira-de-computacao",
    text: "Sociedade Brasileira de ComputaÃ§Ã£o",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "societe-archeologique-de-bordeaux",
    text: "SociÃ©tÃ© ArchÃ©ologique de Bordeaux (French)",
    parent: "NA",
    urlone: "http://www.societe-archeologique-bordeaux.fr/publications/recommandations-aux-auteurs.html",
    urltwo: "NA"
  },
  {
    id: "societe-francaise-detude-de-la-ceramique-antique-en-gaule",
    text: "SociÃ©tÃ© FranÃ§aise dâ€™Ã‰tude de la CÃ©ramique Antique en Gaule (French)",
    parent: "NA",
    urlone: "http://sfecag.free.fr/recommandations.pdf",
    urltwo: "NA"
  },
  {
    id: "societe-nationale-des-groupements-techniques-veterinaires",
    text: "SociÃ©tÃ© Nationale des Groupements Techniques VÃ©tÃ©rinaires (French)",
    parent: "NA",
    urlone: "http://www.sngtv.org/",
    urltwo: "NA"
  },
  {
    id: "societes-contemporaines",
    text: "SociÃ©tÃ©s Contemporaines",
    parent: "NA",
    urlone: "https://www.cairn.info/revue-societes-contemporaines.htm?contenu=apropos",
    urltwo: "NA"
  },
  {
    id: "societies",
    text: "Societies",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "society-and-mental-health",
    text: "Society and Mental Health",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/smh.cfm",
    urltwo: "NA"
  },
  {
    id: "society-for-american-archaeology",
    text: "Society for American Archaeology",
    parent: "NA",
    urlone: "https://www.saa.org/publications",
    urltwo:
      "https://documents.saa.org/container/docs/default-source/doc-publications/style-guide/saa-style-guide_updated-july-2018c5062f7e55154959ab57564384bda7de.pdf?sfvrsn=8247640e_6"
  },
  {
    id: "society-for-historical-archaeology",
    text: "Society for Historical Archaeology",
    parent: "NA",
    urlone: "http://www.sha.org/publications/for_authors.cfm",
    urltwo: "NA"
  },
  {
    id: "society-for-laboratory-automation-and-screening",
    text: "Society for Laboratory Automation and Screening",
    parent: "NA",
    urlone: "http://www.slas.org/publications/scientific-journals/author-instructions/",
    urltwo: "http://pubs.acs.org/doi/pdf/10.1021/bk-2006-STYG.ch014"
  },
  {
    id: "society-of-automotive-engineers-technical-papers-numeric",
    text: "Society of Automotive Engineers Technical Papers (numeric)",
    parent: "NA",
    urlone: "http://volunteers.sae.org/authors/FormattingCitations.pdf",
    urltwo: "NA"
  },
  {
    id: "society-of-biblical-literature-author-date",
    text: "Society of Biblical Literature 2nd edition (author-date)",
    parent: "NA",
    urlone: "http://www.sbl-site.org/publications/SBLHandbookofStyle.aspx",
    urltwo: "https://sblhs2.com/2016/10/13/placement-citations-author-date/"
  },
  {
    id: "society-of-biblical-literature-fullnote-bibliography",
    text: "Society of Biblical Literature 2nd edition (full note)",
    parent: "NA",
    urlone: "http://www.sbl-site.org/publications/SBLHandbookofStyle.aspx",
    urltwo: "NA"
  },
  {
    id: "socio-economic-planning-sciences",
    text: "Socio-Economic Planning Sciences",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "socio-economic-review",
    text: "Socio-Economic Review",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/soceco/for_authors/examples_of_citations_and_refere.html",
    urltwo: "NA"
  },
  {
    id: "sociological-methodology",
    text: "Sociological Methodology",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/sm/sm.cfm",
    urltwo: "NA"
  },
  {
    id: "sociological-theory",
    text: "Sociological Theory",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/st/st.cfm",
    urltwo: "NA"
  },
  {
    id: "sociologie-du-travail",
    text: "Sociologie du travail",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sociology-of-education",
    text: "Sociology of Education",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/soe/soe.cfm",
    urltwo: "NA"
  },
  {
    id: "sociology-of-health-and-illness",
    text: "Sociology of Health &amp; Illness",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291467-9566/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "sociology",
    text: "Sociology",
    parent: "sage-harvard",
    urlone: "http://mc.manuscriptcentral.com/societyimages/soc/SOC%20General%20Notes%20to%20Contributors%202013.pdf",
    urltwo: "NA"
  },
  {
    id: "sodertorns-hogskola-harvard-ibid",
    text: "SÃ¶dertÃ¶rns hÃ¶gskola - Harvard (with Ibid.)",
    parent: "NA",
    urlone: "https://www.sh.se/p3/ext/content.nsf/aget?openagent&amp;key=guider_till_olika_format_1354269274669",
    urltwo:
      "https://www.sh.se/p3/ext/res.nsf/vRes/bibliotek_referenser_harvard_2_1_2_pdf/$File/Referenser%20Harvard%202_1_2.pdf"
  },
  {
    id: "sodertorns-hogskola-harvard",
    text: "SÃ¶dertÃ¶rns hÃ¶gskola - Harvard",
    parent: "NA",
    urlone: "https://www.sh.se/p3/ext/content.nsf/aget?openagent&amp;key=guider_till_olika_format_1354269274669",
    urltwo:
      "https://www.sh.se/p3/ext/res.nsf/vRes/bibliotek_referenser_harvard_2_1_2_pdf/$File/Referenser%20Harvard%202_1_2.pdf"
  },
  {
    id: "soft-computing",
    text: "Soft Computing",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "soft-matter",
    text: "Soft Matter",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soft-robotics",
    text: "Soft Robotics",
    parent: "NA",
    urlone: "https://home.liebertpub.com/publications/soft-robotics/616/for-authors",
    urltwo: "NA"
  },
  {
    id: "software-and-systems-modeling",
    text: "Software &amp; Systems Modeling",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "software-quality-journal",
    text: "Software Quality Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "softwarex",
    text: "SoftwareX",
    parent: "elsevier-without-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soil-and-tillage-research",
    text: "Soil &amp; Tillage Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soil-biology-and-biochemistry",
    text: "Soil Biology and Biochemistry",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/soil-biology-and-biochemistry/0038-0717/guide-for-authors#68000",
    urltwo: "NA"
  },
  {
    id: "soil-dynamics-and-earthquake-engineering",
    text: "Soil Dynamics and Earthquake Engineering",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soil-processes",
    text: "Soil Processes",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "soil-research",
    text: "Soil Research",
    parent: "international-journal-of-wildland-fire",
    urlone: "http://www.publish.csiro.au/nid/87/aid/385.htm#11",
    urltwo: "NA"
  },
  {
    id: "soil-science-and-plant-nutrition",
    text: "Soil Science and Plant Nutrition",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=tssp20&amp;page=instructions#.U6jU9P538jg",
    urltwo: "NA"
  },
  {
    id: "soil-science-society-of-america-journal",
    text: "Soil Science Society of America Journal",
    parent: "asa-cssa-sssa",
    urlone: "https://www.soils.org/publications/authors",
    urltwo: "NA"
  },
  {
    id: "soil",
    text: "SOIL",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soils-and-foundations",
    text: "Soils and Foundations",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soins-aides-soignantes",
    text: "Soins Aides-soignantes",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soins-cadres",
    text: "Soins Cadres",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soins-gerontologie",
    text: "Soins GÃ©rontologie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soins-pediatrie-puericulture",
    text: "Soins PÃ©diatrie/PuÃ©riculture",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soins-psychiatrie",
    text: "Soins Psychiatrie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "soins",
    text: "Soins",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solar-energy-materials-and-solar-cells",
    text: "Solar Energy Materials and Solar Cells",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solar-energy",
    text: "Solar Energy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solent-university-harvard",
    text: "Solent University - Harvard",
    parent: "NA",
    urlone: "https://portal.solent.ac.uk/library/help/factsheets/resources/referencing-harvard.pdf",
    urltwo: "NA"
  },
  {
    id: "solid-earth-sciences",
    text: "Solid Earth Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solid-earth",
    text: "Solid Earth",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solid-state-communications",
    text: "Solid State Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solid-state-electronics-letters",
    text: "Solid State Electronics Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solid-state-electronics",
    text: "Solid State Electronics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solid-state-ionics",
    text: "Solid State Ionics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solid-state-nuclear-magnetic-resonance",
    text: "Solid State Nuclear Magnetic Resonance",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solid-state-sciences",
    text: "Solid State Sciences",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "solutions",
    text: "Solutions",
    parent: "NA",
    urlone: "http://www.thesolutionsjournal.com/node/457",
    urltwo: "NA"
  },
  {
    id: "somnologie-schlafforschung-und-schlafmedizin",
    text: "Somnologie - Schlafforschung und Schlafmedizin (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/neurology/journal/11818?detailsPage=pltci_1380227",
    urltwo: "NA"
  },
  {
    id: "sophia",
    text: "Sophia",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sorbonne-student-law-review",
    text: "Sorbonne Student Law Review - Revue juridique des Ã©tudiants de la Sorbonne",
    parent: "NA",
    urlone: "https://sorbonnestudentlawreview.org/charters/charter_EN",
    urltwo: "https://sorbonnestudentlawreview.org/charters/charter_FR"
  },
  {
    id: "source-code-for-biology-and-medicine",
    text: "Source Code for Biology and Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "south-african-actuarial-journal",
    text: "South African Actuarial Journal",
    parent: "NA",
    urlone: "https://www.ajol.info/index.php/saaj/about/submissions",
    urltwo: "NA"
  },
  {
    id: "south-african-geographical-journal",
    text: "South African Geographical Journal",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSAG20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "south-african-journal-of-animal-science",
    text: "South African Journal of Animal Science",
    parent: "NA",
    urlone: "http://www.ajol.info/index.php/sajas/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "south-african-journal-of-botany",
    text: "South African Journal of Botany",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "south-african-journal-of-chemical-engineering",
    text: "South African Journal of Chemical Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "south-african-journal-of-enology-and-viticulture",
    text: "South African Journal of Enology and Viticulture",
    parent: "NA",
    urlone: "http://www.journals.ac.za/index.php/sajev/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "south-african-journal-of-geology",
    text: "South African Journal of Geology",
    parent: "NA",
    urlone: "http://www.gssa.org.za/?page_id=108",
    urltwo: "NA"
  },
  {
    id: "south-african-medical-journal",
    text: "South African Medical Journal",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.samj.org.za/index.php/samj/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "south-african-theological-seminary",
    text: "South African Theological Seminary",
    parent: "NA",
    urlone: "https://www.sats.edu.za/userfiles/Smith,Howtocitesources.pdf",
    urltwo: "https://www.sats.edu.za/userfiles/Howtocompileabibliography.pdf"
  },
  {
    id: "south-asian-diaspora",
    text: "South Asian Diaspora",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSAD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "south-asian-history-and-culture",
    text: "South Asian History and Culture",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSAC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "southeastern-geographer",
    text: "Southeastern Geographer",
    parent: "NA",
    urlone: "https://sedaag.org/southeastern-geographer/",
    urltwo: "NA"
  },
  {
    id: "southern-african-journal-of-critical-care",
    text: "Southern African Journal of Critical Care",
    parent: "NA",
    urlone: "http://www.sajcc.org.za/index.php/SAJCC/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "southern-cross-university-harvard",
    text: "Southern Cross University - Harvard",
    parent: "style-manual-australian-government",
    urlone: "http://libguides.scu.edu.au/harvard",
    urltwo: "NA"
  },
  {
    id: "soziale-passagen",
    text: "Soziale Passagen (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "soziale-probleme",
    text: "Soziale Probleme (German)",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "soziale-welt",
    text: "Soziale Welt (German)",
    parent: "NA",
    urlone: "http://www.soziale-welt.nomos.de/fileadmin/soziale-welt/doc/Autorenhinweise_sw.pdf",
    urltwo: "NA"
  },
  {
    id: "sozialpadagogisches-institut-berlin-walter-may",
    text: "SozialpÃ¤dagogisches Institut Berlin - Walter May (German)",
    parent: "NA",
    urlone: "http://www.stiftung-spi.de",
    urltwo: "NA"
  },
  {
    id: "sozialwissenschaften-heilmann",
    text: "Sozialwissenschaften (Heilmann) (German)",
    parent: "NA",
    urlone: "http://www.tillheilmann.info/english.php",
    urltwo: "NA"
  },
  {
    id: "soziologie",
    text: "Soziologie (German)",
    parent: "NA",
    urlone: "http://www.uni-leipzig.de/~sozio/content/site/redsoz_hinweise.php",
    urltwo: "NA"
  },
  {
    id: "soziologiemagazin",
    text: "Soziologiemagazin (German)",
    parent: "NA",
    urlone: "https://soziologieblog.hypotheses.org/hinweise-fur-autor_innen",
    urltwo: "NA"
  },
  {
    id: "space-policy",
    text: "Space Policy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "space-science-reviews",
    text: "Space Science Reviews",
    parent: "springer-physics-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "space-weather",
    text: "Space Weather",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "spandidos-publications",
    text: "Spandidos Publications",
    parent: "NA",
    urlone: "http://www.spandidos-publications.com/pages/info_for_authors",
    urltwo: "NA"
  },
  {
    id: "spanish-journal-of-marketing-esic",
    text: "Spanish Journal of Marketing - ESIC",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "spanish-legal",
    text: "Spanish Legal (Spanish)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "spatial-and-spatio-temporal-epidemiology",
    text: "Spatial and Spatio-temporal Epidemiology",
    parent: "elsevier-vancouver-author-date",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "spatial-demography",
    text: "Spatial Demography",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "spatial-statistics",
    text: "Spatial Statistics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "special-care-in-dentistry",
    text: "Special Care in Dentistry",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291754-4505/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "special-topics-and-reviews-in-porous-media-an-international-journal",
    text: "Special Topics &amp; Reviews in Porous Media: An International Journal",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/3d21681c18f5b5e7.html",
    urltwo: "NA"
  },
  {
    id: "spectrochimica-acta-part-a-molecular-and-biomolecular-spectroscopy",
    text: "Spectrochimica Acta Part A: Molecular and Biomolecular Spectroscopy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "spectrochimica-acta-part-b-atomic-spectroscopy",
    text: "Spectrochimica Acta Part B: Atomic Spectroscopy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "spectroscopy-letters",
    text: "Spectroscopy Letters",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=lstl20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "speculum",
    text: "Speculum",
    parent: "NA",
    urlone: "http://www.journals.uchicago.edu/journals/spc/style-sheet",
    urltwo: "NA"
  },
  {
    id: "speech-communication",
    text: "Speech Communication",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "spektrum-der-augenheilkunde",
    text: "Spektrum der Augenheilkunde (German)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "spermatogenesis",
    text: "Spermatogenesis",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "spie-bios",
    text: "SPIE BiOS",
    parent: "NA",
    urlone: "http://spie.org/x14101.xml",
    urltwo: "NA"
  },
  {
    id: "spie-journals",
    text: "SPIE journals",
    parent: "NA",
    urlone: "http://spie.org/x85036.xml",
    urltwo: "NA"
  },
  {
    id: "spie-proceedings",
    text: "SPIE Conference Proceedings",
    parent: "NA",
    urlone:
      "http://spie.org/conferences-and-exhibitions/authors-and-presenters/format-your-manuscript-multimedia-files-and-references#Specifications",
    urltwo: "NA"
  },
  {
    id: "spinal-cord",
    text: "Spinal Cord",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://mts-sc.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "spine-deformity",
    text: "Spine Deformity",
    parent: "springer-basic-brackets-no-et-al",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "spine",
    text: "Spine",
    parent: "NA",
    urlone: "http://journals.lww.com/spinejournal/_layouts/15/1033/oaks.journals/informationforauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "spip-cite",
    text: "SPIP - Cite plugin",
    parent: "NA",
    urlone: "http://www.spip-contrib.net/spip.php?article3698",
    urltwo: "NA"
  },
  {
    id: "spirituality-in-clinical-practice",
    text: "Spirituality in Clinical Practice",
    parent: "apa",
    urlone: "http://content.apa.org/journals/scp",
    urltwo: "NA"
  },
  {
    id: "sport-education-and-society",
    text: "Sport, Education and Society",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSES20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "sport-exercise-and-performance-psychology",
    text: "Sport, Exercise, and Performance Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/spy",
    urltwo: "NA"
  },
  {
    id: "sport-management-review",
    text: "Sport Management Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sport-sciences-for-health",
    text: "Sport Sciences for Health",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sports-biomechanics",
    text: "Sports Biomechanics",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSPB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "sports-coaching-review",
    text: "Sports Coaching Review",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSPC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "sports-engineering",
    text: "Sports Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sports-medicine-international-open",
    text: "Sports Medicine International open",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "sports-medicine-open",
    text: "Sports Medicine - Open",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sports-medicine",
    text: "Sports Medicine",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sports-technology",
    text: "Sports Technology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RTEC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "sports",
    text: "Sports",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "sportverletzung-sportschaden",
    text: "Sportverletzung - Sportschaden",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "sportwissenschaft",
    text: "Sportwissenschaft (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sprache-stimme-gehor",
    text: "Sprache - Stimme - GehÃ¶r",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "springer-basic-author-date-no-et-al-with-issue",
    text: 'Springer - Basic (author-date, no "et al.", with issue numbers)',
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/instruct-authors-e.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "springer-basic-author-date-no-et-al",
    text: 'Springer - Basic (author-date, no "et al.")',
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/instruct-authors-e.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "springer-basic-author-date",
    text: "Springer - Basic (author-date)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/instruct-authors-e.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "springer-basic-brackets-no-et-al-alphabetical",
    text: 'Springer - Basic (numeric, brackets, no "et al.", alphabetical)',
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/instruct-authors-e.pdf",
    urltwo: "NA"
  },
  {
    id: "springer-basic-brackets-no-et-al",
    text: 'Springer - Basic (numeric, brackets, no "et al.")',
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/instruct-authors-e.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "springer-basic-brackets",
    text: "Springer - Basic (numeric, brackets)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/instruct-authors-e.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "springer-basic-note",
    text: "Springer - Basic (note)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "https://www.ligue.org/uploads/documents/Cycle%202017/Springer%20Instructions%20for%20Authors_%20Law.pdf"
  },
  {
    id: "springer-fachzeitschriften-medizin-psychologie",
    text: "Springer - Fachzeitschriften Medizin Psychologie (German)",
    parent: "NA",
    urlone:
      "http://static.springer.com/sgw/documents/135255/application/pdf/Musterbeitrag_Uebersichten_Leitthema_Schwerpunkt.pdf",
    urltwo:
      "http://static.springer.com/sgw/documents/69331/application/pdf/Leitfaden_Uebersichten_Leitthemen_Schwerpunkt.pdf"
  },
  {
    id: "springer-humanities-author-date",
    text: "Springer - Humanities (author-date)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "NA"
  },
  {
    id: "springer-humanities-brackets",
    text: "Springer - Humanities (numeric, brackets)",
    parent: "NA",
    urlone: "www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_Aug2012.pdf",
    urltwo: "NA"
  },
  {
    id: "springer-imis-series-migrationsgesellschaften",
    text: "Springer - IMIS Series Migrationsgesellschaften",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "springer-lecture-notes-in-computer-science-alphabetical",
    text: "Springer - Lecture Notes in Computer Science (sorted alphabetically)",
    parent: "NA",
    urlone: "http://www.springer.com/computer/lncs?SGWID=0-164-6-793341-0",
    urltwo: "NA"
  },
  {
    id: "springer-lecture-notes-in-computer-science",
    text: "Springer - Lecture Notes in Computer Science",
    parent: "NA",
    urlone: "http://www.springer.com/computer/lncs?SGWID=0-164-6-793341-0",
    urltwo: "NA"
  },
  {
    id: "springer-mathphys-author-date",
    text: "Springer - MathPhys (author-date)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf"
  },
  {
    id: "springer-mathphys-brackets",
    text: "Springer - MathPhys (numeric, brackets)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf"
  },
  {
    id: "springer-physics-author-date",
    text: "Springer - Physics (author-date)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf"
  },
  {
    id: "springer-physics-brackets",
    text: "Springer - Physics (numeric, brackets)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf"
  },
  {
    id: "springer-science-reviews",
    text: "Springer Science Reviews",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "springer-socpsych-author-date",
    text: "Springer - SocPsych (author-date)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/instruct-authors-e.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "springer-socpsych-brackets",
    text: "Springer - SocPsych (numeric, brackets)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/instruct-authors-e.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "springer-vancouver-author-date",
    text: "Springer - Vancouver (author-date)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf"
  },
  {
    id: "springer-vancouver-brackets",
    text: "Springer - Vancouver (brackets)",
    parent: "NA",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf"
  },
  {
    id: "springer-vancouver",
    text: "Springer - Vancouver",
    parent: "NA",
    urlone: "http://www.springer.com/medicine/psychiatry/journal/127",
    urltwo: "NA"
  },
  {
    id: "springer-vs-author-date",
    text: "Springer VS (author-date, German)",
    parent: "NA",
    urlone:
      "https://github.com/citation-style-language/styles/files/978822/Hinweise.zur.Manuskripterstellung_final.pdf",
    urltwo: "NA"
  },
  {
    id: "springerplus",
    text: "SpringerPlus",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "springerprotocols",
    text: "SpringerProtocols",
    parent: "NA",
    urlone: "http://www.springerprotocols.com/cdp/view/contributors#ref",
    urltwo: "NA"
  },
  {
    id: "ssm-population-health",
    text: "SSM - Population Health",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "st-patricks-college",
    text: "St Patrick's College",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/370",
    urltwo: "http://www.st-patricks.ac.uk/"
  },
  {
    id: "st-petersburg-polytechnical-university-journal-physics-and-mathematics",
    text: "St. Petersburg Polytechnical University Journal: Physics and Mathematics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "standards-in-genomic-sciences",
    text: "Standards in Genomic Sciences",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "standort",
    text: "Standort (German)",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "statistical-inference-for-stochastic-processes",
    text: "Statistical Inference for Stochastic Processes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "statistical-methods-and-applications",
    text: "Statistical Methods &amp; Applications",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "statistical-papers",
    text: "Statistical Papers",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "statistical-science",
    text: "Statistical Science",
    parent: "institute-of-mathematical-statistics",
    urlone: "https://imstat.org/journals-and-publications/statistical-science/",
    urltwo: "NA"
  },
  {
    id: "statistics-and-computing",
    text: "Statistics and Computing",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "statistics-and-probability-letters",
    text: "Statistics and Probability Letters",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "statistics-in-biopharmaceutical-research",
    text: "Statistics in Biopharmaceutical Research",
    parent: "american-statistical-association",
    urlone: "http://amstat.tfjournals.com/asa-style-guide/",
    urltwo: "NA"
  },
  {
    id: "statistics-in-biosciences",
    text: "Statistics in Biosciences",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "statistics",
    text: "Statistics",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GSTA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "statistika-statistics-and-economy-journal",
    text: "Statistika: Statistics and Economy Journal",
    parent: "NA",
    urlone: "https://www.czso.cz/csu/czso/guide_for_authors",
    urltwo: "NA"
  },
  {
    id: "stavebni-obzor",
    text: "StavebnÃ­ obzor (Czech)",
    parent: "NA",
    urlone: "http://www.stavebniobzor.cz/redakce-casopisu/pokyny-pro-autory/",
    urltwo: "NA"
  },
  {
    id: "steinbeis-hochschule-school-of-management-and-innovation",
    text: "Steinbeis-Hochschule - School of Management &amp; Innovation (German)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "stem-cell-reports",
    text: "Stem Cell Reports",
    parent: "cell",
    urlone: "http://www.cell.com/stem-cell-reports/authors",
    urltwo: "NA"
  },
  {
    id: "stem-cell-research-and-therapy",
    text: "Stem Cell Research &amp; Therapy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "stem-cell-research",
    text: "Stem Cell Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "stem-cell-reviews-and-reports",
    text: "Stem Cell Reviews and Reports",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "stem-cells-and-development",
    text: "Stem Cells and Development",
    parent: "NA",
    urlone: "http://www.liebertpub.com/manuscript/scd",
    urltwo: "NA"
  },
  {
    id: "stem-cells-translational-medicine",
    text: "Stem Cells Translational Medicine",
    parent: "stem-cells",
    urlone: "http://www.stemcellsportal.com/sctm-info-for-contributors#_Toc372810938",
    urltwo: "NA"
  },
  {
    id: "stem-cells",
    text: "Stem Cells",
    parent: "NA",
    urlone: "http://www.stemcellsportal.com/STEM_CELLS_Info_for_Contributors#_Toc372810938",
    urltwo: "NA"
  },
  {
    id: "stereotactic-and-functional-neurosurgery",
    text: "Stereotactic and Functional Neurosurgery",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0372",
    urltwo: "NA"
  },
  {
    id: "steroids",
    text: "Steroids",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "stigma-and-health",
    text: "Stigma and Health",
    parent: "apa",
    urlone: "http://content.apa.org/journals/sah",
    urltwo: "NA"
  },
  {
    id: "stochastic-environmental-research-and-risk-assessment",
    text: "Stochastic Environmental Research and Risk Assessment",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "stochastic-partial-differential-equations-analysis-and-computations",
    text: "Stochastic Partial Differential Equations: Analysis and Computations",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "stomatologie",
    text: "Stomatologie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/dentistry/journal/715?detailsPage=pltci_2412168",
    urltwo: "NA"
  },
  {
    id: "stomatoloski-vjesnik",
    text: "StomatoloÅ¡ki vjesnik (Stomatological Review)",
    parent: "vancouver",
    urlone: "http://stomatoloskivjesnik.ba/eng/?page_id=37",
    urltwo: "NA"
  },
  {
    id: "strahlentherapie-und-onkologie",
    text: "Strahlentherapie und Onkologie",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "strategic-design-research-journal",
    text: "Strategic Design Research Journal",
    parent: "NA",
    urlone: "http://revistas.unisinos.br/index.php/sdrj/about/submissions#onlineSubmissions",
    urltwo: "NA"
  },
  {
    id: "strategic-entrepreneurship-journal",
    text: "Strategic Entrepreneurship Journal",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/1932443x/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "strategic-management-journal",
    text: "Strategic Management Journal",
    parent: "NA",
    urlone: "http://smj.strategicmanagement.net/submission_guidlines.php",
    urltwo: "NA"
  },
  {
    id: "strategic-organization",
    text: "Strategic Organization",
    parent: "sage-harvard",
    urlone: "https://us.sagepub.com/en-us/nam/journal/strategic-organization#JournalStyle",
    urltwo: "NA"
  },
  {
    id: "strategies-in-trauma-and-limb-reconstruction",
    text: "Strategies in Trauma and Limb Reconstruction",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "strategy-science",
    text: "Strategy Science",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/stsc/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "stroke",
    text: "Stroke",
    parent: "NA",
    urlone: "https://www.ahajournals.org/str/manuscript-preparation",
    urltwo: "NA"
  },
  {
    id: "structural-and-multidisciplinary-optimization",
    text: "Structural and Multidisciplinary Optimization",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "structural-change-and-economic-dynamics",
    text: "Structural Change and Economic Dynamics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "structural-chemistry",
    text: "Structural Chemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "structural-control-and-health-monitoring",
    text: "Structural Control and Health Monitoring",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1545-2263/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "structural-safety",
    text: "Structural Safety",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "structure",
    text: "Structure",
    parent: "cell",
    urlone: "http://www.cell.com/structure/authors",
    urltwo: "NA"
  },
  {
    id: "structures",
    text: "Structures",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "student-bmj",
    text: "Student BMJ",
    parent: "bmj",
    urlone: "http://student.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "studi-e-materiali-di-storia-delle-religioni",
    text: "Studi e materiali di storia delle religioni (Italian)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/4642",
    urltwo: "NA"
  },
  {
    id: "studia-bas",
    text: "Studia BAS (Polish)",
    parent: "NA",
    urlone: "http://orka.sejm.gov.pl/WydBAS.nsf/PDF/Studia-wsk/$File/StudiaBAS_wskazowki_dla_autorow.pdf",
    urltwo: "NA"
  },
  {
    id: "studia-theologica-nordic-journal-of-theology",
    text: "Studia Theologica - Nordic Journal of Theology",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=STHE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "studies-in-communication-sciences",
    text: "Studies in Communication Sciences",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "studies-in-comparative-international-development",
    text: "Studies in Comparative International Development",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "studies-in-continuing-education",
    text: "Studies in Continuing Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSCE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "studies-in-east-european-thought",
    text: "Studies in East European Thought",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "studies-in-educational-evaluation",
    text: "Studies in Educational Evaluation",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "studies-in-history-and-philosophy-of-biol-and-biomed-sci",
    text: "Studies in History and Philosophy of Biol &amp; Biomed Sci",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "studies-in-history-and-philosophy-of-modern-physics",
    text: "Studies in History and Philosophy of Modern Physics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "studies-in-history-and-philosophy-of-science",
    text: "Studies in History and Philosophy of Science",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "studies-in-philosophy-and-education",
    text: "Studies in Philosophy and Education",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "studies-in-science-education",
    text: "Studies in Science Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSSE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "studii-teologice",
    text: "Studii Teologice",
    parent: "NA",
    urlone: "http://www.studiiteologice.ro/index.php?option=com_content&amp;view=article&amp;id=24",
    urltwo: "NA"
  },
  {
    id: "studying-teacher-education",
    text: "Studying Teacher Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CSTE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "stuttgart-media-university",
    text: "Hochschule der Medien Stuttgart (German)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/issues/175",
    urltwo: "http://www.hdm-stuttgart.de/"
  },
  {
    id: "style-manual-australian-government-note",
    text: "Style Manual - Australian Government (note)",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?journalCode=raam20&amp;page=instructions#prep",
    urltwo: "NA"
  },
  {
    id: "style-manual-australian-government",
    text: "Style Manual - Australian Government (author-date)",
    parent: "NA",
    urlone: "http://www.australia.gov.au/publications/style-manual",
    urltwo: "NA"
  },
  {
    id: "substance-abuse-treatment-prevention-and-policy",
    text: "Substance Abuse Treatment, Prevention, and Policy",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "subterranean-biology",
    text: "Subterranean Biology",
    parent: "pensoft-journals",
    urlone: "https://subtbiol.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "suburban-zeitschrift-fur-kritische-stadtforschung",
    text: "sub\\urban - Zeitschrift fÃ¼r kritische Stadtforschung (German)",
    parent: "NA",
    urlone: "https://zeitschrift-suburban.de/sys/index.php/suburban/about/submissions",
    urltwo: "https://zeitschrift-suburban.de/sys/files/docs/Autor_innenhinweise2015.pdf"
  },
  {
    id: "suchttherapie",
    text: "Suchttherapie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "sugar-tech",
    text: "Sugar Tech",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "suma-de-negocios",
    text: "Suma de Negocios",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "suma-psicologica",
    text: "Suma PsicolÃ³gica",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sunway-college-johor-bahru",
    text: "Suma PsicolÃ³gica",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "suomen-laakarilehti",
    text: "Suomen LÃ¤Ã¤kÃ¤rilehti (Finnish Medical Journal) (Finnish)",
    parent: "vancouver",
    urlone: "http://www.laakarilehti.fi/kirjoittaja/instructions_to_authors_2010.html#9",
    urltwo: "NA"
  },
  {
    id: "superconductor-science-and-technology",
    text: "Superconductor Science and Technology",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/0953-2048",
    urltwo: "NA"
  },
  {
    id: "superlattices-and-microstructures",
    text: "Superlattices and Microstructures",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "supportive-care-in-cancer",
    text: "Supportive Care in Cancer",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "surface-and-coatings-technology",
    text: "Surface &amp; Coatings Technology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "surface-science-reports",
    text: "Surface Science Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "surface-science",
    text: "Surface Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "surface-topography-metrology-and-properties",
    text: "Surface Topography: Metrology and Properties",
    parent: "institute-of-physics-numeric",
    urlone: "http://iopscience.iop.org/2051-672X",
    urltwo: "NA"
  },
  {
    id: "surfaces-and-interfaces",
    text: "Surfaces and Interfaces",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "surgery-for-obesity-and-related-diseases",
    text: "Surgery for Obesity and Related Diseases",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "surgery-open-science",
    text: "Surgery Open Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "surgery-today",
    text: "Surgery Today",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "surgery",
    text: "Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "surgical-and-radiologic-anatomy",
    text: "Surgical and Radiologic Anatomy",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "surgical-case-reports",
    text: "Surgical Case Reports",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "surgical-clinics-of-north-america",
    text: "Surgical Clinics of North America",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/surgical-clinics-of-north-america/0039-6109/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "surgical-endoscopy",
    text: "Surgical Endoscopy",
    parent: "springer-basic-brackets-no-et-al",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "surgical-neurology-international",
    text: "Surgical Neurology International",
    parent: "NA",
    urlone: "https://surgicalneurologyint.com/submit-article/",
    urltwo: "http://surgicalneurologyint.com/wp-content/uploads/2018/12/Author_Instructions.pdf"
  },
  {
    id: "surgical-oncology",
    text: "Surgical Oncology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "surgical-pathology-clinics",
    text: "Surgical Pathology Clinics",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/surgical-pathology-clinics/1875-9181/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "survey-of-ophthalmology",
    text: "Survey of Ophthalmology",
    parent: "american-medical-association-alphabetical",
    urlone: "http://www.elsevier.com/journals/survey-of-ophthalmology/0039-6257/guide-for-authors#inst",
    urltwo: "NA"
  },
  {
    id: "surveys-in-geophysics",
    text: "Surveys in Geophysics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "surveys-in-operations-research-and-management-science",
    text: "Surveys in Operations Research and Management Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainability-of-water-quality-and-ecology",
    text: "Sustainability of Water Quality and Ecology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainability-science",
    text: "Sustainability Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sustainability",
    text: "Sustainability",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "sustainable-chemical-processes",
    text: "Sustainable Chemical Processes",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "sustainable-chemistry-and-pharmacy",
    text: "Sustainable Chemistry and Pharmacy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainable-cities-and-society",
    text: "Sustainable Cities and Society",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainable-computing-informatics-and-systems",
    text: "Sustainable Computing: Informatics and Systems",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainable-energy-and-fuels",
    text: "Sustainable Energy &amp; Fuels",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainable-energy-grids-and-networks",
    text: "Sustainable Energy, Grids and Networks",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainable-energy-technologies-and-assessments",
    text: "Sustainable Energy Technologies and Assessments",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainable-environment-research",
    text: "Sustainable Environment Research",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainable-materials-and-technologies",
    text: "Sustainable Materials and Technologies",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainable-production-and-consumption",
    text: "Sustainable Production and Consumption",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "sustainable-water-resources-management",
    text: "Sustainable Water Resources Management",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "svensk-exegetisk-arsbok",
    text: "Svensk exegetisk Ã¥rsbok (full note)",
    parent: "NA",
    urlone: "http://exegetiskasallskapet.se/SEA/anvisningar.html",
    urltwo: "NA"
  },
  {
    id: "swarm-and-evolutionary-computation",
    text: "Swarm and Evolutionary Computation",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "swarm-intelligence",
    text: "Swarm Intelligence",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "swedish-legal",
    text: "Swedish Legal (Swedish)",
    parent: "NA",
    urlone: "http://libris.kb.se/bib/10057506",
    urltwo: "NA"
  },
  {
    id: "swiss-journal-of-geosciences",
    text: "Swiss Journal of Geosciences",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "swiss-journal-of-palaeontology",
    text: "Swiss Journal of Palaeontology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "swiss-journal-of-psychology",
    text: "Swiss Journal of Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/sjp",
    urltwo: "NA"
  },
  {
    id: "swiss-medical-weekly",
    text: "Swiss Medical Weekly",
    parent: "vancouver-brackets",
    urlone: "http://www.smw.ch/for-authors/information-for-authors/preparation-of-manuscript/",
    urltwo: "NA"
  },
  {
    id: "swiss-political-science-review",
    text: "Swiss Political Science Review",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/16626370/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "sylwan",
    text: "Sylwan (Polish)",
    parent: "NA",
    urlone: "https://sylwan.lasy.gov.pl/apex/f?p=105:6",
    urltwo: "NA"
  },
  {
    id: "symbiosis",
    text: "Symbiosis",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "symmetry",
    text: "Symmetry",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "synergy",
    text: "Synergy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "synlett",
    text: "Synlett",
    parent: "synthesis",
    urlone: "https://www.thieme.de/de/synlett/author-guidelines-58892.htm",
    urltwo: "NA"
  },
  {
    id: "synthese",
    text: "Synthese",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "synthesis",
    text: "Synthesis",
    parent: "NA",
    urlone: "https://www.thieme.de/de/synthesis/author-guidelines-58874.htm",
    urltwo: "NA"
  },
  {
    id: "synthetic-and-systems-biotechnology",
    text: "Synthetic and Systems Biotechnology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "synthetic-metals",
    text: "Synthetic Metals",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "system",
    text: "System",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "systematic-and-applied-microbiology",
    text: "Systematic and Applied Microbiology",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/systematic-and-applied-microbiology/0723-2020/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "systematic-biology",
    text: "Systematic Biology",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/sysbio/for_authors/",
    urltwo: "NA"
  },
  {
    id: "systematic-parasitology",
    text: "Systematic Parasitology",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "systematic-reviews",
    text: "Systematic Reviews",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "systemic-practice-and-action-research",
    text: "Systemic Practice and Action Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "systems-and-control-letters",
    text: "Systems &amp; Control Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "systems-and-synthetic-biology",
    text: "Systems and Synthetic Biology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "systems-biomedicine",
    text: "Systems Biomedicine",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "systems-science-and-control-engineering-an-open-access-journal",
    text: "Systems Science &amp; Control Engineering: An Open Access Journal",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TSSC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "systems",
    text: "Systems",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "tabula",
    text: "TÃ¡bula (Spanish)",
    parent: "NA",
    urlone: "http://www.acal.es/index.php/publica-con-nosotros",
    urltwo: "NA"
  },
  {
    id: "tagungsberichte-der-historischen-kommission-fur-ost-und-westpreussische-landesforschung",
    text: "Tagungsberichte der Historischen Kommission fÃ¼r ost- und westpreuÃŸische Landesforschung (German)",
    parent: "NA",
    urlone: "http://www.hiko-owp.eu/veroeffentlichungen/",
    urltwo: "NA"
  },
  {
    id: "taiwan-journal-of-ophthalmology",
    text: "Taiwan Journal of Ophthalmology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "taiwanese-journal-of-obstetrics-and-gynecology",
    text: "Taiwanese Journal of Obstetrics &amp; Gynecology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "talanta",
    text: "Talanta",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tandlaegebladet",
    text: "TandlÃ¦gebladet (Danish Dental Journal)",
    parent: "vancouver",
    urlone: "http://tandlaegebladet.dk/",
    urltwo: "NA"
  },
  {
    id: "tanta-dental-journal",
    text: "Tanta Dental Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "targeted-oncology",
    text: "Targeted Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tatup-zeitschrift-fur-technikfolgenabschatzung-in-theorie-und-praxis",
    text: "TATuP - Zeitschrift fÃ¼r TechnikfolgenabschÃ¤tzung in Theorie und Praxis",
    parent: "NA",
    urlone: "http://www.tatup.de/index.php/tatup/about/submissions",
    urltwo: "http://www.tatup.de/downloads/TATuP_Zitationsstil_citation_style.pdf"
  },
  {
    id: "taxon",
    text: "Taxon",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/19968175/homepage/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "taylor-and-francis-acs",
    text: "Taylor &amp; Francis - American Chemical Society",
    parent: "NA",
    urlone: "https://www.tandf.co.uk/journals/authors/style/reference/tf_USACS.pdf",
    urltwo: "https://www.tandfonline.com/action/authorSubmission?journalCode=ldrt20&amp;page=instructions#style"
  },
  {
    id: "taylor-and-francis-apa",
    text: "Taylor &amp; Francis - APA",
    parent: "apa",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/tf_APA.pdf",
    urltwo: "NA"
  },
  {
    id: "taylor-and-francis-chicago-author-date",
    text: "Taylor &amp; Francis - Chicago Manual of Style (author-date)",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/tf_ChicagoAD.pdf",
    urltwo: "NA"
  },
  {
    id: "taylor-and-francis-chicago-f",
    text: "Taylor &amp; Francis - Chicago F",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/tf_F.pdf",
    urltwo: "NA"
  },
  {
    id: "taylor-and-francis-chicago-note",
    text: "Taylor &amp; Francis - Chicago Manual of Style (note)",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/tf_ChicagoEB.pdf",
    urltwo: "NA"
  },
  {
    id: "taylor-and-francis-council-of-science-editors-author-date",
    text: "Taylor &amp; Francis - Council of Science Editors (author-date)",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/tf_CSE.pdf",
    urltwo: "NA"
  },
  {
    id: "taylor-and-francis-harvard-v",
    text: "Taylor &amp; Francis - Harvard V",
    parent: "taylor-and-francis-harvard-x",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/tf_V.pdf",
    urltwo: "NA"
  },
  {
    id: "taylor-and-francis-harvard-x",
    text: "Taylor &amp; Francis - Harvard X",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/tf_X.pdf",
    urltwo: "NA"
  },
  {
    id: "taylor-and-francis-national-library-of-medicine",
    text: "Taylor &amp; Francis - National Library of Medicine",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/tf_NLM.pdf",
    urltwo: "NA"
  },
  {
    id: "taylor-and-francis-numeric-q",
    text: "Taylor &amp; Francis - Numeric Q",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/ref_gsar.pdf",
    urltwo: "NA"
  },
  {
    id: "teacher-development",
    text: "Teacher Development",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RTDE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "teachers-and-teaching",
    text: "Teachers and Teaching",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CTAT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "teaching-and-learning-in-nursing",
    text: "Teaching and Learning in Nursing",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "teaching-and-teacher-education",
    text: "Teaching and Teacher Education",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "teaching-education",
    text: "Teaching Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CTED20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "teaching-in-higher-education",
    text: "Teaching in Higher Education",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=CTHE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "teaching-mathematics-and-its-applications",
    text: "Teaching Mathematics and its Applications",
    parent: "institute-of-mathematics-and-its-applications",
    urlone: "https://academic.oup.com/teamat/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "teaching-sociology",
    text: "Teaching Sociology",
    parent: "american-sociological-association",
    urlone: "http://www.asanet.org/journals/ts/index.cfm",
    urltwo: "NA"
  },
  {
    id: "technical-innovations-and-patient-support-in-radiation-oncology",
    text: "Technical Innovations &amp; Patient Support in Radiation Oncology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "techniques-et-culture",
    text: "Techniques&amp;Culture (French)",
    parent: "NA",
    urlone: "https://journals.openedition.org/tc/1556#tocto3n5",
    urltwo: "NA"
  },
  {
    id: "techniques-in-coloproctology",
    text: "Techniques in Coloproctology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "techniques-in-gastrointestinal-endoscopy",
    text: "Techniques in Gastrointestinal Endoscopy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "techniques-in-regional-anesthesia-and-pain-management",
    text: "Techniques in Regional Anesthesia and Pain Management",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dortmund-ag-virtual-machining",
    text: "Technische UniversitÃ¤t Dortmund - AG Virtual Machining (German)",
    parent: "NA",
    urlone: "https://ls14-www.cs.tu-dortmund.de/",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-betriebswirtschaftslehre-logistik-author-date",
    text: "Technische UniversitÃ¤t Dresden - Betriebswirtschaftslehre/Logistik (author-date)",
    parent: "NA",
    urlone:
      "https://tu-dresden.de/bu/wirtschaft/bwllog/ressourcen/dateien/studium/studienarbeiten/dateien_studienarbeiten/20180619_Homepage_Hinweise-zu-Zitierungen.pdf?lang=de",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-betriebswirtschaftslehre-marketing",
    text: "Technische UniversitÃ¤t Dresden - Betriebswirtschaftslehre/Marketing (author-date)",
    parent: "NA",
    urlone:
      "https://tu-dresden.de/bu/wirtschaft/marketing/ressourcen/dateien/lehre/lehre_pdfs/Leitfaden-Abschlussarbeiten-Juni_2017.pdf?lang=de",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-betriebswirtschaftslehre-rechnungswesen-controlling",
    text: "Technische UniversitÃ¤t Dresden - Betriebswirtschaftslehre/Rechnungswesen/Controlling (German)",
    parent: "NA",
    urlone:
      "https://tu-dresden.de/bu/wirtschaft/bwl/brw/ressourcen/dateien/studium/Richtlinien-wiss-Arbeiten-Stand-Januar-2020.pdf?lang=de",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-erziehungswissenschaften-author-date",
    text: "Technische UniversitÃ¤t Dresden - Erziehungswissenschaften (author-date)",
    parent: "NA",
    urlone: "https://tu-dresden.de/gsw/ew/ressourcen/dateien/studium/pdf/broschuere_booklet.pdf?lang=de",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-finanzwirtschaft-und-finanzdienstleistungen-author-date-with-short-titles",
    text:
      "Technische UniversitÃ¤t Dresden - Finanzwirtschaft und Finanzdienstleistungen (author-date, with short titles)",
    parent: "NA",
    urlone: "https://bildungsportal.sachsen.de/opal/url/RepositoryEntry/683835403",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-finanzwirtschaft-und-finanzdienstleistungen-author-date",
    text: "Technische UniversitÃ¤t Dresden - Finanzwirtschaft und Finanzdienstleistungen (author-date)",
    parent: "NA",
    urlone: "https://bildungsportal.sachsen.de/opal/url/RepositoryEntry/683835403",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-finanzwirtschaft-und-finanzdienstleistungen-note",
    text: "Technische UniversitÃ¤t Dresden - Finanzwirtschaft und Finanzdienstleistungen (note)",
    parent: "NA",
    urlone: "https://bildungsportal.sachsen.de/opal/url/RepositoryEntry/683835403",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-forstwissenschaft",
    text: "Technische UniversitÃ¤t Dresden - Forstwissenschaft (author-date, German)",
    parent: "NA",
    urlone:
      "https://github.com/citation-style-language/styles/files/3089047/Aufbau.wiss.Arbeit-.Grundzuge.Prof.Waldschutz.Waldschutz.Waldschutz.pdf",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-historische-musikwissenschaft-note",
    text: "Technische UniversitÃ¤t Dresden - Historische Musikwissenschaft (note, German)",
    parent: "NA",
    urlone:
      "https://bildungsportal.sachsen.de/opal/auth/RepositoryEntry/7960428545/CourseNode/92427926399928/Merkblatt_Literaturangaben_historische_Musikwissenschaft.pdf",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-kunstgeschichte-note",
    text: "Technische UniversitÃ¤t Dresden - Kunstgeschichte (note, German)",
    parent: "NA",
    urlone: "https://tu-dresden.de/gsw/phil/ikm/kuge/ressourcen/dateien/studium-1/richtlinien_wiss_arbeiten?lang=de",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-linguistik",
    text: "Technische UniversitÃ¤t Dresden - Linguistik (German)",
    parent: "NA",
    urlone: "de",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-medienwissenschaft-und-neuere-deutsche-literatur-note",
    text: "Technische UniversitÃ¤t Dresden - Medienwissenschaft und Neuere Deutsche Literatur (note, German)",
    parent: "NA",
    urlone: "https://tu-dresden.de/gsw/slk/germanistik/mwndl/ressourcen/dateien/studium/leitfaden_hausarbeiten?lang=de",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-medizin",
    text: "Technische UniversitÃ¤t Dresden - Medizin",
    parent: "NA",
    urlone:
      "https://tu-dresden.de/med/mf/ressourcen/dateien/postgraduales/promotionen/zitierrichtinien_fuer_die_dissertation_formblatt9.0?lang=de",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-dresden-wirtschaftswissenschaften",
    text: "Technische UniversitÃ¤t Dresden - Wirtschaftswissenschaften (German)",
    parent: "NA",
    urlone:
      "https://secure-redaktion.tu-dresden.de/die_tu_dresden/fakultaeten/fakultaet_wirtschaftswissenschaften/bwl/lei/dateien/Leitfaden-Abschlussarbeiten_13-01-13.pdf",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-munchen-controlling",
    text: "Technische UniversitÃ¤t MÃ¼nchen - Controlling (German)",
    parent: "NA",
    urlone: "http://www.controlling.wi.tum.de/lehre/abschlussarbeiten/",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-munchen-unternehmensfuhrung",
    text: "Technische UniversitÃ¤t MÃ¼nchen - UnternehmensfÃ¼hrung (German)",
    parent: "NA",
    urlone: "http://www.uf.wi.tum.de/lehre/abschlussarbeiten/",
    urltwo: "NA"
  },
  {
    id: "technische-universitat-wien",
    text: "Technische UniversitÃ¤t Wien (dissertation) (German)",
    parent: "NA",
    urlone: "http://www.tuwien.ac.at/en/",
    urltwo: "NA"
  },
  {
    id: "technobiology",
    text: "Technobiology",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/4a34054d5c03bc20.html",
    urltwo: "NA"
  },
  {
    id: "technological-forecasting-and-social-change",
    text: "Technological Forecasting &amp; Social Change",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "technologies",
    text: "Technologies",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "technology-and-economics-of-smart-grids-and-sustainable-energy",
    text: "Technology and Economics of Smart Grids and Sustainable Energy",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "technology-in-society",
    text: "Technology in Society",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "technology-innovation-and-education",
    text: "Technology, Innovation and Education",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "technology-knowledge-and-learning",
    text: "Technology, Knowledge and Learning",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "technology-pedagogy-and-education",
    text: "Technology, Pedagogy and Education",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RTPE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "technometrics",
    text: "Technometrics",
    parent: "american-statistical-association",
    urlone: "http://amstat.tfjournals.com/asa-style-guide/",
    urltwo: "NA"
  },
  {
    id: "technovation",
    text: "Technovation",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tectonics",
    text: "Tectonics",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "tectonophysics",
    text: "Tectonophysics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tekhne-review-of-applied-management-studies",
    text: "TÃ‰KHNE - Review of Applied Management Studies",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "telecommunication-systems",
    text: "Telecommunication Systems",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "telecommunications-and-radio-engineering",
    text: "Telecommunications and Radio Engineering",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/0632a9d54950b268.html",
    urltwo: "NA"
  },
  {
    id: "telecommunications-policy",
    text: "Telecommunications Policy",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "telematics-and-informatics",
    text: "Telematics and Informatics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "teologia-catalunya",
    text: "Teologia Catalunya",
    parent: "NA",
    urlone: "http://www.teologia-catalunya.cat",
    urltwo: "http://www.worldcat.org/oclc/804144878"
  },
  {
    id: "terra-nova",
    text: "Terra Nova",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-3121/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "terrorism-and-political-violence",
    text: "Terrorism and Political Violence",
    parent: "chicago-fullnote-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=ftpv20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "tertiary-education-and-management",
    text: "Tertiary Education and Management",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RTEM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "test",
    text: "TEST",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tetrahedron-letters",
    text: "Tetrahedron Letters",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/tetrahedron-letters/0040-4039/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "tetrahedron",
    text: "Tetrahedron",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "textiles-and-clothing-sustainability",
    text: "Textiles and Clothing Sustainability",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "textual-cultures",
    text: "Textual Cultures",
    parent: "NA",
    urlone: "http://textual-cultures.org/wp-content/uploads/2018/04/Style-Sheet-TC-updated.pdf",
    urltwo: "NA"
  },
  {
    id: "textual-practice",
    text: "Textual Practice",
    parent: "NA",
    urlone: "https://www.tandf.co.uk//journals/authors/style/layout/style_rtpr.pdf",
    urltwo: "NA"
  },
  {
    id: "tgm-wien-diplom",
    text: "TGM Wien Diplomarbeit (German)",
    parent: "NA",
    urlone: "http://www.tgm.ac.at/",
    urltwo: "NA"
  },
  {
    id: "tgm-wien-diplomarbeit-onorm",
    text: "TGM Wien Diplomarbeit Ã–NORM (German - Austria)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/3929#issuecomment-480520187",
    urltwo: "NA"
  },
  {
    id: "the-aaps-journal",
    text: "The AAPS Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-academy-of-management-annals",
    text: "The Academy of Management Annals",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAMA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-accounting-review",
    text: "The Accounting Review",
    parent: "NA",
    urlone: "http://aaahq.org/pubs/EdPolicies/REV_EdPolicy.pdf",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-bioethics",
    text: "The American Journal of Bioethics",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?show=instructions&amp;journalCode=uajb20",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-cardiology",
    text: "The American Journal of Cardiology",
    parent: "NA",
    urlone: "http://www.ajconline.org/authorinfo",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-clinical-nutrition",
    text: "The American Journal of Clinical Nutrition",
    parent: "journal-of-nutrition",
    urlone: "http://ajcn.nutrition.org/site/misc/ifa_format.xhtml#ref",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-gastroenterology",
    text: "The American Journal of Gastroenterology",
    parent: "NA",
    urlone: "http://www.nature.com/ajg/for_authors.html",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-geriatric-psychiatry",
    text: "The American Journal of Geriatric Psychiatry",
    parent: "NA",
    urlone: "https://www.editorialmanager.com/jgp/default.aspx",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-human-genetics",
    text: "The American Journal of Human Genetics",
    parent: "NA",
    urlone: "http://www.cell.com/AJHG/home",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-medicine",
    text: "The American Journal of Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-pathology",
    text: "The American Journal of Pathology",
    parent: "NA",
    urlone: "http://www.journals.elsevierhealth.com/periodicals/ajpa/authorinfo",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-psychiatry",
    text: "The American Journal of Psychiatry",
    parent: "NA",
    urlone: "https://ajp.psychiatryonline.org/ajp_ifora",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-sports-medicine",
    text: "The American Journal of Sports Medicine",
    parent: "american-medical-association-alphabetical",
    urlone: "http://www.sagepub.com/upm-data/43365_AJSM_submission_guidelines_09_2011.pdf",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-surgery",
    text: "The American Journal of Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-the-medical-sciences",
    text: "The American Journal of the Medical Sciences",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-american-journal-of-tropical-medicine-and-hygiene",
    text: "The American Journal of Tropical Medicine and Hygiene",
    parent: "american-medical-association-no-et-al",
    urlone: "http://www.ajtmh.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "the-american-midland-naturalist",
    text: "The American Midland Naturalist",
    parent: "NA",
    urlone: "https://www3.nd.edu/~ammidnat/editorial.html",
    urltwo: "http://www3.nd.edu/~ammidnat/authors.html"
  },
  {
    id: "the-american-naturalist",
    text: "The American Naturalist",
    parent: "NA",
    urlone: "http://www.press.uchicago.edu/journals/an/instruct.html?journal=an#tprep",
    urltwo: "NA"
  },
  {
    id: "the-american-sociologist",
    text: "The American Sociologist",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-american-statistician",
    text: "The American Statistician",
    parent: "american-statistical-association",
    urlone: "http://amstat.tfjournals.com/asa-style-guide/",
    urltwo: "NA"
  },
  {
    id: "the-american-surgeon",
    text: "The American Surgeon",
    parent: "vancouver",
    urlone: "http://www.sesc.org/aws/SESC/pt/sp/journal_authorinstructions",
    urltwo: "NA"
  },
  {
    id: "the-analysis-of-verbal-behavior",
    text: "The Analysis of Verbal Behavior",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-annals-of-applied-probability",
    text: "The Annals of Applied Probability",
    parent: "institute-of-mathematical-statistics",
    urlone: "https://imstat.org/journals-and-publications/annals-of-applied-probability/",
    urltwo: "NA"
  },
  {
    id: "the-annals-of-pharmacotherapy",
    text: "The Annals of Pharmacotherapy",
    parent: "american-medical-association",
    urlone: "http://www.theannals.com/site/misc/author-info.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "the-annals-of-probability",
    text: "The Annals of Probability",
    parent: "institute-of-mathematical-statistics",
    urlone: "https://imstat.org/journals-and-publications/annals-of-probability/",
    urltwo: "NA"
  },
  {
    id: "the-annals-of-regional-science",
    text: "The Annals of Regional Science",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-annals-of-statistics",
    text: "The Annals of Statistics",
    parent: "institute-of-mathematical-statistics",
    urlone: "https://imstat.org/journals-and-publications/annals-of-statistics/",
    urltwo: "NA"
  },
  {
    id: "the-annals-of-thoracic-surgery",
    text: "The Annals of Thoracic Surgery",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-arts-in-psychotherapy",
    text: "The Arts in Psychotherapy",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-asia-pacific-education-researcher",
    text: "The Asia-Pacific Education Researcher",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-asia-pacific-journal-of-anthropology",
    text: "The Asia Pacific Journal of Anthropology",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RTAP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-asian-journal-of-shipping-and-logistics",
    text: "The Asian Journal of Shipping and Logistics",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-astronomical-journal",
    text: "The Astronomical Journal",
    parent: "the-astrophysical-journal",
    urlone: "http://aas.org/authors/manuscript-preparation-aj-apj-author-instructions#references",
    urltwo: "NA"
  },
  {
    id: "the-astronomy-and-astrophysics-review",
    text: "The Astronomy and Astrophysics Review",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-astrophysical-journal-letters",
    text: "The Astrophysical Journal Letters",
    parent: "the-astrophysical-journal",
    urlone: "http://aas.org/authors/manuscript-preparation-aj-apj-author-instructions#references",
    urltwo: "NA"
  },
  {
    id: "the-astrophysical-journal-supplement-series",
    text: "The Astrophysical Journal Supplement Series",
    parent: "the-astrophysical-journal",
    urlone: "http://aas.org/authors/manuscript-preparation-aj-apj-author-instructions#references",
    urltwo: "NA"
  },
  {
    id: "the-astrophysical-journal",
    text: "The Astrophysical Journal",
    parent: "NA",
    urlone: "http://aas.org/journals/authors/common_instruct#references",
    urltwo: "NA"
  },
  {
    id: "the-auk",
    text: "The Auk",
    parent: "NA",
    urlone: "http://www.peertrack.net/COPO/AUK-CONDOR_Instructions_for_Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "the-australian-educational-researcher",
    text: "The Australian Educational Researcher",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-australian-journal-of-agricultural-and-resource-economics",
    text: "The Australian Journal of Agricultural and Resource Economics",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14678489/homepage/forauthors.html#5",
    urltwo: "NA"
  },
  {
    id: "the-australian-law-journal",
    text: "The Australian Law Journal",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-australian-library-journal",
    text: "The Australian Library Journal",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=UALJ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-behavior-analyst",
    text: "The Behavior Analyst",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-biological-bulletin",
    text: "The Biological Bulletin",
    parent: "NA",
    urlone: "http://www.biolbull.org/site/misc/ifora.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "the-bone-and-joint-journal",
    text: "The Bone &amp; Joint Journal",
    parent: "NA",
    urlone: "http://www.bjj.boneandjoint.org.uk/site/media/Authors/Paperstructure.pdf",
    urltwo: "NA"
  },
  {
    id: "the-botanical-review",
    text: "The Botanical Review",
    parent: "NA",
    urlone: "https://www.springer.com/life+sciences/plant+sciences/journal/12229",
    urltwo: "NA"
  },
  {
    id: "the-breast",
    text: "The Breast",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-british-accounting-review",
    text: "The British Accounting Review",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-british-journal-for-the-philosophy-of-science",
    text: "The British Journal for the Philosophy of Science",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/files/887880/_.BJPS-House-Style-01-2016.pdf",
    urltwo: "NA"
  },
  {
    id: "the-british-journal-of-cardiology",
    text: "The British Journal of Cardiology",
    parent: "NA",
    urlone: "http://bjcardio.co.uk/authors-instructions/",
    urltwo: "NA"
  },
  {
    id: "the-british-journal-of-criminology",
    text: "The British Journal of Criminology",
    parent: "NA",
    urlone: "https://academic.oup.com/bjc/pages/General_Instructions",
    urltwo: "NA"
  },
  {
    id: "the-british-journal-of-psychiatry",
    text: "The British Journal of Psychiatry",
    parent: "NA",
    urlone: "http://bjp.rcpsych.org/site/misc/ifora.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "the-british-journal-of-sociology",
    text: "The British Journal of Sociology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1468-4446/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "the-canadian-geographer",
    text: "The Canadian Geographer (Le GÃ©ographe canadien)",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1541-0064/homepage/ForAuthors.html",
    urltwo: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1541-0064/homepage/TCG_Style_Guide_2015-10-19.pdf"
  },
  {
    id: "the-canadian-journal-of-chemical-engineering",
    text: "The Canadian Journal of Chemical Engineering",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291939-019X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "the-canadian-journal-of-hospital-pharmacy",
    text: "The Canadian Journal of Hospital Pharmacy",
    parent: "vancouver",
    urlone: "http://www.cjhp-online.ca/pages/files/AuthorInstructions.pdf",
    urltwo: "NA"
  },
  {
    id: "the-canadian-journal-of-psychiatry",
    text: "The Canadian Journal of Psychiatry",
    parent: "NA",
    urlone: "https://us.sagepub.com/en-us/nam/the-canadian-journal-of-psychiatry/journal202498#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "the-canadian-veterinary-journal",
    text: "The Canadian Veterinary Journal",
    parent: "vancouver",
    urlone: "http://www.canadianveterinarians.net/publications/cvj-instructions.aspx",
    urltwo: "NA"
  },
  {
    id: "the-cancer-journal",
    text: "The Cancer Journal",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/ppo/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "the-cell-surface",
    text: "The Cell Surface",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-central-african-journal-of-medicine",
    text: "The Central African Journal of Medicine",
    parent: "vancouver",
    urlone: "http://www.ajol.info/index.php/cajm/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "the-cerebellum",
    text: "The Cerebellum",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-ceylon-journal-of-medical-science",
    text: "The Ceylon Journal of Medical Science",
    parent: "vancouver",
    urlone: "http://www.sljol.info/index.php/CJMS/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "the-ceylon-medical-journal",
    text: "The Ceylon Medical Journal",
    parent: "vancouver",
    urlone: "http://www.sljol.info/index.php/CMJ/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "the-chemical-society-of-japan",
    text: "The Chemical Society of Japan",
    parent: "NA",
    urlone: "http://www.csj.jp/journals/styles/ref.html",
    urltwo: "NA"
  },
  {
    id: "the-clinical-neuropsychologist",
    text: "The Clinical Neuropsychologist",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NTCN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-company-of-biologists",
    text: "The Company of Biologists",
    parent: "NA",
    urlone: "http://jeb.biologists.org/site/author/manuscript_preparation.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "the-computer-games-journal",
    text: "The Computer Games Journal",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-condor",
    text: "The Condor",
    parent: "the-auk",
    urlone: "http://www.peertrack.net/COPO/AUK-CONDOR_Instructions_for_Authors.pdf",
    urltwo: "NA"
  },
  {
    id: "the-crop-journal",
    text: "The Crop Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-cryosphere",
    text: "The Cryosphere",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-design-journal",
    text: "The Design Journal",
    parent: "NA",
    urlone: "http://www.bergpublishers.com/bergjournals/thedesignjournal/tabid/3650/default.aspx",
    urltwo: "NA"
  },
  {
    id: "the-economic-history-review",
    text: "The Economic History Review",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/14680289/homepage/forauthors.html",
    urltwo: "https://onlinelibrary.wiley.com/pb-assets/assets/14680289/EcHRauthornotes_Dec2019-1578901393423.pdf"
  },
  {
    id: "the-egyptian-heart-journal",
    text: "The Egyptian Heart Journal",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-egyptian-journal-of-aquatic-research",
    text: "The Egyptian Journal of Aquatic Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-egyptian-journal-of-critical-care-medicine",
    text: "The Egyptian Journal of Critical Care Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-egyptian-journal-of-radiology-and-nuclear-medicine",
    text: "The Egyptian Journal of Radiology and Nuclear Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-egyptian-journal-of-remote-sensing-and-space-sciences",
    text: "The Egyptian Journal of Remote Sensing and Space Sciences",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-egyptian-rheumatologist",
    text: "The Egyptian Rheumatologist",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-electricity-journal",
    text: "The Electricity Journal",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-embo-journal",
    text: "The EMBO Journal",
    parent: "embo-press",
    urlone: "https://www.embopress.org/page/journal/14602075/authorguide#referencesformat",
    urltwo: "NA"
  },
  {
    id: "the-european-journal-of-finance",
    text: "The European Journal of Finance",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REJF20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-european-journal-of-health-economics",
    text: "The European Journal of Health Economics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-european-journal-of-psychology-applied-to-legal-context",
    text: "The European Journal of Psychology Applied to Legal Context",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-european-journal-of-the-history-of-economic-thought",
    text: "The European Journal of the History of Economic Thought",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=REJH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-european-physical-journal-c",
    text: "The European Physical Journal C",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-european-research-journal",
    text: "The European Research Journal",
    parent: "NA",
    urlone: "http://eurj.org/?page_id=29",
    urltwo: "NA"
  },
  {
    id: "the-extractive-industries-and-society",
    text: "The Extractive Industries and Society",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-faseb-journal",
    text: "The FASEB Journal",
    parent: "NA",
    urlone: "http://www.fasebj.org/site/misc/rc.xhtml",
    urltwo: "NA"
  },
  {
    id: "the-febs-journal",
    text: "The FEBS Journal",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1742-4658/homepage/ForAuthors.html#manuscript",
    urltwo: "NA"
  },
  {
    id: "the-foot",
    text: "The Foot",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-geological-society-of-america",
    text: "The Geological Society of America",
    parent: "NA",
    urlone: "http://www.geosociety.org/gsa/pubs/geology/home.aspx#overview",
    urltwo: "http://www.geosociety.org/documents/gsa/pubs/GSA_RefGuide_Examples.pdf"
  },
  {
    id: "the-geological-society-of-london",
    text: "The Geological Society of London",
    parent: "NA",
    urlone: "https://www.geolsoc.org.uk/~/media/Files/GSL/shared/pdfs/Publications/AuthorInfo_Text.pdf",
    urltwo: "NA"
  },
  {
    id: "the-hastings-center-report",
    text: "The Hastings Center Report",
    parent: "NA",
    urlone: "http://www.thehastingscenter.org/the-hastings-report-submission-guidelines/",
    urltwo: "NA"
  },
  {
    id: "the-historical-journal",
    text: "The Historical Journal",
    parent: "NA",
    urlone: "http://assets.cambridge.org/HIS/HIS_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "the-history-of-the-family",
    text: "The History of the Family",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RHOF20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-holocene",
    text: "The Holocene",
    parent: "NA",
    urlone: "http://hol.sagepub.com/",
    urltwo: "NA"
  },
  {
    id: "the-humanistic-psychologist",
    text: "The Humanistic Psychologist",
    parent: "apa",
    urlone: "http://content.apa.org/journals/hum",
    urltwo: "NA"
  },
  {
    id: "the-ies-journal-part-a-civil-and-structural-engineering",
    text: "The IES Journal Part A: Civil &amp; Structural Engineering",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TIEA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-indian-journal-of-neurotrauma",
    text: "The Indian Journal of Neurotrauma",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-indian-journal-of-pediatrics",
    text: "The Indian Journal of Pediatrics",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-institute-of-electronics-information-and-communication-engineers",
    text: "The Institute of Electronics, Information and Communication Engineers (é›»å­æƒ…å ±é€šä¿¡å­¦ä¼š)",
    parent: "NA",
    urlone: "http://www.ieice.org/eng/shiori/index.html",
    urltwo: "NA"
  },
  {
    id: "the-institution-of-engineering-and-technology",
    text: "The Institution of Engineering and Technology",
    parent: "NA",
    urlone: "http://digital-library.theiet.org/journals/author-guide",
    urltwo: "NA"
  },
  {
    id: "the-international-journal-of-advanced-manufacturing-technology",
    text: "The International Journal of Advanced Manufacturing Technology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-international-journal-of-cardiovascular-imaging",
    text: "The International Journal of Cardiovascular Imaging",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-international-journal-of-developmental-biology",
    text: "The International Journal of Developmental Biology",
    parent: "NA",
    urlone: "http://www.ijdb.ehu.es/web/descarga/mschecklist",
    urltwo: "NA"
  },
  {
    id: "the-international-journal-of-life-cycle-assessment",
    text: "The International Journal of Life Cycle Assessment",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-international-journal-of-management-education",
    text: "The International Journal of Management Education",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-international-journal-of-psychiatry-in-medicine",
    text: "The International Journal of Psychiatry in Medicine",
    parent: "vancouver",
    urlone: "http://baywood.metapress.com/link.asp?id=300314",
    urltwo: "NA"
  },
  {
    id: "the-international-journal-of-psychoanalysis",
    text: "The International Journal of Psychoanalysis",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291745-8315/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "the-international-journal-of-the-history-of-sport",
    text: "The International Journal of the History of Sport",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FHSP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-international-journal-of-tuberculosis-and-lung-disease",
    text: "The International Journal of Tuberculosis and Lung Disease",
    parent: "NA",
    urlone:
      "https://www.theunion.org/what-we-do/journals/ijtld/information-for-authors/body/Author-Instructions-2019.pdf",
    urltwo: "NA"
  },
  {
    id: "the-international-review-of-retail-distribution-and-consumer-research",
    text: "The International Review of Retail, Distribution and Consumer Research",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIRR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-international-sports-law-journal",
    text: "The International Sports Law Journal",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-internet-and-higher-education",
    text: "The Internet and Higher Education",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-irish-journal-of-psychology",
    text: "The Irish Journal of Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RIRI20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-isme-journal",
    text: "The ISME Journal",
    parent: "NA",
    urlone: "http://www.nature.com/ismej/ismej_new_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "the-joint-commission-journal-on-quality-and-patient-safety",
    text: "The Joint Commission Journal on Quality and Patient Safety",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-for-nurse-practitioners",
    text: "The Journal for Nurse Practitioners",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-academic-librarianship",
    text: "The Journal of Academic Librarianship",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-adhesive-dentistry",
    text: "The Journal of Adhesive Dentistry",
    parent: "NA",
    urlone: "http://jad.quintessenz.de/index.php?doc=guidelines",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-agricultural-education-and-extension",
    text: "The Journal of Agricultural Education and Extension",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RAEE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-agricultural-science",
    text: "The Journal of Agricultural Science",
    parent: "NA",
    urlone:
      "https://www.cambridge.org/core/journals/journal-of-agricultural-science/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-allergy-and-clinical-immunology",
    text: "The Journal of Allergy and Clinical Immunology",
    parent: "vancouver-superscript-only-year",
    urlone: "http://www.jacionline.org/authorinfo",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-antibiotics",
    text: "The Journal of Antibiotics",
    parent: "nature",
    urlone: "http://mts-ja.nature.com/cgi-bin/main.plex?form_type=display_auth_instructions#format",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-arthroplasty",
    text: "The Journal of Arthroplasty",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-basic-and-applied-zoology",
    text: "The Journal of Basic &amp; Applied Zoology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-bone-and-joint-surgery",
    text: "The Journal of Bone &amp; Joint Surgery",
    parent: "american-medical-association",
    urlone: "http://jbjs.org/public/instructionsauthors.aspx",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-cardiovascular-surgery",
    text: "The Journal of Cardiovascular Surgery",
    parent: "vancouver-superscript",
    urlone: "http://www.minervamedica.it/en/journals/cardiovascular-surgery/notice-to-authors.php",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-cell-biology",
    text: "The Journal of Cell Biology",
    parent: "the-rockefeller-university-press",
    urlone: "http://jcb.rupress.org/",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-chemical-physics",
    text: "The Journal of Chemical Physics",
    parent: "american-institute-of-physics",
    urlone: "http://publishing.aip.org/authors",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-chemical-thermodynamics",
    text: "The Journal of Chemical Thermodynamics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-chinese-sociology",
    text: "The Journal of Chinese Sociology",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-clinical-endocrinology-and-metabolism",
    text: "The Journal of Clinical Endocrinology &amp; Metabolism",
    parent: "endocrine-press",
    urlone: "http://press.endocrine.org/page/authors#mozTocId713371",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-clinical-ethics",
    text: "The Journal of Clinical Ethics",
    parent: "NA",
    urlone: "http://www.clinicalethics.com/JCE%20Reference%20Style.pdf",
    urltwo: "http://www.clinicalethics.com/Instructions.htm#FORMAT_"
  },
  {
    id: "the-journal-of-clinical-investigation",
    text: "The Journal of Clinical Investigation",
    parent: "NA",
    urlone: "http://www.jci.org/kiosk/publish",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-clinical-psychiatry",
    text: "The Journal of Clinical Psychiatry",
    parent: "american-medical-association",
    urlone: "http://www.psychiatrist.com/documents/authors.asp",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-comparative-germanic-linguistics",
    text: "The Journal of Comparative Germanic Linguistics",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-comparative-law",
    text: "The Journal of Comparative Law",
    parent: "NA",
    urlone: "https://www.wildy.com/isbn/1477-0814/journal-of-comparative-law-annual-subscription",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-comparative-neurology",
    text: "The Journal of Comparative Neurology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1096-9861/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-continuing-education-in-nursing",
    text: "The Journal of Continuing Education in Nursing",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/journals/jcen/%7E/media/768885EA82B74F81839CB33DE5A93276.ashx",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-economic-asymmetries",
    text: "The Journal of Economic Asymmetries",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-economic-inequality",
    text: "The Journal of Economic Inequality",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-egyptian-archaeology",
    text: "The Journal of Egyptian Archaeology",
    parent: "NA",
    urlone: "http://www.ees.ac.uk/publications/journal-egyptian-archaeology.html",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-eukaryotic-microbiology",
    text: "The Journal of Eukaryotic Microbiology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1550-7408/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-evidence-based-dental-practice",
    text: "The Journal of Evidence-Based Dental Practice",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-experimental-biology",
    text: "The Journal of Experimental Biology",
    parent: "the-company-of-biologists",
    urlone: "http://jeb.biologists.org/site/author/manuscript_preparation.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-experimental-medicine",
    text: "The Journal of Experimental Medicine",
    parent: "the-rockefeller-university-press",
    urlone: "http://jem.rupress.org/",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-finance-and-data-science",
    text: "The Journal of Finance and Data Science",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-foot-and-ankle-surgery",
    text: "The Journal of Foot &amp; Ankle Surgery",
    parent: "NA",
    urlone: "http://www.jfas.org/content/authorinfo#idp1778672",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-general-physiology",
    text: "The Journal of General Physiology",
    parent: "the-rockefeller-university-press",
    urlone: "http://jgp.rupress.org/",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-geometric-analysis",
    text: "The Journal of Geometric Analysis",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-hand-surgery-asian-pacific-volume",
    text: "The Journal of Hand Surgery Asian-Pacific Volume",
    parent: "NA",
    urlone: "http://www.jhs-ap.org/main.html",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-hand-surgery-european-volume",
    text: "The Journal of Hand Surgery (European Volume)",
    parent: "NA",
    urlone:
      "https://uk.sagepub.com/en-gb/eur/journal-of-hand-surgery-european-volume/journal201891#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-hand-surgery",
    text: "The Journal of Hand Surgery",
    parent: "american-medical-association",
    urlone: "http://www.elsevier.com/journals/journal-of-hand-surgery-american-volume/0363-5023/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-headache-and-pain",
    text: "The Journal of Headache and Pain",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-heart-and-lung-transplantation",
    text: "The Journal of Heart and Lung Transplantation",
    parent: "american-medical-association",
    urlone:
      "http://www.elsevier.com/journals/the-journal-of-heart-and-lung-transplantation/1053-2498/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-hellenic-studies",
    text: "The Journal of Hellenic Studies",
    parent: "NA",
    urlone: "http://www.hellenicsociety.org.uk/publications/",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-immunology",
    text: "The Journal of Immunology",
    parent: "NA",
    urlone: "http://www.jimmunol.org/site/misc/authorinstructions.xhtml#references",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-imperial-and-commonwealth-history",
    text: "The Journal of Imperial and Commonwealth History",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FICH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-indian-prosthodontic-society",
    text: "The Journal of Indian Prosthodontic Society",
    parent: "vancouver",
    urlone: "http://www.j-ips.org/contributors.asp",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-infection-in-developing-countries",
    text: "The Journal of Infection in Developing Countries",
    parent: "NA",
    urlone: "http://www.jidc.org/index.php/journal/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-international-trade-and-economic-development",
    text: "The Journal of International Trade &amp; Economic Development",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJTE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-juristic-papyrology",
    text: "The Journal of Juristic Papyrology",
    parent: "NA",
    urlone: "http://www.taubenschlagfoundation.pl/download/guidelines_for_jjp_articles.pdf",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-legal-pluralism-and-unofficial-law",
    text: "The Journal of Legal Pluralism and Unofficial Law",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RJLP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-mathematical-neuroscience",
    text: "The Journal of Mathematical Neuroscience",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-medieval-and-early-modern-studies",
    text: "The Journal of Medieval and Early Modern Studies",
    parent: "chicago-note-bibliography",
    urlone: "http://jmems.dukejournals.org/",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-modern-history",
    text: "The Journal of Modern History",
    parent: "NA",
    urlone: "http://www.journals.uchicago.edu/journals/jmh/instruct-articles",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-molecular-diagnostics",
    text: "The Journal of Molecular Diagnostics",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/the-journal-of-molecular-diagnostics/1525-1578/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-nervous-and-mental-disease",
    text: "The Journal of Nervous and Mental Disease",
    parent: "NA",
    urlone: "http://edmgr.ovid.com/jnmd/accounts/ifauth.htm",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-neuropsychiatry-and-clinical-neurosciences",
    text: "The Journal of Neuropsychiatry and Clinical Neurosciences",
    parent: "NA",
    urlone: "http://neuro.psychiatryonline.org/neuro_ifora.aspx",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-neuroscience",
    text: "The Journal of Neuroscience",
    parent: "NA",
    urlone: "http://www.jneurosci.org/site/misc/ifa_organization.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-north-african-studies",
    text: "The Journal of North African Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FNAS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-nuclear-medicine",
    text: "The Journal of Nuclear Medicine",
    parent: "NA",
    urlone: "http://jnm.snmjournals.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-nutrition-health-and-aging",
    text: "The Journal of Nutrition, Health &amp; Aging",
    parent: "NA",
    urlone: "https://www.springer.com/medicine/family/journal/12603",
    urltwo:
      "http://www.springer.com/cda/content/document/cda_downloaddocument/12603_IA+JNHA.PDF?SGWID=0-0-45-1552371-p173841503"
  },
  {
    id: "the-journal-of-nutritional-biochemistry",
    text: "The Journal of Nutritional Biochemistry",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-obstetrics-and-gynecology-of-india",
    text: "The Journal of Obstetrics and Gynecology of India",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-organic-chemistry",
    text: "The Journal of Organic Chemistry",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/joceah/joceah_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-pain",
    text: "The Journal of Pain",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/the-journal-of-pain/1526-5900/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-parasitology",
    text: "The Journal of Parasitology",
    parent: "NA",
    urlone: "http://www.journalofparasitology.org/page/author_instructions?code=asp-site",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-pathology",
    text: "The Journal of Pathology",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291096-9896/homepage/ForAuthors.html#references",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-peasant-studies",
    text: "The Journal of Peasant Studies",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=fjps20&amp;page=instructions#.UiEmqqwju1F",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-pediatrics",
    text: "The Journal of Pediatrics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-pharmacology-and-experimental-therapeutics",
    text: "The Journal of Pharmacology and Experimental Therapeutics",
    parent: "american-society-for-pharmacology-and-experimental-therapeutics",
    urlone: "http://jpet.aspetjournals.org/site/misc/ifora.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-pharmacy-technology",
    text: "The Journal of Pharmacy Technology",
    parent: "vancouver-superscript",
    urlone: "http://www.jpharmtechnol.com/guidelines2.html",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-physical-chemistry-a",
    text: "The Journal of Physical Chemistry A",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jpchax/jpchax_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-physical-chemistry-b",
    text: "The Journal of Physical Chemistry B",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jpchax/jpchax_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-physical-chemistry-c",
    text: "The Journal of Physical Chemistry C",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jpchax/jpchax_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-physical-chemistry-letters",
    text: "The Journal of Physical Chemistry Letters",
    parent: "american-chemical-society",
    urlone: "http://pubs.acs.org/paragonplus/submission/jpchax/jpclcd_authguide.pdf",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-physiological-sciences",
    text: "The Journal of Physiological Sciences",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-physiology",
    text: "The Journal of Physiology",
    parent: "NA",
    urlone: "http://jp.physoc.org/site/misc/author.xhtml#submitted",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-positive-psychology",
    text: "The Journal of Positive Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RPOS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-primary-prevention",
    text: "The Journal of Primary Prevention",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-prosthetic-dentistry",
    text: "The Journal of Prosthetic Dentistry",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.com/journals/journal-of-prosthetic-dentistry/0022-3913/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-pure-and-applied-chemistry-research",
    text: "The Journal of Pure and Applied Chemistry Research",
    parent: "NA",
    urlone: "http://jpacr.ub.ac.id/index.php/jpacr/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-real-estate-finance-and-economics",
    text: "The Journal of Real Estate Finance and Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-roman-studies",
    text: "The Journal of Roman Studies",
    parent: "NA",
    urlone: "http://assets.cambridge.org/JRS/JRS_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-sexual-medicine",
    text: "The Journal of Sexual Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-social-studies-research",
    text: "The Journal of Social Studies Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-space-safety-engineering",
    text: "The Journal of Space Safety Engineering",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-supercomputing",
    text: "The Journal of Supercomputing",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-supercritical-fluids",
    text: "The Journal of Supercritical Fluids",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-systems-and-software",
    text: "The Journal of Systems &amp; Software",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-technology-transfer",
    text: "The Journal of Technology Transfer",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-the-acoustical-society-of-america",
    text: "The Journal of the Acoustical Society of America",
    parent: "NA",
    urlone: "http://scitation.aip.org/journals/doc/ASALIB-home/corp/pdf/jasa/author_contrib.pdf",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-the-american-dental-association",
    text: "The Journal of the American Dental Association",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-the-american-osteopathic-association",
    text: "The Journal of the American Osteopathic Association",
    parent: "american-medical-association",
    urlone: "http://www.jaoa.org/site/misc/ifora.xhtml#msprep",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-the-astronautical-sciences",
    text: "The Journal of the Astronautical Sciences",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-journal-of-the-economics-of-ageing",
    text: "The Journal of the Economics of Ageing",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-the-torrey-botanical-society",
    text: "The Journal of the Torrey Botanical Society",
    parent: "NA",
    urlone: "http://www.bioone.org/page/tbot/authors",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-thoracic-and-cardiovascular-surgery",
    text: "The Journal of Thoracic and Cardiovascular Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-transport-history",
    text: "The Journal of Transport History",
    parent: "NA",
    urlone: "https://uk.sagepub.com/en-gb/eur/journal-of-transport-history/journal202520#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-trauma-and-acute-care-surgery",
    text: "The Journal of Trauma and Acute Care Surgery",
    parent: "NA",
    urlone: "https://journals.lww.com/jtrauma/Pages/informationforauthors.aspx",
    urltwo: "http://edmgr.ovid.com/jt/accounts/ifauth.pdf"
  },
  {
    id: "the-journal-of-urology",
    text: "The Journal of Urology",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/the-journal-of-urology/0022-5347/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-veterinary-medical-science",
    text: "The Journal of Veterinary Medical Science",
    parent: "NA",
    urlone: "http://jsvetsci.jp/jvms/instructions-for-authors/",
    urltwo: "NA"
  },
  {
    id: "the-journal-of-wildlife-management",
    text: "The Journal of Wildlife Management",
    parent: "NA",
    urlone: "http://wildlife.org/publications/jwm",
    urltwo: "NA"
  },
  {
    id: "the-journals-of-gerontology-series-a",
    text: "The Journals of Gerontology, Series A: Biological Sciences and Medical Sciences",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/gerona/for_authors/general.html",
    urltwo: "NA"
  },
  {
    id: "the-knee",
    text: "The Knee",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-korean-journal-of-gastroenterology",
    text: "The Korean Journal of Gastroenterology",
    parent: "NA",
    urlone: "http://www.kjg.or.kr/contributors/authors.html",
    urltwo: "NA"
  },
  {
    id: "the-korean-journal-of-internal-medicine",
    text: "The Korean Journal of Internal Medicine",
    parent: "NA",
    urlone: "https://www.kjim.org/authors/authors.php#11",
    urltwo: "NA"
  },
  {
    id: "the-korean-journal-of-pathology",
    text: "The Korean Journal of Pathology",
    parent: "vancouver-superscript",
    urlone: "http://esubmit.koreanjpathol.org/about/author_ins.php",
    urltwo: "NA"
  },
  {
    id: "the-lancet-infectious-diseases",
    text: "The Lancet Infectious Diseases",
    parent: "the-lancet",
    urlone: "http://www.thelancet.com/lancet-infectious-diseases-information-for-authors/formatting-guidelines",
    urltwo: "NA"
  },
  {
    id: "the-lancet-neurology",
    text: "The Lancet Neurology",
    parent: "the-lancet",
    urlone: "http://www.thelancet.com/lancet-neurology-information-for-authors/formatting-guidelines",
    urltwo: "NA"
  },
  {
    id: "the-lancet-oncology",
    text: "The Lancet Oncology",
    parent: "the-lancet",
    urlone: "http://www.thelancet.com/lancet-oncology-information-for-authors/formatting-guidelines",
    urltwo: "NA"
  },
  {
    id: "the-lancet",
    text: "The Lancet",
    parent: "NA",
    urlone: "http://download.thelancet.com/flatcontentassets/authors/lancet-information-for-authors.pdf",
    urltwo: "NA"
  },
  {
    id: "the-lawyer-quarterly",
    text: "The Lawyer Quarterly",
    parent: "pravnik",
    urlone: "https://tlq.ilaw.cas.cz/index.php/tlq/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "the-leadership-quarterly",
    text: "The Leadership Quarterly",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-library",
    text: "The Library",
    parent: "modern-humanities-research-association",
    urlone: "http://www.bibsoc.org.uk/library/contribs",
    urltwo: "NA"
  },
  {
    id: "the-lichenologist",
    text: "The Lichenologist",
    parent: "NA",
    urlone: "http://journals.cambridge.org/action/displayJournal?jid=lic",
    urltwo: "NA"
  },
  {
    id: "the-malaysian-journal-of-pathology",
    text: "The Malaysian Journal of Pathology",
    parent: "vancouver-superscript",
    urlone: "http://www.mjpath.org.my/pdf/information%20for%20authors.pdf",
    urltwo: "NA"
  },
  {
    id: "the-medical-journal-of-australia",
    text: "The Medical Journal of Australia",
    parent: "vancouver-superscript",
    urlone: "https://www.mja.com.au/journal/mja-instructions-authors-mja-style",
    urltwo: "NA"
  },
  {
    id: "the-national-medical-journal-of-india",
    text: "The National Medical Journal of India",
    parent: "NA",
    urlone: "http://www.nmji.in/contributors.asp#ref",
    urltwo: "NA"
  },
  {
    id: "the-netherlands-journal-of-medicine",
    text: "The Netherlands Journal of Medicine",
    parent: "vancouver-brackets",
    urlone: "http://www.njmonline.nl/authors.php",
    urltwo: "NA"
  },
  {
    id: "the-neuroscientist",
    text: "The Neuroscientist",
    parent: "NA",
    urlone: "http://nro.sagepub.com",
    urltwo: "NA"
  },
  {
    id: "the-new-england-journal-of-medicine",
    text: "The New England Journal of Medicine",
    parent: "NA",
    urlone: "http://www.nejm.org/page/author-center/manuscript-submission#style",
    urltwo: "NA"
  },
  {
    id: "the-new-iraqi-journal-of-medicine",
    text: "The New Iraqi Journal of Medicine",
    parent: "vancouver",
    urlone: "http://www.newiraqijm.4t.com/",
    urltwo: "NA"
  },
  {
    id: "the-new-zealand-medical-journal",
    text: "The New Zealand Medical Journal",
    parent: "vancouver-superscript",
    urlone: "http://journal.nzma.org.nz/journal/authors.html",
    urltwo: "NA"
  },
  {
    id: "the-nucleus",
    text: "The Nucleus",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-ocular-surface",
    text: "The Ocular Surface",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-oncologist",
    text: "The Oncologist",
    parent: "NA",
    urlone: "https://theoncologist.onlinelibrary.wiley.com/page/journal/1549490x/homepage/for_authors",
    urltwo: "NA"
  },
  {
    id: "the-open-university-a251",
    text: "The Open University - A251 - Arts Course",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-open-university-harvard",
    text: "The Open University - Harvard",
    parent: "NA",
    urlone: "http://www.open.ac.uk/library/help-and-support/referencing-and-plagiarism",
    urltwo: "NA"
  },
  {
    id: "the-open-university-m801",
    text: "The Open University - M801 - Research Project and Dissertation",
    parent: "NA",
    urlone: "http://www.open.ac.uk/library/help-and-support/referencing-styles",
    urltwo: "NA"
  },
  {
    id: "the-open-university-numeric-superscript",
    text: "The Open University (numeric, superscript)",
    parent: "NA",
    urlone: "http://www.open.ac.uk/library/help-and-support/referencing-styles",
    urltwo: "NA"
  },
  {
    id: "the-open-university-numeric",
    text: "The Open University (numeric)",
    parent: "NA",
    urlone: "http://www.open.ac.uk/library/help-and-support/referencing-styles",
    urltwo: "NA"
  },
  {
    id: "the-open-university-s390",
    text: "The Open University - S390",
    parent: "NA",
    urlone: "http://www.open.ac.uk/libraryservices/documents/Harvard_citation_hlp.pdf",
    urltwo: "https://github.com/citation-style-language/styles/pull/4711#discussion_r411547690"
  },
  {
    id: "the-optical-society",
    text: "The Optical Society",
    parent: "NA",
    urlone: "http://www.opticsinfobase.org/author/author.cfm",
    urltwo: "NA"
  },
  {
    id: "the-pan-african-medical-journal",
    text: "The Pan African Medical Journal",
    parent: "NA",
    urlone: "http://www.panafrican-med-journal.com/instruction.php#References",
    urltwo: "NA"
  },
  {
    id: "the-patient-patient-centered-outcomes-research",
    text: "The Patient - Patient-Centered Outcomes Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-pharmacogenomics-journal",
    text: "The Pharmacogenomics Journal",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/tpj/TPJ_gta.pdf",
    urltwo: "NA"
  },
  {
    id: "the-physician-and-sportsmedicine",
    text: "The Physician and Sportsmedicine",
    parent: "american-medical-association",
    urlone: "https://physsportsmed.org/authors",
    urltwo: "NA"
  },
  {
    id: "the-plant-cell",
    text: "The Plant Cell",
    parent: "NA",
    urlone: "http://www.plantcell.org/site/misc/ifora.xhtml#References",
    urltwo: "NA"
  },
  {
    id: "the-plant-genome",
    text: "The Plant Genome",
    parent: "NA",
    urlone: "https://dl.sciencesocieties.org/publications/tpg/author-instructions",
    urltwo: "NA"
  },
  {
    id: "the-plant-journal",
    text: "The Plant Journal",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291365-313X/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "the-primary-care-companion-for-cns-disorders",
    text: "The Primary Care Companion for CNS Disorders",
    parent: "vancouver-superscript",
    urlone: "http://www.psychiatrist.com/pcc/documents/infoforauthors.asp#references",
    urltwo: "NA"
  },
  {
    id: "the-protein-journal",
    text: "The Protein Journal",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-psychological-record",
    text: "The Psychological Record",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-psychologist-manager-journal",
    text: "The Psychologist-Manager Journal",
    parent: "apa",
    urlone: "http://content.apa.org/journals/mgr",
    urltwo: "NA"
  },
  {
    id: "the-quarterly-journal-of-experimental-psychology",
    text: "The Quarterly Journal of Experimental Psychology",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PQJE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-queensland-lawyer",
    text: "The Queensland Lawyer",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-ramanujan-journal",
    text: "The Ramanujan Journal",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-rangeland-journal",
    text: "The Rangeland Journal",
    parent: "international-journal-of-wildland-fire",
    urlone: "http://www.publish.csiro.au/nid/206/aid/4844.htm#16",
    urltwo: "NA"
  },
  {
    id: "the-review-of-austrian-economics",
    text: "The Review of Austrian Economics",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-review-of-black-political-economy",
    text: "The Review of Black Political Economy",
    parent: "springer-vancouver-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-review-of-faith-and-international-affairs",
    text: "The Review of Faith &amp; International Affairs",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RFIA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-review-of-financial-studies",
    text: "The Review of Financial Studies",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/revfin/for_authors/ms_preparation.html",
    urltwo: "NA"
  },
  {
    id: "the-review-of-international-organizations",
    text: "The Review of International Organizations",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-rockefeller-university-press",
    text: "The Rockefeller University Press",
    parent: "NA",
    urlone: "http://jgp.rupress.org/site/misc/ifora.xhtml",
    urltwo: "NA"
  },
  {
    id: "the-saudi-dental-journal",
    text: "The Saudi Dental Journal",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-saudi-journal-for-dental-research",
    text: "The Saudi Journal for Dental Research",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/the-saudi-journal-for-dental-research/2352-0035/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "the-scandinavian-journal-of-clinical-and-laboratory-investigation",
    text: "The Scandinavian Journal of Clinical &amp; Laboratory Investigation",
    parent: "NA",
    urlone: "http://informahealthcare.com/page/Description?journalCode=clb#Instructions",
    urltwo: "NA"
  },
  {
    id: "the-scandinavian-psychoanalytic-review",
    text: "The Scandinavian Psychoanalytic Review",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSPR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-science-of-nature",
    text: "The Science of Nature",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-seventeenth-century",
    text: "The Seventeenth Century",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSEV20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-sixties",
    text: "The Sixties",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RSIX20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "the-social-science-journal",
    text: "The Social Science Journal",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-spanish-review-of-financial-economics",
    text: "The Spanish Review of Financial Economics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-spine-journal",
    text: "The Spine Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-surgeon",
    text: "The Surgeon",
    parent: "vancouver-superscript",
    urlone: "http://www.elsevier.com/journals/the-surgeon/1479-666X/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "the-tort-law-review",
    text: "The Tort Law Review",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-ulster-medical-journal",
    text: "The Ulster Medical Journal",
    parent: "vancouver-superscript",
    urlone: "http://www.ums.ac.uk/contributors.html",
    urltwo: "NA"
  },
  {
    id: "the-university-of-sydney-harvard",
    text: "The University of Sydney - Harvard",
    parent: "style-manual-australian-government",
    urlone: "https://library.sydney.edu.au/subjects/downloads/citation/Harvard_Complete.pdf",
    urltwo: "NA"
  },
  {
    id: "the-university-of-tokyo-law-review",
    text: "æ±äº¬å¤§å­¦æ³•ç§‘å¤§å­¦é™¢ãƒ­ãƒ¼ãƒ¬ãƒ“ãƒ¥ãƒ¼ (The University of Tokyo Law Review) (Japanese)",
    parent: "NA",
    urlone: "http://www.sllr.j.u-tokyo.ac.jp/",
    urltwo: "http://www.j.u-tokyo.ac.jp/students/wp-content/uploads/sites/5/2017/11/20180115quote.pdf"
  },
  {
    id: "the-university-of-western-australia-harvard",
    text: "The University of Western Australia - Harvard",
    parent: "NA",
    urlone: "http://guides.library.uwa.edu.au/friendly.php?s=harvard",
    urltwo: "NA"
  },
  {
    id: "the-university-of-winchester-harvard",
    text: "The University of Winchester - Harvard",
    parent: "NA",
    urlone: "http://mla.winchester.ac.uk/?attachment_id=2060",
    urltwo: "NA"
  },
  {
    id: "the-urban-review",
    text: "The Urban Review",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-vaccine-companion",
    text: "The Vaccine Companion",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-veterinary-journal",
    text: "The Veterinary Journal",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "the-visual-computer",
    text: "The Visual Computer",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-vldb-journal",
    text: "The VLDB Journal",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "the-world-journal-of-biological-psychiatry",
    text: "The World Journal of Biological Psychiatry",
    parent: "NA",
    urlone: "http://informahealthcare.com/page/wbp/Description#Instructions",
    urltwo: "NA"
  },
  {
    id: "theologie-und-philosophie",
    text: "Theologie und Philosophie (German)",
    parent: "NA",
    urlone: "http://www.sankt-georgen.de/thph/",
    urltwo: "NA"
  },
  {
    id: "theoretical-and-applied-climatology",
    text: "Theoretical and Applied Climatology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theoretical-and-applied-fracture-mechanics",
    text: "Theoretical and Applied Fracture Mechanics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "theoretical-and-applied-genetics",
    text: "Theoretical and Applied Genetics",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theoretical-and-applied-mechanics-letters",
    text: "Theoretical and Applied Mechanics Letters",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "theoretical-and-computational-fluid-dynamics",
    text: "Theoretical and Computational Fluid Dynamics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theoretical-and-experimental-plant-physiology",
    text: "Theoretical and Experimental Plant Physiology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theoretical-biology-and-medical-modelling",
    text: "Theoretical Biology and Medical Modelling",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theoretical-chemistry-accounts",
    text: "Theoretical Chemistry Accounts",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theoretical-computer-science",
    text: "Theoretical Computer Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "theoretical-ecology",
    text: "Theoretical Ecology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theoretical-issues-in-ergonomics-science",
    text: "Theoretical Issues in Ergonomics Science",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TTIE20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "theoretical-medicine-and-bioethics",
    text: "Theoretical Medicine and Bioethics",
    parent: "springer-humanities-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theoretical-population-biology",
    text: "Theoretical Population Biology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "theory-and-decision",
    text: "Theory and Decision",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theory-and-society",
    text: "Theory and Society",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theory-culture-and-society",
    text: "Theory, Culture &amp; Society",
    parent: "NA",
    urlone: "http://www.uk.sagepub.com/journals/Journal200853/manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "theory-in-biosciences",
    text: "Theory in Biosciences",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theory-of-computing-systems",
    text: "Theory of Computing Systems",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "theranostics",
    text: "Theranostics",
    parent: "NA",
    urlone: "http://www.thno.org/ms/author",
    urltwo: "NA"
  },
  {
    id: "therapeutic-advances-in-neurological-disorders",
    text: "Therapeutic Advances in Neurological Disorders",
    parent: "elsevier-vancouver",
    urlone: "http://www.uk.sagepub.com/msg/ta.htm",
    urltwo: "NA"
  },
  {
    id: "therapeutic-delivery",
    text: "Therapeutic Delivery",
    parent: "future-science-group",
    urlone: "http://www.future-science.com/loi/tde",
    urltwo: "NA"
  },
  {
    id: "therapie",
    text: "ThÃ©rapie",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "theriogenology",
    text: "Theriogenology",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "thermal-science-and-engineering-progress",
    text: "Thermal Science and Engineering Progress",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "thermochimica-acta",
    text: "Thermochimica Acta",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "thieme-german",
    text: "Thieme-German (German)",
    parent: "NA",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "thin-solid-films",
    text: "Thin Solid Films",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "thin-walled-structures",
    text: "Thin-Walled Structures",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "thinking-and-reasoning",
    text: "Thinking &amp; Reasoning",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PTAR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "thinking-skills-and-creativity",
    text: "Thinking Skills and Creativity",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "thomson-reuters-legal-tax-and-accounting-australia",
    text: "Thomson Reuters - Legal, Tax &amp; Accounting Australia",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "thorax",
    text: "Thorax",
    parent: "bmj",
    urlone: "http://thorax.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "thrombosis-and-haemostasis",
    text: "Thrombosis and Haemostasis",
    parent: "NA",
    urlone: "http://www.schattauer.de/en/magazine/subject-areas/journals-a-z/thrombosis-and-haemostasis.html",
    urltwo: "NA"
  },
  {
    id: "thrombosis-journal",
    text: "Thrombosis Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "thrombosis-research",
    text: "Thrombosis Research",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "thyroid-research",
    text: "Thyroid Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "thyroid",
    text: "Thyroid",
    parent: "NA",
    urlone: "http://www.liebertpub.com/forauthors/thyroid-journal-program/625/",
    urltwo: "NA"
  },
  {
    id: "ticks-and-tick-borne-diseases",
    text: "Ticks and Tick-borne Diseases",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tidsskrift-for-den-norske-legeforening",
    text: "Tidsskrift for Den norske legeforening",
    parent: "vancouver",
    urlone: "http://tidsskriftet.no/Innhold/Forfatterveiledningen/Manusutforming/Litteraturliste",
    urltwo: "NA"
  },
  {
    id: "tijdschrift-voor-economische-en-sociale-geografie",
    text: "Tijdschrift voor economische en sociale geografie",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1467-9663/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "tijdschrift-voor-geneeskunde",
    text: "Tijdschrift voor Geneeskunde (Dutch)",
    parent: "NA",
    urlone: "https://www.tvg.be/index.php/richtlijnen-auteurs",
    urltwo: "NA"
  },
  {
    id: "tijdschrift-voor-gerontologie-en-geriatrie",
    text: "Tijdschrift voor Gerontologie en Geriatrie (Dutch)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tijdschrift-voor-nucleaire-geneeskunde",
    text: "Tijdschrift voor Nucleaire Geneeskunde",
    parent: "vancouver",
    urlone: "http://www.tijdschriftvoornucleairegeneeskunde.nl/nl/redactie",
    urltwo: "NA"
  },
  {
    id: "tijdschrift-voor-psychotherapie",
    text: "Tijdschrift voor Psychotherapie (Dutch)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tijdschrift-voor-urologie",
    text: "Tijdschrift voor Urologie (Dutch)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tissue-and-cell",
    text: "Tissue and Cell",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tissue-barriers",
    text: "Tissue Barriers",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tissue-engineering",
    text: "Tissue Engineering",
    parent: "NA",
    urlone: "https://home.liebertpub.com/publications/tissue-engineering-parts-a-b-and-c/595/for-authors",
    urltwo: "NA"
  },
  {
    id: "tobacco-control",
    text: "Tobacco Control",
    parent: "bmj",
    urlone: "http://tobaccocontrol.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "tobacco-induced-diseases",
    text: "Tobacco Induced Diseases",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "top",
    text: "TOP",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "topics-in-catalysis",
    text: "Topics in Catalysis",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "topics-in-current-chemistry",
    text: "Topics in Current Chemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "topoi",
    text: "Topoi",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "topology-and-its-applications",
    text: "Topology and its Applications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tourism-management-perspectives",
    text: "Tourism Management Perspectives",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tourism-management",
    text: "Tourism Management",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tourism-planning-and-development",
    text: "Tourism Planning &amp; Development",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RTHP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "toxicological-and-environmental-chemistry",
    text: "Toxicological &amp; Environmental Chemistry",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GTEC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "toxicological-sciences",
    text: "Toxicological Sciences",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org/our_journals/toxsci/for_authors/msprep_submission.html",
    urltwo: "NA"
  },
  {
    id: "toxicologie-analytique-et-clinique",
    text: "Toxicologie Analytique et Clinique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "toxicology-and-applied-pharmacology",
    text: "Toxicology and Applied Pharmacology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "toxicology-in-vitro",
    text: "Toxicology in Vitro",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "toxicology-letters",
    text: "Toxicology Letters",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "toxicology-reports",
    text: "Toxicology Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "toxicology-research",
    text: "Toxicology Research",
    parent: "royal-society-of-chemistry",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "toxicology",
    text: "Toxicology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "toxicon-x",
    text: "Toxicon: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "toxicon",
    text: "Toxicon",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "toxics",
    text: "Toxics",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "toxins",
    text: "Toxins",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "trabajos-de-prehistoria",
    text: "Trabajos de Prehistoria (Spanish)",
    parent: "NA",
    urlone: "http://tp.revistas.csic.es/index.php/tp/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "traces",
    text: "TracÃ©s: Revue de Sciences Humaines (French)",
    parent: "NA",
    urlone: "http://traces.revues.org/",
    urltwo: "NA"
  },
  {
    id: "traffic-injury-prevention",
    text: "Traffic Injury Prevention",
    parent: "NA",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=gcpi20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "traffic",
    text: "Traffic",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291600-0854/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "training-and-education-in-professional-psychology",
    text: "Training and Education in Professional Psychology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/tep",
    urltwo: "NA"
  },
  {
    id: "transactions-of-a-razmadze-mathematical-institute",
    text: "Transactions of A. Razmadze Mathematical Institute",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transactions-of-mathematics-and-its-applications",
    text: "Transactions of Mathematics and its Applications",
    parent: "institute-of-mathematics-and-its-applications",
    urlone: "https://academic.oup.com/imatrm/pages/Author_Guidelines",
    urltwo: "NA"
  },
  {
    id: "transactions-of-the-american-fisheries-society",
    text: "Transactions of the American Fisheries Society",
    parent: "american-fisheries-society",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=utaf20&amp;page=instructions#.UpVAOUMju1E",
    urltwo: "NA"
  },
  {
    id: "transactions-of-the-american-philological-association",
    text: "Transactions of the American Philological Association",
    parent: "NA",
    urlone: "https://classicalstudies.org/sites/default/files/documents/TAPA_Style_Sheet.pdf",
    urltwo: "NA"
  },
  {
    id: "transactions-of-the-asabe",
    text: "Transactions of the ASABE",
    parent: "american-society-of-agricultural-and-biological-engineers",
    urlone: "https://doi.org/10.13031/issn.2151-0032",
    urltwo: "NA"
  },
  {
    id: "transactions-of-the-materials-research-society-of-japan",
    text: "Transactions of the Materials Research Society of Japan",
    parent: "NA",
    urlone: "http://mc.manuscriptcentral.com/mrsj",
    urltwo: "http://www.mrs-j.org/en/pub/index.php"
  },
  {
    id: "transactions-on-accessible-computing",
    text: "ACM Transactions on Accessible Computing",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-algorithms",
    text: "ACM Transactions on Algorithms",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-applied-perception",
    text: "ACM Transactions on Applied Perception",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-architecture-and-code-optimization",
    text: "ACM Transactions on Architecture and Code Optimization",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-asian-language-information-processing",
    text: "ACM Transactions on Asian Language Information Processing",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-autonomous-and-adaptive-systems",
    text: "ACM Transactions on Autonomous and Adaptive Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-computation-theory",
    text: "ACM Transactions on Computation Theory",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-computational-logic",
    text: "ACM Transactions on Computational Logic",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-computer-human-interaction",
    text: "ACM Transactions on Computer-Human Interaction",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-computer-systems",
    text: "ACM Transactions on Computer Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-computing-education",
    text: "ACM Transactions on Computing Education",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-database-systems",
    text: "ACM Transactions on Database Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-design-automation-of-electronic-systems",
    text: "ACM Transactions on Design Automation of Electronic Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-embedded-computing-systems",
    text: "ACM Transactions on Embedded Computing Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-graphics",
    text: "ACM Transactions on Graphics",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-information-and-system-security",
    text: "ACM Transactions on Information and System Security",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-information-systems",
    text: "ACM Transactions on Information Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-intelligent-systems-and-technology",
    text: "ACM Transactions on Intelligent Systems and Technology",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-interactive-intelligent-systems",
    text: "ACM Transactions on Interactive Intelligent Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-internet-technology",
    text: "ACM Transactions on Internet Technology",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-knowledge-discovery-from-data",
    text: "ACM Transactions on Knowledge Discovery from Data",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-management-information-systems",
    text: "ACM Transactions on Management Information Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-mathematical-software",
    text: "ACM Transactions on Mathematical Software",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-modeling-and-computer-simulation",
    text: "ACM Transactions on Modeling and Computer Simulation",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-multimedia-computing-communications-and-applications",
    text: "ACM Transactions on Multimedia Computing, Communications, and Applications",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-parallel-computing",
    text: "ACM Transactions on Parallel Computing",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-programming-languages-and-systems",
    text: "ACM Transactions on Programming Languages and Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-reconfigurable-technology-and-systems",
    text: "ACM Transactions on Reconfigurable Technology and Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-sensor-networks",
    text: "ACM Transactions on Sensor Networks",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-software-engineering-and-methodology",
    text: "ACM Transactions on Software Engineering and Methodology",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-spatial-algorithms-and-systems",
    text: "ACM Transactions on Spatial Algorithms and Systems",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-speech-and-language-processing",
    text: "ACM Transactions on Speech and Language Processing",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-storage",
    text: "ACM Transactions on Storage",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transactions-on-the-web",
    text: "ACM Transactions on the Web",
    parent: "association-for-computing-machinery",
    urlone: "https://www.acm.org/publications/authors/reference-formatting",
    urltwo: "NA"
  },
  {
    id: "transboundary-and-emerging-diseases",
    text: "Transboundary and Emerging Diseases",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1865-1682/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "transcription",
    text: "Transcription",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transfusion-and-apheresis-science",
    text: "Transfusion and Apheresis Science",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transfusion-clinique-et-biologique",
    text: "Transfusion clinique et biologique",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transfusion-medicine-and-hemotherapy",
    text: "Transfusion Medicine and Hemotherapy",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1660-3818",
    urltwo: "NA"
  },
  {
    id: "transfusion-medicine-reviews",
    text: "Transfusion Medicine Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transfusion",
    text: "Transfusion",
    parent: "vancouver-superscript",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291537-2995/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "transfusionsmedizin",
    text: "Transfusionsmedizin",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "transgenic-research",
    text: "Transgenic Research",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "transition-metal-chemistry",
    text: "Transition Metal Chemistry",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "translation-studies",
    text: "Translation Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RTRS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "translation",
    text: "Translation",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "translational-andrology-and-urology",
    text: "Translational Andrology and Urology",
    parent: "vancouver-superscript",
    urlone: "http://www.amepc.org/tau",
    urltwo: "NA"
  },
  {
    id: "translational-behavioral-medicine",
    text: "Translational Behavioral Medicine",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "translational-issues-in-psychological-science",
    text: "Translational Issues in Psychological Science",
    parent: "apa",
    urlone: "http://content.apa.org/journals/tps",
    urltwo: "NA"
  },
  {
    id: "translational-medicine-in-diabetes-lipids-and-cardiovascular-prevention",
    text: "Translational Medicine in Diabetes, Lipids and Cardiovascular Prevention",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "translational-medicine-of-aging",
    text: "Translational Medicine of Aging",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "translational-metabolic-syndrome-research",
    text: "Translational Metabolic Syndrome Research",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "translational-neurodegeneration",
    text: "Translational Neurodegeneration",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "translational-proteomics",
    text: "Translational Proteomics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "translational-psychiatry",
    text: "Translational Psychiatry",
    parent: "nature-publishing-group-vancouver",
    urlone: "http://www.nature.com/tp/TP_GTA.pdf",
    urltwo: "NA"
  },
  {
    id: "translational-research-in-anatomy",
    text: "Translational Research in Anatomy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "translational-research",
    text: "Translational Research",
    parent: "american-medical-association",
    urlone:
      "http://www.elsevier.com/journals/translational-research-the-journal-of-laboratory-and-clinical-medicine/1931-5244/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "translational-stroke-research",
    text: "Translational Stroke Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "transnational-environmental-law",
    text: "Transnational Environmental Law",
    parent: "NA",
    urlone:
      "https://www.cambridge.org/core/journals/transnational-environmental-law/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "transplant-immunology",
    text: "Transplant Immunology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transplantation-proceedings",
    text: "Transplantation Proceedings",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transplantation-reports",
    text: "Transplantation Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transplantation-research",
    text: "Transplantation Research",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "transplantation-reviews",
    text: "Transplantation Reviews",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transplantation",
    text: "Transplantation",
    parent: "NA",
    urlone: "http://journals.lww.com/transplantjournal/Documents/TP_IFA_online.pdf",
    urltwo: "NA"
  },
  {
    id: "transport-in-porous-media",
    text: "Transport in Porous Media",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "transport-policy",
    text: "Transport Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transport-reviews",
    text: "Transport Reviews",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TTRV20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "transport",
    text: "Transport",
    parent: "NA",
    urlone: "https://journals.vgtu.lt/index.php/Transport/guidelinesforauthors",
    urltwo: "NA"
  },
  {
    id: "transportation-geotechnics",
    text: "Transportation Geotechnics",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transportation-in-developing-economies",
    text: "Transportation in Developing Economies",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "transportation-infrastructure-geotechnology",
    text: "Transportation Infrastructure Geotechnology",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "transportation-planning-and-technology",
    text: "Transportation Planning and Technology",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=GTPT20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "transportation-research-interdisciplinary-perspectives",
    text: "Transportation Research Interdisciplinary Perspectives",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transportation-research-part-a",
    text: "Transportation Research Part A",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transportation-research-part-b",
    text: "Transportation Research Part B",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transportation-research-part-c",
    text: "Transportation Research Part C",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transportation-research-part-d",
    text: "Transportation Research Part D",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transportation-research-part-e",
    text: "Transportation Research Part E",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transportation-research-part-f-psychology-and-behaviour",
    text: "Transportation Research Part F: Psychology and Behaviour",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transportation-research-procedia",
    text: "Transportation Research Procedia",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "transportation-research-record",
    text: "Transportation Research Record: Journal of the Transportation Research Board",
    parent: "NA",
    urlone: "http://www.trb.org/GetInvolvedwithTRB/GetInvolvedSubmitaPaper.aspx",
    urltwo: "NA"
  },
  {
    id: "transportation-science",
    text: "Transportation Science",
    parent: "institute-for-operations-research-and-the-management-sciences",
    urlone: "http://pubsonline.informs.org/page/trsc/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "transportation",
    text: "Transportation",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "transportmetrica-a-transport-science",
    text: "Transportmetrica A: Transport Science",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TTRA20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "transportmetrica-b-transport-dynamics",
    text: "Transportmetrica B: Transport Dynamics",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TTRB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "transposition",
    text: "Transposition. Musique et sciences sociales",
    parent: "NA",
    urlone: "https://journals.openedition.org/transposition/1016",
    urltwo: "NA"
  },
  {
    id: "transversalites",
    text: "TransversalitÃ©s (French)",
    parent: "NA",
    urlone: "https://www.cairn.info/revue-transversalites.htm?contenu=apropos",
    urltwo: "https://icp.hypotheses.org/la-revue-transversalites/ecrire-dans-transversalites"
  },
  {
    id: "trauma-case-reports",
    text: "Trauma Case Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "trauma-und-berufskrankheit",
    text: "Trauma und Berufskrankheit (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/journal/10039?detailsPage=pltci_1756509",
    urltwo: "NA"
  },
  {
    id: "traumatology-an-international-journal",
    text: "Traumatology",
    parent: "apa",
    urlone: "http://content.apa.org/journals/trm",
    urltwo: "NA"
  },
  {
    id: "travaux-du-museum-national-dhistoire-naturelle-grigore-antipa",
    text: 'Travaux du MusÃ©um National d\'Histoire Naturelle "Grigore Antipa"',
    parent: "pensoft-journals",
    urlone: "https://travaux.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "travel-behaviour-and-society",
    text: "Travel Behaviour and Society",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "travel-medicine-and-infectious-disease",
    text: "Travel Medicine and Infectious Disease",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tree-genetics-and-genomes",
    text: "Tree Genetics &amp; Genomes",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tree-physiology",
    text: "Tree Physiology",
    parent: "NA",
    urlone: "http://www.oxfordjournals.org.turing.library.northwestern.edu/our_journals/treephys/for_authors/",
    urltwo: "NA"
  },
  {
    id: "trees",
    text: "Trees",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "trends-in-anaesthesia-and-critical-care",
    text: "Trends in Anaesthesia and Critical Care",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "trends-in-analytical-chemistry",
    text: "Trends in Analytical Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "trends-in-biochemical-sciences",
    text: "Trends in Biochemical Sciences",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/biochemical-sciences",
    urltwo: "NA"
  },
  {
    id: "trends-in-biotechnology",
    text: "Trends in Biotechnology",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/biotechnology",
    urltwo: "NA"
  },
  {
    id: "trends-in-cardiovascular-medicine",
    text: "Trends in Cardiovascular Medicine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "trends-in-cell-biology",
    text: "Trends in Cell Biology",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/cell-biology",
    urltwo: "NA"
  },
  {
    id: "trends-in-cognitive-sciences",
    text: "Trends in Cognitive Sciences",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/cognitive-sciences",
    urltwo: "NA"
  },
  {
    id: "trends-in-ecology-and-evolution",
    text: "Trends in Ecology &amp; Evolution",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/ecology-evolution",
    urltwo: "NA"
  },
  {
    id: "trends-in-endocrinology-and-metabolism",
    text: "Trends in Endocrinology &amp; Metabolism",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/endocrinology-metabolism",
    urltwo: "NA"
  },
  {
    id: "trends-in-environmental-analytical-chemistry",
    text: "Trends in Environmental Analytical Chemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "trends-in-food-science-and-technology",
    text: "Trends in Food Science &amp; Technology",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "trends-in-genetics",
    text: "Trends in Genetics",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/genetics",
    urltwo: "NA"
  },
  {
    id: "trends-in-glycoscience-and-glycotechnology",
    text: "Trends in Glycoscience and Glycotechnology",
    parent: "NA",
    urlone: "http://www.fcca.gr.jp/TIGG/Guide.html",
    urltwo: "NA"
  },
  {
    id: "trends-in-immunology",
    text: "Trends in Immunology",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/immunology",
    urltwo: "NA"
  },
  {
    id: "trends-in-microbiology",
    text: "Trends in Microbiology",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/microbiology",
    urltwo: "NA"
  },
  {
    id: "trends-in-molecular-medicine",
    text: "Trends in Molecular Medicine",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/molecular-medicine",
    urltwo: "NA"
  },
  {
    id: "trends-in-neuroscience-and-education",
    text: "Trends in Neuroscience and Education",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "trends-in-neurosciences",
    text: "Trends in Neurosciences",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/neurosciences",
    urltwo: "NA"
  },
  {
    id: "trends-in-organized-crime",
    text: "Trends in Organized Crime",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "trends-in-parasitology",
    text: "Trends in Parasitology",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/parasitology",
    urltwo: "NA"
  },
  {
    id: "trends-in-pharmacological-sciences",
    text: "Trends in Pharmacological Sciences",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/pharmacological-sciences",
    urltwo: "NA"
  },
  {
    id: "trends-in-plant-science",
    text: "Trends in Plant Science",
    parent: "trends-journals",
    urlone: "http://www.cell.com/trends/plant-science",
    urltwo: "NA"
  },
  {
    id: "trends-journals",
    text: "Trends journals",
    parent: "NA",
    urlone: "http://www.cell.com/trends/ecology-evolution/authors",
    urltwo: "http://www.cell.com/cellpress/trends"
  },
  {
    id: "trials",
    text: "Trials",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "triangle",
    text: "Triangle (French)",
    parent: "NA",
    urlone: "http://triangle.ens-lyon.fr/spip.php?article2179",
    urltwo: "NA"
  },
  {
    id: "tribology-international",
    text: "Tribology International",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tribology-letters",
    text: "Tribology Letters",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "triple-helix",
    text: "Triple Helix",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tropical-animal-health-and-production",
    text: "Tropical Animal Health and Production",
    parent: "NA",
    urlone: "http://www.springer.com/life+sciences/animal+sciences/journal/11250",
    urltwo: "NA"
  },
  {
    id: "tropical-cyclone-research-and-review",
    text: "Tropical Cyclone Research and Review",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tropical-diseases-travel-medicine-and-vaccines",
    text: "Tropical Diseases, Travel Medicine and Vaccines",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tropical-doctor",
    text: "Tropical Doctor",
    parent: "sage-vancouver",
    urlone: "http://www.sagepub.com/journals/Journal202201#tabview=manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "tropical-gastroenterology",
    text: "Tropical Gastroenterology",
    parent: "vancouver-superscript",
    urlone: "http://www.tropicalgastro.com/instructions.aspx",
    urltwo: "NA"
  },
  {
    id: "tropical-medicine-and-infectious-disease",
    text: "Tropical Medicine and Infectious Disease",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "tropical-plant-biology",
    text: "Tropical Plant Biology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tropical-plant-pathology",
    text: "Tropical Plant Pathology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tsagi-science-journal",
    text: "TsAGI Science Journal",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/58618e1439159b1f.html",
    urltwo: "NA"
  },
  {
    id: "tsaqafah",
    text: "Tsaqafah",
    parent: "NA",
    urlone: "http://ejournal.unida.gontor.ac.id/index.php/tsaqafah/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "tsinghua-science-and-technology",
    text: "Tsinghua Science and Technology",
    parent: "ieee",
    urlone: "http://ieeexplore.ieee.org/servlet/opac?punumber=5971803",
    urltwo: "NA"
  },
  {
    id: "tuberculosis",
    text: "Tuberculosis",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "tumor-biology",
    text: "Tumor Biology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "tumordiagnostik-and-therapie",
    text: "TumorDiagnostik &amp; Therapie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "tunnelling-and-underground-space-technology-incorporating-trenchless-technology-research",
    text: "Tunnelling and Underground Space Technology incorporating Trenchless Technology Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "turabian-author-date",
    text: "Turabian 9th edition (author-date)",
    parent: "NA",
    urlone: "http://www.chicagomanualofstyle.org/tools_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "turabian-fullnote-bibliography",
    text: "Turabian 8th edition (full note)",
    parent: "NA",
    urlone: "http://www.press.uchicago.edu/books/turabian/turabian_citationguide.html",
    urltwo: "NA"
  },
  {
    id: "turkish-journal-of-emergency-medicine",
    text: "Turkish Journal of Emergency Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "turkish-studies",
    text: "Turkish Studies",
    parent: "chicago-note-bibliography",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=FTUR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "turkiye-bilimsel-ve-teknolojik-arastirma-kurumu",
    text: "TÃœBÄ°TAK (TÃ¼rkiye Bilimsel ve Teknolojik AraÅŸtÄ±rma Kurumu) (Turkish)",
    parent: "NA",
    urlone: "http://www.tubitak.gov.tr/tr/duyuru/bibliyografik-verilerin-duzenlenmesi",
    urltwo: "NA"
  },
  {
    id: "twentieth-century-music",
    text: "Twentieth-Century Music",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/journals/twentieth-century-music/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "tzu-chi-medical-journal",
    text: "Tzu Chi Medical Journal",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "u-schylku-starozytnosci",
    text: "U SchyÅ‚ku StaroÅ¼ytnoÅ›ci (Polish)",
    parent: "NA",
    urlone: "http://www.uss.uw.edu.pl/instrukcja.html",
    urltwo: "NA"
  },
  {
    id: "ucl-institute-of-education-harvard",
    text: "UCL Institute of Education - Harvard",
    parent: "NA",
    urlone: "https://libguides.ioe.ac.uk/harvard",
    urltwo: "NA"
  },
  {
    id: "ucl-university-college-apa",
    text: "UCL University College - APA",
    parent: "NA",
    urlone: "https://sites.google.com/ucl.dk/ucl-formelle-krav/apa",
    urltwo: "NA"
  },
  {
    id: "ucl-university-college-harvard",
    text: "UCL University College - Harvard (Danish)",
    parent: "NA",
    urlone: "https://sites.google.com/ucl.dk/ucl-formelle-krav/harvard",
    urltwo: "NA"
  },
  {
    id: "ucl-university-college-vancouver",
    text: "UCL University College - Vancouver (English)",
    parent: "NA",
    urlone: "https://sites.google.com/ucl.dk/ucl-formelle-krav/vancouver",
    urltwo: "NA"
  },
  {
    id: "uclouvain-centre-charles-de-visscher-pour-le-droit-international-et-europeen",
    text: "UCLouvain - Centre Charles De Visscher pour le droit international et europÃ©en (French)",
    parent: "NA",
    urlone: "https://alfresco.uclouvain.be/share/s/7mfExtQGQv-WRhCoBGoIww",
    urltwo: "NA"
  },
  {
    id: "ugeskrift-for-laeger",
    text: "Ugeskrift for LÃ¦ger (Danish)",
    parent: "NA",
    urlone: "http://ugeskriftet.dk/manuskriptvejledning-0",
    urltwo: "NA"
  },
  {
    id: "ultramicroscopy",
    text: "Ultramicroscopy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ultrasonics-sonochemistry",
    text: "Ultrasonics - Sonochemistry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ultrasonics",
    text: "Ultrasonics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "ultrasound-in-medicine-and-biology",
    text: "Ultrasound in Medicine and Biology",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/ultrasound-in-medicine-and-biology/0301-5629/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "ultrasound-in-obstetrics-and-gynecology",
    text: "Ultrasound in Obstetrics &amp; Gynecology",
    parent: "american-medical-association-no-et-al",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/(ISSN)1469-0705/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "ultrasound-international-open",
    text: "Ultrasound International Open",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "uludag-universitesi-sosyal-bilimler-enstitusu-author-date",
    text: "UludaÄŸ Ãœniversitesi - Sosyal Bilimler EnstitÃ¼sÃ¼ (author-date, Turkish)",
    parent: "NA",
    urlone:
      "http://www.uludag.edu.tr/dosyalar/sosyalbilimler/2016%20Duyurular/SBE%20TEZ%20YAZ.%20KIL.(YEN%C4%B0)%2023.09.2016%20(1).pdf",
    urltwo: "NA"
  },
  {
    id: "uludag-universitesi-sosyal-bilimler-enstitusu-full-note-with-ibid",
    text: "UludaÄŸ Ãœniversitesi - Sosyal Bilimler EnstitÃ¼sÃ¼ (full note, with Ibid., Turkish)",
    parent: "NA",
    urlone:
      "http://www.uludag.edu.tr/dosyalar/sosyalbilimler/2017%20Duyrular/SBE%20TEZ%20YAZ.%20KIL.(22%20Kas%C4%B1m%202017).pdf",
    urltwo: "NA"
  },
  {
    id: "uludag-universitesi-sosyal-bilimler-enstitusu-full-note",
    text: "UludaÄŸ Ãœniversitesi - Sosyal Bilimler EnstitÃ¼sÃ¼ (full note, Turkish)",
    parent: "NA",
    urlone:
      "http://www.uludag.edu.tr/dosyalar/sosyalbilimler/2017%20Duyrular/SBE%20TEZ%20YAZ.%20KIL.(22%20Kas%C4%B1m%202017).pdf",
    urltwo: "NA"
  },
  {
    id: "uludag-universitesi-sosyal-bilimler-enstitusu-ilahiyat-fakultesi-full-note-with-ibid",
    text: "UludaÄŸ Ãœniversitesi - Sosyal Bilimler EnstitÃ¼sÃ¼ - Ä°lahiyat FakÃ¼ltesi (full note, with Ibid., Turkish)",
    parent: "NA",
    urlone:
      "http://www.uludag.edu.tr/dosyalar/sosyalbilimler/2017%20Duyrular/SBE%20TEZ%20YAZ.%20KIL.(22%20Kas%C4%B1m%202017).pdf",
    urltwo: "NA"
  },
  {
    id: "uludag-universitesi-sosyal-bilimler-enstitusu-ilahiyat-fakultesi-full-note",
    text: "UludaÄŸ Ãœniversitesi - Sosyal Bilimler EnstitÃ¼sÃ¼ - Ä°lahiyat FakÃ¼ltesi (full note, Turkish)",
    parent: "NA",
    urlone:
      "http://www.uludag.edu.tr/dosyalar/sosyalbilimler/2017%20Duyrular/SBE%20TEZ%20YAZ.%20KIL.(22%20Kas%C4%B1m%202017).pdf",
    urltwo: "NA"
  },
  {
    id: "ulusal-travma-ve-acil-cerrahi-dergisi",
    text: "Ulusal Travma ve Acil Cerrahi Dergisi (Turkish Journal of Trauma &amp; Emergency Surgery)",
    parent: "vancouver-superscript-brackets-only-year",
    urlone: "http://www.tjtes.org/eng/?sayfa=ybilgi",
    urltwo: "NA"
  },
  {
    id: "umk-procedia",
    text: "UMK Procedia",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "underground-space",
    text: "Underground Space",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "undersea-and-hyperbaric-medicine-journal",
    text: "Undersea &amp; Hyperbaric Medicine Journal",
    parent: "vancouver",
    urlone: "http://membership.uhms.org/?Journal",
    urltwo: "NA"
  },
  {
    id: "unified-style-sheet-for-linguistics-de-gruyter-literature",
    text: "Unified Stylesheet for Linguistics (de Gruyter Literature)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "unified-style-sheet-for-linguistics",
    text: "Unified style sheet for linguistics",
    parent: "NA",
    urlone: "https://www.linguisticsociety.org/sites/default/files/style-sheet_0.pdf",
    urltwo: "NA"
  },
  {
    id: "united-nations-conference-on-trade-and-development",
    text: "United Nations Conference on Trade and Development",
    parent: "NA",
    urlone: "http://unctad.org",
    urltwo: "NA"
  },
  {
    id: "united-nations-development-programme-icca-legal-review",
    text: "United Nations Development Programme ICCA Legal Review",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/issues/4541",
    urltwo: "NA"
  },
  {
    id: "united-nations-framework-convention-on-climate-change",
    text: "United Nations Framework Convention on Climate Change",
    parent: "NA",
    urlone: "https://sia.unfccc.int/publishing/Assets/PR-000010/StreamAssets/UNFCCC_style_guide.pdf",
    urltwo: "https://1drv.ms/b/s!At1gkUFao0OFhVCuo98cTKWkrt8j"
  },
  {
    id: "universal-access-in-the-information-society",
    text: "Universal Access in the Information Society",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "universe",
    text: "Universe",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "universidad-autonoma-cidudad-juarez-estilo-latino-humanistico",
    text: "Universidad AutÃ³noma de Ciudad JuÃ¡rez - Estilo Latino HumanÃ­stico",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/commit/d97e37b92d48c2d1a2511c40ae689f105fe3dffc",
    urltwo: "NA"
  },
  {
    id: "universidad-de-leon-harvard",
    text: "Universidad de LeÃ³n (EspaÃ±a) - Harvard",
    parent: "NA",
    urlone: "http://buleria.unileon.es/handle/10612/5930",
    urltwo: "NA"
  },
  {
    id: "universidad-evangelica-del-paraguay",
    text: "Universidad EvangÃ©lica del Paraguay (Spanish)",
    parent: "NA",
    urlone: "http://www.universidadevangelica.edu.py/",
    urltwo: "NA"
  },
  {
    id: "universidade-de-sao-paulo-escola-de-comunicacoes-e-artes-abnt",
    text: "Universidade de SÃ£o Paulo - Escola de ComunicaÃ§Ãµes e Artes - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://www.abntcatalogo.com.br/norma.aspx?ID=408006",
    urltwo: "NA"
  },
  {
    id: "universidade-de-sao-paulo-instituto-de-matematica-e-estatistica",
    text: "Universidade de SÃ£o Paulo - Instituto de MatemÃ¡tica e EstatÃ­stica",
    parent: "NA",
    urlone: "http://tese-exemplo.googlecode.com/files/tese-exemplo-plainnat-ime.pdf",
    urltwo: "NA"
  },
  {
    id: "universidade-do-porto-faculdade-de-engenharia-chicago",
    text: "Universidade do Porto - Faculdade de Engenharia - Chicago Manual of Style",
    parent: "NA",
    urlone: "https://feup.libguides.com/chicago",
    urltwo: "NA"
  },
  {
    id: "universidade-do-porto-faculdade-de-psicologia-e-de-ciencias-da-educacao",
    text: "Universidade do Porto - Faculdade de Psicologia e de CiÃªncias da EducaÃ§Ã£o - APA",
    parent: "NA",
    urlone:
      "https://www.fpce.up.pt/ciie/?q=publication/revista-educa%C3%A7%C3%A3o-sociedade-culturas/page/instrucoes-aos-autores",
    urltwo: "NA"
  },
  {
    id: "universidade-estadual-de-alagoas-abnt",
    text: "Universidade Estadual de Alagoas - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://www.uneal.edu.br/pro-reitorias/prograd/regulamento-do-tcc-uneal.pdf",
    urltwo: "NA"
  },
  {
    id: "universidade-estadual-do-oeste-do-parana-programa-institucional-de-bolsas-de-iniciacao-cientifica",
    text:
      "Universidade Estadual do Oeste do ParanÃ¡ - Programa Institucional de Bolsas de IniciaÃ§Ã£o CientÃ­fica (Portuguese - Brazil)",
    parent: "NA",
    urlone: "labceuunioeste.wix.com/unioeste",
    urltwo: "NA"
  },
  {
    id: "universidade-estadual-paulista-campus-de-dracena-abnt",
    text: "Universidade Estadual Paulista - Campus de Dracena - ABNT (autoria abreviada) (Portuguese - Brazil)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "universidade-federal-de-juiz-de-fora",
    text: "Universidade Federal de Juiz de Fora (Portuguese - Brazil)",
    parent: "NA",
    urlone: "www.ufjf.br/engenhariadeproducao/files/2010/05/manual_para_elaboracao_TCC.pdf",
    urltwo: "NA"
  },
  {
    id: "universidade-federal-de-pernambuco-abnt",
    text: "Universidade Federal de Pernambuco - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/4798#issuecomment-629918226",
    urltwo: "NA"
  },
  {
    id: "universidade-federal-de-sergipe-departamento-de-engenharia-de-producao-abnt",
    text: "Universidade Federal de Sergipe - Departamento de Engenharia de ProduÃ§Ã£o - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://depro.ufs.br/pagina/22237",
    urltwo: "NA"
  },
  {
    id: "universidade-federal-do-espirito-santo-abnt-initials",
    text: "Universidade Federal do EspÃ­rito Santo - ABNT (autoria abreviada) (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://repositorio.ufes.br/handle/10/1532",
    urltwo: "NA"
  },
  {
    id: "universidade-federal-do-espirito-santo-abnt",
    text: "Universidade Federal do EspÃ­rito Santo - ABNT (autoria completa) (Portuguese - Brazil)",
    parent: "NA",
    urlone: "http://repositorio.ufes.br/handle/10/1532",
    urltwo: "NA"
  },
  {
    id:
      "universidade-federal-do-rio-de-janeiro-instituto-alberto-luiz-coimbra-de-pos-graduacao-e-pesquisa-de-engenharia-abnt",
    text:
      "Universidade Federal do Rio de Janeiro - Instituto Alberto Luiz Coimbra de PÃ³s-GraduaÃ§Ã£o e Pesquisa de Engenharia - ABNT (Portuguese - Brazil)",
    parent: "NA",
    urlone: "https://www.abntcatalogo.com.br/norma.aspx?ID=408006",
    urltwo: "https://coppe.ufrj.br/sites/default/files/arquivo_cpgp/formato.pdf"
  },
  {
    id: "universita-cattolica-del-sacro-cuore",
    text: "UniversitÃ  Cattolica del Sacro Cuore (note, Italian)",
    parent: "NA",
    urlone: "http://www.unicatt.it/",
    urltwo: "NA"
  },
  {
    id: "universita-di-bologna-lettere",
    text:
      "University of Bologna - Liberal Arts College (UniversitÃ  di Bologna - FacoltÃ  di Lettere e Filosofia) (Italian)",
    parent: "NA",
    urlone: "http://corsi.unibo.it/lettere/Pagine/norme-redazionali-tesi-triennale.aspx",
    urltwo: "NA"
  },
  {
    id: "universita-pontificia-salesiana-en",
    text: "UniversitÃ  Pontificia Salesiana (English)",
    parent: "universita-pontificia-salesiana",
    urlone: "http://www.unisal.it",
    urltwo: "http://www.worldcat.org/oclc/868533390"
  },
  {
    id: "universita-pontificia-salesiana-es",
    text: "UniversitÃ  Pontificia Salesiana (Spanish)",
    parent: "universita-pontificia-salesiana",
    urlone: "http://www.unisal.it",
    urltwo: "http://www.worldcat.org/oclc/868533390"
  },
  {
    id: "universita-pontificia-salesiana-fr",
    text: "UniversitÃ  Pontificia Salesiana (French)",
    parent: "universita-pontificia-salesiana",
    urlone: "http://www.unisal.it",
    urltwo: "http://www.worldcat.org/oclc/868533390"
  },
  {
    id: "universita-pontificia-salesiana-it",
    text: "UniversitÃ  Pontificia Salesiana (Italian)",
    parent: "NA",
    urlone: "http://www.unisal.it",
    urltwo: "http://www.worldcat.org/oclc/868533390"
  },
  {
    id: "universita-pontificia-salesiana-pt-br",
    text: "UniversitÃ  Pontificia Salesiana (Portuguese - Brazil)",
    parent: "universita-pontificia-salesiana",
    urlone: "http://www.unisal.it",
    urltwo: "http://www.worldcat.org/oclc/868533390"
  },
  {
    id: "universita-pontificia-salesiana",
    text: "UniversitÃ  Pontificia Salesiana",
    parent: "NA",
    urlone: "http://www.unisal.it",
    urltwo: "http://www.worldcat.org/oclc/868533390"
  },
  {
    id: "universitas-gadjah-mada-departemen-sejarah",
    text: "Universitas Gadjah Mada - Departemen Sejarah (Indonesian)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "universitas-negeri-semarang-fakultas-matematika-dan-ilmu-pengetahuan-alam",
    text: "Universitas Negeri Semarang - Fakultas Matematika dan Ilmu Pengetahuan Alam (Indonesian)",
    parent: "NA",
    urlone: "http://fisika.unnes.ac.id/wp-content/uploads/2010/03/Panduan-Penulisan-Skripsi-TA-Artikel-rev-2014.pdf",
    urltwo: "NA"
  },
  {
    id: "universitas-negeri-yogyakarta-program-pascasarjana",
    text: "Universitas Negeri Yogyakarta - Program Pascasarjana (Indonesian)",
    parent: "NA",
    urlone: "http://pps.uny.ac.id/",
    urltwo:
      "https://github.com/citation-style-language/styles/files/1385103/Pedoman.Tesis.dan.Disertasi.2017.revisi.pdf"
  },
  {
    id: "universitat-basel-deutsche-sprachwissenschaft",
    text: "UniversitÃ¤t Basel - Deutsche Sprachwissenschaft",
    parent: "NA",
    urlone:
      "https://germanistik.philhist.unibas.ch/fileadmin/user_upload/germanistik/Merkblaetter_und_Dokumente/Leitfaden_DeutscheSprachwissenschaft_HS18._Kopie.pdf",
    urltwo: "NA"
  },
  {
    id: "universitat-basel-iberoromanistik",
    text: "UniversitÃ¤t Basel - Iberoromanistik (Spanish)",
    parent: "NA",
    urlone:
      "https://iberoromanistik.philhist.unibas.ch/fileadmin/user_upload/iberoromanistik/pdf-Dokumente/documentos_download/Normas_trabajos_escritos_7a_ed_2018.pdf",
    urltwo: "NA"
  },
  {
    id: "universitat-bern-institut-fur-musikwissenschaft-note",
    text: "UniversitÃ¤t Bern - Institut fÃ¼r Musikwissenschaft (note, German)",
    parent: "NA",
    urlone:
      "https://www.musik.unibe.ch/unibe/portal/fak_historisch/dkk/musik/content/e39903/e561201/e561204/Wissenschaftliches_Arbeiten_Bern_20171208_ger.pdf",
    urltwo:
      "https://www.musik.unibe.ch/unibe/portal/fak_historisch/dkk/musik/content/e39903/e561201/e884863/Zotero-ZitierstilIMWUBBern-ReadMe20190612_ger.pdf"
  },
  {
    id: "universitat-bern-institut-fur-sozialanthropologie",
    text: "UniversitÃ¤t Bern - Institut fÃ¼r Sozialanthropologie (German - Switzerland)",
    parent: "NA",
    urlone:
      "https://www.anthro.unibe.ch/unibe/portal/fak_historisch/dkk/anthro/content/e40416/e176505/section176510/files176511/Zitieren_ger.pdf",
    urltwo: "NA"
  },
  {
    id: "universitat-bremen-institut-fur-politikwissenschaft",
    text: "UniversitÃ¤t Bremen - Institut fÃ¼r Politikwissenschaft (German)",
    parent: "NA",
    urlone: "http://www.politik.uni-bremen.de/ccm/cms-service/download/asset/Kompendium.pdf?asset_id=914048",
    urltwo: "NA"
  },
  {
    id: "universitat-bremen-lehrstuhl-fur-innovatives-markenmanagement",
    text: "UniversitÃ¤t Bremen - Lehrstuhl fÃ¼r innovatives Markenmanagement (German)",
    parent: "NA",
    urlone:
      "http://www.lim.uni-bremen.de/de/richtlinien?file=files/burmann/lehre/Richtlinien_Abschlussarbeiten_Stand_2015_11.pdf",
    urltwo: "NA"
  },
  {
    id: "universitat-freiburg-geschichte",
    text: "Albert-Ludwigs-UniversitÃ¤t Freiburg - Geschichte (German)",
    parent: "NA",
    urlone: "https://mittelalter2.geschichte.uni-freiburg.de/lehre/proseminarheft-mittelalter",
    urltwo: "NA"
  },
  {
    id: "universitat-heidelberg-historisches-seminar",
    text: "UniversitÃ¤t Heidelberg - Historisches Seminar (German)",
    parent: "NA",
    urlone: "http://www.uni-heidelberg.de/fakultaeten/philosophie/zegk/histsem/index.html",
    urltwo: "NA"
  },
  {
    id: "universitat-heidelberg-medizinische-fakultat-mannheim-numeric",
    text: "UniversitÃ¤t Heidelberg - Medizinische FakultÃ¤t Mannheim (numerisch, German)",
    parent: "NA",
    urlone: "https://www.umm.uni-heidelberg.de/fakultaet/promotion/promotion-dr-med/",
    urltwo: "NA"
  },
  {
    id: "universitat-mainz-geographisches-institut",
    text: "UniversitÃ¤t Mainz - Geographisches Institut (German)",
    parent: "NA",
    urlone: "http://www.scribd.com/doc/143090340/00000-Wissenschaftlich-Arbeiten-2013",
    urltwo: "NA"
  },
  {
    id: "universitat-stuttgart-planung-und-partizipation",
    text: "UniversitÃ¤t Stuttgart - Planung und Partizipation (German)",
    parent: "NA",
    urlone:
      "https://www.ivr.uni-stuttgart.de/recht/lehre/m.-sc.-planung-und-partizipation/MPP-Standards-Stand-Oktober-2018.pdf",
    urltwo: "NA"
  },
  {
    id: "universitat-wien-institut-fur-geschichte",
    text: "UniversitÃ¤t Wien - Institut fÃ¼r Geschichte",
    parent: "NA",
    urlone: "http://ifg.univie.ac.at/studium/wissenschaftliches-arbeiten/",
    urltwo: "NA"
  },
  {
    id: "universitat-zu-koln-seminar-fur-abwl-und-finanzierungslehre",
    text: "UniversitÃ¤t zu KÃ¶ln - Seminar fÃ¼r ABWL und Finanzierungslehre (German)",
    parent: "NA",
    urlone:
      "http://www.finance.uni-koeln.de/sites/finanzierungslehre/documents/studium/Leitfaden/Anleitung_fuer_wissenschaftliches_Arbeiten-06_15.pdf",
    urltwo: "NA"
  },
  {
    id: "universitatsmedizin-gottingen",
    text: "UniversitÃ¤tsmedizin GÃ¶ttingen (German)",
    parent: "NA",
    urlone: "https://www.umg.eu/studium-lehre/promotion/downloads-promotion/",
    urltwo: "NA"
  },
  {
    id: "universite-catholique-de-louvain-histoire",
    text: "UniversitÃ© Catholique de Louvain - Histoire (French)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "universite-de-bordeaux-ecole-doctorale-de-droit",
    text: "UniversitÃ© de Bordeaux - Ã‰cole doctorale de droit (French)",
    parent: "NA",
    urlone: "https://hal.archives-ouvertes.fr/hal-02151987",
    urltwo: "https://documentation-style-csl-ed-droit-ubx.readthedocs.io"
  },
  {
    id: "universite-de-lausanne-histoire",
    text: "UniversitÃ© de Lausanne - Histoire (French)",
    parent: "NA",
    urlone: "https://lausannecitationstyle.github.io/support/",
    urltwo: "NA"
  },
  {
    id: "universite-de-liege-droit-par-categorie",
    text: "UniversitÃ© de LiÃ¨ge - Droit (classement par catÃ©gorie, French)",
    parent: "NA",
    urlone: "https://lib.uliege.be/fr/libraries/graulich?qt-quicktab_library=6#qt-quicktab_library",
    urltwo: "NA"
  },
  {
    id: "universite-de-liege-droit",
    text: "UniversitÃ© de LiÃ¨ge - Droit (French)",
    parent: "NA",
    urlone: "https://lib.uliege.be/fr/libraries/graulich?qt-quicktab_library=6#qt-quicktab_library",
    urltwo: "NA"
  },
  {
    id: "universite-de-liege-histoire",
    text: "UniversitÃ© de LiÃ¨ge - Histoire (French)",
    parent: "NA",
    urlone: "http://www.ulg.ac.be/",
    urltwo: "NA"
  },
  {
    id: "universite-de-montreal-apa",
    text: "UniversitÃ© de MontrÃ©al - APA (French - Canada)",
    parent: "NA",
    urlone: "https://bib.umontreal.ca/citer/apa",
    urltwo: "NA"
  },
  {
    id: "universite-de-montreal-faculte-de-musique",
    text: "UniversitÃ© de MontrÃ©al - FacultÃ© de musique (French - Canada)",
    parent: "NA",
    urlone: "http://guides.bib.umontreal.ca/disciplines/149-Musique?tab=620",
    urltwo: "NA"
  },
  {
    id: "universite-de-picardie-jules-verne-ufr-de-medecine",
    text: "UniversitÃ© de Picardie Jules Verne (Amiens) - ThÃ¨se de UFR de MÃ©decine",
    parent: "NA",
    urlone: "www.u-picardie.fr/medecine",
    urltwo: "NA"
  },
  {
    id: "universite-de-sherbrooke-departement-de-geomatique",
    text: "UniversitÃ© de Sherbrooke - DÃ©partement de gÃ©omatique (French - Canada)",
    parent: "NA",
    urlone: "http://www.usherbrooke.ca/geomatique/fileadmin/sites/geomatique/espace-etudiant/guide-redaction2004.pdf",
    urltwo: "NA"
  },
  {
    id: "universite-de-sherbrooke-faculte-d-education",
    text: "UniversitÃ© de Sherbrooke - FacultÃ© d'Ã©ducation (French - Canada)",
    parent: "NA",
    urlone:
      "http://www.usherbrooke.ca/education/fileadmin/sites/education/documents/Intranet/Documents_officiels/GuidePresentation_complet.pdf",
    urltwo: "NA"
  },
  {
    id: "universite-du-quebec-a-montreal-departement-dhistoire",
    text: "UniversitÃ© du QuÃ©bec Ã  MontrÃ©al - DÃ©partement dâ€™histoire (French - Canada)",
    parent: "NA",
    urlone: "https://histoire.uqam.ca/wp-content/uploads/sites/21/Nouveau_Guide_Premier_Cycle_decembre_2014.pdf",
    urltwo: "NA"
  },
  {
    id: "universite-du-quebec-a-montreal-etudes-litteraires-et-semiologie",
    text: "UniversitÃ© du QuÃ©bec Ã  MontrÃ©al - Ã‰tudes littÃ©raires et sÃ©miologie (French - Canada)",
    parent: "NA",
    urlone: "https://litterature.uqam.ca/wp-content/uploads/sites/52/prsentation_et_mthodologie_modifi.pdf",
    urltwo: "NA"
  },
  {
    id: "universite-du-quebec-a-montreal",
    text: "UniversitÃ© du QuÃ©bec Ã  MontrÃ©al (French - Canada)",
    parent: "NA",
    urlone: "http://guides.bibliotheques.uqam.ca/themes/105-Logiciels-de-gestion-bibliographique?tab=563",
    urltwo: "http://www.guidemt.uqam.ca/citer/style-uqam"
  },
  {
    id: "universite-laval-departement-des-sciences-historiques",
    text: "UniversitÃ© Laval - DÃ©partement des sciences historiques (French - Canada)",
    parent: "NA",
    urlone: "http://www.hst.ulaval.ca/services-et-ressources/guides-pedagogiques/",
    urltwo: "NA"
  },
  {
    id: "universite-laval-departement-dinformation-et-de-communication",
    text: "UniversitÃ© Laval - DÃ©partement d'information et de communication (French - Canada)",
    parent: "NA",
    urlone: "http://www.com.ulaval.ca/etudes/boite-a-outils/guide-de-redaction/",
    urltwo: "NA"
  },
  {
    id: "universite-laval-faculte-de-theologie-et-de-sciences-religieuses",
    text: "UniversitÃ© Laval - FacultÃ© de thÃ©ologie et de sciences religieuses (French - Canada)",
    parent: "NA",
    urlone: "http://www.ftsr.ulaval.ca",
    urltwo: "NA"
  },
  {
    id: "universite-libre-de-bruxelles-histoire",
    text: "UniversitÃ© libre de Bruxelles - Histoire (French)",
    parent: "NA",
    urlone: "https://www.dropbox.com/s/5p4jzir9ie0n54q/Guide_presentation_travail_histoire_2011-2012.pdf",
    urltwo: "NA"
  },
  {
    id: "universite-nangui-abrogoua-sciences-de-la-nature-apa",
    text: "UniversitÃ© Nangui Abrogoua - Sciences de la Nature - APA (French)",
    parent: "NA",
    urlone:
      "https://github.com/EhoumanEvans/CitationStyle/raw/master/universite_nangui_abrogoua_ufr_sn_citation_style.pdf",
    urltwo: "NA"
  },
  {
    id: "universite-paris-diderot-ufr-detudes-psychanalytiques",
    text: "UniversitÃ© Paris Diderot - DÃ©partement d'Ã©tudes psychanalytiques (French)",
    parent: "guide-pour-la-redaction-et-la-presentation-des-theses-a-lusage-des-doctorants",
    urlone:
      "https://bibliotheque.univ-paris-diderot.fr/sites/default/files/fichiers/guide_doct_ministere_education.pdf",
    urltwo: "NA"
  },
  {
    id: "universiteit-utrecht-onderzoeksgids-geschiedenis",
    text: "Universiteit Utrecht - Onderzoeksgids Geschiedenis (Dutch)",
    parent: "NA",
    urlone: "http://www.let.uu.nl/ong",
    urltwo: "NA"
  },
  {
    id: "universitetet-i-oslo-rettsvitenskap",
    text: "Universitetet i Oslo - Rettsvitenskap (Norwegian - BokmÃ¥l)",
    parent: "NA",
    urlone: "http://www.ub.uio.no/skrive-publisere/referere/referansestiler/rettsvitenskap.html",
    urltwo: "NA"
  },
  {
    id: "universiti-kebangsaan-malaysia",
    text: "Universiti Kebangsaan Malaysia (Malay)",
    parent: "NA",
    urlone: "http://www.ukm.my/pusatsiswazah/templat-gayaukm/",
    urltwo: "NA"
  },
  {
    id: "university-college-cork-harvard",
    text: "University College Cork - Harvard",
    parent: "harvard-anglia-ruskin-university",
    urlone: "https://uccdh.com/referencing-styles-in-digital-arts-humanities/",
    urltwo: "NA"
  },
  {
    id: "university-college-dublin-school-of-history-and-archives",
    text: "University College Dublin - School of History &amp; Archives",
    parent: "NA",
    urlone: "http://www.ucd.ie/historyarchives/undergraduateprogrammes/essayssubmissions/stylesheet/",
    urltwo: "NA"
  },
  {
    id: "university-college-lillebaelt-apa",
    text: "University College LillebÃ¦lt - APA (Danish)",
    parent: "NA",
    urlone: "http://esdhweb.ucl.dk/476790.Formm%C3%A6ssige%20krav%202014.pdf",
    urltwo: "NA"
  },
  {
    id: "university-for-the-creative-arts-figures",
    text: "University for the Creative Arts (figures and illustrations)",
    parent: "NA",
    urlone: "http://www.uca.ac.uk/library/academic-support/harvard-referencing/",
    urltwo: "NA"
  },
  {
    id: "university-of-aberdeen-school-of-education-harvard",
    text: "University of Aberdeen - School of Education - Harvard",
    parent: "NA",
    urlone: "https://www.abdn.ac.uk/library/documents/guides/ugedu010.pdf",
    urltwo: "NA"
  },
  {
    id: "university-of-aleppo-faculty-of-medicine",
    text: "University of Aleppo - Faculty of Medicine",
    parent: "NA",
    urlone:
      "https://github.com/firasuke/Citation-Style-Language-Styles/blob/master/University%20of%20Aleppo%20-%20Faculty%20of%20Medicine/documentation.md",
    urltwo: "NA"
  },
  {
    id: "university-of-auckland-history",
    text: "University of Auckland - History",
    parent: "NA",
    urlone:
      "https://www.auckland.ac.nz/content/dam/uoa/auckland/arts/about-the-faculty/humanities/for-current-students/history-coursework-guide.pdf",
    urltwo: "NA"
  },
  {
    id: "university-of-bradford-harvard",
    text: "University of Bradford - Harvard",
    parent: "NA",
    urlone: "https://www.brad.ac.uk/library/find-out-about/referencing/",
    urltwo: "NA"
  },
  {
    id: "university-of-cambridge-faculty-of-history",
    text: "University of Cambridge - Faculty of History",
    parent: "NA",
    urlone:
      "https://www.hist.cam.ac.uk/graduate-students/mphil-handbooks/general-mphil-handbook/faculty-style-guide/the-bibliography",
    urltwo: "NA"
  },
  {
    id: "university-of-gothenburg-apa-swedish-legislations",
    text: "University of Gothenburg - APA (Swedish legislations)",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "university-of-helsinki-faculty-of-theology",
    text: "Helsingin yliopisto - Teologinen tiedekunta (Finnish)",
    parent: "NA",
    urlone: "https://blogs.helsinki.fi/tutkivakirjoittaminen/files/2019/03/Teologian_tyyli_19.pdf",
    urltwo: "NA"
  },
  {
    id: "university-of-lincoln-harvard",
    text: "University of Lincoln - Harvard",
    parent: "NA",
    urlone: "http://guides.library.lincoln.ac.uk/learn/referencing",
    urltwo: "http://guides.library.lincoln.ac.uk/ld.php?content_id=26426164"
  },
  {
    id: "university-of-new-england-australia-note",
    text: "University of New England, Australia (note)",
    parent: "NA",
    urlone: "http://www.une.edu.au/__data/assets/pdf_file/0007/87811/REF_Trad.-Footnoting.pdf",
    urltwo: "NA"
  },
  {
    id: "university-of-south-africa-harvard",
    text: "University of South Africa - Harvard",
    parent: "NA",
    urlone: "http://libguides.unisa.ac.za/CEMSHarvard",
    urltwo: "NA"
  },
  {
    id: "university-of-south-australia-2017-harvard",
    text: "University of South Australia 2017 - Harvard",
    parent: "NA",
    urlone: "https://www.library.unisa.edu.au/referencing-roadmap/",
    urltwo: "NA"
  },
  {
    id: "university-of-south-australia-harvard-2011",
    text: "University of South Australia 2017 - Harvard",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "university-of-south-australia-harvard-2013",
    text: "University of South Australia 2017 - Harvard",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "university-of-tasmania-harvard",
    text: "University of Tasmania - Harvard",
    parent: "style-manual-australian-government",
    urlone: "http://utas.libguides.com/referencing/Harvard",
    urltwo: "NA"
  },
  {
    id: "university-of-york-apa",
    text: "University of York - APA 6th edition",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-york-chicago",
    text: "University of York - Chicago Manual of Style 16th edition",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-york-harvard-archaeology",
    text: "University of York - Harvard - Archaeology",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-york-harvard-environment",
    text: "University of York - Harvard - Environment",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-york-harvard",
    text: "University of York - Harvard",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-york-ieee",
    text: "University of York - IEEE",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-york-mhra",
    text: "University of York - Modern Humanities Research Association 3rd edition",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-york-mla",
    text: "University of York - Modern Language Association 8th edition",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-york-oscola",
    text: "University of York - OSCOLA",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-york-vancouver",
    text: "University of York - Vancouver",
    parent: "NA",
    urlone:
      "https://www.york.ac.uk/students/studying/develop-your-skills/study-skills/study/integrity/referencing-styles/",
    urltwo: "NA"
  },
  {
    id: "university-of-zabol-fa",
    text: "University of Zabol (Persian)",
    parent: "NA",
    urlone:
      "http://uoz.ac.ir/_DouranPortal/Documents/%D8%AA%D9%86%D8%B8%DB%8C%D9%85%20%D9%85%D8%B7%D8%A7%D9%84%D8%A8%20%D9%BE%D8%A7%DB%8C%D8%A7%D9%86%20%D9%86%D8%A7%D9%85%D9%87_20101003_204948.doc",
    urltwo: "NA"
  },
  {
    id: "university-of-zabol",
    text: "University of Zabol (English)",
    parent: "NA",
    urlone:
      "http://uoz.ac.ir/_DouranPortal/Documents/%D8%AA%D9%86%D8%B8%DB%8C%D9%85%20%D9%85%D8%B7%D8%A7%D9%84%D8%A8%20%D9%BE%D8%A7%DB%8C%D8%A7%D9%86%20%D9%86%D8%A7%D9%85%D9%87_20101003_204948.doc",
    urltwo: "NA"
  },
  {
    id: "univerza-na-primorskem-fakulteta-za-vede-o-zdravju-apa",
    text: "Univerza na Primorskem - Fakulteta za vede o zdravju - APA (Slovenian)",
    parent: "NA",
    urlone: "http://www.fvz.upr.si/sites/default/files/navodila_za_citiranje_in_navajanje_virov_up_fvz_2018.pdf",
    urltwo: "NA"
  },
  {
    id: "uniwersytet-im-adama-mickiewicza-w-poznaniu-wydzial-anglistyki",
    parent: "NA",
    urlone: "http://wa.amu.edu.pl/wa/stylesheet",
    urltwo: "NA"
  },
  {
    id: "uniwersytet-kardynala-stefana-wyszynskiego-w-warszawie-autor-rok",
    text: "Uniwersytet KardynaÅ‚a Stefana WyszyÅ„skiego w Warszawie (autor-rok, Polish)",
    parent: "NA",
    urlone: "https://wydawnictwo.uksw.edu.pl/strony/dla-autorow-6",
    urltwo: "NA"
  },
  {
    id: "uniwersytet-kardynala-stefana-wyszynskiego-w-warszawie-przypis",
    text: "Uniwersytet KardynaÅ‚a Stefana WyszyÅ„skiego w Warszawie (przypis, Polish)",
    parent: "NA",
    urlone: "https://wydawnictwo.uksw.edu.pl/",
    urltwo: "NA"
  },
  {
    id: "updates-in-surgery",
    text: "Updates in Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "uppsala-universitet-historia",
    text: "Uppsala universitet - Historia",
    parent: "NA",
    urlone:
      "http://hist.uu.se/Student/Masterutbildning/Skrivauppsats/Formaliainstruktioner/tabid/5600/language/en-US/Default.aspx",
    urltwo: "NA"
  },
  {
    id: "uppsala-universitet-institutionen-for-biologisk-grundutbildning",
    text: "Uppsala universitet, Institutionen fÃ¶r biologisk grundutbildning",
    parent: "NA",
    urlone: "http://www.ibg.uu.se/student/service/referenshantering",
    urltwo: "NA"
  },
  {
    id: "urban-climate",
    text: "Urban Climate",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "urban-ecosystems",
    text: "Urban Ecosystems",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "urban-forestry-and-urban-greening",
    text: "Urban Forestry &amp; Urban Greening",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "urban-forum",
    text: "Urban Forum",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "urban-geography",
    text: "Urban Geography",
    parent: "NA",
    urlone: "https://www.tandfonline.com/action/authorSubmission?journalCode=rurb20&amp;page=instructions#refs",
    urltwo: "NA"
  },
  {
    id: "urban-habitats",
    text: "Urban Habitats",
    parent: "NA",
    urlone: "http://urbanhabitats.org/submissions.html",
    urltwo: "NA"
  },
  {
    id: "urban-rail-transit",
    text: "Urban Rail Transit",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "urban-research-and-practice",
    text: "Urban Research &amp; Practice",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RURP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "urban-science",
    text: "Urban Science",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "urban-studies",
    text: "Urban Studies",
    parent: "NA",
    urlone: "http://www.sagepub.com/journalsProdDesc.nav?prodId=Journal201866#tabview=manuscriptSubmission",
    urltwo: "NA"
  },
  {
    id: "urbani-izziv-en",
    text: "Urbani izziv (Urban Challenge) (English)",
    parent: "NA",
    urlone: "https://urbaniizziv.uirs.si/Instructions-for-authors",
    urltwo: "NA"
  },
  {
    id: "urbani-izziv",
    text: "Urbani izziv (Urban Challenge) (Slovenian)",
    parent: "NA",
    urlone: "https://urbaniizziv.uirs.si/Navodila-za-avtorje",
    urltwo: "NA"
  },
  {
    id: "urolithiasis",
    text: "Urolithiasis",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "urologia-internationalis",
    text: "Urologia Internationalis",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1423-0399",
    urltwo: "NA"
  },
  {
    id: "urologic-oncology-seminars-and-original-investigations",
    text: "Urologic Oncology: Seminars and Original Investigations",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "urological-science",
    text: "Urological Science",
    parent: "NA",
    urlone: "http://www.elsevier.com/journals/urological-science/1879-5226/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "urology-case-reports",
    text: "Urology Case Reports",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "urology-video-journal",
    text: "Urology Video Journal",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "urology",
    text: "Urology",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "us-geological-survey",
    text: "U.S. Geological Survey",
    parent: "NA",
    urlone: "http://www.nwrc.usgs.gov/techrpt/sta28.pdf",
    urltwo: "NA"
  },
  {
    id: "usda-forest-service-pacific-northwest-research-station",
    text: "USDA Forest Service - Pacific Northwest Research Station",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/files/1321859/UsfsPnwAuthorsGuide2012.pdf",
    urltwo: "NA"
  },
  {
    id: "user-modeling-and-user-adapted-interaction",
    text: "User Modeling and User-Adapted Interaction",
    parent: "NA",
    urlone: "http://www.springer.com/computer/hci/journal/11257",
    urltwo: "NA"
  },
  {
    id: "uspekhi-gerontologii",
    text: "Ð£ÑÐ¿ÐµÑ…Ð¸ Ð³ÐµÑ€Ð¾Ð½Ñ‚Ð¾Ð»Ð¾Ð³Ð¸Ð¸ (Russian)",
    parent: "NA",
    urlone: "http://www.gersociety.ru/information/uspexi/",
    urltwo: "NA"
  },
  {
    id: "utah-geological-survey",
    text: "Utah Geological Survey",
    parent: "NA",
    urlone: "http://files.geology.utah.gov/online/c/c-105.pdf",
    urltwo: "NA"
  },
  {
    id: "utilities-policy",
    text: "Utilities Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "uwf-umweltwirtschaftsforum",
    text: "uwf UmweltWirtschaftsForum (German)",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "vaccine-reports",
    text: "Vaccine Reports",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vaccine-x",
    text: "Vaccine: X",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vaccine",
    text: "Vaccine",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vaccines",
    text: "Vaccines",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "vacuum",
    text: "Vacuum",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vadose-zone-journal",
    text: "Vadose Zone Journal",
    parent: "asa-cssa-sssa",
    urlone: "https://www.soils.org/publications/authors/vzj-instructions",
    urltwo: "NA"
  },
  {
    id: "value-in-health-regional-issues",
    text: "Value in Health Regional Issues",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "value-in-health",
    text: "Value in Health",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vancouver-author-date",
    text: "Vancouver (author-date)",
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "NA"
  },
  {
    id: "vancouver-brackets-no-et-al",
    text: 'Vancouver (brackets, no "et al.")',
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "NA"
  },
  {
    id: "vancouver-brackets-only-year-no-issue",
    text: "Vancouver (brackets, only year in date, no issue numbers)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/645",
    urltwo: "NA"
  },
  {
    id: "vancouver-brackets",
    text: "Vancouver (brackets)",
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "NA"
  },
  {
    id: "vancouver-fr-ca",
    text: "Vancouver (French - Canada)",
    parent: "NA",
    urlone: "http://guides.bib.umontreal.ca/disciplines/247-Citer-selon-le-style-Vancouver?tab=1004",
    urltwo: "NA"
  },
  {
    id: "vancouver-imperial-college-london",
    text: "Imperial College London - Vancouver",
    parent: "NA",
    urlone: "http://www.imperial.ac.uk/admin-services/library/learning-support/reference-management/vancouver-style/",
    urltwo: "NA"
  },
  {
    id: "vancouver-superscript-brackets-only-year",
    text: "Vancouver (superscript, brackets, only year in date)",
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "NA"
  },
  {
    id: "vancouver-superscript-only-year",
    text: "Vancouver (superscript, only year in date, no issue numbers)",
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "NA"
  },
  {
    id: "vancouver-superscript",
    text: "Vancouver (superscript)",
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "NA"
  },
  {
    id: "vancouver",
    text: "Vancouver",
    parent: "NA",
    urlone: "http://www.nlm.nih.gov/bsd/uniform_requirements.html",
    urltwo: "NA"
  },
  {
    id: "vascular-cell",
    text: "Vascular Cell",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "vascular-medicine",
    text: "Vascular Medicine",
    parent: "sage-vancouver",
    urlone: "http://www.uk.sagepub.com/msg/vmj.htm#ReferenceStyle",
    urltwo: "NA"
  },
  {
    id: "vascular-pharmacology",
    text: "Vascular Pharmacology",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vegetation-history-and-archaeobotany",
    text: "Vegetation History and Archaeobotany",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "vehicle-system-dynamics",
    text: "Vehicle System Dynamics",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NVSD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "vehicular-communications",
    text: "Vehicular Communications",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "venture-capital",
    text: "Venture Capital",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TVEC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "verhaltenstherapie",
    text: "Verhaltenstherapie",
    parent: "karger-journals-author-date",
    urlone: "http://www.karger.com?issn=1423-0402",
    urltwo: "NA"
  },
  {
    id: "verslaving",
    text: "Verslaving (Dutch)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "veterinaria-italiana",
    text: "Veterinaria Italiana",
    parent: "NA",
    urlone: "http://www.izs.it/vet_italiana/guidelines_gen_guidelines_vet_it.htm",
    urltwo: "NA"
  },
  {
    id: "veterinary-and-animal-science",
    text: "Veterinary and Animal Science",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "veterinary-immunology-and-immunopathology",
    text: "Veterinary Immunology and Immunopathology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "veterinary-medicine-austria",
    text: "Veterinary Medicine Austria (Wiener TierÃ¤rztliche Monatsschrift)",
    parent: "NA",
    urlone: "https://www.wtm.at/Instructions-for-Authors.html",
    urltwo: "NA"
  },
  {
    id: "veterinary-microbiology",
    text: "Veterinary Microbiology",
    parent: "NA",
    urlone: "https://www.elsevier.com/journals/veterinary-microbiology/0378-1135/guide-for-authors",
    urltwo: "NA"
  },
  {
    id: "veterinary-parasitology-regional-studies-and-reports",
    text: "Veterinary Parasitology: Regional Studies and Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "veterinary-parasitology-x",
    text: "Veterinary Parasitology: X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "veterinary-parasitology",
    text: "Veterinary Parasitology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "veterinary-pathology",
    text: "Veterinary Pathology",
    parent: "NA",
    urlone: "https://us.sagepub.com/en-us/nam/veterinary-pathology/journal201966#submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "veterinary-quarterly",
    text: "Veterinary Quarterly",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TVEQ20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "veterinary-radiology-and-ultrasound",
    text: "Veterinary Radiology &amp; Ultrasound",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291740-8261/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "veterinary-record-case-reports",
    text: "Veterinary Record Case Reports",
    parent: "veterinary-record",
    urlone: "http://vetrecordcasereports.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "veterinary-record-open",
    text: "Veterinary Record Open",
    parent: "veterinary-record",
    urlone: "http://vetrecordopen.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "veterinary-record",
    text: "Veterinary Record",
    parent: "NA",
    urlone: "http://veterinaryrecord.bmj.com/",
    urltwo: "NA"
  },
  {
    id: "veterinary-research-communications",
    text: "Veterinary Research Communications",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "veterinary-research",
    text: "Veterinary Research",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "veterinary-sciences",
    text: "Veterinary Sciences",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "vibrational-spectroscopy",
    text: "Vibrational Spectroscopy",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "victoria-university-harvard",
    text: "Vibrational Spectroscopy",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "video-journal-and-encyclopedia-of-gi-endoscopy",
    text: "Video Journal and Encyclopedia of GI Endoscopy",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "video-journal-of-education-and-pedagogy",
    text: "Video Journal of Education and Pedagogy",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "vienna-legal",
    text: "Vienna Legal",
    parent: "NA",
    urlone: "http://www.wu.ac.at/taxlaw/en",
    urltwo: "NA"
  },
  {
    id: "vietnam-journal-of-computer-science",
    text: "Vietnam Journal of Computer Science",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "vietnam-journal-of-mathematics",
    text: "Vietnam Journal of Mathematics",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "vietnam-ministry-of-education-and-training-en",
    text: "Vietnam Ministry of Education and Training (English)",
    parent: "NA",
    urlone: "http://www.vdic.org.vn/en/library/guides.html",
    urltwo: "NA"
  },
  {
    id: "vietnam-ministry-of-education-and-training-vi",
    text: "Vietnam Ministry of Education and Training (Vietnamese)",
    parent: "NA",
    urlone: "http://www.vdic.org.vn/en/library/guides.html",
    urltwo: "NA"
  },
  {
    id: "vigiliae-christianae",
    text: "Vigiliae Christianae",
    parent: "NA",
    urlone: "http://www.brill.com/sites/default/files/ftp/authors_instructions/VC.pdf",
    urltwo: "NA"
  },
  {
    id: "vilnius-gediminas-technical-university",
    text: "Vilnius Gediminas Technical University (Lithuanian)",
    parent: "NA",
    urlone: "http://leidykla.vgtu.lt/lt/autoriams/rankraio-rengimas/kai-kurios-raymo-taisykls/literatros-saraas.html",
    urltwo: "NA"
  },
  {
    id: "vingtieme-siecle",
    text: "VingtiÃ¨me SiÃ¨cle. Revue d'histoire (French)",
    parent: "NA",
    urlone: "http://www.cairn.info/en-savoir-plus-sur-la-revue-vingtieme-siecle-revue-d-histoire.htm",
    urltwo: "NA"
  },
  {
    id: "virchows-archiv",
    text: "Virchows Archiv",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "virology-journal",
    text: "Virology Journal",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "virology-reports",
    text: "Virology Reports",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "virology",
    text: "Virology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "virtual-and-physical-prototyping",
    text: "Virtual and Physical Prototyping",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=NVPP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "virtual-reality",
    text: "Virtual Reality",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "virulence",
    text: "Virulence",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "virus-genes",
    text: "Virus Genes",
    parent: "springer-physics-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "virus-research",
    text: "Virus Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "virusdisease",
    text: "VirusDisease",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "viruses",
    text: "Viruses",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "vision-research",
    text: "Vision Research",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vision",
    text: "Vision",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "visual-cognition",
    text: "Visual Cognition",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PVIS20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "visual-informatics",
    text: "Visual Informatics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "visual-journal-of-emergency-medicine",
    text: "Visual Journal of Emergency Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "visual-neuroscience",
    text: "Visual Neuroscience",
    parent: "the-journal-of-physiology",
    urlone: "http://assets.cambridge.org/VNS/VNS_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "visual-studies",
    text: "Visual Studies",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RVST20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "visualization-image-processing-and-computation-in-biomedicine",
    text: "Visualization, Image Processing and Computation in Biomedicine",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/7e4ff0643eace965.html",
    urltwo: "NA"
  },
  {
    id: "visualization-in-engineering",
    text: "Visualization in Engineering",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "visualization-of-mechanical-processes-an-international-online-journal",
    text: "Visualization of Mechanical Processes: An International Online Journal",
    parent: "begell-house-chicago-author-date",
    urlone: "http://dl.begellhouse.com/forauthors/journals/08456987543b9011.html",
    urltwo: "NA"
  },
  {
    id: "viszeralmedizin",
    text: "Viszeralmedizin",
    parent: "karger-journals",
    urlone: "http://www.karger.com?issn=1421-9808",
    urltwo: "NA"
  },
  {
    id: "vita-latina-auteurs-anciens",
    text: "Viszeralmedizin",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vita-latina",
    text: "Viszeralmedizin",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vocation-sage-femme",
    text: "Vocation sage-femme",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "vocations-and-learning",
    text: "Vocations and Learning",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "vodohospodarske-technicko-ekonomicke-informace-en",
    text: "VodohospodÃ¡Å™skÃ© technicko-ekonomickÃ© informace (English)",
    parent: "NA",
    urlone: "http://www.vtei.cz/en/instructions-editors/",
    urltwo: "NA"
  },
  {
    id: "vodohospodarske-technicko-ekonomicke-informace",
    text: "VodohospodÃ¡Å™skÃ© technicko-ekonomickÃ© informace (Czech)",
    parent: "NA",
    urlone: "http://www.vtei.cz/pokyny-pro-autory",
    urltwo: "NA"
  },
  {
    id: "voluntas-international-journal-of-voluntary-and-nonprofit-organizations",
    text: "VOLUNTAS: International Journal of Voluntary and Nonprofit Organizations",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "vox-sanguinis",
    text: "Vox Sanguinis",
    parent: "vancouver-brackets",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1423-0410/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "vulnerable-children-and-youth-studies",
    text: "Vulnerable Children and Youth Studies",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RVCH20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "wader-study",
    text: "Wader Study",
    parent: "NA",
    urlone: "http://www.waderstudygroup.org/wader-study/wader-study-author-guidelines/",
    urltwo: "NA"
  },
  {
    id: "wao-journal",
    text: "WAO Journal",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "waste-and-biomass-valorization",
    text: "Waste and Biomass Valorization",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "waste-management",
    text: "Waste Management",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water-air-and-soil-pollution",
    text: "Water, Air, &amp; Soil Pollution",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "water-alternatives",
    text: "Water Alternatives",
    parent: "NA",
    urlone: "http://www.water-alternatives.org/index.php/guide",
    urltwo: "NA"
  },
  {
    id: "water-conservation-science-and-engineering",
    text: "Water Conservation Science and Engineering",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "water-energy-nexus",
    text: "Water-Energy Nexus",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water-environment-research",
    text: "Water Environment Research",
    parent: "NA",
    urlone: "http://www.wef.org/Publications/page.aspx?id=2834#references",
    urltwo: "NA"
  },
  {
    id: "water-history",
    text: "Water History",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "water-international",
    text: "Water International",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RWIN20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "water-research-x",
    text: "Water Research X",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water-research",
    text: "Water Research",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water-resources-and-economics",
    text: "Water Resources and Economics",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water-resources-and-industry",
    text: "Water Resources and Industry",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water-resources-and-rural-development",
    text: "Water resources and rural development",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water-resources-management",
    text: "Water Resources Management",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "water-resources-research",
    text: "Water Resources Research",
    parent: "american-geophysical-union",
    urlone: "http://publications.agu.org/author-resource-center/text-requirements/reference-format/",
    urltwo: "NA"
  },
  {
    id: "water-sa",
    text: "Water SA",
    parent: "NA",
    urlone: "http://www.wrc.org.za/Pages/KH_WaterSA.aspx?dt=5&amp;ms=62",
    urltwo: "NA"
  },
  {
    id: "water-science-and-engineering",
    text: "Water Science and Engineering",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water-science-and-technology",
    text: "Water Science &amp; Technology",
    parent: "NA",
    urlone: "http://www.iwaponline.com/wst/i2a.htm",
    urltwo: "NA"
  },
  {
    id: "water-science",
    text: "Water Science",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water-security",
    text: "Water Security",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "water",
    text: "Water",
    parent: "multidisciplinary-digital-publishing-institute",
    urlone: "http://www.mdpi.com/authors/references",
    urltwo: "NA"
  },
  {
    id: "waterbirds",
    text: "Waterbirds",
    parent: "NA",
    urlone: "https://waterbirds.org/journal/information-for-contributors/",
    urltwo: "NA"
  },
  {
    id: "watershed-ecology-and-the-environment",
    text: "Watershed Ecology and the Environment",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "wave-motion",
    text: "Wave Motion",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "waves-in-random-and-complex-media",
    text: "Waves in Random and Complex Media",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TWRM20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "wear",
    text: "Wear",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "weather-and-climate-extremes",
    text: "Weather and Climate Extremes",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "weather-and-forecasting",
    text: "Weather and Forecasting",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/wefo",
    urltwo: "NA"
  },
  {
    id: "weather-climate-and-society",
    text: "Weather, Climate, and Society",
    parent: "american-meteorological-society",
    urlone: "http://journals.ametsoc.org/loi/wcas",
    urltwo: "NA"
  },
  {
    id: "web-ecology",
    text: "Web Ecology",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "webbia",
    text: "Webbia",
    parent: "taylor-and-francis-council-of-science-editors-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TWEB20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "weed-research",
    text: "Weed Research",
    parent: "NA",
    urlone: "https://onlinelibrary.wiley.com/page/journal/13653180/homepage/forauthors.html",
    urltwo: "NA"
  },
  {
    id: "weed-science-society-of-america",
    text: "Weed Science Society of America",
    parent: "NA",
    urlone: "https://www.cambridge.org/core/journals/weed-science/information/instructions-contributors",
    urltwo: "NA"
  },
  {
    id: "weed-science",
    text: "Weed Science",
    parent: "weed-science-society-of-america",
    urlone: "http://www.bioone.org/loi/wees",
    urltwo: "NA"
  },
  {
    id: "weed-technology",
    text: "Weed Technology",
    parent: "weed-science-society-of-america",
    urlone: "http://www.bioone.org/loi/wete",
    urltwo: "NA"
  },
  {
    id: "welding-in-the-world",
    text: "Welding in the World",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "welding-international",
    text: "Welding International",
    parent: "taylor-and-francis-national-library-of-medicine",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TWLD20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "west-european-politics",
    text: "West European Politics",
    parent: "NA",
    urlone: "http://www.tandf.co.uk/journals/authors/style/reference/ref_fwep.pdf",
    urltwo: "NA"
  },
  {
    id: "west-virginia-medical-journal",
    text: "West Virginia Medical Journal",
    parent: "vancouver-superscript",
    urlone: "http://www.wvsma.com/Journal.aspx",
    urltwo: "NA"
  },
  {
    id: "western-journal-of-emergency-medicine",
    text: "Western Journal of Emergency Medicine",
    parent: "NA",
    urlone: "https://westjem.com/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "western-pacific-surveillance-and-response",
    text: "Western Pacific Surveillance and Response",
    parent: "vancouver-superscript",
    urlone: "http://www.wpro.who.int/wpsar/authors/en/",
    urltwo: "NA"
  },
  {
    id: "westfalische-wilhelms-universitat-munster-medizinische-fakultat",
    text: "WestfÃ¤lische Wilhelms-UniversitÃ¤t MÃ¼nster - Medizinische FakultÃ¤t (German)",
    parent: "NA",
    urlone: "https://campus.uni-muenster.de/fakultaet/fakultaet/dekanat/promotionen/",
    urltwo: "NA"
  },
  {
    id: "wetlands-ecology-and-management",
    text: "Wetlands Ecology and Management",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "wetlands",
    text: "Wetlands",
    parent: "NA",
    urlone: "http://www.springer.com/life+sciences/ecology/journal/13157",
    urltwo: "NA"
  },
  {
    id: "wheaton-college-phd-in-biblical-and-theological-studies",
    text: "Wheaton College - Ph.D. in Biblical and Theological Studies",
    parent: "NA",
    urlone:
      "http://www.wheaton.edu/Academics/Departments/Theology/Graduate-Studies/PhD-in-Biblical-and-Theological-Studies/~/media/Files/Academics/Departments/BTS/Style_Guide_August_2011_electronic_version.pdf",
    urltwo: "NA"
  },
  {
    id: "who-europe-harvard",
    text: "WHO Regional Office for Europe - Harvard",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/170",
    urltwo: "NA"
  },
  {
    id: "who-europe-numeric",
    text: "WHO Regional Office for Europe (numeric)",
    parent: "NA",
    urlone: "https://github.com/citation-style-language/styles/pull/170",
    urltwo: "NA"
  },
  {
    id: "who-south-east-asia-journal-of-public-health",
    text: "WHO South-East Asia Journal of Public Health",
    parent: "vancouver-superscript",
    urlone: "http://www.searo.who.int/publications/journals/seajph/about/en/",
    urltwo: "NA"
  },
  {
    id: "wiener-klinische-wochenschrift-education",
    text: "Wiener klinische Wochenschrift Education (German)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "wiener-klinische-wochenschrift",
    text: "Wiener klinische Wochenschrift",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "wiener-klinisches-magazin",
    text: "Wiener klinisches Magazin (German)",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "wiener-medizinische-wochenschrift",
    text: "Wiener Medizinische Wochenschrift (German)",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "wiesbaden-business-school",
    text: "Wiesbaden Business School",
    parent: "NA",
    urlone: "https://www.hs-rm.de/fileadmin/user_upload/Leitfaden_Thesis_IBA.pdf",
    urltwo:
      "https://www.hs-rm.de/fileadmin/Home/Fachbereiche/Wiesbaden_Business_School/Studiengaenge/International_Management__M.A._/Leitfaden_Thesis_IM_en.pdf"
  },
  {
    id: "wilderness-and-environmental-medicine",
    text: "Wilderness &amp; Environmental Medicine",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "wildlife-biology",
    text: "Wildlife Biology",
    parent: "oikos",
    urlone: "http://www.wildlifebiology.org/authors/author-guidelines",
    urltwo: "NA"
  },
  {
    id: "wildlife-research",
    text: "Wildlife Research",
    parent: "emu-austral-ornithology",
    urlone: "http://www.publish.csiro.au/nid/147/aid/447.htm#10",
    urltwo: "NA"
  },
  {
    id: "wiley-vch-books",
    text: "Wiley-VCH books",
    parent: "NA",
    urlone: "http://www.wiley-vch.de/publish/en/authors/auguidelines",
    urltwo: "NA"
  },
  {
    id: "wind-energy-science",
    text: "Wind Energy Science",
    parent: "copernicus-publications",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "wine-economics-and-policy",
    text: "Wine Economics and Policy",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "wireless-communications-and-mobile-computing",
    text: "Wireless Communications and Mobile Computing",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291530-8677/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "wireless-networks",
    text: "Wireless Networks",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "wireless-personal-communications",
    text: "Wireless Personal Communications",
    parent: "springer-socpsych-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "wirtschaftsuniversitat-wien-abteilung-fur-bildungswissenschaft",
    text: "WirtschaftsuniversitÃ¤t Wien - Abteilung fÃ¼r Bildungswissenschaft (German - Austria)",
    parent: "NA",
    urlone: "https://www.wu.ac.at/fileadmin/wu/d/i/bildungswissenschaft/Lehre/Masterarbeiten/zitierrichtlinien.pdf",
    urltwo: "NA"
  },
  {
    id: "wirtschaftsuniversitat-wien-author-date",
    text: "WirtschaftsuniversitÃ¤t Wien (author-date)",
    parent: "NA",
    urlone: "https://learn.wu.ac.at/bibliothek/literaturverwaltung",
    urltwo: "NA"
  },
  {
    id: "wirtschaftsuniversitat-wien-handel-und-marketing",
    text: "WirtschaftsuniversitÃ¤t Wien - Handel und Marketing (German - Austria)",
    parent: "NA",
    urlone: "https://learn.wu.ac.at/bibliothek/literaturverwaltung",
    urltwo: "https://www.wu.ac.at/fileadmin/wu/d/i/retail/PDF_Dokumente/Bachelor/HM_Zitiervorschriften_Juni.2017.pdf"
  },
  {
    id: "wirtschaftsuniversitat-wien-health-care-management",
    text: "WirtschaftsuniversitÃ¤t Wien - Health Care Management",
    parent: "NA",
    urlone: "https://www.complexity-research.com/pdf/WissArb.pdf",
    urltwo: "NA"
  },
  {
    id: "wirtschaftsuniversitat-wien-institut-fur-bwl-des-aussenhandels",
    text: "WirtschaftsuniversitÃ¤t Wien - Institut fÃ¼r BWL des AuÃŸenhandels (German - Austria)",
    parent: "NA",
    urlone: "https://www.wu.ac.at/fileadmin/wu/d/i/auha/Downloads/formvorschriftenba2013.pdf",
    urltwo: "NA"
  },
  {
    id: "wirtschaftsuniversitat-wien-unternehmensrechnung-und-controlling",
    text: "WirtschaftsuniversitÃ¤t Wien - Unternehmensrechnung und Controlling",
    parent: "NA",
    urlone: "https://www.wu.ac.at/fileadmin/wu/d/i/uco/Infoblatt_Abschlussarbeiten.pdf",
    urltwo: "NA"
  },
  {
    id: "wirtschaftsuniversitat-wien-wirtschaftspadagogik",
    text: "WirtschaftsuniversitÃ¤t Wien - WirtschaftspÃ¤dagogik (German - Austria)",
    parent: "NA",
    urlone: "https://learn.wu.ac.at/bibliothek/literaturverwaltung",
    urltwo:
      "https://www.wu.ac.at/fileadmin/wu/d/i/wipaed/studiumlehre/Bachelorarbeit/Leitfaden_zum_Zitieren_Wip%C3%A4d.pdf"
  },
  {
    id: "wissen-kompakt",
    text: "wissen kompakt (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/dentistry/journal/11838?detailsPage=pltci_1423207",
    urltwo: "NA"
  },
  {
    id: "wissenschaftlicher-industrielogistik-dialog",
    text: "Wissenschaftlicher Industrielogistik-Dialog (German - Austria)",
    parent: "NA",
    urlone: "http://wild-leoben.jimdo.com/",
    urltwo: "NA"
  },
  {
    id: "wmu-journal-of-maritime-affairs",
    text: "WMU Journal of Maritime Affairs",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "wolters-kluwerbrede-schrijfwijzer-author-date",
    text: "WMU Journal of Maritime Affairs",
    parent: "NA",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "woman-psychosomatic-gynaecology-and-obstetrics",
    text: "Woman - Psychosomatic Gynaecology and Obstetrics",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "women-and-performance-a-journal-of-feminist-theory",
    text: "Women &amp; Performance: a journal of feminist theory",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RWAP20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "womens-health-issues",
    text: "Women's Health Issues",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "womens-health",
    text: "Women's Health",
    parent: "future-science-group",
    urlone: "http://www.futuremedicine.com/loi/whe",
    urltwo: "NA"
  },
  {
    id: "womens-midlife-health",
    text: "Women's Midlife Health",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "womens-studies-international-forum",
    text: "Women's Studies International Forum",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "wood-science-and-technology",
    text: "Wood Science and Technology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "work-and-stress",
    text: "Work &amp; Stress",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TWST20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "workplace-health-and-safety",
    text: "Workplace Health &amp; Safety",
    parent: "american-medical-association",
    urlone: "http://www.healio.com/~/media/Files/Journals/General%20PDFs/AAOHN/AAOHNinfoauth.ashx",
    urltwo: "NA"
  },
  {
    id: "workplace-review",
    text: "Workplace Review",
    parent: "thomson-reuters-legal-tax-and-accounting-australia",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "world-applied-sciences-journal",
    text: "World Applied Sciences Journal",
    parent: "NA",
    urlone: "https://www.idosi.org/wasj/instructions.htm",
    urltwo: "NA"
  },
  {
    id: "world-archaeology",
    text: "World Archaeology",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=RWAR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "world-congress-on-engineering-asset-management",
    text: "World Congress on Engineering Asset Management 2010",
    parent: "NA",
    urlone: "http://wceam.com/",
    urltwo: "NA"
  },
  {
    id: "world-development-perspectives",
    text: "World Development Perspectives",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "world-development",
    text: "World Development",
    parent: "apa",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "world-journal-of-acupuncture-moxibustion",
    text: "World Journal of Acupuncture - Moxibustion",
    parent: "elsevier-vancouver",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "world-journal-of-emergency-surgery",
    text: "World Journal of Emergency Surgery",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "world-journal-of-gastroenterology",
    text: "World Journal of Gastroenterology",
    parent: "NA",
    urlone: "http://www.wjgnet.com/1007-9327/g_info_20100315215714.htm",
    urltwo: "NA"
  },
  {
    id: "world-journal-of-microbiology-and-biotechnology",
    text: "World Journal of Microbiology and Biotechnology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "world-journal-of-otorhinolaryngology-head-and-neck-surgery",
    text: "World Journal of Otorhinolaryngology-Head and Neck Surgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "world-journal-of-surgery",
    text: "World Journal of Surgery",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "world-journal-of-surgical-oncology",
    text: "World Journal of Surgical Oncology",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "world-journal-of-urology",
    text: "World Journal of Urology",
    parent: "springer-basic-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "world-medical-journal",
    text: "World Medical Journal",
    parent: "vancouver-brackets",
    urlone: "http://www.wma.net/en/30publications/20journal/index.html",
    urltwo: "NA"
  },
  {
    id: "world-mycotoxin-journal",
    text: "World Mycotoxin Journal",
    parent: "NA",
    urlone: "http://www.wageningenacademic.com/default.asp?pageid=92",
    urltwo: "NA"
  },
  {
    id: "world-neurosurgery-x",
    text: "World Neurosurgery: X",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "world-neurosurgery",
    text: "World Neurosurgery",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "world-organisation-for-animal-health-scientific-and-technical-review",
    text: "World Organisation for Animal Health - Scientific and Technical Review",
    parent: "NA",
    urlone:
      "http://www.oie.int/en/publications-and-documentation/scientific-and-technical-review-free-access/instructions-to-authors/",
    urltwo: "NA"
  },
  {
    id: "world-patent-information",
    text: "World Patent Information",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "world-politcs",
    text: "World Politics",
    parent: "NA",
    urlone: "https://webshare.princeton.edu/users/piirs/pdf/World%20Politics%20Style%20Sheet.pdf",
    urltwo: "NA"
  },
  {
    id: "world-psychiatry",
    text: "World Psychiatry",
    parent: "american-medical-association",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "world-wide-web",
    text: "World Wide Web",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "worlds-poultry-science-journal",
    text: "World's Poultry Science Journal",
    parent: "NA",
    urlone: "http://assets.cambridge.org/WPS/WPS_ifc.pdf",
    urltwo: "NA"
  },
  {
    id: "worm",
    text: "Worm",
    parent: "landes-bioscience-journals",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "wound-medicine",
    text: "Wound Medicine",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "writing-systems-research",
    text: "Writing Systems Research",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=PWSR20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "xenotransplantation",
    text: "Xenotransplantation",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/%28ISSN%291399-3089/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "yakugaku-zasshi",
    text: "Yakugaku Zasshi (Journal of the Pharmaceutical Society of Japan)",
    parent: "chemical-and-pharmaceutical-bulletin",
    urlone: "http://yakushi.pharm.or.jp/j_regulations.pdf",
    urltwo: "NA"
  },
  {
    id: "yakuzai-ekigaku",
    text: "Yakuzai Ekigaku (Japanese Journal of Pharmacoepidemiology)",
    parent: "vancouver-superscript",
    urlone: "http://www.jspe.jp/publication/toukoukitei/",
    urltwo: "NA"
  },
  {
    id: "yale-journal-of-biology-and-medicine",
    text: "Yale Journal of Biology and Medicine",
    parent: "vancouver-brackets",
    urlone: "http://medicine.yale.edu/yjbm/authorguidelines/manuscriptguidelines.aspx",
    urltwo: "NA"
  },
  {
    id: "yearbook-of-english-studies",
    text: "Yearbook of English Studies",
    parent: "modern-humanities-research-association",
    urlone: "http://www.mhra.org.uk/Publications/Journals/yes.html",
    urltwo: "NA"
  },
  {
    id: "yeast",
    text: "Yeast",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1002/%28ISSN%291097-0061/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "yozgat-bozok-universitesi-fen-bilimleri-enstitusu",
    text: "Yozgat Bozok Ãœniversitesi - Fen Bilimleri EnstitÃ¼sÃ¼ (Turkish)",
    parent: "NA",
    urlone: "https://fenbilimleri.bozok.edu.tr/Dosya/861e4263-f3f6-4dee-8bb2-4e9129015b88.doc",
    urltwo: "NA"
  },
  {
    id: "zagazig-university-medical-journal",
    text: "Zagazig University Medical Journal",
    parent: "vancouver-superscript",
    urlone: "http://www.zumedicaljournal.com/",
    urltwo: "NA"
  },
  {
    id: "zahnmedizin-up2date",
    text: "Zahnmedizin up2date",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "zastosowania-komputerow-w-elektrotechnice",
    text: "Zastosowania KomputerÃ³w w Elektrotechnice",
    parent: "NA",
    urlone: "http://www.iee.put.poznan.pl/zkwe/download/guide.doc",
    urltwo: "NA"
  },
  {
    id: "zdfm-zeitschrift-fur-diversitatsforschung-und-management",
    text: "ZDfm &#8211; Zeitschrift fÃ¼r DiversitÃ¤tsforschung und -management (German - Austria)",
    parent: "NA",
    urlone: "https://www.budrich-journals.de/index.php/zdfm/about/submissions#copyrightNotice",
    urltwo: "NA"
  },
  {
    id: "zdm",
    text: "ZDM",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zdravniski-vestnik",
    text: "ZdravniÅ¡ki Vestnik (Slovenian Medical Journal)",
    parent: "NA",
    urlone: "http://ojs.szd.si/index.php/vestnik/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "zeitgeschichte",
    text: "Zeitgeschichte (German)",
    parent: "NA",
    urlone:
      "http://www.verein-zeitgeschichte.univie.ac.at/fileadmin/user_upload/p_verein_zeitgeschichte/zg_Zitierregeln_dt_2018.pdf",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-allgemeinmedizin",
    text: "Zeitschrift fÃ¼r Allgemeinmedizin",
    parent: "NA",
    urlone: "https://www.online-zfa.de/page/Autorenrichtlinien/22",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-angewandte-mathematik-und-physik",
    text: "Zeitschrift fÃ¼r angewandte Mathematik und Physik",
    parent: "springer-mathphys-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-antikes-christentum",
    text: "Zeitschrift fÃ¼r Antikes Christentum (Journal of Ancient Christianity) (English)",
    parent: "NA",
    urlone: "https://www.degruyter.com/view/supplement/s1612961X_Guidelines_for_English_Articles.pdf",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-aussen-und-sicherheitspolitik",
    text: "Zeitschrift fÃ¼r AuÃŸen- und Sicherheitspolitik (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-bildungsforschung",
    text: "Zeitschrift fÃ¼r Bildungsforschung (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-deutsche-philologie",
    text: "Zeitschrift fÃ¼r deutsche Philologie (German)",
    parent: "NA",
    urlone: "http://www.esv.info/download/zeitschriften/ZfdPh/autorenhinweise.pdf",
    urltwo: "http://www.zfdphdigital.de/inhalt.html"
  },
  {
    id: "zeitschrift-fur-didaktik-der-naturwissenschaften",
    text: "Zeitschrift fÃ¼r Didaktik der Naturwissenschaften (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-die-gesamte-versicherungswissenschaft",
    text: "Zeitschrift fÃ¼r die gesamte Versicherungswissenschaft (German)",
    parent: "springer-mathphys-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-die-geschichte-des-oberrheins",
    text: "Zeitschrift fÃ¼r die Geschichte des Oberrheins (German)",
    parent: "NA",
    urlone: "https://www.kgl-bw.de/a_publ_zgo.htm",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-digitale-geisteswissenschaften",
    text: "Zeitschrift fÃ¼r digitale Geisteswissenschaften (German)",
    parent: "NA",
    urlone: "http://www.zfdg.de/s-wie-style",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-energiewirtschaft",
    text: "Zeitschrift fÃ¼r Energiewirtschaft (German)",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-epileptologie",
    text: "Zeitschrift fÃ¼r Epileptologie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/neurology/journal/10309?detailsPage=pltci_1425909",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-erziehungswissenschaft",
    text: "Zeitschrift fÃ¼r Erziehungswissenschaft (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-gastroenterologie",
    text: "Zeitschrift fÃ¼r Gastroenterologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-geburtshilfe-und-neonatologie",
    text: "Zeitschrift fÃ¼r Geburtshilfe und Neonatologie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-gerontologie-und-geriatrie",
    text: "Zeitschrift fÃ¼r Gerontologie und Geriatrie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/family/journal/391?detailsPage=pltci_1380209",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-herz-thorax-und-gefasschirurgie",
    text: "Zeitschrift fÃ¼r Herz-,Thorax- und GefÃ¤ÃŸchirurgie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/surgery/journal/398?detailsPage=pltci_1730709",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-immobilienokonomie",
    text: "Zeitschrift fÃ¼r ImmobilienÃ¶konomie (German)",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-internationale-beziehungen",
    text: "Zeitschrift fÃ¼r Internationale Beziehungen (German)",
    parent: "NA",
    urlone: "http://www.zib.nomos.de/fileadmin/zib/doc/ZIB_Richtlinien_Autorenhinweise.pdf",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-kunstgeschichte",
    text: "Zeitschrift fÃ¼r Kunstgeschichte",
    parent: "NA",
    urlone:
      "http://www.uni-bielefeld.de/geschichte/abteilung/arbeitsbereiche/bildwissenschaft/zfk/documents/Sylesheet-(ger)-Zeitschrift-fur-Kunstgeschichte.pdf",
    urltwo: "https://github.com/citation-style-language/styles/pull/1861"
  },
  {
    id: "zeitschrift-fur-medien-und-kulturforschung",
    text: "Zeitschrift fÃ¼r Medien- und Kulturforschung (German)",
    parent: "NA",
    urlone: "https://meiner.de/service/autoren-beitraeger#ZMK",
    urltwo: "https://github.com/citation-style-language/styles/pull/3764#commitcomment-31115303"
  },
  {
    id: "zeitschrift-fur-medienwissenschaft",
    text: "Zeitschrift fÃ¼r Medienwissenschaft (German)",
    parent: "NA",
    urlone: "http://zfmedienwissenschaft.de/service/submission-guidelines",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-orthopadie-und-unfallchirurgie",
    text: "Zeitschrift fÃ¼r OrthopÃ¤die und Unfallchirurgie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-ostmitteleuropa-forschung",
    text: "Zeitschrift fÃ¼r Ostmitteleuropa-Forschung (German)",
    parent: "NA",
    urlone: "https://www.herder-institut.de/servicebereiche/verlag/fuer-autorinnen-und-autoren.html",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-padagogik",
    text: "Zeitschrift fÃ¼r PÃ¤dagogik (German)",
    parent: "NA",
    urlone: "http://www.beltz.de/fileadmin/beltz/downloads/zfpaed/Manuskripthinweise_ZfPaed_2013.pdf",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-palliativmedizin",
    text: "Zeitschrift fÃ¼r Palliativmedizin",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-parlamentsfragen",
    text: "Zeitschrift fÃ¼r Parlamentsfragen (German)",
    parent: "NA",
    urlone: "https://zparl.de/information/hinweise-fuer-autoren/",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-politikwissenschaft",
    text: "Zeitschrift fÃ¼r Politikwissenschaft",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-psychodrama-und-soziometrie",
    text: "Zeitschrift fÃ¼r Psychodrama und Soziometrie (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-psychologie",
    text: "Zeitschrift fÃ¼r Psychologie",
    parent: "apa",
    urlone: "http://content.apa.org/journals/zfp",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-qualitative-forschung",
    text: "Zeitschrift fÃ¼r Qualitative Forschung (German)",
    parent: "NA",
    urlone: "http://www.budrich-journals.de/index.php/zqf/about/submissions#authorGuidelines",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-religionswissenschaft-author-date",
    text: "Zeitschrift fÃ¼r Religionswissenschaft (author-date)",
    parent: "NA",
    urlone: "http://www.degruyter.com/view/supplement/s2194508X_Manuskriptrichtlinien_de.pdf",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-religionswissenschaft-note",
    text: "Zeitschrift fÃ¼r Religionswissenschaft (note)",
    parent: "NA",
    urlone: "http://www.degruyter.com/view/supplement/s2194508X_Manuskriptrichtlinien_de.pdf",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-rheumatologie",
    text: "Zeitschrift fÃ¼r Rheumatologie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/internal/journal/393?detailsPage=pltci_1380364",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-sexualforschung",
    text: "Zeitschrift fÃ¼r Sexualforschung",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-soziologie",
    text: "Zeitschrift fÃ¼r Soziologie (German)",
    parent: "NA",
    urlone: "http://www.zfs-online.org/index.php/zfs/information/authors",
    urltwo: "NA"
  },
  {
    id: "zeitschrift-fur-theologie-und-kirche",
    text: "Zeitschrift fÃ¼r Theologie und Kirche (German)",
    parent: "NA",
    urlone: "http://www.mohr.de/fileadmin/user_upload/Hinweise_Autoren_PDF/ZThK/2014/Richtlinien_ZThK_2014.pdf",
    urltwo: "http://www.mohr.de/en/nc/journals/theology/zeitschrift-fuer-theologie-und-kirche-zthk/manuscripts.html"
  },
  {
    id: "zeitschrift-fur-theologie-und-philosophie",
    text: "Zeitschrift fÃ¼r Theologie und Philosophie",
    parent: "NA",
    urlone: "https://ztp.jesuiten.org/",
    urltwo: "https://webapp.uibk.ac.at/ojs2/index.php/ZTP/about/submissions#authorGuidelines"
  },
  {
    id: "zeitschrift-fur-vergleichende-politikwissenschaft",
    text: "Zeitschrift fÃ¼r Vergleichende Politikwissenschaft (German)",
    parent: "springer-humanities-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-weiterbildungsforschung-report",
    text: "Zeitschrift fÃ¼r Weiterbildungsforschung - Report (German)",
    parent: "springer-socpsych-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zeitschrift-fur-zahnarztliche-implantologie",
    text: "Zeitschrift fÃ¼r ZahnÃ¤rztliche Implantologie (German)",
    parent: "NA",
    urlone: "https://www.online-zzi.de/autorengutachter/",
    urltwo: "NA"
  },
  {
    id: "zentralblatt-fur-arbeitsmedizin-arbeitsschutz-und-ergonomie",
    text: "Zentralblatt fÃ¼r Arbeitsmedizin, Arbeitsschutz und Ergonomie (German)",
    parent: "springer-fachzeitschriften-medizin-psychologie",
    urlone: "http://www.springer.com/medicine/internal/journal/40664?detailsPage=pltci_2447251",
    urltwo: "NA"
  },
  {
    id: "zentralblatt-fur-chirurgie",
    text: "Zentralblatt fÃ¼r Chirurgie",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  },
  {
    id: "zeszyty-naukowe-wco-letters-in-oncology-science",
    text: "Zeszyty Naukowe WCO, Letters in Oncology Science",
    parent: "elsevier-with-titles",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "zeszyty-prawnicze-bas",
    text: "Zeszyty Prawnicze BAS (Polish)",
    parent: "NA",
    urlone: "http://orka.sejm.gov.pl/WydBAS.nsf/PDF/ZP-wsk/$File/ZP_wskazowki_dla_autorow.pdf",
    urltwo: "NA"
  },
  {
    id: "zilsel",
    text: "Zilsel (French)",
    parent: "NA",
    urlone: "https://www.cairn.info/revue-zilsel.htm",
    urltwo: "NA"
  },
  {
    id: "zitierguide-leitfaden-zum-fachgerechten-zitieren-in-rechtswissenschaftlichen-arbeiten",
    text:
      "ZitierGuide: Leitfaden zum fachgerechten Zitieren in rechtswissenschaftlichen Arbeiten (Roger MÃ¼ller) (German - Switzerland)",
    parent: "NA",
    urlone: "http://www.worldcat.org/oclc/958980496",
    urltwo: "NA"
  },
  {
    id: "zookeys",
    text: "ZooKeys",
    parent: "pensoft-journals",
    urlone: "https://zookeys.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "zoological-journal-of-the-linnean-society",
    text: "Zoological Journal of the Linnean Society",
    parent: "NA",
    urlone: "http://onlinelibrary.wiley.com/journal/10.1111/(ISSN)1096-3642/homepage/ForAuthors.html",
    urltwo: "NA"
  },
  {
    id: "zoological-letters",
    text: "Zoological Letters",
    parent: "springer-vancouver-brackets",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zoological-studies",
    text: "Zoological Studies",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zoologischer-anzeiger",
    text: "Zoologischer Anzeiger",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "zoology-and-ecology",
    text: "Zoology and Ecology",
    parent: "taylor-and-francis-chicago-author-date",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TZEC20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "zoology-in-the-middle-east",
    text: "Zoology in the Middle East",
    parent: "apa",
    urlone: "http://www.tandfonline.com/action/authorSubmission?journalCode=TZME20&amp;page=instructions",
    urltwo: "NA"
  },
  {
    id: "zoology",
    text: "Zoology",
    parent: "elsevier-harvard",
    urlone: "NA",
    urltwo: "NA"
  },
  {
    id: "zoomorphology",
    text: "Zoomorphology",
    parent: "springer-basic-author-date",
    urlone: "http://www.springer.com/cda/content/document/cda_downloaddocument/Key_Style_Points_1.0.pdf",
    urltwo: "http://www.springer.com/cda/content/document/cda_downloaddocument/manuscript-guidelines-1.0.pdf"
  },
  {
    id: "zoosystema",
    text: "Zoosystema",
    parent: "museum-national-dhistoire-naturelle",
    urlone: "http://sciencepress.mnhn.fr/sites/default/files/periodiques/pdf/ia-zoosystema-2018-flux-continu-en.pdf",
    urltwo: "NA"
  },
  {
    id: "zoosystematics-and-evolution",
    text: "Zoosystematics and Evolution",
    parent: "pensoft-journals",
    urlone: "https://zse.pensoft.net/about#CitationsandReferences",
    urltwo: "NA"
  },
  {
    id: "zootaxa",
    text: "Zootaxa",
    parent: "NA",
    urlone: "http://www.mapress.com/zootaxa/support/author.html#Preparation%20of%20manuscripts",
    urltwo: "NA"
  },
  {
    id: "zurcher-hochschule-fur-angewandte-wissenschaften-zitierguide",
    text: "ZÃ¼rcher Hochschule fÃ¼r Angewandte Wissenschaften - ZitierGuide (Roger MÃ¼ller) (German - Switzerland)",
    parent: "zitierguide-leitfaden-zum-fachgerechten-zitieren-in-rechtswissenschaftlichen-arbeiten",
    urlone: "https://www.zhaw.ch/de/hochschulbibliothek/schreiben-publizieren/#c98258",
    urltwo: "NA"
  },
  {
    id: "zwitscher-maschine",
    text: "Zwitscher-Maschine",
    parent: "NA",
    urlone: "https://www.zwitscher-maschine.org/style-sheet",
    urltwo: "NA"
  },
  {
    id: "zwr-das-deutsche-zahnarzteblatt",
    text: "ZWR - Das Deutsche ZahnÃ¤rzteblatt",
    parent: "thieme-german",
    urlone: "http://www.thieme.de/de/autorenlounge/fuer-zeitschriftenautoren-1789.htm",
    urltwo: "NA"
  }
];
