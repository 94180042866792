import React, { useState, useEffect, useContext } from "react";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";
import { allCslStyles } from "./cslstyle";

const ReferenceStyle = () => {
  const [referenceStyle, setReferenceStyle] = useState("apa");
  const [styles, setStyles] = useState([]);
  const [selectedOption, setSelectedOption] = useState("A-F");
  const [showLoading, setShowLoading] = useState(false);
  const [linkOne, setLinkOne] = useState("");
  const [linkTwo, setLinkTwo] = useState("");
  const [isLinkUrl, setIsLinkUrl] = useState(false);

  const context = useContext(HomeContext);

  useEffect(() => {
    getUserCitationStyle(context.referenceStyle);
  }, []);

  // Get user citation style name
  const getUserCitationStyle = style => {
    let stylesArr = ["0-9", "A-F", "G-L", "M-R", "S-Z"];
    let findIndex = -1;
    findIndex = allCslStyles.findIndex(val => val.id === style);
    let urlOne = "NA";
    let urlTwo = "NA";
    let isLink = false;
    if (findIndex !== -1) {
      if (typeof allCslStyles[findIndex] !== "undefined") {
        urlOne = allCslStyles[findIndex].urlone;
        urlTwo = allCslStyles[findIndex].urltwo;
        isLink = allCslStyles[findIndex].urlone === "NA" && allCslStyles[findIndex].urltwo === "NA" ? false : true;
        let styleResult = getStyleValue(stylesArr, allCslStyles[findIndex].text);
        filterCslStylesList(styleResult);
      } else {
        filterCslStylesList(styleResult);
      }
    } else {
      let styleResult = getStyleValue(stylesArr, "apa");
      filterCslStylesList(styleResult);
    }
    setReferenceStyle(style);
    setIsLinkUrl(isLink);
    setLinkOne(urlOne);
    setLinkTwo(urlTwo);
  };

  // Filter csl styles from csl styles list
  const filterCslStylesList = value => {
    let strArr = value.split("-");
    let firstCharCode = strArr[0].charCodeAt(0);
    let secondCharCode = strArr[1].charCodeAt(0);
    let allCharacters = [];
    for (let i = firstCharCode; i <= secondCharCode; i++) {
      allCharacters.push(String.fromCharCode(i));
    }
    let finalResultArr = [];
    allCslStyles.forEach(cslStyle => {
      if (typeof cslStyle.text !== "undefined") {
        let isExist = allCharacters.findIndex(val => val.toLowerCase() === cslStyle.text[0].toLowerCase().trim());
        if (isExist !== -1) {
          finalResultArr.push(cslStyle);
        }
      }
    });
    setStyles(finalResultArr);
    setSelectedOption(value);
  };

  const getStyleValue = (styleArr, styleValue) => {
    let result = "";
    for (let i = 0; i < styleArr.length; i++) {
      let strArr = styleArr[i].split("-");
      let firstCharCode = strArr[0].charCodeAt(0);
      let secondCharCode = strArr[1].charCodeAt(0);
      let allCharacters = [];
      for (let i = firstCharCode; i <= secondCharCode; i++) {
        allCharacters.push(String.fromCharCode(i));
      }
      let findIndex = allCharacters.findIndex(val => val.toLowerCase() === styleValue[0].toLowerCase().trim());
      if (findIndex !== -1) {
        result = styleArr[i];
      }
    }
    return result;
  };

  // Get csl styles array
  const getCslStyles = e => {
    e.persist();
    filterCslStylesList(e.target.value);
  };

  // Change Reference list
  const changeReferenceList = event => {
    if (event.target.value !== "") {
      getUserCitationStyle(event.target.value);
    }
  };

  //   Insert reference list to doc
  const insertReferenceListToDoc = () => {
    let citeproc = context.getCiteProc(context.orderByArticles);
    let result = citeproc.makeBibliography();
    let referenceArr = [];
    for (let index = 0; index < result[1].length; index++) {
      let xmlString = result[1][index];
      let domString = new DOMParser().parseFromString(xmlString, "text/html");
      // let elementsArr = domString.getElementsByTagName("div");
      let elementsArr = domString.getElementsByClassName("csl-entry");
      if (elementsArr[0].firstElementChild) {
        if (elementsArr[0].firstElementChild.classList.contains("csl-left-margin")) {
          referenceArr.push(
            elementsArr[0].firstElementChild.textContent +
              " " +
              elementsArr[0].childNodes[elementsArr[0].childNodes.length - 2].innerHTML
          );
        } else {
          referenceArr.push(elementsArr[0].innerHTML);
        }
      } else {
        referenceArr.push(elementsArr[0].innerHTML);
      }
    }
    Word.run(context => {
      let doc = context.document;
      let originalRange = doc.getSelection();
      return context.sync().then(() => {
        let contentControl = originalRange.insertContentControl();
        contentControl.appearance = Word.ContentControlAppearance.boundingBox;
        contentControl.tag = "reference-citations";
        contentControl.insertHtml(referenceArr.join("<br>"), "End");
        context.load(contentControl);
        return context.sync();
      });
    }).catch(error => {
      console.log("Error: " + error);
      if (error instanceof OfficeExtension.Error) {
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      }
    });
  };
  const updateCitedList = () => {
    setShowLoading(true);
    context
      .addOrUpdateUserCitationStyle(referenceStyle)
      .then(result => {
        context.updateCitationDoc();
        setShowLoading(false);
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <div className="dr-top-buttons">
      <h4 className="dr-tb-title">Select reference style {selectedOption}</h4>
      <div className="dr-tb-input-all">
        <div className="dr-tb-input-box">
          <input
            type="radio"
            name="ref_style"
            value="0-9"
            checked={selectedOption === "0-9"}
            onChange={e => getCslStyles(e)}
          />{" "}
          <span className="dr-tb-input-label">0-9</span>
        </div>
        <div className="dr-tb-input-box">
          <input
            type="radio"
            name="ref_style"
            value="A-F"
            checked={selectedOption === "A-F"}
            onChange={e => getCslStyles(e)}
          />{" "}
          <span className="dr-tb-input-label">A-F</span>
        </div>
        <div className="dr-tb-input-box">
          <input
            type="radio"
            name="ref_style"
            value="G-L"
            checked={selectedOption === "G-L"}
            onChange={e => getCslStyles(e)}
          />{" "}
          <span className="dr-tb-input-label">G-L</span>
        </div>
        <div className="dr-tb-input-box">
          <input
            type="radio"
            name="ref_style"
            value="M-R"
            checked={selectedOption === "M-R"}
            onChange={e => getCslStyles(e)}
          />{" "}
          <span className="dr-tb-input-label">M-R</span>
        </div>
        <div className="dr-tb-input-box">
          <input
            type="radio"
            name="ref_style"
            value="S-Z"
            checked={selectedOption === "S-Z"}
            onChange={e => getCslStyles(e)}
          />{" "}
          <span className="dr-tb-input-label">S-Z</span>
        </div>
      </div>
      <Select2
        value={referenceStyle}
        data={styles}
        options={{
          placeholder: "Search Style"
        }}
        onChange={e => changeReferenceList(e)}
      />
      {/* <button>Reference list name</button> */}
      <div className="dr-tb-uir-buttons">
        <button onClick={() => updateCitedList()}>
          Update
          {showLoading ? <img src={http.BASE_URL + "assets/generator.svg"} alt="" /> : null}
        </button>
        <button onClick={() => insertReferenceListToDoc()}>
          {/* <img src={http.BASE_URL + "assets/insert.png"} alt="" /> */}
          Insert list
        </button>
        <button onClick={() => context.goToUrl("https://www.nxref.com/contact-us/")}>
          {/* <img src={http.BASE_URL + "assets/request.png"} alt="" /> */}
          Request a style
        </button>
      </div>
    </div>
  );
};

export default ReferenceStyle;
