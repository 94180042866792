import React, { useContext } from "react";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";

const SingleArticle = ({ article, isNxrUser, seqNumber }) => {
  const context = useContext(HomeContext);
  return (
    <div className="single-document-reference">
      {isNxrUser ? (
        <span className="edit-it" onClick={() => context.updateArticleInfo(article)}>
          <img src={http.BASE_URL + "assets/edit.svg"} alt="" />
        </span>
      ) : null}
      <p className="spec">
        {context.citationIsNumberFormat ? <span style={{ marginRight: "5px" }}>[{seqNumber}]</span> : null}
        <a href="#0" onClick={() => context.goToUrl(article.article_url)}>
          {article.title}
        </a>
      </p>
      <p className="info">
        {context.generateAuthorsFormat(article) + "-" + article.journal_name + " - " + article.publication_year}
      </p>
      {article.notes.length > 0 ? (
        <p className="up-down" onClick={() => context.toggleArticleNotes(article)}>
          <img src={http.BASE_URL + "assets/down-white.png"} alt="" />
        </p>
      ) : null}
      {article.isShowNotes ? (
        <div className="show">
          Quoted notes/images: <br />
          {article.notes.map((note, noteIndex) => {
            if (note.selected_note !== "" && note.selected_note !== null) {
              return (
                <p key={noteIndex}>
                  <span>{parseInt(noteIndex + 1) + ") " + note.selected_note}</span>
                </p>
              );
            } else {
              return (
                <p key={noteIndex}>
                  <span>
                    {parseInt(noteIndex + 1) + ") "}
                    <img src={note.downloadable_url} alt="" />
                  </span>
                </p>
              );
            }
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SingleArticle;
