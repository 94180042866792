import React, { useState, useContext, useLayoutEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";
import CitationList from "./CitationList";
import Article from "../../api/Article";

toast.configure();

const SelectedCitation = ({ searchOnline, recommendedArticles, userTopics }) => {
  const [scrolled, setScrolled] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isInsertBibProcess, setIsInsertBibProcess] = useState(false);
  const [docUpdateIcon, setDocUpdateIcon] = useState(false);
  const [withQuotes, setWithQuotes] = useState(false);

  const context = useContext(HomeContext);

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPos = window.scrollY;
    if (scrollPos > 240) {
      setScrolled(true);
    }
    if (scrollPos < 240) {
      setIsCollapse(false);
      setScrolled(false);
    }
  };

  // Check for expand
  const checkForExpand = () => {
    let collapseStatus = !isCollapse;
    setIsCollapse(collapseStatus);
  };

  const toggleDiv = event => {
    event.persist();
    let clickedElement = event.target;
    clickedElement.classList.toggle("active");
    let panel = clickedElement.nextElementSibling;
    if (panel) {
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  };

  // Will be deleted later
  const dummyFunction = () => {};

  const checkWithQuotes = event => {
    event.persist();
    setWithQuotes(event.target.checked);
  };

  // Insert bibliography
  const insertBibliography = () => {
    if (context.selectedArticles.length > 0) {
      setIsInsertBibProcess(true);
      Article.updateArticlesInformationByCrossref({ articles: context.selectedArticles })
        .then(response => {
          console.log(response);
          let citeproc = context.getCiteProc(context.selectedArticles);
          let result = citeproc.makeBibliography();
          let referenceArr = [];
          for (let index = 0; index < result[1].length; index++) {
            let xmlString = result[1][index];
            let domString = new DOMParser().parseFromString(xmlString, "text/html");
            let selectedArticleId = Number(result[0].entry_ids[index]);
            let selectedArticle = context.selectedArticles.find(val => val.id === selectedArticleId);
            let bibliographyText;
            let elementsArr = domString.getElementsByClassName("csl-entry");
            if (elementsArr[0].firstElementChild) {
              if (elementsArr[0].firstElementChild.classList.contains("csl-left-margin")) {
                bibliographyText =
                  "<strong>" +
                  elementsArr[0].firstElementChild.textContent +
                  " " +
                  elementsArr[0].childNodes[elementsArr[0].childNodes.length - 2].innerHTML +
                  "</strong>";
              } else {
                bibliographyText = "<strong>" + elementsArr[0].innerHTML + "</strong>";
              }
            } else {
              bibliographyText = "<strong>" + elementsArr[0].innerHTML + "</strong>";
            }
            let annotationText = `<br><a href='${selectedArticle.article_url}'>${selectedArticle.article_url}</a>`;
            if (selectedArticle.labels.length > 0) {
              let labels = [];
              selectedArticle.labels.forEach(label => {
                labels.push(label.label_name.group_name);
              });
              annotationText += `<br><strong>Article group labels:</strong> ${labels.join(", ")}`;
            }
            if (selectedArticle.annotation) {
              annotationText += `<br><strong>Annotation:</strong><br>&emsp;&emsp;${selectedArticle.annotation.replace(
                /(?:\r\n|\r|\n)/g,
                "<br>&emsp;&emsp;"
              )}`;
            }
            bibliographyText += annotationText;
            if (withQuotes) {
              if (selectedArticle.notes.length > 0) {
                let selectedArticlesNotes = "<br><strong>Quotes [notes]:</strong><br>";
                selectedArticle.notes.forEach(note => {
                  if (note.selected_note) {
                    selectedArticlesNotes += `"${note.selected_note}"`;
                  }
                  if (note.user_custom_note) {
                    selectedArticlesNotes += ` [${note.user_custom_note}]`;
                  }
                  selectedArticlesNotes += "<br>";
                });
                bibliographyText += selectedArticlesNotes;
              }
            }
            referenceArr.push(bibliographyText);
          }
          Word.run(context => {
            let doc = context.document;
            let originalRange = doc.getSelection();
            return context.sync().then(() => {
              originalRange.insertHtml(referenceArr.join("<br><br>"), "End");
              setIsInsertBibProcess(false);
              return context.sync();
            });
          }).catch(error => {
            setIsInsertBibProcess(false);
            console.log("Error: " + error);
            if (error instanceof OfficeExtension.Error) {
              console.log("Debug info: " + JSON.stringify(error.debugInfo));
            }
          });
        })
        .catch(err => {
          setIsInsertBibProcess(false);
          toast.error("Something went wrong", { autoClose: 2000 });
        });
    }
  };

  // Update all doc file
  const updateAllDocFile = () => {
    setDocUpdateIcon(true);
    context.updateCitationDoc();
    setTimeout(() => {
      setDocUpdateIcon(false);
    }, 1500);
  };

  const citedTextList = context.citedTexts.map((text, index) => {
    return (
      <CitationList
        key={index}
        index={index}
        citationText={text.show_citation_text}
        citationNotes={text.notes}
        citatedArticle={text}
        isNxr={context.userInfo.nxr_user}
        fileName={context.userInfo.nxr_user === 1 ? context.documentUrl : context.forSharebleName}
        deleteCitationData={context.deleteCitationData}
        deleteCitatedNote={context.deleteCitatedNote}
        toggleDiv={toggleDiv}
        title={text.article.title}
        pdfDownloaded={text.article.article_downloaded}
        showIcon={true}
        articleUrl={text.article.article_url}
      />
    );
  });
  // Get newly added citation list
  const anotherCitedTextList = context.selectedArticles.map((article, index) => {
    let authors = article.authors !== null ? article.authors.split(";") : [""];
    let firstAuthor = authors[0].split(" ");
    let articleCitationText = firstAuthor[firstAuthor.length - 1] + " et al. " + article.publication_year;
    let filteredNotes =
      typeof article.selectedNotes !== "undefined" && article.selectedNotes.length > 0 ? article.selectedNotes : [];
    return (
      <CitationList
        key={index}
        index={index}
        citationText={articleCitationText}
        citationNotes={filteredNotes}
        citatedArticle={article}
        isNxr={context.userInfo.nxr_user}
        fileName={context.userInfo.nxr_user === 1 ? context.documentUrl : context.forSharebleName}
        deleteCitationData={context.deleteNewAddedCitation}
        deleteCitatedNote={dummyFunction}
        toggleDiv={toggleDiv}
        title={article.title}
        showIcon={false}
        articleUrl={article.article_url}
      />
    );
  });
  return (
    <div>
      <div className="accordion-fixed-wrapper">
        <div
          className={
            scrolled
              ? isCollapse
                ? "accordion-for-fixed accordion-on"
                : "accordion-for-fixed accordion-off"
              : "accordion-for-fixed-off"
          }
        >
          {context.deletedCitedList.length > 0 || citedTextList.length > 0 || anotherCitedTextList.length > 0 ? (
            <div>
              {context.userInfo.nxr_user === 1 ? (
                <div>
                  <div>
                    <button
                      className="update-cite-btn dr-tb-uir-buttons d-inline-block"
                      onClick={() => context.updateCitations()}
                      disabled={
                        context.deletedCitedList.length > 0 ||
                        context.deletedCitatedNotesList.length > 0 ||
                        anotherCitedTextList.length > 0
                          ? false
                          : true
                      }
                    >
                      Update Citation
                      {context.showCitationInsertBtn ? (
                        <img
                          style={{ maxHeight: "15px", paddingLeft: "5px" }}
                          src={http.BASE_URL + "assets/generator.svg"}
                          alt=""
                        />
                      ) : null}
                    </button>
                    {anotherCitedTextList.length > 0 && !searchOnline && !recommendedArticles && !userTopics ? (
                      <button
                        className="update-cite-btn dr-tb-uir-buttons d-inline-block"
                        onClick={() => insertBibliography()}
                        style={{ marginLeft: "5px" }}
                        disabled={context.onContentControl}
                        title="Insert Annotated Bibliography"
                      >
                        Insert Anno. Biblio.
                        {isInsertBibProcess ? (
                          <img
                            style={{ maxHeight: "15px", paddingLeft: "5px" }}
                            src={http.BASE_URL + "assets/generator.svg"}
                            alt=""
                          />
                        ) : null}
                      </button>
                    ) : null}
                  </div>
                  {anotherCitedTextList.length > 0 && !searchOnline && !recommendedArticles && !userTopics ? (
                    <div style={{ textAlign: "right", fontSize: "12px" }}>
                      <input type="checkbox" style={{ margin: '0px' }} onChange={event => checkWithQuotes(event)} /> Include quotes & notes
                    </div>
                  ) : null}
                </div>
              ) : null}
              {scrolled ? (
                <button
                  className={isCollapse ? "accordion-fixed-btn btn-on" : "accordion-fixed-btn btn-off"}
                  title={isCollapse ? "Hide Citation List" : "Show Citation List"}
                  onClick={() => checkForExpand()}
                >
                  updown
                </button>
              ) : null}
            </div>
          ) : (
            <div title="Update any kind of changes in citations">
              {context.userInfo.nxr_user === 1 ? (
                <div>
                  <button
                    className="update-cite-btn dr-tb-uir-buttons d-inline-block"
                    onClick={() => updateAllDocFile()}
                  >
                    Update Citation
                    {docUpdateIcon ? (
                      <img
                        style={{ maxHeight: "15px", paddingLeft: "5px" }}
                        src={http.BASE_URL + "assets/generator.svg"}
                        alt=""
                      />
                    ) : null}
                  </button>
                </div>
              ) : null}
            </div>
          )}
          <div className="accordion-area">{citedTextList}</div>
          <div className="accordion-area">{anotherCitedTextList}</div>
        </div>
      </div>
    </div>
  );
};

export default SelectedCitation;
