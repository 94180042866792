import * as React from "react";
import { IconButton, Modal } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import Articles from "./Articles";
import Notes from "./Notes";
import Images from "./Images";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";
import ArticleLabel from "../../api/ArticleLabel";
import Search from "../../api/Search";

export default class HomeLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchType: "allfields",
      userId: "",
      groupInformation: {
        isGrouped: 0,
        groupInfo: null
      },
      readItLater: 0,
      isNext: false,
      articles: [],
      notes: [],
      images: [],
      searchText: "",
      allUsers: false,
      yoursOnly: true,
      isArticle: true,
      isNote: false,
      isImage: false,
      groups: [],
      selectedGroups: [],
      pageNumber: 1,
      articlesLimit: 10,
      showLoading: true,
      isLoadingArticles: false,
      isSorting: false,
      pages: [],
      pagesNotes: [],
      showPaginateDiv: false,
      showArticleModal: false,
      showAnnotateModal: false,
      showSearchInfoModal: false,
      selectedArticleNotes: [],
      inactiveArticleLabel: true,
      searchFor: "normal",
      annotationText: ""
    };
  }

  // Sort articles labels
  sortArticlesLabels = groups => {
    groups.sort((a, b) => {
      let groupNameA = a.group_name.toUpperCase(); // ignore upper and lowercase
      let groupNameB = b.group_name.toUpperCase(); // ignore upper and lowercase
      if (this.state.isSorting) {
        if (groupNameA > groupNameB) {
          return -1;
        }
        if (groupNameA < groupNameB) {
          return 1;
        }
      } else {
        if (groupNameA < groupNameB) {
          return -1;
        }
        if (groupNameA > groupNameB) {
          return 1;
        }
      }
      // names must be equal
      return 0;
    });
    return groups;
  };

  // Ascending or descending articles labels
  ascOrDscArticlesLabels = () => {
    this.setState({ isSorting: !this.state.isSorting }, () => {
      let sortedLabels = this.sortArticlesLabels(this.state.groups);
      this.setState({ groups: sortedLabels });
    });
  };

  // Get user group info by user id
  getUserGroupInfoByUserId = userId => {};

  // Await user latest articles
  getAllArticles = () => {
    let selectedLabels = this.state.selectedGroups.length > 0 ? this.state.selectedGroups.join(",") : "";
    let searchArticles;
    if (this.state.yoursOnly) {
      searchArticles = Search.getArticlesBySearch(
        this.state.pageNumber,
        this.state.articlesLimit,
        this.state.readItLater,
        this.state.searchType,
        this.state.searchText,
        this.state.searchFor,
        selectedLabels
      );
    } else {
      searchArticles = Search.getGroupArticlesBySearch(
        this.state.pageNumber,
        this.state.articlesLimit,
        this.state.readItLater,
        this.state.searchType,
        this.state.searchText,
        this.state.searchFor,
        selectedLabels
      );
    }
    searchArticles
      .then(response => {
        let articlesResult = [];
        response.data.data.forEach(result => {
          articlesResult.push(result);
        });
        if (this.state.pageNumber !== response.data.last_page) {
          this.setState({
            showPaginateDiv: true,
            isLoadingArticles: false,
            showLoading: false,
            inactiveArticleLabel: false
          });
        } else {
          this.setState({
            showPaginateDiv: false,
            isLoadingArticles: false,
            showLoading: false,
            inactiveArticleLabel: false
          });
        }
        this.setState({
          pages: [...this.state.pages, { pageNumber: response.data.current_page, articles: articlesResult }]
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Get notes by user information
  getNotesByUserInfo = type => {
    let selectedLabels = this.state.selectedGroups.length > 0 ? this.state.selectedGroups.join(",") : "";
    let searchNotes;
    if (this.state.yoursOnly) {
      searchNotes = Search.getUserNotesBySearch(
        this.state.pageNumber,
        this.state.articlesLimit,
        this.state.searchType,
        this.state.searchText,
        type,
        this.state.searchFor,
        selectedLabels
      );
    } else {
      searchNotes = Search.getUserGroupNotesBySearch(
        this.state.pageNumber,
        this.state.articlesLimit,
        this.state.searchType,
        this.state.searchText,
        type,
        this.state.searchFor,
        selectedLabels
      );
    }
    searchNotes
      .then(response => {
        let notesResult = [];
        response.data.data.forEach(result => {
          result.will_paste = false;
          notesResult.push(result);
        });
        if (this.state.pageNumber !== response.data.last_page) {
          this.setState({
            showPaginateDiv: true,
            isLoadingArticles: false,
            showLoading: false,
            inactiveArticleLabel: false
          });
        } else {
          this.setState({
            showPaginateDiv: false,
            isLoadingArticles: false,
            showLoading: false,
            inactiveArticleLabel: false
          });
        }
        this.setState({
          pagesNotes: [...this.state.pagesNotes, { pageNumber: response.data.current_page, notes: notesResult }]
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Get next articles result
  getNextArticles = () => {
    let pageNumber = this.state.pageNumber + 1;
    this.setState({ pageNumber: pageNumber, isLoadingArticles: true, inactiveArticleLabel: true }, () => {
      this.getAllArticles();
    });
  };

  // Get next notes result
  getNextNotesResult = type => {
    let pageNumber = this.state.pageNumber + 1;
    this.setState({ pageNumber: pageNumber, isLoadingArticles: true, inactiveArticleLabel: true }, () => {
      this.getNotesByUserInfo(type);
    });
  };

  // Get all articles groups
  getAllArticlesGroups = () => {
    ArticleLabel.getAllArticleLabels({
      group_id: this.props.userInfo.group_info !== null ? this.props.userInfo.group_info.group_id : 0
    })
      .then(response => {
        let groups = this.sortArticlesLabels(response.data);
        this.setState({ groups: groups });
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Handle checkboxes value
  handleCheckBoxValues = event => {
    event.persist();
    let selectedGroups = this.state.selectedGroups;
    if (event.target.checked) {
      selectedGroups.push(event.target.value);
    } else {
      let index = selectedGroups.indexOf(event.target.value);
      selectedGroups.splice(index, 1);
    }
    this.setState(
      {
        selectedGroups: selectedGroups,
        pages: [],
        showLoading: true,
        isArticle: true,
        isNote: false,
        isImage: false,
        pageNumber: 1,
        showPaginateDiv: false,
        isLoadingArticles: false,
        inactiveArticleLabel: true
      },
      () => {
        this.getAllArticles();
      }
    );
  };

  componentDidMount() {
    this.getAllArticlesGroups();

    this.getAllArticles();
  }

  // Submit search form
  submit = event => {
    event.preventDefault();
    event.persist();
    const searchType = event.target.elements.searchType.value;
    const searchText = event.target.elements.searchText.value;
    this.setState(
      {
        pages: [],
        pagesNotes: [],
        showLoading: true,
        isNext: false,
        searchType: searchType,
        searchText: searchText,
        pageNumber: 1,
        showPaginateDiv: false,
        isLoadingArticles: false,
        inactiveArticleLabel: true
      },
      () => {
        if (this.state.isArticle) {
          this.getAllArticles();
        } else if (this.state.isNote) {
          this.getNotesByUserInfo("notes");
        } else {
          this.getNotesByUserInfo("images");
        }
      }
    );
  };

  // Reset search text
  resetSearchText = () => {
    const $select = document.querySelector("#searchType");
    $select.value = "allfields";
    this.setState(
      {
        pages: [],
        showLoading: true,
        isNext: false,
        isArticle: true,
        isNote: false,
        isImage: false,
        searchType: "allfields",
        searchText: "",
        pageNumber: 1,
        showPaginateDiv: false,
        isLoadingArticles: false,
        inactiveArticleLabel: true,
        searchFor: "normal"
      },
      () => {
        this.getAllArticles();
      }
    );
  };

  // Filtered article notes
  filteredNote = (note, searchText) => {
    if (note.selected_note.toLowerCase().includes(searchText.toLowerCase())) {
      return note;
    } else if (note.user_custom_note.toLowerCase().includes(searchText.toLowerCase())) {
      return note;
    }
    return {};
  };

  // Filtered article images
  filteredImage = (note, searchText) => {
    if (note.user_custom_note.toLowerCase().includes(searchText.toLowerCase())) {
      if (note.selected_figure !== "") {
        return note;
      }
    }
    return {};
  };

  // set search type
  setSearchType = selectedOption => {
    this.setState({ searchType: selectedOption.key });
  };

  //   change search routes
  changeSearchRoute = routeType => {
    if (routeType === "articles") {
      this.setState(
        {
          pages: [],
          showLoading: true,
          isNext: false,
          isArticle: true,
          isNote: false,
          isImage: false,
          pageNumber: 1,
          isLoadingArticles: false,
          inactiveArticleLabel: true
        },
        () => {
          this.getAllArticles();
        }
      );
    } else if (routeType === "notes") {
      this.setState(
        {
          pagesNotes: [],
          showLoading: true,
          isNext: false,
          isArticle: false,
          isNote: true,
          isImage: false,
          pageNumber: 1,
          isLoadingArticles: false,
          inactiveArticleLabel: true
        },
        () => {
          // Get all Article notes
          this.getNotesByUserInfo("notes");
        }
      );
    } else {
      this.setState(
        {
          pagesNotes: [],
          showLoading: true,
          isNext: false,
          isArticle: false,
          isNote: false,
          isImage: true,
          pageNumber: 1,
          isLoadingArticles: false,
          inactiveArticleLabel: true
        },
        () => {
          // Get all article images
          this.getNotesByUserInfo("images");
        }
      );
    }
  };

  // Change search type
  changeSearchType = e => {
    e.persist();
    this.setState({ searchType: e.target.value });
  };

  // change filtered article search user type
  changeSearchUserType = userType => {
    if (userType == "group") {
      this.setState({ allUsers: true, yoursOnly: false }, () => {
        this.changeSearchRoute("articles");
      });
    } else {
      this.setState({ allUsers: false, yoursOnly: true }, () => {
        this.changeSearchRoute("articles");
      });
    }
  };

  // Filtered articles with labels
  filterWithArticlesLabels = () => {};

  // Handle input field
  handleInputField = e => {
    e.persist();
    this.setState({ searchText: e.target.value });
  };

  handleSearchCheckField = e => {
    e.persist();
    if (e.target.checked) {
      this.setState({ searchFor: "exact" });
    } else {
      this.setState({ searchFor: "normal" });
    }
  };

  // Reset articles labels
  resetArticleLabels = () => {
    this.setState(
      {
        selectedGroups: []
      },
      () => {
        this.changeSearchRoute("articles");
      }
    );
  };

  // Sync all articles for add ins
  syncAddIns = () => {
    this.setState(
      {
        searchType: "allfields",
        isNext: false,
        articles: [],
        notes: [],
        images: [],
        searchText: "",
        allUsers: false,
        yoursOnly: true,
        isArticle: true,
        isNote: false,
        isImage: false,
        groups: [],
        selectedGroups: [],
        pageNumber: 1,
        articlesLimit: 10,
        showLoading: true,
        isLoadingArticles: false,
        isSorting: false,
        pages: [],
        pagesNotes: [],
        showPaginateDiv: false,
        showArticleModal: false,
        selectedArticleNotes: [],
        inactiveArticleLabel: true,
        searchFor: "normal"
      },
      () => {
        this.getAllArticlesGroups();
        this.getAllArticles();
        toast.success("Synced successfully", { autoClose: 2000 });
      }
    );
  };

  showArticleNotes = article => {
    let notesResult = [];
    article.notes.forEach(note => {
      let data = note;
      data.will_paste = false;
      notesResult.push(data);
    });
    this.setState({ showArticleModal: true, selectedArticleNotes: notesResult });
  };

  showAnnotateBibliography = article => {
    let annotationText = article.annotation ? article.annotation.replace(/(?:\r\n|\r|\n)/g, "<br>") : "";
    this.setState({ showAnnotateModal: true, annotationText: annotationText });
  };

  closeArticleModal = () => {
    this.setState({ showArticleModal: false, selectedArticleNotes: [] });
  };

  closeAnnotateModal = () => {
    this.setState({ showAnnotateModal: false, annotationText: "" });
  };

  // Change active inactive paste status
  activeOrInactivePasteState = (note, isActive) => {
    let allSelectedNotes = this.state.selectedArticleNotes;
    let findIndex = allSelectedNotes.findIndex(val => val.id === note.id);
    allSelectedNotes[findIndex].will_paste = isActive;
    this.setState({ selectedArticleNotes: allSelectedNotes });
  };

  // Active or inactive page notes
  activeOrInactivePageNotesPasteState = (pageIndex, noteIndex, isActive) => {
    let allPagesNotes = this.state.pagesNotes;
    let note = allPagesNotes[pageIndex].notes[noteIndex];
    note.will_paste = isActive;
    this.setState({ pagesNotes: allPagesNotes });
  };

  // Check note is selected or not
  checkNoteIsSelected = (citedTexts, note) => {
    let findIndex = citedTexts.findIndex(val => val.article_id === note.article_id);
    if (findIndex !== -1) {
      let noteIndex = citedTexts[findIndex].notes.findIndex(val => val.note_id === note.id);
      if (noteIndex !== -1) {
        return true;
      }
    }
    return false;
  };

  showSearchInfoText = () => {
    this.setState({ showSearchInfoModal: true });
  };

  closeSearchInfoModal = () => {
    this.setState({ showSearchInfoModal: false });
  };

  render() {
    return (
      <HomeContext.Consumer>
        {context => {
          const groupsList = this.state.groups.map((group, index) => {
            if (group.group_name !== "") {
              return (
                <div key={index} className="signle-edit">
                  <input
                    type="checkbox"
                    checked={this.state.selectedGroups.indexOf(group.id.toString()) !== -1}
                    value={group.id}
                    onChange={event => this.handleCheckBoxValues(event)}
                    disabled={this.state.inactiveArticleLabel}
                  />
                  <span
                    className="text"
                    style={{ backgroundColor: this.state.inactiveArticleLabel ? "#EBEBE4" : null }}
                  >
                    {group.group_name}
                  </span>
                </div>
              );
            }
          });

          const { showModal, closeModal, showModalStatus, pasteOptionEnable } = context;

          return (
            <div>
              {/* Search section */}
              <div className="top-search">
                <form onSubmit={this.submit}>
                  <div className="input-box">
                    <select id="searchType" name="searchType" onChange={e => this.changeSearchType(e)}>
                      <option value="allfields">All Fields</option>
                      <option value="authors">Authors</option>
                      <option value="title">Titles</option>
                      <option value="quotes">Quotes</option>
                      <option value="notes">Tags/Notes</option>
                      <option value="journalname">Journal Name</option>
                      <option value="year">Year</option>
                    </select>
                    <input
                      type="text"
                      value={this.state.searchText}
                      id="searchText"
                      name="searchText"
                      placeholder="Search your library"
                      onChange={e => this.handleInputField(e)}
                    />
                    <button type="submit">
                      <img src={http.BASE_URL + "assets/search-2.png"} alt="" />
                    </button>
                    <a href="#0" className="refreshbtn" onClick={() => this.resetSearchText()}>
                      <img src={http.BASE_URL + "assets/refresh.svg"} alt="" style={{ height: "13px" }} />
                    </a>
                    <a
                      className="external-link"
                      style={{ margin: "5px 0 0 0" }}
                      href="#0"
                      onClick={() => this.showSearchInfoText()}
                    >
                      <img
                        src={http.BASE_URL + "assets/info_icon.svg"}
                        style={{ height: "20px", width: "40px" }}
                        alt=""
                      />
                    </a>
                  </div>
                  {this.state.searchType === "quotes" || this.state.searchType === "notes" ? (
                    <span className="search-info-text">*This option may not work well for Articles.</span>
                  ) : null}
                  <div className="search-check">
                    <input
                      type="checkbox"
                      value="true"
                      checked={this.state.searchFor === "exact"}
                      onChange={e => this.handleSearchCheckField(e)}
                      style={{ verticalAlign: "middle" }}
                    />
                    <span style={{ fontSize: "11px" }}>Match exact words</span>
                  </div>
                </form>
              </div>
              {/* Filter with labels section */}
              <div
                className={
                  this.props.userInfo.group_info !== null ? "top-radios-edit top-radios-edit-second" : "top-radios-edit"
                }
              >
                {this.props.userInfo.group_info !== null ? (
                  <div className="tre-radios">
                    <label className="label">Library:</label>
                    <div className="two-box">
                      <div className="box">
                        <input
                          type="radio"
                          checked={this.state.yoursOnly ? true : false}
                          onChange={() => this.changeSearchUserType("yours")}
                          id="yours"
                          name="yg"
                          value="yours"
                        />
                        <label htmlFor="yours">
                          <img src={http.BASE_URL + "assets/single-user.png"} alt="" />
                        </label>
                      </div>
                      <div className="box">
                        <input
                          type="radio"
                          checked={this.state.allUsers ? true : false}
                          id="group​"
                          name="yg"
                          onChange={() => this.changeSearchUserType("group")}
                          value="group​"
                        />
                        <label htmlFor="group​">
                          <img src={http.BASE_URL + "assets/multi-user.png"} alt="" />
                        </label>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className={this.props.userInfo.group_info === null ? "tre-edit tre-edit-second" : "tre-edit"}>
                  <button
                    onClick={showModalStatus}
                    style={{ background: this.state.selectedGroups.length > 0 ? "#1f8ea7" : "#81a8b1" }}
                  >
                    Filter Labels
                  </button>
                </div>
                <div className="tre-reset">
                  <button type="button" onClick={() => this.resetArticleLabels()}>
                    <img src={http.BASE_URL + "assets/reset.png"} alt="" />
                  </button>
                </div>
              </div>
              <ul className="second-tab tab-list">
                <li className={this.state.isArticle ? "active" : null}>
                  <a href="#" onClick={() => this.changeSearchRoute("articles")}>
                    Articles​
                  </a>
                </li>
                <li className={this.state.isNote ? "active" : null}>
                  <a href="#" onClick={() => this.changeSearchRoute("notes")}>
                    Quotes
                  </a>
                </li>
                <li className={this.state.isImage ? "active" : null}>
                  <a href="#" onClick={() => this.changeSearchRoute("images")}>
                    Images​
                  </a>
                </li>
              </ul>
              {/* Show more button section */}
              {this.state.showLoading ? (
                <div className="all-article" style={{ margin: "auto", width: "15%" }}>
                  <img src={http.BASE_URL + "assets/generator.svg"} alt="" />
                </div>
              ) : (
                <div className="all-article">
                  {this.state.isArticle ? (
                    <Articles
                      pages={this.state.pages}
                      articleNotes={article => this.showArticleNotes(article)}
                      annotateBibliography={article => this.showAnnotateBibliography(article)}
                    />
                  ) : null}
                  {this.state.isNote ? (
                    <Notes
                      pagesNotes={this.state.pagesNotes}
                      activeOrInactivePageNotesPasteState={this.activeOrInactivePageNotesPasteState}
                    />
                  ) : null}
                  {this.state.isImage ? <Images pagesNotes={this.state.pagesNotes} /> : null}
                  {/* Show more button for articles */}
                  {this.state.isArticle && this.state.showPaginateDiv ? (
                    <div className="all-article" style={{ margin: "auto", width: "30%" }}>
                      {!this.state.isLoadingArticles ? (
                        <button type="button" onClick={() => this.getNextArticles()}>
                          Show more
                        </button>
                      ) : (
                        <button type="button" style={{ border: "none" }}>
                          <img src={http.BASE_URL + "assets/loading.gif"} alt="" style={{ maxHeight: "25px" }} />
                        </button>
                      )}
                    </div>
                  ) : null}
                  {/* Show more button for notes */}
                  {this.state.isNote && this.state.showPaginateDiv ? (
                    <div className="all-article" style={{ margin: "auto", width: "30%" }}>
                      {!this.state.isLoadingArticles ? (
                        <button type="button" onClick={() => this.getNextNotesResult("notes")}>
                          Show more
                        </button>
                      ) : (
                        <button type="button" style={{ border: "none" }}>
                          <img src={http.BASE_URL + "assets/loading.gif"} alt="" style={{ maxHeight: "25px" }} />
                        </button>
                      )}
                    </div>
                  ) : null}
                  {this.state.isImage && this.state.showPaginateDiv ? (
                    <div className="all-article" style={{ margin: "auto", width: "30%" }}>
                      {!this.state.isLoadingArticles ? (
                        <button type="button" onClick={() => this.getNextNotesResult("images")}>
                          Show more
                        </button>
                      ) : (
                        <button type="button" style={{ border: "none" }}>
                          <img src={http.BASE_URL + "assets/loading.gif"} alt="" style={{ maxHeight: "25px" }} />
                        </button>
                      )}
                    </div>
                  ) : null}
                </div>
              )}
              {context.contentStyles != null && context.iconButtonStyles != null ? (
                <div>
                  <Modal
                    className="edit-modal"
                    titleAriaId="title"
                    subtitleAriaId="subtext"
                    isOpen={showModal}
                    onDismiss={closeModal}
                    isBlocking={false}
                    containerClassName={context.contentStyles.container}
                  >
                    <div className="">
                      <span id="title" className="title"></span>
                      <IconButton
                        className="close-btn"
                        styles={context.iconButtonStyles}
                        iconProps={{ iconName: "Cancel" }}
                        ariaLabel="Close popup modal"
                        onClick={closeModal}
                      />
                    </div>
                    <div id="subtext" className="">
                      <button className="update-cite-btn" onClick={() => this.ascOrDscArticlesLabels()}>
                        Sort Labels
                      </button>
                      <div className="all-edits">{groupsList}</div>
                    </div>
                  </Modal>
                  <Modal
                    className="notes-modal"
                    titleAriaId="title"
                    subtitleAriaId="subtext"
                    isOpen={this.state.showArticleModal}
                    onDismiss={this.closeArticleModal}
                    isBlocking={false}
                    containerClassName={context.contentStyles.container}
                  >
                    <div className="">
                      <h4 className="title">
                        <span className="inner" id="title">
                          Quotes/Images
                        </span>
                      </h4>
                      <IconButton
                        className="close-btn"
                        styles={context.iconButtonStyles}
                        iconProps={{ iconName: "Cancel" }}
                        ariaLabel="Close popup modal"
                        onClick={() => this.closeArticleModal()}
                      />
                    </div>
                    <div className="img-info">
                      <ul>
                        {this.state.selectedArticleNotes.map((articleNote, index) => {
                          return (
                            <li key={index} style={{ borderBottom: "1px solid", marginBottom: "8px" }}>
                              <input
                                type="checkbox"
                                value={articleNote.id}
                                checked={context.selectedNotes.findIndex(val => val.id === articleNote.id) !== -1}
                                onChange={event => context.setArticlesNotes(event, articleNote)}
                                disabled={this.checkNoteIsSelected(context.citedTexts, articleNote)}
                              />
                              {articleNote.selected_note !== "" && articleNote.selected_note !== null ? (
                                pasteOptionEnable ? (
                                  articleNote.will_paste ? (
                                    <img
                                      src={http.BASE_URL + "assets/paste-active.svg"}
                                      className="paste-icon"
                                      onClick={() => this.activeOrInactivePasteState(articleNote, false)}
                                    />
                                  ) : (
                                    <img
                                      src={http.BASE_URL + "assets/paste-inactive.svg"}
                                      className="paste-icon"
                                      onClick={() => this.activeOrInactivePasteState(articleNote, true)}
                                    />
                                  )
                                ) : (
                                  <img src={http.BASE_URL + "assets/paste-inactive.svg"} className="paste-icon-dpl" />
                                )
                              ) : null}
                              {articleNote.selected_note !== "" && articleNote.selected_note !== null ? (
                                <p className="text">{articleNote.selected_note}</p>
                              ) : (
                                <img src={articleNote.downloadable_url} alt="" />
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </Modal>
                  <Modal
                    className="notes-modal"
                    titleAriaId="title"
                    subtitleAriaId="subtext"
                    isOpen={this.state.showAnnotateModal}
                    onDismiss={this.closeAnnotateModal}
                    isBlocking={false}
                    containerClassName={context.contentStyles.container}
                  >
                    <div className="">
                      <h4 className="title">
                        <span className="inner" id="title">
                          Notes for annotated bibliography
                        </span>
                      </h4>
                      <IconButton
                        className="close-btn"
                        styles={context.iconButtonStyles}
                        iconProps={{ iconName: "Cancel" }}
                        ariaLabel="Close popup modal"
                        onClick={() => this.closeAnnotateModal()}
                      />
                    </div>
                    <div className="img-info" dangerouslySetInnerHTML={{ __html: this.state.annotationText }}></div>
                  </Modal>
                  <Modal
                    className="edit-modal"
                    titleAriaId="title"
                    subtitleAriaId="subtext"
                    isOpen={this.state.showSearchInfoModal}
                    onDismiss={this.closeSearchInfoModal}
                    isBlocking={false}
                    containerClassName={context.contentStyles.container}
                  >
                    <div className="">
                      <span id="title" className="title"></span>
                      <IconButton
                        className="close-btn"
                        styles={context.iconButtonStyles}
                        iconProps={{ iconName: "Cancel" }}
                        ariaLabel="Close popup modal"
                        onClick={() => this.closeSearchInfoModal()}
                      />
                    </div>
                    <div id="subtext" className="control-wrap">
                      <ul>
                        <li>To search, edit and press ENTER</li>
                        <li>Search query applies in all results until it is cleared</li>
                        <li>Blank search by pressing ENTER returns all results</li>
                      </ul>
                    </div>
                  </Modal>
                </div>
              ) : null}
            </div>
          );
        }}
      </HomeContext.Consumer>
    );
  }
}
