import * as React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import SearchArticles from "./SearchArticles";
import Topic from "./Topic";
import { http } from "../../utils/http";
import TopicsApi from "../../api/Topics";
import "../../topics.css";

export default class Topics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pageLimit: 10,
      showPaginateDiv: false,
      searchFor: "normal",
      searchText: "",
      pages: [],
      loadingTopics: false,
      showLoading: true
    };
  }

  // Get all topics
  getAllTopics = () => {
    TopicsApi.getUserTopics(this.state.currentPage, this.state.pageLimit, this.state.searchFor, this.state.searchText)
      .then(response => {
        this.storeTopicsData(response);
        this.setState({ showLoading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ showLoading: false });
      });
  };

  // Store topics data
  storeTopicsData = response => {
    let topicsResult = [];
    response.data.data.forEach(result => {
      let notes = [];
      result.topic_notes.forEach(topicNote => {
        let selectedNote = topicNote.note;
        selectedNote.will_paste = false;
        notes.push(selectedNote);
      });
      result.notes = notes;
      result.will_topic_paste = false;
      topicsResult.push(result);
    });
    if (this.state.currentPage !== response.data.last_page) {
      this.setState({ showPaginateDiv: true, loadingTopics: false });
    } else {
      this.setState({ showPaginateDiv: false, loadingTopics: false });
    }
    this.setState({
      pages: [...this.state.pages, { pageNumber: response.data.current_page, topics: topicsResult }]
    });
  };

  activeOrInactiveTopicNotesPasteState = (pageIndex, topicIndex, noteIndex, status) => {
    let allPagesTopics = this.state.pages;
    let note = allPagesTopics[pageIndex].topics[topicIndex].notes[noteIndex];
    note.will_paste = status;
    this.setState({ pages: allPagesTopics });
  };

  // Get next articles result
  nextTopics = () => {
    let pageNumber = this.state.currentPage + 1;
    this.setState({ currentPage: pageNumber, loadingTopics: true }, () => {
      this.getAllTopics();
    });
  };

  componentDidMount() {
    this.getAllTopics();
  }

  // Handle input field
  handleInputField = e => {
    e.persist();
    this.setState({ searchText: e.target.value });
  };

  handleSearchCheckField = e => {
    e.persist();
    if (e.target.checked) {
      this.setState({ searchFor: "exact" });
    } else {
      this.setState({ searchFor: "normal" });
    }
  };

  // Submit search form
  submit = event => {
    event.preventDefault();
    event.persist();
    const searchText = event.target.elements.searchText.value;
    this.setState(
      {
        currentPage: 1,
        showPaginateDiv: false,
        searchText: searchText,
        pages: [],
        loadingTopics: false,
        showLoading: true
      },
      () => {
        this.getAllTopics();
      }
    );
  };

  // Reset search text
  resetSearchText = () => {
    this.setState(
      {
        currentPage: 1,
        showPaginateDiv: false,
        searchText: "",
        searchFor: "normal",
        pages: [],
        loadingTopics: false,
        showLoading: true
      },
      () => {
        this.getAllTopics();
      }
    );
  };

  // Active inactive topic status
  activeInactiveTopicStatus = (pageIndex, topicIndex, status) => {
    let allPagesTopics = this.state.pages;
    let topic = allPagesTopics[pageIndex].topics[topicIndex];
    topic.will_topic_paste = status;
    topic.notes.forEach(note => {
      note.will_paste = status;
    });
    this.setState({ pages: allPagesTopics });
  };

  render() {
    return (
      <div>
        {/* Search section */}
        <div className="top-search topsearch-bar">
          <form onSubmit={this.submit}>
            <div className="input-box">
              <input
                type="text"
                value={this.state.searchText}
                id="searchText"
                name="searchText"
                placeholder="Search your topics"
                onChange={e => this.handleInputField(e)}
                required
              />
              <button className="search-online-btn" type="submit">
                <img src={http.BASE_URL + "assets/search-2.png"} alt="" />
              </button>
              <a href="#0" className="refreshbtn2" onClick={() => this.resetSearchText()}>
                <img src={http.BASE_URL + "assets/refresh.svg"} alt="" style={{ height: "13px" }} />
              </a>
            </div>
            <div className="search-check">
              <input
                type="checkbox"
                value="true"
                checked={this.state.searchFor === "exact"}
                onChange={e => this.handleSearchCheckField(e)}
                style={{ verticalAlign: "middle" }}
              />
              <span style={{ fontSize: "11px" }}>Match exact words</span>
            </div>
          </form>
        </div>
        <div className="all-article">
          {this.state.showLoading ? (
            <div className="all-article" style={{ margin: "auto", width: "15%" }}>
              <img src={http.BASE_URL + "assets/generator.svg"} alt="" />
            </div>
          ) : (
            <div className="accordion">
              {this.state.pages.map((page, index) => (
                <div key={index}>
                  {page.pageNumber > 1 ? <div className="separator">Page {page.pageNumber}</div> : null}
                  {page.topics.map((topic, topicIndex) => (
                    <Topic
                      topic={topic}
                      pageIndex={index}
                      topicIndex={topicIndex}
                      activeOrInactiveTopicNotesPasteState={this.activeOrInactiveTopicNotesPasteState}
                      activeInactiveWholeTopicStatus={this.activeInactiveTopicStatus}
                      key={topicIndex}
                    />
                  ))}
                </div>
              ))}
            </div>
          )}
          {/* Show more button for search articles */}
          {this.state.showPaginateDiv ? (
            <div className="all-article" style={{ margin: "auto", width: "30%" }}>
              {!this.state.loadingTopics ? (
                <button type="button" onClick={() => this.nextTopics()}>
                  Show more
                </button>
              ) : (
                <button type="button">
                  <img src={http.BASE_URL + "assets/loading.gif"} alt="" style={{ maxHeight: "25px" }} />
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
