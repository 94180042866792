import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import SearchArticles from "./SearchArticles";
import { http } from "../../utils/http";

toast.configure();

const SearchOnlinePage = ({ userInfo }) => {
  const [initialOffset, setInitialOffset] = useState(0);
  const [offset, setOffset] = useState(0);
  const [nextPageNumber, setNextPageNumber] = useState(1);
  const [queryTerms, setQueryTerms] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pages, setPages] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isNextArticles, setIsNextArticles] = useState(false);

  const handleInputField = e => {
    e.persist();
    setSearchText(e.target.value);
  };

  const submit = event => {
    event.preventDefault();
    event.persist();
    setPages([]);
    setShowLoading(true);
    let queryParamsArr = [];
    queryParamsArr = searchText.trim().split(" ");
    let querySearchTerms = queryParamsArr.join("+");
    setQueryTerms(querySearchTerms);
    axios
      .get(
        `https://api.semanticscholar.org/graph/v1/paper/search?query=${querySearchTerms}&fields=externalIds,url,abstract,authors,title,year,isOpenAccess,fieldsOfStudy&offset=${initialOffset}&limit=10`,
        {
          headers: {
            "x-api-key": "cjTcDODy386lKlycStJmc2bUsgP4KcIN2jYBhWRT"
          }
        }
      )
      .then(response => {
        let responseData = response.data.data;
        let articles = [];
        responseData.forEach(data => {
          let authors = [];
          let article = {};
          data.authors.forEach(author => {
            authors.push(author.name);
          });
          article.title = data.title;
          article.authors = authors.join("; ");
          article.abstract = data.abstract;
          article.doi_link = typeof data.externalIds.DOI !== "undefined" ? data.externalIds.DOI : "";
          article.open_access = data.isOpenAccess;
          article.article_url = data.url;
          article.group_id = userInfo.group_info !== null ? userInfo.group_info.group_id : 0;
          article.publication_year = data.year;
          article.journal_name = data.fieldsOfStudy ? data.fieldsOfStudy[0] : "";
          articles.push(article);
        });
        if (articles.length > 0) {
          articles.sort((a, b) => {
            return b.publication_year - a.publication_year;
          });
          setPages(oldPages => [...oldPages, { pageNumber: 1, articles: articles }]);
        }
        setShowLoading(false);
        if (typeof response.data.next !== "undefined") {
          setOffset(response.data.next);
          setIsNextArticles(true);
        } else {
          setIsNextArticles(false);
        }
      })
      .catch(err => {
        console.log(err);
        setShowLoading(false);
      });
  };

  // Reset online search
  const resetOnlineSearch = () => {
    setPages([]);
    setOffset(0);
    setSearchText("");
    setQueryTerms("");
  };

  const nextSearchArticles = () => {
    setLoadingArticles(true);
    axios
      .get(
        `https://api.semanticscholar.org/graph/v1/paper/search?query=${queryTerms}&fields=externalIds,url,abstract,authors,title,year,isOpenAccess,fieldsOfStudy&offset=${offset}&limit=10`,
        {
          headers: {
            "x-api-key": "cjTcDODy386lKlycStJmc2bUsgP4KcIN2jYBhWRT"
          }
        }
      )
      .then(response => {
        let responseData = response.data.data;
        let articles = [];
        let currentPageNumber = parseInt(nextPageNumber) + 1;
        console.log(currentPageNumber);
        responseData.forEach(data => {
          let authors = [];
          let article = {};
          data.authors.forEach(author => {
            authors.push(author.name);
          });
          article.title = data.title;
          article.authors = authors.join("; ");
          article.abstract = data.abstract;
          article.doi_link = typeof data.externalIds.DOI !== "undefined" ? data.externalIds.DOI : "";
          article.open_access = data.isOpenAccess;
          article.article_url = data.url;
          article.group_id = userInfo.group_info !== null ? userInfo.group_info.group_id : 0;
          article.publication_year = data.year;
          article.journal_name = data.fieldsOfStudy ? data.fieldsOfStudy[0] : "";
          articles.push(article);
        });
        if (articles.length > 0) {
          articles.sort((a, b) => {
            return b.publication_year - a.publication_year;
          });
          setPages(oldPages => [...oldPages, { pageNumber: currentPageNumber, articles: articles }]);
        }
        setNextPageNumber(currentPageNumber);
        if (typeof response.data.next !== "undefined") {
          setOffset(response.data.next);
          setIsNextArticles(true);
        } else {
          setIsNextArticles(false);
        }
        setLoadingArticles(false);
        console.log(pages);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Search section */}
      <div className="top-search topsearch-bar">
        <form onSubmit={submit}>
          <div className="input-box">
            <input
              type="text"
              value={searchText}
              id="searchText"
              name="searchText"
              placeholder="Search online"
              onChange={e => handleInputField(e)}
              required
            />
            <button className="search-online-btn" type="submit">
              <img src={http.BASE_URL + "assets/search-2.png"} alt="" />
            </button>
            <a href="#0" className="refreshbtn2" onClick={() => resetOnlineSearch()}>
              <img src={http.BASE_URL + "assets/refresh.svg"} alt="" style={{ height: "13px" }} />
            </a>
          </div>
        </form>
        <span className="info-text">
          Powered-by{" "}
          <a href="https://www.semanticscholar.org/" target="_blank" rel="noopener noreferrer">
            Semantic Scholar
          </a>
        </span>
      </div>
      <div className="all-article">
        {showLoading ? (
          <div className="all-article" style={{ margin: "auto", width: "15%" }}>
            <img src={http.BASE_URL + "assets/generator.svg"} alt="" />
          </div>
        ) : (
          <SearchArticles pages={pages} />
        )}
        {/* Show more button for search articles */}
        {pages.length > 0 && isNextArticles ? (
          <div className="all-article" style={{ margin: "auto", width: "30%" }}>
            {!loadingArticles ? (
              <button type="button" onClick={() => nextSearchArticles()}>
                Show more
              </button>
            ) : (
              <button type="button">
                <img src={http.BASE_URL + "assets/loading.gif"} alt="" style={{ maxHeight: "25px" }} />
              </button>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchOnlinePage;
