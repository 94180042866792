import React, { useContext } from "react";
import { IconButton, Modal } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { HomeContext } from "../../contexts/HomeContext";
import ReferenceArticles from "../../api/ReferenceArticles";

toast.configure();

const ShareArticle = () => {
  const context = useContext(HomeContext);

  const shareReferenceList = event => {
    event.preventDefault();
    event.persist();

    let email = event.target.elements.email.value;
    let password = event.target.elements.password.value;

    let documentReferenceIds = [];
    let documentReferenceNotesIds = [];

    context.inlineReferenceArticles.forEach(referenceArticle => {
      documentReferenceIds.push(referenceArticle.id);
      if (referenceArticle.notes.length > 0) {
        referenceArticle.notes.forEach(note => {
          documentReferenceNotesIds.push(note.id);
        });
      }
    });

    let data = {
      user: {
        company: "N/A",
        email: email,
        is_grouped: context.userInfo.group_info !== null ? 1 : 0,
        location: "N/A",
        name: "N/A",
        nxr_user: 0,
        profession: "N/A",
        surname: "N/A",
        title: "N/A",
        password: password,
        citation_style: context.userInfo.citation_style
      },
      document_reference_ids: documentReferenceIds,
      document_reference_note_ids: documentReferenceNotesIds,
      documentName: context.documentUrl,
      sharedUserDocumentName: context.forSharebleName,
      citationStyle: context.referenceStyle
    };

    ReferenceArticles.storeSharedReferences(data)
      .then(response => {
        toast.success("Reference List Shared Successfully", { autoClose: 2000 });
        context.closeModal();
      })
      .catch(err => {
        console.log(err);
        toast.error("Something Went Wrong", { autoClose: 2000 });
      });
  };

  return (
    <main className="ms-welcome__main">
      {context.contentStyles != null && context.iconButtonStyles != null ? (
        <Modal
          className="images-modal"
          titleAriaId="title"
          subtitleAriaId="subtext"
          isOpen={context.showShareModal}
          onDismiss={context.closeModal}
          isBlocking={false}
          containerClassName={context.contentStyles.container}
        >
          <div className="">
            <IconButton
              className="close-btn"
              styles={context.iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={context.closeModal}
            />
          </div>
          <div id="subtext" className="">
            <h4 className="title">
              <span className="inner">Share all cited materials</span>
            </h4>
            <div>
              <form onSubmit={shareReferenceList} className="welcome-login-form">
                <div className="welcome-login-full" style={{ padding: "0px" }}>
                  <div className="welcome-input-box">Email:</div>
                  <div className="welcome-input-box">
                    <input type="email" placeholder="Email" name="email" value={context.sharedEmail} readOnly />
                  </div>
                  <div className="welcome-input-box">Password:</div>
                  <div className="welcome-input-box">
                    <input type="text" placeholder="Password" name="password" value={context.sharedPassword} readOnly />
                  </div>
                  <div className="submit">
                    <button type="submit">Share</button>
                  </div>
                  <div className="no-account" style={{ marginTop: "34px" }}>
                    <ol className="share-info">
                      <li>Copy following login detail</li>
                      <li>Click Share</li>
                      <li>Share the detail along the document with the reviewers</li>
                      <li>
                        Ask reviewers to login in nXr.iCite Office add-in with the detail after opening the document
                      </li>
                      <li>Reviewers shouldn’t change the document file name.</li>
                    </ol>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      ) : null}
    </main>
  );
};

export default ShareArticle;
