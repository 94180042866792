import Api from "./Api";

export default {
  /**
   * Get user latest articles
   *
   * @param {number} pageNumber
   * @param {number} pageLimit
   * @param {number} readItLater
   * @param {string} labels
   * @returns response from api
   */
  getUserLatestArticles(pageNumber, pageLimit, readItLater, labels = "") {
    return Api().get("/getUserLatestArticles", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        readItLater: readItLater,
        labels: labels
      }
    });
  },

  getGroupLatestArticles(pageNumber, pageLimit, readItLater, labels = "") {
    return Api().get("/getGroupLatestArticles", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        readItLater: readItLater,
        labels: labels
      }
    });
  },

  getUserGroupLatestArticles(pageNumber, pageLimit) {
    return Api().get("/getUserGroupLatestArticles", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit
      }
    });
  },

  // Delete articles record
  deleteArticlesRecord(data) {
    return Api().post("/deleteArticlesRecord", data);
  },

  // Get Article by ID
  getArticleById(data) {
    return Api().post("/getArticleById", data);
  },

  // Update article information
  updateArticleInfo(data) {
    return Api().post("/updateArticleInfo", data);
  },

  // Get articles result by doi from crossref
  updateArticlesInformationByCrossref(data) {
    return Api().post("/updateArticlesInformationByCrossref", data);
  },

  // Get article by DOI
  getArticleByDoi(data) {
    return Api().post("/getArticleByDoi", data);
  }
};
