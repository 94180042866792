import Api from "./Api";

export default {
  login(form) {
    return Api().post("/login", form);
  },

  auth() {
    return Api().get("/user");
  },

  resetPassword(data) {
    return Api().post("/resetPassword", data);
  },

  reset(data) {
    return Api().post("/reset", data);
  },

  logout(data) {
    return Api().post("/logout", data);
  },

  groupUserRegistration(data) {
    return Api().post("/groupRegistration", data);
  },

  updateUserRecord(data) {
    return Api().post("/updateUserRecord", data);
  }
};
