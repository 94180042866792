import React, { useContext, useState } from "react";
import { IconButton, Modal } from "office-ui-fabric-react";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";

const SearchArticles = ({ pages }) => {
  const [showModal, setShowModal] = useState(false);
  const [articleAbstract, setArticleAbstract] = useState("");

  const context = useContext(HomeContext);

  const setModalStatus = article => {
    setArticleAbstract(article.abstract);
    setShowModal(true);
  };

  const closeModalStatus = () => {
    setShowModal(false);
  };

  const articleList = pages.map((page, index) => {
    return (
      <div key={index}>
        {page.pageNumber > 1 ? <div className="separator">Page {page.pageNumber}</div> : null}
        {page.articles.map((article, articleIndex) => {
          return (
            <div key={articleIndex} className="single-article">
              <p className="info">
                <input
                  type="checkbox"
                  value={0}
                  onChange={event => context.setArticlesValues(event, article)}
                  checked={context.selectedArticles.findIndex(val => val.article_url === article.article_url) !== -1}
                />
                {/* {article.abstract ? (
              <a className="external-link" href="#0" onClick={() => setModalStatus(article)} title="Abstract">
                <img src={http.BASE_URL + "assets/2.png"} style={{ height: "18px" }} alt="" />
              </a>
            ) : (
              <a className="external-link" href="#0" title="Abstract">
                <img src={http.BASE_URL + "assets/2.png"} style={{ height: "18px" }} alt="" />
              </a>
            )} */}
              </p>
              <a href="#0" className="text" onClick={() => context.goToUrl(article.article_url)}>
                {article.title}
              </a>
              <p className="info">
                {context.generateAuthorsFormat(article) +
                  " - " +
                  article.journal_name +
                  " - " +
                  article.publication_year}
              </p>
              <Modal
                className="edit-modal"
                titleAriaId="title"
                subtitleAriaId="subtext"
                isOpen={showModal}
                onDismiss={closeModalStatus}
                isBlocking={false}
                containerClassName={context.contentStyles.container}
              >
                <div className="">
                  <span id="title" className="title"></span>
                  <IconButton
                    className="close-btn"
                    styles={context.iconButtonStyles}
                    iconProps={{ iconName: "Cancel" }}
                    ariaLabel="Close popup modal"
                    onClick={() => closeModalStatus()}
                  />
                </div>
                <div id="subtext" className="">
                  <p>{articleAbstract}</p>
                </div>
              </Modal>
            </div>
          );
        })}
      </div>
    );
  });
  return articleList;
};

export default SearchArticles;
