import React, { useState, useContext } from "react";
import { IconButton, Modal } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import axios from "axios";
import SearchArticles from "./SearchArticles";
import { http } from "../../utils/http";
import { HomeContext } from "../../contexts/HomeContext";

toast.configure();

const Recommendation = ({ userInfo }) => {
  const context = useContext(HomeContext);

  const [pages, setPages] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [searchTypes, setSearchTypes] = useState([]);
  const [searchTopics, setSearchTopics] = useState([]);
  const [recommendationModel, setRecommendationModel] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [showTopicModal, setShowTopicModal] = useState(false);

  const setTopicsModalStatus = () => {
    setShowTopicModal(true);
  };

  const closeTopicsModalStatus = () => {
    setShowTopicModal(false);
  };

  const recommendatedArticles = event => {
    event.preventDefault();
    event.persist();
    setPages([]);
    setSearchTopics([]);
    setShowLoading(true);
    axios
      .post(`${http.RECOMMENDED_API_URL}/topics`, {
        articles: JSON.stringify(context.referenceArticles),
        types: JSON.stringify(searchTypes),
        model: recommendationModel,
        year: filterYear
      })
      .then(response => {
        let responseData = response.data.data;
        let articles = [];
        responseData.forEach(data => {
          let authors = [];
          let article = {};
          data.authors.forEach(author => {
            authors.push(author.name);
          });
          article.title = data.title;
          article.authors = authors.join("; ");
          article.abstract = data.abstract;
          article.doi_link = typeof data.externalIds.DOI !== "undefined" ? data.externalIds.DOI : "";
          article.open_access = data.isOpenAccess;
          article.article_url = data.url;
          article.group_id = userInfo.group_info !== null ? userInfo.group_info.group_id : 0;
          article.publication_year = data.year;
          article.journal_name = data.fieldsOfStudy ? data.fieldsOfStudy[0] : "";
          articles.push(article);
        });
        if (articles.length > 0) {
          /* articles.sort((a, b) => {
            return b.publication_year - a.publication_year;
          }); */
          setPages(oldPages => [...oldPages, { pageNumber: 1, articles: articles }]);
          setSearchTopics(response.data.topics);
        }
        setShowLoading(false);
      })
      .catch(err => {
        console.log(err);
        setShowLoading(false);
      });
  };

  const setSearchTypesValues = (event, type) => {
    event.persist();
    let selectedSearchTypes = searchTypes;
    if (event.target.checked) {
      selectedSearchTypes.push(type);
    } else {
      let index = selectedSearchTypes.findIndex(val => val === type);
      selectedSearchTypes.splice(index, 1);
    }
    setSearchTypes(searchTypes);
  };

  const setRecommendationModelValue = (event, model) => {
    event.persist();
    setRecommendationModel(model);
  };

  const setRecommendationFilteredYear = (event, year) => {
    event.persist();
    setFilterYear(year);
  };

  return (
    <div>
      {/* Search section */}
      <div className="top-search topsearch-bar">
        <form onSubmit={recommendatedArticles}>
          <span>Recommendation based on references: </span> <br />
          <div className="recommendation-input-box" style={{ height: "50px" }}>
            <input type="checkbox" value={"titles"} onChange={event => setSearchTypesValues(event, "titles")} /> Titles
            <input
              type="checkbox"
              value={"abstract"}
              onChange={event => setSearchTypesValues(event, "abstract")}
            />{" "}
            Abstract
            <input type="checkbox" value={"quotes"} onChange={event => setSearchTypesValues(event, "quotes")} /> Quotes
          </div>
          <span>Model option: </span> <br />
          <div className="recommendation-input-box" style={{ height: "50px" }}>
            <input
              type="radio"
              name="model"
              value={"model_A"}
              onChange={event => setRecommendationModelValue(event, "model_A")}
            />{" "}
            Model A
            <input
              type="radio"
              name="model"
              value={"model_B"}
              onChange={event => setRecommendationModelValue(event, "model_B")}
            />{" "}
            Model B
          </div>
          <span>Publication year: </span> <br />
          <div className="recommendation-input-box" style={{ height: "50px" }}>
            <input
              type="radio"
              name="year"
              value={"current_year"}
              onChange={event => setRecommendationFilteredYear(event, "1")}
            />{" "}
            this year
            <input
              type="radio"
              name="year"
              value={"three_year"}
              onChange={event => setRecommendationFilteredYear(event, "3")}
            />{" "}
            3 yrs old
            <input
              type="radio"
              name="year"
              value={"five_year"}
              onChange={event => setRecommendationFilteredYear(event, "5")}
            />{" "}
            5 yrs old
          </div>
          <div className="recommendation-input-box" style={{ height: "50px" }}>
            <button type="button" onClick={() => setTopicsModalStatus()}>
              Show topics
            </button>
          </div>
          <div className="recommendation-input-box">
            <button type="submit">Find relevant references</button>
          </div>
        </form>
      </div>
      <div className="all-article">
        {showLoading ? (
          <div className="all-article" style={{ margin: "auto", width: "15%" }}>
            <img src={http.BASE_URL + "assets/generator.svg"} alt="" />
          </div>
        ) : (
          <SearchArticles pages={pages} />
        )}
        {/* {pages.length > 0 && isNextArticles ? (
          <div className="all-article" style={{ margin: "auto", width: "30%" }}>
            {!loadingArticles ? (
              <button type="button" onClick={() => nextSearchArticles()}>
                Show more
              </button>
            ) : (
              <button type="button">
                <img src={http.BASE_URL + "assets/loading.gif"} alt="" style={{ maxHeight: "25px" }} />
              </button>
            )}
          </div>
        ) : null} */}
        <Modal
          className="edit-modal"
          titleAriaId="title"
          subtitleAriaId="subtext"
          isOpen={showTopicModal}
          onDismiss={closeTopicsModalStatus}
          isBlocking={false}
          containerClassName={context.contentStyles.container}
        >
          <div className="">
            <span id="title" className="title"></span>
            <IconButton
              className="close-btn"
              styles={context.iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={() => closeTopicsModalStatus()}
            />
          </div>
          <div id="subtext" className="" style={{ wordBreak: "break-all" }}>
            <ul>
              {searchTopics.map((topic, index) => {
                return (
                  <ol style={{ paddingLeft: "0px" }} key={index}>
                    {topic}
                  </ol>
                );
              })}
            </ul>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Recommendation;
