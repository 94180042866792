export const http = {
  // BASE_URL: "https://localhost:3000/",
  // BASE_URL: "https://tazpub.com/addins/",
  STYLE_URL: "/icite/",
  BASE_URL: "https://www.nxref.com/addins/",
  /* STYLE_URL: "/icite/",
  BASE_URL: "https://www.nxref.com/pcite/", */
  // API_URL: "https://nxref.com/webapi/api",
  // API_URL: "http://localhost/webapi/api",
  API_URL: "https://nxref.com/nxrapi/api",
  LIBRARY_URL: "https://nxref.com/lib/",
  // LIBRARY_URL: "http://localhost:8080/",
  RECOMMENDED_API_URL: "https://recommendation.webmascot.net"
};
