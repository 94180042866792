import Api from "./Api";

export default {
  getAllArticleLabels(data) {
    return Api().post("/getAllArticleLabels", data);
  },

  // Insert article labels
  insertBulkArticleLabels(data) {
    return Api().post("/insertBulkArticleLabels", data);
  },

  // insert bulk article labels relation data
  insertBulkArticleLabelsRelations(data) {
    return Api().post("/insertBulkArticleLabelsRelations", data);
  }
};
