import Api from "./Api";

export default {
  getCurrentUserNotes(pageNumber, pageLimit, type, labels) {
    return Api().get("/getCurrentUserNotes", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        type: type,
        labels: labels
      }
    });
  },

  getGroupNotes(pageNumber, pageLimit, type, labels) {
    return Api().get("/getGroupNotes", {
      params: {
        page: pageNumber,
        pageLimit: pageLimit,
        type: type,
        labels: labels
      }
    });
  },

  // Update note record
  updateNoteRecord(data) {
    return Api().post("/updateNoteRecord", data);
  }
};
