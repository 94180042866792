import React, { useContext, useState } from "react";
import { IconButton, Modal } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";
import ReferenceArticles from "../../api/ReferenceArticles";

toast.configure();

const UpdateCitation = ({
  citeArticle,
  showCitationModal,
  closeCitationModal,
  setCitationPageType,
  setCitationLocation,
  setCitationRole,
  clearCitationLocation,
  isError,
  isErrorOccured
}) => {
  const context = useContext(HomeContext);

  const checkQuotesPages = quotes => {
    const re = new RegExp("^[0-9, ]*$");
    if (re.test(quotes)) {
      return true;
    }
    return false;
  };

  const updateCitationInformation = event => {
    event.preventDefault();
    event.persist();

    if (checkQuotesPages(event.target.elements.quotes_in_pages.value)) {
      let data = {
        id: citeArticle.id,
        quotes_in_pages: event.target.elements.quotes_in_pages.value,
        pages_type: event.target.elements.pages_type.value,
        citation_location: citeArticle.citation_location,
        citation_role: citeArticle.citation_role,
        custom_citation_text: event.target.elements.custom_citation_text.value
      };

      ReferenceArticles.updateCitationInformation(data)
        .then(response => {
          console.log(response);
          return context.updateCitationDoc();
        })
        .then(() => {
          toast.success("Citation updated successfully", { autoClose: 2000 });
          if (isError) {
            isErrorOccured(false);
          }
          closeCitationModal();
        })
        .catch(err => {
          console.log(err);
          closeCitationModal();
          toast.error("Something went wrong", { autoClose: 2000 });
        });
    } else {
      isErrorOccured(true);
    }
  };

  return (
    <main className="ms-welcome__main">
      {context.contentStyles != null && context.iconButtonStyles != null ? (
        <Modal
          className="images-modal"
          titleAriaId="title"
          subtitleAriaId="subtext"
          isOpen={showCitationModal}
          onDismiss={closeCitationModal}
          isBlocking={false}
          containerClassName={context.contentStyles.container}
        >
          <div className="">
            <IconButton
              className="close-btn"
              styles={context.iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={closeCitationModal}
            />
          </div>
          <div id="subtext" className="">
            <h4 className="title">
              <span className="inner">Citation settings</span>
            </h4>
            <div>
              <form onSubmit={updateCitationInformation} className="welcome-login-form">
                <div className="single-article">
                  <p className="info control-wrap">
                    <span className="text-wrap">Quote/image page numbers</span>
                    <input
                      type="text"
                      name="quotes_in_pages"
                      placeholder="Comma separated page numbers"
                      style={{ width: "100%" }}
                      defaultValue={citeArticle.quotes_in_pages}
                      disabled={context.userInfo.nxr_user === 0}
                    />
                    {isError ? <span style={{ color: "red" }}>Please give valid data</span> : null}
                    <br />
                    <input
                      type="radio"
                      name="pages_type"
                      value="Relative"
                      checked={citeArticle.pages_type === "Relative" || citeArticle.pages_type === null}
                      onChange={event => setCitationPageType(event, "Relative")}
                      disabled={context.userInfo.nxr_user === 0}
                    />{" "}
                    Relative
                    <input
                      className="mr-2"
                      type="radio"
                      name="pages_type"
                      value="Exact"
                      checked={citeArticle.pages_type === "Exact"}
                      onChange={event => setCitationPageType(event, "Exact")}
                      disabled={context.userInfo.nxr_user === 0}
                    />{" "}
                    Exact
                    <img
                      src={http.BASE_URL + "assets/info_icon.svg"}
                      style={{ height: "20px", width: "40px" }}
                      alt=""
                      onClick={() =>
                        context.goToUrl("https://nxref.com/citation-tool-quotes-page-numbers-citation-intent/")
                      }
                    />
                  </p>
                </div>
                <div className="single-article">
                  <div className="info control-wrap bg-white">
                    <span className="text-wrap margin">Citation Intent</span>
                    <span className="mr-2" style={{ cursor: "pointer" }} onClick={() => clearCitationLocation()}>
                      clear
                    </span>
                    <span>
                      <img
                        src={http.BASE_URL + "assets/info_icon.svg"}
                        style={{ height: "20px", width: "40px" }}
                        alt=""
                        onClick={() =>
                          context.goToUrl("https://nxref.com/citation-tool-quotes-page-numbers-citation-intent/")
                        }
                      />
                    </span>
                    <div className="row first">
                      <div className="col-md-6">
                        <div className="input-wraper">
                          <input
                            type="radio"
                            name="citation_location"
                            value="Introduction"
                            checked={citeArticle.citation_location === "Introduction"}
                            onChange={event => setCitationLocation(event, "Introduction")}
                            disabled={context.userInfo.nxr_user === 0}
                          />{" "}
                          Introduction
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-wraper">
                          <input
                            type="radio"
                            name="citation_location"
                            value="Materials_and_Methods"
                            checked={citeArticle.citation_location === "Materials_and_Methods"}
                            onChange={event => setCitationLocation(event, "Materials_and_Methods")}
                            disabled={context.userInfo.nxr_user === 0}
                          />{" "}
                          Materials and Methods
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-wraper">
                          <input
                            type="radio"
                            name="citation_location"
                            value="Result_and_Discussion"
                            checked={citeArticle.citation_location === "Result_and_Discussion"}
                            onChange={event => setCitationLocation(event, "Result_and_Discussion")}
                            disabled={context.userInfo.nxr_user === 0}
                          />{" "}
                          Result and Discussion
                        </div>
                      </div>
                    </div>

                    <div className="row secand">
                      <div className="col-md-6">
                        <div className="input-wraper">
                          <input
                            type="radio"
                            name="citation_role"
                            value="Compare_Contrast"
                            disabled={citeArticle.citation_location === null || context.userInfo.nxr_user === 0}
                            checked={citeArticle.citation_role === "Compare_Contrast"}
                            onChange={event => setCitationRole(event, "Compare_Contrast")}
                          />{" "}
                          Compare and contrast
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-wraper">
                          <input
                            type="radio"
                            name="citation_role"
                            value="Motivation"
                            disabled={citeArticle.citation_location === null || context.userInfo.nxr_user === 0}
                            checked={citeArticle.citation_role === "Motivation"}
                            onChange={event => setCitationRole(event, "Motivation")}
                          />{" "}
                          Motivation
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-wraper">
                          <input
                            type="radio"
                            name="citation_role"
                            value="Extension"
                            disabled={citeArticle.citation_location === null || context.userInfo.nxr_user === 0}
                            checked={citeArticle.citation_role === "Extension"}
                            onChange={event => setCitationRole(event, "Extension")}
                          />{" "}
                          Extension
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-wraper">
                          <input
                            type="radio"
                            name="citation_role"
                            value="Future"
                            disabled={citeArticle.citation_location === null || context.userInfo.nxr_user === 0}
                            checked={citeArticle.citation_role === "Future"}
                            onChange={event => setCitationRole(event, "Future")}
                          />{" "}
                          Future
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="single-article">
                  <p className="info">
                    <span className="text-wrap">Default inline citation text​</span>
                    {typeof citeArticle.citeproc_text !== "undefined" && citeArticle.citeproc_text !== null ? (
                      <input type="text" style={{ width: "100%" }} defaultValue={citeArticle.citeproc_text} disabled />
                    ) : (
                      <input
                        type="text"
                        style={{ width: "100%" }}
                        defaultValue={citeArticle.modal_citation_text}
                        disabled
                      />
                    )}
                  </p>
                </div>
                <div className="single-article">
                  <p className="info">
                    <span className="text-wrap">Edit inline citation text​​</span>
                    <input
                      type="text"
                      name="custom_citation_text"
                      style={{ width: "100%" }}
                      defaultValue={citeArticle.custom_citation_text === null ? "" : citeArticle.custom_citation_text}
                      disabled={citeArticle.disable_custom_text || context.userInfo.nxr_user === 0}
                    />
                  </p>
                </div>
                <div className="submit-wrap">
                  <button type="submit" disabled={context.userInfo.nxr_user === 0}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      ) : null}
    </main>
  );
};

export default UpdateCitation;
