import React, { useState, useContext } from "react";
import { IconButton, Modal } from "office-ui-fabric-react";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";

const Notes = ({ pagesNotes, activeOrInactivePageNotesPasteState }) => {
  const [showModal, setShowModal] = useState(false);
  const [note, setNote] = useState({});
  const [article, setArticle] = useState({});

  const context = useContext(HomeContext);

  const setModalStatus = note => {
    /* setNote({});
    setArticle({}); */
    setNote(note);
    // getNoteInformation(note);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Check note is selected or not
  const checkNoteIsSelected = (citedTexts, note) => {
    let findIndex = citedTexts.findIndex(val => val.article_id === note.article_id);
    if (findIndex !== -1) {
      let noteIndex = citedTexts[findIndex].notes.findIndex(val => val.note_id === note.id);
      if (noteIndex !== -1) {
        return true;
      }
    }
    return false;
  };

  const goToPdfUrl = note => {
    let fileName = context.userInfo.nxr_user === 1 ? context.documentUrl : context.forSharebleName;
    const pdfUrl = http.LIBRARY_URL + "viewer/" + note.article_id + "?from=icite&filename=" + fileName;
    window.open(pdfUrl, "_blank");
  };

  const notesList = pagesNotes.map((page, index) => {
    return (
      <div key={index}>
        {page.pageNumber > 1 ? <div className="separator">Page {page.pageNumber}</div> : null}
        {page.notes.map((note, noteIndex) => {
          return (
            <div key={noteIndex} className="single-notes">
              <div className="notes-div">
                <input
                  type="checkbox"
                  value={note.id}
                  checked={context.selectedNotes.findIndex(val => val.id === note.id) !== -1}
                  onChange={event => context.setArticlesNotes(event, note)}
                  disabled={checkNoteIsSelected(context.citedTexts, note)}
                />
                {context.pasteOptionEnable ? (
                  note.will_paste ? (
                    <img
                      src={http.BASE_URL + "assets/paste-active.svg"}
                      className="paste-icon"
                      onClick={() => activeOrInactivePageNotesPasteState(index, noteIndex, false)}
                    />
                  ) : (
                    <img
                      src={http.BASE_URL + "assets/paste-inactive.svg"}
                      className="paste-icon"
                      onClick={() => activeOrInactivePageNotesPasteState(index, noteIndex, true)}
                    />
                  )
                ) : (
                  <img src={http.BASE_URL + "assets/paste-inactive.svg"} className="paste-icon-dpl" />
                )}
              </div>
              <a href="#0" className="text" onClick={() => setModalStatus(note)}>
                {note.selected_note}
              </a>
              {note.article_downloaded === 1 ? (
                <a href="#0" onClick={() => goToPdfUrl(note)} className="external" style={{ right: "35px" }}>
                  <img src={http.BASE_URL + "assets/pdf.png"} alt="" />
                </a>
              ) : null}
              <a href="#0" onClick={() => context.goToArticleUrlFromNote(note)} className="external">
                <img src={http.BASE_URL + "assets/link.png"} alt="" />
              </a>
            </div>
          );
        })}
      </div>
    );
  });
  return (
    <div>
      <div className="all-notes">{notesList}</div>
      {context.contentStyles != null && context.iconButtonStyles != null ? (
        <Modal
          className="notes-modal"
          titleAriaId="title"
          subtitleAriaId="subtext"
          isOpen={showModal}
          onDismiss={closeModal}
          isBlocking={false}
          containerClassName={context.contentStyles.container}
        >
          <div className="">
            <h4 className="title">
              <span className="inner" id="title">
                Quote
              </span>
            </h4>
            <IconButton
              className="close-btn"
              styles={context.iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={() => closeModal()}
            />
          </div>
          <div className="img-info">
            <p className="text">{note.selected_note}</p>
          </div>
          <div className="input-text-info">
            <textarea
              type="text"
              disabled={note.user_id !== context.userInfo.id}
              defaultValue={note.user_custom_note}
              placeholder="Write tags/notes"
              onKeyUp={e => context.updateUserCustomNote(e, note)}
            ></textarea>
          </div>
          <div id="subtext" className="">
            <h4 className="title">
              <span className="inner">Article Information</span>
            </h4>
            <p>{note.title}</p>
            <p style={{ fontSize: "12px" }}>
              {context.generateAuthorsFormat(note) + "-" + note.journal_name + " - " + note.publication_year}
            </p>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default Notes;
