import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { http } from "../../utils/http";
import UpdateArticle from "./UpdateArticle";
import SingleArticle from "./SingleArticle";
import ReferenceStyle from "./ReferenceStyle";
import { HomeContext } from "../../contexts/HomeContext";
import Article from "../../api/Article";

toast.configure();

const DocumentReference = ({ isNxrUser }) => {
  const [showCrLoading, setShowCrLoading] = useState(false);

  const context = useContext(HomeContext);

  const updateWithCrossref = () => {
    setShowCrLoading(true);
    let articles = [];
    context.referenceArticles.forEach(article => {
      if (article.doi_link !== "" && (typeof article.is_verified === "undefined" || article.is_verified === 0)) {
        articles.push(article);
      }
    });
    if (articles.length > 0) {
      Article.updateArticlesInformationByCrossref({ articles: articles })
        .then(response => {
          console.log(response);
          return context.updateCitationDoc();
        })
        .then(() => {
          setShowCrLoading(false);
          toast.success("Reference updated successfully", { autoClose: 2000 });
        })
        .catch(err => {
          console.log(err);
          setShowCrLoading(false);
          toast.error("Something went wrong", { autoClose: 2000 });
        });
    } else {
      setShowCrLoading(false);
      toast.success("Reference already updated by crossref", { autoClose: 2000 });
    }
  };

  const articles = context.referenceArticles.map((article, index) => {
    return <SingleArticle key={index} article={article} isNxrUser={isNxrUser} seqNumber={index + 1} />;
  });

  return (
    <div>
      <div className="doccument-reference">
        {isNxrUser ? (
          <div className="doc-styles">
            <div className="dr-tb-uir-buttons cross-btn">
              <button onClick={() => updateWithCrossref()}>
                Update Article Information with Openalex
                {showCrLoading ? <img src={http.BASE_URL + "assets/generator.svg"} alt="" /> : null}
              </button>
            </div>
            <hr />
            <ReferenceStyle />
            <hr />
          </div>
        ) : null}
        {context.referenceArticles.length > 0 ? <h4>References</h4> : null}
        {articles}
        <UpdateArticle />
      </div>
    </div>
  );
};

export default DocumentReference;
