import Api from "./Api";

export default {
  getDocumentReferenceArticles(contentControls, documentName, sharableDocName) {
    return Api().get("/getDocumentReferenceArticles", {
      params: {
        documentName: documentName,
        sharableDocName: sharableDocName,
        contentControls: contentControls.join(",")
      }
    });
  },

  // Check login status with content control id
  checkLoginStatus(contentControls) {
    return Api().post("/checkLoginStatus", {
      contentControls: contentControls.join(",")
    });
  },

  // Update reference articles for new file
  updateReferenceArticles(data) {
    return Api().post("/updateReferenceArticles", data);
  },

  getDocumentReferenceNotes(contentControls, documentName, sharableDocName) {
    return Api().get("/getDocumentReferenceNotes", {
      params: {
        documentName: documentName,
        sharableDocName: sharableDocName,
        contentControls: contentControls.join(",")
      }
    });
  },

  // Delete reference articles
  deleteReferenceArticles(data) {
    return Api().post("/deleteReferenceArticles", data);
  },

  // Delete reference articles notes
  deleteReferenceArticlesNotes(data) {
    return Api().post("/deleteReferenceArticlesNotes", data);
  },

  // Store reference articles
  storeReferenceArticles(data) {
    return Api().post("/storeReferenceArticles", data);
  },

  // Store reference articles notes
  storeReferenceArticleNotes(data) {
    return Api().post("/storeReferenceArticleNotes", data);
  },

  // Get content Control ID
  getContentControlId() {
    return Api().get("/getContentControlId");
  },

  // Store shared reference articles
  storeSharedReferences(data) {
    return Api().post("/storeSharedReferences", data);
  },

  // Update citation data
  updateCitationInformation(data) {
    return Api().post("/updateCitationInformation", data);
  },

  // Reset custom citation text
  resetCustomCitationText(data) {
    return Api().post("/resetCustomCitationText", data);
  },

  // Update copy citation
  updateCopyCitation(data) {
    return Api().post("/updateCopyCitation", data);
  },

  // Update file citation style
  updateFileCitationStyle(data) {
    return Api().post("/updateFileCitationStyle", data);
  }
};
