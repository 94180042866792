import React, { useContext, useState, useRef } from "react";
import { IconButton, Modal } from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { HomeContext } from "../../contexts/HomeContext";
import { http } from "../../utils/http";
import Article from "../../api/Article";

toast.configure();

const UpdateArticle = () => {
  const doiRef = useRef(null);
  const [doiBtn, setDoiBtn] = useState(false);
  const [doiLink, setDoiLink] = useState("");

  const context = useContext(HomeContext);

  const updateArticleInformation = event => {
    event.preventDefault();
    event.persist();

    let articleData = {
      id: parseInt(event.target.elements.id.value),
      title: event.target.elements.title.value,
      journal_name: event.target.elements.journal_name.value,
      volume: event.target.elements.volume.value,
      issue: event.target.elements.issue.value,
      publication_year: event.target.elements.publication_year.value,
      pages: event.target.elements.pages.value,
      authors: event.target.elements.authors.value,
      doi_link: event.target.elements.doi_link.value,
      publisher: event.target.elements.publisher.value,
      publication_type: event.target.elements.publication_type.value,
      edited_by: event.target.elements.edited_by.value,
      patent_office: event.target.elements.patent_office.value,
      patent_number: event.target.elements.patent_number.value,
      patent_application: event.target.elements.patent_application.value,
      court_case_docket_id: event.target.elements.court_case_docket_id.value
    };

    Article.updateArticleInfo(articleData)
      .then(response => {
        console.log(response);
        return context.updateCitationDoc();
      })
      .then(() => {
        return context.closeModal();
      })
      .then(() => {
        toast.success("Article Information Updated Successfully", { autoClose: 2000 });
      })
      .catch(err => {
        console.log(err);
        toast.error("Something went wrong", { autoClose: 2000 });
      });
  };

  const changeDoiBtnStatus = () => {
    doiRef.current.focus();
    setDoiBtn(true);
  };

  // Update with doi
  const updateWithDoi = () => {
    Article.getArticleByDoi({
      doiInfo: doiLink ? doiLink : context.articleInfo.doi_link
    }).then(response => {
      let fetchResult = response.data.results;
      context.articleInfo.abstract = fetchResult.abstract;
      context.articleInfo.authors = fetchResult.authors;
      context.articleInfo.doi_link = fetchResult.doi_link;
      context.articleInfo.issue = fetchResult.issue;
      context.articleInfo.journal = fetchResult.journal;
      context.articleInfo.pages = fetchResult.pages;
      context.articleInfo.publication_type = fetchResult.publication_type;
      context.articleInfo.publication_year = fetchResult.publication_year;
      context.articleInfo.publisher = fetchResult.publisher;
      context.articleInfo.title = fetchResult.title;
      context.articleInfo.volume = fetchResult.volume;
      return Article.updateArticleInfo(context.articleInfo);
    }).then(response => {
      console.log(response);
      return context.updateCitationDoc();
    }).then(() => {
      setDoiBtn(false);
    }).catch(err => {
      console.log(err);
      setDoiBtn(false);
    });
  };

  // Close modal
  const closeArticleInfoModal = () => {
    setDoiLink("");
    context.closeModal();
  };

  return (
    <main className="ms-welcome__main">
      {context.contentStyles != null && context.iconButtonStyles != null ? (
        <Modal
          className="images-modal"
          titleAriaId="title"
          subtitleAriaId="subtext"
          isOpen={context.showUpdateInfoModal}
          onDismiss={closeArticleInfoModal}
          isBlocking={false}
          containerClassName={context.contentStyles.container}
        >
          <div className="">
            <IconButton
              className="close-btn"
              styles={context.iconButtonStyles}
              iconProps={{ iconName: "Cancel" }}
              ariaLabel="Close popup modal"
              onClick={closeArticleInfoModal}
            />
          </div>
          {Object.keys(context.articleInfo).length > 0 ? (
            <div id="subtext" className="">
              <h4 className="title">
                <span className="inner">Update Article Information</span>
              </h4>
              <div>
                <form onSubmit={updateArticleInformation} className="welcome-login-form">
                  <div className="welcome-login-full" style={{ padding: "0px" }}>
                    <div className="welcome-input-box update-info icite-edit-box">
                      <input
                        ref={doiRef}
                        type="text"
                        placeholder="Edit DOI and Press Enter"
                        name="doi_link"
                        defaultValue={context.articleInfo.doi_link}
                        onInput={e => setDoiLink(e.target.value)}
                        readOnly={!doiBtn ? true : false}
                        title="DOI"
                      />
                      {doiBtn ? (
                        <button style={{ background: 'rgb(0 0 0/20%)', right: '5px' }} type="button" className="icite-edit-icon-wrap" onClick={() => updateWithDoi()}>OK</button>
                      ) : (
                        <button type="button" className="icite-edit-icon-wrap" onClick={() => changeDoiBtnStatus()}><img src={http.BASE_URL + "assets/icite-edit-icon.png"} className="paste-icon-dpl icite-edit-icon" /></button>
                      )}
                      <input type="hidden" name="id" defaultValue={context.articleInfo.id} />
                    </div>
                    <div className="welcome-input-box update-info">
                      <textarea
                        type="text"
                        placeholder="Title"
                        name="title"
                        defaultValue={context.articleInfo.title}
                        title="Title"
                      ></textarea>
                    </div>
                    <div className="welcome-input-box update-info">
                      <textarea
                        type="text"
                        placeholder="Authors"
                        name="authors"
                        defaultValue={context.articleInfo.authors}
                        title="Authors"
                      ></textarea>
                      <span className="authors-format">* Given names, family name; given names, family name; …</span>
                    </div>
                    <div className="welcome-input-box update-info">
                      <select defaultValue={context.articleInfo.publication_type} name="publication_type">
                        <option value="journal">Research article</option>
                        <option value="conference">Conference</option>
                        <option value="chapter">Chapter</option>
                        <option value="book">Book</option>
                        <option value="thesis">Thesis</option>
                        <option value="patent">Patent</option>
                        <option value="court-case">Court case</option>
                        <option value="web-article">Web article</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Publication/Decided Date"
                        name="publication_year"
                        defaultValue={context.articleInfo.publication_year}
                        title="Publication/Decided Date"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Journal/Conference/Book name"
                        name="journal_name"
                        defaultValue={context.articleInfo.journal_name}
                        title="Journal/Conference/Book name"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Volume"
                        name="volume"
                        defaultValue={context.articleInfo.volume}
                        title="Volume"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Issue/Edition"
                        name="issue"
                        defaultValue={context.articleInfo.issue}
                        title="Issue/Edition"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Pages"
                        name="pages"
                        defaultValue={context.articleInfo.pages}
                        title="Pages"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <textarea
                        type="text"
                        placeholder="Publisher/Institution"
                        name="publisher"
                        defaultValue={context.articleInfo.publisher}
                        title="Publisher/Institution"
                      ></textarea>
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Book edited by"
                        name="edited_by"
                        defaultValue={context.articleInfo.edited_by}
                        title="Book edited by"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Patent Office"
                        name="patent_office"
                        defaultValue={
                          typeof context.articleInfo.patent_office !== "undefined"
                            ? context.articleInfo.patent_office
                            : ""
                        }
                        title="Patent Office"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Patent Number"
                        name="patent_number"
                        defaultValue={
                          typeof context.articleInfo.patent_number !== "undefined"
                            ? context.articleInfo.patent_number
                            : ""
                        }
                        title="Patent Number"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Patent Application"
                        name="patent_application"
                        defaultValue={
                          typeof context.articleInfo.patent_application !== "undefined"
                            ? context.articleInfo.patent_application
                            : ""
                        }
                        title="Patent Application"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <input
                        type="text"
                        placeholder="Court case docket id"
                        name="court_case_docket_id"
                        defaultValue={
                          typeof context.articleInfo.court_case_docket_id !== "undefined"
                            ? context.articleInfo.court_case_docket_id
                            : ""
                        }
                        title="Court case docket id"
                      />
                    </div>
                    <div className="welcome-input-box update-info">
                      <textarea
                        type="text"
                        placeholder="Keywords"
                        name="keywords"
                        defaultValue={context.articleInfo.keywords}
                        title="Keywords"
                      ></textarea>
                    </div>
                    <div className="submit">
                      <button type="submit">Update</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : null}
        </Modal>
      ) : null}
    </main>
  );
};

export default UpdateArticle;
